import { message } from "ant-design-vue";

message.config({
  top: `50%`,
  duration: 2,
  maxCount: 1,
});
export function useToast() {
  return {
    toast: message,
  };
}
