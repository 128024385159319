import { install } from "commom";
import { CMS_INTL_GOOGLE_CLIENTID, useSystemInit } from "core";
import { createApp } from "vue";
import App from "./App";
import router from "./router";
// import { Lazyload } from "vant";
import GoogleSignInPlugin from "vue3-google-signin";
const { systemInit } = useSystemInit();
const app = createApp(App);
install(app);
app.use(router);
app.use(GoogleSignInPlugin, {
  clientId: CMS_INTL_GOOGLE_CLIENTID,
});
// app.use(Lazyload);
router.isReady().then(async () => {
  await systemInit(app);
  app.mount("#app");
});
