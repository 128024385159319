import { defineComponent, PropType } from "vue";
import {
  useI18n,
  useUser,
  useWithdraw,
  timestampToDateTime,
  useClipboards,
  useWallet,
  useToast,
} from "core";
import style from "../Withdraw.module.less";
import { CheckOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<string>,
      default: "B",
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { copyFn } = useClipboards();
    const { user } = useUser();
    const { rechargeDetail, cashStatusFormat, methodFormat, query, verificationCode } =
      useWithdraw();
    const { amountFormat } = useWallet();
    const { $t } = useI18n();
    const { toast } = useToast();
    // 点击复制按钮
    const copyText = async (value: any) => {
      await copyFn(value || "");
      toast.success($t("common_copySuccessfully" /**复制成功 */));
    };
    const step = [
      { id: "1", text: $t("funds_withdraw_stepOne" /* 发起申请 */) },
      { id: "2", text: $t("funds_withdraw_stepTwo" /* 审核中 */) },
      { id: "3", text: $t("funds_withdraw_stepThree" /* 发起到账 */) },
    ];
    const backWithdrawal = async () => {
      emit("close");
      verificationCode.value = false;
      await query();
    };
    return () => (
      <div class={style.withdraw}>
        <div class={style.success}>
          <div class={style.successTitle}>
            <div class={style.successTitleImg}>
              <CheckOutlined />
            </div>
            <div class={style.successTitleText}>
              <div>{$t("funds_withdraw_stepOneTitle" /**提现申请已提交成功 */)}</div>
              <p>
                {$t("funds_withdraw_amout" /**提现金额 */)}：
                <span>{amountFormat(rechargeDetail.value?.cashMoney)}</span>
              </p>
            </div>
          </div>
          <div class={style.successStep}>
            {step.map((item, index) => (
              <div
                key={item.id}
                class={[
                  style.successStepItem,
                  String(rechargeDetail.value?.cashStatus ?? 1) === item.id &&
                    style.successStepItemAct,
                ].join(" ")}
              >
                <span>
                  {index + 1}.{item.text}
                </span>
                {String(rechargeDetail.value?.cashStatus ?? 1) === item.id && <i></i>}
              </div>
            ))}
          </div>
          <div class={style.successContent}>
            <p>
              <span>{$t("funds_withdraw_orderNo" /**订单号 */)}</span>
              <span>
                {rechargeDetail.value.cashOrderNo}{" "}
                <span
                  class={[style.green, style.copy]}
                  onClick={() => copyText(rechargeDetail.value.cashOrderNo)}
                >
                  {$t("common_copy" /* 复制 */)}
                </span>
              </span>
            </p>
            <p>
              <span>{$t("funds_recharge_tradingTime" /* 交易时间 */)}</span>
              <span>{timestampToDateTime(rechargeDetail.value?.createTime || 0)}</span>
            </p>
            <p>
              <span>{$t("funds_withdraw_bizType" /* 提现方式 */)}</span>
              <span>{methodFormat[rechargeDetail.value.cashMode]}</span>
            </p>
            {props.type === "B" && (
              <>
                <p>
                  <span>{$t("funds_withdraw_bizBank" /* 提现银行 */)}</span>
                  <span>{rechargeDetail.value.bankName}</span>
                </p>
                <p>
                  <span>{$t("funds_withdraw_bizBankCard" /* 提现卡号 */)}</span>
                  <span>{rechargeDetail.value.bankCard}</span>
                </p>
              </>
            )}
            <p>
              <span>{$t("funds_withdraw_bizFree" /* 提现手续费 */)}</span>
              <span>
                {amountFormat(Number(rechargeDetail.value.counterFee))} ({user.value?.currency})
              </span>
            </p>
            <p>
              <span>{$t("funds_withdraw_bizStatus" /* 提现状态 */)}</span>
              <span class={style.green}>{cashStatusFormat[rechargeDetail.value.cashStatus]}</span>
            </p>
          </div>
        </div>
      </div>
    );
  },
});
