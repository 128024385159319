import { defineComponent, onMounted, reactive } from "vue";
import { useConfigContext, useI18n, useUserBank } from "core";
import List from "./list/account";
import bank from "./bankCard.module.less";
import popup from "templates/mango/Mobile/popup.module.less";
import { SvgIcon } from "commom";
import { mangoPopup } from "components";
export default defineComponent({
  props: {
    type: {
      type: String,
      default: "B",
    },
    callback: {
      type: Function,
      default: null,
    },
  },
  emits: ["close"],
  setup(props) {
    const { $t } = useI18n();
    const data = reactive({
      index:
        props.type === "B" ||
        [
          "lam106",
          "lam107",
          "lam109",
          "lam110",
          "lam111",
          "lam112",
          "lam113",
          "lam117",
          "lam119",
          "lam120",
          "lam125",
          "lam126",
          "lam127",
          "lam130",
          "lam131",
          "lam132",
          "lam102",
        ].includes(window.CONFIG?.name)
          ? 0
          : 1,
      tab: [{ value: "B", label: $t("common_bankCard" /* 银行卡 */) }],
    });
    ![
      "lam106",
      "lam107",
      "lam109",
      "lam110",
      "lam111",
      "lam112",
      "lam113",
      "lam117",
      "lam119",
      "lam120",
      "lam125",
      "lam126",
      "lam127",
      "lam130",
      "lam131",
      "lam132",
      "lam102",
    ].includes(window.CONFIG?.name) &&
      data.tab.push({ value: "V", label: $t("common_virtualCurrency" /* 虚拟币 */) });
    const { cardList, getUserBank, unbankSubmit } = useUserBank();
    const { config } = useConfigContext();
    const active = (index: number) => {
      data.index = index;
    };
    onMounted(async () => {
      data.index =
        props.type === "B" ||
        [
          "lam106",
          "lam107",
          "lam109",
          "lam110",
          "lam111",
          "lam112",
          "lam113",
          "lam117",
          "lam119",
          "lam120",
          "lam125",
          "lam126",
          "lam127",
          "lam130",
          "lam131",
          "lam132",
          "lam102",
        ].includes(window.CONFIG?.name)
          ? 0
          : 1;
      await getUserBank();
    });
    return () => (
      <div class={[bank.page, "mangoPage"]}>
        <div class={popup.popupHeader}>
          <section class={popup.popupHeaderLeft} />
          <section class={popup.popupHeaderMiddle}>
            {$t("funds_wallet_cardManager" /* 卡片管理 */)}
          </section>
          <section
            class={popup.popupHeaderRight}
            onClick={() => {
              mangoPopup().close();
              props.callback && props.callback();
            }}
          >
            <SvgIcon class={popup.popupHeaderIcon} name="close" width="0.48rem" height="0.48rem" />
          </section>
        </div>
        <div class={bank.tab}>
          <ul>
            {data.tab.map((item, index) => (
              <li
                onClick={() => active(index)}
                key={index}
                class={data.index === index ? bank.active : null}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <List
            accountList={data.index ? cardList.value.V : cardList.value.B}
            onUnbankSubmit={unbankSubmit}
            config={config}
            accountType={data.index ? "V" : "B"}
          />
        </div>
      </div>
    );
  },
});
