import { defineComponent, PropType } from "vue";
import tip from "./tips.module.less";
import { useModalContext, useConfig, useI18n } from "core";
export default defineComponent({
  props: {
    contentText: {
      type: [String, Function],
      default: "",
    },
    cancelBtnText: {
      type: String as PropType<string>,
      default: () => "common_cancel" /*取消*/,
    },
    confirmBtnText: {
      type: String as PropType<string>,
      default: () => "user_findPassword_sure" /*确认*/,
    },
    cancelCallBack: {
      type: Function as PropType<() => void | null>,
      default: null,
    },
    confimCallBack: {
      type: Function as PropType<() => void | null>,
      default: null,
    },
    noCancel: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  setup(props) {
    const { isPc } = useConfig();
    const test = useModalContext();
    const { $t } = useI18n();
    const isMango = window.CONFIG?.currentModel === "mango";
    return () => (
      <div class={[tip.tipsContentView]}>
        <div
          class={[
            tip.tipsContentViewText,
            isMango && tip.tipsContentViewTextMango,
            !isPc.value && tip.tipsContentViewTextMobile,
          ]}
          v-html={[props.contentText]}
        ></div>
        <div
          class={[
            tip.tipsContentViewFooter,
            !isPc.value && tip.tipsContentViewFooterMobile,
            isMango && !isPc.value && tip.tipsContentViewFooterMobileMango,
          ]}
        >
          {props.noCancel && (
            <div
              class={[
                isMango &&
                  (!isPc.value
                    ? tip.tipsContentViewFooterMobileCancel
                    : tip.tipsContentViewFooterCancel),
              ]}
              onClick={() => {
                test?.close();
                props.cancelCallBack && props.cancelCallBack();
              }}
            >
              {$t(props.cancelBtnText) || $t("common_cancel" /*取消*/)}
            </div>
          )}
          <div
            class={[
              !props.noCancel && tip.tipsContentViewFooterConfim,
              isMango &&
                (!isPc.value
                  ? tip.tipsContentViewFooterMobileConf
                  : tip.tipsContentViewFooterMangoConf),
            ]}
            onClick={() => {
              test?.close();
              props.confimCallBack && props.confimCallBack();
            }}
          >
            {$t(props.confirmBtnText) || $t("user_findPassword_sure" /*确认*/)}
          </div>
        </div>
      </div>
    );
  },
});
