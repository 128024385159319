// facebook login params
export const CMS_INTL_FACEBOOK_LOGIN_URI =
  "https://connect.facebook.net/en_US/sdk.js#version=v3.3&appId=331489199397046&status=true&cookie=true&xfbml=true&autoLogAppEvents=true";

// google clientId
export const CMS_INTL_GOOGLE_CLIENTID =
  window.CONFIG?.googleId ||
  "626937364336-1aeh8k3hi7oqopc16j1uosn3d1tv3kvv.apps.googleusercontent.com";

// 当前项目版本
export const PROJECT_VERSION = `1.5.0.0`;
export const STORE_COOKIE_RC = `rcCode`;
export const STORE_COOKIE_PROXY = `proxy`;
export const STORE_LADING = "CMS_LADING";
