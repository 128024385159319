import { defineComponent, onMounted } from "vue";
import type { PropType } from "vue";
import { Loading } from "vant";
import { useTask } from "core";
import { Empty } from "components";
import { TaskData, TypesListItem } from "@kgsport-cms/service";
import task from "./task.module.less";
import type { Router } from "vue-router";
// 任务列表
import Card from "./components/card";

export default defineComponent({
  props: {
    router: Object as PropType<Router>,
  },
  setup(props) {
    console.log(props, "props");
    const {
      store,
      activeTab,
      loading,
      params,
      typesList,
      taskList,
      getMissionTypes,
      getMissionList,
    } = useTask();
    // 1 登录任务 2 充值任务 3 游戏任务 4 代理分享 5 新手任务
    const tabClick = async (item: TypesListItem) => {
      activeTab.value = item.missionType;
      params.type = item.missionType;
      params.current = 1;
      store.data = {} as TaskData;
      store.finished = false;
      await getMissionList();
    };
    onMounted(async () => {
      params.current = 1;
      // 获取类型列表
      await getMissionTypes();
      // 获取任务列表
      await getMissionList();
    });

    const isStyle2 = ["lam102"].includes(window.CONFIG.name);

    return () => (
      <div class={task.task}>
        <div class={task.taskLeft} v-show={typesList.value.length > 0}>
          {typesList.value?.map((item: TypesListItem) => (
            <div
              class={[
                task.taskLeftItem,
                activeTab.value === item.id
                  ? isStyle2
                    ? task.taskLeftActive2
                    : task.taskLeftActive
                  : "",
              ]}
              key={item.id}
              onClick={() => tabClick(item)}
            >
              {item.typeName}
            </div>
          ))}
        </div>
        <div class={[task.taskRight, typesList.value.length == 0 && task.taskRightWidth]}>
          {taskList.value && taskList.value.length ? (
            taskList.value.map(item => (
              <Card key={activeTab.value} type={activeTab.value} router={props.router} />
            ))
          ) : loading.value ? (
            <div class={task.taskRightLoading}>
              <Loading color="@cms-mango-text-color4" />
            </div>
          ) : (
            <Empty type={"mangoNoData"} top={120} />
          )}
        </div>
      </div>
    );
  },
});
