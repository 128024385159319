import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useConfig, useI18n, useUser, useConfigContext } from "core";
import popupStyle from "templates/mango/Mobile/popup.module.less";
import style from "./style.module.less";
import { Form, Input } from "ant-design-vue";
import { SvgIcon } from "commom";
import { mangoPopup } from "components";
import { Button, Popup } from "vant";
import RadioCircle from "./components/RadioCircle";
import { IDialCode } from "@kgsport-cms/service";

export default defineComponent({
  props: {
    callback: {
      type: Function,
      default: null,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { config, getDialCode, defaultCode, areaCodeList } = useConfig();
    const {
      count,
      phoneForm,
      phoneAccount,
      smsCodeAccount,
      sendCodeSubmit,
      reqeustLoading,
      editPhoneSubmit,
    } = useUser();
    const { config: global } = useConfigContext();

    const popup = reactive({ toggle: false });
    /*区号选择*/
    const onConfirm = (item: IDialCode) => {
      popup.toggle = false;
      phoneForm.dialCode = item.code;
    };

    // 搜索
    const areaCodeData = ref<IDialCode[]>([]);
    watch(
      () => areaCodeList.value,
      n => {
        areaCodeData.value = n;
      },
      { immediate: true, deep: true }
    );
    const searchVal = ref("");
    const setAreaCodeData = (text: string) => {
      areaCodeData.value = text
        ? areaCodeList?.value.filter(d => d.code.includes(text)) ?? []
        : areaCodeList?.value;
    };
    const timer = ref();
    watch(
      () => searchVal.value,
      (n, o) => {
        if (n === o) return;
        if (timer.value) clearTimeout(timer.value);
        timer.value = setTimeout(() => setAreaCodeData(n), 300);
      }
    );
    const clearSearch = () => {
      searchVal.value = "";
    };

    const sendCode = async () => {
      if (count.value) return;
      await phoneAccount.validate();
      await sendCodeSubmit(true);
    };

    const submit = async () => {
      // 设置成功后返回设置页面
      editPhoneSubmit().then(res => {
        if (res) {
          emit("close");
          if (props.callback) {
            props.callback();
            mangoPopup().destroyAll();
          }
        }
      });
    };
    watch(
      () => phoneForm.phone,
      () => {
        phoneAccount.clearValidate();
      }
    );
    watch(
      () => phoneForm.smsCode,
      () => {
        smsCodeAccount.clearValidate();
      }
    );

    onMounted(async () => {
      await getDialCode();
      phoneForm.dialCode = defaultCode.value;
    });

    return () => (
      <div>
        <div class={[popupStyle.popupHeader, popupStyle.popupHeaderBorder]}>
          <section class={popupStyle.popupHeaderLeft}></section>
          <section class={popupStyle.popupHeaderMiddle}>
            {$t("mine_usersetup_secretPhone" /*密保手机号*/)}
          </section>
          <section
            class={popupStyle.popupHeaderRight}
            onClick={() => {
              props.callback && props.callback();
              mangoPopup().destroyAll();
            }}
          >
            <SvgIcon
              class={popupStyle.popupHeaderIcon}
              name="close"
              width="0.48rem"
              height="0.48rem"
            />
          </section>
        </div>
        <div class={style.formWrap}>
          <Form>
            <Form.Item {...phoneAccount.validateInfos.phone}>
              <Input.Group compact>
                <div class={style.areaCodeWrap} onClick={() => (popup.toggle = true)}>
                  <div class={style.areaCodeWrapText}>{phoneForm.dialCode}</div>
                  <div class={style.areaCodeWrapBtn}>
                    <SvgIcon width="0.48rem" height="0.48rem" name="arrow" />
                  </div>
                </div>
                <Input
                  placeholder={$t("mine_usersetup_enterMobileNo" /*请输入您的手机号 */)}
                  bordered={false}
                  // type="number"
                  v-model:value={phoneForm.phone}
                />
              </Input.Group>
            </Form.Item>
            {global.smsSwitch ? (
              <>
                <Form.Item {...smsCodeAccount.validateInfos.smsCode} class={style.formWrapItem}>
                  <Input
                    placeholder={$t("user_register_input_verifyCode" /*请输入验证码*/)}
                    bordered={false}
                    type="number"
                    v-model:value={phoneForm.smsCode}
                  />
                  <a class={style.formWrapCode} onClick={sendCode}>
                    {count.value
                      ? `${count.value}s`
                      : $t("user_login_sendVerificationCode" /*发送验证码*/)}
                  </a>
                </Form.Item>
                <div class={style.formWrapCodeTips}>
                  {$t("user_verify_input_sms_code" /*请输入6位数验证码*/)}
                </div>
              </>
            ) : null}

            <div class={style.formWrapBtns}>
              <Button onClick={submit} loading={reqeustLoading.value}>
                {$t("mine_userinfo_verify" /*验证*/)}
              </Button>
            </div>
          </Form>
          <Popup
            position="bottom"
            teleport="body"
            v-model:show={popup.toggle}
            round
            class={style.areaCode}
          >
            <div class={style.areaCodeHeader}>
              <section class={style.areaCodeHeaderCode}>
                {$t("user_register_ToastTitle_areaCode" /* 选择区号 */)}
              </section>
              <section class={style.areaCodeHeaderSearch}>
                <input
                  v-model={searchVal.value}
                  type="text"
                  placeholder={$t("user_register_searchAreaCode" /*搜索区号*/)}
                />
                <i class={style.areaCodeHeaderSearchClose} onClick={clearSearch}>
                  <SvgIcon
                    class={style.areaCodeHeaderSearchCloseIcon}
                    name="closeTick"
                    width="0.24rem"
                    height="0.24rem"
                  />
                </i>
              </section>
            </div>
            <div class={style.areaCodeMain}>
              {areaCodeData.value?.map(option => (
                <div
                  class={style.areaCodeOption}
                  key={option.code}
                  onClick={() => onConfirm(option)}
                >
                  <section class={style.areaCodeOptionText}>
                    <span>{option.name}</span>
                    <span>({option.code})</span>
                  </section>
                  <RadioCircle checked={phoneForm.dialCode === option.code} />
                </div>
              ))}
            </div>
          </Popup>
        </div>
      </div>
    );
  },
});
