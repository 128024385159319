import { computed, defineComponent, onMounted } from "vue";
import { useLogin, useI18n, useConfig, useGoogleAnaltics } from "core";
import { SvgIcon } from "commom";
import css from "./LoginOther.module.less";

export default defineComponent({
  props: {
    bottomType: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const { thirdLoginInfo } = useConfig();
    const { facebookLogin, googleLogin, initFacebookSDK } = useLogin();
    const { userClickLogin } = useGoogleAnaltics();
    const whetherOpenShow = computed(() => !!thirdLoginInfo.value?.whetherOpen);
    const googleShow = computed(() => !!thirdLoginInfo.value?.google);
    const facebookShow = computed(() => !!thirdLoginInfo.value?.facebook);

    onMounted(() => {
      initFacebookSDK();
    });
    return () =>
      whetherOpenShow.value ? (
        <>
          <div class={css.loginOtherTitle}>
            {$t("user_login_otherLoginType" /**其他登录方式 */)}
          </div>
          <ul
            class={[css.loginOtherList, props.bottomType == "register" && css.loginOtherListless]}
          >
            {googleShow.value && (
              <li
                class={css.loginOtherItem}
                style="background:@cms-mango-text-color7;width:30px;height:30px;border-radius:15px"
              >
                <SvgIcon
                  name="newGoogle"
                  width="24px"
                  height="24px"
                  onClick={() => {
                    userClickLogin();
                    googleLogin();
                  }}
                />
              </li>
            )}
            {facebookShow.value && (
              <li
                class={css.loginOtherItem}
                style="background:rgba(30, 110, 230, 0.984);width:30px;height:30px;border-radius:15px"
              >
                <SvgIcon name="newFb" width="32px" height="32px" onClick={facebookLogin} />
              </li>
            )}
          </ul>
        </>
      ) : null;
  },
});
