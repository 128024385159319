import {
  defineComponent,
  onMounted,
  watch,
  reactive,
  onDeactivated,
  onBeforeUnmount,
  computed,
} from "vue";
import { Form } from "ant-design-vue";
import { SyncOutlined, CaretDownOutlined } from "@ant-design/icons-vue";
import {
  useI18n,
  useUser,
  useConfig,
  useUserBank,
  useWithdraw,
  useWallet,
  useModal,
  getSlice,
  saveParams,
  bankAndUdstParam,
  tipModalData,
  useConfigContext,
} from "core";
import { mangoPopup, MangoTipsModal } from "components";
import { SvgIcon } from "commom";
import Success from "./components/Success";
import style from "./Withdraw.module.less";
import popup from "templates/mango/Web//popup.module.less";
// 税号弹窗
import MangoDutyCodeSelect from "templates/mango/Web/components/DutyCodeSelect";
import { Popup } from "vant";
import bank from "./assets/bank.png";
import btn from "./assets/btn.png";

export default defineComponent({
  emits: ["close"],
  setup(props, { emit }) {
    const {
      validate,
      clearValidate,
      query,
      save,
      captchaCode,
      getCountry,
      onCountyCodeChange,
      withdrawLimitData,
      withdrawTabs,
      isDirect,
      captchaImg,
      validateInfos,
      serviceAmount,
      amountReceived,
      phoneText,
      countryCode,
      tabChange,
      actualAmount,
      usdtRate,
      usdtValue,
      getSumDeductMoney,
      verificationCode,
      isDisabledWithdrawBtn,
    } = useWithdraw();
    const {
      balance,
      reclaimloading,
      reclaimAmount,
      platformBalance,
      amountFormat,
      scaleAmountValue,
    } = useWallet();
    const duty = reactive({
      visible: false,
    });
    const { getUserBank, cardList } = useUserBank();
    const { user, getSmsCode, isWithdrawPwd, isPhone, count } = useUser();
    const { $t } = useI18n();
    const { config } = useConfigContext();
    const { config: systemConfig } = useConfig();
    const data = reactive({
      countyCodeShow: false,
      bankListShow: false,
      usdtListShow: false,
      success: false,
      addBank: 0,
    });
    const clickTab = tabChange;
    const showSizeChange = async (current: number, pageSize: number) => {
      countryCode.value.paging.current = current;
      countryCode.value.paging.size = pageSize;
      await getCountry();
    };

    const inputNumber = (e: any) => {
      let value = getSlice(e.target.value);
      let b = balance.value;
      // 判断是否开启小数提现 1开启 0关闭
      if (withdrawLimitData.value?.decimalSwitch == 1) {
        saveParams.cashMoney = Number(value) <= b ? value : b + "";
      } else {
        saveParams.cashMoney =
          Number(value) <= b ? Math.floor(Number(value)) : Math.floor(Number(b)) + "";
      }
    };

    // 验证手机号
    const verificationPhoneModal = () => {
      tipModalData.title = `${$t("common_tip" /* 提示 */)}`;
      tipModalData.confirmText = `${$t("common_confirm" /*确定*/)}`;
      tipModalData.subTitle = `${$t(
        "funds_withdraw_safetyPhoneTips" /**为了更加安全，需要绑定您的手机号 */
      )}`;
      tipModalData.confirmCallback = () => {
        // 弹窗功能开启 绑定手机号
        mangoPopup().destroyAll();
        // mangoPopup({
        //   type: "MangoWithdrawalPassSettings",
        //   title: $t("mine_usersetup_withdrawalPassSettings" /*提现密码设置*/),
        // }).open();
      };
      tipModalData.show = true;
      tipModalData.close = () => {
        data.success = true;
        tipModalData.show = false;
      };
    };
    const verificationPhone = () => {
      if (!isPhone.value) {
        verificationPhoneModal();
        return false;
      }
      return true;
    };
    // 提现条件验证
    const withdrawVerification = () => {
      // 提现密码
      if (!isWithdrawPwd.value) {
        tipModalData.title = `${$t("common_tip" /* 提示 */)}`;
        tipModalData.confirmText = `${$t("common_confirm" /*确定*/)}`;
        tipModalData.subTitle = `${$t(
          "funds_withdraw_notWithdrawPassword" /**为了您的账户安全,提现需要设置提现密码 */
        )}`;
        tipModalData.confirmCallback = () => {
          // 弹窗功能开启 这里跳转设置提现密码
          mangoPopup().destroyAll();
          mangoPopup({
            type: "MangoWithdrawalPassSettings",
            title: $t("mine_usersetup_withdrawalPassSettings" /*提现密码设置*/),
          }).open();
        };
        tipModalData.show = true;
      }
      // 手机号 是否开启提现短信验证码，如果开启并且没有绑定手机号就跳转手机号绑定页面
      // if (user.value?.cashValidate && !isPhone.value) verificationPhoneModal.open();
    };
    // 银行卡和虚拟钱包
    const cardModal = () => {
      tipModalData.title = `${$t("common_tip" /* 提示 */)}`;
      tipModalData.confirmText = `${$t("common_confirm" /*确定*/)}`;
      tipModalData.subTitle =
        saveParams.withdrawType === "B"
          ? $t("funds_withdraw_noBankcardTips" /**您还未绑定银行卡,请先绑定 */)
          : $t("funds_withdraw_noBitCoinTips" /**您还未绑定虚拟币地址,请先绑定 */);
      tipModalData.confirmCallback = () => {
        mangoPopup().destroyAll();
        mangoPopup({
          type: "MangoBankCardManage",
          title: $t("funds_wallet_cardManager" /* 卡片管理 */),
          borderRadius: "12",
          props: { type: "V" },
        }).open();
        // 弹窗功能开启 绑定银行卡活虚拟币
        // mangoPopup({
        //   type: "MangoWithdrawalPassSettings",
        //   title: $t("mine_usersetup_withdrawalPassSettings" /*提现密码设置*/),
        // }).open();
      };
      tipModalData.show = true;
    };
    watch(
      () => [saveParams.withdrawType, cardList.value],
      (n, o) => {
        if (n[0] === "D") return;
        if (n[0] === o[0]) return;
        if (n[1]?.[n[0] as string]?.length) return;
        cardModal();
      },
      {
        deep: true,
      }
    );
    // 提现
    const clickBtn = async () => {
      withdrawVerification();
      if (!isDirect.value && !cardList.value?.[saveParams.withdrawType]?.length) {
        return cardModal();
      }
      // 手机号 是否开启提现短信验证码，如果开启且有绑定手机号就弹出短信验证弹窗
      if (user.value?.cashValidate) {
        await validate(["cashMoney", "cashPassword", "code"]);
        if (user.value?.phone) {
          await getSmsCode({ type: 3 });
          verificationCode.value = true;
          return phoneModal.open();
        } else {
          return verificationPhone();
        }
      }
      await withdrawSave();
    };
    const withdrawSave = async () => {
      // if (user.value?.phone && !saveParams.validateCode) return;
      await save(async () => {
        // phoneModal.close?.();
        data.success = true;
        await platformBalance();
      });
    };

    // 切换银行卡
    const clickCard = ({
      id,
      exchangeRate = "0",
      type,
      cardNo,
      bankName,
      currency,
      network,
    }: any) => {
      saveParams.bankId = id;
      bankAndUdstParam.number = cardNo;
      bankAndUdstParam.name = type === "V" ? `${currency}(${network})` : bankName;
      usdtRate.value = type === "V" ? exchangeRate : "0";
      saveParams.cashPassword = "";
      saveParams.code = "";
      saveParams.memberMemo = "";
      setTimeout(clearValidate, 0);
    };
    // 提现手机验证
    const phoneModal = useModal({
      content: () => (
        <div class={style.withdrawFrom}>
          <Form>
            <Form.Item
              has-feedback
              validateFirst={true}
              name={"validateCode"}
              {...validateInfos["validateCode"]}
            >
              <input
                v-model={saveParams.validateCode}
                placeholder={$t("mine_usersetup_verificationPhoneCodePoint" /**请输入验证码 */)}
                autocomplete="off"
              />
              <span
                onClick={() => !count.value && getSmsCode({ type: 3 })}
                class={style.withdrawFromCode}
              >
                {count.value
                  ? count.value + "s"
                  : $t("mine_changePwd_VerificationCode" /* 验证码 */)}
              </span>
            </Form.Item>
          </Form>
          <div class={style.withdrawFromTips}>
            <span>{$t("funds_withdraw_phoneMsg", [phoneText.value])}</span>
          </div>
          <div class={style.withdrawFromBtn} onClick={withdrawSave}>
            {$t("mine_userinfo_confirmSubmission" /**确认提交 */)}
          </div>
        </div>
      ),
      header: true,
      destroyOnClose: true,
      title: $t("funds_withdraw_msgTip"), // 提现短信验证
      zIndex: 2000,
    });
    countryCode.value.paging.size = 50;
    Promise.all([platformBalance(), getUserBank(), getCountry()]);
    // 点击虚拟币、银行卡弹窗
    const selectBankAndUsdt = () => {
      saveParams.withdrawType === "B"
        ? (data.bankListShow = !data.bankListShow)
        : (data.usdtListShow = !data.usdtListShow);
    };
    // 添加按钮是否显示
    const addBtnShow = computed(() => {
      const { B = [], V = [] } = cardList.value;
      const { isUserBankBindCount = 0, isUserVirtualBindCount = 0 } = config;
      if (saveParams.withdrawType === "B") return B.length < isUserBankBindCount;
      else if (saveParams.withdrawType === "V") return V.length < isUserVirtualBindCount;
      else return false;
    });
    // 重置数据
    const resetData = () => {
      saveParams.bankId = 0;
      saveParams.cashPassword = "";
      saveParams.withdrawType = "B";
      saveParams.cashMoney = "";
      saveParams.memberMemo = "";
      saveParams.validateCode = "";
      saveParams.code = "";
    };
    watch(
      () => data.success,
      val => {
        if (!val) resetData();
      }
    );
    onMounted(async () => {
      bankAndUdstParam.number = "";
      bankAndUdstParam.name = "";
      reclaimAmount();
      withdrawVerification();
      query();
    });
    onBeforeUnmount(() => {
      resetData();
    });
    onDeactivated(() => {
      resetData();
    });
    // 金额比例和实际金额
    const AmountScale2ActualTips = () => {
      return (
        <>
          {saveParams.withdrawType === "V" && (
            <div class={style.sacle}>
              <p>
                {$t("funds_actual_credit" /*实际到账*/)}:{" "}
                <span>{amountFormat(usdtValue.value)}</span>
              </p>
              <p />
            </div>
          )}
          <div class={style.sacle}>
            <p>
              {$t("funds_currency_ratio" /*货币比例*/)} 1:{scaleAmountValue.value}
            </p>
            <p>
              {$t("funds_actual_withdrawal" /*实际提现*/)}:
              <span>{amountFormat(actualAmount.value || 0)}</span>
            </p>
          </div>
        </>
      );
    };
    return () => (
      <>
        {data.success ? (
          <Success type={saveParams.withdrawType} onClose={() => (data.success = false)} />
        ) : (
          <Form>
            <div class={style.withdraw}>
              <div class={style.withdrawContent}>
                <div class={style.withdrawContentMoney}>
                  {$t("funds_wallet_centerWallet" /* 中心钱包 */)}：
                  <span>{amountFormat(balance.value || 0)}</span>
                  <SyncOutlined
                    onClick={async () => {
                      await reclaimAmount();
                    }}
                    class={reclaimloading.value && style.withdrawContentMoneySpin}
                  />
                </div>
                {/*提现金额*/}
                <div class={style.withdrawContentFormItem}>
                  <Form.Item
                    has-feedback
                    validateFirst={true}
                    name={"cashMoney"}
                    {...validateInfos["cashMoney"]}
                  >
                    {!isDirect.value && (
                      <div class={style.countyCode}>
                        <div
                          class={style.countyCodeSelect}
                          onClick={() => (data.countyCodeShow = !data.countyCodeShow)}
                        >
                          <span class={style.countyCodeSelectText}>{saveParams.countyCode}</span>
                          <CaretDownOutlined />
                        </div>
                      </div>
                    )}
                    <input
                      v-model={saveParams.cashMoney}
                      type="text"
                      class={!isDirect.value && style.withdrawContentFromNumber}
                      onInput={e => inputNumber(e)}
                      placeholder={`${$t(
                        "funds_withdraw_amout" /**请输入提款金额 */
                      )} (${amountFormat(
                        withdrawLimitData.value.singleCashMoneyLowerLimit
                      )}-${amountFormat(withdrawLimitData.value.singleCashMoneyUpperLimit)})`}
                      autocomplete="off"
                    />
                    <span class={style.withdrawContentFromCurrency}>
                      ( {user.value?.currency} )
                    </span>
                  </Form.Item>
                </div>
                {/*提现次数*/}
                {window.CONFIG.name === "lam105" ? (
                  <div class={style.withdrawContentNumber}>
                    {$t("funds_withdraw_todayTimes", [withdrawLimitData.value.cashCount])}
                    {/*今日提现[0]次*/}
                  </div>
                ) : (
                  <div
                    class={style.withdrawContentNumber}
                    v-html={$t(
                      "funds_withdraw_todayTimesAndAllTimes",
                      [
                        `<span>${withdrawLimitData.value.cashCount}</span>`,
                        `<span>${withdrawLimitData.value.oddDaysCashTriesLimit}</span>`,
                      ] /* 今日提现[0]次，总计[1]次 */
                    )}
                  />
                )}
                {/*提现注意项*/}
                <div class={[style.withdrawContentTips, style.withdrawContentTipsFlex]}>
                  <p>
                    <span>
                      {$t("funds_withdraw_bizFree" /**提现手续费 */)}
                      <span class={style.red}>
                        {withdrawLimitData.value.counterFeeMode
                          ? `${withdrawLimitData.value.exceedCashNumberUpperLimitServiceCharge}%`
                          : withdrawLimitData.value.exceedCashNumberUpperLimitServiceCharge}
                      </span>
                    </span>
                    <span class={style.money}>
                      {amountFormat(serviceAmount.value.serviceChargePro)} ({user.value?.currency})
                    </span>
                  </p>
                  <p>
                    <span>{$t("funds_withdraw_damaFee" /**打码量费用 */)}</span>
                    <span class={style.money}>
                      {amountFormat(getSumDeductMoney())} ({user.value?.currency})
                    </span>
                  </p>
                  <p>
                    <span>{$t("funds_withdraw_amountReceived" /**预计到账金额 */)}</span>
                    <span class={style.money}>
                      {amountFormat(amountReceived.value || 0)} ({user.value?.currency})
                    </span>
                  </p>
                </div>
                {/*提现tab*/}
                {!!withdrawTabs.value?.length && (
                  <div class={style.withdrawContentTab}>
                    {withdrawTabs.value.map(item => (
                      <div
                        class={[
                          style.withdrawContentTabItem,
                          saveParams.withdrawType === item.value && style.withdrawContentTabItemAct,
                        ]}
                        onClick={() => {
                          data.bankListShow = false;
                          data.usdtListShow = false;
                          clickTab(item.value);
                        }}
                      >
                        {$t(item.label)}
                      </div>
                    ))}
                  </div>
                )}
                {/*银行卡或者虚拟币列表*/}
                {!isDirect.value && (
                  <div class={[style.withdrawContentFormItem, style.withdrawContentFormBank]}>
                    <Form.Item has-feedback validateFirst={true} name={"test"}>
                      <input
                        v-model={bankAndUdstParam.number}
                        type="text"
                        autocomplete="off"
                        disabled={true}
                      />
                      <div class={style.cardNumber}>
                        <div class={style.cardNumberSelect} onClick={() => selectBankAndUsdt()}>
                          <span class={style.cardNumberSelectText}>{bankAndUdstParam.name}</span>
                          <CaretDownOutlined />
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                )}
                {/*提现密码*/}
                <div class={[style.withdrawContentFormItem, style.withdrawContentFormDefault]}>
                  <Form.Item
                    has-feedback
                    validateFirst={true}
                    name={"cashPassword"}
                    {...validateInfos["cashPassword"]}
                  >
                    <input
                      v-model={saveParams.cashPassword}
                      type="password"
                      placeholder={$t("funds_withdraw_password" /* 提现密码 */)}
                      autocomplete="new-password"
                    />
                  </Form.Item>
                </div>
                {/*图形验证码*/}
                {!!withdrawLimitData.value.cashVerification && (
                  <div class={[style.withdrawContentFormItem, style.withdrawContentFormCode]}>
                    <Form.Item
                      has-feedback
                      validateFirst={true}
                      name={"code"}
                      {...validateInfos["code"]}
                    >
                      <input
                        v-model={saveParams.code}
                        placeholder={$t(
                          "funds_recharge_inputVerificationCode" /**请输入图形验证码 */
                        )}
                        autocomplete="off"
                      />
                      <img
                        class={style.captchaCode}
                        src={captchaImg.value}
                        onClick={() => captchaCode()}
                        alt="captcha"
                      />
                    </Form.Item>
                  </div>
                )}
                {/* 税号 */}
                {systemConfig.isTaxFlag && window.CONFIG?.currentModel === "mango" ? (
                  <div class={style.withdrawContentFormItem}>
                    <Form.Item name={"taxNumber"} {...validateInfos["taxNumber"]}>
                      <div class={style.countyCode}>
                        <div class={style.countyCodeSelect} onClick={() => (duty.visible = true)}>
                          <span class={style.countyCodeSelectText}>{saveParams.taxType}</span>
                          <CaretDownOutlined />
                        </div>
                      </div>
                      <input
                        v-model={saveParams.taxNumber}
                        type="text"
                        placeholder={$t("user_register_inputDutyCode" /**请输入税号 */)}
                        autocomplete="off"
                      />
                    </Form.Item>
                  </div>
                ) : null}
                {/*备注*/}
                <div class={[style.withdrawContentFormItem, style.withdrawContentFormDefault]}>
                  <Form.Item>
                    <input
                      v-model={saveParams.memberMemo}
                      placeholder={$t("funds_recharge_remark" /* 备注 */)}
                      autocomplete="off"
                    />
                  </Form.Item>
                </div>
                {/*货比比例*/}
                {AmountScale2ActualTips()}
                {/*提示*/}
                <div class={style.withdrawContentTips}>
                  {saveParams.withdrawType === "V" && (
                    <div class={style.withdrawContentTipsV}>
                      <p class={style.withdrawContentTipsVTips}>
                        <span>
                          {$t(
                            "funds_withdraw_exchangeRateTip" /**汇率实时浮动，自动计算结果仅供参考 */
                          )}
                        </span>
                      </p>
                    </div>
                  )}
                  <div class={style.withdrawContentTipsBottom}>
                    <p>
                      {$t("funds_withdraw_maxTopMoney" /**提现上限 */)}
                      <span class={style.green}>
                        {amountFormat(withdrawLimitData?.value?.singleCashMoneyUpperLimit ?? 0)}
                      </span>
                      {$t("funds_withdraw_lowLimitMoney" /**提现下限 */)}
                      <span class={style.green}>
                        {amountFormat(withdrawLimitData?.value?.singleCashMoneyLowerLimit ?? 0)}
                      </span>
                      {$t("funds_withdraw_todayTotalMoney" /**当天提现总额度为 */)}
                      <span class={style.red}>
                        {amountFormat(withdrawLimitData?.value?.oddDaysCashMoneyUpperLimit ?? 0)}
                      </span>
                    </p>
                    <section>
                      {$t("funds_withdraw_timesOverTips" /**如果您当天提现次数超过 */)}
                      <span class={style.red}>
                        {withdrawLimitData.value.oddDaysCashTriesLimit}，
                      </span>
                      {$t(
                        "funds_withdraw_timesOverMoneyTips" /* 则每次提现加手续费，手续费为提现金额的 */
                      )}
                      <span class={style.green}>
                        {withdrawLimitData.value.counterFeeMode
                          ? `${withdrawLimitData.value.exceedCashNumberUpperLimitServiceCharge}%`
                          : withdrawLimitData.value.exceedCashNumberUpperLimitServiceCharge}
                      </span>
                    </section>
                  </div>
                </div>
              </div>
              <div class={style.withdrawFooter}>
                <div
                  class={[
                    style.withdrawFooterSubmit,
                    isDisabledWithdrawBtn.value && style.withdrawFooterNoSubmit,
                  ]}
                  onClick={clickBtn}
                >
                  {$t("common_submit" /* 提交 */)}
                </div>
              </div>
              {/*选择区号编码*/}
              <Popup
                position="bottom"
                v-model:show={data.countyCodeShow}
                round
                class={[popup.popupWidth100, style.withdrawSelect]}
              >
                <div class={[style.withdrawSelect]}>
                  <div class={popup.popupHeader}>
                    <div
                      class={popup.popupHeaderLeft}
                      onClick={() => (data.countyCodeShow = false)}
                    >
                      {$t("common_cancel" /*取消*/)}
                    </div>
                    <div class={popup.popupHeaderMiddle}>
                      {$t("mine_select_encoding" /* 选择编码 */)}
                    </div>
                    <div
                      class={popup.popupHeaderRight}
                      onClick={() => (data.countyCodeShow = false)}
                    >
                      {$t("common_confirm" /*确定*/)}
                    </div>
                  </div>
                  <div class={style.withdrawSelectCon}>
                    {countryCode.value?.list?.map(item => (
                      <div
                        class={style.withdrawSelectConItem}
                        onClick={() => {
                          onCountyCodeChange(item.code);
                          data.countyCodeShow = false;
                        }}
                      >
                        <div class={style.withdrawSelectConItemName}>{item.name}</div>
                        <div class={style.withdrawSelectConItemText}>({item.code})</div>
                        <div class={style.withdrawSelectConItemSel}>
                          <SvgIcon
                            name={item.code === saveParams.countyCode ? "mangoSel" : "mangoNoSel"}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popup>

              {/*选择银行卡*/}
              <Popup
                position="bottom"
                v-model:show={data.bankListShow}
                round
                class={[popup.popupWidth100, style.withdrawSelect]}
              >
                <div class={[style.withdrawSelect]}>
                  <div class={popup.popupHeader}>
                    <div class={popup.popupHeaderLeft} onClick={() => (data.bankListShow = false)}>
                      {$t("common_cancel" /*取消*/)}
                    </div>
                    <div class={popup.popupHeaderMiddle}>
                      {$t("common_validator_bank" /* 选择银行卡 */)}
                    </div>
                    <div class={popup.popupHeaderRight} onClick={() => (data.bankListShow = false)}>
                      {$t("common_confirm" /*确定*/)}
                    </div>
                  </div>
                  <div class={style.withdrawSelectCon}>
                    {cardList.value?.B?.map(item => (
                      <div
                        class={style.withdrawSelectConItem}
                        onClick={() => {
                          clickCard(item);
                          data.bankListShow = false;
                        }}
                      >
                        <img src={item.icon || bank} />
                        <div class={style.withdrawSelectConItemName}>{item.bankName}</div>
                        <div class={style.withdrawSelectConItemName}>({item.cardNo})</div>
                        <div class={style.withdrawSelectConItemSel}>
                          <SvgIcon
                            name={item.id === saveParams.bankId ? "mangoSel" : "mangoNoSel"}
                          />
                        </div>
                      </div>
                    ))}
                    {addBtnShow.value && (
                      <div
                        class={style.withdrawSelectConAdd}
                        onClick={() =>
                          mangoPopup({
                            type: "MangoBankCardManage",
                            title: $t("funds_wallet_cardManager" /* 卡片管理 */),
                            borderRadius: "12",
                            props: { type: "B" },
                          }).open()
                        }
                      >
                        +{$t("funds_add_bank_tips" /* 添加银行卡 */)}
                      </div>
                    )}
                  </div>
                </div>
              </Popup>
              {/*选择虚拟币*/}
              <Popup
                position="bottom"
                v-model:show={data.usdtListShow}
                round
                class={[popup.popupWidth100, style.withdrawSelect]}
              >
                <div class={[style.withdrawSelect]}>
                  <div class={popup.popupHeader}>
                    <div class={popup.popupHeaderLeft} onClick={() => (data.usdtListShow = false)}>
                      {$t("common_cancel" /*取消*/)}
                    </div>
                    <div class={popup.popupHeaderMiddle}>
                      {$t("funds_choose_btc_address" /* 选择虚拟币地址 */)}
                    </div>
                    <div class={popup.popupHeaderRight} onClick={() => (data.usdtListShow = false)}>
                      {$t("common_confirm" /*确定*/)}
                    </div>
                  </div>
                  <div class={style.withdrawSelectCon}>
                    {cardList.value?.V?.map(item => (
                      <div
                        class={style.withdrawSelectConItem}
                        onClick={() => {
                          clickCard(item);
                          data.usdtListShow = false;
                        }}
                      >
                        <img src={item.icon || btn} />
                        <div class={style.withdrawSelectConItemName}>{item.currency}</div>
                        <div class={style.withdrawSelectConItemText}>({item.network})</div>
                        <div class={style.withdrawSelectConItemName}>({item.cardNo})</div>
                        <div class={style.withdrawSelectConItemSel}>
                          <SvgIcon
                            name={item.id === saveParams.bankId ? "mangoSel" : "mangoNoSel"}
                          />
                        </div>
                      </div>
                    ))}
                    {addBtnShow.value && (
                      <div
                        class={style.withdrawSelectConAdd}
                        onClick={() =>
                          mangoPopup({
                            type: "MangoBankCardManage",
                            title: $t("funds_wallet_cardManager" /* 卡片管理 */),
                            borderRadius: "12",
                            props: { type: "V" },
                          }).open()
                        }
                      >
                        +{$t("funds_virtual_title_tips" /* 添加虚拟币地址 */)}
                      </div>
                    )}
                  </div>
                </div>
              </Popup>
            </div>
          </Form>
        )}
        <MangoTipsModal type="info" {...tipModalData} v-model:show={tipModalData.show} />
        {/* 税号类型弹窗 */}
        <MangoDutyCodeSelect
          height={330}
          v-model:show={duty.visible}
          currency={saveParams.taxType}
          onChange={value => (saveParams.taxType = value)}
        />
      </>
    );
  },
});
