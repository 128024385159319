import { defineComponent, PropType, createVNode, ref } from "vue";
import { useI18nConfig } from ".";
import { parse, compile } from "./i18nUtils";

/**
 * Eg:
 * { user_login_login: 科比[0]库里[1]欧文[2] }
 * keyPath: 国际化key
 * tag: 渲染标签
 *
 * // 支持VNode渲染替换占位符
 * // 在插槽中按照 { $[对应key中的下标值]: () => VNode }
 * <I18n keyPath="user_login_login" tag="div" v-slots={{$0: (text) => (<span>{text}</span>)}}/>
 *
 * // 字符串参数替换
 * <I18n keyPath="user_login_login" tag="div" args={['替换值1','替换值2','替换值3']}/>
 *
 * // 无参数
 * <I18n keyPath="user_login_login"/>
 */
const _cachesI18nComponentToken = Object.create(null);

export default defineComponent({
  props: {
    keyPath: {
      type: String as PropType<string>,
      default: "",
    },
    tag: {
      type: String as PropType<string>,
      default: "",
    },
    args: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
  },
  setup(props, { slots }) {
    const rootTag = ref();
    const { langConfig } = useI18nConfig();
    const i18nText = langConfig.value?.[props.keyPath];

    const parse2compile = (data: any[]) => {
      if (!i18nText) return [];
      let tokens = _cachesI18nComponentToken[i18nText];
      if (!tokens) {
        tokens = parse(i18nText);
        _cachesI18nComponentToken[i18nText] = tokens;
      }
      return compile(tokens, data);
    };

    const render = () => {
      if (!i18nText) return "";
      const reg = /\[(.+?)\]|\{(.+?)\}/g;
      let childNode = [];
      const matchChars = i18nText?.match(reg);

      if (matchChars?.length) {
        const tagsContainer: any[] = [];
        matchChars.forEach((item: string, i: number) => {
          const slotKey = `$${i}`;
          if (item && slots?.[slotKey]) {
            const index = item.replace(/[\\[|\]|\\{|\\}]/g, "");
            tagsContainer.push(slots?.[slotKey]?.(index));
          }
        });

        if (tagsContainer.length) {
          childNode = parse2compile(tagsContainer);
        }
      }

      if (!childNode.length && props.args?.length) {
        childNode = parse2compile(props.args);
      }

      if (childNode?.length) {
        rootTag.value = props.tag ? createVNode(props.tag, null, childNode) : childNode;
      } else {
        rootTag.value = props.tag ? createVNode(props.tag, null, i18nText) : i18nText;
      }
    };

    render();

    return () => rootTag.value;
  },
});
