import { defineComponent, ref, PropType, onMounted, watch, computed } from "vue";
import { SyncOutlined } from "@ant-design/icons-vue";
import { useDebounceFn } from "@vueuse/core";
import { useConfig, useUser, useWallet, useI18n } from "core";
import RechargeView from "./Recharge";
import WithdrawView from "./Withdraw";

import style from "./Recharge.module.less";

export default defineComponent({
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    tab: {
      type: String as PropType<string>,
      default: "charge",
    },
  },
  emits: ["close"],
  setup(props, context) {
    const tabs = ref(props.tab);
    const { $t } = useI18n();
    // 配置中心
    const { config } = useConfig();
    // 用户信息hook
    const { user } = useUser();
    // 钱包相关
    const { AllBalance, platformBalance, reclaimloading, balancePageFormat } = useWallet();
    const updateBalance = useDebounceFn(platformBalance, 1000, { maxWait: 1000 });
    const balanceFormaObj = computed(() => balancePageFormat(AllBalance.value));
    watch(
      () => props.tab,
      val => {
        tabs.value = val;
      }
    );
    onMounted(() => {
      updateBalance();
    });
    return () => (
      <div class={style.wrapper}>
        <div class={style.wrapperHeader}>
          <div class={style.wrapperHeaderTabs}>
            <div class={style.wrapperHeaderTabsLeft}>
              <span
                class={
                  tabs.value == "charge"
                    ? style.wrapperHeaderTabsLeftChargeActivted
                    : style.wrapperHeaderTabsLeftCharge
                }
                onClick={() => (tabs.value = "charge")}
              >
                <span class={style.text}>{$t("funds_recharge_recharge") /* 充值 */}</span>
              </span>
              <span
                class={
                  tabs.value == "drawl"
                    ? style.wrapperHeaderTabsLeftDrawlActivted
                    : style.wrapperHeaderTabsLeftDrawl
                }
                onClick={() => (tabs.value = "drawl")}
              >
                <span class={style.text}>{$t("funds_wallet_withdraw") /* 提现 */}</span>
              </span>
            </div>
            <div class={style.wrapperHeaderTabsRight} v-show={tabs.value === "charge"}>
              <img src={config.flagIcons.currency[user.value?.currency as never]} width={24} />
              <span class={style.wrapperHeaderTabsRightAmount}>
                {balanceFormaObj.value.integer}
                {balanceFormaObj.value.decimal}
              </span>
              <span class={style.wrapperHeaderTabsRightUnit}>{user.value?.currency}</span>
              <SyncOutlined
                class={reclaimloading.value ? style.wrapperHeaderTabsRightSpin : ""}
                onClick={() => updateBalance()}
              />
            </div>
            {/*<div*/}
            {/*  class={style.wrapperHeaderTabsRight}*/}
            {/*  v-show={tabs.value === "drawl"}*/}
            {/*  onClick={() => goService()}*/}
            {/*>*/}
            {/*  <SvgIcon name="custom" width="24px" height="24px" color="@cms-mango-border-color" />*/}
            {/*</div>*/}
          </div>
        </div>
        <div class={style.wrapperContent}>
          {tabs.value === "charge" ? (
            <RechargeView onClose={() => context.emit("close")} />
          ) : (
            <WithdrawView onClose={() => context.emit("close")} />
          )}
        </div>
      </div>
    );
  },
});
