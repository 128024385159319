import { defineComponent, defineAsyncComponent, PropType } from "vue";
import { useI18n } from "core";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import components from "build/components";
const LoadingComponent = defineComponent({
  setup() {
    return () => (
      <div style={{ height: "50px", lineHeight: "50px", paddingTop: 10, textAlign: "center" }}>
        {window.DEBUG ? "Loading" : ""}
      </div>
    );
  },
});

const ErrorComponent = defineComponent({
  props: {
    componentName: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup(props) {
    const { $t } = useI18n();
    return () => (
      <div
        style={{
          height: "50px",
          color: "#ff7626",
          lineHeight: "50px",
          paddingTop: 10,
          textAlign: "center",
        }}
      >
        {$t("common_component_none", [`${props.componentName}`] /*[0]组件不存在*/)}
      </div>
    );
  },
});
export default defineComponent({
  props: {
    name: {
      type: String as PropType<string>,
      default: "",
    },
    required: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
  },
  setup(props) {
    const hasComponent = components[props.name];
    const ComponentNode = defineAsyncComponent({
      loader: async () => {
        return components[props.name]?.();
      },
      loadingComponent: LoadingComponent,
      errorComponent: ErrorComponent,
    });
    return () =>
      hasComponent ? (
        <ComponentNode data-name={props.name} {...props.required} />
      ) : (
        <ErrorComponent componentName={props.name} />
      );
  },
});
