import { defineComponent, PropType, ref, watch } from "vue";
import { useI18n, useConfigContext } from "core";
import css from "./Entry.module.less";
import { mangoPopup } from "components";
import { LeftOutlined } from "@ant-design/icons-vue";
// 账号登录
import LoginAccount from "./components/LoginAccount";
// 手机登录
import LoginPhone from "./components/LoginPhone";
// 账号注册
import RegisterAccount from "./components/RegisterAccount";
// 找回密码
import ForgetPassword from "./components/ForgetPassword";

// 0：账号，1：手机
export type subTabType = 0 | 1;
// 0: 不是忘记密码， 1：忘记密码第一步，2：忘记密码第二步，3：忘记密码第三步
export type forgetPasswordType = 0 | 1 | 2 | 3;

export default defineComponent({
  components: {
    LoginAccount,
    LoginPhone,
    RegisterAccount,
    ForgetPassword,
  },
  props: {
    dialogType: {
      type: Number as PropType<Number>,
      validator: (value: number) => [1, 2].includes(value),
      default: 1,
    },
  },
  setup(props, { attrs, slots, emit, expose }) {
    const { $t } = useI18n();
    const entryType = ref<Number>(props.dialogType);

    const { config: global } = useConfigContext();
    // 账号登录，手机登录
    const subTabs = ref<String[]>([
      $t("common_account" /*账户*/) + $t("user_login_login" /*登录*/),
    ]);
    // 子tabs，0：账号，1：手机
    const type = ref<subTabType>(0);
    // 忘记密码
    const forgetPasswordMode = ref<forgetPasswordType>(1);
    watch(
      () => global.smsSwitch,
      val => {
        if (val) {
          subTabs.value.push($t("user_login_phoneLogin" /*手机登录*/));
        } else if (subTabs.value.length > 1) {
          subTabs.value.splice(1);
        }
      },
      {
        immediate: true,
      }
    );
    // 弹窗类型变化时，充值子选项卡
    watch(
      () => props.dialogType,
      () => {
        type.value = 0;
        forgetPasswordMode.value = 0;
        entryType.value = props.dialogType;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => entryType.value,
      () => {
        type.value = 0;
        forgetPasswordMode.value = 0;
      },
      {
        immediate: true,
      }
    );
    // 切换登录注册
    const switchLoginOrRegister = (value: number) => {
      entryType.value = value;
    };
    // 切换验证方式
    const switchVerificationMode = (value: subTabType) => {
      type.value = value;
    };

    // 切换忘记密码
    const switchForgetPasswordMode = (value: forgetPasswordType) => {
      forgetPasswordMode.value = value;
    };

    const bgClass = {
      lam107: css.specialDialogLeftBg2,
      lam108: css.specialDialogLeftBg3,
      lam126: css.specialDialogLeftBg4,
      lam125: css.specialDialogLeftBg5,
      lam130: css.specialDialogLeftBg6,
      lam131: css.specialDialogLeftBg7,
      lam129: css.specialDialogLeftBg8,
      "int112-1": css.specialDialogLeftBg9,
    };

    return () => (
      <div class={css.specialDialogWrap}>
        <div class={`mango-special-dialog ${css.specialDialog}`} id="js_login">
          {/* 广告图 */}
          <div
            class={[css.specialDialogLeft, bgClass[window.CONFIG?.name] || css.specialDialogLeftBg]}
          />
          {/* 内容区 */}
          <div class={css.specialDialogRight}>
            <div class={css.specialDialogHeader}>
              <div
                class={css.specialDialogReturn}
                v-show={forgetPasswordMode.value !== 0}
                onClick={() => {
                  entryType.value = 1;
                  forgetPasswordMode.value = 0;
                }}
              >
                <LeftOutlined style="padding-right: 5px;" />
                {$t("user_login_back" /*返回*/)}
                {$t("user_login_login" /*登录*/)}
              </div>
              <div class={css.specialDialogTitle}></div>
              <div class={css.specialDialogClose} onClick={() => mangoPopup().destroyAll()}></div>
            </div>
            {/* 非忘记密码 */}
            <div class={css.specialDialogMain} v-show={forgetPasswordMode.value === 0}>
              <ul class={css.specialDialogTabs}>
                {[$t("user_login_login" /*登录*/), $t("user_login_register" /*注册*/)].map(
                  (item, index) => (
                    <li
                      onClick={() => (entryType.value = index + 1)}
                      class={[
                        css.specialDialogTab,
                        entryType.value === index + 1 ? css.specialDialogTabActive : "",
                      ]}
                    >
                      {item}
                    </li>
                  )
                )}
                <li
                  class={css.specialDialogTabActiveBg}
                  style={{ left: `${115 * (Number(entryType.value) - 1)}px` }}
                />
              </ul>
              {/* <ul class={css.specialDialogSubtabs} v-show={entryType.value === 1}>
                {global.smsSwitch &&
                  subTabs.value.map((item, index) => (
                    <li
                      onClick={() => (type.value = index as subTabType)}
                      class={`${css.specialDialogSubtab} ${
                        index === type.value ? css.specialDialogSubtabActive : ""
                      }`}
                      key={index}
                    >
                      {item}
                    </li>
                  ))}
              </ul> */}
              {/* 账号登录 */}
              {entryType.value === 1 && type.value === 0 ? (
                <LoginAccount
                  switchForgetPasswordMode={switchForgetPasswordMode}
                  switchLoginOrRegister={switchLoginOrRegister}
                />
              ) : null}
              {/* 手机登录 */}
              {entryType.value === 1 && type.value === 1 ? (
                <LoginPhone
                  switchVerificationMode={switchVerificationMode}
                  switchLoginOrRegister={switchLoginOrRegister}
                />
              ) : null}
              {/* 账号注册 */}
              {entryType.value === 2 ? (
                <RegisterAccount switchLoginOrRegister={switchLoginOrRegister} />
              ) : null}
            </div>
            {/* 忘记密码 */}
            {forgetPasswordMode.value !== 0 ? (
              <div>
                <div class={css.forgetPasswordHeader}>
                  {$t("user_findPassword_forgetPassword" /**忘记密码 */)}
                </div>
                <div class={css.forgetPasswordBody}>
                  <div class={css.forgetPasswordSteps}>
                    {[
                      $t("user_forgotpassword_title1" /*输入用户名*/) + ">",
                      $t("user_forgotpassword_title2" /*接受验证码*/) + ">",
                      $t("user_forgotpassword_title3" /*修改密码*/),
                    ].map((item, index) => (
                      <span
                        key={index}
                        style={{
                          color:
                            index + 1 === forgetPasswordMode.value
                              ? "@cms-mango-primary-color"
                              : "@cms-mango-text-color4",
                        }}
                      >
                        {item}
                      </span>
                    ))}
                    <ForgetPassword
                      forgetPasswordMode={forgetPasswordMode.value}
                      switchForgetPasswordMode={switchForgetPasswordMode}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
});
