import { defineComponent, reactive, onMounted, toRaw } from "vue";
import { Icon, Popup } from "vant";
import currency from "./currency.module.less";
import { useConfig, useI18n, useWallet, useConfigContext } from "core";

export default defineComponent({
  props: {
    currency: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change", "update:show"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { getCurrencyList } = useConfigContext();
    const { userWalletList, getUserWalletList } = useWallet();
    // 配置中心
    const { config } = useConfig();
    const data = reactive({
      show: true,
      active: "",
    });
    data.active = props.currency ? props.currency : data.active;
    // 确定
    const confrim = () => {
      emit("update:show", false);
      emit("change", toRaw(data.active));
    };
    // 选择货币事件
    const selectCurrency = (currency: string) => {
      data.active = currency;
      confrim();
    };
    // 初始化时判断是否能够获取用户货币
    onMounted(() => {
      if (!userWalletList.value || userWalletList.value?.length === 0) {
        getUserWalletList();
      }
    });
    return () => (
      <Popup
        v-model:show={props.show}
        round
        close-on-click-overlay
        onClose={() => emit("update:show", false)}
        position="bottom"
        lock-scroll={false}
        class={currency.currency}
      >
        <div class={currency.currencyHead}>
          <span class={currency.currencyHeadCancel} onClick={() => emit("update:show", false)}>
            {$t("common_cancel" /*取消*/)}
          </span>
          <span class={currency.currencyHeadTitle}>
            {$t("user_register_ToastTitle_currency" /*选择币种*/)}
          </span>
          <span class={currency.currencyHeadConfrim} onClick={() => confrim()}>
            {$t("common_confirm" /*确定*/)}
          </span>
        </div>
        <div class={currency.currencyCon}>
          {getCurrencyList.value?.map(item => (
            <div
              class={[
                currency.currencyConItem,
                item.code === data.active && currency.currencyConActive,
              ]}
              onClick={() => selectCurrency(item.code)}
            >
              <img
                class={currency.currencyConItemImg}
                src={config.flagIcons.currency[item.code as never]}
              />
              <span>
                {item.name}({item.code})
              </span>
              <div class={currency.currencyConItemCheck}>
                <Icon name="success" />
              </div>
            </div>
          ))}
        </div>
      </Popup>
    );
  },
});
