/**
 * feeback 意见反馈
 */

import { Api, useToast, useI18n, isMobile } from "core";
import { reactive, onMounted, computed } from "vue";
import { mangoMobileToast, mangoPopup } from "components";

interface FeedbackForm {
  title: string;
  userContent: string;
  userImgUrl?: any[];
  letterType: string;
}

export function useFeedback() {
  const { toast } = useToast();
  const { $t } = useI18n();
  const state = reactive({
    showQuestionPicker: false,
    selectQuestionLabel: "",
    loading: false,
    actionsTypes: [] as any[],
    previewImgs: [],
  });

  const form = reactive<FeedbackForm>({
    title: "",
    userContent: "",
    userImgUrl: [],
    letterType: "",
  });

  // 重置
  const reset = () => {
    state.loading = false;
    state.showQuestionPicker = false;
    state.selectQuestionLabel = "";
    form.userContent = "";
    form.userImgUrl = [];
    form.title = "";
    state.previewImgs = [];
    form.letterType = "";
  };

  // 按钮激活
  const btnActivated = computed(() => {
    const flag = form.userContent.length >= 5 && !!form.letterType && form.title.length >= 1;
    return !flag;
  });

  // 选择问题类型
  const selectFeedbackType = (value: any) => {
    if (!value) return;
    // 兼容pc
    if (typeof value === "string") {
      form.letterType = value;
    } else {
      form.letterType = value.value;
      state.selectQuestionLabel = value.name;
    }
  };

  // 获取问题类型列表
  const getTypeList = async () => {
    const { data, result } = await Api.FeedbackService.feedbackTypeList();
    if (result && Object.prototype.hasOwnProperty.call(data, "FEEDBACK_TYPE")) {
      const tempArr = data.FEEDBACK_TYPE;
      tempArr.map((e: { label: any; name: any }) => {
        e.label = e.name;
      });
      state.actionsTypes = tempArr;
      tempArr[0]?.value && selectFeedbackType(tempArr[0].value);
      tempArr[0]?.name && (state.selectQuestionLabel = tempArr[0].name);
    }
  };

  // 上传文件回调
  const afterUpload = async (file: any) => {
    file.status = "uploading";
    file.message = $t("mine_feedback_uploadIng" /*上传中...*/);
    const formData = new FormData();
    formData.append("files", file.file);
    const { data, result } = await Api.CommonService.upload(formData);
    if (result) {
      form.userImgUrl?.push(data[0].url);
      file.status = "success";
    } else {
      file.status = "failed";
      file.message = $t("mine_feedback_upload_fail" /*上传失败*/);
    }
  };

  // 上传前检验
  const beforeUpload = (file: { type: string; size: number }) => {
    const imgSchemes = ["image/jpeg", "image/png", "image/jpg"];
    if (!imgSchemes.includes(file.type)) {
      toast.error($t("mine_feedback_upload_imgRule" /*图片仅支持 jpg/jpeg/png!*/));
      return false;
    }
    if (file.size > 18 * 1000 * 1024) {
      toast.error($t("mine_feedback_upload_imgSizeRule" /*大小不要超过15M!*/));
      return false;
    }
    return true;
  };

  // 删除图片
  const deleteImg = (file: any) => {
    console.log(file);
  };

  // 提交问题
  const submitFeedback = async () => {
    const hideLoading = toast.loading();
    const imgUrl = form.userImgUrl && form.userImgUrl.length > 0 ? form.userImgUrl.join(",") : {};
    const { result, message } = await Api.FeedbackService.feedbackSubmit({
      ...form,
      userImgUrl: imgUrl,
    });
    hideLoading();
    if (result) {
      reset();
      if (window.CONFIG?.currentModel === "mango") {
        // isMobile ? mangoMobileToast({ type: "success", title: message }) : mangoPopup().close();
        mangoPopup().close();
      }
    }
  };

  onMounted(getTypeList);

  return {
    state,
    form,
    selectFeedbackType,
    submitFeedback,
    reset,
    deleteImg,
    afterUpload,
    beforeUpload,
    btnActivated,
  };
}
