import { defineComponent, onMounted, ref, PropType, watch, onUnmounted } from "vue";
import { useModal, useNotice, useAuth, useI18n, isMobile, useMessage } from "core";
import { useRafFn } from "@vueuse/core";
import { useRouter } from "vue-router";
import { NoticesItem } from "@kgsport-cms/service";
import { mangoPopup, TipsModal } from "components";
import noticeCon from "./notice.module.less";
import { nextTick } from "process";

export default defineComponent({
  props: {
    styleStr: {
      type: Object as PropType<any>,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const el = ref<HTMLElement | null>(null);
    const parentEl = ref<HTMLElement | null>(null);
    // 父元素的宽度
    const contentWidth = ref<number>(0);

    const { noticeStore, getNotice, records } = useNotice();
    const { store: messageStore } = useMessage();
    const router = useRouter();
    const { isLogin } = useAuth();
    let noticePauseCallback = () => {};
    let noticeResumeCallback = () => {};

    const noticeModal = useModal({
      content: (props: NoticesItem) => (
        <TipsModal
          cancelBtnText={$t("common_close" /*关闭*/)}
          confirmBtnText={$t("common_lookAll" /*查看全部*/)}
          contentText={props.summary}
          confimCallBack={() => {
            emit("close");
            const isMango = window.CONFIG?.currentModel === "mango";
            if (isLogin.value) {
              if (isMango) {
                if (isMobile) {
                  mangoPopup({
                    type: "MangoMessageH5",
                    header: false,
                    borderRadius: "12",
                    props: { type: "notice" },
                  }).open();
                } else {
                  mangoPopup({
                    type: "MangoMessage",
                    title: $t("mine_center_message" /*我得消息*/),
                    closeCallBack: () => {
                      messageStore.current = 1;
                      noticeStore.current = 1;
                      messageStore.messageList = [];
                    },
                  }).open();
                }
              } else {
                router.push("/account/message?type=0");
              }
            } else {
              if (isMango) {
                if (isMobile) {
                  mangoPopup({
                    type: "MangoEntryMobile",
                    props: { status: "login" },
                    header: false,
                    blurredBg: false,
                    borderRadius: "12",
                  }).open();
                } else {
                  mangoPopup({
                    type: "MangoEntry",
                    props: { dialogType: 1, header: false },
                  }).open();
                }
              } else {
                router.push("/entry/login");
              }
            }
          }}
        />
      ),
      header: true,
      zIndex: 1700,
    });
    const noticeAnimation = () => {
      if (el.value) {
        const $$noticeList = el.value;
        const w = $$noticeList?.getBoundingClientRect().width;
        let translateX = 0;
        const step = () => {
          translateX += 0.6;
          if (translateX >= w) {
            translateX = -contentWidth.value;
          }
          $$noticeList.style.transform = `translateX(${-translateX}px)`;
        };
        return useRafFn(step, { immediate: true });
      }
    };
    // 设置父元素的宽度
    const setContentWidth = () => {
      contentWidth.value = (parentEl.value as HTMLElement).getBoundingClientRect().width;
    };
    const resizeHander = () => {
      if (records.value) {
        noticePauseCallback && noticePauseCallback();
        setContentWidth();
      }
    };
    onMounted(() => {
      getNotice();
      nextTick(() => {
        setContentWidth();
      });
      window.addEventListener("resize", resizeHander);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resizeHander);
    });

    watch(
      () => records.value,
      async news => {
        if (news?.length) {
          //等待拉取时间
          await Promise.resolve(); //等待渲染时间
          const result = noticeAnimation(); //执行动画
          if (result) {
            noticePauseCallback = result?.pause;
            noticeResumeCallback = result?.resume;
          }
        }
      }
    );
    return () => (
      <div
        ref={parentEl}
        class={noticeCon.noticeConContent}
        style={props.styleStr}
        onMouseenter={() => noticePauseCallback()}
        onMouseleave={() => noticeResumeCallback()}
      >
        {records.value?.length ? (
          <div ref={el} class={noticeCon.noticeConContentList}>
            {records.value?.map((item, index) => (
              <div
                class={noticeCon.noticeConContentItem}
                onClick={() => noticeModal.open(item, { title: item.title })}
              >
                {item.title}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  },
});
