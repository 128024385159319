import { defineComponent, PropType, ref } from "vue";
import type { FormInstance } from "ant-design-vue";
import { Form, Input, Button } from "ant-design-vue";
import { useI18n, useForgotPass } from "core";
import css from "../../Entry.module.less";

import { forgetPasswordType } from "../../Entry";
export default defineComponent({
  props: {
    forgetPasswordMode: {
      type: Number as PropType<forgetPasswordType>,
      default: false,
    },
    switchForgetPasswordMode: Function as PropType<(value: forgetPasswordType) => void>,
  },
  setup(props) {
    const form = ref<FormInstance>();
    const { $t } = useI18n();
    const {
      count,
      userForm,
      resetForm,
      resetAccount,
      userAccount,
      submitForgotData,
      getPhoneInfoCode,
      getPhone,
    } = useForgotPass();

    const handlerEvent = async () => {
      const handlerArray = [
        async () => {
          await userAccount.validate("username");
          if (await getPhone()) props.switchForgetPasswordMode?.(2);
        },
        async () => {
          await resetAccount.validate("code");
          props.switchForgetPasswordMode?.(3);
        },
        async () => {
          await submitForgotData(true);
          props.switchForgetPasswordMode?.(0);
        },
      ];
      handlerArray[props.forgetPasswordMode - 1]();
    };

    return () => (
      <Form ref={form} style="margin-top: 30px">
        {props.forgetPasswordMode === 1 && (
          <Form.Item validateFirst={true} {...userAccount.validateInfos.username}>
            <Input
              size="large"
              v-model:value={userForm.username}
              placeholder={$t("user_findPassword_pleaseInputUserName") /*请输入用户名*/}
            />
          </Form.Item>
        )}
        {props.forgetPasswordMode === 2 && (
          <>
            {count.value > 0 && (
              <p class={css.forgetPasswordTips}>
                {$t(
                  "funds_withdraw_phoneMsg" /**已发送短信到您的[0]，请输入6位数验证码，如您未收到验证码，请联系7x24小时客服 */,
                  [`${userForm.dialCode} ${userForm.phone}`]
                )}
              </p>
            )}
            <Form.Item validateFirst={true} {...resetAccount.validateInfos.code}>
              <div class={css.loginPhoneCode}>
                <Input
                  size="large"
                  v-model:value={resetForm.code}
                  placeholder={$t("user_register_input_verifyCode" /**请输入手机验证码 */)}
                />
                <Button
                  type="link"
                  size="large"
                  class={css.loginPhoneCodeText}
                  onClick={getPhoneInfoCode}
                >
                  {count.value > 0
                    ? `${count.value}s`
                    : $t("common_send_verifyCode" /**发送验证码 */)}
                </Button>
              </div>
            </Form.Item>
          </>
        )}
        {props.forgetPasswordMode === 3 && (
          <>
            <Form.Item {...resetAccount.validateInfos.newPassword}>
              <Input.Password
                size="large"
                v-model:value={resetForm.newPassword}
                placeholder={$t("mine_userinfo_pleaseEnterNewPassword" /*请输入新密码*/)}
              />
            </Form.Item>
            <Form.Item {...resetAccount.validateInfos.confirmPassword}>
              <Input.Password
                size="large"
                v-model:value={resetForm.confirmPassword}
                placeholder={$t("mine_userinfo_pleaseConfirmNewPass" /*请确认新密码*/)}
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button onClick={handlerEvent} block class={css.specialDialogButton} size="large">
            {props.forgetPasswordMode === 3
              ? $t("mine_userinfo_confirmSubmission" /**确认提交 */)
              : $t("user_findPassword_nextStep" /**下一步 */)}
          </Button>
        </Form.Item>
      </Form>
    );
  },
});
