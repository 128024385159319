import type { ExtractPropTypes } from "vue";

const CONFIG_PROVIDER_KEY = "APP_CONFIG_PROVIDER";
// 体育provider
const SPORT_CONFIG_PROVIDER_KEY = "SPORT_CONFIG_PROVIDER_KEY";

// 配置参数
const configProviderProps: any = {
  /**
   * 主题色
   */
  themeColor: String,
  /**
   * 银行卡绑定数量
   */
  isUserBankBindCount: Number,
  /**
   * 虚拟账户绑定数量
   */
  isUserVirtualBindCount: Number,
  /**
   * app推广域名
   */
  appSpreadDomain: String,
  /**
   * vip显示
   */
  isEnableVip: Boolean,
  /**
   * 是否开启代理中心
   */
  isOpenProxy: Boolean,
  /**
   * 金额显示类型
   */
  amountShowType: String,
  /**
   * 短信开关
   */
  smsEnable: Boolean,
  /**
   * 客服链接
   */
  customerUrl: String,
  /**
   * 彩票聊天屎开关
   */
  cpChatEnable: Boolean,
  tsx: Object,
};

const globalStoreProviderProps: any = {
  /**
   * 消息未读数量
   */
  messageUnReadCount: Number,
  /**
   * 更新消息未读数量
   */
  updateMessageUnReadCount: Function,
  /**
   * 首页个人弹窗是否已弹出
   */
  indexSelfPopupStatus: Boolean,
  /**
   * 首页活动弹窗是否已弹出
   */
  indexActivityPopupStatus: Boolean,
  /**
   * 首页线路弹窗是否已弹出
   */
  indexLinePopupStatus: Boolean,
  /**
   * 更新弹窗状态
   */
  updatePopupStatus: Function,
};

const appProviderProps = { ...configProviderProps, ...globalStoreProviderProps };

type ConfigProviderPropsTypes = ExtractPropTypes<typeof appProviderProps>;

export {
  ConfigProviderPropsTypes,
  CONFIG_PROVIDER_KEY,
  SPORT_CONFIG_PROVIDER_KEY,
  appProviderProps,
};
