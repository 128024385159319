import { onMounted, Ref, ref, shallowRef } from "vue";
import { HttpResponse } from "@kgsport-cms/common";
import { noon } from "../common";

export interface UseAsyncOption {
  isLoading?: boolean;
  isMounted?: boolean;
  isLaborted?: boolean;
  cache?: () => string;
  isDisable: boolean;
}
export interface UseAsyncReturn<T> {
  loading: Ref<boolean>;
  error: Ref<T | undefined>;
  data: Ref<T | null>;
}
const store = Object.create(null);
export function useAsync<T = any>(
  cb: () => Promise<HttpResponse<T>>,
  options?: UseAsyncOption
): UseAsyncReturn<T> {
  const { isMounted = true, cache = noon, isDisable = true } = options || {};
  const loading = ref(false);
  const data = shallowRef<T | null>(null);
  const error = shallowRef();
  if (isDisable) {
    const cacheKey = cache();
    if (isMounted) {
      onMounted(async () => {
        if (loading.value) return;
        try {
          if (cacheKey && store[cacheKey]) {
            return (data.value = store[cacheKey]);
          }
          loading.value = true;
          const response = await cb?.();
          data.value = response?.data || null;
          if (cacheKey && data.value) {
            store[cacheKey] = data.value;
          }
        } catch (e) {
          console.error("useAsyncHook", e);
        } finally {
          loading.value = false;
        }
      });
    } else {
      cb?.()
        .then((response: any) => {
          data.value = response?.data || response;
        })
        .catch(err => {
          error.value = err;
        })
        .finally(() => {
          loading.value = false;
        });
    }
  }
  return {
    loading,
    data,
    error,
  };
}
