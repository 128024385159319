import { defineComponent } from "vue";
import { mangoPopup } from "components";
import styles from "./History.module.less";
import { useI18n } from "core";
// import { man } from 'components'
import { Table } from "ant-design-vue";

export default defineComponent({
  props: {
    list: {
      type: Array,
      defalut: () => [],
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const hide = () => {
      mangoPopup().destroyAll();
    };
    return () => (
      <div class={styles.historyWrapper}>
        <div class={styles.historyMask} onClick={hide}></div>
        <div class={styles.history}>
          <div class={styles.historyHeader}>
            <h1>{$t("game_cocos_history" /*历史*/)}</h1>
            <div class={styles.historyHeaderReturn} onClick={hide}>
              {$t("game_cocos_return" /*返回*/)}
            </div>
          </div>
          <div class={styles.historyBody}>
            <Table
              dataSource={props.list}
              pagination={{
                defaultPageSize: parseInt(((window.innerHeight - 300) / 55).toString()),
              }}
              columns={[
                {
                  title: "ID",
                  dataIndex: "orderid",
                  key: "orderid",
                  width: 50,
                },
                {
                  title: $t("game_cocos_date" /*日期*/),
                  dataIndex: "createdtime",
                  key: "createdtime",
                  width: 180,
                },
                {
                  title: $t("game_cocos_name" /*游戏名称*/),
                  dataIndex: "name",
                  key: "name",
                  width: 80,
                },
                {
                  title: $t("game_cocos_bet_amount" /*投注金额*/),
                  dataIndex: "betMoney",
                  key: "betMoney",
                  width: 80,
                },
                {
                  title: $t("game_cocos_win" /*获胜金额*/),
                  dataIndex: "showScroe",
                  key: "showScroe",
                  width: 80,
                },
                {
                  title: $t("game_cocos_status" /*状态*/),
                  dataIndex: "status",
                  key: "status",
                  width: 80,
                },
              ]}
            ></Table>
          </div>
        </div>
      </div>
    );
  },
});
