/**
 * 获取公告
 */
import { computed, reactive } from "vue";
import { Api, useConfig } from "core";
import { NoticesItem } from "@kgsport-cms/service";

type StoreSchema = {
  records: NoticesItem[];
  size: number;
  current: number;
  total: number;
  pages: number;
  noticeLoading: boolean;
};
const noticeStore = reactive<StoreSchema>({
  records: [],
  size: 10,
  current: 1,
  total: 0,
  pages: 0,
  noticeLoading: false,
});
export function useNotice() {
  const { isPc } = useConfig();
  const records = computed(() => noticeStore.records);
  const loading = computed(() => noticeStore.noticeLoading);
  const getNotice = async () => {
    const params = {
      current: noticeStore.current,
      size: noticeStore.size,
    };
    noticeStore.noticeLoading = true;
    const { data, result } = (await Api.CommonService.notice(params)) || {};
    noticeStore.noticeLoading = false;
    if (!result) return;
    noticeStore.current = data.current;
    noticeStore.total = data.total;
    noticeStore.size = data.size;
    noticeStore.pages = data.pages;
    if (isPc) {
      noticeStore.records = data.records;
    } else {
      noticeStore.records =
        data.current == 1 ? data.records : noticeStore.records.concat(data.records || []);
    }
  };
  // onMounted(async () => {
  //   await getNotice();
  // });
  return {
    getNotice,
    records,
    loading,
    noticeStore,
  };
}
