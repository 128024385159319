/**
 * 投注记录
 */

import { reactive, computed } from "vue";
import { Api, isMobile, useI18n, useLoading, useUser, useWallet } from "core";
import {
  BetOrdersPrams,
  RecordItemDatil,
  BetOrderData,
  ReportList,
  ReportItem,
  BetOrder,
} from "@kgsport-cms/service";
import dayjs from "dayjs";
import { useToast } from "./useToast.hook";
import { floor, round, ceil, get } from "lodash-es";
export interface ColumnItem {
  key: string;
  text: string;
  value: {
    beginTime: number;
    endTime: number;
  };
}
export interface CommonTypeItem {
  label: string;
  code: string;
  value?: any;
  index?: string;
  count?: number;
  sportVisibleEnum?: number[];
}

export interface OtherData {
  betAmount: number;
  validAmount: number;
  winAmount: number;
  betCount?: number;
  currency?: string;
}
export interface pageData {
  /**
   * 每页数量
   */
  size: number | null;
  /**
   * 总页数
   */
  pages: number;
  /**
   * 总条数
   */
  total: number;
  /**
   * 当前页
   */
  current: number;
}

export const PeriodI18n = {
  1: "common_jintian",
  2: "common_earlyHandicap",
  3: "common_handicap_live",
  4: "common_bet_stray",
};

// 结算状态
//注单状态 已结算 8:赢半 6:输半 7. won (赢)5. lose (输)9. draw (平)未结算2. running (进行中)1. waiting (等待)其他15. reject(拒绝)16. refund（退款）4. void (取消)
// 已结算 8.WinHalf(赢半) 6. LoseHalf(输半) 7. won (赢)5. lose (输)9. draw (平)
// 未结算 2. running (进行中) 1. waiting (等待)其他  15. reject(拒绝)  16. refund（退款） 4. void (取消)
export type SettlementType =
  | "won"
  | "lose"
  | "draw"
  | "running"
  | "waiting"
  | "reject"
  | "refund"
  | "void";

// 结算状态枚举
export enum SettlementEnum {
  waiting = 1,
  running = 2,
  void = 4,
  lose = 5,
  LoseHalf = 6,
  won = 7,
  WinHalf = 8,
  draw = 9,
  reject = 15,
  refund = 16,
}

// 是否结算(是否是可赢额)
export const isSettlement = (ticketStatus: number) =>
  ![
    SettlementEnum.running,
    SettlementEnum.waiting,
    SettlementEnum.reject,
    SettlementEnum.refund,
    SettlementEnum.void,
  ].includes(ticketStatus);

export const SettlementList: CommonTypeItem[] = [
  {
    label: "game_bet_loseHalf" /*"输半"*/,
    code: "LoseHalf",
    value: SettlementEnum.LoseHalf,
  },
  {
    label: "game_bet_winHalf" /*"赢半"*/,
    code: "WinHalf",
    value: SettlementEnum.WinHalf,
  },
  {
    label: "game_bet_won" /*"胜"*/,
    code: "won",
    value: SettlementEnum.won,
  },
  {
    label: "game_bet_lost" /*"输"*/,
    code: "lose",
    value: SettlementEnum.lose,
  },
  {
    label: "game_bet_draw" /*"和"*/,
    code: "draw",
    value: SettlementEnum.draw,
  },
  {
    label: "game_bet_betSucced" /*"投注成功"*/,
    code: "running",
    value: SettlementEnum.running,
  },
  {
    label: "game_bet_waiting" /*"待确认"*/,
    code: "waiting",
    value: SettlementEnum.waiting,
  },
  {
    label: "game_bet_reject" /*"投注拒绝"*/,
    code: "reject",
    value: SettlementEnum.reject,
  },
  {
    label: "game_bet_refund" /*"退款"*/,
    code: "refund",
    value: SettlementEnum.refund,
  },
  {
    label: "common_cancel" /*"取消"*/,
    code: "void",
    value: SettlementEnum.void,
  },
];

export function sportCurrency(
  value: string | number | undefined,
  symbol = "",
  decimals = 2,
  options?: {
    thousandsSeparator?: any;
    symbolOnLeft?: any;
    spaceBetweenAmountAndSymbol?: any;
    type?: any;
    decimalSeparator?: any;
  }
) {
  let thousandsSeparator = "";
  let symbolOnLeft = "";
  let spaceBetweenAmountAndSymbol = "";
  const digitsRE = /(\d{3})(?=\d)/g;
  options = options || {};

  const newValue = value ? parseFloat(value as string) : 0;
  if (!isFinite(newValue) || (!newValue && newValue !== 0)) return "";

  thousandsSeparator = options.thousandsSeparator != null ? options.thousandsSeparator : ",";

  symbolOnLeft = options.symbolOnLeft != null ? options.symbolOnLeft : true;

  spaceBetweenAmountAndSymbol =
    options.spaceBetweenAmountAndSymbol != null ? options.spaceBetweenAmountAndSymbol : false;

  let stringified = floor(Math.abs(newValue), decimals).toFixed(decimals);
  if (options.type && options.type === 1) {
    // type 等于1表示进行四舍五入 2表示无条件进位  默认情况为无条件舍弃
    stringified = round(Math.abs(newValue), decimals).toFixed(decimals);
  } else if (options.type && options.type === 2) {
    stringified = ceil(Math.abs(newValue), decimals).toFixed(decimals);
  }

  stringified = options.decimalSeparator
    ? stringified.replace(".", options.decimalSeparator)
    : stringified;

  const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;

  const i = _int.length % 3;

  const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? thousandsSeparator : "") : "";

  const _float = decimals ? stringified.slice(-1 - decimals) : "";

  symbol = spaceBetweenAmountAndSymbol ? (symbolOnLeft ? `${symbol} ` : ` ${symbol}`) : symbol;

  symbol = symbolOnLeft
    ? symbol + head + _int.slice(i).replace(digitsRE, `$1${thousandsSeparator}`) + _float
    : head + _int.slice(i).replace(digitsRE, `$1${thousandsSeparator}`) + _float + symbol;

  const sign = newValue < 0 ? "-" : "";

  return sign + symbol;
}
export enum MarketType {
  /**
   * 马来盘
   */
  Malay = 1,
  /**
   * 香港盘
   */
  HongKong = 2,
  /**
   * 欧洲盘
   */
  Europe = 3,
  /**
   * 印度尼西亚盘
   */
  Indonesia = 4,
  // /**
  //  * 美国盘
  //  */
  // America = 5
}
// 盘口列表
export const MarketTypeList: any[] = [
  {
    label: "game_setting_europe",
    code: "Europe",
    value: MarketType.Europe,
  },
  {
    label: "game_setting_hk",
    code: "HongKong",
    value: MarketType.HongKong,
  },
  // {
  //   label: "game_setting_america",
  //   code: 'America',
  //   value: MarketType.America,
  //   display: false
  // },
  {
    label: "game_setting_maly",
    code: "Malay",
    value: MarketType.Malay,
  },
  {
    label: "game_setting_Indonesia",
    code: "Indonesia",
    value: MarketType.Indonesia,
  },
];
/**
 * Parlay Combo Type (串关组合列表)
 */
export const ParlayComboType = [
  {
    label: "game_play_name_twoOfone", // 2串1
    code: "Doubles",
    value: 0,
  },
  {
    label: "game_play_name_threeOfone", // 3串1
    code: "Trebles",
    value: 1,
  },
  {
    label: "game_play_name_threeOfone", // 3串1
    code: "Treble",
    value: 1,
  },
  {
    label: "game_play_name_threeOffour", // 3串4
    code: "Trixie",
    value: 2,
  },
  {
    label: "game_play_name_lucky7", // 幸运7
    code: "Lucky7",
    value: 3,
  },
  {
    label: "game_play_name_fourOfone", // 4串1
    code: "Fold4",
    value: 4,
  },
  {
    label: "game_play_name_fourOfone", // 4串1
    code: "4-Fold",
    value: 4,
  },
  /*{
    label: "game_play_name_yankees", // 洋基
    code: "Yankee",
    value: 5,
  },*/
  {
    label: "game_play_name_lucky15", // 幸运15
    code: "Lucky15",
    value: 6,
  },
  {
    label: "game_play_name_fiveOfone", // 5串1
    code: "Fold5",
    value: 7,
  },
  {
    label: "game_play_name_fiveOfone", // 5串1
    code: "5-Fold",
    value: 7,
  },
  {
    label: "game_play_name_canadian", // 超级美国佬
    code: "Canadian",
    value: 8,
  },
  {
    label: "game_play_name_lucky31", // 幸运 31
    code: "Lucky31",
    value: 9,
  },
  {
    label: "game_play_name_sixOfone", // 6串1
    code: "Fold6",
    value: 10,
  },
  {
    label: "game_play_name_sixOfone", // 6串1
    code: "6-Fold",
    value: 10,
  },
  /*{
    label: "game_play_name_heinz", // 亨氏
    code: "Heinz",
    value: 11,
  },*/
  {
    label: "game_play_name_lucky63", // 幸运 63
    code: "Lucky63",
    value: 12,
  },
  {
    label: "game_play_name_sevenOfone", // 7串1
    code: "Fold7",
    value: 13,
  },
  {
    label: "game_play_name_sevenOfone", // 7串1
    code: "7-Fold",
    value: 13,
  },
  /*{
    label: "game_play_name_superHeinz", // 超级亨氏
    code: "SuperHeinz",
    value: 14,
  },*/
  {
    label: "game_play_name_lucky127", // 幸运 127
    code: "Lucky127",
    value: 15,
  },
  {
    label: "game_play_name_eightOfone", // 8串1
    code: "Fold8",
    value: 16,
  },
  {
    label: "game_play_name_eightOfone", // 8串1
    code: "8-Fold",
    value: 16,
  },
  /*{
    label: "game_play_name_goliath", // 大亨
    code: "Goliath",
    value: 17,
  },*/
  {
    label: "game_play_name_lucky255", // 幸运255
    code: "Lucky255",
    value: 18,
  },
  {
    label: "game_play_name_nineOfone", // 9串1
    code: "Fold9",
    value: 19,
  },
  {
    label: "game_play_name_nineOfone", // 9串1
    code: "9-Fold",
    value: 19,
  },
  {
    label: "game_play_name_tenOfone", // 10串1
    code: "Fold10",
    value: 20,
  },
  {
    label: "game_play_name_tenOfone", // 10串1
    code: "10Fold",
    value: 20,
  },
  {
    label: "game_play_name_tenOfone", // 10串1
    code: "10-Fold",
    value: 20,
  },
  {
    label: "game_play_name_elevenOfone", // 11串1
    code: "Fold11",
    value: 21,
  },
];

export const ParlayComboConnectType = [
  {
    label: `7串120`, // (7 串 120)
    code: "SuperHeinz",
  },
  {
    label: `8串247`, // (8 串 247)
    code: "Goliath",
  },
  {
    label: `9串502`, // (9 串 502)
    code: "Block9",
  },
  {
    label: `10串1013`, // (10 串 1013)
    code: "Block10",
  },
  {
    label: `2串1`, // (2 串 1)
    code: "Doubles",
  },
  {
    label: `3串1`, // (3 串 1)
    code: "Trebles",
  },
  {
    label: `4串1`, // (4 串 1)
    code: "FourFolds",
  },
  {
    label: `5串1`, // (5 串 1)
    code: "FiveFolds",
  },
  {
    label: `6串1`, // (6 串 1)
    code: "SixFolds",
  },
  {
    label: `7串1`, // (7 串 1)
    code: "SevenFolds",
  },
  {
    label: `8串1`, // (8 串 1)
    code: "EightFolds",
  },
  {
    label: `9串1`, // (9 串 1)
    code: "NineFolds",
  },
  {
    label: `10串1`, // (10 串 1)
    code: "TenFolds",
  },
  {
    label: `3串4`, // (3 串 4)
    code: "Trixie",
  },
  {
    label: `5串26`, // (5 串 26)
    code: "SuperYankee",
  },
  {
    label: `6串57`, // (6 串 57)
    code: "Heinz",
  },
  {
    label: `4串11`, // (4 串 11)
    code: "Yankee",
  },
];

// 体育、游戏初始化&&动态model数据
const betStore = reactive<{
  betSportData: BetOrderData | any | null;
  betRecordData: RecordItemDatil | any | null;
  pageData: pageData | any | null;
  betReportData: ReportList | any | null;
  detailItem: ReportItem | any | null;
  loading: boolean;
  detailShow: boolean;
  currTabKey: number;
  sportTabKey: number | null;
  currGameName: string;
  current_settleTab_index: number;
}>({
  betRecordData: {},
  betSportData: {
    page: {
      records: [],
    },
  },
  pageData: {
    current: 1,
    size: 20,
    total: 0,
  },
  betReportData: {},
  detailItem: {},
  loading: false,
  detailShow: false,
  currTabKey: window.CONFIG?.name?.includes?.("int132") ? 1 : 0,
  sportTabKey: null,
  currGameName: "",
  current_settleTab_index: 0,
});
// 体育投注参数
const form = reactive<BetOrdersPrams>({
  current: 1,
  size: 10,
  gameKind: "",
  currency: "",
  status: null,
  endTime: 0,
  beginTime: 0,
});

export function useBetRecord() {
  const { user } = useUser();
  const { $t } = useI18n();
  const { amountFormat } = useWallet();
  const loadingModel = useLoading();
  // 时间数据
  const dateList = [
    {
      key: "today",
      text: $t("common_jintian"),
      value: {
        beginTime: dayjs().startOf("day").valueOf(),
        endTime: dayjs().valueOf(),
      },
    },
    {
      key: "yesterday",
      text: $t("common_yesterday"),
      value: {
        beginTime: dayjs().startOf("day").subtract(1, "days").valueOf(),
        endTime: dayjs().startOf("day").valueOf(),
      },
    },
    {
      key: "last7days",
      text: $t("common_last7days"),
      value: {
        beginTime: dayjs().startOf("day").subtract(7, "days").valueOf(),
        endTime: dayjs().valueOf(),
      },
    },
    {
      key: "last30days",
      text: $t("common_last30days"),
      value: {
        beginTime: dayjs().startOf("day").subtract(29, "day").valueOf(),
        endTime: dayjs().valueOf(),
      },
    },
  ];
  // tab数据
  const tabData = [
    // { text: $t("mine_bet_sportRecord" /* 体育记录 */), key: 0 },
    { text: $t("mine_bet_gameRecord" /* 游戏记录 */), key: 1 },
  ].filter(Boolean);
  if (!window.CONFIG?.name?.includes("int132")) {
    // int132目前没有原生体育注单，暂时隐藏，bug号【36610】
    tabData.unshift({ text: $t("mine_bet_sportRecord" /* 体育记录 */), key: 0 });
  }
  // 结算状态
  const settleTab = [
    {
      label: $t("common_all" /*全部*/),
      value: null,
    },
    {
      label: $t("mine_bet_noSettlemen" /*未结算*/),
      value: 0,
    },
    {
      label: $t("mine_bet_haveAlreadySettled" /*已结算*/),
      value: 1,
    },
  ];
  // 结算枚举定义
  const settleEnum = {
    "0": $t("mine_bet_noSettlemen" /*未结算*/),
    "1": $t("mine_bet_haveAlreadySettled" /*已结算*/),
    "-1": $t("mine_bet_cancel" /*已取消*/),
  };
  // 输赢枚举定义
  const winEnum = [
    $t("game_bet_lost" /*输*/),
    $t("game_bet_won" /*赢*/),
    $t("game_bet_draw" /*平*/),
  ];
  // 游戏注单详情页初始化参数 type兼容mango弹窗关闭事件
  const initParam = (type?: string) => {
    if (type) {
      form.endTime = "";
      form.beginTime = "";
      form.currency = "";
    }
    form.endTime = form.endTime || dateList[0].value.endTime;
    form.beginTime = form.beginTime || dateList[0].value.beginTime;
    form.gameKind = betStore.detailItem.gameKind || "";
    form.currency = form.currency || betStore.detailItem.currency || user.value?.currency;
  };
  initParam();
  const state = reactive({
    pullLoading: false, // 下拉加载请求
    pullUpLoading: false, // 上拉加载, H5专用
    pages: 10,
    activeTab: 0,
    avtiveFilterDate: 0,
    filterVisible: false,
    finished: false,
    btnType: "sports",
    btnTypeList: [
      {
        label: "mine_device_sport", // 体育
        value: "sports",
      },
      {
        label: "common_game", // 游戏
        value: "games",
      },
    ],
  });
  // 动态计算游戏投注记录数据
  const betReportData = computed(() => betStore.betReportData);
  // 获取游戏详情记录数据
  const betDetailData = computed(() => betStore.betRecordData);
  // 获取体育数据
  const betSportData = computed(() => betStore.betSportData);
  // const { toast } = useToast();
  /**
   * 获取体育投注记录
   */
  const getSportRecordData = async () => {
    form.currency = form.currency || user.value?.currency;
    form.current = betStore.pageData.current || 1;
    form.size = betStore.pageData.size || 20;
    form.gameKind = "IM_SPORT";
    isMobile && loadingModel.show();
    betStore.loading = true;
    try {
      const { result, data } = await Api.SportBetRecordsService.orders(form);
      loadingModel.hide();
      betStore.loading = false;
      if (result) {
        // h5列表数据拼接
        if (isMobile) {
          const records = betStore.betSportData.page?.records ?? [];
          if (data.page) {
            data.page.records = [
              ...(data.page?.current !== 1 ? records : []),
              ...(data.page?.records || []),
            ];
          }
          betStore.betSportData = data || {};
        } else {
          betStore.betSportData = data || {};
        }
        betStore.pageData = {
          size: get(data, "page.size"),
          pages: get(data, "page.pages"),
          total: get(data, "page.total"),
          current: get(data, "page.current"),
        };
      }
    } catch (e) {
      loadingModel.hide();
      console.log("获取体育注单失败", e);
    }
  };

  /**
   * 获取一级游戏投注记录
   */
  const getBetReport = async () => {
    isMobile && loadingModel.show();
    betStore.loading = true;
    try {
      const params = {
        endTime: form.endTime,
        beginTime: form.beginTime,
        currency: form.currency,
      };
      const { result, data } = await Api.RecordService.betReport(params);
      betStore.loading = false;
      loadingModel.hide();
      if (result) {
        betStore.betReportData = data || {};
        // 测试提的BUG，过滤掉IM
        betStore.betReportData.records = betStore.betReportData.records.filter(
          (item: ReportItem) => item.gameKind !== "IM_SPORT"
        );
      }
    } catch (e) {
      loadingModel.hide();
      console.error("获取一级游戏数据失败", e);
    }
  };

  /**
   * 获取二级游戏投注记录
   */
  const getRecordData = async () => {
    form.currency = form.currency || user.value?.currency;
    form.current = betStore.pageData.current || 1;
    form.size = betStore.pageData.size;
    betStore.loading = true;
    const { result = false, data } = (await Api.RecordService.betRecord(form)) ?? {};
    betStore.loading = false;
    if (result) {
      betStore.betRecordData = data;
      betStore.pageData = {
        size: data.page.size,
        pages: data.page.pages,
        total: data.page.total,
        current: data.page.current,
      };
    }
  };
  // 重置分页参数
  const resetPage = () => {
    betStore.pageData = {
      current: 1,
      size: 20,
      total: 0,
    };
  };
  // 注单状态改变回调
  const onTabChange = async (index: number) => {
    betStore.current_settleTab_index = index;
    form.status = settleTab[index].value;
    resetPage();
    await getSportRecordData();
    // form.status = null;
  };
  // 时间改变后
  const pickerChange = (item: ColumnItem) => {
    form.endTime = item.value.endTime;
    form.beginTime = item.value.beginTime;
    resetPage();
    // 判断加载那个接口，1:只加载体育注单接口
    if (betStore.currTabKey === 0) {
      getSportRecordData();
    } else {
      // 判断是否为游戏注单详情或者注单列表
      betStore.detailShow ? getRecordData() : getBetReport();
    }
  };
  // 币种切换回调
  const handleSelect = (currency: string) => {
    form.currency = currency;
    resetPage();
    // 判断加载那个接口，0:只加载体育注单接口
    if (betStore.currTabKey === 0) {
      getSportRecordData();
    } else {
      // 判断是否为游戏注单详情或者注单列表
      betStore.detailShow ? getRecordData() : getBetReport();
    }
  };
  // 游戏注单详情返回回调(重置参数)
  const backHandle = () => {
    betStore.detailShow = false;
    betStore.detailItem = {};
    betStore.betRecordData = {};
  };
  // 导航tab切换
  const changeTab = (index: number) => {
    betStore.currTabKey = index;
    resetPage();
    backHandle();
    // web端兼容逻辑
    if (!isMobile) {
      betStore.currTabKey === 0 ? getSportRecordData() : getBetReport();
    }
  };

  // 串关可赢额计算
  const getPayoutAmount = (data: Array<{ [key: string]: any }>): string => {
    return amountFormat(
      Number(data?.find?.(d => d.exculding?.payoutAmount)?.exculding?.payoutAmount ?? 0)
    );
  };

  // 根据comboType转翻译
  const mulTypeToName = (mulType: string) => {
    const label = ParlayComboType.find?.(d => d.code === mulType)?.label;
    if (label) return $t(label);
    const label2 = ParlayComboConnectType.find?.(d => d.code === mulType)?.label;
    if (label2) return label2.replace("串", $t("game_match_link"));
    return "";
  };

  return {
    onTabChange,
    getBetReport,
    getRecordData,
    getSportRecordData,
    pickerChange,
    handleSelect,
    backHandle,
    settleTab,
    tabData,
    settleEnum,
    winEnum,
    dateList,
    state,
    betDetailData,
    betReportData,
    betSportData,
    form,
    betStore,
    changeTab,
    getPayoutAmount,
    mulTypeToName,
    initParam,
  };
}
