import { defineComponent, ref, computed } from "vue";
import styles from "./Rule.module.less";
import { mangoPopup } from "components";
import { Image } from "vant";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useI18n, useUser } from "core";
import { useCocos } from "core/game";

export default defineComponent({
  props: {
    type: String,
    gameParams: Object,
  },
  setup(props) {
    const { currencySymbol } = useUser();
    const { $t } = useI18n();
    const { rules } = useCocos();
    const swiper = ref<any>(null);
    // 类型
    const type = ref<number>(0);
    // 当前滚动屏幕
    const currentPage = ref<number>(0);

    // 当前规则数据
    const currentRules = computed(() => rules.value[props.type as string] || []);

    const onSwiper = (e: any) => {
      swiper.value = e;
    };

    const changeHandler = () => {
      if (currentPage.value < currentRules.value.length - 1 && type.value === 0) {
        swiper.value.slideNext();
      } else {
        mangoPopup().destroyAll();
      }
    };
    return () => (
      <div class={styles.ruleWrapper}>
        <div class={["rule-animate", styles.rule]}>
          <div class={styles.ruleHeader}>
            {[$t("game_cocos_rule_title" /*游戏规则*/), $t("game_cocos_restriction" /*限制*/)].map(
              (item, index) => (
                <button
                  class={[styles.ruleButton, index === type.value ? styles.ruleButtonActive : ""]}
                  key={index}
                  onClick={() => (type.value = index)}
                >
                  {item}
                </button>
              )
            )}
          </div>
          <div class={styles.ruleBody}>
            <div class={styles.ruleCarousel} v-show={type.value === 0}>
              <div class={styles.ruleCarouselWrapper}>
                <Swiper
                  modules={[Pagination]}
                  autoHeight
                  initialSlide={0}
                  onSlideChange={e => {
                    currentPage.value = e.activeIndex;
                  }}
                  onSwiper={onSwiper}
                  pagination={{
                    clickable: true,
                  }}
                >
                  {currentRules.value.map((item: any, index: number) => (
                    <SwiperSlide key={index} style="padding-bottom:20px">
                      <Image style="width:100%;border-radius: 5px;overflow:hidden" src={item.url} />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <p class={styles.ruleInfo}>
                  {currentRules.value && currentRules.value.length
                    ? currentRules.value[currentPage.value]?.info
                    : ""}
                </p>
              </div>
            </div>
            <ul class={styles.ruleRestriction} v-show={type.value === 1}>
              <li>
                <p>{$t("game_cocos_bet_maximum" /*最大赌注*/)}</p>
                <p>
                  {currencySymbol.value} {props.gameParams?.maximumBet || 0}
                </p>
              </li>
              <li>
                <p>{$t("game_cocos_payment_maximum" /*最高付款额*/)}</p>
                <p>
                  {currencySymbol.value} {props.gameParams?.maximumPayment || 0}
                </p>
              </li>
            </ul>
          </div>
          <div class={styles.ruleFooter}>
            <button class={[styles.ruleButton, styles.ruleButtonActive]} onClick={changeHandler}>
              {type.value === 0 && currentPage.value < currentRules.value?.length - 1
                ? $t("game_cocos_next" /*下一个*/)
                : $t("game_cocos_start" /*开始游戏*/)}
            </button>
          </div>
        </div>
      </div>
    );
  },
});
