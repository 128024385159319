import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import style from "./style.module.less";
import Button from "../components/Button";
import { mangoPopup } from "components";
import { useI18n, useWithdraw, useUser, useToast, saveParams } from "core";
import popup from "templates/mango/Mobile/popup.module.less";
import { SvgIcon } from "commom";

export default defineComponent({
  name: "MobileWithdrawalVerification",
  setup() {
    const router = useRouter();
    const { $t } = useI18n();
    const { save, saveOrder } = useWithdraw();
    const { user, count, getSmsCode } = useUser();
    const { toast } = useToast();
    const isFocus = ref<boolean>(false);
    const send = async () => {
      await getSmsCode({ type: 3 });
    };

    const loading = ref(false);
    // 提交
    const submit = async () => {
      if (loading.value) return;
      if (!saveParams.validateCode) {
        toast.error($t("user_register_input_verifyCode" /*请输入验证码*/));
        return;
      }
      if (String(saveParams.validateCode).length !== 6) {
        toast.error($t("common_validator_verifyCode_rule" /*请输入正确的6位验证码*/));
        return;
      }
      loading.value = true;
      const res = await save();
      loading.value = false;
      if (!res) return;
      await router.replace(
        `withdrawalApplySuccess?type=${saveParams.withdrawType}&cashOrderNo=${
          saveOrder.value?.cashOrderNo ?? ""
        }`
      );
    };

    const input = (e: Event) => {
      const target = e.target as HTMLInputElement;
      target.value = target.value.replace(/\D/g, "");
    };
    const focusInput = () => {
      isFocus.value = true;
    };
    const blurInut = () => {
      isFocus.value = false;
    };
    return () => (
      <div class={style.page}>
        <div class={[popup.popupHeader, popup.popupHeaderBorder]}>
          <section class={popup.popupHeaderLeft}></section>
          <section class={popup.popupHeaderMiddle}>
            {$t("funds_withdraw_smsVerify" /*提现短信验证*/)}
          </section>
          <section class={popup.popupHeaderRight} onClick={() => mangoPopup().destroyAll()}>
            <SvgIcon class={popup.popupHeaderIcon} name="close" width="0.48rem" height="0.48rem" />
          </section>
        </div>
        <section class={style.verify}>
          <input
            v-model={saveParams.validateCode}
            class={isFocus.value ? [style.focusinput, style.input] : style.input}
            maxlength="6"
            type="number"
            placeholder={$t("mine_usersetup_verificationPhoneCodePoint") /* 请输入验证码 */}
            onInput={input}
            onFocus={focusInput}
            onBlur={blurInut}
          />
          {!count.value ? (
            <article class={style.sendCode} onTouchend={send}>
              {$t("user_login_sendVerificationCode") /* 发送验证码 */}
            </article>
          ) : (
            <article class={style.time}>{count.value}s</article>
          )}
        </section>
        <div class={style.content}>
          <section class={style.msg}>
            {$t(
              "funds_withdraw_phoneMsg",
              [
                `${user?.value?.dialCode ?? ""} ${user?.value?.phone ?? ""}`,
              ] /* 已发送短信到您的[0]，请输入6位数验证码，如您未收到验证码，请联系7x24小时客服 */
            )}
          </section>
          <Button loading={loading.value} onClick={submit}>
            {$t("funds_withdraw_comfirn") /* 提交申请 */}
          </Button>
        </div>
      </div>
    );
  },
});
