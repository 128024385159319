import { defineComponent, Fragment, onMounted, PropType, reactive, ref } from "vue";
import { Form, Button, Input, Checkbox } from "ant-design-vue";
import { FormItemOption, useRegister, useI18n, useUser, useConfig, useCommon } from "core";
import { DownOutlined, UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import css from "../../Entry.module.less";
import { forgetPasswordType } from "../../Entry";
import { IDialCode } from "@kgsport-cms/service";
import { SvgIcon } from "commom";

// 其他登录方式
import LoginOther from "../LoginOther";
// 电话区域弹窗
import MangoAreCodeSelect from "templates/mango/Web/components/AreCodeSelect";
// 语言或者币种弹窗
import LangueOrCurrencySelect from "templates/mango/Web/components/LangueOrCurrencySelect";
// 税号弹窗
import MangoDutyCodeSelect from "templates/mango/Web/components/DutyCodeSelect";

export default defineComponent({
  components: {
    MangoAreCodeSelect,
    LoginOther,
  },
  props: {
    switchLoginOrRegister: Function as PropType<(value: number) => void>,
    switchForgetPasswordMode: Function as PropType<(value: forgetPasswordType) => void>,
  },
  setup(props) {
    const { $t } = useI18n();
    const {
      formConfig,
      modelRef,
      validateInfos,
      loading,
      openGeetestVerifier,
      currentCurrencyText,
      phoneOption,
      dataStore,
      currentCurrency,
      clickCurrencyLists,
      clickLangueLists,
      emailOption,
      count: emailCount,
      getEmailCode: registerEmailCode,
      getCaptchaCode,
      agreement,
      dutyFields,
    } = useRegister();
    const { openCaptcha, count } = useUser();
    const { getCurrencyList, config, getLangueList, getDialCode } = useConfig();
    // 图形验证码
    const { captchaImg } = useCommon();
    // 弹窗需要的数据
    const popup = reactive({
      // 区域弹窗显示
      areCodeSelect: false,
      // 是否语言弹窗
      isLangue: false,
      // 语言|币种弹窗显示
      langueOrcurrencyVisible: false,
      // 税号弹窗
      duty: false,
    });
    /**
     * 区域选择下拉选中数据变化执行函数
     * @param option 选中数据项
     */
    const changeAreCode = (option: IDialCode) => {
      modelRef.dialCode = option.code;
    };

    /**
     * 区域选择下拉选中数据变化执行函数
     * @param option 选中数据项
     */
    const changeDutyCode = (value: string) => {
      modelRef[dutyFields.dutyCode] = value;
    };

    const sendCode = () => {
      if (count.value > 0) return;
      openCaptcha({
        type: 1,
        phone: modelRef.phone as string,
        dialCode: modelRef.dialCode as string,
      });
    };

    const sendEmailCode = () => {
      if (emailCount.value > 0) return;
      registerEmailCode({ email: modelRef.email });
    };

    // 语言|币种变化时
    const changeHander = (item: any) => {
      if (popup.isLangue) {
        clickLangueLists(item);
      } else {
        clickCurrencyLists(item);
      }
    };

    onMounted(() => {
      getDialCode();
      window.CONFIG.isShowImgCode && getCaptchaCode();
    });

    const formInput = (item: FormItemOption) => {
      if (["smsCode", "phone"].includes(item.key)) {
        return (
          <>
            <Form.Item name="phone" {...validateInfos.phone}>
              <div class={css.loginPhoneNum}>
                <div class={css.loginPhoneDialCode} onClick={() => (popup.areCodeSelect = true)}>
                  <span>{modelRef.dialCode || $t("common_select" /*选择*/)}</span>
                  <DownOutlined />
                </div>
                <Input
                  size="large"
                  v-model:value={modelRef.phone}
                  placeholder={$t("user_login_pleaseInputPhoneNum" /**请输入手机号 */)}
                />
              </div>
            </Form.Item>
            {config.smsSwitch && phoneOption.value && (
              <Form.Item name="smsCode" {...validateInfos.smsCode}>
                <div class={css.loginPhoneCode}>
                  <Input
                    size="large"
                    v-model:value={modelRef.smsCode}
                    placeholder={$t("user_login_verificationCode" /*验证码*/)}
                  />
                  <Button
                    type="link"
                    size="large"
                    class={css.loginPhoneCodeText}
                    disabled={!modelRef.phone}
                    onClick={sendCode}
                  >
                    {count.value > 0
                      ? `${count.value}s`
                      : $t("common_send_verifyCode" /**发送验证码 */)}
                  </Button>
                </div>
              </Form.Item>
            )}
          </>
        );
      } else if (item.key === "username") {
        return (
          <Form.Item validateFirst={true} name="username" {...validateInfos[item.key]}>
            <Input
              size="large"
              v-model:value={modelRef[item.key]}
              placeholder={$t("user_register_pleaseInputUserName" /*请输入用户名*/)}
            >
              {{
                prefix: () => <UserOutlined style="color: rgba(255,255,255,.2)" />,
              }}
            </Input>
          </Form.Item>
        );
      } else if (["confirmPassword", "password"].includes(item.key)) {
        return (
          <Form.Item name={item.key} {...validateInfos[item.key]}>
            <Input.Password
              size="large"
              v-model:value={modelRef[item.key]}
              placeholder={
                item.key === "confirmPassword"
                  ? $t("user_register_input_confirmPassword" /* 请确认密码 */)
                  : $t("user_findPassword_pleaseInputPassword" /*请输入密码*/)
              }
            >
              {{
                prefix: () => <LockOutlined style="color: rgba(255,255,255,.2)" />,
              }}
            </Input.Password>
          </Form.Item>
        );
      } else if (["code"].includes(item.key)) {
        return window.CONFIG.isShowImgCode ? (
          <Form.Item name={item.key} {...validateInfos[item.key]}>
            <div style="display:flex">
              <Input
                class={css.loginAccountCaptcha}
                v-model:value={modelRef[item.key]}
                allowClear
                size="large"
                placeholder={$t("funds_recharge_inputVerificationCode") /*请输入图形验证码*/}
              >
                {{
                  prefix: () => (
                    <SvgIcon
                      class={css.loginAccountCaptchaIcon}
                      name="vertifyCode"
                      width="16px"
                      height="18px"
                    />
                  ),
                  suffix: () => (
                    <img
                      src={captchaImg.value}
                      onClick={getCaptchaCode}
                      class={css.loginAccountCaptchaImg}
                      alt=""
                    />
                  ),
                }}
              </Input>
            </div>
          </Form.Item>
        ) : null;
      } else if (item.key === "language" && getLangueList.value?.length > 1) {
        return (
          <Form.Item validateFirst={true} {...validateInfos[item.key]}>
            <Button
              onClick={() => {
                if (["int115-2"].includes(window.CONFIG?.name)) return;
                popup.langueOrcurrencyVisible = true;
                popup.isLangue = true;
              }}
              block
              size="large"
              class={css.registerAccountSelect}
            >
              <span>{dataStore.form.info}</span>
              <DownOutlined />
            </Button>
          </Form.Item>
        );
      } else if (item.key === "currency" && getCurrencyList.value?.length > 1) {
        return (
          <Form.Item validateFirst={true} {...validateInfos[item.key]}>
            <Button
              onClick={() => {
                popup.langueOrcurrencyVisible = true;
                popup.isLangue = false;
              }}
              block
              size="large"
              class={css.registerAccountSelect}
            >
              <span>{currentCurrencyText.value}</span>
              <DownOutlined />
            </Button>
          </Form.Item>
        );
      } else if (item.key === "duty") {
        return (
          <>
            <Form.Item name={dutyFields.dutyNumber} {...validateInfos[dutyFields.dutyNumber]}>
              <div class={css.loginPhoneNum}>
                <div class={css.loginPhoneDialCode} onClick={() => (popup.duty = true)}>
                  <span>{modelRef[dutyFields.dutyCode] || $t("common_select" /*选择*/)}</span>
                  <DownOutlined />
                </div>
                <Input
                  size="large"
                  style="padding-left:10px"
                  v-model:value={modelRef[dutyFields.dutyNumber]}
                  placeholder={$t("user_register_inputDutyCode" /**请输入税号 */)}
                />
              </div>
            </Form.Item>
          </>
        );
      } else if (item.key === "email") {
        return (
          <>
            <Form.Item validateFirst={true} name={item.key} {...validateInfos[item.key]}>
              <Input v-model:value={modelRef[item.key]} placeholder={item.name} />
            </Form.Item>
            {!!(emailOption.value?.value && Number(emailOption.value?.value) === 1) && (
              <Form.Item
                name="emailCode"
                {...validateInfos.emailCode}
                label={$t("user_findPassword_verificationCode" /**验证码 */)}
              >
                <div class={css.loginPhoneCode}>
                  <Input
                    size="large"
                    v-model:value={modelRef.emailCode}
                    placeholder={$t("user_register_input_verifyCode" /*请输入验证码*/)}
                  />

                  <Button
                    size="large"
                    type="link"
                    class={css.loginPhoneCodeText}
                    disabled={!modelRef.email}
                    onClick={sendEmailCode}
                  >
                    {emailCount.value > 0
                      ? `${emailCount.value}s`
                      : $t("common_send_verifyCode" /**发送验证码 */)}
                  </Button>
                </div>
              </Form.Item>
            )}
          </>
        );
      }
      return (
        <Form.Item validateFirst={true} name={item.key} {...validateInfos[item.key]}>
          <Input size="large" v-model:value={modelRef[item.key]} placeholder={item.name}>
            {{
              prefix: () =>
                item.key === "username" ? <UserOutlined style="color: rgba(255,255,255,.2)" /> : "",
            }}
          </Input>
        </Form.Item>
      );
    };

    return () => (
      <Fragment>
        {/* 区域弹窗显示 */}
        <MangoAreCodeSelect
          height={330}
          v-model:show={popup.areCodeSelect}
          currency={modelRef.dialCode}
          onChange={changeAreCode}
        />
        {/* 税号弹窗 */}
        <MangoDutyCodeSelect
          height={330}
          v-model:show={popup.duty}
          currency={modelRef[dutyFields.dutyCode]}
          onChange={changeDutyCode}
        />
        {/* 语言或者币种弹窗 */}
        {popup.langueOrcurrencyVisible ? (
          <LangueOrCurrencySelect
            height={330}
            dropdownList={popup.isLangue ? getLangueList.value : getCurrencyList.value}
            type={popup.isLangue ? "lang" : "currency"}
            currency={popup.isLangue ? dataStore.form.seriNo : currentCurrency.code}
            v-model:show={popup.langueOrcurrencyVisible}
            onChange={changeHander}
          />
        ) : null}

        <Form>
          <div
            style={{
              // height: "190px",
              overflow: "hidden",
              overflowY: "auto",
              padding: "20px 2px 0 2px",
            }}
          >
            {formConfig.items.map(item => formInput(item))}
          </div>
          <Checkbox v-model:checked={agreement.value}>
            <span>
              <span style="color:@cms-mango-text-color7;">
                {$t("user_register_serveTips" /*我确认我已满18岁并且已阅读*/)}
              </span>
              <span class={css.loginAccountLink}>{$t("user_login_serveBtn" /*服务条款*/)}</span>
            </span>
          </Checkbox>
          <Button
            size="large"
            class={css.specialDialogButton}
            onClick={openGeetestVerifier}
            loading={loading.value}
            style="margin: 10px 0"
          >
            {$t("user_register_createAccount" /**创建账户 */)}
          </Button>
        </Form>
        <p class={css.loginAccountTextLight}>
          {$t("user_login_hasNoAccountNumber" /*你有没有账号？*/)}
          <span class={css.loginAccountLink} onClick={() => props.switchLoginOrRegister?.(1)}>
            {$t("user_login_login" /*登录*/)}
          </span>
        </p>
        <LoginOther bottomType="register" />
      </Fragment>
    );
  },
});
