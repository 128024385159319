/**
 * 1、获取钱包余额
 * 2、总余额(不包括冻结金额)、冻结金额
 * 3、一键回收其他游戏钱包余额
 */
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { useDebounceFn } from "@vueuse/core";
import { Api } from "../services";
import { ReclaimAmount, WithdrawSchema, WalletItem } from "@kgsport-cms/service";
import {
  useToast,
  useConfigContext,
  useConfig,
  userRecord,
  mathAdd,
  FlagIcons,
  useUser,
  useI18n,
  useAuth,
  moneyFormatUtils,
} from "core";
export interface WalletData {
  lotteryData: WithdrawSchema;
  downFlag: boolean;
  tabIndex: number;
  reclaimloading: boolean;
  userWalletList: WalletItem[];
  acWallet: WalletItem | null; //当前钱包信息
  loading: boolean;
}

const store = reactive<WalletData>({
  // 钱包明细
  lotteryData: {
    balance: 0,
    freeze: 0,
    gameBalance: [],
    gameTotal: 0,
  },
  downFlag: true,
  tabIndex: 1,
  reclaimloading: false,
  //币种列表
  userWalletList: [],
  acWallet: {
    currency: window.CONFIG?.defaultCurrency || "USD",
    name: "",
    sortNum: 0,
    status: true,
    balance: {} as WithdrawSchema,
    ico: FlagIcons.currency[window.CONFIG?.defaultCurrency || "USD"],
  },
  loading: false,
});

export function useWallet() {
  const { $t } = useI18n();
  const { toast } = useToast();
  const router = useRouter();
  const { delayTimeout, amountShowType, config } = useConfig();
  const { getquotaList, getOrderStatus } = userRecord();
  const { getCurrencyList } = useConfigContext();
  const { getUserInfo, user } = useUser();
  const { isLogin } = useAuth();

  const venueList = computed(() => store.lotteryData.gameBalance);
  // 冻结金额
  const freeze = computed(() => store.lotteryData.freeze);

  // 游戏内总余额
  const gameTotal = computed(() => store.lotteryData.gameTotal);

  // 中心钱包余额
  const balance = computed(() => store.lotteryData.balance);

  //用户当前币列表
  const userWalletList = computed<WalletItem[]>(() => store.userWalletList);
  //当前币种通道（含子信息）
  const acWallet = computed<WalletItem | null>(() => store.acWallet);
  const loading = computed<boolean>(() => store.loading);
  //当前钱包列表（已过滤用户币种）
  const currencyList = computed(() =>
    getCurrencyList.value
      ?.filter(res => !store.userWalletList.find(item => item.currency === res.code))
      .map(res => {
        return { ...res, status: false, ico: FlagIcons.currency[res.code as never] };
      })
  );

  // 金额按用户配置格式进行格式化
  const amountFormat = (value: number) =>
    moneyFormatUtils(
      value,
      amountShowType.value,
      user.value?.currency || window.CONFIG?.defaultCurrency || "USD"
    );

  const addPlus = (balance = 0, freeze = 0, gameTotal = 0) =>
    mathAdd(balance).plus(freeze).plus(gameTotal).toNumber();
  // 总余额
  const AllBalance = computed(() =>
    amountFormat(
      addPlus(store.lotteryData.balance, store.lotteryData.freeze, store.lotteryData.gameTotal)
    )
  );
  // 总余额(不包括冻结金额)
  const availableBalance = computed(() =>
    mathAdd(store.lotteryData.balance).plus(store.lotteryData.gameTotal).toNumber()
  );
  const reclaimloading = computed(() => store.reclaimloading);
  // 金额比例
  const scaleAmountValue = computed(
    () => config.rwRates.find(v => v.name === user.value?.currency)?.value ?? "1"
  );

  const changeDown = () => {
    store.downFlag = !store.downFlag;
  };
  const tabsClick = (index: number) => {
    store.tabIndex = index;
  };
  // 获取钱包
  const platformBalance = async () => {
    if (!isLogin.value) return;
    try {
      store.reclaimloading = true;
      const { data = {}, result = false } = (await Api.UserService.platformBalance()) ?? {};
      store.reclaimloading = false;
      if (result) {
        store.lotteryData = data;
        getOrderStatus();
      }
    } catch (e) {
      console.error("ERROR:", e);
    }
  };
  // socket动态刷新余额
  const setStoreBalance = (data: any) => {
    if (data) {
      store.lotteryData = data;
    }
  };
  const reclaimAmountUpdata = async () => {
    const { result } = await Api.GameService.reclaimAmount();
    if (result) {
      return toast.success($t("message_toast_recycleSuccess" /*回收成功*/));
    }
  };
  // 一键回收其他游戏钱包余额
  const reclaimAmount = async () => {
    try {
      store.reclaimloading = true;
      const { result, data } = await Api.GameService.reclaimAmount();
      store.reclaimloading = false;
      if (result) {
        setTimeout(() => {
          platformBalance();
        }, delayTimeout.value);
        await getquotaList();
        // return data.some((item: ReclaimAmount) => item.status === 0)
        //   ? toast.success($t("message_toast_recycleSuccess" /*回收成功*/))
        //   : toast.error($t("message_toast_recycleError" /*游戏余额回收失败*/));
        data.some((item: ReclaimAmount) => item.status === 0) &&
          toast.success($t("message_toast_recycleSuccess" /*回收成功*/));
        return;
      }
      return;
    } catch (e) {
      console.log(e, "eee");
    }
  };

  const compare = (p: string) => {
    //这是比较函数
    return function (m: any, n: any) {
      const a = m[p];
      const b = n[p];
      return b - a; //升序
    };
  };

  //获取用户钱包&当前用户激活钱包

  const getUserWalletList = async () => {
    try {
      store.loading = true;
      const { data } = await Api.WalletService.list();
      store.loading = false;
      data.map(res => {
        res.status = Object.is(res.status, 2);
        res.ico = FlagIcons.currency[res.currency as never];
        return res;
      });
      store.userWalletList = data.sort(compare("status")) || [];
      store.acWallet = store.userWalletList.find((res: WalletItem) => res.status) || null;
    } catch (e) {
      store.loading = false;
      console.log(e, "eee");
    }
  };
  // 新增币种（默认关闭 status传1）或切换币种 （币种为开启状态return）back: 是否返回上一页，
  const balanceSwitch = async (
    currency: string,
    status = true,
    back = false,
    callback?: Function
  ) => {
    store.lotteryData = {
      balance: 0,
      freeze: 0,
      gameBalance: [],
      gameTotal: 0,
    };
    try {
      const { result } = await Api.WalletService.switch({
        currency,
        status: status ? 2 : 1,
      });
      if (result) {
        typeof callback === "function" && callback();
        toast.success($t("common_operate_success"));
        await getUserInfo(false);
        await platformBalance();
        if (!status) {
          getUserWalletList();
        } else {
          store.userWalletList.map(res => (res.status = res.currency === currency));
          store.userWalletList.sort(compare("status"));
          store.acWallet = store.userWalletList?.find((res: WalletItem) => res.status) || null;
        }
        if (back) router.go(-1);
      }
    } catch (e) {
      console.log(e, "eee");
    }
  };

  // 格式化显示金额, 分隔整数和小数位，单独显示
  const balancePageFormat = (amount: string | number) => {
    let symbol = ".";

    // 南美小数位标识为,
    if (["10", "11"].includes(amountShowType.value)) {
      symbol = ",";
    }

    const [integer = "0", decimal = ""] = amount ? String(amount).split(symbol) : [];

    return {
      integer: integer && decimal ? `${integer}${symbol}` : integer,
      decimal,
    };
  };

  return {
    freeze,
    balance,
    loading,
    addPlus,
    AllBalance,
    availableBalance,
    venueList,
    reclaimloading,
    store,
    gameTotal,
    changeDown,
    tabsClick,
    platformBalance,
    userWalletList,
    balanceSwitch,
    getUserWalletList: useDebounceFn(getUserWalletList, 1000),
    acWallet,
    currencyList,
    reclaimAmount: useDebounceFn(reclaimAmount, 1000),
    reclaimAmountUpdata: useDebounceFn(reclaimAmountUpdata, 1000),
    amountFormat,
    balancePageFormat,
    scaleAmountValue,
    setStoreBalance,
  };
}
export function useSetWallet() {
  return {
    store,
  };
}
