import { computed, defineComponent, Fragment } from "vue";
import { BaseRouterView, DynamicEngine } from "commom";
import { matchPath } from "core";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const footer = route.path.includes("/delAccount") ? [] : ["MangoFooter"];
    const header = route.path.includes("/delAccount") ? [] : ["MangoHeader"]; // header组件
    const matchsFooter: string[] = ["/game/wrap", "/delAccount"];
    const isFooter = computed(() => matchPath(route.path, matchsFooter));
    return () => (
      <BaseRouterView>
        {{
          header: () => (
            <Fragment>
              {header.map(item => (
                <DynamicEngine name={item} key={item} />
              ))}
            </Fragment>
          ),
          default: () => (
            <Fragment>
              {footer.map(item => (
                <DynamicEngine key={item} name={item} />
              ))}
            </Fragment>
          ),
        }}
      </BaseRouterView>
    );
  },
});
