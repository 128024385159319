import { computed, reactive } from "vue";
import { Locale } from "vant";
import { getSystemLang } from "core";

const antVI18nFileNameMap = {
  "zh-CN": "zh_CN",
  "zh-TW": "zh_TW",
  en: "en_US",
  th: "th_TH",
  vi: "vi_VN",
  ja: "ja_JP",
  ko: "ko_KR",
  my: "en_US",
  tr: "tr_TR",
  ar: "ar_EG",
  es: "es_ES",
  pt: "pt_PT",
  id: "id_ID",
  ms: "ms_MY",
  de: "de_DE",
  fr: "fr_FR",
  ru: "ru_RU",
  hi: "hi_IN",
};

const vantI18nFileNameMap = {
  "zh-CN": "zh-CN",
  "zh-TW": "zh-TW",
  en: "en-US",
  th: "th-TH",
  vi: "vi-VN",
  ja: "ja-JP",
  ko: "ko-KR",
  my: "en-US",
  tr: "tr-TR",
  ar: "en-US",
  es: "es-ES",
  pt: "pt-BR",
  id: "id-ID",
  ms: "en-US",
  de: "de-DE-formal",
  fr: "fr-FR",
  ru: "ru-RU",
  hi: "hi-IN",
};

const uilibI18nStore = reactive({
  currentLang: "en",
  local: undefined,
});

/**
 * 系统三方ui库，国际化处理
 */
export function useUiLibI18n() {
  // 当前加载语言环境
  const currentLocal = computed(() => uilibI18nStore.local);
  // 三方语言标示
  const currentLang = computed(() => uilibI18nStore.currentLang);

  // 加载ant 国际化资源
  const loadAntVLocal = async () => {
    const lang = getSystemLang();
    let result = null;
    const currentLang = (uilibI18nStore.currentLang = antVI18nFileNameMap[lang]);
    try {
      result = await import(
        /* webpackChunkName: "antv.i18n.[request]" */ `ant-design-vue/es/locale/${currentLang}.js`
      );
    } catch (error) {
      result = await import(
        /* webpackChunkName: "antv.i18n.[request]" */ `ant-design-vue/es/locale/zh_CN.js`
      );
    } finally {
      uilibI18nStore.local = result.default;
    }
  };

  // 加载vant 国际化资源
  const loadVantLocal = async () => {
    const lang = getSystemLang();
    let result = null;
    const currentLang = (uilibI18nStore.currentLang = vantI18nFileNameMap[lang]);
    try {
      result = await import(
        /* webpackChunkName: "vant.i18n.[request]" */ `vant/es/locale/lang/${currentLang}.js`
      );
    } catch (error) {
      result = await import(
        /* webpackChunkName: "vant.i18n.[request]" */ `vant/es/locale/lang/en-US.js`
      );
    } finally {
      uilibI18nStore.local = result.default;
      Locale.use(currentLang, result.default);
    }
  };

  return {
    currentLang,
    currentLocal,
    loadAntVLocal,
    loadVantLocal,
  };
}
