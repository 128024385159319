/*
 * @Descripttion   : your project
 * @Author         : amber
 * @Date           : 2022-12-07 10:38:40
 * @LastEditors    : amber
 * @LastEditTime   : 2022-12-07 10:56:19
 */
import {
  computed,
  ComputedRef,
  inject,
  reactive,
  shallowReadonly,
  ShallowReactive,
  ref,
} from "vue";
import {
  IDialCode,
  DomaiType,
  IuserCard,
  IMobileTabBar,
  Langue,
  Currency,
  listSortItem,
  ThirdLoginInfo,
  MobileRegistryInfo,
  SystemSchema,
  CustomerTextItem,
} from "@kgsport-cms/service";
import {
  unique,
  useAuth,
  Api,
  dialCodeZero,
  isMobile,
  useRuntime,
  STORE_RC,
  decrypt,
  getCookie,
  useLocalStorage,
  awaitWrap,
  useEvent,
  STORE_COOKIE_RC,
  STORE_COOKIE_PROXY,
  STORE_DOWNLOAD,
  CMS_DOMAIN_LIST,
  PROJECT_VERSION,
  useI18n,
} from "core";
import { useUrlSearchParams, useStorage, RemovableRef, useDebounceFn } from "@vueuse/core";
import { CaptchaType, formatCaptchaType, CONFIG_PROVIDER_KEY } from "commom";
import {
  mobileGameScence,
  mobileTabBar,
  mobileUserCard,
  vipIcon,
  RechargeInfoTips,
} from "./default.config";
import svgJson from "./navBarJson/";
import { FlagIcons } from "./langAndCurrency.config";
import { IpAreaMap, AreaItemType, EuCodes, EuCurrency } from "./AppConfig";
export interface agentContactType {
  contact: string;
  contactDetail: string;
  contactLogo: string;
  contactType: string;
  id: number;
}
import { hybridApp } from "../common";

interface ThirdLoginInfoNumber {
  whetherOpen: number;
  google: number;
  facebook: number;
}

export interface ConfigStore {
  /**
   * 国际区号
   */
  AreaCode: IDialCode[];
  /* 默认国际区号 */
  defaultDialCode: string;
  // 聊天室路径
  chat: string;
  // 代理路径
  agent: string;
  // h5代理路径
  h5agent: string;
  isEnableSign: boolean;
  tsx: Record<string, any>;
  config: Record<string, any>;
  themeColor: string;
  isUserBankBindCount: number;
  isUserVirtualBindCount: number;
  appSpreadDomain: string;
  isEnableVip: boolean;
  isOpenProxy: boolean;
  smsEnable: boolean;
  // 短信验证码/密保手机号
  smsSwitch: boolean;
  // 是否开启验证码验证
  withdrowSms: boolean;
  // 短信找回密码
  smsFindPwdSwitch: boolean;
  /* 代理联系方式 */
  agentContact: agentContactType[] | null;
  cpChatEnable: boolean;
  amountShowType: string;
  customerUrl: string;
  customerText: CustomerTextItem[];
  shareCustomerText: CustomerTextItem[];
  messageUnReadCount: number;
  indexSelfPopupStatus: boolean;
  indexActivityPopupStatus: boolean;
  captchaType: CaptchaType | null;
  // 用于全局 header 高度
  paddingTop: number;
  linePopup: boolean;
  activityPopup: boolean;
  personalPopup: boolean;
  domainList: DomaiType | null;
  // 是否显示h5首页卡片
  isshowMobileUserCard: string;
  // 是否使用亚博banner
  bannerScence: string;
  // 是否显示顶部下载组件
  isShowDownLoad: string;
  // 是否显示红包雨
  isShowRedRain: boolean;
  // 用户卡片domainList
  mobileUserCard: IuserCard[];
  // 用户卡片模式
  mobileUserCardModel: number;
  // h5 底部导航栏tabbar
  mobileTabBar: IMobileTabBar[];
  // h5 游戏列表场景
  mobileGameScence: string;
  // h5 用户卡片vip icon
  vipIcon: string;
  // PC 导航游戏列表场景
  navScene: string;
  RechargeInfoTips: string;
  // 借呗开关
  loanEnable: boolean;
  // 余额宝开关
  yubaoEnable: boolean;
  // 个人中心-是否显示意见反馈
  isShowFeedBack: string;
  // 免提直充开关
  isDirectOpen: boolean;
  listSort: listSortItem[];
  svgData: any;
  // 语言列表
  langueList: langugItem[];
  // 默认语种列表
  defaultLangList: string[];
  // 租户默认语言
  defaultLang: string;
  // 租户默认币种
  defaultCurrency: string;
  // 租户默认国家编码
  defaultCountryCode: string;
  // 租户默认时区
  defaultTimezone: string;
  // 币种列表
  currencyList: Currency[];
  flagIcons: {
    lang: any;
    currency: any;
  };
  // 是否显示用户绑定手机
  isShowBindPhone: string;
  // 是否显示提现短信验证
  isShowWithdrawlPhoneVerify: string;
  //第三方注册时默认币种
  registerDefaultCurrency: string;
  // h5logo地址
  h5LogoUrl: string;
  // 第三方登录是否开启字段
  thirdLoginInfo: ThirdLoginInfo | ThirdLoginInfoNumber;
  mobileRegistryInfo: MobileRegistryInfo;
  // 安卓客服下载地址
  customerDownloadUrl: string;
  // ios客服下载地址
  iosCustomerDownloadUrl: string;
  // 平台维护信息
  platformMaintainInfo: any;
  // 金额比例配置
  rwRates: Record<string, string>[];
  // ip区域
  ipArea: AreaItemType;
  // 是否注入体育模块
  injectScript: boolean;
  // 是否显示seo文本
  isShowSeoText: string;
  // 是否显示顶部上面的下载
  isShowTopdetail: boolean;
  // 是否显示税号
  isTaxFlag: boolean;
  // 税号列表
  dutyList: any[];
  passwordType: 1 | 0;
  // 否允许注销开关  0否1是
  allowCancel: 0 | 1;
  delAccount: boolean;
}
export interface langugItem {
  info: string;
  language: string;
  seriNo: string;
  localLanguage: string;
}

export interface ConfigProvider {
  sitename: ComputedRef<string>;
  config: ShallowReactive<ConfigStore>;
  isPc: ComputedRef<boolean>;
  customer: ComputedRef<string>;
  customerText: ComputedRef<CustomerTextItem[]>;
  shareCustomerText: ComputedRef<CustomerTextItem[]>;
  shareHandle: (item: CustomerTextItem) => void;
  download: ComputedRef<string>;
  pcSite: ComputedRef<string>;
  h5Site: ComputedRef<string>;
  areaCodeList: ComputedRef<IDialCode[]>;
  defaultCode: ComputedRef<string>;
  delayTimeout: ComputedRef<number>;
  rccode: RemovableRef<string>;
  scenesListSort: ComputedRef<listSortItem[]>;
  isEnableVip: ComputedRef<boolean>;
  agentContact: ComputedRef<agentContactType[] | null>;
  cpChatEnable: ComputedRef<boolean>;
  amountShowType: ComputedRef<string>;
  thirdLoginInfo: ComputedRef<ThirdLoginInfo | ThirdLoginInfoNumber>;
  messageUnReadCount: ComputedRef<number>;
  getDialCode: () => Promise<any[] | undefined>;
  goService: () => void;
  goAgent: () => void;
  goChat: () => Promise<Window | null | undefined>;
  getApi: () => string;
  setSystemConfigs: () => Promise<SystemSchema | undefined>;
  updateMessageCount: (value: number) => void;
  setPlatformMaintain: (value: any) => void;
  tsx: Record<string, any>;
  updateHeaderTop: (value: number | undefined) => void;
  setDomain: () => void;
  isHideDownlod: boolean;
  casingApp: boolean;
  yuebao: ComputedRef<boolean>;
  loan: ComputedRef<boolean>;
  getCurrencyList: ComputedRef<Currency[]>;
  getLangueList: ComputedRef<Langue[]>;
  getVersion: () => string;
  domainList: any;
  regDefaultCurrency: ComputedRef<string>;
  customerDownloadUrl: ComputedRef<string>;
  iosCustomerDownloadUrl: ComputedRef<string>;
  requestIpArea: () => Promise<AreaItemType>;
  isShowTopdetail: ComputedRef<boolean>;
  setTopdetailsStstus: (value: boolean) => void;
  allowCancel: ComputedRef<boolean>;
  delAccount: ComputedRef<boolean>;
}

export enum GECMAP {
  // 中国
  CH = "￥",
  // 香港
  HK = "HKD",
  // 印尼
  ID = "₨",
  // 新加坡
  SN = "SGD",
  // 泰国
  TH = "฿",
  // 美国
  US = "$",
  // 越南
  VM = "₫",
}

// 默认支持的语种
export const DEFAULT_LANG = [
  "zh-CN",
  "en",
  "th",
  "vi",
  "ja",
  "ko",
  "my",
  "tr",
  "ar",
  "es",
  "pt",
  "zh-TW",
  "id",
  "ms",
];
// 各租户paddingTop设置 默认128JBB版型
const paddingTopObj = {
  int115: 68,
  "int115-1": 68,
  int116: 90,
  int112: 120,
  int126: 90,
  int102: 68,
  lam100: 68,
  lam101: 68,
  lam102: 68,
  lam103: 68,
  lam104: 68,
  lam105: 68,
  lam106: 68,
  lam107: 68,
  lam139: 138,
  lam137: 138,
};

const store = reactive<ConfigStore>({
  AreaCode: [],
  defaultDialCode: "",
  chat: "/static/chat/",
  agent: "/static/agent/",
  h5agent: "/static/agenth5/",
  config: window.CONFIG || {},
  isEnableSign: true,
  tsx: window.cms_common ? window.cms_common?.resource?.tsx : window.top?.cms_common?.resource?.tsx, // 特殊处理当前视图在ifram里面情况
  themeColor: "default", // 主题
  isUserBankBindCount: 0, // 银行卡绑定数量
  isUserVirtualBindCount: 0, // 虚拟账户绑定数量
  appSpreadDomain: "", // app推广域名
  isEnableVip: true, // vip显示
  isOpenProxy: false, // 是否开启代理中心
  amountShowType: "4", // 金额显示类型
  smsEnable: true,
  customerUrl: "",
  customerText: [],
  shareCustomerText: [],
  agentContact: null,
  cpChatEnable: false,
  messageUnReadCount: 0, // 消息未读数量
  indexSelfPopupStatus: false, // 个人弹窗
  indexActivityPopupStatus: false, // 活动弹窗
  captchaType: CaptchaType.GEETEST, // TODO 缺省 验证器类型
  smsSwitch: true,
  withdrowSms: true,
  smsFindPwdSwitch: true,
  paddingTop:
    window.cms_common?.resource?.name in paddingTopObj
      ? paddingTopObj[window.cms_common?.resource?.name]
      : window.CONFIG?.currentModel === "mango"
      ? 68
      : 128,
  linePopup: true, // 线路弹窗开关
  activityPopup: true, //活动弹窗开关
  personalPopup: true, //个人弹窗开关
  domainList: null, // 线路
  navScene: window.CONFIG?.navScene || "ScenesKg",
  isshowMobileUserCard: window.CONFIG?.isshowMobileUserCard || "show",
  h5LogoUrl: window.CONFIG?.h5LogoUrl || window.location.host,
  bannerScence: window.CONFIG?.bannerScence || "kgBanner", // kgBanner/yaboBanner
  mobileUserCardModel: window.CONFIG?.mobileUserCardModel || 1,
  // 用户卡片
  mobileUserCard: window.CONFIG?.mobileUserCard || mobileUserCard,
  // h5 底部导航栏tabbar
  mobileTabBar: window.CONFIG?.mobileTabBar || mobileTabBar,
  // h5 游戏列表场景
  mobileGameScence: window.CONFIG?.mobileGameScence || mobileGameScence,
  // h5 用户卡片vip icon
  vipIcon: window.CONFIG?.vipIcon || vipIcon,
  RechargeInfoTips: window.CONFIG?.RechargeInfoTips || RechargeInfoTips,
  loanEnable: false,
  yubaoEnable: false,
  isShowDownLoad: window.CONFIG?.isShowDownLoad || "show",
  isShowRedRain: window.CONFIG?.isShowRedRain || false,
  // 安卓客服下载地址
  customerDownloadUrl: "",
  // ios客服下载地址
  iosCustomerDownloadUrl: "",
  listSort: [
    {
      name: "导航",
      onOff: true,
      remark: "备注:导航",
      sort: 1,
      type: "navigation",
    },
    {
      name: "游戏列表",
      onOff: true,
      remark: "备注:游戏列表",
      sort: 2,
      type: "gameList",
    },
    {
      name: "banner",
      onOff: true,
      remark: "备注:banner",
      sort: 3,
      type: "banner",
    },
    {
      name: "彩系列表",
      onOff: true,
      remark: "备注:彩系列表",
      sort: 4,
      type: "lotteryList",
    },
    {
      name: "中奖记录",
      onOff: true,
      remark: "备注:中奖记录",
      sort: 5,
      type: "winList",
    },
  ],
  isShowFeedBack: window.CONFIG?.isShowFeedBack || "hide",
  isDirectOpen: true,
  svgData: svgJson,
  // 语言列表
  langueList: [],
  // 默认语言列表
  defaultLangList: window.CONFIG?.defaultLangueList || [],
  // 租户配置的默认语言
  defaultLang: window.CONFIG?.defaultLang || "en",
  defaultCurrency: window.CONFIG?.defaultCurrency || "USD",
  defaultCountryCode: window.CONFIG?.defaultCountryCode || "US",
  // 租户默认时区
  defaultTimezone: window.CONFIG?.defaultTimezone || "Africa/Casablanca",
  currencyList: [],
  flagIcons: FlagIcons,
  isShowBindPhone: window.CONFIG?.isShowBindPhone || "show",
  isShowWithdrawlPhoneVerify: window.CONFIG?.isShowWithdrawlPhoneVerify || "show",
  registerDefaultCurrency: "USD",
  thirdLoginInfo:
    window.CONFIG?.thirdLoginInfo ||
    ({ whetherOpen: 0, facebook: 0, google: 0 } as ThirdLoginInfo | ThirdLoginInfoNumber),
  mobileRegistryInfo: {
    whetherOpen: false,
    phoneNum: "",
  },
  // 维护信息
  platformMaintainInfo: {},
  rwRates: [],
  ipArea: {} as AreaItemType,
  injectScript: true,
  isShowSeoText: window.CONFIG?.isShowSeoText || "",
  // isShowTopdetail: !window.CONFIG?.isShowTopTips ? true : window.CONFIG?.isShowTopTips,
  isShowTopdetail: !("isShowTopTips" in window.CONFIG) ? true : !!window.CONFIG?.isShowTopTips,
  isTaxFlag: false,
  dutyList: [],
  // 提现密码输入框类型
  passwordType: window.CONFIG?.passwordType ?? 0,
  allowCancel: 0,
  delAccount: window.location.href.includes("deleteYourAccountOpen=1"),
});
const { getToken } = useAuth();
const params = useUrlSearchParams<Record<string, any>>("history");
// 处理链接推广码
const rccode = useStorage<string>(STORE_RC, params.rc || "");
rccode.value = params.rc || getCookie(STORE_COOKIE_RC) || getCookie(STORE_COOKIE_PROXY);
export function useConfig(): ConfigProvider {
  const { $t } = useI18n();
  const { localStore } = useLocalStorage();
  const { isHideDownlod, casingApp } = hybridApp();
  //站点名称
  const sitename = computed(() => store.config.sitename || "");
  const isPc = computed(() => !isMobile);
  // 客服链接
  const customer = computed(() => store.customerUrl);
  const customerText = computed(() => store.customerText);
  // 分享
  const shareCustomerText = computed(() => store.shareCustomerText);
  // 分享处理
  const shareHandle = (item: CustomerTextItem) => {
    console.log(item, "item");
    if (isPc) {
      window.open(
        item.jumpUrl,
        "",
        `height=600,width=600,top=${window.outerHeight - 600},left=${window.outerWidth - 600}`
      );
    } else {
      window.open(item.jumpUrl, "");
    }
  };
  // 下载链接
  const download = computed(() => window.location.origin + "/m/download");

  // TODO 临时用链接 有接口支持改为列表
  const pcSite = computed(() => window.location.origin);
  const h5Site = computed(() => window.location.origin + "/m");
  // 国际区号列表
  const areaCodeList = computed<IDialCode[]>(() => store.AreaCode);
  // 默认国际区号
  const defaultCode = computed<string>(() => store.defaultDialCode);

  const messageUnReadCount = computed<number>(() => store.messageUnReadCount);
  const isEnableVip = computed<boolean>(() => store.isEnableVip);
  const cpChatEnable = computed<boolean>(() => store.cpChatEnable);
  const agentContact = computed(() => store.agentContact);
  const scenesListSort = computed(() => store.listSort.sort((a, b) => a.sort - b.sort));
  const amountShowType = computed<string>(() => store.amountShowType);
  const thirdLoginInfo = computed<ThirdLoginInfo>(() => store.thirdLoginInfo as ThirdLoginInfo);
  // 余额宝开关
  const yuebao = computed<boolean>(() => store.yubaoEnable);
  // 借呗开关
  const loan = computed<boolean>(() => store.loanEnable);
  // 币种列表
  const getCurrencyList = computed(() => store.currencyList);
  // 语言列表
  const getLangueList = computed(() => store.langueList);
  // 是否允许注销开关
  const allowCancel = computed(() => !!store.allowCancel);
  const delAccount = computed(() => !!store.delAccount);
  // 获取API
  const getApi = () => window.CONFIG?.api || process.env.CMS_API || window.location.origin || "";
  // 版本号
  const getVersion = () => PROJECT_VERSION || process.env.CMS_VERSION || "";
  // 获取域名接口列表
  const domainList = computed(() => store.domainList);
  //获取第三方注册默认币种
  const regDefaultCurrency = computed(() => store.registerDefaultCurrency);
  // 安卓客服下载地址
  const customerDownloadUrl = computed(() => store.customerDownloadUrl);
  // ios客服下载地址
  const iosCustomerDownloadUrl = computed(() => store.iosCustomerDownloadUrl);

  // // 获取svgjson
  // const getSvgData = async (item: IMobileTabBar)=> {
  //   const data = await svgJson['kgGreen_']();
  //   return {...item, svgData: data.jbbGreen};
  // }
  // 是否显示顶部下载
  const isShowTopdetail = computed(() => store.isShowTopdetail);
  // 获取手机国际区号
  const dialCodeList = ref();
  const getDialCode = async () => {
    if (dialCodeList.value?.list) return dialCodeList.value;
    try {
      const { data = { list: [] }, result = false } = (await Api.ConfigService.dialCode()) ?? {};
      if (result) {
        const dataJson = data.list.map((e: any) => {
          e.code = dialCodeZero(e.code);
          return e;
        });
        // 数组去重 后台返回了重复的数据，重复数据会导致下拉选框组件出问题
        store.AreaCode = unique(dataJson, "code");
        const defaultDial = (data as any).default;
        const defaultCode = dataJson.find(
          (item: IDialCode) =>
            Number(item.isDefault) === 1 || item.code.replace("+", "") === defaultDial
        );
        defaultCode && (store.defaultDialCode = defaultCode.code);
        dialCodeList.value = dataJson;
        return dataJson;
      }
    } catch (e) {
      console.error(e);
    }
  };
  const setPlatformMaintain = (data: any) => {
    store.platformMaintainInfo = data;
  };
  // 延迟调用接口时间，
  const delayTimeout = computed(() => 1000);
  const goService = () => {
    if (customer.value) {
      window.open(customer.value);
    } else {
      const customerService = customerText.value.find(
        d => d.title === "CustomerService" && d.type === 1
      );
      customerService?.jumpUrl && window.open(customerService.jumpUrl);
    }
  };
  const goAgent = () => {
    window.location.href = store.agent;
  };
  const goChat = async () => {
    const { result } = await Api.GameService.transfer({ platformCode: "KGNL" });
    if (result) {
      return window.open(
        `${
          store.chat
        }#/?isPopup=true&tenantCode=${null}&token=${await getToken()}&tokenKey=${null}&osType=0`,
        "CNN_WindowName",
        "height=812, width=375, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      );
    }
  };
  const updateHeaderTop = (value: number | undefined) => {
    if (!store.paddingTop) return;
    if (typeof value === "number") {
      store.paddingTop = value;
    }
  };
  const updateMessageCount = (value: number) => {
    store.messageUnReadCount = value;
  };

  const setSystemConfigs = async () => {
    try {
      const { data, result } = (await Api.ConfigService.systemConfig()) ?? {};
      if (data && result) {
        store.isUserBankBindCount = Number(data.isUserBankBindCount);
        store.isUserVirtualBindCount = Number(data.isUserVirtualBindCount);
        store.appSpreadDomain = data.appSpreadDomain;
        store.isEnableVip = Number(data.isEnableVip) === 1;
        store.isOpenProxy = data.isOpenProxy === 1;
        store.amountShowType = data.amountShowType;
        store.smsEnable = Number(data.smsEnable) === 1;
        store.customerUrl = data.customerUrl || "";
        store.customerText = data.customerText || [];
        store.shareCustomerText = data.customerText?.filter(item => item.type === 2) || [];
        store.agentContact = data.agentContacts || null;
        store.cpChatEnable = Number(data.cpChatEnable) === 1;
        store.isEnableSign = Number(data.isEnableSign) === 1;
        store.captchaType = formatCaptchaType(data);
        store.smsSwitch = Number(data.smsSwitch) === 1;
        store.smsSwitch = Number(data.smsSwitch) === 1;
        store.withdrowSms = Number(data.withdrowSms) === 1;
        store.smsFindPwdSwitch = Number(data.smsFindPwdSwitch) === 1;
        store.loanEnable = data.loanEnable;
        store.yubaoEnable = data.yubaoEnable;
        store.isDirectOpen = Number(data.isDirectOpen) === 1;
        store.listSort = data.listSort || [];
        store.currencyList = data.currencyList || [];
        store.langueList = data.langueList || [];
        store.registerDefaultCurrency = data.registerDefaultCurrency;
        store.customerDownloadUrl = data.customerDownloadUrl;
        store.iosCustomerDownloadUrl = data.iosCustomerDownloadUrl;
        data.thirdLoginInfo &&
          typeof data.thirdLoginInfo === "string" &&
          (store.thirdLoginInfo = JSON.parse(data.thirdLoginInfo));
        const mobileRegistryInfo =
          typeof data.mobileRegistryInfo === "string"
            ? JSON.parse(data.mobileRegistryInfo)
            : data.mobileRegistryInfo;
        if (mobileRegistryInfo) {
          mobileRegistryInfo.whetherOpen = !!mobileRegistryInfo?.whetherOpen;
        }
        store.mobileRegistryInfo = mobileRegistryInfo;
        store.isTaxFlag = Number(data.taxFlag) === 1;
        store.dutyList = [
          {
            name: $t("common_individual_taxNumber" /*CPF(个人税号)*/),
            value: "CPF",
          },
          {
            name: $t("common_company_taxNumber" /*CNPJ(公司税号)*/),
            value: "CNPJ",
          },
        ];

        updateCustomerPluginStatus(data.liveChat);
        store.rwRates = data.rwRates || [];
        store.allowCancel = data.allowCancel;
        window.CONFIG?.currentModel !== "mango" && (await requestIpArea());
        return Promise.resolve(data);
      }
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  };

  const setTopdetailsStstus = (val: boolean) => {
    store.isShowTopdetail = val;
  };
  // 获取域名
  const setDomain = async () => {
    if (store.domainList) return;
    try {
      const { result, data } = await Api.ConfigService.getDomainList();
      if (result) {
        const domain = JSON.parse(await decrypt(data)) as DomaiType;
        localStore.set(CMS_DOMAIN_LIST, domain);
        store.domainList = domain || {};
      }
    } catch (e) {
      console.log("获取域名解析失败:" + e);
    }
  };

  // 更新三方客服控件状态
  const updateCustomerPluginStatus = (status: string) => {
    if (window.LiveChatWidget && status !== "1") {
      window.LiveChatWidget.call("hide");
    }
  };

  // 根据ip获取区域
  const requestIpArea = async (): Promise<AreaItemType> => {
    if (Object.keys(store.ipArea).length) return store.ipArea;

    const [_, response] = await awaitWrap(Api.ConfigService.getIpArea());
    if (response) {
      const { data, result } = response;
      if (result) {
        store.ipArea = IpAreaMap[data.country] || {};
        // 欧洲国家货币默认全部使用欧元
        if (EuCodes.includes(data.country)) {
          store.ipArea = { ...store.ipArea, ...{ currency: EuCurrency } };
        }
        return store.ipArea;
      }
    }
    return {} as AreaItemType;
  };

  const tsx = store.tsx;

  return {
    // 只读对象
    config: shallowReadonly(store),
    isPc,
    pcSite,
    h5Site,
    customer,
    customerText,
    shareCustomerText,
    shareHandle,
    download,
    defaultCode,
    areaCodeList,
    delayTimeout,
    rccode,
    messageUnReadCount,
    isEnableVip,
    agentContact,
    scenesListSort,
    cpChatEnable,
    tsx,
    sitename,
    amountShowType,
    thirdLoginInfo,
    getDialCode,
    goService,
    goAgent,
    goChat,
    getApi,
    setSystemConfigs,
    updateMessageCount,
    updateHeaderTop,
    setPlatformMaintain,
    setDomain: useDebounceFn(setDomain, 1000, { maxWait: 1000 }),
    requestIpArea,
    isHideDownlod,
    casingApp,
    yuebao,
    loan,
    getCurrencyList,
    getLangueList,
    domainList,
    getVersion,
    regDefaultCurrency,
    customerDownloadUrl,
    iosCustomerDownloadUrl,
    isShowTopdetail,
    setTopdetailsStstus,
    allowCancel,
    delAccount,
  };
}

export function useProduction() {
  const { __CMS_IFRAME__, isEdit } = useRuntime();
  const runtime = computed<boolean>(() => {
    if (__CMS_IFRAME__ || isEdit) return false;
    return true;
  });
  return {
    runtime,
  };
}

// 获取AppConfigProvider的数据
export function useConfigContext() {
  return inject<ConfigProvider>(CONFIG_PROVIDER_KEY, () => useConfig(), true);
}
