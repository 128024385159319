import { defineComponent } from "vue";
import style from "./style.module.less";
export default defineComponent({
  name: "RadioCircle",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  setup(props, { emit }) {
    return () => (
      <span
        class={[style.radioCircle, props.checked && style.radioCircleChecked]}
        onClick={e => emit("click", e)}
      ></span>
    );
  },
});
