import { defineComponent, onMounted, computed, ref, reactive } from "vue";
import { useBetRecord, useI18n, useWallet, useConfig } from "core";
import { SvgIcon } from "commom";
import { Empty, mangoPopup } from "components";
import bet from "./bet.module.less";
import { Loading } from "vant";
export default defineComponent({
  name: "MangoBet",
  setup() {
    const { $t } = useI18n();
    const { userWalletList, getUserWalletList, amountFormat } = useWallet();
    const {
      pickerChange,
      backHandle,
      onTabChange,
      settleTab,
      dateList,
      betStore,
      betReportData,
      betDetailData,
      betSportData,
      form,
      getBetReport,
    } = useBetRecord();
    const status = ref(0);
    // 默认展示时间名称
    const timeText = ref(dateList[0].text);
    // 配置中心
    const { config, getCurrencyList } = useConfig();
    const model = reactive({
      showTime: false,
      showCurrency: false,
    });
    // 切换弹框
    const switchStatus = (type: number) => {
      if (type == 1) {
        model.showCurrency = false;
        model.showTime = !model.showTime;
      } else {
        model.showTime = false;
        model.showCurrency = !model.showCurrency;
      }
    };
    // 注单卡片数据
    const amountCard = computed(() => {
      return betStore.detailShow ? betDetailData.value?.otherData : betReportData.value;
    });
    onMounted(async () => {
      await getBetReport();
    });
    return () => (
      <div class={bet.bet}>
        <div class={bet.betHeader}>
          <div class={bet.betHeaderLeft}>
            {$t("game_recordTotal" /*注单笔数*/)}：<span>{betReportData.value?.betCount}</span>
          </div>
          <div
            class={bet.betHeaderMiddle}
            onClick={() => {
              switchStatus(1);
            }}
          >
            <span>{timeText.value}</span>
            {/* bet.betHeaderMiddl嗯eClose, model.showTime && */}
            <i class={[bet.betHeaderMiddleOpen]}>
              <SvgIcon width="24px" height="24px" name="arrow" color={"@cms-mango-primary-color"} />
            </i>
          </div>
          <div
            class={bet.betHeaderRight}
            onClick={() => {
              switchStatus(2);
            }}
          >
            <img src={config.flagIcons.currency[form.currency as never]} alt="" />
            {form.currency}
          </div>
        </div>
        <div class={bet.betTitle}>
          <p>
            <span>{$t("mine_changeRecord_generalIncome" /*总收入*/)}：</span>
            <span>
              {amountFormat(amountCard.value?.winAmount || 0)}(
              {amountCard.value?.currency || form.currency || "--"})
            </span>
          </p>
          <p>
            <span>{$t("mine_changeRecord_totalExpenditure" /*总支出*/)}：</span>
            <span>
              {amountFormat(amountCard.value?.betAmount || 0)}(
              {amountCard.value?.currency || form.currency || "--"})
            </span>
          </p>
        </div>
        <div class={bet.betCon}>
          {betReportData.value?.records?.map(
            (item: {
              img: string | undefined;
              gameName: any;
              betAmount: any;
              winAmount: any;
              currency: any;
              betCount: any;
              gameKind: any;
            }) => (
              <div class={bet.betConList}>
                <div
                  class={bet.betConListItem}
                  onClick={() => {
                    mangoPopup({
                      type: "MangoBetDetail",
                      title: $t("mine_center_betDetails" /*注单详情*/),
                      props: { gameKind: item.gameKind },
                    }).open();
                  }}
                >
                  <div class={bet.betConListCon}>
                    <section class={bet.betConListAvatar}>
                      <img src={item.img} alt="" />
                    </section>
                    <div class={bet.betConListTitle}>
                      <section class={bet.betConListName}>{item.gameName}</section>
                      <section class={bet.betConListQuota}>
                        {$t("mine_betRecord_billCount", [`${item.betCount}`] /*x单*/)}
                      </section>
                    </div>
                  </div>
                  <div class={bet.betConListCon}>
                    <div class={bet.betConListSingle}>
                      <section class={bet.betConListMoney}>
                        <span
                          style={{
                            color:
                              Number(item.winAmount) >= 0
                                ? Number(item.winAmount) === 0
                                  ? "@cms-mango-primary-color"
                                  : ""
                                : "red",
                          }}
                        >
                          {amountFormat(item.winAmount || 0)}
                        </span>
                        <span>({item.currency || "--"})</span>
                      </section>
                      <section class={bet.betConListQuota}>
                        {$t("game_bet_amount" /*投注额*/)}：{amountFormat(item.betAmount || 0)}
                      </section>
                    </div>
                    {/* <section class={bet.betConListArrow}>
                      <img src={arrowRight} alt="" />
                    </section> */}
                  </div>
                </div>
              </div>
            )
          )}
          {betStore.loading && (
            <div class={bet.betConLoading}>
              <Loading size={20} color="@cms-mango-primary-color" />
            </div>
          )}
          <Empty
            type={"mangoNoData"}
            v-show={
              (!betStore.loading && !betReportData.value?.records) ||
              betReportData.value?.records?.length === 0
            }
          />
        </div>
        {/* 选择币种 */}
        <div
          class={[bet.betSelect, model.showCurrency && bet.betSelectShow, bet.betSelectCurrency]}
        >
          <div class={bet.betSelectHeader}>
            <div class={bet.betSelectHeaderCancel} onClick={() => (model.showCurrency = false)}>
              {$t("common_cancel" /*取消*/)}
            </div>
            <div class={bet.betSelectHeaderTitle}>
              {$t("funds_choose_btc_address" /* 选择虚拟币地址 */)}
            </div>
            <div class={bet.betSelectHeaderConfrim} onClick={() => (model.showCurrency = false)}>
              {$t("common_confirm" /*确定*/)}
            </div>
          </div>
          <div class={bet.betSelectCon}>
            {getCurrencyList.value?.map(item => (
              <div
                class={bet.betSelectConItem}
                onClick={() => {
                  form.currency = item.code;
                  getBetReport();
                  model.showCurrency = false;
                }}
              >
                <img src={config.flagIcons.currency[item.code as never]} />
                <div class={bet.betSelectConItemName}>{item.name}</div>
                <div class={bet.betSelectConItemSel}>
                  <SvgIcon
                    name={item.code === form.currency ? "mangoSel" : "mangoNoSel"}
                    color={item.code === form.currency ? "@cms-mango-primary-color" : ""}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* 选择时间 */}
        <div class={[bet.betSelect, model.showTime && bet.betSelectShow]}>
          <div class={bet.betSelectHeader}>
            <div class={bet.betSelectHeaderCancel} onClick={() => (model.showTime = false)}>
              {$t("common_cancel" /*取消*/)}
            </div>
            <div class={bet.betSelectHeaderTitle}>
              {$t("funds_choose_btc_address" /* 选择虚拟币地址 */)}
            </div>
            <div class={bet.betSelectHeaderConfrim} onClick={() => (model.showTime = false)}>
              {$t("common_confirm" /*确定*/)}
            </div>
          </div>
          <div class={bet.betSelectCon}>
            {dateList.map(item => (
              <div
                class={bet.betSelectConItem}
                onClick={() => {
                  form.endTime = item.value.endTime;
                  form.beginTime = item.value.beginTime;
                  timeText.value = item.text;
                  getBetReport();
                  model.showTime = false;
                }}
              >
                <div
                  class={[
                    bet.betSelectConItemText,
                    timeText.value === item.text && bet.betSelectConItemActive,
                  ]}
                >
                  {item.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
});
