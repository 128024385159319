import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useI18n, useMessage, useNotice, useConfig } from "core";
import message from "./message.module.less";
import { mangoPopup } from "components";
import { MobilePageList, SvgIcon } from "commom";
import { NoticesItem } from "@kgsport-cms/service";
import { Icon } from "vant";
import RadioCircle from "./RadioCircle";
type PageListRefType = {
  refreshList: () => void;
  resetStore: () => void;
  resetLoading: () => void;
};
export default defineComponent({
  name: "MangoMessage",
  setup() {
    const { $t } = useI18n();
    const {
      store,
      removeMsg,
      handleRead,
      getMessageCount,
      getMessageList,
      tabMongoList,
      pathToDetail,
    } = useMessage();
    const { noticeStore, getNotice } = useNotice();
    const { messageUnReadCount } = useConfig();
    const mobilePageListRefs = ref<PageListRefType | null>(null);
    const data = reactive({
      selectAll: false,
      editMode: false,
    });
    const selectOne = (id: number) => {
      if (store.ids.includes(id)) {
        delete store.ids[store.ids.indexOf(id)];
      } else {
        store.ids.push(id);
      }
    };

    // 根据类型切换API
    // KG - CJ（Dubai），15: 21
    // 优惠通知暂时不用管;
    // 就暂时无消息那个就好;
    const activeApi = (value: string) => {
      const api = { getNotice, getMessageList };
      if (value === "discount") {
        return api["getNotice"];
      } else if (value === "notice") {
        return api["getNotice"];
      } else if (value === "message") {
        return api["getMessageList"];
      }
    };

    // 根据类型切换数据列表
    const activeList = (value: string) => {
      const list = {
        discount: () => [],
        notice: () => noticeStore.records || [],
        message: () => store.messageList || [],
      };
      return list[value]() as NoticesItem[];
    };

    // 根据类型切换数据列表
    const activeStore = (value: string): any => {
      if (value === "discount") {
        return store;
      } else if (value === "notice") {
        return noticeStore;
      } else if (value === "message") {
        return store;
      }
    };

    const listIcon = (type: string) => {
      switch (type) {
        case "discount":
          return "messageActivity";
        case "notice":
          return "messageInboxNotice";
        case "message":
          return "messageInboxNotice";
        default:
          return "messageNotice";
      }
    };

    // watch if select all
    watch(
      () => data.selectAll,
      v => {
        store.ids = v ? store.messageList.map(v => v.messageId) : [];
      }
    );

    const format = (str: string | null | undefined): string => {
      if (!str || typeof str !== "string") return "";
      const res = str.replace(/<([^(<>)]){0,2}>/g, "");
      return res.startsWith("<img ")
        ? `[${$t("common_pic" /*图片*/)}]`
        : str.replace(/<[^>]+>/g, "");
    };
    const toDetails = (item: any) => {
      mangoPopup({
        type: "MangoMessageDetail",
        title: $t("mine_message_details"),
        props: { id: store.type === "message" ? item.messageId : item.id },
        closeCallBack: () => {
          if (store.type === "message") mobilePageListRefs.value?.refreshList();
        },
      }).open();
    };

    onMounted(() => {
      // mobilePageListRefs.value?.refreshList();
      // getMessageList();
    });

    return () => (
      <div class={message.message}>
        <div
          class={message.messageEdit}
          onClick={() => {
            mobilePageListRefs.value?.resetLoading();
            data.editMode = !data.editMode;
          }}
          v-show={store.type === "message"}
        >
          {data.editMode ? $t("common_complete" /*完成*/) : $t("common_edit" /*编辑*/)}
        </div>
        <div class={message.messageTab}>
          {tabMongoList.map((item, index) => (
            <div
              class={[message.messageTabItem, item.type === store.type && message.messageTabActive]}
              onClick={() => {
                store.type = item.type;
                getMessageCount();
                if (store.type !== "message") data.editMode = false;
              }}
            >
              {$t(item.name)}
              {!!messageUnReadCount.value && item.type == "message" && (
                <span class={message.messageTabItemCount}>
                  {messageUnReadCount.value > 99 ? "99+" : messageUnReadCount.value}
                </span>
              )}
            </div>
          ))}
        </div>
        <div class={message.messageList}>
          <MobilePageList
            requestApi={activeApi(store.type)}
            ref={mobilePageListRefs}
            list={activeList(store.type)}
            total={activeStore(store.type).total}
            pages={activeStore(store.type).pages}
            v-model:current={activeStore(store.type).current}
            isWatch={true}
            isInit={true}
            empty="mangoNoData"
          >
            {activeList(store.type)?.map(item => (
              <div
                class={message.messageListItem}
                onClick={() => {
                  toDetails(item);
                }}
              >
                {data.editMode ? (
                  <RadioCircle
                    checked={store.ids.includes(item.messageId)}
                    onClick={e => {
                      e.stopPropagation();
                      selectOne(item.messageId);
                    }}
                  />
                ) : null}
                <SvgIcon
                  class={[
                    message.messageListItemIcon,
                    store.type === "message" &&
                      item.readStatus !== 1 &&
                      message.messageListItemIconUnread,
                  ]}
                  name={listIcon(store.type)}
                />
                <div class={message.messageListItemContent}>
                  <p class={message.messageListItemContentTitle}>
                    {item.title || format(item.content)}
                  </p>
                  <p class={message.messageListItemContentSub}>{format(item.content) || " "}</p>
                  {/* <p class={message.listItemContentDate}>{toTime(item.createTime) || " "}</p> */}
                </div>
                <div class={message.messageListItemArrow}>
                  {!data.editMode ? (
                    <Icon name="arrow" color="var(--cms-mango-text-color5)" />
                  ) : null}
                </div>
              </div>
            ))}
          </MobilePageList>
        </div>

        {data.editMode && (
          <div class={[message.messageBottom, data.editMode && message.messageBottomShow]}>
            <div
              class={[
                message.messageBottomCheck,
                activeList(store.type).length == 0 && message.messageBottomCheckDisable,
              ]}
              onClick={() => (data.selectAll = !data.selectAll)}
            >
              <RadioCircle checked={data.selectAll} />
              {$t("message_edit_selectAll" /* 全选 */)}
            </div>
            <div class={message.messageBottomAction}>
              <div
                class={message.messageBottomActionItem}
                onClick={() =>
                  handleRead(null, () => {
                    mobilePageListRefs.value?.refreshList();
                  })
                }
              >
                {/* <i class={[message.toolbarActionIcon, message.toolbarActionIconRead]} /> */}
                {$t("message_edit_read" /* 已读 */)}
              </div>
              <div
                class={message.messageBottomActionItem}
                onClick={() => removeMsg(null, () => mobilePageListRefs.value?.refreshList())}
              >
                {/* <i class={[message.toolbarActionIcon, message.toolbarActionIconDelete]} /> */}
                {$t("common_del" /* 删除 */)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
});
