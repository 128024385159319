import { computed, reactive, ref, watch } from "vue";
import { Form } from "ant-design-vue";
import { useToast } from "./useToast.hook";
import { useDebounceFn } from "@vueuse/core";
import {
  cardHolderRule,
  aliasRule,
  cardNoRule,
  usdtCardNoRule,
  bankValueRule,
  bankNameRule2,
  cardNoLam105Rule,
  cardNoMangoRule,
} from "../common";
import { BankList } from "@kgsport-cms/service";
import { useUser, Api, useI18n, destroyModalAll, saveParams, bankAndUdstParam } from "core";

type Item = {
  key: string;
  value: string;
  icon: string;
  bankType: string;
};
export interface BankStore {
  bankList: Item[];
  cardList: BankList;
  addLoading: boolean;
}
const store = reactive<BankStore>({
  bankList: [],
  cardList: {
    B: [],
    V: [],
  },
  addLoading: false,
});

const buttonConfig = reactive<any>({
  off: false,
});

const useForm = Form.useForm;
export function useUserBank(type = "B") {
  const { $t } = useI18n();
  const { getUserInfo, user } = useUser();
  const bankList = computed<Item[]>(() => store.bankList);
  const cardList = computed<BankList>(() => store.cardList);
  const off = computed<any>(() => buttonConfig.off);
  const tipsModalShow = ref(false);
  const isJump = ref(true);
  // 个人中心修改表单
  const bankForm = reactive({
    cardNo: "",
    bankName: "",
    bankValue: "",
    address: "",
    type,
    alias: "",
    currency: "",
    network: "",
    cardHolder: "",
    tarCurrency: "",
  });
  const bankRule = computed(() => {
    return {
      cardHolder: cardHolderRule(),
      bankValue: bankValueRule(),
      cardNo:
        window.CONFIG.name === "lam105"
          ? cardNoLam105Rule(bankForm.bankValue?.toUpperCase())
          : window.CONFIG.currentModel === "mango"
          ? cardNoMangoRule(bankForm.bankValue?.toUpperCase())
          : cardNoRule(),
      bankName: bankNameRule2(),
      // address: addressRule(),
      // address: "", // CJ说暂时改成非必填
    };
  });
  const usdtRule = reactive({
    alias: aliasRule(),
    cardNo: usdtCardNoRule(),
  });

  const timmer = ref<any>(null);

  // mango 平台 cardNo 限制
  const cardNoMangoMaxLength = ref<undefined | string | number>(undefined);

  const validateRef = ref<any>(null);
  const validateInfosRef = ref<any>(null);

  let { validate, validateInfos } = useForm(
    bankForm,
    bankForm.type === "V" ? usdtRule : bankRule.value
  );
  validateInfosRef.value = validateInfos;
  validateRef.value = validate;

  watch(
    () => bankForm,
    () => {
      if (window.CONFIG.name === "lam105" || window.CONFIG.currentModel === "mango") {
        if (timmer.value) {
          clearTimeout(timmer.value);
        }
        timmer.value = setTimeout(() => {
          validateRef
            .value()
            .then((res: any) => {})
            .catch((e: any) => {
              const { errorFields } = e;
              if (errorFields.length) {
                errorFields.map((item: any) => {
                  validateInfosRef.value[item.name].help = item.errors;
                  validateInfosRef.value[item.name].validateStatus = "error";
                });
              }
            });
        }, 0);
      }
    },
    { immediate: true, deep: true }
  );

  watch(
    () => bankForm.bankValue,
    () => {
      if (window.CONFIG.name === "lam105" || window.CONFIG.currentModel === "mango") {
        let { validate, validateInfos } = useForm(
          bankForm,
          bankForm.type === "V" ? usdtRule : bankRule.value
        );
        validateRef.value = validate;
        validateInfosRef.value = validateInfos;
      }
      if (window.CONFIG.currentModel === "mango" && window.CONFIG.name !== "lam105") {
        const maxLength = {
          CPF: 11,
          CNPJ: 10,
          PHONE: 11,
          EMAIL: undefined,
          EVP: 50,
        };
        cardNoMangoMaxLength.value = maxLength[bankForm.bankValue];
      }
    },
    {
      immediate: true,
    }
  );

  watch(
    () => user.value?.realName,
    () => {
      bankForm.cardHolder = user.value?.realName || "";
    },
    {
      immediate: true,
    }
  );

  const { toast } = useToast();

  // 获取银行卡配置
  const getBankConfig = async () => {
    try {
      const { data, result } = await Api.ConfigService.bankConfig({
        currency: user.value?.currency,
      });
      if (!result) return;
      store.bankList = data || [];
    } catch (e) {
      console.error(e);
    }
  };
  // 获取已绑定的 银行卡 虚拟币列表
  const getUserBank = async () => {
    try {
      const { data, result } = await Api.BankService.bankList({ currency: user.value?.currency });
      if (!result) return;
      const { B = [], V = [] } = data || {};
      store.cardList.B = B;
      store.cardList.V = V;
      // 兼容mango模版
      if (window.CONFIG?.currentModel === "mango") {
        if (saveParams.withdrawType === "V") {
          bankAndUdstParam.number = V[0].cardNo || "";
          bankAndUdstParam.name = `${V[0].currency || ""}(${V[0].network || ""})`;
          saveParams.bankId = V[0].id || "";
        } else {
          bankAndUdstParam.number = B[0].cardNo || "";
          bankAndUdstParam.name = `${B[0].bankName || ""}`;
          saveParams.bankId = B[0].id || "";
        }
      }
    } catch (e) {
      console.log(e, "e");
    }
  };

  // 添加银行卡\虚拟币
  const bankSubmit = async (cb?: () => void) => {
    store.addLoading = true;
    try {
      if (window.CONFIG.currentModel === "mango") {
        await validateRef.value();
      } else {
        await validate();
      }
      bankForm.tarCurrency = user.value?.currency as string;
      const { result, message } = await Api.BankService.bindBank(bankForm);
      if (!result) {
        message && toast.error(message);
        tipsModalShow.value = false;
        store.addLoading = false;
        return;
      }
      await getUserInfo(false);
      setTimeout(async () => {
        await getUserBank();
        destroyModalAll();
        tipsModalShow.value = true;
      }, 200);
      window.CONFIG?.currentModel === "mango" && cb?.();
    } catch (e: any) {
      // const { errorFields } = e;
      // if (errorFields.length) {
      //   errorFields.map((item: any) => {
      //     validateInfosRef.value[item.name].help = item.errors;
      //     validateInfosRef.value[item.name].validateStatus = "error";
      //   });
      // }
      console.log(e, "eeeeee");
    } finally {
      store.addLoading = false;
    }
  };
  // 解绑银行卡 虚拟币
  const unbankSubmit = async (id: string) => {
    const { result } = await Api.BankService.unBindBank(id);
    if (!result) {
      return;
    }
    toast.success($t("funds_withdraw_removeBindSucess")); /* 解绑成功 */
    await Promise.all([getUserBank(), getUserInfo(false)]);
  };
  const bankDebounceSubmit = useDebounceFn(bankSubmit, 500);

  return {
    store,
    bankList,
    cardList,
    bankForm,
    validate: validate,
    cardNoMangoMaxLength,
    validateInfos: validateInfos,
    validateInfosRef: validateInfosRef,
    validateRef: validateRef,
    off,
    isJump,
    bankDebounceSubmit,
    bankSubmit,
    unbankSubmit,
    getBankConfig,
    getUserBank,
    tipsModalShow,
  };
}
