import { defineComponent } from "vue";
import style from "./Payment.module.less";
import cancelImg from "../../assets/cancel.png";
import {
  useRechargeInfo,
  useClipboards,
  useToast,
  useI18n,
  timestampToDateTime,
  useConfig,
  useUser,
  useWallet,
} from "core";
import { CountDown } from "vant";

export default defineComponent({
  emits: ["handleCancel"],
  props: {
    //当前充值方式
    currRecharge: String,
    default: () => "",
  },
  setup(props, contex) {
    const handleCancel = () => {
      contex.emit("handleCancel");
    };
    const { toast } = useToast();
    const { info, getOrderInfo, cancelOrder } = useRechargeInfo();
    const { copyFn } = useClipboards();
    const { $t, I18n } = useI18n();
    const { goService } = useConfig();
    const { user } = useUser();
    const { amountFormat } = useWallet();
    //复制
    const copyText = async (value: any) => {
      copyFn(value || "");
      toast.success($t("common_copySuccessfully" /* 复制成功 */));
    };
    const revoke = () => {
      //取消订单
      cancelOrder(() => {});
      setTimeout(handleCancel, 2000);
    };
    const tipsList = [
      $t("funds_recharge_tips1" /* 1. 请填写正确的转账金额和附言（备注），否则存款无法及时到账 */),
      $t("funds_recharge_tips2" /* 2. 下次存款时，请获取新的账号，存入旧账号将无法到账 */),
    ];
    const renderBankInfo = () => {
      return (
        <div class={style.bank}>
          {info.value.payAccountBankName && (
            <div class={style.row}>
              <span class={style.rowFw500}>{info.value.payAccountBankName}</span>
            </div>
          )}

          <div class={style.row}>
            <span class={style.rowFw500}>{$t("funds_recharge_paymentAccount")}</span>
            <div class={style.rowItem}>
              <span class={style.rowFw500}>{info.value.payAccountAccount /**账户号 */}</span>
              <span class={style.rowCopy} onClick={() => copyText(info.value.payAccountAccount)}>
                {$t("common_copy" /* 复制 */)}
              </span>
            </div>
          </div>
          <div class={style.row}>
            <span class={style.rowFw500}>{$t("funds_payee_name") /**收款人姓名 */}</span>
            <div class={style.rowItem}>
              <span class={style.rowFw500}>{info.value.payAccountOwner}</span>
              <span class={style.rowCopy} onClick={() => copyText(info.value.payAccountOwner)}>
                {$t("common_copy" /* 复制 */)}
              </span>
            </div>
          </div>
          {user.value?.account && (
            <div class={style.row}>
              <span class={style.rowFw500}>{$t("mine_device_UserAccount") /**会员账号 */}</span>
              <div class={style.rowItem}>
                <span class={style.rowFw500}>{user.value?.account}</span>
                <span class={style.rowCopy} onClick={() => copyText(user.value?.account)}>
                  {$t("common_copy" /* 复制 */)}
                </span>
              </div>
            </div>
          )}

          <div class={style.row}>
            <span class={style.rowFw400}>{$t("funds_withdraw_orderNo" /**订单号 */)}</span>
            <div class={style.rowItem}>
              <span>{info.value.orderNo}</span>
              <span class={style.rowCopy} onClick={() => copyText(info.value.orderNo)}>
                {$t("common_copy" /* 复制 */)}
              </span>
            </div>
          </div>
          <div class={style.row}>
            <span class={style.rowFw400}>{$t("funds_recharge_tradingTime" /* 交易时间 */)}</span>
            <div class={style.rowItem}>
              <span>{timestampToDateTime(Number(info.value?.createTime || 0))}</span>
            </div>
          </div>
          <div class={style.row}>
            <span class={style.rowFw400}>{$t("funds_withdraw_paymentAmount") /**支付金额 */}</span>
            <div class={style.rowItem}>
              <span>{amountFormat(info.value.amount)}</span>
            </div>
          </div>
          <div class={style.row}>
            <span class={style.rowFw400}>{$t("funds_recharge_payWay" /* 充值方式 */)}</span>
            <div class={style.rowItem}>
              <span>{info.value.payTypeName /**充值方式 */}</span>
            </div>
          </div>
        </div>
      );
    };
    return () => (
      <div class={style.payment}>
        <header class={style.header}>
          <div class={style.headerInfo}>
            <h5>{$t("funds_recharge_amout") /**充值数额 */}：</h5>
            <div class={style.headerInfoAmount}>
              <div class={style.headerInfoAmountNumber}>
                <span>{amountFormat(info.value.amount)}</span>
                <span class={style.headerInfoAmountCurrency}>{user.value?.currency}</span>
                <span class={style.txt} onClick={() => copyText(info.value.amount || 0)}>
                  {$t("common_copy" /* 复制 */)}
                </span>
              </div>
            </div>
            <div class={style.headerInfoAmountBottom}>
              <div class={style.headerInfoAmountBottomRow1}>
                {info.value?.orderExpireTime ? (
                  <I18n
                    tag="div"
                    class={style.headerInfoAmountBottomTime}
                    keyPath="funds_recharge_payTimeCount" // 请在[0]内完成支付
                    v-slots={{
                      $0: () => {
                        return (
                          <CountDown
                            class={style.count}
                            time={(info.value?.orderExpireTime || 15) * 60 * 1000}
                            onFinish={getOrderInfo}
                            format="mm:ss"
                          />
                        );
                      },
                    }}
                  />
                ) : null}
              </div>
              <I18n
                tag="div"
                class={style.headerInfoAmountBottomRow2}
                keyPath="funds_withdraw_goService" // 如已完成支付，请{}确认
                v-slots={{
                  $0: () => {
                    return (
                      <span onClick={() => goService()}>
                        {$t("user_contactUs_actionTitle" /* 联系客服 */)}
                      </span>
                    );
                  },
                }}
              />
            </div>
          </div>
          {info.value?.qrCode && (
            <div class={style.headerCode}>
              <div class={style.headerCodeLeft}>
                <span class={style.headerCodeLeftScan}>
                  {$t("funds_recharge_paymentScan" /* 扫一扫付款 */)}
                  {">>"}
                </span>
                <span>{$t("funds_withdraw_rightClickSave") /**右键保存 */}</span>
              </div>
              <img src={info.value?.qrCode} width={123} />
            </div>
          )}
        </header>
        <main class={style.main}>
          <div class={style.mainBox}>
            <div class={style.mainBoxTop}>{renderBankInfo()}</div>
          </div>
          {/* <Upload></Upload> */}
        </main>
        <div class={style.bottom}>
          <div class={style.bottomTitle}>
            {$t("funds_recharge_sameAmoutTips") /**转账金额务必与订单金额一致 */}
          </div>
          <div class={style.bottomItem}>
            {/**充值方式为银行卡转账不展示：请勿使用支付宝、微信转账至公司账户 */}
            {props.currRecharge === "bank"
              ? tipsList
                  .filter((item, index) => index !== 2)
                  .map(text => {
                    return <p>{text}</p>;
                  })
              : tipsList.map(text => {
                  return <p>{text}</p>;
                })}
          </div>
          <div class={style.bottomCancel}>
            <img src={cancelImg} onClick={() => revoke()} />
            <span onClick={() => revoke()}>
              {$t("funds_recharge_cancelRecharge" /* "撤销充值申请" */)}
            </span>
          </div>
        </div>
      </div>
    );
  },
});
