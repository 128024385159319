import { defineComponent } from "vue";
import { Image } from "commom";
import style from "./avatar.module.less";
import { useUser, useConfigContext } from "core";

export default defineComponent({
  setup() {
    const { tsx } = useConfigContext();
    const { user } = useUser();
    return () => (
      <div class={style.userAvatar}>
        {tsx?.common && (
          <Image
            key={user.value?.avatar}
            src={
              tsx.common[`headerImg_avatar_${user.value?.avatar}`] || tsx.common.headerImg_avatar_1
            }
          />
        )}
      </div>
    );
  },
});
