import { isMobile } from "../utils";
// 目前web 和h5 token无法共用 需要根据当前环境 区分token
export const STORE_TOKEN = `ACCESS_TOKEN_${isMobile ? "H5" : "WEB"}`;
// 用户信息
export const STORE_USER_INFO = `ACCESS_USER_INFO`;
// 代理
export const STORE_AGENT = `ACCESS_AGENT_${isMobile ? "H5" : "WEB"}`;
// rc 推广码
export const STORE_RC = `CMS_RC`;

// 全局配置
export const STORE_GLOBEL_CONFIGS = `CMS_GLOBEL_CONFIGS`;

// H5消息类型
export const MESSAGE_ACTIVE_H5 = `CMS_MESSAGE_ACTIVE_H5`;

// PC消息类型
export const MESSAGE_ACTIVE_PC = `CMS_MESSAGE_ACTIVE_PC`;

// 会员验证信息
export const STORE_USER_REMEMBER = `CMS_USER_REMEMBER`;
// 手机号记忆
export const STORE_USER_PHONE_REMEMBER = `STORE_USER_PHONE_REMEMBER`;

// 充值弹窗是否提醒
export const RECHARGE_MODAL_SHOW = `CMS_RECHARGE_MODAL_SHOW`;

// 线路弹窗
export const STORE_LINE_POPUP = `CMS_LINE_POPUP`;
// 线路弹窗
export const STORE_ACTIVITY_POPUP = `CMS_ACTIVITY_POPUP`;
export const STORE_PERSONAL_POPUP = `CMS_PERSONAL_POPUP`;

// 套壳APP 影藏顶部下载
export const STORE_DOWNLOAD = `CMS_DOWNLOAD`;
// 默认语言枚举
export const CMS_IN18N_LANG = `CMS_IN18N_LANG`;
// 系统时区
export const CMS_TIME_ZONE = `CMS_TIME_ZONE`;
// 当前用户钱包
//export const USER_WALLET_CODE = `USER_WALLET_CODE`;
//配置域名缓存key
export const CMS_DOMAIN_LIST = `CMS_DOMAIN_LIST`;
// 游戏跳转jump信息
export const CMS_JUMP_DATA = `CMS_JUMP_DATA`;
export const AGENT_CLOSE_HOME_POPUP = `CMS_AGENT_CLOSE_HOME_POPUP`;
// ios套壳标识
export const CMS_IOS_APP_TYPE = `CMS_IOS_APP_TYPE`;
// i18n
export const CMS_I18N_LANG_NAME = `CMS_I18N_LANG_NAME`;

// 体育路由名字
export enum SportRouteName {
  /**
   * 体育首页
   */
  home = "sp_home",
  /**
   * 体育滚球
   */
  inPlay = "sp_inPlay",
  /**
   * 详情
   */
  detail = "sp_detail",
  /**
   * 体育注单
   */
  order = "sp_order",
  /**
   * 体育偏好设置
   */
  option = "sp_option",
  /**
   * 体育公告
   */
  notice = "sp_notice",
  /**
   * 体育规则
   */
  rules = "sp_rules",
  /**
   * 体育赛果
   */
  result = "sp_result",
  /**
   * 登录
   */
  login = "login",
  /**
   * 注册
   */
  register = "register",
}
