import { defineComponent, PropType, Transition } from "vue";
import toast from "./style.module.less";
import { SvgIcon } from "commom";

const initialUpperCase = (str: string) => {
  let s = "";
  if (str) {
    const arr = str.split("");
    arr[0] = arr[0].toUpperCase();
    s = arr.join("");
  }
  return s;
};
export default defineComponent({
  props: {
    // 弹窗显示状态
    show: {
      type: Boolean,
      default: false,
    },
    // 类型
    type: {
      type: String as PropType<"info" | "success" | "error">,
      default: "info",
    },
    // 主标题
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["update:show"],
  setup(props, { emit }) {
    return () => (
      <Transition
        name="toastIn"
        enterActiveClass={toast.toastInEnterActive}
        leaveActiveClass={toast.toastInLeaveActive}
        onAfterLeave={() => {
          emit("update:show", false);
        }}
      >
        <div v-show={props.show} class={toast.toast}>
          <SvgIcon
            class={toast.toastIcon}
            name={`mango${initialUpperCase(props.type)}Tip`}
            width="0.24rem"
            height="0.24rem"
          />
          <div class={toast.toastContent}>{props.title}</div>
        </div>
      </Transition>
    );
  },
});
