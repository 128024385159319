import { defineComponent, reactive, PropType, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { Swipe, SwipeItem, Icon } from "vant";
import { Carousel } from "ant-design-vue";
import { ActivityListItem } from "@kgsport-cms/service";
import {
  isMobile,
  DataType,
  useI18n,
  useLocalStorage,
  STORE_PERSONAL_POPUP,
  STORE_ACTIVITY_POPUP,
  todayTime,
} from "core";
import { Image } from "commom";
import "swiper/css";
import "swiper/css/navigation";
import style from "../pcPopup.module.less";
import h5Style from "../MobileStyle/mobilePopup.module.less";
import RadioCircle from "./RadioCircle";

interface StoreType {
  currentIndex: number;
  tipsStatus: boolean;
}
type noon = () => void;
export default defineComponent({
  props: {
    data: {
      type: Array as PropType<DataType[] | ActivityListItem[]>,
      default: () => [],
    },
    handClose: {
      type: Function as PropType<noon>,
      default: () => {},
    },
    type: {
      type: String as PropType<"my" | "activity">,
      default: "my",
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const router = useRouter();
    const { localStore } = useLocalStorage();
    const swiperRef = ref<any>();
    const store = reactive<StoreType>({
      currentIndex: 0,
      // 弹窗今日提醒状态
      tipsStatus: false,
    });
    const isMyPopup = props.type === "my";
    // const isMyPopup = false
    const selectNav = (item: DataType, index: number) => {
      store.currentIndex = index;
    };
    const goUrl = (id: string) => {
      props.handClose();
      router.push(`/activity/detail?id=${id}`);
    };
    const checkTodayNoTips = () => {
      store.tipsStatus = !store.tipsStatus;
      const key = isMyPopup ? STORE_PERSONAL_POPUP : STORE_ACTIVITY_POPUP;
      if (store.tipsStatus) {
        localStore.set(key, Date.now() + "", todayTime());
      } else {
        localStore.remove(key);
      }
    };
    const loadIframe = (id: string) => {
      const iframeBox: any = document.getElementById(id);
      const doc = iframeBox.contentWindow?.document;
      let pEl: [any] = doc.getElementsByTagName("p");
      for (var i = 0; i < pEl.length; i++) {
        pEl[i].style.color = "#fff";
      }
    };
    const H5Render = (props: DataType[], close: noon) => {
      const RenderIframe = (item: DataType) => (
        <div
          class={h5Style.h5PopupIframebox}
          style={{
            height: [
              "lam107",
              "int105-1",
              "lam102",
              "lam106",
              "lam117",
              "lam120",
              "lam111",
              "lam119",
            ].includes(window.CONFIG?.name)
              ? "8rem"
              : "3.52rem",
          }}
          v-html={item.content}
        />
      );

      const RenderImage = (item: DataType) => (
        <div
          class={h5Style.h5PopupImgbox}
          style={{
            height: [
              "lam107",
              "int105-1",
              "lam102",
              "lam106",
              "lam117",
              "lam120",
              "lam111",
              "lam119",
              "lam130",
            ].includes(window.CONFIG?.name)
              ? "8rem"
              : "3.52rem",
          }}
          onClick={() => {
            if (isMyPopup && item.whetherJump && item.jumpUrl) {
              window.location.href = item.jumpUrl;
            }
          }}
        >
          <Image fit="comtain" src={item.popupPic || item.appImage || ""} />
        </div>
      );
      const RenderImage2 = (item: DataType) => (
        <div
          class={h5Style.h5PopupImgbox}
          style={{
            height: [
              "lam107",
              "int105-1",
              "lam102",
              "lam106",
              "lam117",
              "lam111",
              "lam119",
            ].includes(window.CONFIG?.name)
              ? "8rem"
              : ["lam120", "lam130"].includes(window.CONFIG?.name)
              ? "5.6rem"
              : "3.76rem",
          }}
          onClick={() => {
            if (isMyPopup && item.whetherJump && item.jumpUrl) {
              window.location.href = item.jumpUrl;
            }
          }}
        >
          <Image fit="comtain" src={item.popupPic || item.appImage || ""} />
        </div>
      );

      const RenderContent = (item: DataType) => {
        if (item.showType === 3) return RenderImage(item);
        if (item.unId) return RenderImage2(item);
        return RenderIframe(item);
      };

      const indicatorNum = computed(() => {
        const len = props?.length ?? 0;
        return Array.from(new Array(len > 5 ? 5 : len)).map((_, i) => i);
      });
      const prevSwipe = () => {
        // console.log(swiperRef,"[]==")
        swiperRef.value.prev();
      };
      const nextSwipe = () => {
        swiperRef.value.next();
      };
      return (
        <div
          class={
            isMyPopup
              ? [
                  h5Style.h5Popup,
                  ["lam111"].includes(window.CONFIG?.name) ? h5Style.h5PopupBg2 : h5Style.h5PopupBg,
                ]
              : [h5Style.h5Popup, h5Style.h5PopupNobg]
          }
        >
          {isMyPopup ? (
            <div class={h5Style.h5PopupHeader}>
              <h1>{$t("common_message" /**消息 */)}</h1>
              <Icon class={h5Style.h5PopupHeaderClose} name="cross" onClick={() => close()} />
            </div>
          ) : null}
          <Swipe
            loop
            touchable
            ref={swiperRef}
            v-slots={{
              indicator({ active }: { active: number }) {
                if (isMyPopup && indicatorNum.value.length > 1) {
                  return (
                    <div class={h5Style.h5PopupIndicator}>
                      {indicatorNum.value.map(i => (
                        <section
                          class={[
                            h5Style.h5PopupIndicatorItem,
                            (active < 5 ? active === i : active - 5 == i) &&
                              h5Style.h5PopupIndicatorActive,
                          ]}
                        >
                          {active}
                        </section>
                      ))}
                    </div>
                  );
                } else {
                  return null;
                }
              },
            }}
          >
            {props.map(item => (
              <SwipeItem key={item.id}>
                <div>
                  <div onClick={() => item.unId && goUrl(item.unId)}>{RenderContent(item)}</div>
                </div>
              </SwipeItem>
            ))}
          </Swipe>
          {isMyPopup ? null : (
            <div class={h5Style.actionbox}>
              <div class={h5Style.prevbtn} onClick={prevSwipe}></div>
              <div class={h5Style.nextbtn} onClick={nextSwipe}></div>
            </div>
          )}
          {isMyPopup ? null : (
            <div class={h5Style.bottomClose}>
              <Icon
                class={h5Style.h5PopupHeaderClose}
                name="close"
                color="#fff"
                size="30px"
                onClick={() => close()}
              />
            </div>
          )}
          <div class={h5Style.h5PopupClose}>
            {/* <Image src={closeImg} /> */}
            <div class={[h5Style.todayWrap]} onClick={checkTodayNoTips}>
              <RadioCircle checked={store.tipsStatus} />
              <span class={h5Style.todayText}>
                {$t("funds_recharge_notRemindToday" /*今日不再提醒*/)}
              </span>
            </div>
          </div>
        </div>
      );
    };
    const PcRender = (props: DataType[], close: noon) => {
      // const RenderIframe = (item: DataType) => (
      //   <div>
      //     <iframe
      //       class={style.h5PopupIframebox}
      //       id="pcIframe"
      //       frameborder="0"
      //       onLoad={() => loadIframe("pcIframe")}
      //       srcdoc={item.content}
      //     ></iframe>
      //   </div>

      // );
      const RenderIframe = (item: DataType) => (
        <div class={style.h5PopupIframebox} v-html={item.content} />
      );
      const RenderImage = (item: DataType) => {
        return (
          <div
            class={style.h5PopupImgbox}
            style={{
              height:
                ["lam107", "int105-1", "lam119", "lam120", "lam130"].includes(window.CONFIG.name) &&
                item.showType === 3
                  ? "594px"
                  : "",
            }}
            onClick={() => {
              if (isMyPopup && item.whetherJump && item.jumpUrl) {
                window.location.href = item.jumpUrl;
              }
            }}
          >
            <img src={item.popupPic || item.pcImage || ""} />
          </div>
        );
      };

      const RenderContent = (item: DataType) => {
        if (item.showType === 3) return RenderImage(item);
        if (item.unId) return RenderImage(item);
        return RenderIframe(item);
      };

      const indicatorNum = computed(() => {
        const len = props?.length ?? 0;
        return Array.from(new Array(len > 5 ? 5 : len)).map((_, i) => i);
      });
      const prevSwipe = () => {
        // console.log(swiperRef,"[]==")
        swiperRef.value.prev();
      };
      const nextSwipe = () => {
        swiperRef.value.next();
      };
      return (
        <div
          class={
            isMyPopup
              ? [
                  style.h5Popup,
                  ["lam111"].includes(window.CONFIG?.name) ? style.h5PopupBg2 : style.h5PopupBg,
                ]
              : [style.h5Popup, style.h5PopupNobg]
          }
          style={{
            height: ["lam107", "int105-1", "lam119"].includes(window.CONFIG.name) ? "auto" : "",
            paddingBottom: ["lam107", "int105-1", "lam119"].includes(window.CONFIG.name)
              ? "0px"
              : "",
          }}
        >
          {isMyPopup ? (
            <div class={style.h5PopupHeader}>
              <h1>{$t("common_message" /**消息 */)}</h1>
              <Icon class={style.h5PopupHeaderClose} name="cross" onClick={() => close()} />
            </div>
          ) : null}
          <Carousel
            ref={swiperRef}
            dots={isMyPopup && indicatorNum.value.length > 1}
            dotsClass={style.pcdot}
            draggable
            // v-slots={{
            //   indicator({ active }: { active: number }) {
            //     if (isMyPopup) {
            //       return (
            //         <div class={style.h5PopupIndicator}>
            //           {indicatorNum.value.map(i => (
            //             <section
            //               class={[
            //                 style.h5PopupIndicatorItem,
            //                 (active < 5 ? active === i : active - 5 == i) &&
            //                   style.h5PopupIndicatorActive,
            //               ]}
            //             >
            //               {active}
            //             </section>
            //           ))}
            //         </div>
            //       );
            //     } else {
            //       return null;
            //     }
            //   },
            // }}
          >
            {props.map(item => (
              // <SwipeItem key={item.id}>
              <div key={item.id}>
                <div onClick={() => item.unId && goUrl(item.unId)}>{RenderContent(item)}</div>
              </div>
              // </SwipeItem>
            ))}
          </Carousel>
          {isMyPopup ? null : (
            <div class={style.actionbox}>
              <div class={style.prevbtn} onClick={prevSwipe}></div>
              <div class={style.nextbtn} onClick={nextSwipe}></div>
            </div>
          )}
          {isMyPopup ? null : (
            <div class={style.bottomClose}>
              <Icon
                class={style.h5PopupHeaderClose}
                name="close"
                color="#fff"
                size="30px"
                onClick={() => close()}
              />
            </div>
          )}
          <div class={style.h5PopupClose}>
            {/* <Image src={closeImg} /> */}
            <div class={[style.todayWrap]} onClick={checkTodayNoTips}>
              <RadioCircle checked={store.tipsStatus} />
              <span class={style.todayText}>
                {$t("funds_recharge_notRemindToday" /*今日不再提醒*/)}
              </span>
            </div>
          </div>
        </div>
      );
    };
    return () =>
      isMobile ? H5Render(props.data, props.handClose) : PcRender(props.data, props.handClose);
    // return () => H5Render(props.data, props.handClose);
  },
});
