import { defineComponent, onMounted, reactive, PropType } from "vue";
import styleModule from "./style.module.less";
import { Popup } from "vant";
import { useI18n } from "core";

import popup from "templates/mango/Web/popup.module.less";

interface DateListItemType {
  label: string;
  value: string;
}
export default defineComponent({
  props: {
    options: {
      type: Array as PropType<DateListItemType[]>,
      default: () => {
        return [
          { label: "mine_changeRecord_today", value: "0" },
          { label: "common_yesterday", value: "1" },
          { label: "common_last7days", value: "7" },
          { label: "common_last30days", value: "30" },
        ];
      },
    },
    day: {
      type: String as PropType<string>,
      default: () => "0",
    },
  },
  emits: ["changeDate"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const data = reactive({
      dateText: "mine_changeRecord_today",
      dateStatus: "0",
      showShortcutDate: false,
    });
    // 快捷日期筛选事件
    const confirmDate = (item: DateListItemType) => {
      data.dateText = item.label;
      data.dateStatus = item.value;
      emit("changeDate", item);
      data.showShortcutDate = false;
    };
    // 根据父组件传值设置初始化日期
    const initDate = (day: string) => {
      const currenDay = props.options.filter((item: DateListItemType) => item.value === day);
      data.dateText = currenDay[0].label;
      data.dateStatus = currenDay[0].value;
    };
    onMounted(() => {
      if (props.day === "0") return;
      initDate(props.day);
    });
    return () => (
      <div>
        <div class={styleModule.dateContent}>
          <div
            class={`${styleModule.dateContentTime} ${
              data.showShortcutDate ? styleModule.dateContentTimeActive : ""
            }`}
            onClick={() => (data.showShortcutDate = !data.showShortcutDate)}
          >
            {$t(data.dateText)}
          </div>
        </div>
        {/* 快捷日期 */}
        <Popup
          v-model:show={data.showShortcutDate}
          position="bottom"
          round
          lock-scroll={false}
          class={popup.popupWidth100}
        >
          <div class={[popup.popupHeader, popup.popupHeaderBg]}>
            <section class={popup.popupHeaderLeft} onClick={() => (data.showShortcutDate = false)}>
              {$t("common_cancel" /* "取消" */)}
            </section>
            <section class={popup.popupHeaderMiddle}>
              {$t("funds_withdraw_chooseDate" /*筛选日期*/)}
            </section>
            <section class={popup.popupHeaderRight}></section>
          </div>
          <div class={styleModule.popupContanner}>
            <ul class={styleModule.popupContannerList}>
              {props.options.map(item => (
                <li
                  class={[
                    styleModule.popupContannerListItem,
                    props.day === item.value && styleModule.popupContannerListItemActive,
                  ]}
                  onClick={() => confirmDate(item)}
                >
                  {$t(item.label)}
                </li>
              ))}
            </ul>
          </div>
        </Popup>
      </div>
    );
  },
});
