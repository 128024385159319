import { reactive, computed, ref } from "vue";
import { Api, useToast, useUser } from "core";
import { ITreasureBoxCommissionItem } from "@kgsport-cms/service";

interface Store {
  // 是否显示宝箱
  isShow: boolean;
  // 数据
  datas: ITreasureBoxCommissionItem[];
  // 是否显示领取界面
  isDrawdown: boolean;
  // 领取加载状态
  loading: boolean;
  // 领取结果
  success: boolean;
}

const store = reactive<Store>({
  isShow: false,
  datas: [],
  isDrawdown: false,
  loading: false,
  success: false,
});

export function useTreasureBox() {
  const { user } = useUser();
  const { toast } = useToast();
  // 获取宝箱数据
  const queryTreasureBoxCommission = () => {
    store.isShow = false;
    store.success = false;
    store.loading = false;
    store.isDrawdown = false;
    Api.TreasureBoxService.queryTreasureBoxCommission({
      account: user.value?.account,
    })
      .then(res => {
        store.datas = res.data;
        if (res.succeed) {
          if (res.data.length) {
            store.isShow = true;
          } else {
            store.isShow = false;
          }
        } else {
          store.isShow = false;
          toast.error(res.message);
        }
      })
      .catch(res => {
        console.log(res, "res");
      });
  };

  // 领取宝箱
  const treasureBoxCommissionSettle = () => {
    store.loading = true;
    Api.TreasureBoxService.treasureBoxCommissionSettle({
      account: user.value?.account,
    })
      .then(res => {
        store.loading = false;
        if (res.succeed) {
          store.success = true;
        } else {
          store.success = false;
          store.isShow = false;
          toast.error(res.message);
        }
      })
      .catch(res => {
        store.loading = false;
      });
  };

  // 关闭宝箱
  const treasureBoxClose = () => {
    Api.TreasureBoxService.treasureBoxClose({
      account: user.value?.account,
    })
      .then(res => {
        store.isShow = false;
        store.success = false;
        store.isDrawdown = false;
      })
      .catch(res => {
        console.log(res, "res");
      });
  };

  // 修改是否显示领取界面状态
  const modifyDrawdownStateHandler = (flag: boolean) => {
    store.isDrawdown = flag;
  };

  // 修改是否显示宝箱状态
  const modifyIsShowStateHandler = (flag: boolean) => {
    store.isShow = flag;
    if (flag) {
      store.success = false;
      store.isDrawdown = false;
    }
  };

  // 总佣金
  const total = computed(() => {
    let total = 0;
    for (let i = 0; i < store.datas.length; i++) {
      total += store.datas[i].rewordAmount;
    }
    return total.toFixed(2);
  });
  return {
    queryTreasureBoxCommission,
    store,
    total,
    treasureBoxClose,
    treasureBoxCommissionSettle,
    modifyDrawdownStateHandler,
    modifyIsShowStateHandler,
  };
}
