import { reactive, computed, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useUrlSearchParams } from "@vueuse/core";
import {
  Api,
  CMS_IN18N_LANG,
  useLocalStorage,
  useConfig,
  // useAuth,
  useToast,
  matchPath,
  awaitWrap,
  useUser,
  useLoaclforage,
  STORE_USER_INFO,
  STORE_TOKEN,
} from "core";
import { parse, compile } from "./i18nUtils";
import I18nComponent from "./i18n";
import { en } from "i18n";
import { Langue } from "@kgsport-cms/service";
import { i18nRquestLoad } from "./i18nRequestLoad";
interface langugItem {
  info: string;
  language: string;
  seriNo: string;
  localLanguage: string;
}

interface I18nConfig {
  langConfig: null | langugItem;
  defLang: string;
}

// 缓存国际化占位符编译数据
const _cachesI18nToken = Object.create(null);
const { localStore } = useLocalStorage();
const DEFAULT_LANG = window.CONFIG?.defaultLang || "zh-CN";

const store = reactive<I18nConfig>({
  langConfig: null,
  defLang: DEFAULT_LANG,
});

// export const

export const setLangConfig = async (lang = DEFAULT_LANG, isSelf = true) => {
  const { loadI18nCache } = i18nRquestLoad();
  const loadStaticFile = async () => {
    try {
      const res = await import(/* webpackChunkName: "i18n.[request]" */ `i18n/${lang}.json`);
      console.log(lang + "国际化资源加载成功", res);
      store.langConfig = res.default;
    } catch (error) {
      store.defLang = DEFAULT_LANG;
      // 容错处理。如果没有加载到语言资源，默认使用中文
      const res = await import(
        /* webpackChunkName: "i18n.[request]" */ `i18n/${DEFAULT_LANG}.json`
      );
      console.log(`${lang}语言加载失败，使用默认语言${DEFAULT_LANG}.json`);
      store.langConfig = res.default;
    }
  };

  try {
    store.defLang = lang;
    localStore.set(CMS_IN18N_LANG, lang);
    if (!isSelf) {
      window.history.go(0);
      // window.location.href = "";
    } else {
      const result = await loadI18nCache(lang);
      if (typeof result === "object") {
        store.langConfig = { ...result };
        return;
      }

      // 缓存和接口数据读取失败， 使用静态语言包
      loadStaticFile();
    }

    return store.langConfig;
  } catch (error) {
    console.error(error);
  }
};

// 函数方式
export function useI18nFn() {
  const $t = (keys: string | string[], args?: any[]) => {
    if (typeof keys === "string") {
      keys = [keys];
    }
    // 防止初始化页面显示表达式
    const langConfig = store.langConfig || en;

    let str = keys?.map(key => langConfig?.[key] || `${key}`).join("");

    if (args) {
      let tokens = _cachesI18nToken[str];
      if (!tokens) {
        tokens = parse(str);
        _cachesI18nToken[str] = tokens;
      }
      str = compile(tokens, args).join("");

      // for (const i of args) {
      //   str = str.replace(/\[[\d]\]/, i + "");
      // }
    }
    return str;
  };

  return { $t };
}

// 组件方式
export function useI18nComponent() {
  return {
    I18nComponent,
  };
}

/**
 * 国际化转换hook
 *
 * Eg
 * { user_login_login: 科比[0]库里[1]欧文[2] }
 *
 * const { $t } = useI18n()
 *
 * // 无参数
 * $t("国际化key")
 *
 * // 参数替换
 * $t("国际化key", ['替换值1', '替换值2', '替换值3'])
 *
 */
export function useI18n() {
  const { $t } = useI18nFn();
  const { I18nComponent } = useI18nComponent();
  return {
    $t,
    I18n: I18nComponent,
  };
}

export function useI18nConfig() {
  const { $t } = useI18n();
  const route = useRoute();
  const { toast } = useToast();
  const { getLangueList } = useConfig();
  // const { isLogin } = useAuth();
  const { userLang } = useUser();
  const isLogin = computed(() => !!localStorage[STORE_TOKEN]);
  const langConfig = computed(() => store.langConfig);
  const langConfigMap = computed(() =>
    getLangueList.value.reduce((obj, item) => {
      obj[item.seriNo] = item.info;
      return obj;
    }, {} as Record<string, string>)
  );
  const defLang = computed(() => store.defLang);
  const langList = computed(() => {
    const activeLang = getLangueList.value.find(item => item.seriNo.indexOf(store.defLang) > -1);
    const filteData = getLangueList.value.filter(item => item.seriNo.indexOf(store.defLang) < 0);
    activeLang && filteData.unshift(activeLang);
    return [...filteData];
  });

  watch([() => userLang.value, () => route?.path], ([newValue, newPath]) => {
    // 代理环境不读取用户身上的语言，代理后台接口不支持更新
    if (window.__AGENT_ENV__) return;
    const lang = localStore.get(CMS_IN18N_LANG);
    if (newValue !== lang && isLogin.value && matchPath(newPath, ["/"])) {
      // 延迟执行，用户登录成功进入首页后在进行刷新
      setTimeout(() => {
        toast.loading($t("common_lang_updateTips" /*当前用户语言更新中...*/));
        localStore.set(CMS_IN18N_LANG, newValue);
        setLangConfig(newValue, false);
      }, 500);
    }
  });

  return {
    langList,
    langConfig,
    setLangConfig,
    getLangueList,
    defLang,
    langConfigMap,
    DEFAULT_LANG,
  };
}

export function useI18nInit() {
  const userLang = ref(DEFAULT_LANG);
  // const { isLogin } = useAuth();
  const isLogin = computed(() => !!localStorage[STORE_TOKEN]);
  const { config, getLangueList } = useConfig();
  const { localStore } = useLocalStorage();
  const urlParams = useUrlSearchParams<Record<string, string>>("history");
  // 获取用户语言
  const getUserInfoLang = async () => {
    const [_, response] = await awaitWrap(Api.UserService.userInfo());
    if (response) {
      const { data, result } = response;
      if (result) {
        userLang.value = data.language || DEFAULT_LANG;
        // 更新缓存
        localStore.set(STORE_USER_INFO, JSON.stringify(data));
      }
    }
  };

  const initLang = async (langList?: Langue[], isAgent?: boolean) => {
    /**
     * 1.首页打开优先读取浏览器默认语言，如果没有匹配上，读取租户默认配置
     * 2.登录后，用户语言且缓存前端
     * 3.退出登陆，使用缓存语言
     * 4.再次打开浏览器，使用缓存语言
     * 5.清空浏览器使用默认语言
     */

    // 未登录并且指定未登录语言
    if (window.CONFIG?.fixedCurrency && !isLogin.value) {
      await setLangConfig(window.CONFIG?.fixedCurrency);
      return;
    }

    // 兼容处理地址栏带lang的参数（套壳包）
    if (urlParams.lang) {
      await setLangConfig(urlParams.lang);
      return;
    }

    // 代理无法更新用户语言环境
    if (isLogin.value && !isAgent) {
      await getUserInfoLang();
      if (userLang.value) {
        await setLangConfig(userLang.value);
        return;
      }
    }

    const lang = localStore.get(CMS_IN18N_LANG);
    if (lang) {
      await setLangConfig(lang);
      return;
    }
    // // 根据ip设置默认语言
    // const ipAreaConfig = await requestIpArea();

    // if (Object.keys(ipAreaConfig).length) {
    //   if (getLangueList.value.map(v => v.seriNo).includes(ipAreaConfig.lang)) {
    //     await setLangConfig(ipAreaConfig.lang);
    //     return;
    //   }
    // }

    const browserLang = getBrowserLang();
    if ((langList ? langList : getLangueList.value).map(v => v.seriNo).includes(browserLang)) {
      await setLangConfig(browserLang);
      return;
    }

    // 读取租户语言配置
    if (config.defaultLang) {
      await setLangConfig(config.defaultLang);
      return;
    }

    await setLangConfig();
  };

  return { initLang };
}

export function getSystemLang() {
  // const { isLogin } = useAuth();
  const isLogin = computed(() => !!localStorage[STORE_TOKEN]);
  if (!isLogin.value && window.CONFIG?.fixedCurrency) return window.CONFIG?.fixedCurrency;
  let lang = localStore.get(CMS_IN18N_LANG);
  const browserLang = getBrowserLang();
  if (!lang && window.CONFIG?.defaultLangueList?.includes(browserLang)) {
    lang = browserLang;
  }
  return lang || window.CONFIG?.defaultLang || "en";
}

function getBrowserLang() {
  // 读取浏览器默认语言
  const browserLang = window.navigator.language;
  // 中国香港转换为台湾
  if (browserLang === "zh-HK") return "zh-TW";
  // 非TW的中文语种全部使用zh-CN
  if (browserLang.indexOf("zh-") >= 0 && browserLang !== "zh-TW") {
    return "zh-CN";
  }
  // 特殊处理语言多种的情况
  if (browserLang.indexOf("-") >= 0) {
    return browserLang.split("-")[0];
  }

  return browserLang;
}
