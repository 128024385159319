import { mathMultiplied, mathDiv, mathToFixed, BigNumber } from "core";

// 检测toLocaleString 是否支持
export const isSupportsLocales = (() => {
  try {
    new Date().toLocaleString("i");
    return true;
  } catch (e) {
    return false;
  }
  return false;
})();

/*
 * 参数说明：
 * s：要格式化的数字
 * n：保留几位小数
 * */
export function moneyFormat(s: any, n: number) {
  const f = s < 0 ? "-" : "";
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((Math.abs(s) + "").replace(/[^\d.-]/g, "")).toFixed(n) + "";
  const l = s.split(".")[0].split("").reverse();
  const r = s.split(".")[1];
  let t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
  }
  return f + t.split("").reverse().join("") + "." + r;
}

/*
 * 参数说明：
 * num：要格式化的数字
 * */
export function currency(num: number): string {
  if (isSupportsLocales) {
    return num.toLocaleString();
  }
  return moneyFormat(num, 2);
}

/*
 * 格式化电话号码
 * 参数说明：
 * num：要格式化的电话号码
 * */
export function phoneNumber(num: string): string {
  if (num) {
    return num.substring(0, 3) + "*****" + num.substring(8, 11);
  }
  return "";
}
/*
 * 是否为数字类型
 * 参数说明：
 * value:any
 * */
export function isRealNumber(value: any) {
  return typeof value === "number" && !isNaN(value);
}

/**
 * 数字千位符格式化
 * eg:
 * 17267737 -> 17,267,737
 */
export function numberFormat2Thousands(num: string | number, currency?: string) {
  if (currency === "BRL") {
    return new Intl.NumberFormat("pt-BR").format(Number(num));
  }

  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 * 数字格式化 k M
 * @param num 格式化数字
 * @param isThousands 是否显示千分位
 * @param isDecimal 是否保留小数位
 * @param unit 格式化单位(k, M)
 * @returns number
 */
export function numberFormat2Unit(num: number, isThousands = true, isDecimal = true, unit = "k") {
  const handlerPrecision = (v: number) =>
    mathToFixed(v, isDecimal ? 2 : null, BigNumber.ROUND_DOWN);

  const handlerUnit = (threshold: number) => {
    let resultNumber = handlerPrecision(
      mathMultiplied(Math.sign(num), mathDiv(Math.abs(num), threshold).toNumber()).toNumber()
    );
    !isDecimal && (resultNumber = String(Math.trunc(Number(resultNumber))));
    return (isThousands ? numberFormat2Thousands(resultNumber) : resultNumber) + unit;
  };

  const handlerCallbackMap = {
    k: () => handlerUnit(1000),
    M: () => handlerUnit(1000000),
  };

  const fn = handlerCallbackMap[unit || "k"] || (() => num);
  return fn();
}

/**
 * 金额格式化
 * @param value 格式化数字
 * @param type 显示类型
 * @param currency 货币标识
 * @returns number
 * eg:
 * 数据: 10000111.01 转换后
 * 10000111
 * 10000111.01
 * 10,000,111
 * 10,000,111.01
 * 10000k
 * 10000.11k
 * 10,000k
 * 10,000.11k
 * 10,000
 */
export function moneyFormatUtils(value: number, type: string, currency: string) {
  const defaultFormat = 4;
  const handlerMoneyFormatMap: Record<number, () => string | number> = {
    // 千位无分割无小数示例：10000
    1: () => Math.trunc(value),
    // 千位无分割有小数示例：10000.00
    2: () => mathToFixed(value, 2, BigNumber.ROUND_DOWN),
    // 千位分割无小数示例：10,000
    3: () => numberFormat2Thousands(Math.trunc(value)),
    // 千位分割有小数示例：10,000.00
    4: () => numberFormat2Thousands(mathToFixed(value, 2, BigNumber.ROUND_DOWN)),
    // 千位无分割显示K无小数示例：10K
    5: () => numberFormat2Unit(Math.trunc(value), false, false),
    // 千位无分割显示K有小数示例：10.00K
    6: () => numberFormat2Unit(value, false, true),
    // 千位分割显示K无小数示例：1,000K
    7: () => numberFormat2Unit(Math.trunc(value), true, false),
    // 千位分割显示K有小数示例：1,000.00K
    8: () => numberFormat2Unit(value),
    // 有分割符千位下忽略示例：10,000
    9: () => numberFormat2Thousands(Math.trunc(Number(mathDiv(value, 1000)))),
    // 南美千分位无分隔有小数 1000,00
    10: () => mathToFixed(value, 2, BigNumber.ROUND_DOWN).replace(".", ","),
    // 南美千分位有分隔有小数 1.000，00
    11: () => numberFormat2Thousands(mathToFixed(value, 2, BigNumber.ROUND_DOWN), "BRL"),
  };

  if (handlerMoneyFormatMap[type]) return handlerMoneyFormatMap[type]();

  return handlerMoneyFormatMap[defaultFormat]();
}
