import { reactive, computed } from "vue";
import { Api, useAuth, isMobile, useI18n, useToast } from "core";
import { mangoPopup } from "components";
import { useRouter } from "vue-router";
// crash游戏图片
import crash_step1 from "templates/mango/assets/crash/1.png";
import crash_step2 from "templates/mango/assets/crash/2.png";
// mines游戏图片
import mines_step1 from "templates/mango/assets/mines/1.png";
import mines_step2 from "templates/mango/assets/mines/2.png";
// dice游戏图片
import dice_step1 from "templates/mango/assets/dice/1.png";
import dice_step2 from "templates/mango/assets/dice/2.png";
// tower游戏图片
import tower_step1 from "templates/mango/assets/tower/1.png";
import tower_step2 from "templates/mango/assets/tower/2.png";
import tower_step3 from "templates/mango/assets/tower/3.png";
// stairs游戏图片
import stairs_step1 from "templates/mango/assets/stairs/1.png";
import stairs_step2 from "templates/mango/assets/stairs/2.png";
import stairs_step3 from "templates/mango/assets/stairs/3.png";
// ball游戏图片
import ball_step1 from "templates/mango/assets/ball/1.png";
// limbo游戏图片
import limbo_step1 from "templates/mango/assets/limbo/1.png";
import limbo_step2 from "templates/mango/assets/limbo/2.png";
// plinko游戏图片
import plinko_step1 from "templates/mango/assets/plinko/1.png";
import plinko_step2 from "templates/mango/assets/plinko/2.png";
// luckyColour游戏图片
import luckyColour_step1 from "templates/mango/assets/luckyColour/1.png";
import luckyColour_step2 from "templates/mango/assets/luckyColour/2.png";
// fruitBomb游戏图片
import fruitBomb_step1 from "templates/mango/assets/fruitBomb/1.png";
// keno游戏图片
import keno_step1 from "templates/mango/assets/keno/1.png";
import keno_step2 from "templates/mango/assets/keno/2.png";
// ring游戏图片
import ring_step1 from "templates/mango/assets/ring/1.png";
import ring_step2 from "templates/mango/assets/ring/2.png";
// bldhash,bldhash3,bldhash5游戏图片
import bldhash_step1 from "templates/mango/assets/bldhash/1.jpg";
import bldhash_step2 from "templates/mango/assets/bldhash/2.jpg";

const COCOS = localStorage.COCOS ? JSON.parse(localStorage.COCOS) : {};
const { gameParams, history } = COCOS;

/**
 * 获取参数值
 * @param name
 * @param url
 * @returns {any}
 */
function getUrl(name: string, url?: string): string {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  let result =
    (url || window.location.href).split("?").length > 1 &&
    (url || window.location.href).split("?")[1].match(reg);
  return result ? decodeURIComponent(result[2]) : "";
}

export interface GameConfigItem {
  currency: string;
  gameCode: string;
  maximumBet: number;
  maximumPayment: number;
  minnumBet: number;
  vipLevel: number;
}

export interface GameParams {
  token: string;
  env: string;
  envi: string;
  minnumBet: number;
  maximumBet: number;
  maximumPayment: number;
  po: number;
  url: string;
  // 配置文件
  betLimitInfo: GameConfigItem[];
}

export interface IRule {
  url: string;
  info: string;
}

interface Store {
  gameParams: GameParams;
  history: string[];
  proportion: {
    [propName: string]: any;
  };
  rules: {
    [propName: string]: IRule[];
  };
  scrollTop: number;
}

const store = reactive<Store>({
  gameParams: {
    token: gameParams?.token,
    env: gameParams?.env,
    envi: gameParams?.envi,
    minnumBet: gameParams?.minnumBet,
    maximumBet: gameParams?.maximumBet,
    maximumPayment: gameParams?.maximumPayment,
    po: gameParams?.po,
    url: gameParams?.url,
    betLimitInfo: gameParams?.betLimitInfo || [],
  },
  history: history || [],
  proportion: {
    crash: 1.17519,
    bldhash: 1.17519,
    bldhash3: 1.17519,
    bldhash5: 1.17519,
    mines: 1.42061,
    // dice: 1.17542,
    dice: "503.08px",
    tower: 1.48,
    stairs: "592.97px",
    ball: "551.78px",
    limbo: 1.17519,
    plinko: 1.26762,
    luckyColour: 1.25133,
    fruitBomb: 1.90117,
    keno: 1.49168,
    ring: 1.53886,
  },
  rules: {},
  scrollTop: 0,
});

export function useCocos() {
  const { $t } = useI18n();
  const { isLogin } = useAuth();
  const router = useRouter();
  const { toast } = useToast();
  store.rules = {
    crash: [
      {
        url: crash_step1,
        info: $t("game_cocos_crash_info1" /*维护时间在倒计时结束前按下“投注”并跟随火箭飞行。*/),
      },
      {
        url: crash_step2,
        info: $t("game_cocos_crash_info2" /*在火箭在太空中爆炸之前撤回资金，以免输掉赌注。。*/),
      },
    ],
    bldhash: [
      {
        url: bldhash_step1,
        info: $t("game_cocos_crash_info1" /*维护时间在倒计时结束前按下“投注”并跟随火箭飞行。*/),
      },
      {
        url: bldhash_step2,
        info: $t("game_cocos_crash_info2" /*在火箭在太空中爆炸之前撤回资金，以免输掉赌注。。*/),
      },
    ],
    bldhash3: [
      {
        url: bldhash_step1,
        info: $t("game_cocos_crash_info1" /*维护时间在倒计时结束前按下“投注”并跟随火箭飞行。*/),
      },
      {
        url: bldhash_step2,
        info: $t("game_cocos_crash_info2" /*在火箭在太空中爆炸之前撤回资金，以免输掉赌注。。*/),
      },
    ],
    bldhash5: [
      {
        url: bldhash_step1,
        info: $t("game_cocos_crash_info1" /*维护时间在倒计时结束前按下“投注”并跟随火箭飞行。*/),
      },
      {
        url: bldhash_step2,
        info: $t("game_cocos_crash_info2" /*在火箭在太空中爆炸之前撤回资金，以免输掉赌注。。*/),
      },
    ],
    mines: [
      {
        url: mines_step1,
        info: $t(
          "game_cocos_mines_info1" /*1.下注，选择炸弹数量，点击“下注”。2.场内炸弹数量越多，胜率越高。*/
        ),
      },
      {
        url: mines_step2,
        info: $t(
          "game_cocos_mines_info2" /*1.寻找石头，但要小心炸弹！2.在你引爆炸弹输掉你的赌注之前拿走你的奖金！*/
        ),
      },
    ],
    dice: [
      {
        url: dice_step1,
        info: $t(
          "game_cocos_dice_info1" /*通过控制滑块进行投注并预测幸运号码 选择“旋转上方”或“旋转下方”所选号码。*/
        ),
      },
      {
        url: dice_step2,
        info: $t(
          "game_cocos_dice_info2" /*随机号码生成器将从 0 到 99 中选择中奖号码。如果幸运号码在所选范围内，您就中奖了！*/
        ),
      },
    ],
    tower: [
      {
        url: tower_step1,
        info: $t("game_cocos_tower_info1" /*下注，选择恶魔数量，然后按“下注”*/),
      },
      {
        url: tower_step2,
        info: $t("game_cocos_tower_info2" /*从底层开始揭示鬼魂。小心恶魔！*/),
      },
      {
        url: tower_step3,
        info: $t("game_cocos_tower_info3" /*尝试到达塔顶。在遇到恶魔之前收回胜利。*/),
      },
    ],
    stairs: [
      {
        url: stairs_step1,
        info: $t("game_cocos_stairs_info1" /*进行投注，选择火球数量并开始游戏。*/),
      },
      {
        url: stairs_step2,
        info: $t("game_cocos_stairs_info2" /*通过点击图块，你会爬得更高。*/),
      },
      {
        url: stairs_step3,
        info: $t("game_cocos_stairs_info3" /*当您达到所需的结果时，点击“兑现”并领取您的奖金。*/),
      },
    ],
    ball: [
      {
        url: ball_step1,
        info: $t(
          "game_cocos_ball_info1" /*设置您想要的金额，然后按“下注”，将随机掉落 5 个球，您拥有的组合越酷，您获得的奖金就越大！*/
        ),
      },
    ],
    limbo: [
      {
        url: limbo_step1,
        info: $t("game_cocos_limbo_info1" /*预测获胜系数并下注*/),
      },
      {
        url: limbo_step2,
        info: $t(
          "game_cocos_limbo_info2" /*按“下注”。并立即得到结果。如果大于或等于预测数 - 获胜。最大获胜系数为x9999*/
        ),
      },
    ],
    plinko: [
      {
        url: plinko_step1,
        info: $t(
          "game_cocos_plinko_info1" /*选择游戏的复杂程度（根据颜色）并下注。复杂程度（颜色）决定最大和最小增益系数的数量。*/
        ),
      },
      {
        url: plinko_step2,
        info: $t("game_cocos_plinko_info2" /*您的奖励是根据球落在获胜单元格上的系数确定的。*/),
      },
    ],
    luckyColour: [
      {
        url: luckyColour_step1,
        info: $t(
          "game_cocos_luckyColour_info1" /*每期4个房间包含：下单时间和抽奖时间（倒计时）。*/
        ),
      },
      {
        url: luckyColour_step2,
        info: $t(
          "game_cocos_luckyColour_info2" /*1. JOIN GREEN：如果结果显示 1,3,7,9，您将得到 (100*2) 200。 2. JOIN RED：如果结果显示 2,4,6,8，您将得到 (100* 2）200；如果结果显示 0，您将得到 (100*1.5) 150。 3. JOIN VIOLET：如果结果显示 0 或 5，您将得到 (100*4.5) 450。 4. SELECT NUMBER：如果结果相同作为您选择的数字，您将获得 (100*9) 900。*/
        ),
      },
    ],
    fruitBomb: [
      {
        url: fruitBomb_step1,
        info: $t(
          "game_cocos_fruitBomb_info1" /*1.您可以在游戏开始前选择您想要下注的水果。2. 如果您选择了正确的水果，您将赢得赌注乘以金额。3. 如果您选择了错误的水果，您就会输掉赌注。*/
        ),
      },
    ],
    keno: [
      {
        url: keno_step1,
        info: $t("game_cocos_keno_info1" /*选择 1 到 10 个带有数字的单元格，下注并点击“下注”*/),
      },
      {
        url: keno_step2,
        info: $t(
          "game_cocos_keno_info2" /*然后系统随机选择10个号码。您的数字越正确，收益就越大！*/
        ),
      },
    ],
    ring: [
      {
        url: ring_step1,
        info: $t("game_cocos_ring_info1" /*选择风险级别和分段金额。下注并运行环。*/),
      },
      {
        url: ring_step2,
        info: $t("game_cocos_ring_info2" /*等到铃声停止。您的胜利取决于戒指上掉落的部分。*/),
      },
    ],
  };
  /**
   * 获取游戏参数
   * @param callback 成功回调
   */
  const getGameParams = async (callback?: Function) => {
    const { data, result } = await Api.GameService[
      isLogin.value ? "getGameParamsByToken" : "getGameParams"
    ]();
    if (result) {
      localStorage.COCOS = JSON.stringify({
        gameParams: {
          ...data,
          betLimitInfo: data.betLimitInfo || [],
        },
        history: store.history || [],
      });
      store.gameParams = {
        ...data,
        betLimitInfo: data.betLimitInfo || [],
      };
    } else {
      toast.error($t("game_cocos_entry_failure" /* 进入游戏失败，请稍后再试 */));
    }
    typeof callback === "function" && callback();
  };
  /**
   * 向window暴露的方法，供cocos使用
   * @param type 类型
   * @param gameName 路由
   * @param datas 数据
   * @returns
   */
  const cocosHandler = (
    type: "login" | "rule" | "record" | "recharge" | "onBlur" | "onFocus",
    datas?: any
  ) => {
    const scrollTop = window.scrollY || window.pageYOffset;
    const handler = {
      // 调用登录模块
      login: () => {
        if (isLogin.value) return;
        mangoPopup({
          type: isMobile ? "MangoEntryMobile" : "MangoEntry",
          props: { status: "login" },
          header: false,
          blurredBg: false,
          borderRadius: "20",
        }).open();
      },
      // 显示规则
      rule: () => {
        mangoPopup({
          type: isMobile ? "MangoCocosRuleMobile" : "MangoCocosRuleWeb",
          props: { type: getUrl("gameName"), gameParams: getCurrentGameParams() },
          header: false,
          maskClosable: true,
        }).open();
      },
      // 显示战绩
      record: () => {
        const newArr =
          datas && datas.length
            ? datas.map((item: any) => {
                const detail = JSON.parse(item.detail);
                return {
                  orderid: item.orderid,
                  createdtime: item.createdtime,
                  name: getUrl("gameName").toLocaleUpperCase(),
                  betMoney: detail.betMoney,
                  showScroe: detail.showScore,
                  status: item.result === 1 ? "WIN" : "LOSE",
                };
              })
            : [];
        mangoPopup({
          type: isMobile ? "MangoCocosHistoryMobile" : "MangoCocosHistoryWeb",
          props: {
            list: newArr,
          },
          header: false,
          hasBackground: false,
        }).open();
      },
      // 显示充值
      recharge: () => {
        if (!isLogin.value) return;
        mangoPopup({
          type: isMobile ? "MangoRechargeH5" : "MangoRechargeWithdrawModal",
          header: isMobile ? false : true,
          borderRadius: "12",
          props: { switchVal: "deposit", tab: "charge" },
        }).open();
      },
      // input失去焦点事件
      onBlur: () => {
        window.scrollTo(0, store.scrollTop);
      },
      // input失去焦点事件
      onFocus: () => {
        store.scrollTop = scrollTop;
      },
    };

    handler[type] && handler[type]();
  };
  // 余额转换接口
  const gameTransfer = async (platformCode: string) => {
    try {
      if (!platformCode) return;
      await Api.GameService.transfer({ platformCode });
    } catch (e) {
      console.error("gameTransfer:", e);
    }
  };

  // 更新记录
  const updateHistory = () => {
    const gameName = getUrl("gameName");
    if (!store.history.includes(gameName)) {
      if (store.gameParams.url && store.proportion[gameName]) {
        cocosHandler("rule");
        store.history.push(gameName);
        localStorage.COCOS = JSON.stringify({
          gameParams: store.gameParams,
          history: store.history,
        });
      }
    }
  };

  // 获取当前游戏的参数
  const getCurrentGameParams = () => {
    const { gameParams, proportion } = store;
    const { betLimitInfo } = gameParams;
    const gameName = getUrl("gameName");
    const current = betLimitInfo.find(item => item.gameCode === gameName.toLocaleUpperCase());

    if (!gameParams.url || !proportion[gameName]) {
      // router.push("/");
    }

    return {
      token: gameParams.token,
      env: gameParams.env,
      envi: gameParams.envi,
      minnumBet: current ? current.minnumBet : gameParams.minnumBet,
      maximumBet: current ? current.maximumBet : gameParams.maximumBet,
      maximumPayment: current ? current.maximumPayment : gameParams.maximumPayment,
      po: gameParams.po,
      url: gameParams.url,
    };
  };
  return {
    store,
    getGameParams,
    cocosHandler,
    getCurrentGameParams,
    gameTransfer,
    updateHistory,
    proportion: computed(() => store.proportion),
    rules: computed(() => store.rules),
  };
}
