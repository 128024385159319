import { defineComponent, Fragment, onMounted, PropType, reactive } from "vue";
import { Form, Input, Button } from "ant-design-vue";
import css from "../../Entry.module.less";
import { DownOutlined } from "@ant-design/icons-vue";
import { useLogin, useI18n, useUser, useConfig, useCommon } from "core";
import { IDialCode } from "@kgsport-cms/service";
// 其他登录方式
import LoginOther from "../LoginOther";
// 电话区域弹窗
import MangoAreCodeSelect from "templates/mango/Web/components/AreCodeSelect";
// 类型
import { subTabType } from "../../Entry";
import { SvgIcon } from "commom";
export default defineComponent({
  components: {
    LoginOther,
    MangoAreCodeSelect,
  },
  props: {
    switchLoginOrRegister: Function as PropType<(value: number) => void>,
    switchVerificationMode: Function as PropType<(value: subTabType) => void>,
  },
  setup(props) {
    const { $t } = useI18n();
    const { loading, setPhoneForm, openCaptcha, getCaptchaCode } = useLogin();
    const { getDialCode, defaultCode } = useConfig();
    const { phoneAccount, smsCodeAccount, phoneForm, sendCodeSubmit, count } = useUser();
    const { captchaImg } = useCommon();
    const loginEvent = async (isPhone: boolean) => {
      if (isPhone) {
        setPhoneForm(phoneForm);
      }
      await openCaptcha(isPhone);
    };
    const sendCode = () => {
      if (count.value > 0) return;
      sendCodeSubmit();
    };
    // 弹窗需要的数据
    const popup = reactive({
      // 区域弹窗显示
      areCodeSelect: false,
    });
    /**
     * 区域选择下拉选中数据变化执行函数
     * @param option 选中数据项
     */
    const changeAreCode = (option: IDialCode) => {
      phoneForm.dialCode = option.code;
    };
    onMounted(async () => {
      await getDialCode();
      await getCaptchaCode();
      console.log(defaultCode.value, "defaultCode.value;");
      phoneForm.dialCode = defaultCode.value;
    });
    return () => (
      <Fragment>
        <MangoAreCodeSelect
          height={330}
          v-model:show={popup.areCodeSelect}
          currency={phoneForm.dialCode}
          onChange={changeAreCode}
        />
        <Form style={{ marginTop: "20px" }}>
          <Form.Item required validateFirst={true} {...phoneAccount.validateInfos.phone}>
            <div class={css.loginPhoneNum}>
              <div onClick={() => (popup.areCodeSelect = true)} class={css.loginPhoneDialCode}>
                {phoneForm.dialCode || $t("common_select" /*选择*/)}
                <DownOutlined style="margin-left:8px" />
              </div>
              <Input
                allowClear
                size="large"
                // style="padding-left:90px"
                v-model:value={phoneForm.phone}
                placeholder={$t("user_login_pleaseInputPhoneNum" /**请输入手机号 */)}
              ></Input>
            </div>
          </Form.Item>
          <Form.Item required {...smsCodeAccount.validateInfos.smsCode}>
            <div class={css.loginPhoneCode}>
              <Input
                allowClear
                size="large"
                v-model:value={phoneForm.smsCode}
                placeholder={$t("user_login_verificationCode" /**验证码 */)}
              ></Input>
              <Button
                size="large"
                type="link"
                disabled={!phoneForm.phone}
                class={css.loginPhoneCodeText}
                onClick={sendCode}
              >
                {count.value > 0
                  ? `${count.value}s`
                  : $t("common_send_verifyCode" /**发送验证码 */)}
              </Button>
            </div>
          </Form.Item>
          {window.CONFIG.isShowImgCode && (
            <Form.Item required {...phoneAccount.validateInfos.code}>
              <div style="display:flex">
                <Input
                  v-model:value={phoneForm.code}
                  allowClear
                  size="large"
                  placeholder={$t("funds_recharge_inputVerificationCode") /*请输入图形验证码*/}
                >
                  {{
                    prefix: () => (
                      <SvgIcon
                        class={css.loginAccountCaptchaIcon}
                        name="vertifyCode"
                        width="16px"
                        height="18px"
                      />
                    ),
                    suffix: () => (
                      <img
                        src={captchaImg.value}
                        onClick={getCaptchaCode}
                        style="width:80px;height:28px;cursor:pointer"
                      />
                    ),
                  }}
                </Input>
              </div>
            </Form.Item>
          )}
          <Button
            size="large"
            class={css.specialDialogButton}
            loading={loading.value}
            onClick={() => loginEvent(true)}
          >
            {$t("user_login_login" /**登录 */)}
          </Button>
        </Form>
        <div class={css.loginAccountText}>
          <p>
            {$t("user_login_serveTips" /*要访问此网站，请确保您已年满 18 岁并同意*/)}
            <span class={css.loginAccountLink}>{$t("user_login_serveBtn" /*服务条款*/)}</span>
          </p>
          <p class={css.loginAccountTextLight}>
            {$t("user_login_noAccountNumber" /*你没有账户吗？*/)}
            <span class={css.loginAccountLink} onClick={() => props.switchLoginOrRegister?.(2)}>
              {$t("user_login_register" /*注册*/)}
            </span>
          </p>
        </div>
        <LoginOther />
      </Fragment>
    );
  },
});
