import { reactive, computed } from "vue";
import { ApiAgent, awaitWrap } from "core";
import { Langue } from "@kgsport-cms/service";
interface BaseConfig {
  // api: string; //代理api域名
  // sitename: string; //代理租户站点名称 document.title 默认取值
  // name: string; //租户唯一id
  // script?: string; //动态脚本
  // scenes?: string; // 预留字段
  // h5Scenes?: string;
  langList: Langue[];
}

const store = reactive(<BaseConfig>{
  // api: window?.CONFIG?.api || "",
  // name: window?.CONFIG?.name || "",
  // sitename: window?.CONFIG?.sitename || "代理中心",
  langList: [],
});

export function useAgConfig() {
  // const api = computed<string>(() => store.api);
  // const docTitle = computed<string>(() => store.sitename);
  // const tenantCode = computed<string>(() => store.name);
  const langList = computed<any[]>(() => store.langList);

  const getLangueList = async () => {
    if (store.langList.length) return Promise.resolve(store.langList);
    const [_, response] = await awaitWrap(ApiAgent.AgentSystemConfigService.langList());
    if (response) {
      const { data, result } = response;
      if (result) {
        store.langList = [...data];
      }
    }
    return Promise.resolve(store.langList);
  };

  if (window?.CONFIG.title) {
    document.title = window?.CONFIG.title || "";
  }

  return {
    // api,
    // docTitle,
    // tenantCode,
    langList,
    getLangueList,
  };
}
