import { defineComponent, ref, PropType } from "vue";
import { useConfig, useI18n } from "core";
import popup from "templates/mango/Web/popup.module.less";
import { Popup } from "vant";
import RadioCircle from "../RadioCircle";
import style from "./dropdown.module.less";

export default defineComponent({
  props: {
    show: Boolean,
    height: {
      type: Number,
      default: 600,
    },
    currency: {
      type: String,
      default: "",
    },
    dropdownList: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    type: {
      type: String as PropType<string>,
      default: "",
    },
  },
  emits: ["change", "update:show"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { config } = useConfig();
    const target = ref<any>(null);

    const isLang = props.type === "lang";

    // 图片
    const getImageSrc = (item: any) => {
      const resultMap = {
        lang: () => config.flagIcons.lang[item.seriNo],
        currency: () => config.flagIcons.currency[item.code],
      };
      return resultMap[props.type]();
    };

    /**
     * 判断是否选中单选框
     * @param item 对比数据项
     * @returns
     */
    const isCheck = (item: any) => {
      const resultMap = {
        lang: () => props.currency === item.seriNo,
        currency: () => props.currency === item.code,
      };
      return resultMap[props.type]();
    };

    return () => (
      <Popup
        position="bottom"
        v-model:show={props.show}
        close-on-click-overlay
        round
        transition-appear
        onClick-overlay={() => emit("update:show", false)}
        class={style.areaCode}
      >
        <div class={[popup.popupHeader, popup.popupHeaderBg]}>
          <section class={popup.popupHeaderLeft} onClick={() => emit("update:show", false)}>
            {$t("common_cancel" /* "取消" */)}
          </section>
          <section class={popup.popupHeaderMiddle}>
            {props.type === "lang"
              ? $t("user_register_ToastTitle_language" /*选择语种*/)
              : $t("user_register_ToastTitle_currency" /*选择币种*/)}
          </section>
          <section class={popup.popupHeaderRight} style="display:none">
            确定
          </section>
        </div>
        <div ref={target} class={style.dropdown}>
          <div class={style.dropdownContent}>
            {props.dropdownList.map((item, index) => (
              <div
                class={style.dropdownList}
                key={index}
                onClick={() => {
                  emit("change", item);
                  emit("update:show", false);
                }}
              >
                <div class={style.dropdownListLeft}>
                  <img class={style.dropdownItemImg} src={getImageSrc(item)} />
                  <section class={style.bankLanguage}>
                    {isLang ? (
                      <>
                        <span class={style.bankLocal}>{item.localLanguage}</span>
                        <span class={style.bankIcon}>{item.info}</span>
                      </>
                    ) : (
                      <>
                        <span class={style.bankIcon}>
                          {item.name}({item.code})
                        </span>
                      </>
                    )}
                  </section>
                </div>

                <RadioCircle checked={isCheck(item)} />
              </div>
            ))}
          </div>
        </div>
      </Popup>
    );
  },
});
