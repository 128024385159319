import { defineComponent, ref } from "vue";
import { Form, Input } from "ant-design-vue";
import { Button, PasswordInput, NumberKeyboard } from "vant";
import { useI18n, useUser } from "core";
import { TipsIcon } from "./assets";
import style from "./withdrawalpasssettings.module.less";
import popup from "templates/mango/Mobile/popup.module.less";
import { SvgIcon } from "commom";
import { mangoPopup } from "components";

export default defineComponent({
  props: {
    callback: {
      type: Function,
      default: null,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { withdrawAccount, withdrawForm, reqeustLoading, editWithdrawSubmit } = useUser();
    const showKeyboard1 = ref(false);
    const showKeyboard2 = ref(false);

    return () => (
      <div class={style.formWrap}>
        <div class={[popup.popupHeader, popup.popupHeaderBorder]}>
          <section class={popup.popupHeaderLeft}></section>
          <section class={popup.popupHeaderMiddle}>
            {$t("mine_usersetup_withdrawalPassSettings" /*提现密码设置*/)}
          </section>
          <section
            class={popup.popupHeaderRight}
            onClick={() => {
              mangoPopup().destroyAll();
              props.callback && props.callback();
            }}
          >
            <SvgIcon class={popup.popupHeaderIcon} name="close" width="0.48rem" height="0.48rem" />
          </section>
        </div>
        <div class={style.line}>
          <img src={TipsIcon} alt="Tips" />
          <span>{$t("funds_withdraw_setPasswordTip" /*提现密码设置后不可自行修改，请牢记*/)}</span>
        </div>
        <Form>
          {!window.CONFIG?.passwordType ? (
            <>
              <Form.Item {...withdrawAccount.validateInfos.withdrawPassword} validateFirst={true}>
                <Input.Password
                  placeholder={$t("funds_withdraw_inputWithdrawPassword" /*请输入提现密码*/)}
                  type="password"
                  v-model:value={withdrawForm.withdrawPassword}
                />
              </Form.Item>
              <Form.Item
                {...withdrawAccount.validateInfos.confirmWithdrawPassword}
                validateFirst={true}
              >
                <Input.Password
                  placeholder={$t("mine_usersetup_pleaseConfirmWithdrawalPass" /*请确认提现密码*/)}
                  type="password"
                  v-model:value={withdrawForm.confirmWithdrawPassword}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                class={style.formWrapPi}
                {...withdrawAccount.validateInfos.withdrawPassword}
              >
                <section class={style.formWrapPiTitle}>
                  {$t("funds_withdraw_inputWithdrawPassword" /*请输入提现密码*/)}
                </section>
                <PasswordInput
                  class={style.formWrapPiPwd}
                  value={withdrawForm.withdrawPassword}
                  focused={showKeyboard1.value}
                  onFocus={() => {
                    showKeyboard2.value = false;
                    showKeyboard1.value = true;
                  }}
                />
                <NumberKeyboard
                  show={showKeyboard1.value}
                  v-model={withdrawForm.withdrawPassword}
                  teleport="body"
                  maxlength={6}
                  zIndex={2000}
                  onBlur={() => (showKeyboard1.value = false)}
                />
              </Form.Item>
              <Form.Item
                class={style.formWrapPi}
                {...withdrawAccount.validateInfos.confirmWithdrawPassword}
              >
                <section class={style.formWrapPiTitle}>
                  {$t("mine_usersetup_pleaseConfirmWithdrawalPass" /*请确认提现密码*/)}
                </section>
                <PasswordInput
                  class={style.formWrapPiPwd}
                  value={withdrawForm.confirmWithdrawPassword}
                  focused={showKeyboard2.value}
                  onFocus={() => {
                    showKeyboard1.value = false;
                    showKeyboard2.value = true;
                  }}
                />
                <NumberKeyboard
                  show={showKeyboard2.value}
                  v-model={withdrawForm.confirmWithdrawPassword}
                  teleport="body"
                  maxlength={6}
                  zIndex={2000}
                  onBlur={() => (showKeyboard2.value = false)}
                />
              </Form.Item>
            </>
          )}
          <div class={style.formWrapBtns}>
            <Button
              onClick={async () => {
                await editWithdrawSubmit();
                props.callback && props.callback();
              }}
              loading={reqeustLoading.value}
            >
              {$t("mine_userinfo_confirmSubmission" /*确认提交*/)}
            </Button>
          </div>
        </Form>
      </div>
    );
  },
});
