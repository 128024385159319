import { reactive, computed } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { TimezoneItem } from "@kgsport-cms/service";
import { Api, awaitWrap, useToast } from "core";
dayjs.extend(utc);
dayjs.extend(timezone);

import { CMS_IN18N_LANG, CMS_TIME_ZONE, useLocalStorage } from "core";

const { localStore } = useLocalStorage();

type DayjsStoreType = {
  timezoneList: TimezoneItem[];
  currentTimezone: string;
  timezoneMap: Record<string, string>;
};

const DEFAULT_TIMEZONE = "Asia/Shanghai";
const DEFAULT_LANG = "en";

const dayjsStore = reactive<DayjsStoreType>({
  timezoneList: [],
  currentTimezone: DEFAULT_TIMEZONE,
  timezoneMap: {},
});

let isLoad = false;

const { toast } = useToast();

export function useDayjs() {
  const timezoneList = computed(() => dayjsStore.timezoneList);
  const currentTimezone = computed(() => getCurrentTimezone());
  const timezoneMap = computed(() => dayjsStore.timezoneMap);

  const initDayjs = () => {
    const timeZone = localStore.get(CMS_TIME_ZONE) || window.CONFIG?.defaultTimezone;
    if (timeZone) {
      dayjs.tz.setDefault(timeZone);
    }

    // 判断当前语言环境和日期语言是否一样，不一样需要更新
    const lang = localStore.get(CMS_IN18N_LANG);
    const dayjsLang = dayjs.locale();
    if (lang && lang !== dayjsLang) {
      loadDayjsLocale(lang);
      return dayjs;
    }

    const df = (window.CONFIG?.defaultLang || DEFAULT_LANG).toLocaleLowerCase();
    if (!lang && df !== dayjsLang) {
      loadDayjsLocale(lang);
      return dayjs;
    }

    return dayjs;
  };

  // 加载日期语言
  const loadDayjsLocale = async (lang: string) => {
    try {
      if (!lang) return;
      const dayjsLang = lang.toLocaleLowerCase();
      await import(`dayjs/locale/${dayjsLang}.js`);
      dayjs.locale(dayjsLang);
    } catch (error) {
      const df = (window.CONFIG?.defaultLang || DEFAULT_LANG).toLocaleLowerCase();
      await import(`dayjs/locale/${df}.js`);
      dayjs.locale(df);
      console.warn("[dayjs]:Failed to load date language resource");
    }
  };

  // 当前时区
  const getCurrentTimezone = () => {
    const timeZone = localStore.get(CMS_TIME_ZONE);
    return timeZone ? timeZone : window.CONFIG?.defaultTimezone || dayjs.tz.guess();
  };

  // 切换时区
  const changeDayjsTimezone = (timezone: string | undefined = DEFAULT_TIMEZONE) => {
    toast.loading();
    localStore.set(CMS_TIME_ZONE, timezone);
    window.history.go(0);
  };

  // 转换时区
  const formatTimezone = (timezoneStr: string): string[] => {
    if (!timezoneStr) return [];
    const data = timezoneStr.split("_");
    if (data.length) {
      return [data.shift() as string, data.join("_")];
    }
    return [];
  };

  // 获取时区列表
  const requestTimezoneList = async (isCache = true) => {
    if (isLoad) return;
    if (isCache && dayjsStore.timezoneList.length) return;
    isLoad = true;
    const lang = localStore.get(CMS_IN18N_LANG);
    const df = window.CONFIG?.defaultLang || DEFAULT_LANG;
    const getlocal = lang ? lang : df;

    const [_, response] = await awaitWrap(
      Api.TimezoneService.timezoneList({ language: getlocal, type: "time_zone" })
    );

    if (response) {
      const { data, result } = response;
      if (result && data?.length) {
        const activeTimezone = data.find(item => item.seriNo.indexOf(currentTimezone.value) > -1);
        const filteData = data.filter(item => item.seriNo.indexOf(currentTimezone.value) < 0);
        activeTimezone && filteData.unshift(activeTimezone);
        dayjsStore.timezoneList = [...filteData];
        data.forEach(item => {
          const timezoneStr = formatTimezone(item.seriNo);
          timezoneStr[1] && (dayjsStore.timezoneMap[timezoneStr[1]] = timezoneStr[0]);
        });
      }
    }
    isLoad = false;
  };

  return {
    dayjs,
    timezoneList,
    currentTimezone,
    timezoneMap,
    initDayjs,
    loadDayjsLocale,
    changeDayjsTimezone,
    requestTimezoneList,
    getCurrentTimezone,
    formatTimezone,
  };
}
