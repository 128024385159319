import { useDayjs } from "core";
import { isIos } from "./browser.util";

const { dayjs, currentTimezone } = useDayjs();

export function DayToNumber(day = 0, format = "YYYY-MM-DD") {
  return dayjs.tz(dayjs().subtract(day, "day"), currentTimezone.value).format(format);
}

// 当前时区时间展示
export function currentTime(formtter = "YYYY-MM-DD HH:mm:ss") {
  return dayjs.tz(dayjs(), currentTimezone.value).format(formtter);
}
export function currentTimeNormal(formtter = "YYYY-MM-DD HH:mm:ss") {
  return dayjs().format(formtter);
}
// 判断当前时间是否为当天
export function isToday(timestamp: number | string) {
  let time = dayjs(Number(timestamp)).format("YYYY-MM-DD");
  let newTime = dayjs().format("YYYY-MM-DD");
  return time === newTime ? 0 : 1;
}

/*
 * 时间戳转日期
 */
export function timestampToDate(timestamp: number | string) {
  if (!timestamp || timestamp == 0) return "";
  return dayjs.tz(dayjs(timestamp), currentTimezone.value).format("YYYY-MM-DD");
}

/*
 * 时间戳转日期时间
 */
export function timestampToDateTime(timestamp: number) {
  if (!timestamp || timestamp == 0) return "";
  return dayjs.tz(dayjs(timestamp), currentTimezone.value).format("YYYY-MM-DD HH:mm:ss");
  // return fmtDate(timestamp, "yyyy-MM-dd hh:mm:ss");
}

/**
 *格式化日期
 * @param dateString
 * @param fmt
 * @returns {*}
 */
export function fmtDate(dateString: string | number, fmt: string): string {
  const date = new Date(dateString);
  const o: any = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds(),
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}

/**
 * 两个日期天数差
 */
export function timesDiffToDays(timestamp: number, startDate: number = dayjs().valueOf()) {
  if (!timestamp || timestamp == 0) return 0;
  const today = timestampToDate(startDate || 0);
  const registerTime = timestampToDate(timestamp || 0);
  return dayjs(today).diff(dayjs(registerTime), "day");
}

/**
 * 时区列表时间转换dd
 * @param timeZone 时区标示
 * @returns 时间+星期
 */
export function nowConvertByTimezone(timeZone: string) {
  try {
    const date = dayjs(dayjs().valueOf()).tz(timeZone);
    return `${date.format("DD-MM")} (${date.format("dddd")})`;
  } catch (error) {
    return "--";
  }
}
/*
  视频进度条时间格式化
 */
export const secondToTime = (second: number): string => {
  second = second || 0;
  if (second === 0 || second === Infinity || second.toString() === "NaN") {
    return "00:00:00";
  }
  const add0 = (num: any) => (num < 10 ? "0" + num : "" + num);
  const hour = Math.floor(second / 3600);
  const min = Math.floor((second - hour * 3600) / 60);
  const sec = Math.floor(second - hour * 3600 - min * 60);
  return (hour > 0 ? [hour, min, sec] : ["0", min, sec]).map(add0).join(":");
};

// 标准时间转字符串
export const timeToString = (time: string | number, format = "YYYY-MM-DD") => {
  return dayjs.tz(dayjs(time), currentTimezone.value).format(format);
};

// 标准时间转字符串
export const dateToTime = (time: string | number) => {
  return dayjs.tz(dayjs(time), currentTimezone.value).format("HH:mm");
};

/**
 * 增加时间
 * @param num 增加的数量
 * @param type 类型 d日，w周，M月，Q季度，y年，h小时，m分钟，s秒，ms毫秒
 * @returns 时间戳
 */
export const dateAddByType = (num = 1, type = "d" as any) => {
  return dayjs().add(num, type).valueOf();
};
/**
 * 时间格式化
 */
export function toTime(time: Date | number | string, format = "YYYY/MM/DD HH:mm:ss") {
  if (!time) return;
  const formatTime = dayjs.tz(dayjs(time), currentTimezone.value).format(format);
  if (formatTime === "Invalid Date") {
    return time;
  }
  return formatTime;
}
export function toTimeNormal(time: Date | number | string, format = "YYYY/MM/DD HH:mm:ss") {
  if (!time) return;
  const formatTime = dayjs(time).format(format);
  if (formatTime === "Invalid Date") {
    return time;
  }
  return formatTime;
}

/**
 * 今日23点时间戳
 */
export function todayTime() {
  const today = `${timeToString(dayjs().valueOf())} 23:59:59`;
  return dayjs.tz(dayjs(today), currentTimezone.value).valueOf();
}

/*
 * 判断是否满足18岁
 * time : 毫秒级时间戳，用户选中日期的时间戳
 * */
export function isAdult(time: number) {
  const nowDate = dayjs(); // 当前时间
  const mouth = nowDate.month() + 1;
  let day = nowDate.day();
  const year = nowDate.year() - 18;
  if (mouth === 2 && day >= 28) {
    if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
      day = 29;
    } else {
      day = 28;
    }
  }
  // 判断安卓&&IOS
  let oldDate;
  if (isIos) {
    oldDate = dayjs(year + "/" + mouth + "/" + day).valueOf();
  } else {
    oldDate = dayjs(year + "-" + mouth + "-" + day).valueOf();
  }
  return oldDate < time ? true : false; // true 表示不满18
}

// 获取美东时间
export function getNowFormatDate(type?: number) {
  const date = new Date();
  const seperator2 = ":";
  const _year = date.getFullYear();
  const _mouth = date.getMonth() + 1;
  const _date = date.getDate();
  let miuntes = "0";
  let seconds = "0";
  if (date.getMinutes() < 10) {
    miuntes = "0" + date.getMinutes();
  } else {
    miuntes = date.getMinutes().toString();
  }
  if (date.getSeconds() < 10) {
    seconds = "0" + date.getSeconds();
  } else {
    seconds = date.getSeconds().toString();
  }
  let hours = date.getHours() - 12;
  if (hours < 0) {
    hours = 24 + hours;
  }
  const currentdate = hours + seperator2 + miuntes + seperator2 + seconds;
  return type === 1
    ? currentdate
    : type === 2
    ? `${_year}-${_mouth}-${_date} ${currentdate}`
    : `${_year}/${_mouth}/${_date} ${currentdate}`;
}

// 获取某个月第一天
export const dateOfStartDay = (time: string | number, format = "YYYY-MM") => {
  return dayjs(time).startOf("month").format(format);
};
// 获取某个月最后一天
export const dateOfEndDay = (time: string | number, format = "YYYY-MM") => {
  return dayjs(time).endOf("month").format(format);
};
// 获取某星期第一天
export const dateOfStartWeekDay = (time: string | number, format = "YYYY-MM-DD") => {
  return dayjs(time).startOf("week").format(format);
};
// 获取某个星期最后一天
export const dateOfEndWeekDay = (time: string | number, format = "YYYY-MM-DD") => {
  return dayjs(time).endOf("week").format(format);
};
// 标准时间转字符串
export const searchTimeToString = (time: string | number, format = "YYYY-MM-DD") => {
  return dayjs(time).format(format);
};
// 获取当前毫秒
export const getCurrentTime = () => {
  return dayjs().valueOf();
};
