export type AreaItemType = {
  lang: string; // 语言
  currency: string; // 货币
};

export const IpAreaMap: Record<string, AreaItemType> = {
  // 美国
  US: {
    lang: "en",
    currency: "USD",
  },
  // 泰国
  TH: {
    lang: "th",
    currency: "THB",
  },
  //越南语
  VN: {
    lang: "vi",
    currency: "VND",
  },
  //缅甸语
  MM: {
    lang: "my",
    currency: "MMK",
  },
  // 马来西亚
  MY: {
    lang: "ms",
    currency: "MYR",
  },
  // 印尼
  ID: {
    lang: "id",
    currency: "IDR",
  },
  // 中国
  CN: {
    lang: "zh-CN",
    currency: "CNY",
  },
  // 日语
  JP: {
    lang: "ja",
    currency: "JPY",
  },
  // 韩语
  KR: {
    lang: "ko",
    currency: "KRW",
  },
  // 香港
  HK: {
    lang: "zh-TW",
    currency: "HKD",
  },
  // 西班牙
  ES: {
    lang: "es",
    currency: "EUR",
  },
  // 葡萄牙
  PT: {
    lang: "pt",
    currency: "EUR",
  },
  // 土耳其
  TR: {
    lang: "tr",
    currency: "TRY",
  },
  // 德国
  DE: {
    lang: "de",
    currency: "EUR",
  },
  // 法国
  FR: {
    lang: "fr",
    currency: "EUR",
  },
  // 俄国
  RU: {
    lang: "ru",
    currency: "EUR",
  },
  // 英国
  GB: {
    lang: "en",
    currency: "GBP",
  },
  // 印地
  IN: {
    lang: "hi",
    currency: "EUR",
  },
};

// 欧洲国家code
export const EuCodes = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
];

// 欧元货币code
export const EuCurrency = "EUR";
