import { Form, Select, FormInstance, message } from "ant-design-vue";
import { reactive, computed, watch, ref } from "vue";
import {
  Api,
  usePath,
  useUser,
  useAsync,
  qqRule,
  useAuth,
  useToast,
  wechatRule,
  passwordRule,
  confirmPasswordRule,
  phoneNumberRule,
  phoneNumberRule2,
  emailRule,
  emailRule2,
  requireRule,
  verificationCodeRule,
  jsencrypt,
  usernameRule,
  dutyRule,
  verificationCodeRule3,
  verificationCodeRule4,
  useConfigContext,
  useEvent,
  useI18n,
  useConfig,
  langugItem,
  useCountDown,
  captchaRule,
  useCommon,
  useGoogleAnaltics,
  usePointStatistics,
} from "core";
import { mangoPopup } from "components";
import { useDebounceFn } from "@vueuse/core";

const useForm = Form.useForm;
export interface FormItemOption {
  key: string;
  rules: any[];
  name: string;
  slot?: (props: any) => JSX.Element;
  isRequired: boolean;
  text?: string;
}
interface FormItem {
  [key: string]: FormItemOption;
}
interface Test {
  key: string;
  value: string;
  sort: number;
}
interface FormConfig {
  items: FormItemOption[];
}

const store = {
  isMango: false, // 为true标识在mango租户下，需要在页面开启，这里注册后采用刷新页面重新拉去数据
};
// 获取注册配置 生成注册表单
export function useRegister() {
  const { $t } = useI18n();
  const { goHome } = usePath();
  const { getUserInfo } = useUser();
  const { userClickRegister, userRegisterfinish } = useGoogleAnaltics();
  const { registerSuccessStatistice } = usePointStatistics();
  const { loginEvent } = useEvent();
  const { config, rccode, getCurrencyList } = useConfigContext();
  const { count, start } = useCountDown();
  const { isPc, defaultCode, requestIpArea, config: systemConfig } = useConfig();
  const { captchaCode } = useCommon();
  const isMango = window.CONFIG?.currentModel === "mango";
  const isShowImgCode = computed(() => window?.CONFIG?.isShowImgCode ?? false);
  const formRef = ref<FormInstance | null>(null);
  // 税号
  const dutyFields = {
    dutyCode: "taxType",
    dutyNumber: "taxNum",
  };
  const getCaptchaCode = async () => {
    const data = await captchaCode();
    // 用户注册 captchaKey  手机注册需要绑定 phoneModelRef
    modelRef.captchaKey = data.captchaKey;
  };
  // 配置表单字段映射 以及相关校验规则
  const options = [
    {
      value: "+86",
      label: "+86",
    },
    {
      value: "+63",
      label: "+63",
    },
  ];
  const option: FormItem = {
    qqOption: {
      key: "qq",
      rules: qqRule(),
      name: "QQ",
      isRequired: false,
    },
    weChatOption: {
      key: "wechat",
      rules: wechatRule(),
      name: $t("user_verify_wx" /*微信号*/),
      isRequired: false,
    },
    // testAccountAmount: {
    //   key: "testAccountAmount",
    //   rules: usernameRule(),
    //   name: "试玩账号金额",
    //   isRequired: false,
    // },
    phoneOption: {
      key: "phone",
      rules: phoneNumberRule(),
      name: $t("user_verify_phone" /*手机号*/),
      slot: props => <Select v-model:value={props.dialCode} options={options} />,
      isRequired: false,
    },
    emailOption: {
      key: "email",
      rules: emailRule(),
      name: $t("mine_userinfo_email" /*邮箱*/),
      isRequired: false,
    },
    realNameOption: {
      key: "realName",
      rules: [],
      name: $t("mine_usersetup_changeWithdrawalPass" /*真实姓名*/),
      isRequired: false,
    },
    intrCodeOption: {
      key: "intrCode",
      rules: [],
      name: $t("user_register_referralCode" /*推荐码*/),
      isRequired: false,
    },
    birthdayOption: {
      key: "birthday",
      rules: [],
      name: $t("mine_userinfo_birthday" /*生日*/),
      isRequired: false,
    },
    rePassWord: {
      key: "confirmPassword",
      rules: [],
      name: "",
      isRequired: false,
    },
  };
  const { setToken } = useAuth();
  const check = reactive({
    provision: false,
  });

  const tabsActive = ref("account"); // phone
  // 表单引用
  const modelRef = reactive<any>({
    dialCode: defaultCode.value || "+86",
    smsCode: "",
    username: "",
    password: "",
    captchaKey: "",
    code: "",
    confirmPassword: "",
    currency: "USD",
    language: "en",
    emailCode: "",
    [dutyFields.dutyCode]: "CNPJ",
    [dutyFields.dutyNumber]: "",
  });

  // geet数据
  const geetFrom = reactive<any>({
    geetest_challenge: "",
    geetest_validate: "",
    geetest_seccode: "",
    status: 0,
  });
  const agreement = reactive({
    value: true,
    tip: false,
  });
  watch(
    () => agreement.value,
    n => {
      agreement.tip = !n;
    }
  );
  // 添加测试
  // 表单相关配置以及字段映射
  const formConfig = reactive<FormConfig>({
    items: [
      {
        key: "username",
        rules: usernameRule(),
        text: $t("user_register_userName" /**用户名 */),
        name: $t("user_register_pleaseInputUserName" /*请输入用户名*/),
        isRequired: true,
      },
      {
        key: "password",
        rules: passwordRule(),
        text: $t("user_register_userPassword" /**用户密码 */),
        name: $t("user_findPassword_pleaseInputPassword" /*请输入密码*/),
        isRequired: true,
      },
    ],
  });
  if (isShowImgCode.value) {
    formConfig.items.push({
      key: "code",
      rules: captchaRule(),
      text: $t("funds_recharge_imgVerificationCode" /**图形验证码 */),
      name: $t("funds_recharge_inputVerificationCode" /*请输入图形验证码*/),
      isRequired: true,
    });
  }
  if (getCurrencyList.value?.length > 1) {
    formConfig.items.push(
      {
        key: "language",
        rules: [],
        text: $t("user_register_languagesKind" /**语种 */),
        name: $t("user_register_languagesKind" /*语种*/),
        isRequired: true,
      },
      {
        key: "currency",
        rules: [],
        text: $t("user_register_currency" /**币种 */),
        name: $t("user_register_currency" /*币种*/),
        isRequired: true,
      }
    );
  }
  if (systemConfig.isTaxFlag && window.CONFIG?.currentModel === "mango") {
    formConfig.items.push({
      key: "duty",
      rules: [],
      text: $t("user_register_duty" /*税号*/),
      name: $t("user_register_inputDutyCode" /*请输入税号*/),
      isRequired: true,
    });
  }
  const rulesRef = reactive<any>({
    username: usernameRule(),
    password: passwordRule(),
    code: isShowImgCode.value ? captchaRule() : [],
    [dutyFields.dutyNumber]:
      systemConfig.isTaxFlag && window.CONFIG?.currentModel === "mango" ? dutyRule() : [],
  });

  const loading = ref<boolean>(false);
  const { data } = useAsync(() => Api.ConfigService.registerConfig());
  const formList = computed<Test[]>(
    () => data.value?.sort((a: Test, b: Test) => (a.sort || 0) - (b.sort || 0)) || []
  );
  const emailOption = computed<Test>(
    () => data.value.find((d: Test) => d.key === "emailOption") || {}
  );
  const phoneOption = computed<Test>(
    () => data.value.find((d: Test) => d.key === "phoneOption") || {}
  );
  const initForm = () => {
    const base = Object.create(null);
    const rePassWord = formList.value.find(d => d.key === "rePassWord");
    const hasRePassWord = rePassWord && rePassWord.value === "1";
    if (hasRePassWord) {
      // 确认密码
      const baseItem = option["rePassWord"];
      const index = formConfig.items.findIndex(d => d.key === "password");
      baseItem.key = "confirmPassword";
      baseItem.rules = [...passwordRule(), ...confirmPasswordRule(modelRef)];
      if (rePassWord.value === "1") {
        baseItem.rules.unshift(requireRule());
      }
      rulesRef[baseItem.key] = [...baseItem.rules];
      formConfig.items.splice(index + 1, 0, baseItem);
    }
    // item.value 0 非必填， 1 必填， 2 隐藏
    formList.value
      ?.filter(d => d.key !== "rePassWord")
      .forEach(item => {
        const baseItem = option[item.key];
        if (baseItem && Number(item.value) < 2) {
          base[baseItem.key] = "";
          baseItem.name =
            baseItem.name +
            `${
              item.value === "0"
                ? `(${$t("user_register_optional" /*选填*/)})`
                : `(${$t("user_register_required" /*必填*/)})`
            }`;
          baseItem.isRequired = item.value === "1";
          baseItem.text = baseItem.name;
          if (item.key === "realNameOption") {
            // 如果key是realNameOption 则在密码后插入realNameOption
            const index = hasRePassWord
              ? formConfig.items.findIndex(d => d.key === "confirmPassword")
              : formConfig.items.findIndex(d => d.key === "password");
            formConfig.items.splice(index + 1, 0, baseItem);
          } else if (item.key === "emailOption") {
            baseItem.key = "email";
            rulesRef.email = item.value === "1" ? emailRule() : emailRule2();
            rulesRef.emailCode =
              item.value === "1" ? verificationCodeRule() : verificationCodeRule4();
            formConfig.items.push(baseItem);
          } else if (item.key === "phoneOption") {
            // 后台开启才显示手机项
            baseItem.key = "phone";
            rulesRef.phone = item.value === "1" ? phoneNumberRule() : phoneNumberRule2();
            rulesRef.smsCode =
              config.smsSwitch && item.value === "1"
                ? verificationCodeRule()
                : verificationCodeRule4();
            formConfig.items.push(baseItem);
          } else {
            formConfig.items.push(baseItem);
          }
          formConfig.items.forEach(item => {
            if (item.isRequired) {
              rulesRef[item.key] = [...item.rules];
              if (rulesRef[item.key].length === 0) {
                rulesRef[item.key].unshift(requireRule());
              }
            }
          });
          base.intrCode = rccode.value;
          Object.assign(base, {
            password: "",
            geetest_seccode: "",
            geetest_validate: "",
            geetest_challenge: "",
          });
          Object.assign(modelRef, base);
        }
      });
    if (isMango) {
      const phoneIndex = formConfig.items.findIndex(d => d.key === "phone");
      phoneIndex > -1 && formConfig.items.unshift(...formConfig.items.splice(phoneIndex, 1));
      const codeIndex = formConfig.items.findIndex(d => d.key === "code");
      formConfig.items.push(...formConfig.items.splice(codeIndex, 1));
    }
  };
  const { toast } = useToast();
  console.log(modelRef, rulesRef, "ddd");
  const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);
  // 其他验证如手机、邮箱
  const otherVerification = useForm(modelRef, {
    phone: phoneNumberRule(),
    smsCode: verificationCodeRule(),
    email: emailRule(),
    emailCode: verificationCodeRule(),
  });

  watch(
    () => formList.value,
    () => {
      initForm();
      setTimeout(() => {
        otherVerification.clearValidate();
      });
    },
    { deep: true }
  );

  // 打开验证器
  const openCaptcha = async () => {
    console.log(formConfig);
    // 谷歌统计
    userClickRegister(modelRef.intrCodeOption || "webroot");
    try {
      tabsActive.value === "account" ? await validate() : await phoneForm.validate();
      if (isMango) {
        if (!agreement.value) {
          agreement.tip = true;
          if (isPc.value) {
            toast.error($t("user_login_agreement_text" /*请接受服务条款*/));
          }
          return;
        }
      }
      loading.value = true;
      // 未配置验证器
      // if (!config.captchaType) {
      await registerSubmit();
      // }
      // 配置了验证器
      // const prams = { name: config.captchaType, clientType: "web" };
      // const { data, result } = await Api.CommonService.captchaCode(prams);
      // if (result) {
      //   const captcha = new Captcha({
      //     type: config.captchaType,
      //     config: {
      //       gt: data.gt,
      //       challenge: data.challenge,
      //     },
      //     onSuccess: async (prams: any) => {
      //       geetFrom.geetest_challenge = prams.geetest_challenge;
      //       geetFrom.geetest_seccode = prams.geetest_seccode;
      //       geetFrom.geetest_validate = prams.geetest_validate;
      //       await registerSubmit();
      //     },
      //     onClose: () => (loading.value = false),
      //     onFail: () => (loading.value = false),
      //   });
      //   await captcha.open();
      // }
    } catch (error: any) {
      loading.value = false;
      formRef.value?.scrollToField(error.errorFields[0].name, {
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const registerSubmit = async () => {
    try {
      let res;
      if (tabsActive.value === "account") {
        // 账号注册
        const { confirmPassword, ...other } = modelRef;
        const info = Object.assign({}, other);
        info.password = await jsencrypt(info.password);
        confirmPassword && (info.rePassWord = await jsencrypt(confirmPassword));
        res = await Api.AuthService.register({ ...info, ...geetFrom });
      } else {
        // 手机注册
        await phoneForm.validate();
        loading.value = true;
        const password = await jsencrypt(phoneModelRef.password);
        const rePassWord = await jsencrypt(phoneModelRef.confirmPassword);
        res = await Api.AuthService.phoneRegister({
          ...phoneModelRef,
          password,
          rePassWord,
          ...geetFrom,
        });
      }
      const { result, data } = res;
      loading.value = false;
      if (!result) {
        return;
      }
      registerSuccessStatistice();
      userRegisterfinish();
      setToken(data.accessToken);
      await getUserInfo(false);
      loginEvent.emit(true);
      if (window.CONFIG?.currentModel === "mango") {
        mangoPopup().destroyAll();
        window.location.reload();
      } else {
        await goHome();
      }
    } catch (e) {
      console.error("registerSubmit", e);
    } finally {
      loading.value = false;
    }
  };

  // 手机注册
  const phoneModelRef = reactive({
    phone: "",
    dialCode: "+86",
    smsCode: "",
    username: "",
    password: "",
    confirmPassword: "",
    currency: "USD",
    language: "en",
    captchaKey: "",
    code: "",
    [dutyFields.dutyCode]: "",
    [dutyFields.dutyNumber]: "",
  });
  const phoneRulesRef = reactive<any>({
    phone: phoneNumberRule(),
    smsCode: verificationCodeRule3(),
    username: usernameRule(),
    password: passwordRule(),
    // confirmPassword: [...passwordRule(), ...confirmPasswordRule(phoneModelRef)],
    code: isShowImgCode.value ? captchaRule() : [],
  });
  const phoneForm = useForm(phoneModelRef, phoneRulesRef);

  // 注册界面公共交互hooks
  const dataStore = reactive({
    form: {
      money: $t("common_usd" /**美元 */),
      moneyCode: "USD",
      seriNo: "en",
      info: "英语",
    },
    togglePassword: false,
    toggleRePassword: false,
    toggleLanguage: false,
    isMounted: false,
    popType: 1, // 1 币种  2 语种
    toggleAreaCode: false,
    validate: 0,
  });
  // 默认当前币种
  const currentCurrency = reactive({
    name: $t("common_usd" /**美元 */),
    code: "USD",
  });
  // 默认当前语言
  const currentLangue = reactive({
    name: "英语",
    code: "en",
  });
  // 监听币种回调逻辑处理（默认值）
  const setCurrentCurrency = async (c: string) => {
    if (currentCurrency.code === c) return;
    const { code, name = "" } = getCurrencyList.value?.find?.(d => d.code === c) ?? {};
    if (!code) return;
    currentCurrency.code = code;
    currentCurrency.name = name;
    dataStore.form.money = name;
    dataStore.form.moneyCode = code;
    modelRef["currency"] = code;
  };
  // 监听语言回调处理（默认值）
  const setCurrentLangue = async (list: langugItem[]) => {
    let data = {} as langugItem;
    // 注册优先获取ip区域数据，如果没有匹配上，使用租户默认配置的语言
    const ipAreaConfig = await requestIpArea();
    if (Object.keys(ipAreaConfig).length) {
      data = list.find(d => d.seriNo === ipAreaConfig.lang) ?? ({} as langugItem);
    }

    if (!Object.keys(data).length) {
      data = list.find(d => d.seriNo === config.defaultLang) ?? ({} as langugItem);
    }

    const { seriNo, info = "" } = data ?? {};
    if (!seriNo) return;
    currentLangue.code = seriNo;
    currentLangue.name = info;
    dataStore.form.info = info;
    dataStore.form.seriNo = seriNo;
    modelRef["language"] = seriNo;
  };
  // 查看密码动态交互
  const eye = computed(() => (dataStore.togglePassword ? "eyeShow" : "eyeHide"));
  const reEye = computed(() => (dataStore.toggleRePassword ? "eyeShow" : "eyeHide"));
  // 当前币种显示文案
  const currentCurrencyText = computed<string>(
    () => `${dataStore.form.money}(${dataStore.form.moneyCode})`
  );
  // 点击语种弹出弹窗
  const selectPop = (type: number) => {
    dataStore.popType = type;
    dataStore.toggleLanguage = true;
  };
  // 点击币种列表选中回调
  const clickCurrencyLists = ({ name, code }: { name: string; code: string }) => {
    dataStore.toggleLanguage = false;
    currentCurrency.code = code;
    currentCurrency.name = name;

    dataStore.form.money = currentCurrency.name;
    dataStore.form.moneyCode = currentCurrency.code;
    modelRef["currency"] = currentCurrency.code;
  };
  // 点击语言列表选中回调
  const clickLangueLists = ({ info, seriNo }: { info: string; seriNo: string }) => {
    dataStore.toggleLanguage = false;
    currentLangue.name = info;
    currentLangue.code = seriNo;
    dataStore.form.info = currentLangue.name;
    dataStore.form.seriNo = currentLangue.code;
    modelRef["language"] = currentLangue.code;
  };

  // 取消选择
  const cancelSelect = () => {
    dataStore.toggleLanguage = false;
    if (dataStore.popType === 1) {
      currentCurrency.name = dataStore.form.money;
      currentCurrency.code = dataStore.form.moneyCode;
    } else {
      currentLangue.name = dataStore.form.info;
      currentLangue.code = dataStore.form.seriNo;
    }
  };
  // 确认选择（h5逻辑）
  const confirmSelect = () => {
    dataStore.toggleLanguage = false;
    if (dataStore.popType === 1) {
      dataStore.form.money = currentCurrency.name;
      dataStore.form.moneyCode = currentCurrency.code;
      modelRef["currency"] = currentCurrency.code;
    } else {
      dataStore.form.info = currentLangue.name;
      dataStore.form.seriNo = currentLangue.code;
      modelRef["language"] = currentLangue.code;
    }
  };

  // 监听默认语种改变
  watch(
    () => config.langueList,
    n => {
      if (!n?.length) return;
      setCurrentLangue(n);
    },
    { immediate: true }
  );
  // 监听注册默认币种改变
  watch(
    [() => config.registerDefaultCurrency, () => getCurrencyList.value],
    async ([currency]) => {
      if (!currency) return;
      const ipAreaConfig = await requestIpArea();
      if (Object.keys(ipAreaConfig).length) {
        if (getCurrencyList.value.map(v => v.code).includes(ipAreaConfig.currency)) {
          setCurrentCurrency(ipAreaConfig.currency);
          return;
        }
      }
      setCurrentCurrency(currency);
    },
    { immediate: true }
  );

  watch(
    () => defaultCode.value,
    (n, o) => {
      if (n === o) return;
      phoneModelRef.dialCode = n;
      modelRef.dialCode = n;
    },
    { immediate: true }
  );

  //发送邮箱验证码
  const getEmailCode = async (params: any) => {
    try {
      await validate(["email"]);
      const { result = false } = (await Api.UserService.getEmailVerifyCode(params)) ?? {};
      if (!result) {
        return false;
      }
      toast.success($t("user_login_sendVerificationCode" /*发送验证码*/));
      start(60);
      return true;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    tabsActive,
    formRef,
    formList,
    emailOption,
    phoneOption,
    check,
    formConfig,
    modelRef,
    resetFields,
    validate,
    validateInfos,
    loading,
    options,
    openGeetestVerifier: useDebounceFn(openCaptcha, 1000, { maxWait: 1000 }),
    phoneModelRef,
    phoneRulesRef,
    phoneForm,
    dataStore,
    currentLangue,
    currentCurrencyText,
    currentCurrency,
    eye,
    reEye,
    cancelSelect,
    clickCurrencyLists,
    clickLangueLists,
    selectPop,
    confirmSelect,
    otherVerification,
    count,
    getEmailCode,
    getCaptchaCode,
    agreement,
    isShowImgCode,
    dutyFields,
  };
}
