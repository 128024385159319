import { computed, defineComponent, PropType } from "vue";
import dayjs from "dayjs";
import type { OrderDetails } from "@kgsport-cms/service";
import style from "./style.module.less";
import { useWallet, useI18n, useUser, useWithdraw, useClipboards } from "core";

export default defineComponent({
  name: "OrderInfo",
  props: {
    type: {
      type: String,
      default: "B",
    },
    orderDetails: {
      type: Object as PropType<OrderDetails>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const { user } = useUser();
    const { cashStatusFormat, methodFormat } = useWithdraw();
    const { amountFormat } = useWallet();
    const { copyFn } = useClipboards();
    const unit = computed(() => user.value?.currency ?? "USD");
    // cashOrderNo
    const copy = async (e: TouchEvent) => {
      const orderNumber = (e.target as HTMLElement).previousSibling?.textContent ?? "";
      if (!orderNumber) return;
      await copyFn(orderNumber);
    };
    const createTime = computed(() =>
      props.orderDetails.createTime
        ? dayjs(props.orderDetails.createTime).format("YYYY-MM-DD hh:mm:ss")
        : ""
    );

    const withdrawalMethod = computed(() => methodFormat[props.orderDetails.cashMode]);

    const cashStatus = computed(() => cashStatusFormat[props.orderDetails.cashStatus]);
    return () => {
      const { cashOrderNo, bankName, bankCard, counterFee = 0 } = props.orderDetails;
      return (
        <div class={style.orderInfo}>
          <section class={style.info}>
            <article class={style.label}>{$t("funds_withdraw_orderNo" /* 订单号 */)}</article>
            <article class={style.dtl}>
              <span class={style.dtlNo}>{cashOrderNo}</span>
              <span class={style.green} onTouchend={copy}>
                {$t("common_copy" /* 复制 */)}
              </span>
            </article>
          </section>
          <section class={style.info}>
            <article class={style.label}>{$t("funds_withdraw_bizTime" /* 提现时间 */)}</article>
            <article class={style.dtl}>{createTime.value}</article>
          </section>
          <section class={style.info}>
            <article class={style.label}>{$t("funds_withdraw_bizType" /* 提现方式 */)}</article>
            <article class={style.dtl}>{withdrawalMethod.value}</article>
          </section>
          {props.type === "B" && (
            <>
              <section class={style.info}>
                <article class={style.label}>{$t("funds_withdraw_bizBank" /* 提现银行 */)}</article>
                <article class={style.dtl}>{bankName}</article>
              </section>
              <section class={style.info}>
                <article class={style.label}>
                  {$t("funds_withdraw_bizBankCard" /* 提现卡号 */)}
                </article>
                <article class={style.dtl}>{bankCard}</article>
              </section>
            </>
          )}
          <section class={style.info}>
            <article class={style.label}>{$t("funds_withdraw_bizFree" /* 提现手续费 */)}</article>
            <article class={[style.dtl, style.serviceCharge].join(" ")}>
              <span class={style.amount}>{amountFormat(Number(counterFee))}</span>
              <span class={style.unit}>({unit.value})</span>
            </article>
          </section>
          <section class={style.info}>
            <article class={style.label}>{$t("funds_withdraw_bizStatus" /* 提现状态 */)}</article>
            <article class={style.dtl}>
              <span class={style.green}>{cashStatus.value}</span>
            </article>
          </section>
        </div>
      );
    };
  },
});
