import { defineComponent } from "vue";
import style from "./style.module.less";
import { useI18n } from "core";

export default defineComponent({
  name: "SpeedProgress",
  props: {
    active: {
      type: String,
      default: "1",
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const data = [
      { id: "1", text: $t("funds_withdraw_stepOne" /* 发起申请 */) },
      { id: "2", text: $t("funds_withdraw_stepTwo" /* 审核中 */) },
      { id: "3", text: $t("funds_withdraw_stepThree" /* 发起到账 */) },
    ];
    return () => (
      <div class={style.addpadding}>
        <div class={style.speedProgress}>
          {data.map((item, index) => (
            <section
              key={item.id}
              class={[style.progress, props.active === item.id && style.progressActive]}
            >
              <span>
                {index + 1}.{item.text}
              </span>
              {/* {props.active === item.id && <img src={ArrowRight} alt="" />} */}
            </section>
          ))}
        </div>
      </div>
    );
  },
});
