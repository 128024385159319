import { defineComponent, ref } from "vue";
import { useWithdraw, useWallet } from "core";
import style from "./style.module.less";

import Wallet from "./components/Wallet";
import Main from "./components/Main";

export default defineComponent({
  name: "MobileApplyWithdrawal",
  props: {
    isPay: Boolean,
  },
  emits: ["close"],
  setup(prop, { emit }) {
    const { getUserWalletList } = useWallet();
    const { query } = useWithdraw();
    const isMounted = ref(false);
    (async () => {
      await Promise.all([getUserWalletList(), query()]);
      isMounted.value = true;
    })();

    return () => [
      <div class={style.applyWithdrawal}>
        <Wallet />
        {isMounted.value && <Main isPay={prop.isPay} onClose={() => emit("close")} />}
      </div>,
    ];
  },
});
