import { computed, defineComponent, ref, reactive, watch, onBeforeMount } from "vue";
import { Form } from "ant-design-vue";
import { PasswordInput, NumberKeyboard } from "vant";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import { mangoPopup, MangoTipsModal } from "components";
import style from "./style.module.less";
import exclamationMark from "../../assets/exclamationMark.png";
import Button from "../../../components/Button";
import Input, { Rule } from "../Input";
import CustomPicker from "../Picker";
import TabButton from "../TabButton";
import {
  useI18n,
  useWallet,
  useWithdraw,
  useUser,
  saveParams,
  tipModalData,
  getSlice,
  useConfig,
  useToast,
} from "core";
import { Item as BankListItem, CountryCodeListItem } from "@kgsport-cms/service";
import { Popup, List } from "vant";
import RadioCircle from "../RadioCircle";
import WithdrawApply from "../../../MobileWithdrawalApplySuccess";
// 税号弹窗
import MangoDutyCodeSelect from "templates/mango/Mobile/components/DutyCodeSelect";
export default defineComponent({
  name: "MainCom",
  props: {
    isPay: Boolean,
  },
  emits: ["pickerChange", "close"],
  setup(prop, { emit }) {
    const { $t } = useI18n();
    const { balance, amountFormat, scaleAmountValue, getUserWalletList } = useWallet();
    const {
      getCountry,
      countryCode,
      captchaImg,
      withdrawLimitData,
      captchaCode,
      serviceAmount,
      amountReceived,
      validateInfos,
      clearValidate,
      validate,
      resetFields,
      save,
      saveOrder,
      onCountyCodeChange,
      withdrawTypeBank,
      actualAmount,
      usdtRate,
      usdtValue,
      getSumDeductMoney,
      verificationCode,
      submitLoading,
      isDirect,
      query,
      withdrawTabs,
      tabChange,
    } = useWithdraw();
    const duty = reactive({
      visible: false,
    });
    const { user, getSmsCode } = useUser();
    const { config: systemConfig } = useConfig();
    const unit = computed(() => user.value?.currency ?? "");
    const isMounted = ref(false);
    (async () => {
      await Promise.all([getUserWalletList(), query()]);
      // route.params.type && (saveParams.withdrawType = route.params.type as string);
      isMounted.value = true;
    })();

    const showKeyboard = ref(false);

    // 选择国家编码
    const countrySelectShow = ref(false);
    const countryListLoading = ref(false);
    onBeforeMount(async () => {
      await getCountry();
      // countryCode.value.paging.current += 1;
    });
    const countryListLoad = async () => {
      await getCountry();
      countryCode.value.paging.current += 1;
      countryListLoading.value = false;
    };
    const countryTrigger = (e: Event) => {
      e.preventDefault();
      countrySelectShow.value = true;
    };
    const countryCancel = () => {
      countrySelectShow.value = false;
    };
    const setCountryItem = ({ code }: CountryCodeListItem) => {
      onCountyCodeChange(code);
      countryCancel();
    };
    const data = reactive({
      success: false,
      type: "",
    });
    const { toast } = useToast();
    watch(
      () => saveParams.withdrawType,
      (n, o) => {
        if (!n || n === o) return;
        clearValidate();
      },
      { immediate: true }
    );

    // 提现金额验证
    const withdrawalAmountRules = reactive<Rule[]>([
      {
        verification: value => {
          let b = balance.value || 0;
          value = getSlice(value);
          if (withdrawLimitData.value?.decimalSwitch == 1) {
            return {
              value: Number(value) <= b ? value : b + "",
              status: true,
            };
          } else {
            let newValue = Math.floor(Number(value));
            b = Math.floor(Number(b));
            return {
              value: newValue <= b ? newValue + "" : b + "",
              status: true,
            };
          }
        },
        // message: "提现金额不能大于钱包余额",
      },
    ]);
    // 提现密码验证
    const pwdRules = reactive<Rule[]>([
      {
        verification: value => {
          value = value.replace(/[^0-9a-zA-Z-]+/g, "");
          return {
            value,
            status: true,
          };
        },
      },
    ]);

    const pickerChange = async ({ id, exchangeRate = "0" }: BankListItem) => {
      saveParams.bankId = id;
      usdtRate.value = exchangeRate;
    };

    // 到下一步
    const toNext = async () => {
      data.type = saveParams.withdrawType;
      if (user.value?.cashValidate) {
        await validate();
        if (user.value?.phone) {
          await getSmsCode({ type: 3 });
          return mangoPopup({
            type: "MangoMobileWithdrawalVerification",
            header: false,
            borderRadius: "12",
          }).open();
        }
      }
      if (submitLoading.value) return;
      await save(async () => {
        data.success = true;
        emit("close");
      });
    };

    const submit = async (e: Event) => {
      e.preventDefault();
      if (!saveParams.cashMoney) {
        toast.error($t("common_validator_empty" /*输入项不能为空*/));
        return;
      }
      if (!saveParams.cashPassword) {
        toast.error($t("user_register_input_password" /*请输入密码*/));
        return;
      }
      if (systemConfig.isTaxFlag && window.CONFIG.currentModel === "mango") {
        if (!saveParams.taxNumber) {
          toast.error($t("user_register_inputDutyCode" /*请输入税号*/));
          return;
        }
        if (!/^\w{10,30}$/.test(saveParams.taxNumber)) {
          toast.error($t("common_validator_duty_rule" /*请输入10-30位字符*/));
          return;
        }
      }
      await toNext();
    };

    // 重置数据
    const resetData = () => {
      countryCode.value = {
        list: [],
        finished: false,
        paging: { size: 20, current: 1, total: 0 },
      };
      resetFields({ withdrawType: "B", cashMoney: "" });
      verificationCode.value = false;
    };
    resetData();

    // 金额比例和实际金额
    const AmountScale2ActualTips = () => {
      return (
        <div class={style.sacle}>
          <p>
            {$t("funds_currency_ratio" /*货币比例*/)} 1:{scaleAmountValue.value}
          </p>
          <p>
            {$t("funds_actual_withdrawal" /*实际提现*/)}:
            <span>{amountFormat(actualAmount.value || 0)}</span>
          </p>
        </div>
      );
    };
    const changePop = (type: string) => {
      // 弹窗功能开启 这里跳转设置提现密码
      if (type === "B") {
        mangoPopup({
          type: "BankCardManageMobile",
          header: false,
          props: { type: "B" },
        }).open();
      } else if (type === "V") {
        mangoPopup({
          type: "BankCardManageMobile",
          header: false,
          props: { type: "V" },
        }).open();
      }
    };
    return () => {
      const {
        cashCount,
        oddDaysCashTriesLimit,
        counterFeeMode,
        exceedCashNumberUpperLimitServiceCharge,
        oddDaysCashMoneyUpperLimit,
        singleCashMoneyLowerLimit,
        singleCashMoneyUpperLimit,
        cashVerification,
      } = withdrawLimitData.value;
      return (
        <div>
          {data.success && prop.isPay ? (
            <WithdrawApply type={data.type} cashOrderNo={saveOrder.value?.cashOrderNo} />
          ) : (
            <div class={style.main}>
              <div class={style.mainBox}>
                <Form.Item validateFirst={true} name="cashMoney" {...validateInfos["cashMoney"]}>
                  <div class={style.country}>
                    {withdrawTypeBank.value && countryCode.value?.list?.length > 1 && (
                      <div class={style.countryPicker} onClick={countryTrigger}>
                        <section class={style.countryName}>
                          <span>{saveParams.countyCode}</span>
                          <i
                            class={[
                              style.countryNameTriangle,
                              countrySelectShow.value && style.countryNameOpen,
                            ]}
                          >
                            <CaretDownOutlined />
                          </i>
                        </section>
                      </div>
                    )}
                    <Input
                      class={[
                        style.countryCash,
                        withdrawTypeBank.value &&
                          countryCode.value?.list?.length > 1 &&
                          style.countryCashBank,
                      ]}
                      v-model={saveParams.cashMoney}
                      type="text"
                      check="number"
                      maxlength={10}
                      placeholder={
                        $t("funds_withdraw_amout" /* 提现金额 */) +
                        `(${amountFormat(singleCashMoneyLowerLimit)}-${amountFormat(
                          singleCashMoneyUpperLimit
                        )})`
                      }
                      unit={unit.value}
                      rules={withdrawalAmountRules}
                    />
                  </div>
                </Form.Item>
                <div class={style.notice}>
                  {/* <i class={style.noticeImg}>
              <SvgIcon width="24px" height="24px" name="smallBell" />
            </i> */}
                  {window.CONFIG.name === "lam105" ? (
                    <section class={style.text}>
                      {$t("funds_withdraw_todayTimes", [cashCount])}
                      {/*今日提现[0]次*/}
                    </section>
                  ) : (
                    <section
                      class={style.text}
                      v-html={$t(
                        "funds_withdraw_todayTimesAndAllTimes",
                        [
                          `<span>${cashCount}</span>`,
                          `<span>${oddDaysCashTriesLimit}</span>`,
                        ] /* 今日提现[0]次，总计[1]次 */
                      )}
                    />
                  )}
                </div>
                <div class={style.explain}>
                  <section class={style.explainItem}>
                    <article class={style.title}>
                      {$t("funds_withdraw_bizFree" /* 提现手续费 */)}
                      <span class={style.red}>
                        {counterFeeMode
                          ? amountFormat(exceedCashNumberUpperLimitServiceCharge) + "%"
                          : amountFormat(exceedCashNumberUpperLimitServiceCharge)}
                      </span>
                    </article>
                    <article class={style.usd}>
                      {amountFormat(serviceAmount.value.serviceChargePro)} ({unit.value})
                    </article>
                  </section>
                  <section class={style.explainItem}>
                    <article class={style.title}>
                      {$t("funds_withdraw_damaFee" /* 打码量费用 */)}
                    </article>
                    <article class={style.usd}>
                      {amountFormat(getSumDeductMoney())} ({unit.value})
                    </article>
                  </section>
                  <section class={style.explainItem}>
                    <article class={style.title}>
                      {$t("funds_withdraw_amountReceived" /* 预计到账金额 */)}
                    </article>
                    <article class={style.usd}>
                      {amountFormat(amountReceived.value)} ({unit.value})
                    </article>
                  </section>
                </div>
                {!!withdrawTabs.value?.length && (
                  <TabButton
                    data={withdrawTabs.value}
                    value={saveParams.withdrawType}
                    onChange={tabChange}
                  />
                )}
                {!isDirect.value && (
                  <Form.Item name="bankId" {...validateInfos["bankId"]}>
                    <CustomPicker type={saveParams.withdrawType} onChange={pickerChange} />
                  </Form.Item>
                )}
                <div class={style.space}>
                  {!isDirect.value && !withdrawTypeBank.value && (
                    <>
                      <section class={style.exchangeRate}>
                        <img src={exclamationMark} alt="" />
                        <span>
                          {$t(
                            "funds_withdraw_exchangeRateTip" /* 汇率实时浮动，自动计算结果仅供参考 */
                          )}
                        </span>
                      </section>
                      {!!Number(usdtValue.value) && (
                        <section class={style.usdt}>
                          <article>{$t("funds_actual_credit" /*实际到账*/)}: </article>
                          <article>{amountFormat(usdtValue.value)}</article>
                        </section>
                      )}
                    </>
                  )}
                </div>
                {!window.CONFIG?.passwordType ? (
                  <Form.Item
                    validateFirst={true}
                    name={"cashPassword"}
                    {...validateInfos["cashPassword"]}
                  >
                    <Input
                      v-model={saveParams.cashPassword}
                      type="password"
                      // maxlength={16}
                      placeholder={$t("funds_withdraw_password" /* 提现密码 */)}
                      // rules={pwdRules}
                    />
                  </Form.Item>
                ) : (
                  <div class={style.mainBoxPwd}>
                    <div class={style.mainBoxPwdTitle}>
                      {$t("common_withdraw_six_pwd")}
                      {/*请输入6为提现密码*/}
                    </div>
                    <PasswordInput
                      class={style.mainBoxPwdInput}
                      value={saveParams.cashPassword as string}
                      focused={showKeyboard.value}
                      onFocus={() => (showKeyboard.value = true)}
                    />
                    <NumberKeyboard
                      show={showKeyboard.value}
                      v-model={saveParams.cashPassword}
                      teleport="body"
                      maxlength={6}
                      zIndex={2000}
                      onBlur={() => (showKeyboard.value = false)}
                    />
                  </div>
                )}
                {/* 税号 */}
                {systemConfig.isTaxFlag && window.CONFIG?.currentModel === "mango" ? (
                  <>
                    <Form.Item name={"taxNumber"} {...validateInfos["taxNumber"]}>
                      <div class={style.country}>
                        <div class={style.countryPicker}>
                          <div class={style.countryName} onClick={() => (duty.visible = true)}>
                            <span>{saveParams.taxType}</span>
                            <i
                              class={[
                                style.countryNameTriangle,
                                countrySelectShow.value && style.countryNameOpen,
                              ]}
                            >
                              <CaretDownOutlined />
                            </i>
                          </div>
                        </div>
                        <Input
                          class={[style.countryCash, style.countryCashBank]}
                          v-model={saveParams.taxNumber}
                          type="text"
                          placeholder={$t("user_register_inputDutyCode" /**请输入税号 */)}
                        />
                      </div>
                    </Form.Item>
                    {/* 税号类型弹窗 */}
                    <MangoDutyCodeSelect
                      height={330}
                      v-model:show={duty.visible}
                      currency={saveParams.taxType}
                      onChange={value => (saveParams.taxType = value)}
                    />
                  </>
                ) : null}

                {!!cashVerification && (
                  <Form.Item validateFirst={true} name={"code"} {...validateInfos["code"]}>
                    <div class={style.captchaImg}>
                      <Input
                        v-model={saveParams.code}
                        maxlength={4}
                        placeholder={$t("funds_recharge_imgVerificationCode" /* 图形验证码 */)}
                        rules={pwdRules}
                      />
                      <section class={style.img} onClick={captchaCode}>
                        <img src={captchaImg.value} alt="captchaImg" />
                      </section>
                    </div>
                  </Form.Item>
                )}
                <Input
                  v-model={saveParams.memberMemo}
                  maxlength={2000}
                  placeholder={$t("funds_recharge_remark" /* 备注 */)}
                />
                {AmountScale2ActualTips()}
                <section class={style.tip}>
                  {$t("funds_withdraw_maxTopMoney" /* 提现上限 */)}
                  <span class={style.green}> {amountFormat(singleCashMoneyUpperLimit)}</span>{" "}
                  {$t("funds_withdraw_lowLimitMoney" /* 提现下限 */)}
                  <span class={style.green}> {amountFormat(singleCashMoneyLowerLimit)}</span>{" "}
                  {$t("funds_withdraw_todayTotalMoney" /* 当天提现总额度为 */)}
                  <span class={style.red}> {amountFormat(oddDaysCashMoneyUpperLimit)}</span>
                  <br />
                  {$t("funds_withdraw_timesOverTips" /* 如果您当天提现次数超过 */)}
                  <span class={style.red}> {oddDaysCashTriesLimit}</span>，
                  {$t(
                    "funds_withdraw_timesOverMoneyTips" /* 则每次提现加手续费，手续费为提现金额的 */
                  )}
                  <span class={style.green}>
                    {counterFeeMode
                      ? exceedCashNumberUpperLimitServiceCharge + "%"
                      : exceedCashNumberUpperLimitServiceCharge}
                  </span>
                </section>
                <Popup
                  v-model:show={countrySelectShow.value}
                  round
                  position="bottom"
                  style={{ height: "60%" }}
                >
                  <div class={style.countryHeader}>
                    <section class={style.countryHeaderCancel} onClick={countryCancel}>
                      {$t("user_register_alert_cancel" /* 取消 */)}
                    </section>
                    <section class={style.countryHeaderCode}>
                      {$t("mine_select_encoding" /* 选择编码 */)}
                    </section>
                    <section></section>
                  </div>
                  <div class={style.countryMain}>
                    <List
                      v-model:loading={countryListLoading.value}
                      finished={countryCode.value.finished}
                      loading-text={$t("common_loading_text" /* 加载中... */)}
                      finished-text=""
                      onLoad={countryListLoad}
                    >
                      {countryCode.value?.list.map(option => (
                        <div
                          class={style.countryOption}
                          key={option.code}
                          onClick={() => setCountryItem(option)}
                        >
                          <section class={style.countryOptionText}>
                            <span>{option.name}</span>
                            <span>({option.code})</span>
                          </section>
                          <RadioCircle checked={saveParams.countyCode === option.code} />
                        </div>
                      ))}
                    </List>
                  </div>
                </Popup>
                <MangoTipsModal type="info" maskClosable {...tipModalData} />
              </div>
              <div class={style.mainBtn}>
                <Button
                  type="primary"
                  loading={submitLoading.value}
                  // disabled={isDisabledWithdrawBtn.value}
                  onClick={submit}
                >
                  {$t("common_submit" /* 提交 */)}
                </Button>
              </div>
            </div>
          )}
        </div>
      );
    };
  },
});
