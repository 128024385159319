import { defineComponent, onBeforeMount, ref } from "vue";
import style from "./style.module.less";
import refreshPng from "../../assets/refresh.png";
import { useI18n, useWallet } from "core";

export default defineComponent({
  name: "WalletCom",
  setup() {
    const { freeze, balance, gameTotal, amountFormat, reclaimAmount, platformBalance, venueList } =
      useWallet();
    const { $t } = useI18n();
    const refreshCenter = ref(false);
    const refreshOther = ref(false);

    // 刷新钱包
    const refreshWallet = async (v: number) => {
      // 转账 (其他钱包转到中心钱包)
      await reclaimAmount();
      v === 1 ? (refreshCenter.value = true) : (refreshOther.value = true);
      refreshCenter.value = false;
      refreshOther.value = false;
    };

    onBeforeMount(async () => {
      if (!venueList.value?.length && !freeze.value && !balance.value && !gameTotal.value) {
        await platformBalance();
      }
      await refreshWallet(1);
    });

    return () => (
      <div class={style.wallet}>
        <div class={style.walletMain}>
          <div class={style.center}>
            <section class={style.title}>
              {$t("funds_wallet_centerWallet" /* 中心钱包 */)}:
              <section class={style.money}>
                {/*{amountFormat((freeze.value || 0) + (balance.value || 0))}*/}
                {amountFormat(balance.value || 0)}
              </section>
              <i onClick={() => refreshWallet(1)}>
                <img class={refreshCenter.value && style.rotate} src={refreshPng} alt="" />
              </i>
            </section>
          </div>
          {/* <div class={style.transferAccounts} onClick={transferAccounts}>
            <i class={style.transferAccountsArrow}>
              <img src={arrowLinePng} alt="" />
            </i>
            //转账
            <span>{$t("funds_withdraw_transfer" )}</span>
          </div> */}
          {/* <div class={style.other}>
            <section class={style.title}>
              <i onClick={() => refreshWallet(2)}>
                <img class={refreshOther.value && style.rotate} src={refreshPng} alt="" />
              </i>
              //其他钱包
              {$t("funds_other_wallet" )}
            </section>
            <section class={style.money}>{amountFormat(gameTotal.value || 0)}</section>
          </div> */}
        </div>
        {/* <setcion class={style.tipText}>
            温馨提示：其他钱包的资金必须转移到中心钱包才能提现
          {$t(
            "funds_withdraw_transferTips"
          )}
        </setcion> */}
      </div>
    );
  },
});
