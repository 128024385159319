/*
 * useRuntime 获取当前模板 组件 运行在那个环境当中
 * __CMS_EDIT__  在可视化搭建编辑器中
 * __CMS_RUNTIME__ 模板生成环境运行时
 * __CMS_DEV__  开发环境
 * __CMS_PREVIEW__  组件 模板 预览环境
 * */

export function useRuntime() {
  // 编辑器模式
  const __CMS_EDIT__ = window.CMS_EDIT || process.env.CMS_EDIT;
  // 开发环境
  const __CMS_DEV__ = process.env.CMS_DEV;
  // 运行时
  const __CMS_RUNTIME__ = process.env.CMS_RUNTIME;
  const __CMS_IFRAME__ = self !== top;

  const isRuntime = (() => {
    return __CMS_RUNTIME__ === "RUNTIME";
  })();
  const isEdit = (() => {
    return __CMS_EDIT__ === "EDIT";
  })();
  const isDev = (() => {
    return __CMS_DEV__ === "DEV";
  })();
  return {
    __CMS_EDIT__,
    __CMS_RUNTIME__,
    __CMS_DEV__,
    __CMS_IFRAME__,
    isRuntime,
    isEdit,
    isDev,
  };
}
