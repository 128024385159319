import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import { Popup, Field, Icon, Button } from "vant";
import { Form } from "ant-design-vue";
import { useI18n, cardHolder, useUserBank, useUser } from "core";
import { MangoTipsModal, mangoPopup, Empty } from "components";
import Bindcard from "./bindcard.module.less";
import popup from "templates/mango/Web/popup.module.less";
import bankPin from "./assets/bank.png";

export default defineComponent({
  emits: ["close"],
  setup(props, { emit }) {
    const {
      store,
      bankForm,
      bankList,
      cardNoMangoMaxLength,
      validateInfosRef,
      getBankConfig,
      bankDebounceSubmit,
      tipsModalShow,
    } = useUserBank();
    const { user } = useUser();
    const { $t } = useI18n();
    const data = reactive({
      showbankList: false,
      // errorTips: "",
      // showerror: false,
      bankType: "",
    });

    // $t("common_fail" /* 失败 */)
    const clickBankList = (item: { key: string; value: string; bankType: string }) => {
      if (bankForm.bankValue === item.value) return;
      data.showbankList = false;
      data.bankType = item.bankType;
      bankForm.bankName = item.key;
      bankForm.bankValue = item.value;
    };

    // const isNextFlag = computed(
    //   () =>
    //     bankForm.cardNo &&
    //     bankForm.cardHolder &&
    //     bankForm.bankName &&
    //     // bankForm.address &&
    //     !data.showerror
    // );

    const getBank = async () => {
      await getBankConfig();
      data.showbankList = true;
    };

    // onMounted(async () => {
    //   await getUserInfo();
    //   bankForm.cardHolder = user.value?.realName || "";
    // });
    const toConfirm = () => {
      tipsModalShow.value = false;
      mangoPopup().close();
    };

    // watch(
    //   bankForm,
    //   () => {
    //     if (bankForm.cardHolder && !cardHolder.test(bankForm.cardHolder)) {
    //       data.showerror = true;
    //       data.errorTips = $t("funds_bank_inputName" /**请输入正确的持卡人姓名 */);
    //       return;
    //     }
    //     data.showerror = false;
    //   },
    //   { deep: true }
    // );

    const isNextFlag = computed(() => {
      let flag = false;
      for (let field in validateInfosRef.value) {
        if (validateInfosRef.value[field].validateStatus === "error") {
          flag = true;
          break;
        }
      }
      return flag;
    });

    return () => (
      <div class={Bindcard.bindcard}>
        {/* {data.showerror && <div class={Bindcard.errorTips}>{data.errorTips}</div>} */}
        <div class={Bindcard.topLine} />
        <div class={Bindcard.topTitle}>
          <span class={Bindcard.topTitleIcon} />
          {$t("mine_center_pleaseInputBankInfo") /* 请填写银行卡信息 */}
        </div>
        <div class={Bindcard.list}>
          <Form.Item
            validateFirst={true}
            name={"cardHolder"}
            {...validateInfosRef.value["cardHolder"]}
          >
            <Field
              class={Bindcard.input}
              name="realName"
              v-model={bankForm.cardHolder}
              maxlength="16"
              placeholder={$t("funds_bank_cardUserName") /* 持卡人姓名 */}
              type="text"
              disabled={user.value?.realName !== null}
              onBlur={() => (bankForm.cardHolder = bankForm.cardHolder.trim())}
            />
          </Form.Item>
        </div>
        <div class={[Bindcard.list, Bindcard.borderBottom, Bindcard.bankName]}>
          <Form.Item validateFirst={true} name={"bankName"} {...validateInfosRef.value["bankName"]}>
            <Field
              class={Bindcard.input}
              name="bankName"
              v-model={bankForm.bankName}
              placeholder={$t("mine_center_bankAccountOpenBank" /**请选择开户行 */)}
              type="text"
              disabled
            />
            <div
              class={Bindcard.activeBank}
              onClick={() => {
                getBank();
              }}
            >
              <Icon class={Bindcard.selectIcon} name={"play"} />
            </div>
          </Form.Item>
        </div>
        <div class={[Bindcard.list, Bindcard.borderBottom]}>
          cardNoResult
          <Form.Item validateFirst={true} name={"cardNo"} {...validateInfosRef.value["cardNo"]}>
            <Field
              class={Bindcard.input}
              name="number"
              v-model={bankForm.cardNo}
              required
              maxlength={cardNoMangoMaxLength.value}
              placeholder={$t("funds_bank_input_tips" /**请输入银行卡号 */)}
              type="text"
              onBlur={() => (bankForm.cardNo = bankForm.cardNo.trim())}
            />
          </Form.Item>
        </div>
        {String(data.bankType) === "1" && (
          <div class={[Bindcard.list, Bindcard.borderBottom]}>
            <Form.Item validateFirst={true} name={"address"} {...validateInfosRef.value["address"]}>
              <Field
                class={Bindcard.input}
                v-model={bankForm.address}
                placeholder={$t("funds_bank_open_tips" /**开户行地址 */)}
                type="text"
              />
            </Form.Item>
          </div>
        )}
        <div class={Bindcard.bankTip}>
          {$t("mine_center_bindCardTips" /**请确保如上填写的信息真实有效，否则将无法成功提现 */)}
        </div>
        <Button
          class={[Bindcard.nextStep, isNextFlag.value && Bindcard.errorBtn]}
          onClick={bankDebounceSubmit}
          loading={store.addLoading}
        >
          {$t("mine_userinfo_confirmSubmission") /* 确认提交 */}
        </Button>
        <Popup
          v-model:show={data.showbankList}
          position="bottom"
          style={{ height: "440px", borderRadius: "10px 10px 0 0" }}
          class={popup.popupWidth100}
        >
          <div class={[popup.popupHeader, popup.popupHeaderBg]}>
            <section class={popup.popupHeaderLeft} onClick={() => (data.showbankList = false)}>
              {$t("common_cancel")}
            </section>
            <section class={popup.popupHeaderMiddle}>
              {$t("agent_nbanklist_chosebelongbank" /*选择所属银行*/)}
            </section>
            <section class={popup.popupHeaderRight}></section>
          </div>
          <div class={Bindcard.bankContainer}>
            {bankList.value && bankList.value.length ? (
              bankList.value.map(item => (
                <div class={Bindcard.bankList} onClick={() => clickBankList(item)}>
                  <span class={Bindcard.bankBox}>
                    <span class={Bindcard.bankCard}>
                      <img src={item.icon || bankPin} alt="bankPng" />
                    </span>
                    <span class={Bindcard.bankIcon}>{item.key}</span>
                  </span>
                  {bankForm.bankValue === item.value && <span class={Bindcard.selectImg} />}
                </div>
              ))
            ) : (
              <Empty v-show={true} type="mangoNoData" top={50} />
            )}
          </div>
        </Popup>
        <MangoTipsModal
          v-model:show={tipsModalShow.value}
          title={$t("common_complete" /*完成*/)}
          subTitle={`${$t("common_commit_success" /*添加银行卡*/)}`}
          type="success"
          confirmText={$t("common_confirm" /*确定*/)}
          confirmCallback={toConfirm}
          cancelCallback={() => mangoPopup().close()}
        />
      </div>
    );
  },
});
