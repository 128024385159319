/**
 * 1、校验取款提交按钮是否可以点击
 * 2、取款初始化，取款成功之后需要初始化
 * 3、提交取款
 * 4、查询取款配置
 * 5、获取虚拟币种类
 * 6、取款图形验证码
 */
import { reactive, toRefs, computed, ref, onBeforeMount, onBeforeUnmount, watch } from "vue";
import { useRouter } from "vue-router";
import { MobileTipsMango, mangoPopup } from "components";
import {
  withdrawLimit,
  IVirtualCurrency,
  VirtualCoin,
  CodingData,
  SaveOrder,
  OrderDetails,
  CountryCode,
  WithdrawTabsItem,
} from "@kgsport-cms/service";

import { Form } from "ant-design-vue";
import {
  Api,
  useConfig,
  useToast,
  useUser,
  useWallet,
  useI18n,
  useUserBank,
  mathMultiplied,
  mathDiv,
  bankValueRule,
  useAuth,
  isMobile,
  useCommon,
  useGoogleAnaltics,
  dutyRule,
} from "core";
import {
  requireArrRule,
  passwordRule2,
  verificationCodeRule,
  verificationCodeRule2,
} from "../common";

type Item = {
  id: string;
  con: string;
};
export type Save = {
  bankId: number | string;
  cashPassword: number | string;
  withdrawType: string;
  cashMoney: number | string;
  memberMemo?: string;
  phone?: number | string;
  validateCode: number | string;
  captchaKey?: number | string;
  code: number | string;
  countyCode: string;
  currency: string;
  number: string;
  cardName: string;
  taxType: string;
  taxNumber: string;
};

export interface Withdraw {
  withdrawAmount: number[];
  futureDraw: any[];
  drawTypeList: Item[];
  futureActive: number;
  tabBtnActive: number;
  withdrawAmountIndex: number;
  withdrawLimit: withdrawLimit;
  codingData: CodingData;
  captchaImg: string;
  virtualCurrency: IVirtualCurrency[] | null;
  virtualCoin: VirtualCoin;
  saveOrder: SaveOrder;
  orderDetails: OrderDetails;
  countryCode: CountryCode;
}

const { toast } = useToast();
const store = reactive<Withdraw>({
  // 预约取款时间
  futureDraw: [
    { id: 0, con: "0-3小时之内取款-加送0.2%" },
    { id: 1, con: "0-5小时之内取款-加送0.3%" },
    { id: 2, con: "0-6小时之内取款-加送0.5%" },
    { id: 3, con: "0-12小时之内取款-加送0.6%" },
  ],
  // 取款方式
  drawTypeList: [
    { id: "B", con: "极速取款" },
    { id: "V", con: "USDT取款" },
    { id: "D", con: "免提直充" },
  ],
  // 预约取款方式
  futureActive: 0,
  // 快捷金额
  withdrawAmount: [100, 200, 300, 500, 1000, 2000, 3000, 5000, 10000, 20000, 30000],
  // 选中快捷金额索引
  withdrawAmountIndex: -1,
  // 取款方式
  tabBtnActive: 0,
  // 取款配置
  withdrawLimit: {
    cashCount: 0,
    cashSumMoney: 0,
    cashVerification: 0,
    counterFeeMode: 0,
    exceedCashNumberUpperLimitServiceCharge: 1,
    oddDaysCashMoneyUpperLimit: 9999999,
    oddDaysCashTriesLimit: 0,
    singleCashMoneyLowerLimit: 1,
    singleCashMoneyUpperLimit: 5000000,
    sumDeductMoney: 0,
    whetherDeductAdministrationCost: 0,
    fixedBalance: 0,
    administrationCostDeductPayMoneyRatio: 0,
    bankSwitch: 0,
    usdtSwitch: 0,
    decimalSwitch: 0,
  },
  codingData: {} as CodingData,
  // 取款图像验证码
  captchaImg: "",
  // 虚拟币取款配置
  virtualCurrency: null,
  // 当前虚拟币币种对应的汇率
  virtualCoin: {
    coin: "",
    exchangeRate: "",
    icon: "",
    protocol: [],
  },
  saveOrder: {} as SaveOrder,
  orderDetails: {} as OrderDetails,
  countryCode: {
    list: [],
    finished: false,
    paging: { size: 20, current: 1, total: 0 },
  },
});

// 取款参数
const saveParams = reactive<Save>({
  bankId: 0,
  cashPassword: "",
  withdrawType: "B",
  cashMoney: "",
  memberMemo: "",
  phone: 0,
  validateCode: "",
  captchaKey: "",
  code: "",
  countyCode: "",
  currency: "",
  number: "",
  cardName: "",
  taxType: "",
  taxNumber: "",
});
// 展示银行卡或者虚拟币名称参数（纯展示 无交互）
const bankAndUdstParam = reactive({
  name: "",
  number: "",
});
export { saveParams };
export { bankAndUdstParam };

// h5进入提现页面前的弹窗提示验证
const tipModalData = reactive({
  show: false,
  title: "",
  subTitle: "",
  confirmText: "",
  cancelText: "",
  confirmCallback() {},
  cancelCallback() {},
  close() {
    tipModalData.show = false;
  },
});

export { tipModalData };
// 开启验证提现短信
const verificationCode = ref(false);

const useForm = Form.useForm;
export function useWithdraw() {
  const router = useRouter();
  const { $t } = useI18n();
  const { user } = useUser();
  const { isPc, config } = useConfig();
  const { userClickWithdraw } = useGoogleAnaltics();
  const { isLogin } = useAuth();
  const { cardList, getUserBank } = useUserBank();
  const { platformBalance, scaleAmountValue } = useWallet();
  const { captchaCode } = useCommon();
  const rechargeDetail = computed(() => store.orderDetails);
  saveParams.phone = user.value?.phone;
  saveParams.currency = user.value?.currency as string;
  // 当前withdrawType是否是免提直充
  const isDirect = computed(() => saveParams.withdrawType === "D");
  const WithdrawRule = reactive({
    bankId: computed(() => (!isDirect.value ? bankValueRule() : [])),
    cashMoney: requireArrRule(),
    cashPassword: passwordRule2(),
    // 个人信息开启提现短信验证才使用
    validateCode: computed(() =>
      user.value?.cashValidate && verificationCode.value ? verificationCodeRule() : []
    ),
    // 图形验证码开启提后才使用
    code: computed(() => (store.withdrawLimit.cashVerification ? verificationCodeRule2() : [])),
    taxNumber: config.isTaxFlag && window.CONFIG.currentModel === "mango" ? dutyRule() : [],
  });
  const { resetFields, validate, validateInfos, clearValidate } = useForm(saveParams, WithdrawRule);
  // 校验取款提交按钮是否可以点击
  const isDisabledWithdrawBtn = computed<boolean>(() => {
    const isBindCard =
      saveParams.withdrawType === "B"
        ? !!user.value?.bankcardCount
        : saveParams.withdrawType === "V"
        ? !!user.value?.virtualCurrencyCount
        : true;
    const cashValidate = user.value?.cashValidate === 0 ? true : saveParams.validateCode;
    const code = store.withdrawLimit.cashVerification === 0 ? true : saveParams.code;
    return (
      !+saveParams.cashMoney || !saveParams.cashPassword || !code || !cashValidate || !isBindCard
    );
  });
  // 提现类型(B: 银行卡 V: 虚拟币)
  const withdrawTypeBank = computed(() => saveParams.withdrawType === "B");

  // 1-未受理 2-受理中；3-已出款；4-已取消；5-拒绝出款 6-撤销出款订单
  const cashStatusFormat = {
    1: $t("funds_not_accepted" /* 未受理 */),
    2: $t("funds_withdraw_shoulizhong" /* 受理中 */),
    3: $t("funds_paid_out" /* 已出款 */),
    4: $t("mine_bet_cancel" /* 已取消 */),
    5: $t("funds_refuse_pay" /* 拒绝出款 */),
    6: $t("funds_withdraw_cancelWithdrawOrder" /* 撤销出款订单 */),
  };
  const methodFormat = {
    1: $t("funds_member_withdrawal" /* 会员取款 */),
    2: $t("funds_background_withdrawal" /* 后台取款 */),
  };
  // 手机号提示
  const phoneText = computed<string>(
    () => `${user.value?.dialCode} ********${user.value?.phone.slice(-4)}`
  );

  // 提现tabs
  const withdrawTabs = computed<WithdrawTabsItem[]>(() => {
    const { bankCode = 0, usdtCode = 0 } = store.withdrawLimit || {};
    return [
      !!Number(bankCode) && {
        label: $t("funds_withdraw_bankCardTitle"), // 银行卡提现
        value: "B",
      },
      ![
        "lam106",
        "lam107",
        "lam109",
        "lam110",
        "lam111",
        "lam112",
        "lam113",
        "lam117",
        "lam119",
        "lam120",
        "lam125",
        "lam126",
        "lam127",
        "lam130",
        "lam131",
        "lam132",
        "lam102",
      ].includes(window.CONFIG?.name) &&
        !!Number(usdtCode) && {
          label: $t("funds_withdraw_bitcoin"), // 虚拟币提现
          value: "V",
        },
      config.isDirectOpen && {
        label: $t("funds_recharge_directCharging"), // 免提直充
        value: "D",
      },
    ].filter(Boolean) as WithdrawTabsItem[];
  });
  // 未超限手续费：
  // whetherDeductAdministrationCost：未超限是否扣除行政费用 0 只扣除优惠费用  1只收行政费用  2 不扣除全部费用
  // fixedBalance 只扣除优惠费用金额比例
  // administrationCostDeductPayMoneyRatio 行政费用扣除充值金额比例
  /*const fee1 = computed(() =>
    store.withdrawLimit.whetherDeductAdministrationCost === 0
      ? store.withdrawLimit.fixedBalance
      : store.withdrawLimit.whetherDeductAdministrationCost === 1
      ? Number(saveParams.cashMoney) *
        (store.withdrawLimit.administrationCostDeductPayMoneyRatio / 100)
      : 0
  );
  // 超限手续费：未超限不扣除超限手续费
  const fee2 = computed(() =>
    store.withdrawLimit.cashCount > store.withdrawLimit.oddDaysCashTriesLimit
      ? store.withdrawLimit.counterFeeMode === 1
        ? Number(saveParams.cashMoney) *
          (store.withdrawLimit.exceedCashNumberUpperLimitServiceCharge / 100)
        : store.withdrawLimit.exceedCashNumberUpperLimitServiceCharge
      : 0
  );*/
  // 手续费：未超限手续费 + 超限手续费 （乘比例后手续费）
  const serviceAmount = computed<{ serviceCharge: number; serviceChargePro: number }>(() => {
    const {
      cashCount, // 用户当天已取现的次数
      oddDaysCashTriesLimit, // 单日提现次数上限
      counterFeeMode, // 超过当日最大次数收手续费类型，0定额，1百分比
      exceedCashNumberUpperLimitServiceCharge, // 超过当日最大次数手续费
    } = store.withdrawLimit;

    /* 未超限 */
    if (cashCount < oddDaysCashTriesLimit) return { serviceCharge: 0, serviceChargePro: 0 };
    /* 超限 */
    // 没乘比例的手续费
    const serviceCharge = counterFeeMode
      ? Number(saveParams.cashMoney) * (exceedCashNumberUpperLimitServiceCharge / 100)
      : exceedCashNumberUpperLimitServiceCharge;
    return { serviceCharge, serviceChargePro: covertAmountByScale(serviceCharge) };
  });

  // 预计到账金额 = (输入金额 - 手续费 - 打码量金额)
  const amountReceived = computed(() => {
    const res =
      Number(saveParams.cashMoney) -
      Number(store.withdrawLimit.sumDeductMoney) -
      Number(serviceAmount.value.serviceCharge);
    return covertAmountByScale(res < 0 ? 0 : res);
  });

  // 按比例处理后，实际金额
  const actualAmount = computed(() => covertAmountByScale(Number(saveParams.cashMoney)));

  // 汇率
  const usdtRate = ref("0");
  // usdt
  const usdtValue = computed(() =>
    +usdtRate.value
      ? mathDiv(
          covertAmountByScale(Number(saveParams.cashMoney)),
          Number(usdtRate.value)
        ).toNumber()
      : 0
  );

  const covertAmountByScale = (amount: number) =>
    mathMultiplied(amount, Number(scaleAmountValue.value)).toNumber() ?? 0;

  // 打码量
  const getSumDeductMoney = () => covertAmountByScale(Number(store.withdrawLimit.sumDeductMoney));
  // 提现限制
  const withdrawLimitData = computed(() => store.withdrawLimit);

  // 获取国家编码
  const getCountry = async () => {
    try {
      const { data, result } = await Api.WithdrawService.country(store.countryCode.paging);
      if (!result) return;
      saveParams.countyCode = window.CONFIG?.defaultCountryCode ?? data.records[0]?.code;
      if (data.records.length < store.countryCode.paging.size) store.countryCode.finished = true;

      if (store.countryCode.paging.current === 1) {
        saveParams.countyCode = window.CONFIG?.defaultCountryCode ?? data.records[0].code;
        store.countryCode.list = [];
      }

      if (isPc.value) {
        store.countryCode.list = data.records;
      } else {
        store.countryCode.list = [...store.countryCode.list, ...data.records];
      }
      store.countryCode.paging.current = data.current;
      store.countryCode.paging.size = data.size;
      store.countryCode.paging.total = data.total;
    } catch (e) {
      console.error("useWithdraw:getCountry", e);
    }
  };
  // 修改国家编码
  const onCountyCodeChange = (code: string) => {
    saveParams.countyCode = code;
  };

  const getCaptchaCode = async () => {
    const data = await captchaCode();
    store.captchaImg = data.img;
    saveParams.captchaKey = data.captchaKey;
  };

  // 银行卡提现 虚拟币提现tab切换
  const tabChange = async (type: string) => {
    if (saveParams.withdrawType === type) return;
    resetFields({
      withdrawType: type,
      cashMoney: "",
      code: "",
      cashPassword: "",
      countyCode: saveParams.countyCode,
    });
    // 获取图形验证码
    store.withdrawLimit?.cashVerification && (await getCaptchaCode());
    if (type === "B") await verificationBindBank();
    else if (type === "V") await verificationBindVirtual();
    else {
      // type = D
    }
    // 兼容mango模版
    if (window.CONFIG?.currentModel === "mango") {
      if (saveParams.withdrawType === "V") {
        bankAndUdstParam.number = cardList.value?.V[0]?.cardNo || "";
        bankAndUdstParam.name =
          cardList.value?.V[0]?.currency && cardList.value?.V[0]?.network
            ? `${cardList.value?.V[0]?.currency || ""}(${cardList.value?.V[0]?.network || ""})`
            : "";
        saveParams.bankId = cardList.value?.V[0]?.id || 0;
      } else {
        bankAndUdstParam.number = cardList.value?.B[0]?.cardNo || "";
        bankAndUdstParam.name = `${cardList.value?.B[0]?.bankName}` || "";
        saveParams.bankId = cardList.value?.B[0]?.id || 0;
      }
    }
  };

  // 提交取款loading
  const submitLoading = ref(false);
  // 提交取款
  const save = async (cb?: () => void) => {
    userClickWithdraw();
    try {
      if (Number(saveParams.bankId) === 0) {
        toast.warn($t("common_withdraw_bankError" /**银行卡获取异常，请刷新页面后重新尝试 */));
        return;
      }
      submitLoading.value = true;
      await validate();
      if (isDisabledWithdrawBtn.value) return false;
      if (Number(saveParams.cashMoney) > store.withdrawLimit.singleCashMoneyUpperLimit) {
        toast.warn($t("funds_withdraw_exceedMaxTopMoney")); // 提现金额超过单次提现最大金额
        return false;
      }
      if (Number(saveParams.cashMoney) < store.withdrawLimit.singleCashMoneyLowerLimit) {
        toast.warn($t("funds_withdraw_exceedLowLimitMoney")); // 提现金额低于单次提现最低金额
        return false;
      }
      const { data, result, message } = await Api.WithdrawService.save({
        ...saveParams,
        ...{ cashMoney: actualAmount.value },
      });
      if (result) {
        store.saveOrder = data;
        // toast.success(message);
        if (data.cashMessage) {
          mangoPopup({
            type: isMobile ? "MangoCashMessageMobile" : "MangoCashMessage",
            header: false,
            hasBackground: false,
            borderRadius: "12",
            props: {
              desc: data.cashMessage,
            },
          }).open();
        }
        cb?.();
        await tabChange(saveParams.withdrawType);
        await get();
        await platformBalance();
        return true;
      }
      return false;
    } catch (e) {
      console.error("useWithdraw:save", e);
    } finally {
      submitLoading.value = false;
    }
  };
  // 请求提现配置
  const requestWithdrawLimit = async () => {
    try {
      const { data, result } = await Api.WithdrawService.query({
        currency: user.value?.currency,
      });
      if (!result) return;
      store.withdrawLimit = data;
      return Promise.resolve(data);
    } catch (error) {
      console.error("requestWithdrawLimit", error);
    }
  };
  // 查询取款配置
  const query = async () => {
    try {
      const data = await requestWithdrawLimit();
      saveParams.withdrawType =
        Number(store.withdrawLimit.bankCode) === 1 || !!cardList.value?.B?.length ? "B" : "V";
      if (!data?.cashVerification) return;
      await getCaptchaCode();
    } catch (e) {
      console.error("useWithdraw:query", e);
    }
  };
  // 会员出款打码量审核信息
  const valid = async () => {
    try {
      const { data, result } = await Api.WithdrawService.valid();
      if (!result) return;
      store.codingData = data;
    } catch (e) {
      console.error("useWithdraw:valid", e);
    }
  };
  // 获取虚拟币种类
  const getVirtualCurrency = async () => {
    try {
      const { data, result } = await Api.ConfigService.getVirtualCurrency({
        currency: user.value?.currency,
      });
      if (result) store.virtualCurrency = data;
    } catch (e) {
      console.error("useWithdraw:getVirtualCurrency", e);
    }
  };
  // 根据虚拟币币种 - 获取汇率
  const getVirtualCoin = async () => {
    const coin = cardList.value?.V.find(d => d.id === saveParams.bankId)?.currency ?? "";
    if (!coin) return;
    try {
      const { data, result } = await Api.ConfigService.getVirtualCoin({
        coin,
        currency: user.value?.currency,
      });
      result && (store.virtualCoin = data);
    } catch (e) {
      console.error("useWithdraw:getVirtualCoin", e);
    }
  };
  // 取款图形验证码

  const timer = ref();
  // 查询下单明细
  const get = async (cashOrderNo?: string) => {
    try {
      const { data, result } = await Api.WithdrawService.get({
        cashOrderNo: cashOrderNo || store.saveOrder.cashOrderNo,
      });
      if (!result) return;
      store.orderDetails = data as OrderDetails;

      if (data.cashOrderNo === 3) return;
      timer.value && clearTimeout(timer.value);
      timer.value = setTimeout(() => get(cashOrderNo), 10000);
    } catch (e) {
      timer.value && clearTimeout(timer.value);
      console.error("useWithdraw:get", e);
    }
  };
  // 短信验证
  const verificationSms = async () => {
    const { cashValidate } = user?.value ?? {};
    if (config.smsSwitch && cashValidate === 0) {
      tipModalData.show = true;
      tipModalData.title = $t("funds_withdraw_msgTip" /* 提现短信验证 */);
      tipModalData.subTitle = $t(
        "funds_withdraw_openMsgTip" /* 是否开启提现短信验证，保证资金更安全 */
      );
      tipModalData.confirmText = $t("common_goToSet" /* 前往设置 */);
      tipModalData.cancelText = $t("funds_withdraw_continueWithdraw" /* 继续提现 */);

      tipModalData.confirmCallback = async () => {
        await router.push({ name: "userSetup" });
      };
      tipModalData.cancelCallback = async () => {
        tipModalData.show = false;
        await router.push({ name: "applyWithdrawal" });
      };
      return false;
    }
    return true;
  };
  // 未设置提现密码
  const verificationWithdrawalPassword = async () => {
    const { hasWithdrawPwd } = user?.value ?? {};
    if (!hasWithdrawPwd) {
      tipModalData.show = true;
      tipModalData.title = $t("funds_withdraw_unSetPwd" /* 未设置提现密码 */);
      tipModalData.subTitle = $t(
        "message_notSetWithdrawPass" /* 为保证您的资金安全，请您先设置提现密码！ */
      );
      tipModalData.confirmText = $t("common_goToSet" /* 前往设置 */);
      tipModalData.cancelText = $t("common_close" /* 关闭 */);
      tipModalData.confirmCallback = () => {
        if (window.CONFIG?.currentModel === "mango") {
          mangoPopup({
            type: isMobile ? "WithdrawalPassSettingsMobile" : "MangoWithdrawalPassSettings",
            title: $t("mine_usersetup_withdrawalPassSettings" /* 设置提现密码 */),
            header: !isMobile,
            borderRadius: isMobile ? "12" : "20",
          }).open();
        } else {
          router.push({ name: "withdrawalPassSettings" });
        }
      };
      tipModalData.cancelCallback = () => {
        tipModalData.show = false;
      };
      return false;
    }
    return true;
  };
  // 未绑定银行卡或未绑定虚拟地址
  const verificationBindBankOrVirtual = async () => {
    const { B = [], V = [] } = cardList.value || {};
    if (!B?.length && !V?.length) {
      tipModalData.show = true;
      tipModalData.title = $t("funds_withdraw_gotoBindBankOrBTC" /* 未绑定银行卡或虚拟币地址 */);
      tipModalData.subTitle = $t(
        "funds_withdraw_noAnyCardCoinsToBind" /* 您还未绑定银行卡或虚拟币地址,请先绑定 */
      );
      tipModalData.confirmText = $t("funds_withdraw_gotoBindBankOrBTC" /* 绑定银行卡或者虚拟币 */);
      tipModalData.cancelText = $t("user_register_alert_cancel" /* 取消 */);
      tipModalData.confirmCallback = () => {
        if (window.CONFIG?.currentModel === "mango") {
          mangoPopup({
            type: isMobile ? "BankCardManageMobile" : "MangoBankCardManage",
            title: $t("funds_wallet_cardManager" /* 卡片管理 */),
            borderRadius: "12",
            props: { type: saveParams.withdrawType },
            header: !isMobile,
          }).open();
        } else {
          router.push({ name: "management" });
        }
      };
      tipModalData.cancelCallback = () => {
        tipModalData.show = false;
      };
      return false;
    }
    return true;
  };
  // 未绑定手机
  const verificationBindPhone = async () => {
    const { phone } = user?.value ?? {};
    const { B = [], V = [] } = cardList.value || {};
    if (!phone) {
      tipModalData.show = true;
      tipModalData.title = $t("funds_withdraw_gotoBindPhone" /* 未绑定手机号 */);
      tipModalData.subTitle = $t(
        "funds_withdraw_safetyPhoneTips" /* 为了更加安全，需要帮您的手机号 */
      );
      tipModalData.confirmText = $t("funds_withdraw_gotoBindPhone" /* 去绑定 */);
      tipModalData.cancelText =
        B?.length || V?.length
          ? $t("funds_withdraw_continueWithdraw" /* 继续提现 */)
          : $t("user_register_alert_cancel" /* 取消 */);
      tipModalData.confirmCallback = () => {
        if (window.CONFIG?.currentModel === "mango") {
          mangoPopup({
            type: isMobile ? "MangoSecretPhoneMobile" : "MangoSecretPhone",
            title: $t("mine_usersetup_secretPhone" /* 密保手机 */),
            header: !isMobile,
            borderRadius: isMobile ? "12" : "20",
          }).open();
        } else {
          router.push({ name: "secretPhone" });
        }
      };
      tipModalData.cancelCallback = () => {
        tipModalData.show = false;
        setTimeout(async () => {
          const res =
            B?.length || V?.length
              ? await verificationSms()
              : await verificationBindBankOrVirtual();
          if (!res) return;
          await router.push({ name: "applyWithdrawal" });
        }, 300);
      };
      return false;
    }
    return true;
  };
  // 未绑定银行卡
  const verificationBindBank = async () => {
    const { B = [] } = cardList.value || {};
    if (!B?.length) {
      tipModalData.show = true;
      tipModalData.title = $t("funds_withdraw_noBankcard" /* 未绑定银行卡 */);
      tipModalData.subTitle = $t("funds_withdraw_noBankcardTips" /* 您还未绑定银行卡,请先绑定 */);
      tipModalData.confirmText = $t("funds_withdraw_gotoBindBankCard" /* 绑定银行卡 */);
      tipModalData.cancelText = $t("user_register_alert_cancel" /* 取消 */);
      tipModalData.confirmCallback = () => {
        if (window.CONFIG?.currentModel === "mango") {
          mangoPopup({
            type: isMobile ? "BankCardManageMobile" : "MangoBankCardManage",
            title: $t("funds_wallet_cardManager" /* 卡片管理 */),
            props: { type: "B" },
            header: !isMobile,
            borderRadius: isMobile ? "12" : "20",
          }).open();
        } else {
          router.push({ name: "bindCard" });
        }
      };
      tipModalData.cancelCallback = () => {
        tipModalData.show = false;
      };
      return false;
    }
    return true;
  };
  // 未绑定虚拟地址
  const verificationBindVirtual = async () => {
    // const { virtualCurrencyCount } = user?.value ?? {};
    const { V = [] } = cardList.value || {};
    if (!V?.length) {
      tipModalData.show = true;
      tipModalData.title = $t("funds_withdraw_noBitCoin" /* 未绑定虚拟地址 */);
      tipModalData.subTitle = $t(
        "funds_withdraw_noBitCoinTips" /* 您还未绑定虚拟币地址,请先绑定 */
      );
      tipModalData.confirmText = $t("funds_withdraw_gotoBindBTC" /* 绑定虚拟币 */);
      tipModalData.cancelText = $t("user_register_alert_cancel" /* 取消 */);
      tipModalData.confirmCallback = () => {
        if (window.CONFIG?.currentModel === "mango") {
          mangoPopup({
            type: isMobile ? "BankCardManageMobile" : "MangoBankCardManage",
            title: $t("funds_wallet_cardManager" /* 卡片管理 */),
            props: { type: "V" },
            header: !isMobile,
            borderRadius: isMobile ? "12" : "20",
          }).open();
        } else {
          router.push({ name: "bindUsdt" });
        }
      };
      tipModalData.cancelCallback = () => {
        tipModalData.show = false;
      };
      return false;
    }
    return true;
  };
  const verificationBeforeWithdraw = async (isMango?: boolean, isMangoH5?: boolean) => {
    if (!isLogin.value) {
      // 验证登录
      await router.push("/entry/login");
      return;
    }
    // 提现密码 绑定手机 银行卡或虚拟币 (2022.11.15之前规则)
    // 提现弹窗顺序，设置提现密码，绑定银行卡，就这2个 (2022.11.15今日CJ定的规则)
    if (!(await verificationWithdrawalPassword())) return;
    // if (!(await verificationBindPhone())) return;
    // if (!(await verificationSms())) return;
    const status = await verificationBindBankOrVirtual();
    if (!status) return;
    if (window.CONFIG?.currentModel === "mango") {
      return mangoPopup({
        type: "MangoRechargeH5",
        header: false,
        borderRadius: "12",
        props: { switchVal: "withdraw" },
      }).open();
    } else {
      await router.push({ name: "applyWithdrawal" });
    }
  };
  watch(
    () => user.value,
    val => {
      if (val) {
        saveParams.taxType = val.taxType;
        saveParams.taxNumber = val.taxNum;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  onBeforeUnmount(() => {
    clearTimeout(timer.value);
  });
  // 获取当前币种银行卡（虚拟币）列表，需要用列表长度判断是否绑定了银行卡（虚拟币）；user中的bankcardCount, virtualCurrencyCount两个参数是所有币种的数量
  onBeforeMount(async () => {
    clearTimeout(timer.value);
    const { B = [], V = [] } = cardList.value || {};
    if (B?.length || V?.length || !isLogin.value) return;
    await getUserBank();
  });
  return {
    ...toRefs(store),
    serviceAmount,
    rechargeDetail,
    amountReceived,
    phoneText,
    tabChange,
    getCountry,
    save,
    query,
    valid,
    captchaCode: getCaptchaCode,
    get,
    getVirtualCurrency,
    getVirtualCoin,
    onCountyCodeChange,
    isDisabledWithdrawBtn,
    validate,
    resetFields,
    validateInfos,
    clearValidate,
    saveParams,
    verificationCode,
    withdrawTabs,
    isDirect,
    cashStatusFormat,
    methodFormat,
    withdrawTypeBank,
    tipModalData,
    verificationBeforeWithdraw,
    verificationBindBank,
    verificationBindVirtual,
    actualAmount,
    usdtRate,
    usdtValue,
    getUserBank,
    getSumDeductMoney,
    withdrawLimitData,
    submitLoading,
    requestWithdrawLimit,
    // 抛出两个方法，在弹窗内部切换充值，提现按钮时判断
    verificationWithdrawalPassword,
    verificationBindBankOrVirtual,
  };
}
