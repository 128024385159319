import { provide, defineComponent, onMounted } from "vue";
import { useTitle } from "@vueuse/core";
import { CONFIG_PROVIDER_KEY } from "./const";
import { useConfig, isHybridApp, getBridgeTheme, useLogin } from "core";
async function initTheme() {
  try {
    if (!isHybridApp()) return;
    const data = await getBridgeTheme<any>();
    const theme = data?.data?.theme as any;
    if (theme) {
      if (theme.themeSelectedBackgroundColor) {
        window.cms_common.variables["--cms-primary-color"] = theme.themeSelectedBackgroundColor;
      }
    }
    console.log(theme, window.cms_common.variables);
    window.cms_common.cssRootVar(window.cms_common.variables);
  } catch (e) {
    console.log(e);
  }
}
export default defineComponent({
  name: "AppConfigProvider",
  setup(props, { slots }) {
    const { tokenQuickLogin } = useLogin();
    const store = useConfig();
    useTitle(window.CONFIG?.title || store.sitename || "");
    const { setSystemConfigs, setDomain } = store;
    provide(CONFIG_PROVIDER_KEY, store);
    initTheme();
    onMounted(() => {
      tokenQuickLogin();
      setDomain();
    });
    return () => slots.default?.();
  },
});
