import { defineComponent, nextTick, onMounted, PropType, ref, watch } from "vue";
import style from "./style.module.less";
import { useToast } from "core";

export interface Rule {
  verification(value: string): { value: string; status: boolean };
  message?: string;
}

const numReg = /\d|\.|Backspace|Delete|ArrowLeft|ArrowRight/;
const numReg2 = /\d|Backspace|Delete|ArrowLeft|ArrowRight/;
export default defineComponent({
  name: "InputCom",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    check: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: [String, Number],
      default: "200",
    },
    unit: {
      type: String,
      default: "",
    },
    rules: {
      type: Array as PropType<Rule[]>,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isFocus = ref<Boolean>(false);
    const { toast } = useToast();
    const verification = (e: Event) => {
      const target = e.target as HTMLInputElement;
      let { value } = target;
      value = value === "." ? "0" : value;
      const { rules } = props;
      const len = rules.length;
      if (len) {
        for (let i = 0; i < len; i++) {
          const { value: v, status = true } = rules[i].verification?.(value) ?? {};
          if (!status) {
            rules[i].message && toast.error(rules[i].message);
            return false;
          }
          value = v;
        }
      }
      target.value = value;
      emit("update:modelValue", value);
    };
    const keydown = (e: KeyboardEvent) => {
      const { value } = e.target as HTMLInputElement;
      if (
        props.check === "number" &&
        (value.includes(".") ? !numReg2.test(e.key) : !numReg.test(e.key))
      ) {
        e.preventDefault();
      }
    };
    const focusInput = () => {
      isFocus.value = true;
    };
    const blurInput = () => {
      isFocus.value = false;
    };
    const inputEle = ref();
    onMounted(() => {
      nextTick(() => {
        (inputEle.value as HTMLInputElement).value = "";
      });
    });
    watch(
      () => props.modelValue,
      n => {
        inputEle.value && (inputEle.value.value = n);
      },
      { immediate: true }
    );

    return () => (
      <div class={isFocus.value ? [style.input, style.focusinput] : style.input}>
        <input
          ref={inputEle}
          class={props.unit && style.hasUnit}
          type={props.type}
          maxlength={props.maxlength}
          placeholder={props.placeholder}
          onInput={e => {
            inputEle.value.focus();
            inputEle.value.setSelectionRange(
              inputEle.value.value?.length,
              inputEle.value.value?.length
            );
            verification(e);
          }}
          onKeydown={keydown}
          onFocus={focusInput}
          onBlur={blurInput}
          autocomplete={props.type === "password" ? "new-password" : "off"}
        />
        {props.unit && <section class={style.unit}>({props.unit})</section>}
      </div>
    );
  },
});
