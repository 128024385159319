import {
  LoadingInjectionContext,
  LOADING_INJECTION_KEY,
  Loading,
  loading,
  LoadingContext,
} from "commom";
import { createVNode, getCurrentInstance, inject, render } from "vue";
interface LoadingOption {
  loadingText?: any;
  loadingImg?: any;
  textStyle?: object | null | undefined;
  show?: () => void;
  hide?: () => void;
  parentContext?: any;
  appContext?: any;
}
let _div: any = null;
let _vm: any = null;
// 创建初始化外层节点
const createEl = () => {
  const div = document.createElement("div");
  div.classList.add("cms-loading-container");
  document.body.appendChild(div);
  return div;
};
// 移除节点
const removeEl = () => {
  const div = document.getElementsByClassName("cms-loading-container")[0];
  document.body.removeChild(div);
};
// 暴露userLoading上下文对象(外部手动控制)
export function useLoadingContext() {
  return inject<LoadingInjectionContext>(LOADING_INJECTION_KEY, LoadingContext) || null;
}
export function useLoading(option?: LoadingOption) {
  const hidePopup = () => {
    // if (_vm) {
    //   removeEl();
    // }
    update({
      visible: false,
    });
  };
  const { loadingText, loadingImg, textStyle = null, hide = hidePopup } = option || {};
  const appContext = getCurrentInstance() && (getCurrentInstance() as any).appContext;
  let baseConfig = {
    visible: true,
    loadingText,
    loadingImg,
    textStyle,
    hide,
  };
  /**
   * 打开加载弹窗事件
   * @param modalProps  自定义参数
   */
  const show = (option: Partial<LoadingOption> = {}) => {
    if (_vm) {
      update({
        visible: true,
        ...option,
      });
      return;
    }
    _div = createEl();
    _vm = renderModal(Object.assign(baseConfig, option), _div);
  };
  /**
   * 模板渲染函数
   * @param props 属性参数
   * @param el 界面节点
   */
  const renderModal = (props: any, el: HTMLElement) => {
    const vm = createVNode(Loading, { ...props, update, baseConfig });
    vm.appContext = option?.parentContext || option?.appContext || appContext || vm.appContext;
    render(vm, el);
    return vm;
  };
  /**
   * 组件状态更新
   * @param {any} newConfig 配置属性
   */
  const update = (newConfig: any) => {
    if (!_vm) return;
    baseConfig = {
      ...baseConfig,
      ...newConfig,
    };
    Object.assign(_vm.component.props, baseConfig);
    _vm.component.update();
  };
  return {
    update,
    hide,
    show,
    loading,
  };
}
