/**
 * 忘记密码
 * 根据用户名获取电话号码
 * 发送短信验证码
 */
import { reactive, watch, computed } from "vue";
import { Form } from "ant-design-vue";
import {
  Api,
  useToast,
  isMobile,
  useCountDown,
  useConfigContext,
  usernameRule,
  passwordRule,
  confirmPasswordRule,
  phoneHiddenNumberRule,
  verificationCodeRule,
  usernamePassword,
  useI18n,
} from "core";
import { mangoMobileToast } from "components";
import { Captcha, CaptchaType } from "commom";

import { useRouter } from "vue-router";
const useForm = Form.useForm;

export function useForgotPass() {
  const { $t } = useI18n();
  const { toast } = useToast();
  const router = useRouter();
  const { count, isActive, start } = useCountDown();
  const { config } = useConfigContext();

  const store = reactive({
    tabIndex: 0,
    time: 60,
    timer: null,
    timeFlag: true,
    showParms: {
      text: $t("mine_forgotpass_info" /*请填写用户信息*/),
      cIndex: 0,
    },
  });
  // 用户信息表单(用户名、手机号、区号)
  const userForm = reactive<any>({
    username: "",
    phone: "",
    dialCode: "",
  });
  // 重置验证表单(手机验证码、新密码)
  const resetForm = reactive<any>({
    code: "",
    newPassword: "",
    confirmPassword: "",
  });
  // 用户信息表单--验证规则(用户名、手机号、区号)
  const userRule = reactive<any>({
    username: usernameRule(),
    phone: phoneHiddenNumberRule(),
  });
  // 重置验证表单(手机验证码、新密码)
  const resetRule = reactive<any>({
    code: verificationCodeRule(),
    newPassword: [...passwordRule(), ...usernamePassword(userForm)],
    confirmPassword: [...passwordRule(), ...confirmPasswordRule(resetForm, "newPassword")], // 确认新密码
  });
  const userAccount = useForm(userForm, userRule);
  const resetAccount = useForm(resetForm, resetRule);
  // 极验配置
  const captchaCodeConfig = { name: CaptchaType.GEETEST, clientType: isMobile ? "h5" : "web" };
  // 第一步用户信息验证
  const oneStep = async () => {
    try {
      await userAccount.validate();
      store.showParms.text = $t("user_login_sendVerificationCode" /*发送验证码*/);
      store.showParms.cIndex = 1;
    } catch (e) {
      console.log("oneStep", e);
    }
  };
  // 第二步验证码发送
  const twoStep = () => {
    try {
      getPhoneInfoCode();
      store.showParms.text = $t("user_forgotpassword_title3" /*修改密码*/);
      store.showParms.cIndex = 2;
      resetForm.code = "";
      resetForm.newPassword = "";
      resetAccount.resetFields();
    } catch (e) {
      console.log("oneStep", e);
    }
  };
  // 第三步表单提交
  const submitForgotData = async (isNotPushLogin?: boolean) => {
    try {
      await resetAccount.validate();
      const { result } = await Api.AuthService.forgotPass({
        ...userForm,
        ...resetForm,
      });
      if (!result) {
        return;
      }
      !isNotPushLogin && router.push("/entry/login");
    } catch (e) {
      console.error("submitForgotData", e);
    }
  };
  // 邮箱、手机号选项切换
  const checkTabs = (index: number) => {
    if (store.tabIndex == index) return;
    if (index === 1) return toast.warning("暂未开放");
    store.tabIndex = index;
  };
  // 根据用户名获取电话号码
  const getPhone = async () => {
    try {
      const phoneRule: any[] = usernameRule();
      if (!userForm.username || !phoneRule[1].pattern.test(userForm.username)) return;
      // 未配置验证器
      // if (!config.captchaType) {
      const { data, result, message } = await Api.UserService.getPhoneNumber({
        username: userForm.username,
      });
      if (!result) {
        window.CONFIG.currentModel === "mango" &&
          mangoMobileToast({ type: "error", title: message });
        return false;
      }
      userForm.phone = data.phone;
      userForm.dialCode = data.dialCode;
      return true;
      // }
      // 配置了验证器
      // const prams = { name: config.captchaType, clientType: isMobile ? "h5" : "web" };
      // const { data, result, message } = await Api.CommonService.captchaCode(prams);
      // if (!result) {
      //   toast.error(message);
      // }
      // const captcha = new Captcha({
      //   type: config.captchaType,
      //   config: {
      //     gt: data.gt,
      //     challenge: data.challenge,
      //   },
      //   onSuccess: async (prams: any) => {
      //     const { geetest_challenge, geetest_seccode, geetest_validate } = prams;
      //     const { data, message, result } = await Api.UserService.getPhoneNumber({
      //       geetest_challenge,
      //       geetest_seccode,
      //       geetest_validate,
      //       username: userForm.username,
      //     });
      //     if (!result) {
      //       toast.error(message);
      //       return;
      //     }
      //     userForm.phone = data.phone;
      //     userForm.dialCode = data.dialCode;
      //   },
      //   onClose: () => {},
      //   onFail: () => {},
      // });
      // await captcha.open();
    } catch (e) {
      console.log(e);
    }
  };
  // 发送短信验证码
  const getPhoneInfoCode = async () => {
    if (count.value !== 0) return;
    const param = {
      type: 4,
      phone: userForm.phone,
      dialCode: userForm.dialCode,
    };
    try {
      const { result: res, message: msg } = await Api.UserService.getPhoneInfoCode(param);
      if (!res) {
        return;
      }
      start(240);
      // const { data, result, message } = await Api.CommonService.captchaCode(captchaCodeConfig);
      // if (!result) {
      //   toast.error(message);
      // }
      // const captcha = new Captcha({
      //   type: CaptchaType.GEETEST,
      //   config: {
      //     gt: data.gt,
      //     challenge: data.challenge,
      //   },
      //   onSuccess: async (prams: any) => {
      //     const { geetest_challenge, geetest_seccode, geetest_validate } = prams;
      //     const { result: res, message: msg } = await Api.UserService.getPhoneInfoCode({
      //       ...param,
      //       geetest_challenge,
      //       geetest_seccode,
      //       geetest_validate,
      //     });
      //     if (!res) {
      //       toast.error(msg);
      //       return;
      //     }
      //     start(60);
      //   },
      //   onClose: () => {},
      //   onFail: () => {},
      // });
      // await captcha.open();
    } catch (e) {
      console.log(e);
    }
  };
  // 监听用户名修改时清空电话号码
  watch(
    () => userForm.username,
    () => {
      userForm.phone = "";
    }
  );
  return {
    store,
    router,
    count,
    isActive,
    userForm,
    resetForm,
    userRule,
    resetRule,
    resetAccount,
    userAccount,
    checkTabs,
    submitForgotData,
    getPhoneInfoCode,
    getPhone,
    oneStep,
    twoStep,
  };
}
