import { defineComponent, ref } from "vue";
import card from "./card.module.less";
import { SvgIcon } from "commom";
import { PullRefresh, List, Popup, Slider } from "vant";
import { useTask, useI18n, useWallet } from "core";
import { TaskListItem } from "@kgsport-cms/service";
import { mangoPopup } from "components";
import type { Router } from "vue-router";
import type { PropType } from "vue";

export default defineComponent({
  props: {
    type: {
      type: [String, Number],
      default: 0, // 1 登录任务 2 充值任务 3 游戏任务 4 代理分享 5 新手任务
    },
    router: Object as PropType<Router>,
  },
  setup(props) {
    const { $t } = useI18n();
    // const { changeMangoHeaderMenuShow } = useUser();
    const { amountFormat } = useWallet();
    const {
      round,
      loading,
      finished,
      taskList,
      params,
      getMissionList,
      onRefresh,
      getMissionApplyExt,
    } = useTask();
    // 详情弹窗
    const taskDetail = ref(false);
    const detailData = ref<TaskListItem>();
    const detailClick = (e: Event, item: TaskListItem) => {
      e.stopPropagation();
      e.preventDefault();
      detailData.value = item;
      taskDetail.value = true;
    };
    /**
     *
     * @param item
     * 14: "首次绑定手机号"
     * 15: "首次绑定虚拟货币"
     * 16: "设置提现密码"
     * 17: "完善个人生日"
     * 18: "绑定银行卡"
     */
    const getWelfare = (item: any) => {
      const { statisTerm } = item;
      params.current = 1;
      switch (statisTerm) {
        case 14:
          mangoPopup({
            type: "MangoSecretPhone",
            title: $t("mine_usersetup_secretPhone" /*密保手机号*/),
            props: {
              callback: () => {
                getMissionList();
              },
            },
            closeCallBack: () => {
              getMissionList();
            },
          }).open();
          break;
        case 15:
          mangoPopup({
            type: "MangoBankCardManage",
            title: $t("funds_wallet_cardManager" /* 卡片管理 */),
            props: { type: "V" },
            closeCallBack: () => {
              getMissionList();
            },
          }).open();
          break;
        case 16:
          mangoPopup({
            type: "MangoWithdrawalPassSettings",
            title: $t("mine_usersetup_withdrawalPassSettings" /*提现密码设置*/),
            closeCallBack: () => {
              getMissionList();
            },
            props: {
              callback: () => {
                getMissionList();
              },
            },
          }).open();
          break;
        case 17:
          mangoPopup({
            type: "MangoPersonalInfo",
            title: $t("mine_userinfo_PersonalInformation" /*个人信息*/),
            closeCallBack: () => {
              getMissionList();
            },
          }).open();
          break;
        case 18:
          mangoPopup({
            type: "MangoBankCardManage",
            title: $t("funds_wallet_cardManager" /* 卡片管理 */),
            props: { type: "B" },
            closeCallBack: () => {
              getMissionList();
            },
          }).open();
          break;
        default:
          break;
      }
    };

    const btnClick = async (item: TaskListItem) => {
      // rewordStatus 当前的状态 1 未参加（显示参加按钮） 2 未满足（显示 去充值 去玩游戏） 3 待审核（按钮置灰） 4 待领取（跳转福利中心） 5 已完成
      const { rewordStatus } = item.details[item.currentNum - 1];
      if (rewordStatus === 1) {
        // "参加"
        await getMissionApplyExt({ hallId: item.id }, item.current);
      } else if (rewordStatus === 2) {
        const obj = {
          "2": () =>
            mangoPopup({
              type: "MangoRechargeWithdrawModal",
              props: { tab: "charge" },
            }).open(),
          "3": () => {
            mangoPopup().destroyAll();
            props.router?.push("/");
          },
          "4": () => {
            mangoPopup().destroyAll();
            props.router?.push("/agent");
          },
          "5": () => {
            getWelfare(item);
          },
        } as { [key: string]: () => void };
        Object.keys(obj).includes(String(props.type)) && obj[String(props.type)]();
      } else if (rewordStatus === 4) {
        mangoPopup({
          type: "MangoWelfareCenter",
          // 福利中心
          title: $t("mine_center_welfare"),
          props: {
            activityTab: 7,
          },
        }).open();
      }
    };
    const getBtnText = (item: TaskListItem) => {
      const { rewordStatus } = item.details[item.currentNum - 1];
      if (rewordStatus === 1) {
        return $t("common_participatein" /*参加*/);
      } else if (rewordStatus === 2) {
        const obj = {
          "2": $t("common_to_recharge" /*去充值*/),
          "3": $t("common_to_play_game" /*去玩游戏*/),
          "4": $t("common_to_share" /*去分享*/),
          "5": $t("funds_withdraw_gotoBind" /*去绑定*/),
        } as {
          [key: string]: string;
        };
        return Object.keys(obj).includes(String(props.type)) ? obj[props.type] : "";
      } else if (rewordStatus === 3) {
        return $t("mine_welfare_pending_review" /*待审核*/);
      } else if (rewordStatus === 4) {
        return $t("common_unclaimed" /*待领取*/);
      } else {
        return $t("mine_welfare_finished" /*已完成*/);
      }
    };

    const startX = ref(0);
    /**
     * 监听mousedown
     * @param e
     * @param item
     * @returns
     */
    const mouseDown = (e: MouseEvent, item: TaskListItem) => {
      startX.value = e.screenX;
    };
    /**
     * 监听mouseup
     * @param e
     * @param item
     * @returns
     */
    const mouseUp = (e: MouseEvent, item: TaskListItem) => {
      const x2 = e.screenX;
      const curSpeed =
        (item.details[item.currentNum - 1].currentCondition /
          item.details[item.currentNum - 1].conditionReword) *
        100;
      const dif = x2 - startX.value;
      if (Math.abs(dif) < 30) return;
      if (dif > 0) {
        if (curSpeed < 100) return;
        // 向右 +
        item.currentNum < item.totalNum && (item.currentNum += 1);
      } else {
        // 向左 -
        item.currentNum > 1 && (item.currentNum -= 1);
      }
      const speed =
        (item.details[item.currentNum - 1].currentCondition /
          item.details[item.currentNum - 1].conditionReword) *
        100;
      item.speed = speed >= 100 ? 100 : round(speed, 2);
    };

    const isStyle2 = ["lam102"].includes(window.CONFIG.name);

    return () => [
      <div class={card.taskCard}>
        <PullRefresh v-model={loading.value} onRefresh={onRefresh}>
          <List
            v-model:loading={loading.value}
            finished={finished.value}
            finished-text={
              taskList.value?.length
                ? $t("common_pagelist_end" /*- 已加载全部内容 -*/)
                : $t("common_no_data" /*暂无数据*/)
            }
            immediateCheck={false}
            onLoad={getMissionList}
          >
            {taskList.value?.map?.(item => (
              <div
                key={item.id}
                class={card.taskCardItem}
                onMousedown={(e: MouseEvent) => mouseDown(e, item)}
                onMouseup={(e: MouseEvent) => mouseUp(e, item)}
              >
                <div class={card.taskCardItemLeft}>
                  <section class={card.taskCardTitle} onClick={(e: Event) => detailClick(e, item)}>
                    {item.title}
                  </section>
                  <section class={card.taskCardDesc}>{item.description}</section>
                  <section class={card.sliderbox}>
                    <section class={card.taskCardBar}>
                      <Slider
                        v-model={item.speed}
                        bar-height="8px"
                        inactive-color="@cms-mango-text-color4"
                        active-color="var(--cms-mango-primary-color)"
                        button-size="0"
                        disabled
                      />
                    </section>
                    <article
                      class={[
                        card.taskCardBarText,
                        item.speed === 100 && card.taskCardBarTextLight,
                      ]}
                    >
                      {item.speed}%
                    </article>
                  </section>
                  <section class={[card.taskCardTip]}>
                    <article class={card.taskCardTipRew}>
                      {$t("mine_task_cashReward" /*现金奖励*/)}：
                      <span>+{amountFormat(item.details[item.currentNum - 1].rewordAmount)}</span>
                    </article>
                    <article class={card.taskCardTipPro}>
                      {item.currentNum} / {item.totalNum}
                    </article>
                  </section>
                </div>
                {/*这个按钮，开启任务详情后显示，点击跳转到另外的一个任务详情页面（目前暂时不需要实现）*/}
                {!!item.missionDetailFlag && (
                  <SvgIcon
                    name="roundArrow"
                    style={{ current: "pointer" }}
                    class={card.taskCardItemArrow}
                    width="24px"
                    height="24px"
                  />
                )}
                {props.type === "1" || !getBtnText(item) ? null : (
                  <div class={card.taskCardItemRight}>
                    <section
                      class={[
                        card.taskCardBtn,
                        item.details?.[item.currentNum - 1]?.rewordStatus === 3
                          ? card.taskCardBtnDisable
                          : item.details?.[item.currentNum - 1]?.rewordStatus === 2
                          ? isStyle2
                            ? card.taskCardBtnCom2
                            : card.taskCardBtnCom
                          : card.taskCardBtnNor,
                      ]}
                      onClick={() => btnClick(item)}
                    >
                      {getBtnText(item)}
                    </section>
                  </div>
                )}
              </div>
            ))}
          </List>
        </PullRefresh>
      </div>,
      <Popup class={card.taskCardPopup} v-model:show={taskDetail.value}>
        <div class={card.taskCardDetail}>
          <section class={card.taskCardDetailTitle}>
            {$t("common_task_detail" /*任务详情*/)}
            <SvgIcon
              class={card.taskCardDetailTitleClose}
              onClick={() => (taskDetail.value = false)}
              name="close"
              width="24px"
              height="24px"
            />
          </section>
          <div class={card.taskCardDetailContent}>
            <section class={card.taskCardDetailItem}>
              <article class={card.taskCardDetailItemTitle}>
                {$t("common_task_briefing" /*任务简介*/)}
              </article>
              <article class={card.taskCardDetailItemDesc}>{detailData.value?.description}</article>
            </section>
            <section class={card.taskCardDetailItem}>
              <article class={card.taskCardDetailItemTitle}>
                {$t("common_task_effective_time" /*任务有效时间*/)}
              </article>
              <article class={card.taskCardDetailItemDesc}>
                {/*2022-12-01 00:00:00 至 2022-12-31 00:00:00*/}
                {detailData.value?.missionTime}
              </article>
            </section>
            <section class={card.taskCardDetailItem}>
              <article class={card.taskCardDetailItemTitle}>
                {$t("common_task_scope" /*任务范围*/)}
              </article>
              <article class={card.taskCardDetailItemDesc}>
                {detailData.value?.missionRange}
              </article>
            </section>
            <section class={card.taskCardDetailItem}>
              <article class={card.taskCardDetailItemTitle}>
                {$t("common_task_mission_statement" /*任务说明*/)}
              </article>
              <article class={card.taskCardDetailItemDesc}>
                {detailData.value?.missionCaption}
              </article>
            </section>
          </div>
        </div>
      </Popup>,
    ];
  },
});
