import { defineComponent, onMounted, watchEffect } from "vue";
import { IVirtualCurrency } from "@kgsport-cms/service";
import { useI18n, useUserBank, useWithdraw, useModalContext, useClipboards } from "core";
import { Image } from "commom";
import bindUsdt from "./bindUsdt.module.less";
import { MangoTipsModal, mangoPopup } from "components";
import { Field, Button } from "vant";
export default defineComponent({
  emits: ["close"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const test = useModalContext();
    const { text } = useClipboards();
    const { store, bankForm, bankDebounceSubmit, tipsModalShow } = useUserBank("V");
    const { getVirtualCurrency, virtualCurrency } = useWithdraw();
    const addvirtual = async () => {
      if (!bankForm.cardNo || !bankForm.alias) return;
      await bankDebounceSubmit();
    };

    bankForm.type = "V";
    const paste = () => {
      try {
        navigator.clipboard.readText().then(v => {
          bankForm.cardNo = v;
        });
      } catch (e) {}
    };
    const toConfirm = () => {
      tipsModalShow.value = false;
      mangoPopup().close();
    };

    watchEffect(() => {
      if (virtualCurrency.value && virtualCurrency.value.length) {
        bankForm.currency = virtualCurrency.value[0].coin;
        bankForm.network = virtualCurrency.value[0].protocol[0];
      } else {
        bankForm.currency = "";
        bankForm.network = "";
      }
    });

    const clickNetwork = (item: IVirtualCurrency) => {
      bankForm.currency = item.coin;
      bankForm.network = item.protocol[0];
    };

    onMounted(getVirtualCurrency);

    return () => (
      <div class={bindUsdt.bindUsdtForm}>
        <div class={bindUsdt.topLine} />
        <div class={bindUsdt.bindUsdtFormItem}>
          <p>{$t(["funds_bank_alias", "funds_card_title"])}</p>
          <div class={bindUsdt.bindUsdtFormItemCon}>
            <div class={bindUsdt.list}>
              <Field
                type="text"
                class={bindUsdt.input}
                v-model={bankForm.alias}
                maxlength={19}
                placeholder={$t("funds_bank_aliasHolder")}
              />
            </div>
          </div>
        </div>
        <div class={bindUsdt.bindUsdtFormItem}>
          <p>{$t("funds_bank_virtualCurrencyType")}</p>
          <div class={bindUsdt.bindUsdtFormItemCon}>
            {virtualCurrency.value?.map(item => (
              <div
                onClick={() => clickNetwork(item)}
                class={[
                  bindUsdt.bindUsdtFormItemConType,
                  bankForm.currency === item.coin && bindUsdt.bindUsdtFormItemConTypeActive,
                ]}
              >
                <span class={bindUsdt.bindUsdtFormItemConTypeImg}>
                  <Image src={item.icon} />
                </span>
                <span>{item.coin}</span>
              </div>
            ))}
          </div>
        </div>
        <div class={bindUsdt.bindUsdtFormItem}>
          <p>{$t("funds_bank_virtualCurrencyAgreement")}</p>
          <div class={bindUsdt.bindUsdtFormItemCon}>
            {virtualCurrency.value
              ?.find(list => list.coin === bankForm.currency)
              ?.protocol.map(item => (
                <span
                  onClick={() => (bankForm.network = item)}
                  class={[
                    bindUsdt.bindUsdtFormItemConType,
                    bankForm.network === item && bindUsdt.bindUsdtFormItemConTypeActive,
                    bindUsdt.typeInfo,
                  ]}
                >
                  {item}
                </span>
              ))}
          </div>
        </div>
        <div class={bindUsdt.bindUsdtFormItem}>
          <p>{$t("funds_card_xuNiBiDiZhi")}</p>
          <div class={bindUsdt.bindUsdtFormItemCon}>
            <div class={bindUsdt.list}>
              <input
                class={bindUsdt.input}
                v-model={bankForm.cardNo}
                onInput={() => {
                  bankForm.cardNo = bankForm.cardNo.replace(/[\u4e00-\u9fff]/g, "");
                }}
                type="text"
                placeholder={$t(["common_input", "funds_card_xuNiBiDiZhi"])}
              />
              {/* <div class={bindUsdt.paste} onClick={() => paste()}>
                {$t("common_paste")}  粘贴
              </div> */}
            </div>
          </div>
        </div>
        <div class={bindUsdt.bindUsdtFormTips}>
          {/*<ExclamationCircleFilled />*/}
          <span>{$t("funds_virtual_warn_tips")}</span>
        </div>
        <Button
          class={[bindUsdt.btnAdd, (!bankForm.cardNo || !bankForm.alias) && bindUsdt.disableBtn]}
          loading={store.addLoading}
          onClick={() => {
            addvirtual();
            test?.close();
          }}
        >
          {$t("mine_userinfo_confirmSubmission" /*确认提交*/)}
        </Button>
        <MangoTipsModal
          v-model:show={tipsModalShow.value}
          title={$t("common_commit_success" /*提交成功*/)}
          subTitle={$t("common_complete" /*完成*/)}
          type="success"
          confirmText={$t("common_confirm" /*确定*/)}
          confirmCallback={toConfirm}
          cancelCallback={() => mangoPopup().close()}
        />
      </div>
    );
  },
});
