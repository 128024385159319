import { defineComponent, reactive } from "vue";
import styleModule from "./style.module.less";
import { Popup } from "vant";
import popup from "templates/mango/Web/popup.module.less";
import { useI18n, userRecord } from "core";

export const SelectType = defineComponent({
  emits: ["change"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { store, fundsTypeList, fundsType, fundsActiveText, initStatus } = userRecord();
    const data = reactive({
      show: false,
    });
    const activeType = (index: number) => {
      store.fundsType = index;
      initStatus();
      data.show = false;
      emit("change");
    };
    return () => (
      <>
        <div
          class={`${styleModule.selectTransactionTypeText} ${
            data.show ? styleModule.selectTransactionTypeTextActive : ""
          }`}
          onClick={() => (data.show = !data.show)}
        >
          <span>{$t(fundsActiveText.value)}</span>
        </div>
        <Popup v-model:show={data.show} position="bottom" round class={popup.popupWidth100}>
          <div class={[popup.popupHeader, popup.popupHeaderBg]}>
            <section class={popup.popupHeaderLeft} onClick={() => (data.show = false)}>
              {$t("common_cancel" /* "取消" */)}
            </section>
            <section class={popup.popupHeaderMiddle}>
              {$t("mine_changeRecord_MoneyType" /*资金类型*/)}
            </section>
            <section class={popup.popupHeaderRight}></section>
          </div>
          <div class={styleModule.popupTransaction}>
            <div class={styleModule.popupTransactionList}>
              <ul class={styleModule.popupTransactionListBox}>
                {fundsTypeList.map((item, index) => (
                  <li
                    class={[
                      styleModule.popupTransactionListBoxItem,
                      item.value === fundsType.value ? styleModule.active : null,
                    ]}
                    onClick={() => activeType(index)}
                  >
                    {$t(item.label)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Popup>
      </>
    );
  },
});
