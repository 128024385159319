import { defineComponent } from "vue";
import styles from "./style.module.less";
import gouPng from "./assets/gou.png";
import closePng from "./assets/close.png";
import { mangoPopup } from "components";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "DICA",
    },
    desc: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    return () => (
      <div class={styles.cashMessage}>
        <div class={styles.cashMessageCon}>
          <div class={styles.cashMessageConTop}>
            <img src={gouPng} alt="" />
          </div>
          <div class={styles.cashMessageConTitle}>{props.title}</div>
          <div class={styles.cashMessageConMsg}>{props.desc}</div>
          <img
            class={styles.cashMessageConClose}
            src={closePng}
            alt=""
            onClick={() => mangoPopup().close()}
          />
        </div>
      </div>
    );
  },
});
