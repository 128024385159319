import { defineComponent, nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import { Table, Empty } from "ant-design-vue";
import styles from "./style.module.less";
import { SvgIcon, Loading } from "commom";
import { mangoPopup } from "components";
import { useI18n, useAuth, useTurntable, useWallet, useUser } from "core";
import { DrawTurntable } from "@kgsport-cms/service";

import imgTop from "./assets/img_top.png";
import c from "./assets/c.png";
import one from "./assets/one.png";
import two from "./assets/two.png";
import three from "./assets/three.png";
import four from "./assets/four.png";
import five from "./assets/five.png";
import six from "./assets/six.png";
import arrow from "./assets/arrow.png";
import bg from "./assets/bg.png";
import btn_bg1 from "./assets/btn_bg1.png";
import btn_bg2 from "./assets/btn_bg2.png";
import btn_c from "./assets/btn_c.png";
import p from "./assets/p.png";
import spin_btn from "./assets/spin_btn.png";

// 音乐
import ring_bg from "./assets/music/ring_bg.mp3"; // 默认背景音乐
import ring_bet from "./assets/music/ring_bet.mp3"; // 点击音效
import ring_scrol from "./assets/music/ring_scrol.mp3"; // 旋转转盘音效
import ring_win from "./assets/music/ring_win.mp3"; // 获奖
import ring_loss from "./assets/music/ring_loss.mp3"; // 空奖

const music = {
  bg: ring_bg,
  bet: ring_bet,
  scrol: ring_scrol,
  win: ring_win,
  loss: ring_loss,
};

export default defineComponent({
  setup() {
    const { currencySymbol } = useUser();
    const { $t } = useI18n();
    const { isLogin } = useAuth();
    const { amountFormat } = useWallet();
    const {
      store,
      recordShow,
      changeRecordShow,
      isScroll,
      changeScroll,
      isPausedBg,
      setPausedBg,
      resetTurn,
      turntablePrize,
      activityTurn,
      turntableDetailList,
      turntableDetailListMoneyH5,
      drawTurntable,
      intermediateResult,
      setIntermediate,
      awardRulesStatus,
      changeAwardRules,
      getActivityTurntable,
      getTurntableDetailList,
    } = useTurntable();

    const columns = [
      {
        title: $t("mine_fundDetails_date" /*日期*/),
        dataIndex: "createDatetime",
        key: "createDatetime",
      },
      {
        title: $t("user_register_userName" /*用户名*/),
        dataIndex: "account",
        key: "account",
      },
      {
        title: $t("mine_award_turn" /*派奖*/),
        dataIndex: "money",
        key: "money",
      },
    ];
    const audioBg = ref<HTMLAudioElement>();
    const audioBet = ref<HTMLAudioElement>();
    const audioScrol = ref<HTMLAudioElement>();
    const audioWin = ref<HTMLAudioElement>();
    const audioLoss = ref<HTMLAudioElement>();
    const audioBgToggle = () => {
      audioBg.value?.paused ? audioBg.value?.play() : audioBg.value?.pause();
      setPausedBg(!!audioBg.value?.paused);
    };
    const isMounted = ref(false);
    let timer1: NodeJS.Timeout,
      timer2: NodeJS.Timeout,
      timer3: NodeJS.Timeout,
      timer4: NodeJS.Timeout;
    const spinHandle = () => {
      if (!isLogin.value) return;
      if (activityTurn.value && !activityTurn.value?.qualifications) return;
      if (isScroll.value) return;
      audioBet.value?.play();
      timer1 = setTimeout(async () => {
        changeScroll(true);
        audioScrol.value?.play();
        const data = await drawTurntable();
        timer2 = setTimeout(async () => {
          changeScroll(false);
          if (!data) {
            audioScrol.value?.pause();
            audioScrol.value!.currentTime = 0;
            audioLoss.value?.play();
            resetTurn();
            return;
          }
          audioScrol.value?.pause();
          audioScrol.value!.currentTime = 0;
          audioWin.value?.play();
          const index = turntablePrize.value.prizeData?.findIndex(
            (d: { id: number }) => d.id === (data as DrawTurntable)?.prize
          );
          if (typeof index !== "undefined" && index > -1) {
            setIntermediate(index);
          }

          timer3 = setTimeout(() => {
            moneyRef.value.scrollLeft = moneyRef.value.scrollWidth;
          }, 1000);
        }, store.diff);
      }, 0);
    };

    const moneyRef = ref();
    onMounted(async () => {
      isMounted.value = true;
      store.loading = true;
      await Promise.all([getActivityTurntable(), getTurntableDetailList()]);
      store.loading = false;
      nextTick(() => {
        isPausedBg.value ? audioBg.value?.pause() : audioBg.value?.play();
      });
      timer4 = setTimeout(() => {
        moneyRef.value.scrollLeft = moneyRef.value.scrollWidth;
      }, 1000);
    });
    onBeforeUnmount(() => {
      isMounted.value = false;
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      resetTurn();
    });

    return () => (
      <div class={styles.turntable}>
        <Loading v-model:visible={store.loading} />
        <audio ref={audioBg} src={ring_bg} autoplay loop />
        <audio ref={audioBet} src={ring_bet} />
        <audio ref={audioScrol} src={ring_scrol} loop />
        <audio ref={audioWin} src={ring_win} />
        <audio ref={audioLoss} src={ring_loss} />

        <div class={styles.turntableTop}>
          <img src={imgTop} alt="" />
          <SvgIcon
            class={[styles.turntableTopSvg, styles.turntableTopSvgL]}
            name={isPausedBg.value ? "soundClose" : "sound"}
            width="0.34rem"
            height="0.34rem"
            onClick={audioBgToggle}
          />
          <SvgIcon
            class={[styles.turntableTopSvg, styles.turntableTopSvgR]}
            name="closeWhite"
            width="0.34rem"
            height="0.34rem"
            onClick={mangoPopup().close}
          />
          <div class={styles.turntableTopText}>
            <section>
              {currencySymbol.value}{" "}
              {activityTurn.value?.initDrawAmount
                ? amountFormat(activityTurn.value?.initDrawAmount)
                : ""}
            </section>
            <section>{$t("mine_award_amount" /*开奖金额*/)}</section>
          </div>
        </div>
        <div class={styles.turntableMbg}></div>
        <img class={styles.turntableIbg} src={bg} alt="" />
        <div class={styles.turntableLbg}></div>
        <div class={styles.turntableTurn}>
          <div class={styles.turntableTurnBox}>
            <div class={styles.turntableImgs}>
              <img
                class={[styles.turntableImgsImg, isMounted.value && styles.turntableImgsRotate]}
                src={c}
                alt=""
              />
              <img
                class={[styles.turntableImgsImg2, isMounted.value && styles.turntableImgsRotate]}
                src={c}
                alt=""
              />
              <img
                class={[styles.turntableImgsImg, isMounted.value && styles.turntableImgsRotate2]}
                src={one}
                alt=""
              />
              <img
                class={[styles.turntableImgsImg, isMounted.value && styles.turntableImgsRotate]}
                src={two}
                alt=""
              />
              <img
                class={[styles.turntableImgsImg, isMounted.value && styles.turntableImgsRotate2]}
                src={three}
                alt=""
              />
              <img
                class={[styles.turntableImgsImg, isMounted.value && styles.turntableImgsRotate]}
                src={four}
                alt=""
              />
              <img
                class={[styles.turntableImgsImg, isMounted.value && styles.turntableImgsRotate2]}
                src={five}
                alt=""
              />
              <img
                class={[styles.turntableImgsImg, isMounted.value && styles.turntableImgsRotate]}
                src={six}
                alt=""
              />
              <img
                class={[styles.turntableImgsImg3, isScroll.value && styles.turntableImgsScroll]}
                src={activityTurn.value?.turntablePic}
                alt=""
                style={{ transform: `rotate(${intermediateResult.value}deg)` }}
              />
              <img class={styles.turntableImgsSpinBtn} src={spin_btn} alt="" onClick={spinHandle} />
            </div>
            <img class={[styles.turntableTurnBoxArrow]} src={arrow} alt="arrow" />
          </div>
        </div>
        <div class={styles.turntableContent}>
          {/*<img class={styles.turntableLogo} src={activityTurn.value?.tenantLogo} alt="" />*/}
          <div class={styles.turntableBtn}>
            <img class={styles.turntableBtnBg} src={isLogin.value ? btn_bg2 : btn_bg1} alt="" />
            <section class={styles.turntableBtnC}>
              <img src={btn_c} alt="" />
              <article class={styles.turntableBtnCT}>
                {activityTurn.value?.qualifications || 0}
              </article>
            </section>
            {isLogin.value ? (
              <section class={styles.turntableBtnT}>
                {activityTurn.value?.initDrawTimes || 0}/{activityTurn.value?.dayDrawLimit || 0}
              </section>
            ) : (
              <section class={styles.turntableBtnT}>
                {$t("mine_login_rotate" /*登录并旋转*/)}
              </section>
            )}
          </div>
          <div class={styles.turntableDetail}>
            <section onClick={() => changeAwardRules(true)}>
              {$t("common_award_rules" /*获奖规则*/)}
            </section>
            <section onClick={() => changeRecordShow(true)}>
              {$t("mine_award_records" /*获奖记录*/)}
            </section>
          </div>
        </div>
        {awardRulesStatus.value && (
          <div class={styles.turntableAwardRules}>
            <div class={styles.turntableAwardRulesShadow} onClick={() => changeAwardRules(false)} />
            <div class={styles.turntableAwardRulesMain}>
              <div class={styles.turntableAwardRulesMainHeader}>
                <section class={styles.turntableAwardRulesMainHeaderBtn}>
                  {$t("common_award_rules" /*获奖规则*/)}
                </section>
                <SvgIcon
                  class={styles.turntableAwardRulesMainClose}
                  name="closeWhite"
                  width="0.32rem"
                  height="0.32rem"
                  color="#fff"
                  onClick={() => changeAwardRules(false)}
                />
              </div>
              <div
                class={styles.turntableAwardRulesMainContent}
                v-html={activityTurn.value?.desc}
              />
            </div>
          </div>
        )}
        <div class={styles.turntableMoney} ref={moneyRef}>
          {turntableDetailListMoneyH5.value?.map(item => (
            <div class={styles.turntableMoneyItem}>
              <img src={activityTurn.value?.prizeCurLogo || p} alt="" />
              <span>{item.money}</span>
            </div>
          ))}
        </div>

        <div
          class={styles.turntableTable}
          style={{ transform: `translateX(${recordShow.value ? 0 : "100%"})` }}
        >
          <div class={styles.turntableTableTop}>
            <section class={styles.turntableTableTopTitle}>
              {$t("mine_award_records" /*获奖记录*/)}
            </section>
            <section class={styles.turntableTableTopBack} onClick={() => changeRecordShow()}>
              <SvgIcon
                class={styles.turntableTableTopBackIcon}
                name="arrowLeftLine"
                width="0.24rem"
                height="0.24rem"
              />
              {$t("user_login_back" /*返回*/)}
            </section>
          </div>
          <div class={styles.turntableTableMain}>
            <Table
              columns={columns}
              dataSource={turntableDetailList.value}
              scroll={{ x: 600, y: 342 }}
              v-slots={{
                emptyText: () => (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={$t("agent_HomeMA_nodata" /*暂无数据*/)}
                  />
                ),
              }}
              pagination={false}
            />
          </div>
        </div>
      </div>
    );
  },
});
