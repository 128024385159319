import { computed, reactive } from "vue";
import { useAuth, useUser } from "core";
import { Api } from "../services";

export interface DataType {
  pcImage?: string;
  showType?: number;
  appImage?: string;
  pushRange?: number;
  popsCount?: number;
  position?: string;
  messageId?: number;
  title?: string;
  content?: string;
  createTime?: number;
  id?: string;
  unId?: string;
  popupPic?: string;
  whetherJump?: number;
  jumpUrl?: string;
}
interface TData {
  list: DataType[];
}
export function useIndexPopup() {
  const { isLogin } = useAuth();
  const { user } = useUser();
  const store = reactive<TData>({
    list: [],
  });
  const personList = computed(() => store.list);
  const getPersonList = async () => {
    if (!isLogin.value && window.CONFIG?.currentModel !== "mango") return;
    try {
      const position = 2;
      const { result, data } = await Api.MessageService.propMessage({
        position,
        username: isLogin.value ? user.value?.account : "",
      });

      if (result) {
        store.list = data || [];
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    personList,
    getPersonList,
  };
}
