/*
 * @Descripttion   : your project
 * @Author         : amber
 * @Date           : 2022-09-11 22:57:36
 * @LastEditors    : amber
 * @LastEditTime   : 2022-09-11 23:02:38
 */
import { defineComponent } from "vue";
import help from "./HelpDetail.module.less";

export default defineComponent({
  props: {
    content: String,
  },
  setup(props) {
    return () => <div class={help.container} v-html={props.content}></div>;
  },
});
