import { defineComponent, computed, PropType } from "vue";
import empty from "./form.module.less";
import { useRouter } from "vue-router";
import defaultPng from "./assets/default.png";
import betPng from "./assets/bet.png";
import messagePng from "./assets/message.png";
import netWorkPng from "./assets/netWork.png";
import mangoNoData from "./assets/mangoNoData.png";
import { merge } from "lodash";
import { useI18n } from "core";

export default defineComponent({
  props: {
    // 使用场景 通用（default），投注（bet），消息（message）， 网络（netWork），充值（recharge）
    type: {
      type: String as PropType<string>,
      default: "default",
    },
    // 距离父元素的顶部距离
    top: {
      type: Number as PropType<number>,
      default: 0,
    },
    // 自定义配置
    option: {
      type: [Object, undefined, null] as PropType<object | undefined | null>,
      default: () => {},
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const router = useRouter();
    const data = {
      default: {
        img: defaultPng,
        text: $t("message_listEmpty_tittle" /* 这里没有其他内容哦 */),
        urlText: $t("message_listEmpty_goOther" /* 去别处看看 */),
        callback: () => {
          router.push("/");
        },
      },
      bet: {
        img: betPng,
        text: $t("game_noBettingRecord" /* 暂无投注记录 */),
        urlText: $t("message_listEmpty_goOther" /* 去别处看看 */),
        callback: () => {
          router.push("/");
        },
      },
      message: {
        img: messagePng,
        text: $t("tip_message_noMessageNotice" /* 暂无消息通知 */),
        urlText: $t("message_listEmpty_goOther" /* 去别处看看 */),
        callback: () => {
          router.push("/");
        },
      },
      recharge: {
        img: betPng,
        text: $t("game_noRechargeRecordTemporarily" /* 暂无充值记录 */),
        urlText: $t("message_listEmpty_goOther" /* 去别处看看 */),
        callback: () => {
          router.push("/");
        },
      },
      netWork: {
        img: netWorkPng,
        text: $t("game_signalLossUnconnect" /* 信号丢失暂时无法连接 */),
        urlText: $t("tip_message_clickTheRefresh" /* 刷新 */),
        callback: () => {
          router.go(0);
        },
      },
      mangoNoData: {
        img: mangoNoData,
        text: $t("common_no_data" /* 暂无数据 */),
      },
    };
    // const options = Object.assign(data, props.option);
    const options = merge(data, props.option);
    const type = Object.keys(options).includes(props.type) ? props.type : "default";
    const dataObj: any = computed(() => options[type]);
    return () => (
      <div class={empty.empty} style={{ marginTop: props.top + "px" }}>
        <img src={dataObj.value?.img} alt="" />
        <div class={[empty.emptyWrap, window.CONFIG?.currentModel === "mango" && empty.emptyNoBg]}>
          <p class={window.CONFIG?.currentModel === "mango" && empty.emptyWrapMango}>
            {dataObj.value.text}
          </p>
          <p v-show={dataObj.value.urlText} onClick={() => dataObj.value.callback()}>
            {dataObj.value.urlText}
          </p>
        </div>
      </div>
    );
  },
});
