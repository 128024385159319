import { defineComponent, onMounted, ref, watch } from "vue";
import { Form, Input } from "ant-design-vue";
import { Button } from "vant";
import style from "./emailaddress.module.less";
import { useI18n, useUser } from "core";
import { mangoPopup } from "components";

export default defineComponent({
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { profile, getProfile, count, form, userAccount, getEmailCode, editDebounceSubmit } =
      useUser();
    const isMounted = ref(false);
    const isSendEmail = ref(false);

    const submit = async () => {
      if (profile.value?.emailOption === 0) {
        await userAccount.validate(["email"]);
        await editDebounceSubmit([], false, () => {
          mangoPopup().close();
        });
        return;
      }
      if (isSendEmail.value) {
        await editDebounceSubmit(
          profile.value?.emailOption === 1 ? ["emailCode"] : [],
          false,
          () => {
            mangoPopup().close();
          }
        );
      } else {
        isSendEmail.value = Boolean(
          await getEmailCode({
            email: form.email,
          })
        );
      }
    };
    watch(
      () => count.value,
      n => {
        if (!n) isSendEmail.value = false;
      }
    );
    const getText = () => {
      if (count.value) return <div class={style.formWrapCodeText}>{count.value + "s"}</div>;
      if (!isSendEmail.value) return "";
      return (
        <div
          class={style.formWrapSendText}
          onClick={async () => {
            isSendEmail.value = Boolean(
              await getEmailCode({
                email: form.email,
              })
            );
          }}
        >
          {$t("common_againSend" /*重新发送*/)}
        </div>
      );
    };

    onMounted(async () => {
      if (!profile.value) await getProfile();

      setTimeout(() => {
        userAccount.clearValidate();
        isMounted.value = true;
      });
    });

    return () => (
      <div class={style.formWrap}>
        {isMounted.value && (
          <Form validateTrigger={"onBlur"}>
            {!isSendEmail.value ? (
              <Form.Item name="email" validateFirst={true} {...userAccount.validateInfos.email}>
                <Input
                  placeholder={$t("mine_usersetup_enterEmailAddress" /*输入您的邮箱地址 */)}
                  bordered={false}
                  v-model:value={form.email}
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item name="emailCode" validateFirst {...userAccount.validateInfos.emailCode}>
                  <Input.Group compact>
                    <Input
                      placeholder={$t("user_register_input_verifyCode" /*请输入验证码*/)}
                      bordered={false}
                      type="number"
                      v-model={form.emailCode}
                    />
                    {getText()}
                  </Input.Group>
                </Form.Item>
                <div class={style.formWrapTips}>
                  {$t(
                    "mine_usersetup_emailTips" /*邮箱验证码以邮件形式发送至您的邮箱，请注意查收*/
                  )}
                </div>
              </>
            )}

            <div class={style.formWrapBtns}>
              <Button onClick={submit}>
                {profile.value?.emailOption === 1
                  ? isSendEmail.value
                    ? $t("mine_userinfo_confirmSubmission" /**确认提交 */)
                    : $t("mine_usersetup_sendVerificationCode" /**发送邮箱验证码 */)
                  : $t("mine_userinfo_confirmSubmission" /**确认提交 */)}
              </Button>
            </div>
          </Form>
        )}
      </div>
    );
  },
});
