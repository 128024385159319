import { defineComponent, onMounted, reactive, computed } from "vue";
import entry from "../style.module.less";
import { Tab, Tabs, Button } from "vant";
import { useConfigContext, useForgotPass, useI18n } from "core";
import { Form } from "ant-design-vue";
import { SvgIcon } from "commom";

export default defineComponent({
  setup() {
    const { goService } = useConfigContext();
    const { $t } = useI18n();
    const {
      count,
      userForm,
      resetForm,
      resetAccount,
      userAccount,
      submitForgotData,
      getPhoneInfoCode,
      getPhone,
    } = useForgotPass();
    const data = reactive({
      checked: true,
      isMounted: false,
      togglePassword: false,
      step: "1",
    });
    const eye = computed(() => (data.togglePassword ? "eyeShow" : "eyeHide"));
    const onNext = async () => {
      switch (data.step) {
        case "1":
          try {
            await userAccount.validate("username");
            const res = await getPhone();
            if (!res) return;
          } catch (e) {
            console.log(e);
            return;
          }
          break;
        case "2":
          break;
        case "3":
          await submitForgotData(true);
          break;
      }
      if (Number(data.step) !== 3) {
        data.step = (Number(data.step) + 1).toString();
      }
    };
    onMounted(() => setTimeout(() => (data.isMounted = true), 10));
    return () => (
      <div
        class={[entry.formBox, data.isMounted && entry.formBoxShow]}
        style={{ padding: "0 20px" }}
      >
        <Tabs v-model:active={data.step} class={entry.forgetStep}>
          <Tab
            title={$t("user_findPassword_inputUsername" /* 输入用户名 */) + ">"}
            name="1"
            titleClass={Number(data.step) > 0 && entry.forgetStepActive}
          >
            <div class={entry.forget}>
              <div class={entry.formBoxForm}>
                <div class={entry.formBoxFormSelect}>
                  <div class={entry.formBoxFormSelectContent}>
                    <Form>
                      <Form.Item {...userAccount.validateInfos.username}>
                        <input
                          v-model={userForm.username}
                          placeholder={$t(
                            "user_findPassword_pleaseInputUserName" /* 请输入用户名 */
                          )}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            title={$t("user_findPassword_acceptCaptcha" /* 接受验证码 */) + ">"}
            name="2"
            titleClass={Number(data.step) > 1 && entry.forgetStepActive}
          >
            <div class={entry.forget}>
              <div>
                <div
                  class={[entry.formBoxFormCheckbox, entry.forgetSms]}
                  onClick={() => (data.checked = !data.checked)}
                >
                  <i
                    class={[
                      entry.formBoxFormCheckboxIcon,
                      data.checked && entry.formBoxFormCheckboxIconChecked,
                    ]}
                  />
                  {$t("user_findPassword_sendCodeToPhone" /* 将验证码发送到绑定的手机号码 */)}
                </div>
                <div class={entry.forgetPhone}>
                  {userForm.dialCode} {userForm.phone}
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            title={$t("user_findPassword_changePassword" /* 修改密码 */)}
            name="3"
            titleClass={Number(data.step) > 2 && entry.forgetStepActive}
          >
            <div class={[entry.forget, entry.forgetForm]}>
              <div class={entry.formBoxForm}>
                <Form.Item {...resetAccount.validateInfos.code}>
                  <div class={entry.formBoxFormInput}>
                    <input
                      v-model={resetForm.code}
                      placeholder={$t("user_findPassword_verificationCode" /* 验证码 */)}
                    />
                    {count.value === 0 ? (
                      <div class={entry.loginPhoneInputNumberCode} onClick={getPhoneInfoCode}>
                        {$t("user_login_sendVerificationCode" /* 发送验证码 */)}
                      </div>
                    ) : (
                      <div
                        class={[
                          entry.loginPhoneInputNumberCode,
                          entry.loginPhoneInputNumberCodeSend,
                        ]}
                      >
                        {count.value}s
                      </div>
                    )}
                  </div>
                </Form.Item>
                <Form.Item {...resetAccount.validateInfos.newPassword}>
                  <div class={entry.formBoxFormSelect}>
                    <div class={entry.formBoxFormSelectContent}>
                      <input
                        v-model={resetForm.newPassword}
                        type={data.togglePassword ? "text" : "password"}
                        placeholder={$t("user_findPassword_pleaseInputPassword" /* 请输入密码 */)}
                      />
                    </div>
                    <div
                      class={entry.formBoxFormInputPassword}
                      onClick={() => (data.togglePassword = !data.togglePassword)}
                    >
                      <SvgIcon
                        class={entry.formBoxFormIcon}
                        width="0.48rem"
                        height="0.48rem"
                        name={eye.value}
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item {...resetAccount.validateInfos.confirmPassword}>
                  <div class={entry.formBoxFormSelect}>
                    <div class={entry.formBoxFormSelectContent}>
                      <input
                        v-model={resetForm.confirmPassword}
                        type={data.togglePassword ? "text" : "password"}
                        placeholder={$t(
                          "user_findPassword_pleaseInputUserPassword" /* 请输入确认密码 */
                        )}
                      />
                    </div>
                    <div
                      class={entry.formBoxFormInputPassword}
                      onClick={() => (data.togglePassword = !data.togglePassword)}
                    >
                      <SvgIcon
                        class={entry.formBoxFormIcon}
                        width="0.48rem"
                        height="0.48rem"
                        name={eye.value}
                      />
                    </div>
                  </div>
                </Form.Item>
              </div>
            </div>
          </Tab>
        </Tabs>
        <div>
          <Button onClick={onNext}>
            {data.step === "3"
              ? $t("user_findPassword_sure" /* 确认 */)
              : $t("user_findPassword_nextStep" /* 下一步 */)}
          </Button>
        </div>
        <div class={entry.forgetBottom} onClick={goService}>
          {$t("user_login_contactUs" /* 联系我们 */)}
        </div>
      </div>
    );
  },
});
