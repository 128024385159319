import { computed, defineComponent, PropType } from "vue";
import { Button } from "vant";
import { SvgIcon } from "commom";
import { mangoPopup } from "components";
import style from "./TipModal.module.less";

const Icon = {
  info: "mangoInfo",
  success: "success",
  recharge: "tipsRecharge",
  withdrawal: "tipsWithdrawal",
};

export default defineComponent({
  props: {
    title: {
      type: String as PropType<string>,
      default: "",
    },
    subTitle: {
      type: String as PropType<string>,
      default: "",
    },
    type: {
      type: String as PropType<"info" | "success" | "recharge" | "withdrawal">,
      default: "info",
    },
    confirmText: {
      type: String as PropType<string>,
      default: "",
    },
    cancelText: {
      type: String as PropType<string>,
      default: "",
    },
    confirmCallback: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    cancelCallback: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const icon = computed(() => Icon[props.type]);

    return () => (
      <div class={style.overlayWrap}>
        <div
          class={style.overlayWrapClose}
          onClick={() => {
            mangoPopup().destroyAll();
          }}
        >
          <SvgIcon
            onClick={() => {
              mangoPopup().destroyAll();
            }}
            class={style.overlayContentBottomIcon}
            width="16px"
            height="16px"
            color={"@cms-mango-text-color3"}
            name="close"
          />
        </div>
        <div class={style.overlayContent}>
          <h1>{props.title}</h1>
          <p>{props.subTitle}</p>
          <div class={style.overlayContentBottom}>
            <SvgIcon
              class={style.overlayContentBottomIcon}
              width="148px"
              height="148px"
              name={icon.value}
            />
            {props.confirmText && (
              <Button
                type="primary"
                onClick={() => {
                  mangoPopup().destroyAll();
                  props.confirmCallback();
                }}
              >
                {props.confirmText}
              </Button>
            )}
            {props.cancelText && (
              <Button
                onClick={() => {
                  mangoPopup().destroyAll();
                  props.cancelCallback();
                }}
              >
                {props.cancelText}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  },
});
