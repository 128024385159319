/**
 * 1、获取福利列表
 * 2、获取未领取福利数量
 * 3、领取福利接口
 */
import { computed, onMounted, reactive } from "vue";
import { Api } from "../services";
import { useToast } from "./useToast.hook";
import { IWealData, IWealList } from "@kgsport-cms/service";
import { useUser, useTreasureBox, useWallet } from "core";

interface storeInterface {
  wealList: IWealList[];
  wealCount: number;
  loading: boolean;
  wealType: string[];
  wealStatus: string[];
  wealGroup: IWealData;
}

const store = reactive<storeInterface>({
  wealList: [],
  wealCount: 0,
  loading: false,
  wealType: [
    "mine_welfare_up_get", // 升级奖励
    "mine_welfare_weekend_get", // 周俸禄
    "mine_welfare_month_get", // 月俸禄
    "mine_welfare_birthday_get", // 生日礼金
    "mine_welfare_redpacket_get", // 每月红包
  ],
  wealStatus: [
    "mine_welfare_pending_review", // 待审核
    "mine_welfare_canReceive", // 接口原意为未领取 页面显示可领取
    "mine_welfare_finished", // 已完成
    "mine_welfare_lostEfficacy", // 已失效
  ],
  wealGroup: {},
});

export function useWelfare(isOnMounted: boolean = true) {
  const { toast } = useToast();
  const { platformBalance } = useWallet();
  const { user } = useUser();
  const wealList = computed<IWealList[]>(() => store.wealList);
  const wealCount = computed<number>(() => store.wealCount);
  const wealGroup = computed(() => store.wealGroup);
  // 查询宝箱接口
  const { queryTreasureBoxCommission } = useTreasureBox();
  // 获取福利列表
  const getWealList = async () => {
    try {
      const params = {
        currency: user.value?.currency,
      };
      store.loading = true;
      const { data = [], result } = await Api.UserService.getWealList(params);
      store.loading = false;
      result && (store.wealList = data);
    } catch (e) {
      console.error(e);
    }
  };
  // 获取未领取福利数量
  const getWealCount = async () => {
    try {
      const { data, result } = await Api.UserService.getWealCount();
      result && (store.wealCount = data);
    } catch (e) {
      console.error(e);
    }
  };
  // 领取福利接口
  const receiveAward = async (id: number, type: number) => {
    try {
      const { result } = await Api.UserService.receiveAward({ rewordId: id, type });
      if (result) {
        getWealList();
        getWealCount();
      }
    } catch (e) {
      console.error(e);
    }
  };

  // VIP福利中心列表
  // /api/user/getWealGroup?currency=CNY
  // currency: user.value?.currency
  const getWealGroup = async () => {
    store.loading = true;
    try {
      const {
        data = [],
        result,
        message,
      } = await Api.UserService.getWealGroup({ currency: user.value?.currency as string });
      if (!result) {
        toast.error(message);
        return;
      }
      store.wealGroup = data as IWealData;
    } catch (e) {
      console.log(e);
    } finally {
      store.loading = false;
    }
  };

  // 领取福利接口
  const receiveAwardMango = async (id: number, type: number) => {
    try {
      const { result } = await Api.UserService.receiveAward({ rewordId: id, type });
      if (result) {
        getWealGroup();
        // 宝箱
        if (type === 8) {
          platformBalance();
          queryTreasureBoxCommission();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  onMounted(async () => {
    if (isOnMounted) {
      await Promise.all([getWealList(), getWealCount()]);
    }
  });
  return {
    store,
    wealList,
    wealCount,
    receiveAward,
    getWealGroup,
    wealGroup,
    receiveAwardMango,
  };
}
