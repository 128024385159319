import { defineComponent, onMounted } from "vue";
import detail from "./detail.module.less";
import { toTime, useMessage, useMessageDetail } from "core";
export default defineComponent({
  name: "MangoMessageDetail",
  props: {
    id: {
      type: Number,
      default: -99,
    },
  },
  setup(props) {
    const { state, getMessageInfo } = useMessageDetail();
    const { store } = useMessage();
    onMounted(async () => {
      getMessageInfo(props.id, store.type);
    });
    return () => (
      <div class={detail.detail}>
        <div class={detail.detailTitle}>
          <h2 class={detail.detailText}>{state.info.title || "--"}</h2>
          <p class={detail.detailTime}>{toTime(state.info.beginTime)}</p>
        </div>
        <div class={detail.detailContent} v-html={state.info.content || ""} />
      </div>
    );
  },
});
