/**
 * 兑换码
 */

import { reactive } from "vue";
import { Api } from "../services";
import { useToast } from "./useToast.hook";
import { mangoPopup } from "components";
import { useI18n } from "core";

const store = reactive({
  code: "",
  loading: false,
});

export function usePromotionCode() {
  const { $t } = useI18n();
  const { toast } = useToast();
  const getPromotionCode = async () => {
    if (!store.code) return toast.error($t("common_input_promotion_code" /*请输入礼包兑换码*/));
    if (store.loading) return;
    store.loading = true;
    const { data, result, message } = await Api.PromotionCodeService.getPromotionCode(store.code);
    store.loading = false;
    if (!result) {
      !!message && toast.error(message);
      return;
    }
    toast.success(message || $t("common_operate_success" /**操作成功 */));
    mangoPopup().destroyAll();
    return data;
  };
  return {
    store,
    getPromotionCode,
  };
}
