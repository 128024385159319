import dsbridge from "dsbridge";
import { useUrlSearchParams } from "@vueuse/core";
import { STORE_DOWNLOAD, CMS_IOS_APP_TYPE } from "../enums";
type BridgeParams = Record<string, any> | string | number | null | Array<any>;
const params = useUrlSearchParams<Record<string, any>>("history");
const paramsKeys = Object.keys(params);
// app 套壳影藏h5 下载提示
export function hybridApp() {
  // app 套壳影藏h5 下载提示
  const isHideDownlod =
    ["VestApp", "1"].includes(localStorage.getItem(STORE_DOWNLOAD) || "") ||
    paramsKeys.includes("downlodTip") ||
    paramsKeys.includes("isApp");

  // iOS桌面pwa包
  const casingApp =
    localStorage.getItem(STORE_DOWNLOAD) === "casingApp" || paramsKeys.includes("casingApp");

  // ios 描述文件套壳app
  const descriptionFileApp =
    localStorage.getItem(CMS_IOS_APP_TYPE) === "df" || paramsKeys.includes("df");

  if (isHideDownlod && !localStorage.getItem(STORE_DOWNLOAD)) {
    localStorage.setItem(STORE_DOWNLOAD, "VestApp");
  }
  if (casingApp && !localStorage.getItem(STORE_DOWNLOAD)) {
    localStorage.setItem(STORE_DOWNLOAD, "casingApp");
  }
  if (descriptionFileApp && !localStorage.getItem(CMS_IOS_APP_TYPE)) {
    localStorage.setItem(CMS_IOS_APP_TYPE, "df");
  }
  return {
    isHideDownlod,
    casingApp,
    descriptionFileApp,
  };
}
// 获取套壳包类型，通过地址clientType = ios | android
export function getHybridAppType() {
  return params.clientType;
}

export function isHybridApp() {
  const { isHideDownlod } = hybridApp();
  if (isHideDownlod) return false;
  if (window._dsbridge || window._dswk) return true;
  return false;
}
function DelayPromise(ms: number) {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error("request timeout"));
    }, ms);
  });
}
const ms = 2000;
export function proxyBridge<T = any>(
  name: string,
  params?: BridgeParams,
  callback?: (data: T) => void
): Promise<T> {
  const timeout = DelayPromise(ms);
  const promise = new Promise((resolve, reject) => {
    console.log("bridge:调用名称2", name, params);
    const data = dsbridge.call(name, params, function (res) {
      console.log("bridge:调用原始数据", name, res);
      if (typeof res === "string") {
        try {
          res = JSON.parse(res);
          // eslint-disable-next-line no-empty
        } catch (e) {
          console.log("bridge:调用结果 error", name, e);
          // return reject(e);
        }
      }
      resolve(res);
      callback?.(res);
      console.log("bridge:调用结果", name, res);
    });
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Promise.race<T>([promise, timeout]);
}

// 原生分享
export async function openBridgeShare(params: any) {
  return await proxyBridge("openShare", params);
}

// 关闭页面
export async function closeBridgePage(params: any) {
  return await proxyBridge("closePage", params);
}
// 打开app页面
export async function openBridgeNativePage(params: any) {
  return await proxyBridge("openNativePage", params);
}
// 跳原生登录
export async function openBridgeNativeLoginPage(params: any) {
  return await proxyBridge("jumpToNativeLogin", params);
}

// 获取app信息
export async function getBridgeAppInfo<T = any>(): Promise<T> {
  return await proxyBridge<T>("getAppInfo");
}
// 获取Token
export async function getBridgeToken() {
  return await proxyBridge<string>("getToken");
}

// 获取T用户详情
export async function getBridgeUserInfo<T = any>(): Promise<T> {
  return await proxyBridge<T>("getUserInfo");
}

// 获取T用户详情
export async function openBridgePage<T = any>(data: any): Promise<T> {
  return await proxyBridge<T>("openPage", data);
}

// 获取主题
export async function getBridgeTheme<T = any>(): Promise<T> {
  return await proxyBridge<T>("getCurrentTheme");
}

// 打开外部浏览器
export async function openBridgeBrowser<T = any>(url: any): Promise<T> {
  return await proxyBridge<T>("openBrowser", url);
}
