import { defineComponent, computed, PropType } from "vue";
import { Form, Input, Button } from "ant-design-vue";
import { useLogin, useConfig, useI18n } from "core";
import VerifyAccount from "./verifyAccount.module.less";
interface IfileList {
  key: string;
  label: string;
  modelValue: "qq" | "wechat" | "email" | "phone" | "realName" | "cardNo";
  placeholder: string;
}
interface IverifyData {
  reason: string;
  num: number;
  verify: string;
}
export default defineComponent({
  props: {
    verifyData: {
      type: Object as PropType<IverifyData>,
      default: () => {},
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const { baseForm, loginAccountSubmit } = useLogin();
    const { isPc } = useConfig();
    const fileList: IfileList[] = [
      {
        key: "qq",
        label: "QQ",
        modelValue: "qq",
        placeholder: $t("user_verify_qqTips" /*请输入您注册时填写的QQ*/),
      },
      {
        key: "weixin",
        label: $t("user_verify_wx" /*微信号码*/),
        modelValue: "wechat",
        placeholder: $t("user_verify_wxTips" /*请输入您注册时填写的微信号码*/),
      },
      {
        key: "email",
        label: $t("mine_userinfo_email" /*邮箱*/),
        modelValue: "email",
        placeholder: $t("user_verify_emailTips" /*请输入您注册时填写的邮箱*/),
      },
      {
        key: "phone",
        label: $t("user_verify_phone" /*手机号码*/),
        modelValue: "phone",
        placeholder: $t("user_verify_phoneTips" /*请输入您注册时填写的手机号码*/),
      },
      {
        key: "fullName",
        label: $t("user_register_realName" /*真实姓名*/),
        modelValue: "realName",
        placeholder: $t("user_verify_realNameTips" /*请输入您注册时填写的真实姓名*/),
      },
      {
        key: "cardNo",
        label: $t("funds_bank_cardNum" /*银行卡号*/),
        modelValue: "cardNo",
        placeholder: $t("user_verify_cardTips" /*请输入您注册时填写的银行卡号*/),
      },
    ];
    const FormItemList = computed(() =>
      fileList.filter(
        list =>
          props.verifyData?.verify &&
          JSON.parse(props.verifyData?.verify).find((item: any) => item.key === list.key)
      )
    );
    return () => (
      <div class={[VerifyAccount.verifyAccount, !isPc.value && VerifyAccount.verifyAccountMobile]}>
        <Form class={VerifyAccount.verifyAccountForm}>
          {FormItemList.value.map(item => (
            <Form.Item label={item.label}>
              <Input
                size="middle"
                placeholder={item.placeholder}
                v-model:value={baseForm[item.modelValue]}
              ></Input>
            </Form.Item>
          ))}
          <div class={VerifyAccount.verifyAccountFormTips}>
            {$t(
              "user_verify_userCheck",
              [
                props.verifyData?.reason,
                `${props.verifyData?.num}`,
              ] /*[0]请至少通过[1]项以上的会员资料认证*/
            )}
          </div>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              block
              onClick={() => {
                loginAccountSubmit();
              }}
            >
              {$t("common_complete" /*完成*/)}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  },
});
