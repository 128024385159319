import { defineComponent, PropType } from "vue";
import style from "./style.module.less";
import { WithdrawTabsItem } from "@kgsport-cms/service";

export default defineComponent({
  name: "TabButton",
  emits: ["change"],
  props: {
    data: {
      type: Array as PropType<WithdrawTabsItem[]>,
      default: () => [],
    },
    value: {
      type: String,
      default: "B",
    },
  },
  setup(props, { emit }) {
    const tabTrigger = (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.tagName !== "SECTION") return;
      const {
        dataset: { value },
      } = target;
      emit("change", value);
    };
    return () => (
      <div class={style.tabButton} onClick={tabTrigger}>
        {props.data.map(item => (
          <section
            class={[
              style.btn,
              props.value === item.value && style.btnActive,
              props.data.length == 2 && [style.btn, style.btnless],
              props.data.length == 1 && [style.btn, style.btnone],
            ]}
            data-value={item.value}
          >
            {item.label}
          </section>
        ))}
      </div>
    );
  },
});
