import { defineComponent, onMounted, reactive, watch } from "vue";
import feedback from "./fadeback.module.less";
import { Input } from "ant-design-vue";
import { useFeedback, useI18n } from "core";
import edit from "./asstes/edit.png";
import left from "./asstes/left.png";

export default defineComponent({
  name: "MangoTest",
  setup() {
    const { $t } = useI18n();
    const { state, form, submitFeedback, selectFeedbackType, btnActivated } = useFeedback();
    const data = reactive({
      actionValue: "",
      showType: false,
    });
    const clickBtn = () => {
      if (btnActivated.value) return;
      submitFeedback();
    };
    const handleChange = (option: any) => {
      data.actionValue = option.name;
      selectFeedbackType(option.value);
    };
    watch(
      () => state.actionsTypes,
      () => {
        data.actionValue = state.actionsTypes[0].name;
        selectFeedbackType(state.actionsTypes[0].value);
      }
    );
    onMounted(async () => {});
    return () => (
      <div class={feedback.fb}>
        <div class={feedback.fbHeader}>
          <div class={feedback.fbHeaderL}>
            <img src={edit} />
            <span>{$t("mine_feedback_ideaType" /* 意见类型 */)}</span>
          </div>
          <div class={feedback.fbHeaderR} onClick={() => (data.showType = !data.showType)}>
            <span>{data.actionValue}</span>
            <div class={[feedback.fbHeaderNext, data.showType && feedback.fbHeaderDown]}>
              <img src={left} />
            </div>
          </div>
        </div>
        <div class={feedback.fbTitle}>
          <Input
            placeholder={$t("mine_write_letter_problem_input" /* 请输入标题，10字以内 */)}
            v-model:value={form.title}
            maxlength={100}
          />
        </div>
        <div class={feedback.fbText}>
          <Input.TextArea
            placeholder={$t("mine_write_letter_problem_msg" /* 请输入内容 */)}
            v-model:value={form.userContent}
            showCount
            autosize={{ minRows: 9, maxRows: 9 }}
            maxlength={1000}
          />
        </div>
        <div class={feedback.fbBtn} onClick={() => clickBtn()}>
          {$t("common_submit" /* 提交 */)}
        </div>
        {/*选择虚拟币*/}
        <div class={[feedback.fbSelect, data.showType && feedback.fbSelectShow]}>
          <div class={feedback.fbSelectHeader}>
            <div class={feedback.fbSelectHeaderCancel} onClick={() => (data.showType = false)}>
              {$t("common_cancel" /*取消*/)}
            </div>
            <div class={feedback.fbSelectHeaderTitle}>
              {$t("mine_feedback_chooseIdeaType" /*请选择反馈类型*/)}
            </div>
          </div>
          <div class={feedback.fbSelectCon}>
            {state.actionsTypes.map(item => (
              <div
                class={[
                  feedback.fbSelectConItem,
                  data.actionValue === item.name && feedback.fbSelectConActive,
                ]}
                onClick={() => {
                  handleChange(item);
                  data.showType = false;
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
});
