/**
 * 1、根据类型获取优惠活动列表
 * 2、获取优惠详情
 * 3、申请优惠
 * 4、获取优惠类型
 */

import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
  nextTick,
} from "vue";
import { useLoading, useI18n, matchPath } from "core";
import { Api } from "../services";
import { useToast } from "./useToast.hook";
import {
  ActivityDetaiItem,
  ActivityListItem,
  ActivityTypeItem,
  TurntableDetailType,
  drawTurntableType,
} from "@kgsport-cms/service";
import { useRouter, useRoute } from "vue-router";

export interface ActivityStore {
  typeList: ActivityTypeItem[];
  list: ActivityListItem[];
  popupList: ActivityListItem[];
  detail: ActivityDetaiItem | null;
  applyType: any;
  joinBtnBottom: number;
  typeCode: string;
  turntableDetail: TurntableDetailType | null;
  prizeId: drawTurntableType | null;
  navList: any;
  subNavList: any;
  type: string | number | undefined;
  bindType: string | number;
}

const { toast } = useToast();
const { $t } = useI18n();
const store = reactive<ActivityStore>({
  typeList: [],
  list: [],
  popupList: [],
  detail: null,
  typeCode: "all",
  applyType: {
    1: "common_activity_auto" /*自动参与*/,
    2: "common_activity_manual" /*手动参与*/,
    3: "user_contactUs_actionTitle" /*联系客服*/,
  },
  joinBtnBottom: 50,
  turntableDetail: null,
  prizeId: null,
  navList: [], //活动标题
  subNavList: [], //活动类型列表
  type: 0,
  bindType: 0,
});

export function useActivity() {
  const loadingModel = useLoading();
  const router = useRouter();
  const route = useRoute();
  const typeList = computed<ActivityTypeItem[]>(() => store.typeList);
  const floatTypeList = computed<ActivityTypeItem[]>(() =>
    store.typeList.filter(e => e.floatStatus === 1 && e.typeCode === store.typeCode)
  );
  const turntableDetail = computed<TurntableDetailType | null>(() => store.turntableDetail);
  // 抽奖id
  const prizeId = computed<drawTurntableType | null>(() => store.prizeId);
  const list = computed<ActivityListItem[]>(() => store.list);
  const popupList = computed<ActivityListItem[]>(() => store.popupList);
  const detail = computed<ActivityDetaiItem | null>(() => store.detail);
  const joinBtnBottom = computed(() => store.joinBtnBottom);
  const navList = computed<any[]>(() => store.navList);
  const subNavList = computed<any[]>(() => store.subNavList);
  const bindType = computed(() => store.navList.find((d: any) => d.unId)?.unId ?? "");
  // 是否展示参与活动按钮
  const isJoin = computed(() => detail.value?.activityLobbyId && detail.value?.applyType === 2);
  const loading = ref(false);
  // 获取优惠类型
  const getTypeList = async (bindType?: number | string) => {
    store.typeList = [];
    store.subNavList = [];
    store.list = [];
    const { data, result } = await Api.ActivityService.typeList({ bindType });
    if (result) {
      store.typeList = data || [];
      store.subNavList = data || [];
    }
  };
  // 根据类型获取优惠活动列表
  const getList = async (bindType: number | string, type?: number, isPopup?: number) => {
    try {
      route.path === "/activity" && loadingModel.show();
      store.list = [];
      const params: any = {
        bindType,
      };
      if (isPopup) params.isPopup = isPopup;
      if (type) params.type = type;
      loading.value = true;
      const { data, result } = await Api.ActivityService.list(params);
      loading.value = false;
      loadingModel.hide();
      if (result) {
        if (!isPopup) {
          store.list = data || [];
        }
        if (window.CONFIG.currentModel === "mango" && isPopup) {
          store.popupList = data || [];
        }
      }
    } catch (error) {
      loading.value = false;
      loadingModel.hide();
    } finally {
      loading.value = false;
      loadingModel.hide();
    }
  };
  /**
   * 活动标题-对应一级导航
   */
  const getTitleList = async () => {
    try {
      store.navList = [];
      loading.value = true;
      const { data, result } = await Api.ActivityService.titleList();
      if (result) {
        loading.value = false;
        store.navList = [
          ...[{ typeName: $t("common_all_activity" /*所有活动*/), unId: "", isAll: true }],
          ...(data || []),
        ];
      }
    } catch (error) {
      loading.value = false;
    } finally {
      loading.value = false;
    }
  };
  // 获取优惠详情
  const getDateils = async (activityId: number, lang?: string) => {
    const { data, result } = await Api.ActivityService.details({
      activityId,
      lang,
    });
    result && (store.detail = data);
  };

  // 申请优惠
  const applyActivity = async (activityId?: number | undefined | string) => {
    if (!activityId) return;
    const { result } = await Api.ActivityService.apply({ activityId });
    if (!result) {
      return;
    }
    toast.success($t("common_success" /*成功*/));
  };
  // 活动详情申请方式类型转换
  const changeApplyType = (type: number) => {
    const applyType = {
      "1": "common_activity_auto" /*自动参与*/,
      "2": "common_activity_manual" /*手动参与*/,
      "3": "user_contactUs_actionTitle" /*联系客服*/,
    };
    return applyType[type as never];
  };
  // 获取转盘信息
  const getActivityTurntableDetail = async (id: number) => {
    const { data, result } = await Api.ActivityService.getActivityTurntableDetail({ id });
    if (result) {
      store.turntableDetail = data;
    }
  };
  // 转盘抽奖
  const postDrawTurntable = async (activityLobbyId: number) => {
    const { data, result } = await Api.ActivityService.drawTurntable({ activityLobbyId });
    if (result) {
      console.log(data);
      store.prizeId = data || null;
    }
  };
  const clearDetail = async () => {
    store.detail = null;
  };
  const scrollBottom = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    // 到底的条件
    store.joinBtnBottom = scrollTop + windowHeight > scrollHeight - 340 ? 390 : 50;
  };
  // 活动详情跳转方法
  const jumpActiveDetail = (id: number | string) => {
    router.push({ path: "/activity/detail", query: { id } });
  };
  onBeforeMount(() => {
    window.addEventListener("scroll", scrollBottom);
  });
  onBeforeUnmount(() => {
    window.removeEventListener("scroll", scrollBottom);
  });

  /*watch(
    () => store.subNavList,
    value => {
      if (value.length > 0 && matchPath(route.path, ["/activity", "/"])) {
        console.log(value);
        const result = value[0];
        store.type = result.unId;
        nextTick(async () => {
          await getList(store.bindType, result.unId);
        });
      }
    }
  );*/
  return {
    store,
    loading,
    navList,
    subNavList,
    typeList,
    list,
    popupList,
    isJoin,
    detail,
    joinBtnBottom,
    getList,
    getTypeList,
    floatTypeList,
    jumpActiveDetail,
    changeApplyType,
    applyActivity,
    getDateils,
    clearDetail,
    getActivityTurntableDetail,
    turntableDetail,
    postDrawTurntable,
    prizeId,
    getTitleList,
    bindType,
  };
}
