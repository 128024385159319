import { defineComponent, ref } from "vue";
import { useI18n, useWelfare } from "core";
import style from "./WelfareCenter.module.less";
import Card from "./components/card";
interface TabItem {
  label: string;
  value: number;
}

export default defineComponent({
  props: {
    activityTab: {
      type: Number,
      default: () => 9999,
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const { wealGroup } = useWelfare();
    const tabsList = [
      { label: $t("mine_welfare_up_get" /**升级奖励 */), value: 0 },
      { label: $t("mine_welfare_weekend_get" /**周俸禄 */), value: 1 },
      { label: $t("mine_welfare_month_get" /**月俸禄 */), value: 2 },
      { label: $t("mine_welfare_birthday_get" /**生日礼金 */), value: 3 },
      { label: $t("mine_welfare_redpacket_get" /**每月红包 */), value: 4 },
      { label: $t("mine_event_hall" /**活动大厅 */), value: 5 },
      { label: $t("mine_vip_signIn" /**签到 */), value: 6 },
      { label: $t("mine_mission_rewards" /**任务奖励 */), value: 7 },
      { label: $t("common_treasure_box_record" /**宝箱记录 */), value: 8 },
    ];
    let activeTab = ref(0);
    let trueTabList = ref<TabItem[]>([]);
    const tabClick = (item: TabItem) => {
      activeTab.value = item.value;
    };
    const getIndex = (val: any) => {
      if (val[Number(props.activityTab)]) {
        return Number(props.activityTab);
      } else {
        let dataNum = [];
        for (let i in val) {
          if (val[i]?.length > 0) {
            dataNum.push(i);
          }
        }
        return dataNum?.length ? dataNum[0] : 0;
      }
    };

    const setTabs = (val: any) => {
      let valKeys = Object.keys(val);
      trueTabList.value = tabsList
        .map((item: any) => {
          if (valKeys.includes(item.value.toString())) {
            return item;
          }
        })
        .filter(ite => ite);
      activeTab.value = Number(getIndex(val)) || trueTabList.value[0].value;
    };

    const isStyle2 = ["lam102"].includes(window.CONFIG.name);

    return () => (
      <div class={style.welfare}>
        <div class={style.welfareLeft}>
          {trueTabList.value.map((item: TabItem, index: number) => (
            <div
              class={[
                style.welfareLeftItem,
                activeTab.value === index ? style.welfareLeftActive : "",
                activeTab.value === item.value
                  ? isStyle2
                    ? style.welfareLeftActive2
                    : style.welfareLeftActive
                  : "",
              ]}
              key={index}
              onClick={() => tabClick(item)}
              v-show={wealGroup.value[index]?.length}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div class={style.welfareRight}>
          <Card type={activeTab.value} onSetTabs={setTabs} />
        </div>
      </div>
    );
  },
});
