import {
  merge,
  AgentAuthService,
  AgentHomeService,
  LlevelService,
  AFinanceService,
  AspreadService,
  AgentSystemConfigService,
  MangoAgentService,
  AgentPromotionService,
} from "@kgsport-cms/service";
import { requesterAgent as requesterFn } from "./v2.requester.service";
type ServiceType = {
  AgentAuthService: AgentAuthService;
  AgentHomeService: AgentHomeService;
  LlevelService: LlevelService;
  AFinanceService: AFinanceService;
  AspreadService: AspreadService;
  AgentSystemConfigService: AgentSystemConfigService;
  MangoAgentService: MangoAgentService;
  AgentPromotionService: AgentPromotionService;
};
const Services = {
  AgentAuthService,
  AgentHomeService,
  LlevelService,
  AFinanceService,
  AspreadService,
  AgentSystemConfigService,
  MangoAgentService,
  AgentPromotionService,
};
export const ApiAgent = merge<ServiceType>(Services, {
  requesterFn,
  timeout: 10 * 1000,
});
