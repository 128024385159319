import { computed, defineComponent, Transition } from "vue";
import popup from "./popup.module.less";

import {
  MangoEntry,
  MangoRechargeWithdrawModal,
  MangoUserSetup,
  MangoEntryMobile,
  MangoPersonalInfo,
  MangoModifyLogin,
  MangoWithdrawalPassSettings,
  MangoSecretPhone,
  MangoEmailAddress,
  MangoZaloAddress,
  MangoFundingdetails,
  MangoFundingdetailsDetail,
  MangoBankCardManage,
  MangoBindCard,
  MangoBindUsdt,
  PersonalInfoMobile,
  ZaloAddressMobile,
  WithdrawalPassSettingsMobile,
  MangoSecretPhoneMobile,
  EmailAddressMobile,
  ModifyLoginMobile,
  MangoRechargeH5,
  MangoMessageH5,
  MangoTimeZone,
  BankCardManageMobile,
  MangoFadeback,
  MangoWallet,
  MangoBet,
  MangoBetDetail,
  MangoMessage,
  MangoMessageDetail,
  MangoHelpList,
  MangoHelpDetail,
  MangoTask,
  MangoWelfareCenter,
  MangoMobileTurntable,
  MangoCocosRuleMobile,
  MangoCocosHistoryMobile,
  MangoCocosHistoryWeb,
  MangoCocosRuleWeb,
  MangoTipModalMobile,
  MangoTipModalWeb,
  MangoMobileWithdrawalVerification,
  MangoPromotionCodeMobile,
  MangoPromotionCode,
  MangoCashMessageMobile,
} from "templates/";
import { mangoPopup } from "components";
import { MangoCashMessage } from "../../templates/mango/Web/CashMessage";
interface Icomponents {
  [key: string]: JSX.Element;
}
export default defineComponent({
  props: {
    // 弹窗显示状态
    type: {
      type: String,
      default: "MangoEntry",
    },
    // 主标题
    title: {
      type: String,
      default: "",
    },
    // 是否展示头部标题
    header: {
      type: Boolean,
      default: true,
    },
    // 弹窗状态
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "auto",
    },
    hasBackground: {
      type: Boolean,
      default: true,
    },
    maskClosable: {
      type: Boolean,
      default: false,
    },
    blurredBg: {
      type: Boolean,
      default: true,
    },
    borderRadius: {
      type: String,
      default: "20",
    },
    height: {
      type: String,
      default: "auto",
    },
    // 传入组件的props
    props: {
      type: Object,
      default: null,
    },
    // 关闭时传入的回调函数
    closeCallBack: {
      type: Function,
      default: null,
    },
    // 是否外层滚动
    isOuterRoll: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const components: Icomponents = {
      MangoEntry: <MangoEntry {...props.props} />,
      MangoUserSetup: <MangoUserSetup {...props.props} />,
      MangoPersonalInfo: <MangoPersonalInfo {...props.props} />,
      MangoModifyLogin: <MangoModifyLogin {...props.props} />,
      MangoWithdrawalPassSettings: <MangoWithdrawalPassSettings {...props.props} />,
      MangoSecretPhone: <MangoSecretPhone {...props.props} />,
      MangoEmailAddress: <MangoEmailAddress {...props.props} />,
      MangoZaloAddress: <MangoZaloAddress {...props.props} />,
      MangoFundingdetails: <MangoFundingdetails {...props.props} />,
      MangoFundingdetailsDetail: <MangoFundingdetailsDetail {...props.props} />,
      MangoEntryMobile: <MangoEntryMobile {...props.props} />,
      MangoBankCardManage: <MangoBankCardManage {...props.props} />,
      MangoBindCard: <MangoBindCard {...props.props} />,
      MangoBindUsdt: <MangoBindUsdt {...props.props} />,
      PersonalInfoMobile: <PersonalInfoMobile {...props.props} />,
      ZaloAddressMobile: <ZaloAddressMobile {...props.props} />,
      WithdrawalPassSettingsMobile: <WithdrawalPassSettingsMobile {...props.props} />,
      MangoSecretPhoneMobile: <MangoSecretPhoneMobile {...props.props} />,
      EmailAddressMobile: <EmailAddressMobile {...props.props} />,
      ModifyLoginMobile: <ModifyLoginMobile {...props.props} />,
      MangoRechargeWithdrawModal: <MangoRechargeWithdrawModal {...props.props} />,
      MangoRechargeH5: <MangoRechargeH5 {...props.props} />,
      MangoMessageH5: <MangoMessageH5 {...props.props} />,
      MangoTimeZone: <MangoTimeZone {...props.props} />,
      BankCardManageMobile: <BankCardManageMobile {...props.props} />,
      MangoFadeback: <MangoFadeback {...props.props} />,
      MangoWallet: <MangoWallet {...props.props} />,
      MangoBet: <MangoBet />,
      MangoBetDetail: <MangoBetDetail {...props.props} />,
      MangoMessage: <MangoMessage />,
      MangoMessageDetail: <MangoMessageDetail {...props.props} />,
      MangoHelpList: <MangoHelpList {...props.props} />,
      MangoHelpDetail: <MangoHelpDetail {...props.props} />,
      MangoTask: <MangoTask {...props.props} />,
      MangoWelfareCenter: <MangoWelfareCenter {...props.props} />,
      MangoMobileTurntable: <MangoMobileTurntable {...props.props} />,
      MangoCocosRuleMobile: <MangoCocosRuleMobile {...props.props} />,
      MangoCocosHistoryMobile: <MangoCocosHistoryMobile {...props.props} />,
      MangoCocosHistoryWeb: <MangoCocosHistoryWeb {...props.props} />,
      MangoCocosRuleWeb: <MangoCocosRuleWeb {...props.props} />,
      MangoTipModalMobile: <MangoTipModalMobile {...props.props} />,
      MangoTipModalWeb: <MangoTipModalWeb {...props.props} />,
      MangoMobileWithdrawalVerification: <MangoMobileWithdrawalVerification {...props.props} />,
      MangoPromotionCodeMobile: <MangoPromotionCodeMobile {...props.props} />,
      MangoPromotionCode: <MangoPromotionCode {...props.props} />,
      MangoCashMessage: <MangoCashMessage {...props.props} />,
      MangoCashMessageMobile: <MangoCashMessageMobile {...props.props} />,
    };

    /**
     * 是否显示头部
     */
    const isShowHeader = computed(() => {
      return props.header && props.type !== "MangoEntry";
    });
    return () => (
      <Transition name="model-fade">
        <div class={popup.mangoPopup} v-show={props.show}>
          <div
            class={[popup.mangoPopupShadow, props.blurredBg && popup.mangoPopupShadowBlurred]}
            onClick={() => props.maskClosable && mangoPopup().close(props.closeCallBack)}
          ></div>
          <div
            class={[popup.mangoPopupMain, props.hasBackground && popup.mangoPopupBg]}
            style={{
              width: props.width,
              height: props.height,
              borderRadius: props.borderRadius + "px",
            }}
          >
            {isShowHeader.value ? (
              <div class={popup.mangoPopupHeader}>
                {props.title}
                <div
                  class={popup.mangoPopupHeaderClose}
                  onClick={() => mangoPopup().close(props.closeCallBack)}
                ></div>
              </div>
            ) : null}
            <div
              class={[
                popup.mangoPopupContainer,
                props.isOuterRoll ? "" : popup.mangoPopupContainerNotOuterRoll,
              ]}
              style={{
                paddingTop: isShowHeader.value ? "50px" : "0px",
                maxHeight: isShowHeader.value ? "100vh" : "100vh",
              }}
            >
              {components[props.type]}
            </div>
          </div>
        </div>
      </Transition>
    );
  },
});
