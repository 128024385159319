import { useClipboard } from "@vueuse/core";
const { copy, isSupported, text } = useClipboard();
export function useClipboards() {
  const copyFn = isSupported ? copy : execCopyCommand;
  return {
    copyFn,
    text,
  };
}

export function execCopyCommand(text: string) {
  try {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    // 使text area不在viewport，同时设置不可见
    textArea.style.position = "absolute";
    textArea.style.left = "-100px";
    textArea.style.top = "-100px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise(() => {
      // 执行复制命令并移除文本框
      document.execCommand("copy");
      textArea.remove();
    });
  } catch (e) {
    console.error("Failed to copy-e:", e);
  }
}
