import { reactive, computed } from "vue";
import { Api } from "../services";
import { useAuth, useToast, toTimeNormal, useUser, useWallet, getCurrentTime } from "core";
import {
  ActivityTurntable,
  DrawTurntable,
  TurntableDetailItem,
  TurntablePrize,
} from "@kgsport-cms/service";

interface PathCountItem {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  x3: number;
  y3: number;
  angle: number;
  angleCenter: number;
  w: number;
  h: number;
  pic?: string;
  fill?: string;
}

interface Store {
  recordShow: boolean;
  isScroll: boolean;
  isPausedBg: boolean;
  pathCount: PathCountItem[];
  turntablePrize: TurntablePrize;
  activityTurn: ActivityTurntable;
  drawTurn: DrawTurntable;
  turntableDetailList: TurntableDetailItem[];
  intermediateResult: number;
  angle: number;
  diff: number;
  awardRulesStatus: boolean;
  loading: boolean;
}

const store = reactive<Store>({
  recordShow: false,
  isScroll: false,
  isPausedBg: false,
  pathCount: [] as PathCountItem[],
  turntablePrize: {} as TurntablePrize,
  activityTurn: {} as ActivityTurntable,
  drawTurn: {} as DrawTurntable,
  turntableDetailList: [] as TurntableDetailItem[],
  intermediateResult: 0,
  angle: 0, // 扇形角度
  diff: 0,
  awardRulesStatus: false,
  loading: false,
});
export function useTurntable() {
  const { isLogin } = useAuth();
  const { toast } = useToast();
  const { user } = useUser();
  const { amountFormat } = useWallet();
  const recordShow = computed(() => store.recordShow);
  const isScroll = computed(() => store.isScroll);
  const isPausedBg = computed(() => store.isPausedBg);
  const pathCount = computed(() => store.pathCount);
  const activityTurn = computed(() => store.activityTurn);
  const turntablePrize = computed(() => store.turntablePrize);
  const drawTurn = computed(() => store.drawTurn);
  const turntableDetailList = computed(() => store.turntableDetailList);
  const turntableDetailListMoney = computed(
    () => JSON.parse(JSON.stringify(store.turntableDetailList))?.reverse() as TurntableDetailItem[]
  );
  const turntableDetailListMoneyH5 = computed(
    () =>
      JSON.parse(
        JSON.stringify(store.turntableDetailList.slice(0, 7))
      )?.reverse() as TurntableDetailItem[]
  );
  const intermediateResult = computed(() => store.intermediateResult);
  const awardRulesStatus = computed(() => store.awardRulesStatus);
  const changeRecordShow = (val = false) => {
    store.recordShow = val;
  };
  const changeAwardRules = (status = true) => {
    store.awardRulesStatus = status;
  };
  const changeScroll = (val = false) => {
    store.isScroll = val;
  };
  const setPausedBg = (status: boolean) => {
    store.isPausedBg = status;
  };

  // 中奖结果
  const setIntermediate = (i: number) => {
    store.intermediateResult = -i * store.angle;
  };

  /**
   * 计算扇形
   * @param count 数量
   * @param r 半径
   */
  const setPathCount = (count = 8, r = 115.5) => {
    const x0 = r, // 圆心x
      y0 = r; // 圆心y
    store.angle = 360 / count; // 角度
    !store.intermediateResult && (store.intermediateResult = store.angle / 2);
    let x1 = r * 2, // x起点坐标
      y1 = r; // y起点坐标
    let w = 0,
      h = 0;
    store.pathCount = Array.from(new Array(count)).map((_, i) => {
      const radian = (Math.PI / 180) * ((i + 1) * store.angle); // 弧度
      const x2 = x0 + Math.cos(radian) * r;
      const y2 = y0 + Math.sin(radian) * r;
      const angleCenter = -((i + 1) * store.angle - store.angle / 2);
      const x3 = (x1 + x2) / 2;
      const y3 = (y1 + y2) / 2;
      if (!i) {
        w = r - 24;
        h = y2 - y1;
      }
      const item = {
        x0,
        y0,
        x1,
        y1,
        x2,
        y2,
        x3,
        y3,
        angle: store.angle,
        angleCenter,
        w,
        h,
        pic: store.activityTurn.prizeData?.[i].pic,
      };
      x1 = x2;
      y1 = y2;
      return item;
    });
  };

  // 获取转盘奖品明细
  const getTurntablePrize = async () => {
    try {
      const params = {} as any;
      if (isLogin.value) params.account = user.value?.account ?? "";
      const { data, message, result } = await Api.TurntableService.getTurntablePrize(params);
      if (!result) {
        !!message && toast.error(message);
        return;
      }
      // 未配置数据不继续请求数据
      if (!data?.prizeCount) return;
      if (data.prizeValue && typeof data.prizeValue === "string") {
        data.prizeData = JSON.parse(data.prizeValue);
      }
      store.turntablePrize = data;
      store.angle = 360 / data.prizeCount; // 角度
      !store.intermediateResult && (store.intermediateResult = 0);
    } catch (e) {
      console.log(e);
    }
  };

  // 查询转盘活动
  const getActivityTurntable = async () => {
    try {
      const params = {} as any;
      if (isLogin.value) params.account = user.value?.account ?? "";
      const { data, message, result } = await Api.TurntableService.getActivityTurntable(params);
      if (!result) {
        toast.error(message);
        return;
      }
      setTimeout(() => {
        store.activityTurn = data;
      }, store.diff);
    } catch (e) {
      console.log(e);
    }
  };
  // 转盘抽奖
  const drawTurntable = async () => {
    try {
      const startTime = getCurrentTime();
      const { data, message, result } = await Api.TurntableService.drawTurntable({
        activityLobbyId: store.turntablePrize.activityLobbyId,
      });
      const endTime = getCurrentTime();
      // 转盘旋转时间至少2s
      let diff = endTime - startTime;
      if (diff >= 2000) diff = 0;
      store.diff = diff;
      if (!result) {
        toast.error(message);
        return false;
      }
      store.drawTurn = data;
      Promise.all([getActivityTurntable(), getTurntableDetailList()]).then(() => {});
      return data as DrawTurntable;
    } catch (e) {
      console.log(e);
    }
  };
  // 转盘活动优惠获奖明细
  const getTurntableDetailList = async () => {
    try {
      const {
        data = [],
        message,
        result,
      } = await Api.TurntableService.getTurntableDetailList({
        id: store.turntablePrize.activityLobbyId,
      });
      if (!result) {
        toast.error(message);
        return;
      }
      const arr = data?.map((d: TurntableDetailItem) => {
        !!d.createDatetime &&
          (d.createDatetime = <string>toTimeNormal(d.createDatetime, "HH:mm:ss"));
        !!d.money && (d.money = amountFormat(Number(d.money)));
        return d;
      });
      setTimeout(() => {
        store.turntableDetailList = arr;
      }, store.diff);
    } catch (e) {
      console.log(e);
    }
  };

  const resetTurn = () => {
    store.recordShow = false;
    store.isScroll = false;
    store.intermediateResult = 0;
  };

  return {
    store,
    recordShow,
    changeRecordShow,
    isScroll,
    changeScroll,
    isPausedBg,
    setPausedBg,
    resetTurn,
    intermediateResult,
    setIntermediate,
    pathCount,
    turntablePrize,
    getTurntablePrize,
    activityTurn,
    getActivityTurntable,
    drawTurn,
    drawTurntable,
    turntableDetailList,
    turntableDetailListMoney,
    turntableDetailListMoneyH5,
    getTurntableDetailList,
    awardRulesStatus,
    changeAwardRules,
  };
}
