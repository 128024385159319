import { BigNumber } from "bignumber.js";

export { BigNumber };

export function setBNConfig(options: BigNumber.Config) {
  BigNumber.set(options);
}

// 加法
export function mathAdd(num: number) {
  return new BigNumber(0).plus(num);
}

// 乘法
export function mathMultiplied(
  leftNumber: number,
  righitNumber: number,
  options?: BigNumber.Config
) {
  if (options) {
    setBNConfig(options);
  }

  return new BigNumber(leftNumber).multipliedBy(righitNumber);
}

// 除法
export function mathDiv(leftNumber: number, righitNumber: number, options?: BigNumber.Config) {
  if (options) {
    setBNConfig(options);
  }
  return new BigNumber(leftNumber).dividedBy(righitNumber);
}

// 截取小数位
export function mathToFixed(num: number, decimal: number | null, mode: BigNumber.RoundingMode) {
  if (decimal === null) return new BigNumber(num).toFixed();
  return new BigNumber(num).toFixed(decimal, mode);
}
// 输入框限制
export function getSlice(value: string) {
  value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
  value = value.replace(/^\./g, ""); //验证第一个字符是数字
  value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
  value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  value = value.replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3"); //控制可输入的小数
  value = value.replace(/^0+$/, "0"); // 替换输入 000000 -> 0
  return value;
}
