import { defineComponent } from "vue";
import style from "./style.module.less";
export default defineComponent({
  name: "RadioCircle",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return () => (
      <span class={[style.radioCircle, props.checked && style.radioCircleChecked]}></span>
    );
  },
});
