import { defineComponent } from "vue";
import style from "./style.module.less";

export default defineComponent({
  name: "CSwitch",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    isChange: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:open", "change"],
  setup(props, { emit }) {
    const touchStart = () => {
      if (props.isChange) emit("update:open", !props.open);
      emit("change");
    };
    return () => (
      <div
        class={[style.cSwitch, props.open ? style.cSwitchOpen : style.cSwitchClose]}
        onClick={() => touchStart()}
      >
        <section class={style.cSwitchCircle}></section>
      </div>
    );
  },
});
