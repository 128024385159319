import { defineComponent, onMounted, ref } from "vue";
import message from "./style.module.less";
import { toTimeNormal, useI18n, useMessage, useMessageDetail } from "core";
import { ActionSheet, ActionSheetAction } from "vant";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    id: {
      type: Number,
      default: -99,
    },
  },
  setup(props) {
    const router = useRouter();
    const { removeMsg, store } = useMessage();
    const { state, getMessageInfo } = useMessageDetail();
    const { $t } = useI18n();
    const show = ref(false);

    onMounted(async () => {
      // await getMessageInfo();
      getMessageInfo(props.id, store.type);
    });

    const actions: ActionSheetAction[] = [
      {
        name: $t("common_del" /* 删除 */),
        callback: async () => {
          show.value = false;
          await removeMsg({ idList: String(state.info.messageId) });
          await router.push("/message");
        },
      },
    ];
    return () => (
      <div>
        {/*TODO missing translation*/}
        {/* <MobileHeader
          fiexd
          // 消息详情
          title={$t("mine_message_details" )}
          style={"background-color: @cms-mango-text-color7;box-shadow:unset;"}
        /> */}
        <div class={message.tabInner}>
          <div class={message.detailTitle}>
            <h2 class={message.detailText}>{state.info.title || "--"}</h2>
            <p class={message.detailTime}>{toTimeNormal(state.info.beginTime)}</p>
          </div>
          <div class={message.detailContent} v-html={state.info.content || ""} />
        </div>
        <ActionSheet
          v-model:show={show.value}
          actions={actions}
          teleport="body"
          cancel-text={$t("common_cancel" /**取消 */)}
        />
      </div>
    );
  },
});
