import { defineComponent, computed } from "vue";
import { Popup, Picker } from "vant";
import style from "./duty.module.less";
import popup from "templates/mango/Web//popup.module.less";
import { useI18n, useConfig } from "core";
export default defineComponent({
  props: {
    currency: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 600,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change", "update:show"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { config } = useConfig();
    const index = computed(() => config.dutyList.findIndex(item => item.value === props.currency));

    return () => (
      <Popup
        position="bottom"
        v-model:show={props.show}
        close-on-click-overlay
        onClick-overlay={() => emit("update:show", false)}
        round
        teleport="body"
        class={style.areaCode}
      >
        <div class={style.areaCodeMain} style={{ height: props.height + "px" }}>
          <Picker
            class={popup.picker}
            title={$t("user_register_duty" /*税号*/)}
            columns={config.dutyList}
            columns-field-names={{ text: "name", value: "value" }}
            default-index={index.value !== -1 ? index.value : 0}
            onCancel={() => emit("update:show", false)}
            onConfirm={(v, i) => {
              emit("update:show", false);
              emit("change", v.value);
            }}
            confirm-button-text={$t("common_confirm" /*确定*/)}
            cancel-button-text={$t("common_cancel" /*取消*/)}
          />
        </div>
      </Popup>
    );
  },
});
