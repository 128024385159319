import { InternalRuleItem, Value, RuleItem } from "async-validator";
import {
  passowrd,
  username,
  email,
  phone,
  verificationCode,
  verificationCode3,
  qq,
  wechat,
  cardHolder,
  withdrawPass,
  hiddenPhone,
  nickName,
  cardNo,
  cardNo2,
  duty,
} from "./reg.validator";
import { useI18nFn, useDayjs, isAdult } from "core";

const { dayjs } = useDayjs();
const { $t } = useI18nFn();
/*
 * 函数功能 预留返回国际化的功能 和自定义
 */
// 校验为空
export function requireRule() {
  return {
    required: true,
    message: $t("common_validator_empty" /*输入项不能为空*/),
    trigger: "change",
  };
}
// 校验为空
export function requireArrRule() {
  return [
    { required: true, message: $t("common_validator_empty" /*输入项不能为空*/), trigger: "change" },
  ];
}

// 校验用户名
export function usernameRule(isLogin = false) {
  const rule = [
    {
      required: true,
      message: isLogin
        ? $t("user_login_input_userName" /*请输入用户名*/)
        : $t("user_register_input_userName" /*请输入用户名*/),
      trigger: "change",
    },
    {
      pattern: username,
      message: $t("common_validator_userName_rule" /*用户名由4-20位字符组成*/),
      trigger: "change",
    },
  ];
  return rule;
}

// 校验密码
export function passwordRule(type?: number): RuleItem[] {
  let msg = $t("user_register_input_password" /*请输入密码*/);
  if (type && type === 1) {
    msg = $t("mine_userinfo_pleaseEnterNewPassword") /* 请输入新密码 */;
  } else if (type && type === 2) {
    msg = $t("mine_userinfo_pleaseConfirmNewPass") /* 请确认新密码 */;
  }
  const rule = [
    {
      required: true,
      message: msg,
      trigger: "change",
    },
    {
      pattern: passowrd,
      message: $t("common_validator_password_rule" /*4-20位字符组成*/),
      trigger: "change",
    },
  ];
  return rule;
}
// 提现密码规则（2023/7/20 cj让取消提现密码限制）
export function passwordRule2(): RuleItem[] {
  const rule = [
    {
      required: true,
      message: $t("user_register_input_password" /*请输入密码*/),
      trigger: "change",
    },
  ];
  return rule;
}

// 校验邮箱
export function emailRule() {
  const rule = [
    { required: true, message: $t("user_toast_email" /*请输入邮箱地址*/), trigger: "change" },
    {
      pattern: email,
      message: $t("common_validator_email_rule" /*邮箱格式不正确*/),
      trigger: "blur",
    },
  ];
  return rule;
}
// 校验邮箱 - 非必填
export function emailRule2() {
  const rule = [
    {
      pattern: email,
      message: $t("common_validator_email_rule" /*邮箱格式不正确*/),
      trigger: "blur",
    },
  ];
  return rule;
}

// 校验验证码
export function verificationCodeRule() {
  const rule = [
    {
      required: true,
      message: $t("user_register_input_verifyCode" /*请输入验证码*/),
      trigger: "change",
    },
    {
      pattern: verificationCode,
      message: $t("common_validator_verifyCode_rule" /*请输入正确的6位验证码*/),
      trigger: "change",
    },
  ];
  return rule;
}
// 校验图形验证码
export function verificationCodeRule2() {
  const rule = [
    {
      required: true,
      message: $t("user_register_input_verifyCode" /*请输入验证码*/),
      trigger: "change",
    },
  ];
  return rule;
}
// 校验验证码 数字+字母随机
export function verificationCodeRule3() {
  const rule = [
    {
      required: true,
      message: $t("user_register_input_verifyCode" /*请输入验证码*/),
      trigger: "change",
    },
    {
      pattern: verificationCode3,
      message: $t("common_validator_verifyCode_rule" /*请输入正确的6位验证码*/),
      trigger: "change",
    },
  ];
  return rule;
}
export function verificationCodeRule4() {
  const rule = [
    {
      pattern: verificationCode3,
      message: $t("common_validator_verifyCode_rule" /*请输入正确的6位验证码*/),
      trigger: "change",
    },
  ];
  return rule;
}
// 校验提现密码
export function withdrawPassRule() {
  const rule = [
    {
      required: true,
      message: $t("user_withdraw_pwd_tips" /*请输入提现密码（6位数字）*/),
      trigger: "change",
    },
    {
      pattern: withdrawPass,
      message: $t("user_withdraw_pwd_tips" /*请输入提现密码（6位数字）*/),
      trigger: "change",
    },
  ];
  return rule;
}
export function withdrawPassRule2() {
  const rule = [
    {
      required: true,
      message: $t("funds_withdraw_inputWithdrawPassword" /*请输入提现密码*/),
      trigger: "change",
    },
  ];
  return rule;
}
// 校验手机号号
export function phoneNumberRule() {
  const rule = [
    {
      required: true,
      message: $t("user_login_pleaseInputPhoneNum" /*请输入手机号*/),
      trigger: "change",
    },
    {
      pattern: phone,
      message: $t("common_validator_phone_rule" /*请输入正确的手机号4-20位*/),
      trigger: "change",
    },
  ];
  return rule;
}
// 校验手机号号 - 非必填
export function phoneNumberRule2() {
  const rule = [
    {
      pattern: phone,
      message: $t("common_validator_phone_rule" /*请输入正确的手机号4-20位*/),
      trigger: "change",
    },
  ];
  return rule;
}
//  校验缺省手机号
export function phoneHiddenNumberRule() {
  const rule = [
    {
      required: true,
      message: $t("user_login_pleaseInputPhoneNum" /*请输入手机号*/),
      trigger: "change",
    },
    {
      pattern: hiddenPhone,
      message: $t("common_validator_phone_error" /*手机号不正确*/),
      trigger: "change",
    },
  ];
  return rule;
}

// 校验微信号
export function wechatRule() {
  const rule = [
    { required: true, message: $t("common_validator_wx" /*请输入微信号*/), trigger: "change" },
    {
      pattern: wechat,
      message: $t("common_validator_wx_rule" /*请输入正确的微信号*/),
      trigger: "change",
    },
  ];
  return rule;
}

// 校验qq号
export function qqRule() {
  const rule = [
    { required: true, message: $t("common_validator_qq" /*请输入QQ号*/), trigger: "change" },
    {
      pattern: qq,
      message: $t("common_validator_qq_rule" /*请输入正确的QQ号码*/),
      trigger: "change",
    },
  ];
  return rule;
}

// 校验zalo
export function zaloRule() {
  const rule = [
    { required: true, message: $t("common_validator_zalo" /*请输入zalo地址*/), trigger: "change" },
  ];
  return rule;
}

// 校验用户名和密码是否相同
export function usernamePassword(props: any): any[] {
  const validator = async (rule: InternalRuleItem, value: Value) => {
    // tips: 禅道 https://zentao.wzapp000.com/zentao/bug-view-19890.html 去掉
    // if (value === props.username) {
    //   return Promise.reject("用户名和密码不能相同");
    // }
    return Promise.resolve();
  };
  const rule = [
    {
      validator,
    },
  ];
  return rule;
}
// 校验两次密码是否相同
export function confirmPasswordRule(props: any, asFiled?: string) {
  const validator = (rule: InternalRuleItem, value: Value) => {
    const filed = asFiled ? props[asFiled] : props.password;

    if (value !== filed) {
      return Promise.reject($t("common_validator_password_two" /*两次密码输入不一致*/));
    }
    return Promise.resolve();
  };
  const rule = [
    {
      validator,
    },
  ];
  return rule;
}
export function withdrawPassRuleCustom(props: any, asFiled?: string) {
  const validator = (rule: InternalRuleItem, value: Value) => {
    if (!value) {
      return Promise.reject($t("user_withdraw_pwd_tips" /*请输入提现密码（6位数字）*/));
    }
    if (!withdrawPass.test(value)) {
      return Promise.reject($t("user_withdraw_pwd_tips" /*请输入提现密码（6位数字）*/));
    }
    const filed = asFiled ? props[asFiled] : props.password;
    if (value !== filed) {
      return Promise.reject($t("common_validator_password_two" /*两次密码输入不一致*/));
    }
    return Promise.resolve();
  };
  const rule = [
    {
      validator,
    },
  ];
  return rule;
}
export function withdrawPassRuleCustom2(props: any, asFiled?: string) {
  const validator = (rule: InternalRuleItem, value: Value) => {
    if (!value) {
      return Promise.reject($t("user_withdraw_pwd_again_tips" /*请再次输入提现密码*/));
    }
    const filed = asFiled ? props[asFiled] : props.password;
    if (value !== filed) {
      return Promise.reject($t("common_validator_password_two" /*两次密码输入不一致*/));
    }
    return Promise.resolve();
  };
  const rule = [
    {
      validator,
    },
  ];
  return rule;
}
// 新旧密码校验
export function confirmNewAndOldPasswordRule(props: any, asFiled?: string) {
  const validator = (rule: InternalRuleItem, value: Value) => {
    const filed = asFiled ? props[asFiled] : props.password;
    if (value === filed) {
      return Promise.reject($t("common_validator_password_newOld" /*新密码和旧密码不能相同！*/));
    }
    return Promise.resolve();
  };
  const rule = [
    {
      validator,
    },
  ];
  return rule;
}

// 校验绑定银行卡
export function cardHolderRule() {
  const rule = [
    {
      required: true,
      message: $t("common_validator_cardName" /*请输入的持卡人姓名*/),
      trigger: "change",
    },
    {
      pattern: cardHolder,
      message: $t("common_validator_cardName_error" /*持卡人姓名有误*/),
      trigger: "change",
    },
  ];
  return rule;
}
// 银行卡号
export function cardNoRule() {
  const rule = [
    { required: true, message: $t("funds_bank_input_tips" /*请输入银行卡号*/), trigger: "change" },
    // {
    //   pattern: cardNo,
    //   message: $t("common_validator_bank_rule" /*银行卡号长度为16-19位*/),
    //   trigger: "change",
    // },
    {
      // pattern: ["lam105"].includes(window.CONFIG?.name) ? cardNo2 : cardNo,
      pattern: cardNo,
      message: $t("common_validator_bankRuleNumOnly" /*银行卡号只能输入数字*/),
      trigger: "change",
    },
  ];
  if (window.CONFIG.currentModel === "mango") {
    rule.pop();
  }
  return rule;
}

// Lam105银行卡规则
export function cardNoLam105Rule(key?: string) {
  let rule: any[] = [{ required: true, message: $t("common_input" /*请输入*/), trigger: "blur" }];
  switch (key) {
    case "CPF":
    case "CNPJ":
      rule.push({
        pattern: /^\d{11}$/,
        message: $t("common_validator_cpfRule" /*请输入税号*/),
        trigger: "blur",
      });
      break;
    case "PHONE":
      rule.push({
        pattern: /^(\+55\d)?\d{10}$/,
        message: $t("user_login_pleaseInputPhoneNum" /*请输入手机号*/),
        trigger: "blur",
      });
      break;
    case "EMAIL":
      rule.push({
        pattern: email,
        message: $t("user_toast_email" /*请输入邮箱地址*/),
        trigger: "blur",
      });
      break;
    case "EVP":
      rule.push({
        pattern: /^[A-Za-z0-9\-]+$/,
        message: $t("common_validator_evpRule" /*请输入EVP*/),
        trigger: "blur",
      });
      break;
  }
  return rule;
}

// mango平台银行限制
export function cardNoMangoRule(key?: string) {
  let rule: any[] = [{ required: true, message: $t("common_input" /*请输入*/), trigger: "blur" }];
  switch (key) {
    case "CPF":
      rule.push({
        pattern: /^\d{11}$/,
        message: $t("common_validator_cpfRule" /*请输入税号*/),
        trigger: "blur",
      });
      break;

    case "CNPJ":
      rule.push({
        pattern: /^((\d{2}\.\d{3}\.\d{3})|(\d{4}-\d{2}))$/,
        message: $t("common_validator_cpfRule" /*请输入税号*/),
        trigger: "blur",
      });
      break;

    case "PHONE":
      rule.push({
        pattern: /^((\d{11})|(\d{8}))$/,
        message: $t("user_login_pleaseInputPhoneNum" /*请输入手机号*/),
        trigger: "blur",
      });
      break;
    case "EMAIL":
      rule.push({
        pattern: email,
        message: $t("user_toast_email" /*请输入邮箱地址*/),
        trigger: "blur",
      });
      break;
    case "EVP":
      rule.push({
        pattern: /^[A-Za-z0-9]{34,50}$/,
        message: $t("common_validator_evpRule" /*请输入EVP*/),
        trigger: "blur",
      });
      break;
  }
  return rule;
}

// 选择银行卡
export function bankNameRule() {
  const rule = [
    { required: true, message: $t("common_validator_bank" /*请选择银行卡*/), trigger: "change" },
  ];
  return rule;
}
// 选择银行卡
export function bankNameRule2() {
  const rule = [
    {
      required: true,
      message: $t("common_validator_bankName" /*请选择开户行*/),
      trigger: "change",
    },
  ];
  return rule;
}
// 开户行地址
export function addressRule() {
  const rule = [
    { required: true, message: $t("funds_bank_open_tips" /*请输入开户行地址*/), trigger: "change" },
  ];
  return rule;
}
export function bankValueRule() {
  const rule = [
    { required: true, message: $t("common_validator_bank" /*请选择银行*/), trigger: "change" },
  ];
  return rule;
}

// 检验绑定虚拟账户账户
export function aliasRule() {
  const rule = [
    {
      required: true,
      message: $t("common_validator_virtualCoinName" /*请输入虚拟币账户别名*/),
      trigger: "change",
    },
  ];
  return rule;
}

// 虚拟币账户
export function usdtCardNoRule() {
  const rule = [
    {
      required: true,
      message: $t("common_validator_virtualCoin" /*请输入虚拟币账户*/),
      trigger: "change",
    },
  ];
  return rule;
}

// 昵称 要求加验证规则 kiki
export function nicknameRule() {
  const rule = [
    { required: true, message: $t("common_validator_name" /*昵称不能为空*/), trigger: "change" },
    {
      pattern: nickName,
      message: $t("common_validator_name_rule" /*昵称由4-16个汉字、字母与数字的任意组合组成*/),
      trigger: "change",
    },
  ];
  return rule;
}

// 生日验证
export function birthdayRule(props: any) {
  const validator = () => {
    if (!props.birthday) {
      return Promise.reject($t("common_validator_empty" /*输入项不能为空*/));
    }
    if (isAdult(dayjs(props.birthday).valueOf())) {
      return Promise.reject($t("mine_userinfo_birthday18" /*生日不能设置小于18周岁！*/));
    }
    return Promise.resolve();
  };
  const rule = [
    {
      validator,
    },
  ];
  return rule;
}

export function captchaRule() {
  return [
    {
      required: true,
      message: $t("funds_recharge_inputVerificationCode" /**请输入图形验证码 */),
      trigger: "change",
    },
  ];
}

export function dutyRule() {
  return [
    {
      required: true,
      message: $t("user_register_inputDutyCode" /**请输入税号 */),
      trigger: "change",
    },
    {
      pattern: duty,
      message: $t("common_validator_duty_rule" /*请输入10-30位字符*/),
      trigger: "change",
    },
  ];
}
