import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  computed,
  watch,
  getCurrentInstance,
} from "vue";
import { useRoute } from "vue-router";
import { useAsyncQueue } from "@vueuse/core";
import {
  useModal,
  destroyModalAll,
  useIndexPopup,
  isMobile,
  useActivity,
  useConfigContext,
  STORE_LINE_POPUP,
  STORE_ACTIVITY_POPUP,
  STORE_PERSONAL_POPUP,
  useEvent,
  matchPath,
  useI18n,
  useLocalStorage,
} from "core";
import style from "./IndexPopup.module.less";
import Popup from "./Popup";

export default defineComponent({
  setup() {
    const { $t } = useI18n();
    const route = useRoute();
    const appContext = getCurrentInstance()?.appContext;
    // 控制弹窗显示的路由地址
    const showPopup = computed(() => matchPath(route.path, ["/"]));
    const { onToken, onLogin } = useEvent();
    const { config } = useConfigContext();
    const { personList, getPersonList } = useIndexPopup();
    const { getList, getTitleList, popupList, bindType } = useActivity();
    const { localStore } = useLocalStorage();
    // const linePopup = async () => {
    //   return new Promise<void>(resolve => {
    //     if (!config.linePopup || localStorage[STORE_LINE_POPUP]) return resolve();
    //     const lineModal = useModal({
    //       onClose: () => {
    //         localStorage.setItem(STORE_LINE_POPUP, Date.now() + "");
    //         lineModal.close();
    //         resolve();
    //       },
    //       appContext,
    //       header: true,
    //       title: $t("message_toast_noOpen", [sitename.value] /*[0]或APP打不开，怎么办*/),
    //       position: "center",
    //       bodyClass: isMobile ? style.model : "",
    //       content: () => <LinePopup />,
    //     });
    //     lineModal.open();
    //   });
    // };
    const activityPopup = async () => {
      return new Promise<void>(resolve => {
        // if (isMobile) {
        //   return resolve();
        // }
        if (
          !config.activityPopup ||
          localStore.get(STORE_ACTIVITY_POPUP) > 0 ||
          !popupList.value.length
        ) {
          return resolve();
        }

        const activityNoticeModal = useModal({
          header: false,
          position: isMobile ? "top" : "center",
          title: $t("message_notice_title" /*活动公告*/),
          appContext,
          closable: false,
          onClose: () => {
            activityNoticeModal.close();
            resolve();
          },
          bodyClass: isMobile ? style.model : style.pcmodel,
          content: () => (
            <Popup
              type="activity"
              data={popupList.value?.filter(d => !!d.popupPic)}
              handClose={() => {
                activityNoticeModal.close();
                resolve();
              }}
            />
          ),
        });
        activityNoticeModal.open();
      });
    };
    const personalPopup = async () => {
      return new Promise<void>(resolve => {
        if (isMobile) {
          config.activityPopup;
        }

        if (
          !config.personalPopup ||
          localStore.get(STORE_PERSONAL_POPUP) > 0 ||
          !personList.value.length
        )
          return resolve();

        const selfNoticeModal = useModal({
          onClose: () => {
            selfNoticeModal.close();
            resolve();
          },
          closable: false,
          appContext,
          header: false,
          title: $t("message_personal_title" /*个人消息*/),
          position: isMobile ? "top" : "center",
          bodyClass: isMobile ? style.model : style.pcmodel,
          content: () => (
            <div>
              <Popup
                data={personList.value}
                handClose={() => {
                  selfNoticeModal.close();
                  resolve();
                }}
              />
            </div>
          ),
        });
        selfNoticeModal.open();
      });
    };

    const popupInit = async () => {
      try {
        if (!showPopup.value) return;
        await Promise.all([getList("", 0, 1), getPersonList()]);
        useAsyncQueue([personalPopup, activityPopup]);
      } catch (error) {
        console.error("IndexPopup init error:", error);
      }
    };
    onToken(() => {
      [STORE_LINE_POPUP, STORE_ACTIVITY_POPUP, STORE_PERSONAL_POPUP].forEach(item => {
        localStore.remove(item);
      });
    });
    onLogin(() => {
      [STORE_LINE_POPUP, STORE_ACTIVITY_POPUP, STORE_PERSONAL_POPUP].forEach(item => {
        localStore.remove(item);
      });
      setTimeout(popupInit, 500);
    });
    onMounted(async () => {
      if (showPopup.value) {
        await getTitleList();
      }
      await popupInit();
    });
    onBeforeUnmount(() => {
      destroyModalAll();
    });
    watch(
      () => route.path,
      () => {
        if (!showPopup.value) {
          destroyModalAll();
        }
      }
    );
    return () => null;
  },
});
