import { ref, watch, unref, computed } from "vue";
import { useEventListener } from "@vueuse/core";
import { isIos } from "core";

type Fn = () => void;

const lockElementArray: HTMLElement[] = [];

function preventDefault(rawEvent: TouchEvent) {
  const e = rawEvent || window.event;
  if (e.touches.length > 1) return true;
  if (e.preventDefault) e.preventDefault();
  return false;
}

export function useScrollLock(element: HTMLElement, orgOverflow = "") {
  const setLockElementArray = (flag?: boolean) => {
    if (flag) {
      lockElementArray.pop();
      return;
    }
    lockElementArray.push(element);
  };
  const getLockElementLength = () => {
    return lockElementArray.length;
  };

  const isLocked = ref(false);
  let touchMoveListener: Fn | null = null;
  let initialOverflow = "";
  watch(
    () => unref(element),
    el => {
      if (el) {
        const ele = el;
        initialOverflow = orgOverflow;
        if (isLocked.value) ele.style.overflow = "hidden";
        else ele.style.overflow = "";
      }
    },
    {
      immediate: true,
    }
  );
  const lock = () => {
    const ele = unref(element);
    if (!ele || isLocked.value) return;
    if (isIos) {
      console.log(isIos, "isIos");
      // touchMoveListener = useEventListener(document, "touchmove", preventDefault, {
      //   passive: false,
      // });
    }

    setLockElementArray();
    ele.style.overflow = "hidden";
    isLocked.value = true;
  };
  const unlock = () => {
    const ele = unref(element);
    if (!ele || !isLocked.value) return;

    isIos && (touchMoveListener == null ? void 0 : touchMoveListener());

    setLockElementArray(true);
    const count = getLockElementLength();
    if (count <= 0) {
      ele.style.overflow = initialOverflow;
    }

    isLocked.value = false;
  };
  return computed({
    get() {
      return isLocked.value;
    },
    set(v) {
      if (v) {
        lock();
      } else {
        unlock();
      }
    },
  });
}
