import { defineComponent } from "vue";
import { Form, Input } from "ant-design-vue";
import { Button } from "vant";
import { useI18n, useUser } from "core";
import { TipsIcon } from "./assets";
import style from "./withdrawalpasssettings.module.less";

export default defineComponent({
  props: {
    callback: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { withdrawAccount, withdrawForm, reqeustLoading, editWithdrawSubmit } = useUser();

    return () => (
      <div class={style.formWrap}>
        <div class={style.line}>
          <img src={TipsIcon} alt="Tips" />
          <span>{$t("funds_withdraw_setPasswordTip" /*提现密码设置后不可自行修改，请牢记*/)}</span>
        </div>
        <Form>
          <Form.Item {...withdrawAccount.validateInfos.withdrawPassword} validateFirst={true}>
            <Input.Password
              placeholder={$t("funds_withdraw_password" /* 提现密码 */)}
              type="password"
              v-model:value={withdrawForm.withdrawPassword}
            />
          </Form.Item>
          <Form.Item
            {...withdrawAccount.validateInfos.confirmWithdrawPassword}
            validateFirst={true}
          >
            <Input.Password
              placeholder={$t("user_withdraw_pwd_again_tips" /*请再次输入提现密码*/)}
              type="password"
              v-model:value={withdrawForm.confirmWithdrawPassword}
            />
          </Form.Item>
          <div class={style.formWrapBtns}>
            <Button
              onClick={async () => {
                await editWithdrawSubmit();
                props.callback && props.callback();
              }}
              loading={reqeustLoading.value}
            >
              {$t("mine_userinfo_confirmSubmission" /*确认提交*/)}
            </Button>
          </div>
        </Form>
      </div>
    );
  },
});
