import type { App } from "vue";
import Lazyload from "commom/Lazyload";
import { QRcode, Image, LayoutContainer } from "commom";
const components = {
  Image,
  QRcode,
  LayoutContainer,
};
export const install = function (app: App) {
  app.directive("lazy", Lazyload);
  Object.keys(components).forEach(key => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const component = components[key];
    app.component(key, component);
  });
  return app;
};
export default { install };
