export function randomString(e: number) {
  e = e || 32;
  const t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length;
  let n = "";
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
}

export function uuid(len = 32, radix = 16) {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid = [];
  let i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;

    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join("");
}

export function makeRandomId(length: number) {
  //crypto 生成随机数
  if ("Crypto" in window) {
    return Array.prototype.map
      .call(window.crypto.getRandomValues(new Uint8Array(32)), item => item.toString(32))
      .join("")
      .slice(length);
  }
  return Math.random().toString(36).slice(length);
}

export function urlEncode(param: any, key?: string, encode?: string) {
  if (param == null) return "";
  let paramStr = "";
  const t = typeof param;
  if (t == "string" || t == "number" || t == "boolean") {
    paramStr += "&" + key + "=" + (encode == null || encode ? encodeURIComponent(param) : param);
  } else {
    for (const i in param) {
      const k = key == null ? i : key + (param instanceof Array ? "[" + i + "]" : "." + i);
      paramStr += urlEncode(param[i], k, encode);
    }
  }
  return paramStr;
}

const KEY = "ACCESS_UUID";

export const generateUUID = () => {
  const uid = randomString(32);
  localStorage.setItem(KEY, uid);
  return uid;
};

export const setUUID = (uuid: string) => localStorage.setItem(KEY, uuid);

export const hasUUID = () => {};

export const getUUID = () => {
  const uuid = localStorage.getItem(KEY);
  if (!uuid) {
    return generateUUID();
  }
  return uuid;
};

// 生成6位随机数
type Fn = (length?: number, chars?: string) => string;
const generateUuid: Fn = (
  length = 8,
  chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
) => {
  let result = "";
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};
export const generateRandomNum = () =>
  `${generateUuid(1, "ABCDEFGHIJKLMNOPQRSTUVWXYZ")}${generateUuid(5)}`;

export const getUserAgent = () => {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return "ios";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    return "android";
  } else if (/(Windows Phone)/i.test(navigator.userAgent)) {
    return "windowsPhone";
  }
};
