import { defineComponent, onMounted, reactive, watch, ref } from "vue";
import { Icon } from "vant";
import { NoticesItem } from "@kgsport-cms/service";

import { useI18n, useMessage, useNotice } from "core";
import { MobilePageList, SvgIcon } from "commom";
import message from "./style.module.less";
type PageListRefType = {
  refreshList: () => void;
  resetStore: () => void;
};
import RadioCircle from "./components/RadioCircle";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: () => "discount",
    },
    editMode: {
      type: Boolean,
    },
  },
  emits: ["gotoDetail"],
  setup(props, { emit }) {
    const { store, removeMsg, handleRead, getMessageList, pathToDetail } = useMessage();
    const { noticeStore, getNotice } = useNotice();
    const { $t } = useI18n();
    const mobilePageListRefs = ref<PageListRefType | null>(null);
    const data = reactive({
      selectAll: false,
    });
    const selectOne = (id: number) => {
      if (store.ids.includes(id)) {
        delete store.ids[store.ids.indexOf(id)];
      } else {
        store.ids.push(id);
      }
    };
    const hasRead = () => {
      if (data.selectAll) {
        data.selectAll = !data.selectAll;
      }
      handleRead();
    };
    // 根据类型切换API
    // KG - CJ（Dubai），15: 21
    // 优惠通知暂时不用管;
    // 就暂时无消息那个就好;
    const activeApi = (value: string) => {
      const api = { getNotice, getMessageList };
      if (value === "discount") {
        return api["getNotice"];
      } else if (value === "notice") {
        return api["getNotice"];
      } else if (value === "message") {
        return api["getMessageList"];
      }
    };

    // 根据类型切换数据列表
    const activeList = (value: string) => {
      const list = {
        discount: () => [],
        notice: () => noticeStore.records || [],
        message: () => store.messageList || [],
      };
      return list[value]() as NoticesItem[];
    };

    // 根据类型切换数据列表
    const activeStore = (value: string): any => {
      if (value === "discount") {
        return store;
      } else if (value === "notice") {
        return noticeStore;
      } else if (value === "message") {
        return store;
      }
    };

    const listIcon = (type: string) => {
      switch (type) {
        case "discount":
          return "messageActivity";
        case "notice":
          return "messageInboxNotice";
        case "message":
          return "messageInboxNotice";
        default:
          return "messageNotice";
      }
    };

    // watch if select all
    watch(
      () => data.selectAll,
      v => {
        store.ids = v ? store.messageList.map(v => v.messageId) : [];
      }
    );

    onMounted(() => {
      mobilePageListRefs.value?.refreshList();
    });

    const format = (str: string | null | undefined): string => {
      if (!str || typeof str !== "string") return "";
      const res = str.replace(/<([^(<>)]){0,2}>/g, "");
      return res.startsWith("<img ")
        ? `[${$t("common_pic" /*图片*/)}]`
        : str.replace(/<[^>]+>/g, "");
    };
    const toDetails = (item: any) => {
      emit("gotoDetail", {
        id: item.id ?? item.messageId,
        type: props.type,
        readStatus: item?.readStatus,
      });
      pathToDetail({
        id: String(item.id ?? item.messageId),
        type: String(props.type),
        readStatus: String(item?.readStatus),
      });
    };
    return () => [
      <div class={message.list}>
        <MobilePageList
          requestApi={activeApi(props.type)}
          ref={mobilePageListRefs}
          list={activeList(props.type)}
          total={activeStore(props.type).total}
          pages={activeStore(props.type).pages}
          v-model:current={activeStore(props.type).current}
          empty="mangoNoData"
        >
          {activeList(props.type)?.map(item => (
            <div
              class={message.listItem}
              // onClick={() =>
              // pathToDetail({
              //   id: String(item.id ?? item.messageId),
              //   type: String(props.type),
              //   readStatus: String(item?.readStatus),
              // })
              // }
              onClick={() => {
                toDetails(item);
              }}
            >
              {props.editMode ? (
                <RadioCircle
                  checked={store.ids.includes(item.messageId)}
                  onClick={e => {
                    e.stopPropagation();
                    selectOne(item.messageId);
                  }}
                />
              ) : null}
              <SvgIcon
                class={[
                  message.listItemIcon,
                  props.type === "message" && item.readStatus !== 1 && message.listItemIconUnread,
                ]}
                name={listIcon(props.type)}
              />
              <div class={message.listItemContent}>
                <p class={message.listItemContentTitle}>{item.title || format(item.content)}</p>
                <p class={message.listItemContentSub}>{format(item.content) || " "}</p>
                {/* <p class={message.listItemContentDate}>{toTime(item.createTime) || " "}</p> */}
              </div>
              <div class={message.listItemArrow}>
                {!props.editMode ? (
                  <Icon name="arrow" color="var(--cms-mango-text-color5)" />
                ) : null}
              </div>
            </div>
          ))}
        </MobilePageList>
      </div>,
      <div class={[message.toolbar, message.toolbarShow]}>
        {props.editMode ? (
          <>
            <div class={message.toolbarCheck} onClick={() => (data.selectAll = !data.selectAll)}>
              <RadioCircle checked={data.selectAll} />
              {$t("message_edit_selectAll" /* 全选 */)}
            </div>
            <div class={message.toolbarAction}>
              <div class={message.toolbarActionItem} onClick={hasRead}>
                {/* <i class={[message.toolbarActionIcon, message.toolbarActionIconRead]} /> */}
                {$t("message_edit_read" /* 已读 */)}
              </div>
              <div
                class={[message.toolbarActionItem, message.toolbarActionItemDelete]}
                onClick={() => removeMsg()}
              >
                {/* <i class={[message.toolbarActionIcon, message.toolbarActionIconDelete]} /> */}
                {$t("common_del" /* 删除 */)}
              </div>
            </div>
          </>
        ) : null}
      </div>,
    ];
  },
});
