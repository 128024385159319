import { defineComponent, onMounted, reactive, computed, ref, watch, onBeforeUnmount } from "vue";
import { Button, Popup } from "vant";
import entry from "../style.module.less";
import ThirdLogin from "../components/ThirdLogin";
import Forget from "../Forget/Forget";
import { useConfigContext, useI18n, useLogin, useUser, useConfig, useCommon } from "core";
import { Form } from "ant-design-vue";
import { SvgIcon } from "commom";
import PhoneLogin from "../components/PhoneLogin";

export default defineComponent({
  emits: ["changeType"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { config } = useConfig();
    const {
      baseForm,
      account,
      phone,
      loading,
      check,
      openCaptcha,
      setPhoneForm,
      changeVerificationCodeRule,
      getCaptchaCode,
    } = useLogin();
    const { captchaImg, captchaCode } = useCommon();
    const { phoneForm, sendCodeSubmit, count } = useUser();
    const { config: global } = useConfigContext();
    const data = reactive({
      active: "account",
      togglePassword: false,
      toggleAreaCode: false,
      isMounted: false,
    });
    const forgetPassPopup = ref(false);
    const cancelForgetPass = () => {
      forgetPassPopup.value = false;
    };
    const eye = computed(() => (data.togglePassword ? "eyeShow" : "eyeHide"));
    onMounted(() => {
      data.isMounted = true;
      window.CONFIG.isShowImgCode && getCaptchaCode();
    });
    // 是否输入了内容，没有输入就不能查看密码
    const passwordInput = ref(false);
    const passwordChange = (e: Event) => {
      passwordInput.value = "data" in e;
    };

    // 手机注册开启时，手机登录界面默认显示发送短信的界面
    if (config.mobileRegistryInfo?.whetherOpen) changeVerificationCodeRule("strnum");
    // 兼容华为手机输入框导致界面挤压问题
    const setBodyHeight = () => {
      document.getElementsByTagName("body")[0].style.height = window.innerHeight + "px";
    };
    const resetBodyHeight = () => {
      document.getElementsByTagName("body")[0].style.height = "100%";
    };
    watch(
      () => data.active,
      n => {
        if (n === "account") {
          phoneForm.smsCode = "";
        }
      },
      { immediate: true }
    );
    onBeforeUnmount(async () => {
      resetBodyHeight();
    });
    return () => (
      <div
        class={[
          entry.formBox,
          data.isMounted && entry.formBoxShow,
          loading.value && entry.formBoxDisbaled,
        ]}
      >
        {/* {global.smsSwitch && (
          <Tabs class={entry.login} v-model:active={data.active} shrink>
            <Tab title={$t("user_login_accountLogin" 账号密码登录)} name="account" />
            <Tab title={$t("user_login_phoneLogin"  手机号登录)} name="phone" />
          </Tabs>
        )} */}
        {data.active === "account" && (
          <>
            <div class={entry.loginContent}>
              <Form class={entry.formBoxForm}>
                <div class={entry.formBoxFormSelect}>
                  <SvgIcon
                    class={entry.formBoxFormIcon}
                    width="0.48rem"
                    height="0.48rem"
                    name="user"
                  />
                  <div class={entry.formBoxFormSelectContent}>
                    <Form.Item validateFirst={true} {...account.validateInfos.username}>
                      <input
                        v-model={baseForm.username}
                        onClick={() => setBodyHeight()}
                        onBlur={() => resetBodyHeight()}
                        placeholder={$t("user_login_pleaseInputUserName") /*请输入用户名*/}
                      />
                    </Form.Item>
                  </div>
                  {!!baseForm.username && (
                    <div
                      class={entry.formBoxFormInputPassword}
                      onClick={() => (baseForm.username = "")}
                    >
                      <SvgIcon
                        class={entry.formBoxFormIcon}
                        width="0.4rem"
                        height="0.4rem"
                        name="close"
                      />
                    </div>
                  )}
                </div>
                <div class={entry.formBoxFormSelect}>
                  <SvgIcon
                    class={entry.formBoxFormIcon}
                    width="0.3rem"
                    height="0.3rem"
                    name="lock"
                    style="margin-right: 0.1rem"
                  />
                  <div class={entry.formBoxFormSelectContent}>
                    <Form.Item validateFirst={true} {...account.validateInfos.password}>
                      <input
                        v-model={baseForm.password}
                        onClick={() => setBodyHeight()}
                        onBlur={() => resetBodyHeight()}
                        placeholder={$t("user_findPassword_pleaseInputPassword" /**请输入密码 */)}
                        type={data.togglePassword && passwordInput.value ? "text" : "password"}
                        onInput={passwordChange}
                      />
                    </Form.Item>
                  </div>
                  <div
                    class={entry.formBoxFormInputPassword}
                    onClick={() => (data.togglePassword = !data.togglePassword)}
                  >
                    <SvgIcon
                      class={entry.formBoxFormIcon}
                      width="0.48rem"
                      height="0.48rem"
                      name={eye.value}
                    />
                  </div>
                </div>
                {window.CONFIG.isShowImgCode && (
                  <div class={entry.formBoxFormSelect}>
                    <SvgIcon
                      class={entry.formBoxFormIcon}
                      width="0.4rem"
                      height="0.4rem"
                      name="vertifyCode"
                      style="margin-right: 0.1rem"
                    />
                    <div class={entry.formBoxFormSelectContent}>
                      <Form.Item name="code" {...account.validateInfos.code}>
                        <input
                          v-model={baseForm.code}
                          placeholder={$t(
                            "funds_recharge_inputVerificationCode" /**请输入图形验证码 */
                          )}
                          type="text"
                          maxlength={6}
                        />
                      </Form.Item>
                    </div>
                    <div class={entry.formBoxFormInputCaptcha} onClick={getCaptchaCode}>
                      <img src={captchaImg.value} alt="" />
                    </div>
                  </div>
                )}
                <div class={entry.loginBottom}>
                  <div
                    class={entry.formBoxFormCheckbox}
                    onClick={() => (forgetPassPopup.value = true)}
                  >
                    <span>{$t("user_login_forgetPassword") /**忘记密码 */}</span>？
                  </div>
                </div>
              </Form>
              <Button
                loading={loading.value}
                onClick={async () => {
                  await openCaptcha(false);
                  // await router.push("/");
                }}
              >
                {$t("user_login_login" /**登录 */)}
              </Button>
            </div>
            <div class={entry.loginAccountText}>
              <div class={entry.loginAccountTextNoflex}>
                {$t("user_login_serveTips" /*要访问此网站，请确保您已年满 18 岁并同意*/)}
                <span class={entry.loginAccountLink}>{$t("user_login_serveBtn" /*服务条款*/)}</span>
              </div>
              <p class={entry.loginAccountTextLight}>
                {$t("user_login_noAccountNumber" /*你没有账户吗？*/)}
                <span class={entry.loginAccountLink} onClick={() => emit("changeType", "register")}>
                  {$t("user_login_register" /*注册*/)}
                </span>
              </p>
            </div>
          </>
        )}
        {/*Phone Login 开启短信验证通道才显示手机登录*/}
        {global.smsSwitch && data.active === "phone" && (
          <>
            <div class={entry.loginContent}>
              <div class={entry.formBoxForm}>
                <PhoneLogin
                  onPhoneChange={v => (phoneForm.phone = v)}
                  onSmsCodeChange={v => (phoneForm.smsCode = v)}
                  onDialCodeChange={v => (phoneForm.dialCode = v)}
                  onSendCodeChange={() => sendCodeSubmit(false, 2)}
                  validateInfos={phone.validateInfos}
                  count={count}
                  hidden={false}
                />
                {global.smsSwitch ? (
                  <div class={entry.loginBottomPhone}>
                    {$t("user_verify_input_sms_code" /* 请输入6位数验证码 */)}
                  </div>
                ) : (
                  <div class={entry.loginBottom}>
                    <div
                      class={entry.formBoxFormCheckbox}
                      onClick={() => (check.provision = !check.provision)}
                    >
                      <i
                        class={[
                          entry.formBoxFormCheckboxIcon,
                          check.provision && entry.formBoxFormCheckboxIconChecked,
                        ]}
                      />
                      {$t("user_login_rememberPhone") /**记住手机号*/}
                    </div>
                  </div>
                )}
                {window.CONFIG.isShowImgCode && (
                  <div class={entry.formBoxFormSelect}>
                    <SvgIcon
                      class={entry.formBoxFormIcon}
                      width="0.4rem"
                      height="0.4rem"
                      name="vertifyCode"
                      style="margin-right: 0.1rem"
                    />
                    <div class={entry.formBoxFormSelectContent}>
                      <Form.Item name="code" {...phone.validateInfos.code}>
                        <input
                          v-model={phoneForm.code}
                          placeholder={$t(
                            "funds_recharge_inputVerificationCode" /**请输入图形验证码 */
                          )}
                          type="text"
                          maxlength={6}
                        />
                      </Form.Item>
                    </div>
                    <div
                      class={entry.formBoxFormInputCaptcha}
                      onClick={async () => {
                        const data = await captchaCode();
                        phoneForm.captchaKey = data.captchaKey;
                      }}
                    >
                      <img src={captchaImg.value} alt="" />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <Button
                  loading={loading.value}
                  onClick={async () => {
                    await setPhoneForm(phoneForm);
                    await openCaptcha(true);
                  }}
                >
                  {$t("user_login_login" /**登录 */)}
                </Button>
              </div>
            </div>
            <div class={entry.loginAccountText}>
              <div class={entry.loginAccountTextNoflex}>
                {$t("user_login_serveTips" /*要访问此网站，请确保您已年满 18 岁并同意*/)}
                <span class={entry.loginAccountLink}>{$t("user_login_serveBtn" /*服务条款*/)}</span>
              </div>
              <p class={entry.loginAccountTextLight}>
                {$t("user_login_noAccountNumber" /*你没有账户吗？*/)}
                <span class={entry.loginAccountLink} onClick={() => emit("changeType", "register")}>
                  {$t("user_login_register" /*注册*/)}
                </span>
              </p>
            </div>
          </>
        )}
        <ThirdLogin />
        <Popup
          position="bottom"
          v-model:show={forgetPassPopup.value}
          round
          teleport="body"
          class={entry.currencyPopup}
        >
          <div class={entry.currencyTitle}>
            <span class={entry.cancel} onClick={cancelForgetPass}></span>
            <h4>{$t("user_findPassword_findPassword" /* 找回密码 */)}</h4>
            <span class={(entry.cancel, entry.confirm)} onClick={cancelForgetPass}>
              <SvgIcon
                class={entry.currencyTitleClose}
                width="0.48rem"
                height="0.48rem"
                name="close"
              />
            </span>
          </div>
          <Forget />
        </Popup>
      </div>
    );
  },
});
