import { defineComponent } from "vue";
import styles from "./style.module.less";
import { mangoPopup } from "components";
import { SvgIcon } from "commom";
import { useI18n, usePromotionCode } from "core";
import { Loading } from "vant";
import banner from "./assets/b.png";

export default defineComponent({
  setup() {
    const { $t } = useI18n();
    const { store, getPromotionCode } = usePromotionCode();

    return () => (
      <div class={styles.code}>
        <div class={styles.codeMain}>
          <section class={styles.codeMainClose} onClick={() => mangoPopup().destroyAll()}>
            <SvgIcon name="close" width="0.4rem" height="0.4rem" color="#5C6F84" />
          </section>
          <header>{$t("common_promotion_code" /*兑换码*/)}</header>
          <main>
            <img src={banner} alt="" />
            <h3>{$t("common_input_promotion_code" /*请输入礼包兑换码*/)}</h3>
            <input type="text" maxlength={40} v-model={store.code} />
          </main>
          <section class={styles.codeMainBtn} onClick={getPromotionCode}>
            {store.loading && <Loading color="#fff" size="0.3rem" />}
            {$t("common_popup_promotion" /*兑换*/)}
          </section>
        </div>
      </div>
    );
  },
});
