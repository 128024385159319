import { defineComponent, ref } from "vue";
import entry from "./style.module.less";
import { mangoPopup } from "components";
import TabEntry from "./components/Tab";
import { SvgIcon } from "commom";
import Login from "./Login";
import Register from "./Register";

export default defineComponent({
  props: {
    status: {
      type: String,
      default: "login",
    },
  },
  emits: ["close"],
  setup(props) {
    const type = ref(props.status || "login");
    const changeType = (v: string) => {
      type.value = v;
    };
    return () => (
      <div class={entry.containerWrapper} id="js_login">
        <SvgIcon
          class={entry.containerClose}
          onClick={() => mangoPopup().destroyAll()}
          width="0.5rem"
          height="0.5rem"
          name="close"
        />
        <div class={entry.container}>
          <TabEntry
            type={type.value}
            onChange={changeType}
            onClose={() => mangoPopup().destroyAll()}
          />
          {type.value === "register" ? (
            <Register onChangeType={(v: string) => changeType(v)} />
          ) : (
            <Login onChangeType={(v: string) => changeType(v)} />
          )}
        </div>
      </div>
    );
  },
});
