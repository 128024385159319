import { computed, defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import styleModule from "./style.module.less";
import { RecordItem } from "./components/recordItem";
import { SelectTransactionType } from "./components/selectTransactionType";
import { SelectType } from "./components/selectType";
import { mangoPopup } from "components";
import SelectDate from "templates/mango/Web/Account/SelectDate";
import CurrencySelect from "templates/mango/Web/Account/CurrencySelect";

import { userRecord, useI18n, useConfig, useWallet, FundsDataLoadEnum } from "core";
import PageList from "templates/mango/Web/Account/PageList";

type PageListRefType = {
  refreshList: () => void;
  resetStore: () => void;
};

export default defineComponent({
  setup() {
    const { getTransType, store, fundsType, getFundsRequestApi, getFundsResponseList, dayChange } =
      userRecord();
    const { $t } = useI18n();
    const { config } = useConfig();
    const { amountFormat } = useWallet();
    const isBillFlow = computed(() => fundsType.value === 0);
    const mobilePageListTrans = ref<PageListRefType | null>(null);
    const data = reactive({
      currencyPop: false,
      requestApi: () => {},
    });
    const updateList = () => {
      data.requestApi = getFundsRequestApi(FundsDataLoadEnum.PAGE_LOAD);
      nextTick(() => {
        mobilePageListTrans.value?.refreshList();
      });
    };
    // 切换日期
    const activeDate = (obj: any) => {
      dayChange(obj.value);
      updateList();
    };
    // 切换币种
    const activeCurrency = (currency: string) => {
      store.currency = currency;
      updateList();
    };

    onMounted(async () => {
      await getTransType();
      updateList();
    });

    return () => (
      <div class={styleModule.fundPage}>
        <div class={styleModule.fundPageHeader}>
          <SelectType onChange={updateList} />
          <span class={styleModule.fundPageHeaderClose} onClick={() => mangoPopup().close()}></span>
        </div>
        <div class={styleModule.recordPage}>
          <div class={styleModule.dropdown}>
            <SelectTransactionType onChange={updateList} />
            <SelectDate onChangeDate={activeDate} day={store.day} />
            <div class={styleModule.fundPageCurrency}>
              <div
                class={styleModule.selectTransactionTypeText}
                onClick={() => (data.currencyPop = true)}
              >
                <img src={config.flagIcons.currency[store.currency]} alt="" />
                <span>{store.currency}</span>
              </div>
              <CurrencySelect
                v-model:show={data.currencyPop}
                currency={store.currency || ""}
                onChange={activeCurrency}
              />
            </div>
          </div>
        </div>

        {isBillFlow.value && (
          <div class={styleModule.total}>
            <div class={styleModule.totalBox}>
              <div class={styleModule.totalBoxIncome}>
                <p>{$t("mine_changeRecord_generalIncome" /* "总收入" */)}：</p>
                <p>
                  <span>{amountFormat(Number(store.totalIncome))}</span> <i>({store.currency})</i>
                </p>
              </div>
              <div class={styleModule.totalBoxExpenditure}>
                <p>{$t("mine_changeRecord_totalExpenditure" /* "总支出" */)}：</p>
                <p>
                  <span>{amountFormat(Number(store.totalOutlay))}</span> <i>({store.currency})</i>
                </p>
              </div>
            </div>
          </div>
        )}

        <div
          class={[styleModule.list, !isBillFlow.value && styleModule.longList]}
          style="position: relative"
        >
          <PageList
            ref={mobilePageListTrans}
            requestApi={data.requestApi}
            list={getFundsResponseList()}
            total={store.pagination.total}
            pages={store.pagination.pages}
            v-model:current={store.pagination.current}
            v-slots={{
              default: (list: any[]) => (
                <>
                  {list.map(item => (
                    <RecordItem itemData={item} />
                  ))}
                </>
              ),
            }}
          />
        </div>
      </div>
    );
  },
});
