/**
 * 1、充值类型列表
 * 2、充值渠道对应限制金额区间
 * 3、快捷金额
 * 4、支持的银行列表
 * 5、空充值渠道
 * 6、ustd汇率
 * 7、转账支付
 * 8、转账对应账户
 * 9、充值图形验证码
 */
import { ComputedRef, reactive, ref, Ref, watch, onMounted, onUnmounted, computed } from "vue";
import { useDebounceFn, useIntervalFn, Fn } from "@vueuse/core";
import {
  number2digitsReg,
  useToast,
  useUser,
  Api,
  useAsync,
  useLocalStorage,
  RECHARGE_MODAL_SHOW,
  useConfig,
  useConfigContext,
  useI18n,
  useWallet,
  mathMultiplied,
  isIos,
  isAndroid,
  openBridgePage,
  openBridgeBrowser,
  getSlice,
  isMobile,
  mathDiv,
  useAuth,
  useCommon,
  useGoogleAnaltics,
  usePointStatistics,
  hybridApp,
} from "core";
import { useRoute, useRouter } from "vue-router";
import { mangoPopup } from "components";
import {
  PayChannelSchema,
  PaySchema,
  PayTransferSchema,
  PayTypeSchema,
  RechargeOrderInfo,
} from "@kgsport-cms/service";

interface RechargeStore {
  activeType: number;
  activePayChannel: number;
  activeAmount: number | null;
  activeUSDTprotocol: number;
  payChannel: any[];
  amount: number | null;
  name: string | null;
  placeholderLabel: string;
  showBankPicker: boolean;
  bank?: string;
  isShowPopup: boolean;
  validateCodeEnabled: number;
  captchaImg: string;
  captchaKey: string;
  capCode: string;
  visible: boolean;
  loadingStatus: boolean;
  thirdBankCode: string;
  taxType: string;
  taxNumber: string;
}

/**
 * 通道风控金额类型 0.任何金额 1.浮动金额 2.固定金额 3浮动固定金额
 */
export enum ChannelAmountTyps {
  any = 0,
  float = 1,
  fixed = 2,
  floatAndfixed = 3,
}

/**
 * 角标0-无，1-火热，2-稳定，3-最新
 */
export enum SubscriptTyps {
  none = 0,
  fire = 1,
  stable = 2,
  new = 3,
}

/**
 * 是否银行: [0 - 否, 1 - 是，2-虚拟币]
 */
export enum BankFlagEnum {
  isNotBank = 0,
  isBank = 1,
  isVirtualCurrency = 2,
}

const store = reactive<RechargeStore>({
  activeType: 0,
  activePayChannel: 0,
  activeUSDTprotocol: 0,
  activeAmount: null,
  payChannel: [],
  amount: null,
  name: null,
  showBankPicker: false,
  placeholderLabel: "", // 输入框文字显示
  bank: "", // 选中的银行
  isShowPopup: true,
  validateCodeEnabled: 0,
  captchaImg: "",
  captchaKey: "",
  capCode: "",
  visible: false, //web付款界面展示
  loadingStatus: false, //loading状态
  thirdBankCode: "", // 三方银行卡编码
  // 税号类型
  taxType: "CPF",
  // 税号类型
  taxNumber: "",
});

// 充值
export function useRecharge() {
  const router = useRouter();
  const { $t } = useI18n();
  const { isLogin } = useAuth();
  const defaultLabel = $t("funds_recharge_inputDepositAmount" /* 请输入存款金额 */);
  store.placeholderLabel = defaultLabel;
  const { localStore } = useLocalStorage();
  const { isHideDownlod } = useConfigContext();
  const { descriptionFileApp } = hybridApp();
  const { userClickRecharge } = useGoogleAnaltics();
  const { clickRechargeSuccessStatistice } = usePointStatistics();
  const { isPc, config } = useConfig();
  const { toast } = useToast();
  const { user } = useUser();
  const { data } = useAsync(
    () => Api.RechargeService.payTypeList({ currency: user.value?.currency }),
    { isDisable: isLogin.value }
  );
  const { amountFormat, scaleAmountValue } = useWallet();
  const { captchaCode } = useCommon();
  //充值类型列表
  const typeList = computed<PayTypeSchema[]>(() => data.value || []);
  // 当前充值方式
  const activeItem = computed<PayTypeSchema>(() => typeList.value?.[store.activeType] || null);
  // 当前充值渠道
  const activeChannelItem = computed<PayChannelSchema>(
    () => store.payChannel?.[store.activePayChannel] || null
  );
  // 三方支付
  const isThreePartiesPay = computed(() => activeChannelItem.value?.mode === 2);
  // 三方支付银行编号列表
  const thirdBankCodes = computed(() => activeChannelItem.value?.thirdBankCode);
  watch(
    () => thirdBankCodes.value,
    n => {
      if (!n?.length) {
        store.thirdBankCode = "";
        return;
      }
      store.thirdBankCode = n[0].code ?? "";
    }
  );
  // 今日时间
  const today = computed<number>(() =>
    new Date(`${new Date().toLocaleDateString()} 23:59:59`).getTime()
  );
  const isShowPopup = computed<boolean>(() => store.isShowPopup);
  // 充值渠道对应限制金额区间
  const amountRange = computed<number[]>(() => {
    const { minAmountPerOrder: min = 0, maxAmountPerOrder: max = 0 } =
      activeChannelItem.value || {};
    return [min, max];
  });
  const loadingStatus = computed(() => store.loadingStatus);
  //快捷金额
  const amountList = computed<string[]>(() => {
    if (
      !activeChannelItem.value?.riskControlValue ||
      !Object.prototype.hasOwnProperty.call(activeChannelItem.value, "riskControlValue")
    ) {
      // 兼容lam109充值新模版逻辑，如果后端没返回固定金额配置，那么久用前端的固定金额
      let arr =
        ["lam109"].includes(window.CONFIG?.name) && isMobile
          ? ["30", "50", "100", "150", "200", "300"]
          : [];
      return arr;
    }
    return activeChannelItem.value.riskControlValue?.split("|") || [];
  });

  // 显示金额输入
  const amountInputVsisble = computed(
    () => activeChannelItem.value?.riskControlType !== ChannelAmountTyps.fixed
  );

  // 金额选择可见
  const amountSelectVsisble = computed(() => {
    const val = activeChannelItem.value?.riskControlType;
    if (!val || !amountList.value.length) return false;
    return val === ChannelAmountTyps.fixed;
  });

  const bankFlagNumber = computed(() => Number(activeItem.value?.bankFlag ?? 0));

  // 支持的银行列表
  const supportBanks = computed(() => {
    const banks = activeChannelItem.value?.banks;
    if (!banks) return [];
    const formList = JSON.parse(banks);
    return Object.keys(formList).map((key: any) => {
      return {
        text: formList[key],
        value: key,
        label: formList[key],
      };
    });
  });

  // 空充值渠道
  const isEmptyChannel = computed(() => store.payChannel && !store.payChannel.length);

  // ustd汇率
  const usdtRate: ComputedRef<number> = computed(() => Number(activeChannelItem.value?.rate ?? 0));

  // 预计计算
  const usdtTotal: ComputedRef<string> = computed(() => {
    let n: number;
    if (Number(store.amount) && usdtRate.value > 0) {
      n = Number(store.amount) / usdtRate.value;
    } else {
      n = 0;
    }
    return amountFormat(n);
  });

  // 按钮可点击
  const isBtnActivation = computed(() => {
    // 符合规则的金额
    const isLegalAmount = !!store.amount && number2digitsReg.test(String(store.amount));
    // 金额在区间
    const amountInInterval =
      Number(store.amount) >= amountRange.value[0] && Number(store.amount) <= amountRange.value[1];
    const validateCodeEnabled = store.validateCodeEnabled === 0 ? false : store.capCode === "";
    if (!isLegalAmount) {
      return true;
    } else if (!amountInInterval) {
      return true;
      // } else if (supportBanks.value.length > 0) {
      //   return !store.bank;
      // }
    } else if (
      bankFlagNumber.value === BankFlagEnum.isBank &&
      !store.visible &&
      !store.name &&
      !["lam109", "lam125", "lam126", "lam127", "lam130", "lam132", "lam131"].includes(
        window.CONFIG?.name
      )
    ) {
      return true;
    } else if (isThreePartiesPay.value && thirdBankCodes.value?.length && !store.thirdBankCode) {
      return true;
    } else if (
      window.CONFIG?.currentModel === "mango" &&
      activeChannelItem.value?.cpfFlag === "1" &&
      (store.taxNumber?.length < 10 || store.taxNumber?.length > 30 || !store.taxNumber)
    ) {
      return true;
    } else {
      return validateCodeEnabled;
    }
  });
  // 按比例处理后，实际金额
  // 实际到账  = 输入框输入金额 * 后台返回比例
  const actualAmount = computed(() =>
    mathMultiplied(Number(store.amount), Number(scaleAmountValue.value)).toNumber()
  );

  // 虚拟币预计支付
  // 预计支付  = 输入框输入金额 * 后台返回比例 / 数字货币汇率
  const expectedAmount = computed(() => {
    const number = mathDiv(Number(actualAmount.value), usdtRate.value).toNumber();
    if (number > 0) {
      return mathDiv(Number(actualAmount.value), usdtRate.value).toNumber();
    }
    return "0.00";
  });

  // 获取图形验证码
  const getCaptchaCode = async () => {
    const data = await captchaCode();
    store.captchaImg = data.img;
    store.captchaKey = data.captchaKey;
  };

  // 获取充值弹窗数据
  const getRechargePopupData = () => {};
  watch(
    () => data.value,
    async () => {
      store.activeType = 0;
      if (data.value && activeItem.value) {
        await getChannel(activeItem.value.code, user.value?.currency);
      }
    }
  );
  // 监听渠道类型变化
  watch(
    () => activeChannelItem.value,
    (channel, oldChannel) => {
      if (!channel) return;
      if (channel !== oldChannel) {
        restForm();
      }
      store.validateCodeEnabled = channel.validateCodeEnabled;
      store.validateCodeEnabled && getCaptchaCode();
      if (channel.riskControlType !== ChannelAmountTyps.fixed) {
        store.placeholderLabel = `${$t("funds_single_deposit_amount" /* 单笔存款金额 */)}：${
          amountRange.value[0]
        }~${amountRange.value[1]}`;
      } else {
        store.placeholderLabel = defaultLabel;
      }
    }
  );

  // 监听充值方式变化
  watch(
    () => store.activeType,
    (type, oldType) => {
      if (type !== oldType) {
        store.activePayChannel = 0;
        restForm();
      }
    }
  );
  const changeType = async (item: any, index: number) => {
    if (store.activeType === index) return;
    store.activeType = index;
    if (!item) return;
    await getChannelDebounced(item.code, user.value?.currency);
  };
  const changeChannel = async (index: number) => {
    store.activePayChannel = index;
  };
  const changeAmountType = async (index: number) => {
    store.activeAmount = index;
    store.amount = Number(amountList.value[index]);
  };
  const getChannel = async (payType: string, currency: string | undefined) => {
    if (!payType) return;
    if (!isPc.value) {
      toast.loading($t("common_loading" /* 加载中 */));
    }
    store.loadingStatus = true;
    const { data, result } = await Api.RechargeService.payChannels({ payType, currency });
    store.loadingStatus = false;
    if (!result) {
      store.payChannel = [];
      return;
    }
    store.payChannel = data;
  };
  // 重置表单
  const restForm = () => {
    store.activeUSDTprotocol = 0;
    store.activeAmount = null;
    store.amount = null;
    store.name = null;
    store.showBankPicker = false;
    store.bank = "";
    store.placeholderLabel = defaultLabel;
    store.validateCodeEnabled = 0;
  };
  // 监听数字输入
  watch(
    () => store.amount,
    v => {
      if (v === null || Number(v) === 0) {
        store.amount = null;
        return;
      }
      const sourceNumber = String(v);
      let n = v;
      // 金额正则处理
      if (!number2digitsReg.test(sourceNumber)) {
        const replaceValue = parseFloat(sourceNumber.replace(/[^0-9_.]/g, ""));
        if (isNaN(replaceValue)) {
          store.amount = null;
          return;
        }

        const intNum = parseInt((replaceValue * 100).toString());
        n = parseFloat((intNum / 100).toString());
      }
      // 金额大小范围处理
      const riskControlType = activeChannelItem.value?.riskControlType;
      if (riskControlType !== ChannelAmountTyps.fixed && amountRange.value[1] > 0) {
        if (n > amountRange.value[1]) {
          n = amountRange.value[1];
        }
      }
      store.amount = n;
    }
  );
  //校验按钮是否可点击
  const validator = (): boolean => {
    if (["bank"].includes(activeItem.value?.code) && !store.name) {
      //银行卡转账需校验存款姓名
      toast.error($t("funds_recharge_inputName" /* 请输入存款人姓名 */));
      return false;
    }
    const { amount, capCode } = store;
    if (!amount) {
      toast.error($t("funds_recharge_inputDepositAmount" /* 请输入存款金额 */));
      return false;
    }
    if (Number(amount) < amountRange.value[0] || Number(amount) > amountRange.value[1]) {
      toast.error(
        $t(
          "funds_deposit_amount_between",
          [
            String(amountRange.value[0]),
            String(amountRange.value[1]),
          ] /* 请输入[0]-[1]之间的存款金额 */
        )
      );
      return false;
    }
    if (store.validateCodeEnabled && !capCode) {
      toast.error($t("mine_usersetup_verificationPhoneCodePoint" /* 请输入验证码 */));
      return false;
    }
    return true;
  };
  // 充值动作
  const loadingPay = ref(false);
  // 获取最新订单
  const getNewOnlineOrder = async () => {
    // 获取最新订单信息
    const { data, result } = (await Api.RechargeService.getLastOrder({ mode: 2 })) || {};
    if (result) {
      // 将充值订单存储到本地会话中
      let orderList = localStorage.getItem("ORDER_LIST") || "[]";
      let orderArr = [];
      if (orderList && orderList != "null") {
        orderArr = JSON.parse(orderList);
      }
      // 判断存储中是否已经包含该订单，
      if (!orderArr.includes(data.orderNo)) {
        orderArr.push(data.orderNo);
        localStorage.setItem("ORDER_LIST", JSON.stringify(orderArr));
      }
    }
  };
  const doPayAction = async () => {
    if (loadingPay.value) return;
    // 租户埋点
    clickRechargeSuccessStatistice();
    const { bank, captchaKey, name, thirdBankCode, taxNumber } = store;
    const amount = Number(actualAmount.value);

    if (isPc.value && !validator()) return;

    // 判断金额
    if (!amount) return;
    // 判断银行 替换成thirdBankCode字段
    // if (supportBanks.value.length > 0 && !bank) return;
    if (isThreePartiesPay.value && thirdBankCodes.value?.length && !thirdBankCode) return;
    try {
      loadingPay.value = true;
      const { code } = activeItem.value;
      const { id, mode, rate, bankFlag } = activeChannelItem.value;
      // 谷歌统计
      // 获取当前支付类型名称
      userClickRecharge(
        typeList.value[store.activeType].name,
        typeList.value[store.activeType].payTypeName
      );
      if (mode !== 2) {
        // 银行卡转账参数
        const isUsdtPay = Number(bankFlag) === 2;
        await transferPay(isUsdtPay, { amount, accountId: id, rate, rechargePerson: name || "" });
        return;
      }
      // 在线支付对应通道
      const paydata: PaySchema = {
        payChannelId: id,
        amount: amount,
        payType: code,
        bankCode: bank,
        rechargePerson: name || "",
        captchaKey,
        currency: user.value?.currency || "",
        thirdBankCode,
        taxNumber,
      };
      if (store.validateCodeEnabled) {
        paydata.captchaKey = store.captchaKey;
        paydata.code = store.capCode;
      }
      let newWindow: any = null;
      if (!isHideDownlod) {
        newWindow = window.open("", "_blank");
      }
      // await validate();
      const { data, result } = await Api.RechargeService.pay(paydata);
      store.validateCodeEnabled && (await getCaptchaCode());
      if (!result) {
        newWindow?.close();
        return;
      }
      getNewOnlineOrder();
      // 判断获取为字符串,至少大于10个字符正常form表单html 页面插入节点
      if (typeof data === "string" && data.length > 10 && !data.startsWith("http")) {
        pathRechargeInfo({ type: "0" });
        if (isHideDownlod) {
          setTimeout(() => {
            router.push("/rechargeOnline?url=" + encodeURIComponent(data));
          }, 500);
          newWindow?.close();
          return;
        }
        newWindow?.document.write(data);
        newWindow?.document.forms[0] && newWindow?.document.forms[0].submit();
        return;
      }
      if (data.startsWith("http")) {
        // ios套壳app，调用原生openPage方法，app内部处理
        // 20230928 ios描述文件app不走原生app方法
        if (isHideDownlod && isIos && !descriptionFileApp) {
          openBridgePage({ url: data }).catch(() => {});
        } else if (isHideDownlod) {
          setTimeout(() => {
            router.push("/rechargeOnline?url=" + encodeURIComponent(data));
          }, 500);
          // openBridgeBrowser(data).catch(() => {});
        } else {
          if (newWindow) {
            newWindow.location.href = data;
          } else {
            window.location.href = data;
          }
        }
        if (isMobile) {
          window.CONFIG?.currentModel === "mango"
            ? (store.visible = true)
            : pathRechargeInfo({ type: "1" });
        }
        return;
      }
      newWindow?.close();
    } catch (e) {
      console.error("getPay error", e);
    } finally {
      loadingPay.value = false;
    }
  };
  // 转账支付
  const transferPay = async (
    isUsdtPay = false,
    {
      amount,
      accountId,
      rate,
      rechargePerson,
    }: { amount: number; accountId: number; rate: string; rechargePerson: string }
  ) => {
    const transferData: PayTransferSchema = {
      payAccountId: accountId,
      amount: amount as number,
      rechargePerson,
      // accountType: isUsdtPay ? '1' : '0',
      accountType: "0",
      memberId: "0", // TODO 增加vip充值需要的参数
      currency: user.value?.currency || "",
      // todo 增加税号需要的参数
      taxType: store.taxType,
      taxNumber: store.taxNumber,
    };
    if (isUsdtPay) {
      // 虚拟币转账
      // 货币数量 = 金额 / 费率
      const usdtTotal = Number(amount) / Number(rate);
      transferData["currencyCount"] = usdtTotal.toFixed(2);
      // transferData["currencyCount"] = "100";
      transferData["currencyRate"] = rate;
    }
    if (store.validateCodeEnabled) {
      transferData["captchaKey"] = store.captchaKey;
      transferData["code"] = store.capCode;
    }
    transferData["currency"] = user.value?.currency;
    // 转账对应账户
    const { data, result } = await Api.RechargeService.transfer(transferData);
    store.validateCodeEnabled && getCaptchaCode();
    if (result) {
      // 将充值订单存储到本地会话中
      let orderList = localStorage.getItem("ORDER_LIST") || "[]";
      let orderArr = [];
      if (orderList && orderList !== "null") {
        orderArr = JSON.parse(orderList);
      }
      // 判断存储中是否已经包含该订单，
      if (!orderArr.includes(data.orderNo)) {
        orderArr.push(data.orderNo);
        localStorage.setItem("ORDER_LIST", JSON.stringify(orderArr));
      }
      isPc.value || window.CONFIG?.currentModel === "mango"
        ? (store.visible = true)
        : pathRechargeInfo({ type: isUsdtPay ? "2" : "1" });
      // int106马甲包埋点
      if (window.androidJS && window.CONFIG?.name === "int106") {
        window.androidJS.tracker("recharge", {});
      }
    }
  };

  /**
   * @description: 跳转信息页-H5专用
   * @param {string} orderNo 充值订单号
   * @param {string} type 普通充值 1:虚拟币充值
   * @returns
   */
  const pathRechargeInfo = ({ type }: { type: string }) => {
    router.push({
      path: "/recharge/info",
      query:
        type === "1"
          ? {
              type,
            }
          : {
              type,
              usdtTotal: usdtTotal.value,
            },
    });
  };
  const onThirdCountyCodeChange = (code: string) => (store.thirdBankCode = code);

  const updateRechargePopUpStatus = (check: boolean) => {
    store.isShowPopup = check;
    localStore.set(RECHARGE_MODAL_SHOW, check, today.value);
  };
  const getChannelDebounced = useDebounceFn(getChannel, 0);
  const getPayDebounced = useDebounceFn(doPayAction, 500);

  onMounted(() => {
    // 过期或者不存在
    const localRecharge = localStore.get(RECHARGE_MODAL_SHOW);
    const isRechargeModalShowExpires =
      localRecharge === -1 || localRecharge === null || localRecharge;
    updateRechargePopUpStatus(!!isRechargeModalShowExpires);
  });

  watch(
    () => user.value,
    val => {
      if (val) {
        store.taxType = val.taxType;
        store.taxNumber = val.taxNum;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );

  return {
    store,
    today,
    typeList,
    usdtTotal,
    usdtRate,
    activeItem,
    amountRange,
    amountList,
    actualAmount,
    expectedAmount,
    changeType,
    isShowPopup,
    getChannel,
    defaultLabel,
    supportBanks,
    // validateInfos,
    changeChannel,
    isEmptyChannel,
    bankFlagNumber,
    isBtnActivation,
    getPayDebounced,
    changeAmountType,
    activeChannelItem,
    amountInputVsisble,
    amountSelectVsisble,
    captchaCode: getCaptchaCode,
    getChannelDebounced,
    getRechargePopupData,
    updateRechargePopUpStatus,
    validator,
    loadingStatus,
    loadingPay,
    isThreePartiesPay,
    thirdBankCodes,
    onThirdCountyCodeChange,
  };
}

// 充值方式右上标
export const FlagNames: { [key: number]: string } = {
  1: "funds_recharge_fiery", // "火热"
  2: "funds_recharge_stable", //"稳定"
  3: "funds_recharge_newest", // "最新"
};

/**
 * 充值信息
 */
// 1-未受理 2-充值受理中；3-已入款；4-订单已取消
export const RechargeStatus: { [key: number]: string } = {
  1: "funds_not_accepted",
  2: "funds_order_accepting",
  3: "funds_paid_in",
  4: "funds_order_cancel",
};

// 充值信息
export function useRechargeInfo() {
  const route = useRoute();
  const { getUserInfo } = useUser();
  const { $t } = useI18n();

  const info: Ref<RechargeOrderInfo> = ref({
    amount: 0,
    bankAddress: "",
    counterFee: "",
    createTime: 0,
    orderExpireTime: 0,
    orderNo: "",
    payAccountAccount: "",
    payAccountBankName: "",
    payAccountOwner: "",
    payTypeName: "",
    qrCode: "",
    status: 1,
    webRemarks: "",
  });

  /**
   * 获取路由信息
   */
  const routeQueryType = computed(() => Number(route.query.type) || 1);

  const routeQueryUsdt = computed(
    () => Number(window.CONFIG?.currentModel == "mango" ? store.amount : route.query.usdtTotal) || 0
  );

  /**
   * 完成状态(3 充值成功, 4 订单已取消)
   */
  const isFinish = computed<boolean>(() => [3, 4].includes(info.value.status));

  /**
   * 定时任务关闭函数
   */
  let pauseEvent: Fn = () => {};

  /**
   * 取消订单
   */
  const cancelOrder = async (callBack: (back: boolean, msg?: string) => void) => {
    const { orderNo } = info.value;
    const { result, message = "" } = await Api.RechargeService.revoke({ orderNo });
    if (!result) return;
    store.visible = false;

    //h5返回上级路由
    callBack(true, message);
  };

  /**
   * 获取订单信息
   */
  const getOrderInfo = async () => {
    const { data, result } = (await Api.RechargeService.getLastOrder()) || {};
    if (result) {
      info.value = data;
      if (data.status === 3) {
        store.visible = false;
        await getUserInfo(false);
        if (window.CONFIG?.currentModel === "mango") {
          mangoPopup().destroyAll();
        }
      } else if (data.status === 4) {
        store.visible = false;
        if (window.CONFIG?.currentModel === "mango") {
          mangoPopup().destroyAll();
        }
      }
    }
  };

  /**
   * 刷新充值状态定时任务(10s)
   */
  const timedTask = () => {
    const { pause } = useIntervalFn(async () => {
      await getOrderInfo();
      isFinish.value && pause();
    }, 1000 * 10);
    return pause;
  };

  onMounted(async () => {
    await getOrderInfo();
    pauseEvent = timedTask();
  });

  onUnmounted(() => {
    pauseEvent();
  });

  return {
    info,
    cancelOrder,
    getOrderInfo,
    routeQueryType,
    routeQueryUsdt,
    isFinish,
  };
}
