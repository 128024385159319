import { computed, defineComponent, reactive, ref, watch } from "vue";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import style from "./Recharge.module.less";
import { NoticeContent } from "components";
import { useRecharge, useI18n, useConfig, useUser, BankFlagEnum, useWallet, dutyRule } from "core";
import { SvgIcon } from "commom";
import { Empty } from "components";
import { Payment } from "./components/Payment";
import { Loading, Popup } from "vant";

import popup from "templates/mango/Web//popup.module.less";
import pay from "./assets/pay.png";
// 税号弹窗
import MangoDutyCodeSelect from "templates/mango/Web/components/DutyCodeSelect";

export default defineComponent({
  emits: ["close"],
  setup(props, { emit }) {
    /**
     * amountRange：充值区间
     * amountList：快捷金额
     */
    const {
      store,
      typeList,
      activeItem,
      amountRange,
      amountList,
      loadingStatus,
      usdtRate,
      actualAmount,
      expectedAmount,
      captchaCode,
      changeType,
      changeChannel,
      changeAmountType,
      isBtnActivation,
      getPayDebounced,
      loadingPay,
      bankFlagNumber,
      isThreePartiesPay,
      thirdBankCodes,
      onThirdCountyCodeChange,
      activeChannelItem,
    } = useRecharge();

    const duty = reactive({
      visible: false,
    });
    //国际化
    const { I18n, $t } = useI18n();
    const { goService, config } = useConfig();
    const { user } = useUser();
    const { scaleAmountValue, amountFormat } = useWallet();
    const countyCodeShow = ref(false);
    const currentIndex = ref(999);
    const isEmpty = computed(() => typeList.value?.length > 0);
    const bankCodeShow = ref(false);
    const renderEmpty = () => {
      return (
        <Empty
          type="mangoNoData"
          option={
            { mangoNoData: { text: $t("funds_no_recharge_channels") } } /**暂无可用充值渠道 */
          }
        />
      );
    };
    // 金额比例和实际金额
    const AmountScale2ActualTips = () => {
      return (
        <>
          <div class={style.sacle}>
            <p>
              {$t("funds_currency_ratio" /*货币比例*/)} 1:{scaleAmountValue.value}
            </p>
            <p>
              {$t("funds_actual_pay" /*实际支付*/)}:
              <span>{amountFormat(actualAmount.value || 0)}</span>
            </p>
          </div>
          {bankFlagNumber.value === BankFlagEnum.isVirtualCurrency && usdtRate.value > 0 && (
            <div class={style.sacle}>
              <p>
                {$t("funds_recharge_exchangeRate" /*参考汇率*/)} 1:{usdtRate.value}
              </p>
              <p>
                {$t("funds_recharge_estimatedPayment" /*预计支付*/)}:
                <span>{amountFormat(Number(expectedAmount.value))}</span>
              </p>
            </div>
          )}
        </>
      );
    };
    //充值方式
    const renderPayChannel = () => {
      return (
        <>
          {store.payChannel?.length > 0 && (
            <div class={style.channel}>
              <p class={style.title}>{$t("funds_recharge_method") /**充值方式 */}</p>
              <div class={style.channelList}>
                {store.payChannel?.map((item: any, index: number) => {
                  return (
                    <div
                      class={[
                        style.channelListItem,
                        store.activePayChannel === index ? style.activted : "",
                      ]}
                      onClick={() => changeChannel(index)}
                    >
                      <div class={style.channelListItemImg}>
                        <img src={item.url || pay} />
                      </div>
                      <div class={[style.channelListItemBox]}>
                        {/* todos: 处理任务#15220 */}
                        {window.CONFIG.name === "lam108" &&
                        activeItem.value.code === "PIX" ? null : (
                          <div class={[style.channelListItemBoxRow]}>
                            <span
                              class={[
                                style.channelListItemBoxRowName,
                                store.activePayChannel === index &&
                                  style.channelListItemBoxRowActive,
                              ]}
                            >
                              {item.bankFlag === 0 ? item.payTypeName : item.bankName}
                            </span>
                          </div>
                        )}
                        <p>{item.name}</p>
                        <p v-show={(item.bankFlag == 1 || item.bankFlag == 2) && item.account}>
                          {item.account}
                        </p>
                      </div>
                      <div
                        class={style.channelListItemFooter}
                        v-show={Number(String(item.eachRechargeDiscountValue).replace("%", "")) > 0}
                      >
                        {$t("funds_recharge_discount" /* 优惠 */)} {item.eachRechargeDiscountValue}
                      </div>
                      {/*<div class={style.channelListItemLabel} v-show={item.typeSubscript !== 0}>*/}
                      {/*  {item.typeSubscript !== 0 ? (*/}
                      {/*    <span class={style.channelListItemText}>*/}
                      {/*      {$t(FlagNames[item.typeSubscript])}*/}
                      {/*    </span>*/}
                      {/*  ) : null}*/}
                      {/*</div>*/}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {store.loadingStatus}
          {store.payChannel?.length === 0 && !store.loadingStatus && renderEmpty()}
        </>
      );
    };
    //存款金额->[银联钱包,QQ钱包,网银,微信]
    const renderUnionDep = () => {
      return (
        <>
          {store.payChannel?.length > 0 ? (
            <div class={style.deposit}>
              <div class={style.title}>{$t("funds_recharge_depositAmount") /**存款金额 */}</div>
              <div class={style.depositRow}>
                {isThreePartiesPay.value && !!thirdBankCodes.value?.length && (
                  <div class={style.countyCode}>
                    <div
                      class={style.countyCodeSelect}
                      onClick={e => {
                        e.stopPropagation();
                        bankCodeShow.value = !bankCodeShow.value;
                      }}
                    >
                      <span class={style.countyCodeSelectText}>{store.thirdBankCode}</span>
                      <CaretDownOutlined />
                    </div>
                  </div>
                )}
                <input
                  class={[
                    isThreePartiesPay.value && thirdBankCodes.value?.length && style.thirdBankInput,
                  ]}
                  type="number"
                  v-model={store.amount}
                  placeholder={`${$t("funds_recharge_limitAmout" /**限额 */)}${amountFormat(
                    amountRange.value?.[0] ?? 0
                  )}-${amountFormat(amountRange.value?.[1] ?? 0)} ${user.value?.currency ?? ""}`}
                />
                <div class={style.amountList}>
                  {amountList?.value.map((num, index) => (
                    <span
                      onClick={() => {
                        currentIndex.value = index;
                        changeAmountType(index);
                      }}
                      class={currentIndex.value === index && style.amountListActive}
                    >
                      {num}
                      {currentIndex.value === index && (
                        <SvgIcon
                          class={style.amountListIcon}
                          name="mangoRechargeCheck"
                          width="32px"
                          height="19px"
                          color="var(--cms-mango-primary-color)"
                        />
                      )}
                    </span>
                  ))}
                  <span class={style.amountListZw}></span>
                  <span class={style.amountListZw}></span>
                </div>
              </div>
              {!!store.validateCodeEnabled && (
                <div class={style.depositRow}>
                  <input
                    type="text"
                    v-model={store.capCode}
                    placeholder={$t("funds_recharge_inputVerificationCode" /* 请输入图形验证码 */)}
                  />
                  <img
                    class={style.depositRowCaptcha}
                    onClick={() => captchaCode()}
                    src={store.captchaImg}
                  />
                </div>
              )}
              {AmountScale2ActualTips()}
            </div>
          ) : null}
        </>
      );
    };

    //存款->银行卡
    const renderBankDep = () => {
      return (
        <>
          {store.payChannel.length > 0 ? (
            <div class={[style.deposit]}>
              <div class={style.title}>{$t("funds_recharge_depositAmount") /**存款金额 */}</div>
              <div class={style.depositRow}>
                {isThreePartiesPay.value && !!thirdBankCodes.value?.length && (
                  <div class={style.countyCode}>
                    <div
                      class={style.countyCodeSelect}
                      onClick={() => (bankCodeShow.value = !bankCodeShow.value)}
                    >
                      <span class={style.countyCodeSelectText}>{store.thirdBankCode}</span>
                      <CaretDownOutlined />
                    </div>
                  </div>
                )}
                <input
                  class={[
                    style.bankInput,
                    isThreePartiesPay.value && thirdBankCodes.value?.length && style.thirdBankInput,
                  ]}
                  // TODO 暂时改为金额都可以输入
                  // readonly
                  type="number"
                  v-model={store.amount}
                  placeholder={`${$t("funds_recharge_limitAmout" /**限额 */)}${amountFormat(
                    amountRange.value?.[0] ?? 0
                  )}-${amountFormat(amountRange.value?.[1] ?? 0)} ${user.value?.currency ?? ""}`}
                />
                <div class={style.amountList}>
                  {amountList?.value.map((num, index) => (
                    <span
                      onClick={() => {
                        currentIndex.value = index;
                        changeAmountType(index);
                      }}
                      class={currentIndex.value === index && style.amountListActive}
                    >
                      {num}
                      {currentIndex.value === index && (
                        <SvgIcon
                          class={style.amountListIcon}
                          name="mangoRechargeCheck"
                          width="32px"
                          height="19px"
                          color="var(--cms-mango-primary-color)"
                        />
                      )}
                    </span>
                  ))}
                  <span class={style.amountListZw}></span>
                  <span class={style.amountListZw}></span>
                </div>
              </div>
              {AmountScale2ActualTips()}
              {!["lam125", "lam126", "lam127", "lam130", "lam132", "lam131"].includes(
                window.CONFIG?.name
              ) && (
                <>
                  <div class={[style.title, style.titleRed]}>
                    {$t("funds_recharge_name") /**存款人姓名 */}
                  </div>
                  <div class={[style.depositRow, style.depositRowRed]}>
                    <input
                      type="text"
                      v-model={store.name}
                      placeholder={$t("funds_recharge_inputName") /**请输入存款人姓名 */}
                    />
                  </div>
                </>
              )}
              {/*税号*/}
              {activeChannelItem.value?.cpfFlag === "1" &&
              window.CONFIG?.currentModel === "mango" ? (
                <>
                  <div class={[style.duty, style.depositRow]}>
                    <div class={style.dutyCode} onClick={() => (duty.visible = true)}>
                      {store.taxType}
                    </div>
                    <input
                      class={[style.dutyNumber]}
                      v-model={store.taxNumber}
                      placeholder={$t("common_validator_duty_rule" /**请输入10-30位字符 */)}
                    />
                  </div>
                </>
              ) : null}
              {!!store.validateCodeEnabled && (
                <div class={style.depositRow}>
                  <input
                    class={style.depositRowInput}
                    type="text"
                    v-model={store.capCode}
                    placeholder={$t("funds_recharge_inputVerificationCode" /*请输入图形验证码*/)}
                  />
                  <img
                    class={style.depositRowCaptcha}
                    onClick={() => captchaCode()}
                    src={store.captchaImg}
                  />
                </div>
              )}
            </div>
          ) : null}
          {/* 税号类型弹窗 */}
          <MangoDutyCodeSelect
            height={330}
            v-model:show={duty.visible}
            currency={store.taxType}
            onChange={value => (store.taxType = value)}
          />
        </>
      );
    };

    return () => (
      <div class={style.wrapperCharge} onClick={() => (bankCodeShow.value = false)}>
        {/* 充值付款界面 */}
        {store.visible ? (
          <Payment
            currRecharge={activeItem.value?.code}
            onHandleCancel={() => (store.visible = false)}
          />
        ) : (
          <>
            {/* 充值主体内容 */}
            {isEmpty.value && (
              <>
                {/* 公告栏 */}
                <div class={style.wrapperChargeNotice}>
                  <div class={style.wrapperChargeNoticeContent}>
                    <SvgIcon
                      name="notice"
                      width="24px"
                      height="24px"
                      color="@cms-mango-text-color"
                    />
                    <NoticeContent
                      class={style.wrapperChargeNoticeContentNotice}
                      styleStr={{ width: "100%", color: "#fff" }}
                      onClose={() => emit("close")}
                    />
                  </div>
                </div>
                <div class={style.wrapperChargeView}>
                  {/* 存款金额 */}
                  {/*bankFlag 是否银行: [0 - 否, 1 - 是，2-虚拟币]*/}
                  {activeItem.value?.bankFlag === "1" ? renderBankDep() : renderUnionDep()}
                  <div class={style.wrapperChargeViewLoading}>
                    {loadingStatus.value && <Loading type="spinner" />}
                  </div>
                  <p class={style.title}>{$t("common_payment_type" /*支付类型*/)}</p>
                  <div class={style.wrapperChargeTop}>
                    {typeList.value?.map((item, index) => {
                      return (
                        <div
                          class={[
                            style.wrapperChargeTopItem,
                            store.activeType == index ? style.wrapperChargeTopItemActivted : "",
                          ]}
                          onClick={() => changeType(item, index)}
                        >
                          <img src={item.iconUrl} width={32} />
                          {item.name}
                        </div>
                      );
                    })}
                    <div class={style.wrapperChargeTopZw}></div>
                    <div class={style.wrapperChargeTopZw}></div>
                  </div>
                  <div class={style.wrapperChargeContent}>
                    {/* 充值方式 */}
                    {renderPayChannel()}
                  </div>
                </div>
                <div class={style.wrapperChargeFooter}>
                  <div
                    class={[
                      style.wrapperChargeFooterSubmit,
                      isBtnActivation.value && style.wrapperChargeFooterSubmitDisabled,
                    ]}
                    onClick={() => {
                      if (isBtnActivation.value) return;
                      getPayDebounced();
                    }}
                  >
                    {loadingPay.value && <Loading size={20} color="#fff" />}
                    {$t("common_next" /**下一步 */)}
                  </div>
                  <I18n
                    tag="div"
                    keyPath="funds_recharge_problems" /*存款遇到问题？联系[0]解决*/
                    class={style.wrapperChargeFooterService}
                    v-slots={{
                      $0: () => {
                        return window.CONFIG?.name === "lam103" && (window as any).ssq ? (
                          <span onClick={() => (window as any).ssq.push("chatOpen")}>
                            {$t("mine_customerservice_onlineCS" /**联系客服 */)}
                          </span>
                        ) : (
                          <span onClick={() => goService()}>
                            {$t("mine_customerservice_onlineCS" /**联系客服 */)}
                          </span>
                        );
                      },
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
        {/*选择虚拟币*/}
        <Popup
          position="bottom"
          v-model:show={bankCodeShow.value}
          round
          class={[popup.popupWidth100, style.wrapperSelect]}
        >
          <div class={[style.wrapperSelect]}>
            <div class={popup.popupHeader}>
              <div class={popup.popupHeaderLeft} onClick={() => (bankCodeShow.value = false)}>
                {$t("common_cancel" /*取消*/)}
              </div>
              <div class={popup.popupHeaderMiddle}>
                {$t("funds_choose_btc_address" /* 选择虚拟币地址 */)}
              </div>
              <div class={popup.popupHeaderRight} onClick={() => (bankCodeShow.value = false)}>
                {$t("common_confirm" /*确定*/)}
              </div>
            </div>
            <div class={style.wrapperSelectCon}>
              {thirdBankCodes.value?.map(item => (
                <div
                  class={style.wrapperSelectConItem}
                  onClick={() => {
                    onThirdCountyCodeChange(item.code);
                    bankCodeShow.value = false;
                  }}
                >
                  <div class={style.wrapperSelectConItemName}>{item.name}</div>
                  <div class={style.wrapperSelectConItemText}>({item.code})</div>
                  <div class={style.wrapperSelectConItemSel}>
                    <SvgIcon name={item.code === store.thirdBankCode ? "mangoSel" : "mangoNoSel"} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popup>
      </div>
    );
  },
});
