import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import style from "./style.module.less";
import { SvgIcon } from "commom";

import SpeedProgress from "./components/SpeedProgress";
import OrderInfo from "./components/OrderInfo";
import { useI18n, useUser, useWithdraw, useWallet } from "core";

export default defineComponent({
  name: "MobileWithdrawalApplySuccess",
  props: {
    type: String,
    cashOrderNo: String,
  },
  setup(prop) {
    const route = useRoute();
    const { $t } = useI18n();
    const { user } = useUser();
    const { get, orderDetails } = useWithdraw();
    const { amountFormat } = useWallet();
    const unit = computed(() => user.value?.currency ?? "USD");

    (async () => await get(prop.cashOrderNo))();

    return () => (
      <>
        <div class={style.withdrawalApplySuccess}>
          <div class={style.successful}>
            <SvgIcon
              name="mangoOkCircle"
              width="0.88rem"
              height="0.88rem"
              color="var(--cms-mango-primary-color)"
            />
            <section>
              <article class={style.title}>
                {$t("funds_withdraw_stepOneTitle" /* 提现申请已提交成功 */)}
              </article>
              <article class={style.amount}>
                <span>{$t("funds_withdraw_amout" /* 提现金额 */)}：</span>
                {amountFormat(orderDetails.value?.cashMoney ?? 0)} ({unit.value})
              </article>
            </section>
          </div>
          <SpeedProgress active={String(orderDetails.value?.cashStatus ?? 1)} />
          <OrderInfo type={prop.type} orderDetails={orderDetails.value} />
        </div>
      </>
    );
  },
});
