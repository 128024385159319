import { isMobile, searchTimeToString } from "core";
export function useGoogleAnaltics() {
  // 用户点击登录统计
  const userClickLogin = () => {
    if (window.gtag) {
      console.log("click login success!");
      isMobile
        ? window.gtag("event", "h5_login", { click: "" })
        : window.gtag("event", "pc_login", { click: "" });
    }
  };
  // 登录成功
  const userLoginSuccess = (
    vipLevel: number | undefined,
    registerTime: number | undefined,
    invitationCode: number | string | null | undefined
  ) => {
    if (window.gtag) {
      console.log("click login success!");
      isMobile
        ? window.gtag("event", "h5_loginfinish", {
            vipLevel,
            registerTime,
            invitationCode,
          })
        : window.gtag("event", "pc_loginfinish", {
            vipLevel,
            registerTime,
            invitationCode,
          });
    }
  };
  // 用户点击注册统计
  const userClickRegister = (intrCodeOption: string | number) => {
    if (window.gtag) {
      console.log("click success!");
      isMobile
        ? window.gtag("event", "h5_register", { intrCodeOption })
        : window.gtag("event", "pc_register", { intrCodeOption });
    }
  };
  // 用户点击试玩统计
  const userClickFreeplay = (gameKindCode: string | number) => {
    if (window.gtag) {
      console.log("click success!");
      isMobile
        ? window.gtag("event", "h5_freePlay", { gameKindCode })
        : window.gtag("event", "pc_freePlay", { gameKindCode });
    }
  };
  // 用户点击充值统计 三方充值name、四方充值payTypeName
  const userClickRecharge = (name: string | null, payTypeName: string | null) => {
    if (window.gtag) {
      console.log("click success!");
      isMobile
        ? window.gtag("event", "h5_recharge", { name, payTypeName })
        : window.gtag("event", "pc_recharge", { name, payTypeName });
    }
  };
  // 用户点击提现统计
  const userClickWithdraw = () => {
    if (window.gtag) {
      console.log("click success!");
      isMobile
        ? window.gtag("event", "h5_withdraw", { click: "" })
        : window.gtag("event", "pc_withdraw", { click: "" });
    }
  };
  // 用户成功统计
  const userRegisterfinish = () => {
    if (window.gtag) {
      console.log("click success!");
      isMobile
        ? window.gtag("event", "h5_registerfinish", { click: "" })
        : window.gtag("event", "pc_registerfinish", { click: "" });
    }
  };
  // 网络请求错误统计
  const userRequestBad = (status: number, desc: string | null, path: string | undefined) => {
    if (window.gtag) {
      console.log("click success!");
      isMobile
        ? window.gtag("event", "h5_network_error", { status, desc, path })
        : window.gtag("event", "pc_network_error", { status, desc, path });
    }
  };
  return {
    userClickLogin,
    userClickRegister,
    userClickFreeplay,
    userClickRecharge,
    userClickWithdraw,
    userLoginSuccess,
    userRegisterfinish,
    userRequestBad,
  };
}
