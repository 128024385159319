import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import styleModule from "./style.module.less";
import { mangoPopup } from "components";
import { userRecord, useI18n, toTimeNormal, useWallet } from "core";
import bill from "./assets/bill.png";
import account from "./assets/account.png";

export const RecordItem = defineComponent({
  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const router = useRouter();
    const { fundsType, getStatus, getListTransactionTypeText, fundsActiveText } = userRecord();
    const { amountFormat } = useWallet();
    const clickRecordItem = () => {
      const params = props.itemData;
      params.fundsType = fundsType.value;
      mangoPopup({
        type: "MangoFundingdetailsDetail",
        props: {
          query: params,
        },
        title: $t(fundsActiveText.value),
      }).open();
    };

    return () => (
      <div class={styleModule.itemContanner} onClick={() => clickRecordItem()}>
        <div class={styleModule.itemContannerLeft}>
          <img
            class={styleModule.itemContannerLeftImg}
            src={props.itemData?.amount > 0 ? account : bill}
            alt=""
          />
          <div class={styleModule.itemContannerLeftBox}>
            <div class={styleModule.itemContannerLeftBoxTitle}>
              {getListTransactionTypeText(props.itemData)}
            </div>
            <div class={styleModule.itemContannerLeftBoxTime}>
              {toTimeNormal(props.itemData?.createTime, "YYYY-MM-DD HH:mm:ss") || "--"}
            </div>
          </div>
        </div>
        <div class={styleModule.itemContannerRight}>
          <div class={styleModule.itemContannerRightTop}>
            <p class={styleModule.itemContannerRightTopMoney}>
              {fundsType.value === 3 ? (
                <span
                  class={[
                    Number(props.itemData?.transferType) === 1
                      ? styleModule.itemContannerRightTopMoneyAccount
                      : styleModule.itemContannerRightTopMoneyBill,
                  ]}
                >
                  {Number(props.itemData?.transferType) === 1 ? "+" : "-"}
                  {amountFormat(props.itemData?.amount || props.itemData?.cashMoney)}
                </span>
              ) : (
                <span
                  class={[
                    props.itemData.amount > 0
                      ? styleModule.itemContannerRightTopMoneyAccount
                      : styleModule.itemContannerRightTopMoneyBill,
                  ]}
                >
                  {props.itemData?.amount > 0 ? "+" : ""}
                  {amountFormat(props.itemData?.amount || props.itemData?.cashMoney)}
                </span>
              )}
              <span class={styleModule.itemContannerRightTopMoneyCurrency}>
                ({props.itemData?.currency || "--"})
              </span>
            </p>
          </div>
          {[1, 2].includes(fundsType.value) && (
            <div class={styleModule.itemContannerRightBottom}>
              {$t(getStatus(props.itemData?.status || props.itemData?.cashStatus))}
            </div>
          )}
        </div>
      </div>
    );
  },
});
