import { h, render, VNode } from "vue";
import ToastMain from "./main";

interface Options {
  type?: "info" | "success" | "error";
  title?: string;
  time?: number;
}

interface InstanceObj {
  isUnmounted: boolean;
  vm: VNode;
  open: () => void;
  unmount: () => void;
}

type MobileToast = (option: Options) => InstanceObj;
const mangoMobileToast: MobileToast = option => {
  const { time = 3000, ...opt } = option;
  const toastBox = document.createElement("div");
  // eslint-disable-next-line prefer-const
  let instance: InstanceObj;
  const vm: VNode = h(ToastMain, {
    ...opt,
    show: false,
    "onUpdate:show"() {
      vm.component && (vm.component.props.show = false);
      instance?.unmount?.();
    },
  });
  instance = {
    isUnmounted: false,
    vm,
    open() {
      render(vm, toastBox);
      vm.component?.props && (vm.component.props.show = true);
      document.body.appendChild(toastBox);
    },
    unmount() {
      if (vm.component?.isUnmounted) return;
      instance.isUnmounted = true; // 设置销毁标记
      render(null, toastBox);
      toastBox?.parentNode?.removeChild(toastBox);
    },
  };
  instance.open();
  setTimeout(instance.unmount, time);
  return instance;
};

export { mangoMobileToast, InstanceObj, Options };
