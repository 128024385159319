import { defineComponent, onMounted } from "vue";
import { nowConvertByTimezone, useDayjs, useI18n } from "core";
import timeZone from "./timeZone.module.less";
export default defineComponent({
  name: "MangoTimeZone",
  setup() {
    const { $t } = useI18n();
    const {
      timezoneList,
      currentTimezone,
      changeDayjsTimezone,
      requestTimezoneList,
      formatTimezone,
    } = useDayjs();

    onMounted(() => {
      requestTimezoneList();
    });
    return () => (
      <div class={timeZone.timeZone}>
        <div class={timeZone.timeZoneTitle}>
          {$t("mine_timezone_timeZoneChanges" /*时区变更*/)}
          <span>
            {$t(
              "common_browser_automatically_refreshes_after_time_zone_is_changed" /*时区变更后会自动刷新浏览器*/
            )}
          </span>
        </div>
        {timezoneList.value?.map(item => {
          const timezoneKeys = formatTimezone(item.seriNo);
          return (
            <div class={timeZone.timeZoneItem} onClick={() => changeDayjsTimezone(timezoneKeys[1])}>
              <div
                class={[
                  timeZone.timeZoneItemTop,
                  timezoneKeys[1] === currentTimezone.value && timeZone.timeZoneItemTopActive,
                ]}
              >
                <span>{item.info}</span>
                <span>{timezoneKeys[0]}v</span>
              </div>
              <div class={timeZone.timeZoneItemBottom}>
                <span></span>
                <span>{nowConvertByTimezone(timezoneKeys[1] || "")}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  },
});
