import { defineComponent, computed, reactive } from "vue";
import styleModule from "./style.module.less";
import popup from "templates/mango/Web/popup.module.less";
import { Popup } from "vant";
import { useI18n, userRecord } from "core";

interface optionsType {
  dictLabel: string;
  dictName: string;
  dictValue: string;
}

export const SelectTransactionType = defineComponent({
  emits: ["change"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const {
      fundsActiveFilterText,
      fundsListSize,
      fundsFilterValue,
      fundsType,
      getFundsFilterList,
      updateStatus,
    } = userRecord();
    const data = reactive({
      show: false,
    });
    const isBillFlow = computed(() => fundsType.value === 0);
    const activeType = (index: number, item: optionsType | string) => {
      updateStatus(typeof item === "string" ? index : item.dictValue);
      data.show = false;
      emit("change");
    };

    return () => (
      <>
        <div
          class={`${styleModule.selectTransactionTypeText} ${
            data.show ? styleModule.selectTransactionTypeTextActive : ""
          }`}
          onClick={() => (data.show = !data.show)}
        >
          <span>{$t(fundsActiveFilterText.value)}</span>
          <span>{fundsListSize.value}</span>
        </div>
        <Popup
          v-model:show={data.show}
          position="bottom"
          round
          lock-scroll={false}
          class={[popup.popupWidth100, popup.popupHeight700]}
        >
          <div class={[popup.popupHeader, popup.popupHeaderBg]}>
            <section class={popup.popupHeaderLeft} onClick={() => (data.show = false)}>
              {$t("common_cancel" /* "取消" */)}
            </section>
            <section class={popup.popupHeaderMiddle}>
              {$t("mine_changeRecord_Filtrate" /*筛选*/)}
            </section>
            <section class={popup.popupHeaderRight}></section>
          </div>
          <div class={styleModule.popupTransaction}>
            <div class={styleModule.popupTransactionList}>
              <ul class={styleModule.popupTransactionListBox}>
                {getFundsFilterList().map((item: any, index: number) => (
                  <li
                    class={[
                      styleModule.popupTransactionListBoxItem,
                      (isBillFlow.value && fundsFilterValue.value === item.dictValue) ||
                      (!isBillFlow.value && fundsFilterValue.value === index)
                        ? styleModule.active
                        : null,
                    ]}
                    onClick={() => activeType(index, item)}
                  >
                    {$t(item.dictName || item)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Popup>
      </>
    );
  },
});
