import type { ComponentPublicInstance } from "vue";
import MangoTipsModal from "./mangoTipsModal";
import { mountComponent, usePopupState, useI18nFn } from "core";

// eslint-disable-next-line
let instance: ComponentPublicInstance<{}, any>;

type TipsModalOptions = {
  show?: boolean;
  title?: string;
  subTitle?: string;
  type?: string;
  confirmText?: string;
  cancelText?: string;
  confirmCallback?: () => void;
  cancelCallback?: () => void;
  maskClosable?: boolean;
  close?: () => void;
};
type DefaultType = "login";

const { $t } = useI18nFn();
let TipsDefaultOptions: Record<string, TipsModalOptions> | null = null;

function initInstance() {
  ({ instance } = mountComponent({
    setup() {
      TipsDefaultOptions = {
        login: {
          title: $t("user_login_notLogin" /**您还未登录 */),
          subTitle: $t("common_noLoginTips" /**登录后体验更多功能 */),
          type: "success",
          confirmText: $t("common_nowLogin" /**立即登录 */),
          cancelText: $t("user_register_alert_cancel" /**取消 */),
        },
      };
      const { state } = usePopupState();
      return () => <MangoTipsModal {...state} v-model:show={state.show} />;
    },
  }));
}

export const closeMangoModalTips = () => {
  if (instance) {
    instance.toggle(false);
  }
};

export function showMangoModalTips(options?: TipsModalOptions, defaultType?: DefaultType) {
  if (!options) options = {} as TipsModalOptions;

  if (!instance) {
    initInstance();
  }

  if (defaultType) {
    options = Object.assign({}, options, TipsDefaultOptions?.[defaultType]);
  }

  options = Object.assign({ show: true }, options);

  instance.open(options);

  return instance;
}
