import { defineComponent } from "vue";
import svgStyle from "./SvgIcon.module.less";
import { icons } from "./svgs";

export default defineComponent({
  name: "MobileSecurityCenter",
  props: {
    name: {
      default: "",
      type: String,
      require: true,
    },
    color: {
      default: "",
      type: String,
    },
    width: {
      default: "32px",
      type: String,
    },
    height: {
      default: "32px",
      type: String,
    },
  },
  emits: ["click"],
  setup(props, { emit }) {
    const SvgRender = () => (
      <div
        onClick={() => emit("click")}
        style={Object.assign(
          { fill: props.color ? props.color : "", width: props.width, height: props.height },
          props.color ? { color: props.color } : {}
        )}
        class={svgStyle.svgIcon}
        v-html={icons[props.name]}
      ></div>
    );

    return () => <SvgRender />;
  },
});
