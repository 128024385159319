import type { App } from "vue";
import { useDayjs, useI18nInit, useConfig, useAgConfig } from "core";

export type SystemInitOptionsType = {
  initDayjs?: boolean;
  initSportCallback?: () => void;
  isAgent?: boolean;
};

export const useSystemInit = () => {
  const { setSystemConfigs } = useConfig();
  const { initDayjs } = useDayjs();
  const { initLang } = useI18nInit();
  const { getLangueList } = useAgConfig();
  const systemInit = async (
    app: App<Element>,
    options: SystemInitOptionsType = {
      initDayjs: true,
      isAgent: false,
    }
  ) => {
    let langList;
    window.__AGENT_ENV__ = !!options.isAgent;
    if (options.isAgent) {
      langList = await getLangueList();
    } else {
      await initLang([], options.isAgent);
      await setSystemConfigs();
    }

    await initDayjs();
    await initLang(langList, options.isAgent);
    if (options.initSportCallback) {
      await options.initSportCallback();
    }

    // 缓存app实例，弹窗组件或者其他在实例外创建的组件绑定appContext使用
    window.__VUE_APP__ = app;
  };

  return {
    systemInit,
  };
};
