/*
 * @Descripttion   : your project
 * @Author         : amber
 * @Date           : 2022-09-11 22:57:36
 * @LastEditors    : amber
 * @LastEditTime   : 2022-09-11 23:02:38
 */
import { defineComponent, onMounted, reactive, watch } from "vue";
import { useHelp, useI18n } from "core";
import { Empty } from "components";
import help from "./Help.module.less";
import { mangoPopup } from "components";

export default defineComponent({
  setup() {
    const { getHelpList, navList } = useHelp();
    const { $t } = useI18n();
    const data = reactive({
      selectId: 0,
    });

    console.log(navList, "navList");
    onMounted(() => {
      getHelpList();
    });
    return () => (
      <div class={help.container}>
        <div class={help.containerHeader}>
          {$t("mine_usercenter_help" /*帮助中心*/)}
          <div
            class={help.containerHeaderClose}
            onClick={() => {
              mangoPopup().destroyAll();
            }}
          ></div>
        </div>
        <ul class={help.helpList}>
          {navList.value && navList.value.length ? (
            navList.value.map(item => (
              <li
                class={`${help.helpItem}  ${data.selectId === item.id ? help.helpActive : ""}`}
                key={item.id}
              >
                <div class={help.helpHeader} onClick={() => (data.selectId = item.id)}>
                  {item.title}
                </div>
                <dl class={help.helpSubItem}>
                  {item.children?.map(subItem => (
                    <dd
                      class={help.helpSubHeader}
                      key={subItem.id}
                      onClick={() => {
                        mangoPopup({
                          type: "MangoHelpDetail",
                          title: subItem.title,
                          props: {
                            content: subItem.content,
                          },
                        }).open();
                      }}
                    >
                      {subItem.title}
                    </dd>
                  ))}
                </dl>
              </li>
            ))
          ) : (
            <Empty type="mangoNoData" top={50} />
          )}
        </ul>
      </div>
    );
  },
});
