const components = {
 
  // 代理浮窗
  AgentFloatPopup: ()=>import(/* webpackChunkName: "pc.agentfloatpopup" */ 'blocks/AgentPopup/src'),
 
  // 娱乐城
  Casino: ()=>import(/* webpackChunkName: "h5.casino" */ 'blocks/MobileGame/Casino/src'),
 
  // 首页浮窗
  FloatPopup: ()=>import(/* webpackChunkName: "pc.floatpopup" */ 'blocks/FloatPopup/src'),
 
  // WEB 维护界面
  Maintain: ()=>import(/* webpackChunkName: "pc.maintain" */ 'blocks/Maintain/src'),
 
  // 首页浮窗
  MobileAgentFloatPopup: ()=>import(/* webpackChunkName: "h5.mobileagentfloatpopup" */ 'blocks/MobileAgentPopup/src'),
 
  // Banner 轮播图
  MobileBanner: ()=>import(/* webpackChunkName: "h5.mobilebanner" */ 'blocks/MobileBanner/src'),
 
  // logo、客服卡片
  MobileCustomerCard: ()=>import(/* webpackChunkName: "h5.mobilecustomercard" */ 'blocks/MobileCustomerCard/src'),
 
  // App下载
  MobileDownload: ()=>import(/* webpackChunkName: "h5.mobiledownload" */ 'blocks/MobileDownload/src'),
 
  // H5顶部下载栏
  MobileDownloadHeader: ()=>import(/* webpackChunkName: "h5.mobiledownloadheader" */ 'blocks/MobileDownloadHeader/src'),
 
  // 首页浮窗
  MobileFloatPopup: ()=>import(/* webpackChunkName: "h5.mobilefloatpopup" */ 'blocks/MobileFloatPopup/src'),
 
  // 游戏列表
  MobileGame: ()=>import(/* webpackChunkName: "h5.mobilegame" */ 'blocks/MobileGame/src'),
 
  // H5场景1
  MobileGameScenes1: ()=>import(/* webpackChunkName: "h5.mobilegamescenes1" */ 'blocks/MobileGame/Scence1/src'),
 
  // H5场景2
  MobileGameScenes2: ()=>import(/* webpackChunkName: "h5.mobilegamescenes2" */ 'blocks/MobileGame/Scence2/src'),
 
  // H5场景3
  MobileGameScenes3: ()=>import(/* webpackChunkName: "h5.mobilegamescenes3" */ 'blocks/MobileGame/Scence3/src'),
 
  // H5场景4
  MobileGameScenes4: ()=>import(/* webpackChunkName: "h5.mobilegamescenes4" */ 'blocks/MobileGame/Scence4/src'),
 
  // H5场景5
  MobileGameScenes5: ()=>import(/* webpackChunkName: "h5.mobilegamescenes5" */ 'blocks/MobileGame/Scence5/src'),
 
  // H5场景6
  MobileGameScenes6: ()=>import(/* webpackChunkName: "h5.mobilegamescenes6" */ 'blocks/MobileGame/Scence6/src'),
 
  // H5场景7
  MobileGameScenes7: ()=>import(/* webpackChunkName: "h5.mobilegamescenes7" */ 'blocks/MobileGame/Scence7/src'),
 
  // H5场景8
  MobileGameScenes8: ()=>import(/* webpackChunkName: "h5.mobilegamescenes8" */ 'blocks/MobileGame/Scence8/src'),
 
  // H5场景9
  MobileGameScenes9: ()=>import(/* webpackChunkName: "h5.mobilegamescenes9" */ 'blocks/MobileGame/Scence9/src'),
 
  // 115定制悬浮窗
  MobileInt115Popup: ()=>import(/* webpackChunkName: "h5.mobileint115popup" */ 'blocks/MobileInt115Popup/src'),
 
  // H5 维护界面
  MobileMaintain: ()=>import(/* webpackChunkName: "h5.mobilemaintain" */ 'blocks/MobileMaintain/src'),
 
  // 广播
  MobileNotice: ()=>import(/* webpackChunkName: "h5.mobilenotice" */ 'blocks/MobileNotice/src'),
 
  // H5Seo文本
  MobileSeoText: ()=>import(/* webpackChunkName: "pc.mobileseotext" */ 'blocks/MobileSeoText/src'),
 
  // 赞助场景1
  MobileSponer1: ()=>import(/* webpackChunkName: "h5.mobilesponer1" */ 'blocks/MobileSponer/sponer1/src'),
 
  // H5体育导航
  MobileSportNav: ()=>import(/* webpackChunkName: "pc.mobilesportnav" */ 'blocks/MobileSportNav/src'),
 
  // 底部导航
  MobileTabBar: ()=>import(/* webpackChunkName: "h5.mobiletabbar" */ 'blocks/MobileTabBar/src'),
 
  // 用户信息卡片
  MobileUserCardJbb: ()=>import(/* webpackChunkName: "h5.mobileusercardjbb" */ 'blocks/MobileUserCard/src'),
 
  // 用户信息卡片2
  MobileUserCardJbb1: ()=>import(/* webpackChunkName: "h5.mobileusercardjbb1" */ 'blocks/MobileUserCard1/src'),
 
  // 用户信息卡片2
  MobileUserCardJbb2: ()=>import(/* webpackChunkName: "h5.mobileusercardjbb2" */ 'blocks/MobileUserCard2/src'),
 
  // 财神到红包雨
  RedEnvelope: ()=>import(/* webpackChunkName: "public.redenvelope" */ 'blocks/RedEnvelope/src'),
 
  // jbbWEB个人中心组件
  AccountCenterJbb: ()=>import(/* webpackChunkName: "pc.accountcenterjbb" */ 'templates/jbb/Web/Account/AccountCenter'),
 
  // jbbWeb-活动列表
  Activity115: ()=>import(/* webpackChunkName: "pc.activity115" */ 'templates/int115/Web/Activity'),
 
  // 活动详情模版
  ActivityDetail: ()=>import(/* webpackChunkName: "h5.activitydetail" */ 'templates/jbb/Mobile/Activity/ActivityDetail'),
 
  // 活动详情
  ActivityDetail115: ()=>import(/* webpackChunkName: "pc.activitydetail115" */ 'templates/int115/Web/Activity/Detail'),
 
  // 活动详情
  ActivityDetailTplJbb: ()=>import(/* webpackChunkName: "pc.activitydetailtpljbb" */ 'templates/jbb/Web/Activity/ActiveDetails'),
 
  // jbbWeb-活动列表
  ActivityJbb: ()=>import(/* webpackChunkName: "pc.activityjbb" */ 'templates/jbb/Web/Activity/Activity'),
 
  // 优惠活动模版
  ActivityTpl: ()=>import(/* webpackChunkName: "h5.activitytpl" */ 'templates/jbb/Mobile/Activity/ActivityTpl'),
 
  // PC代理中心
  Agent: ()=>import(/* webpackChunkName: "pc.agent" */ 'templates/agent/Web'),
 
  // 落地页
  AgentDownload: ()=>import(/* webpackChunkName: "pc.agentdownload" */ 'templates/agent/Web/DownloadPA'),
 
  // 落地页
  AgentEntryLogin: ()=>import(/* webpackChunkName: "pc.agententrylogin" */ 'templates/agent/Web/AgentLogin'),
 
  // jbbWeb-公告详情
  AnnoucementJbb: ()=>import(/* webpackChunkName: "pc.annoucementjbb" */ 'templates/jbb/Web/Account/Announcement'),
 
  // jbbWeb-Banner组件
  Banner108: ()=>import(/* webpackChunkName: "pc.banner108" */ 'templates/int108/Web/Banner'),
 
  // int123-Banner
  BannerInt123: ()=>import(/* webpackChunkName: "pc.bannerint123" */ 'templates/int123/Web/Home/components/Banner'),
 
  // jbbWeb-Banner组件
  BannerJbb: ()=>import(/* webpackChunkName: "pc.bannerjbb" */ 'templates/jbb/Web/Banner'),
 
  // int123棋牌游戏二级界面
  BoardGameInt123: ()=>import(/* webpackChunkName: "pc.boardgameint123" */ 'templates/int123/Web/Game/components/BoardGame'),
 
  // 气泡canvas背景
  canvasBubbling: ()=>import(/* webpackChunkName: "pc.canvasbubbling" */ 'templates/int123/Web/Game/components/CanvasBubbling'),
 
  // jbbWeb卡片管理
  CardManagerWebJbb: ()=>import(/* webpackChunkName: "pc.cardmanagerwebjbb" */ 'templates/jbb/Web/Account/WalletManagement/CardManager'),
 
  // jbbWeb会员资料设置
  DataSettingJbb: ()=>import(/* webpackChunkName: "pc.datasettingjbb" */ 'templates/jbb/Web/Account/DataSetting'),
 
  // jbbWeb-Download
  Download108: ()=>import(/* webpackChunkName: "pc.download108" */ 'templates/int108/Web/Download'),
 
  // jbbWeb-app下载
  DownloadAppJbb: ()=>import(/* webpackChunkName: "pc.downloadappjbb" */ 'templates/jbb/Web/DownloadApp'),
 
  // jbbWeb-Download
  DownloadJbb: ()=>import(/* webpackChunkName: "pc.downloadjbb" */ 'templates/jbb/Web/Download'),
 
  // int123-电子游艺
  EgameInt123: ()=>import(/* webpackChunkName: "pc.egameint123" */ 'templates/int123/Web/Game/components/Egame'),
 
  // 电子邮件地址
  EmailAddressJbb: ()=>import(/* webpackChunkName: "h5.emailaddressjbb" */ 'templates/jbb/Mobile/Account/EmailAddress'),
 
  // jbb登录注册
  Entry108: ()=>import(/* webpackChunkName: "pc.entry108" */ 'templates/int108/Web/Entry'),
 
  // jbb登录注册
  EntryJbb: ()=>import(/* webpackChunkName: "pc.entryjbb" */ 'templates/jbb/Web/Entry'),
 
  // int123-电竞二级页
  EsportInt123: ()=>import(/* webpackChunkName: "pc.esportint123" */ 'templates/int123/Web/Game/components/Esport'),
 
  // 赛事公告
  EventAnnouncementJbb: ()=>import(/* webpackChunkName: "h5.eventannouncementjbb" */ 'templates/jbb/Mobile/Account/EventAnnouncement'),
 
  // 比赛结果
  EventResultJbb: ()=>import(/* webpackChunkName: "h5.eventresultjbb" */ 'templates/jbb/Mobile/Account/EventResult'),
 
  // 赛事设置
  EventSettingsJbb: ()=>import(/* webpackChunkName: "h5.eventsettingsjbb" */ 'templates/jbb/Mobile/Account/eventSettings'),
 
  // jbbWeb会员反馈
  FeedbackWebJbb: ()=>import(/* webpackChunkName: "pc.feedbackwebjbb" */ 'templates/jbb/Web/Account/FeedbackWeb'),
 
  // 108首页悬浮功能
  FloatSlider108: ()=>import(/* webpackChunkName: "pc.floatslider108" */ 'templates/int108/Web/FloatSlider'),
 
  // int123-左右两侧浮窗
  FloatSliderInt123: ()=>import(/* webpackChunkName: "pc.floatsliderint123" */ 'templates/int123/Web/FloatSlider'),
 
  // jbb首页悬浮功能
  FloatSliderJbb: ()=>import(/* webpackChunkName: "pc.floatsliderjbb" */ 'templates/jbb/Web/FloatSlider'),
 
  // jbbWeb-Footer
  Footer108: ()=>import(/* webpackChunkName: "pc.footer108" */ 'templates/int108/Web/Footer'),
 
  // int123-footer
  FooterHomeInt123: ()=>import(/* webpackChunkName: "pc.footerhomeint123" */ 'templates/int123/Web/Home/components/FooterHome'),
 
  // int115Web_Footer
  FooterInt115: ()=>import(/* webpackChunkName: "pc.footerint115" */ 'templates/int115/Web/Footer'),
 
  // jbbWeb-Footer
  FooterJbb: ()=>import(/* webpackChunkName: "pc.footerjbb" */ 'templates/jbb/Web/Footer'),
 
  // int123-Footer
  FooterOtherInt123: ()=>import(/* webpackChunkName: "pc.footerotherint123" */ 'templates/int123/Web/Footer'),
 
  // jbbWeb资金明细
  FundDetailsJbb: ()=>import(/* webpackChunkName: "pc.funddetailsjbb" */ 'templates/jbb/Web/Account/FundDetails'),
 
  // 资金明细
  FundingdetailsDetailJbb: ()=>import(/* webpackChunkName: "h5.fundingdetailsdetailjbb" */ 'templates/jbb/Mobile/Account/fundingdetailsDetail'),
 
  // int123-游戏二级界面
  GameInt123: ()=>import(/* webpackChunkName: "pc.gameint123" */ 'templates/int123/Web/Game'),
 
  // jbbWeb-游戏列表
  GameListJbb: ()=>import(/* webpackChunkName: "pc.gamelistjbb" */ 'templates/jbb/Web/Game/GameList'),
 
  // jbbWeb-子游戏列表
  GameSubJbb: ()=>import(/* webpackChunkName: "pc.gamesubjbb" */ 'templates/jbb/Web/Game/GameSub'),
 
  // 赞助列表
  H5SponsorJbb: ()=>import(/* webpackChunkName: "h5.h5sponsorjbb" */ 'templates/jbb/Mobile/Sponsor/SponsorList'),
 
  // jbbWeb-Header
  Header108: ()=>import(/* webpackChunkName: "pc.header108" */ 'templates/int108/Web/Header'),
 
  // int123Web_header
  HeaderInt123: ()=>import(/* webpackChunkName: "pc.headerint123" */ 'templates/int123/Web/Header'),
 
  // jbbWeb-Header
  HeaderJbb: ()=>import(/* webpackChunkName: "pc.headerjbb" */ 'templates/jbb/Web/Header'),
 
  // 帮助中心
  HelpTpl: ()=>import(/* webpackChunkName: "pc.helptpl" */ 'templates/jbb/Web/help'),
 
  // jbbWeb首页
  Home108: ()=>import(/* webpackChunkName: "pc.home108" */ 'templates/int108/Web/Home'),
 
  // int115Web_home
  HomeInt115: ()=>import(/* webpackChunkName: "pc.homeint115" */ 'templates/int115/Web/Home'),
 
  // int123Web_home
  HomeInt123: ()=>import(/* webpackChunkName: "pc.homeint123" */ 'templates/int123/Web/Home'),
 
  // int128Web_home
  HomeInt128: ()=>import(/* webpackChunkName: "pc.homeint128" */ 'templates/int120/Mobile/Home'),
 
  // jbbWeb首页
  HomeJbb: ()=>import(/* webpackChunkName: "pc.homejbb" */ 'templates/jbb/Web/Home'),
 
  // int123-捕鱼游戏
  HuntersInt123: ()=>import(/* webpackChunkName: "pc.huntersint123" */ 'templates/int123/Web/Game/components/Hunter'),
 
  // 255国际化-账号登录首页
  i18nAccount: ()=>import(/* webpackChunkName: "h5.i18naccount" */ 'templates/jbb/Mobile/Entry'),
 
  // 个人中心
  i18nAccountCenter: ()=>import(/* webpackChunkName: "h5.i18naccountcenter" */ 'templates/jbb/Mobile/Account'),
 
  // 意见反馈
  i18nAccountCenterFeedback: ()=>import(/* webpackChunkName: "h5.i18naccountcenterfeedback" */ 'templates/jbb/Mobile/Account/Feedback'),
 
  // 货币
  i18nCurrency: ()=>import(/* webpackChunkName: "h5.i18ncurrency" */ 'templates/jbb/Mobile/Account/Currency'),
 
  // 资金明细
  i18nFundingdetails: ()=>import(/* webpackChunkName: "h5.i18nfundingdetails" */ 'templates/jbb/Mobile/Account/Fundingdetails'),
 
  // 255国际化-游戏中心
  i18nGameCenter: ()=>import(/* webpackChunkName: "h5.i18ngamecenter" */ 'templates/jbb/Mobile/GameCenter'),
 
  // 赚钱任务
  i18nJbbMobileTask: ()=>import(/* webpackChunkName: "h5.i18njbbmobiletask" */ 'templates/jbb/Mobile/Account/Task'),
 
  // 绑定银行卡
  i18nMobileBindCardTpl: ()=>import(/* webpackChunkName: "h5.i18nmobilebindcardtpl" */ 'templates/jbb/Mobile/MobileBank/MobileBindCard'),
 
  // 绑定虚拟账户
  i18nMobileBindUsdtTpl: ()=>import(/* webpackChunkName: "h5.i18nmobilebindusdttpl" */ 'templates/jbb/Mobile/MobileBank/MobileBindUsdt'),
 
  // VIP规则
  i18nMobileVipRule: ()=>import(/* webpackChunkName: "h5.i18nmobileviprule" */ 'templates/jbb/Mobile/Vip/VipRule'),
 
  // 我的消息
  i18nMyMessage: ()=>import(/* webpackChunkName: "h5.i18nmymessage" */ 'templates/jbb/Mobile/Account/MyMessage'),
 
  // 钱包
  i18nWallet: ()=>import(/* webpackChunkName: "h5.i18nwallet" */ 'templates/jbb/Mobile/Account/Wallet'),
 
  // 福利中心
  i18nWelfarecenter: ()=>import(/* webpackChunkName: "h5.i18nwelfarecenter" */ 'templates/jbb/Mobile/Account/Welfarecenter'),
 
  // jbbWeb-IconList
  IconListJbb: ()=>import(/* webpackChunkName: "pc.iconlistjbb" */ 'templates/jbb/Web/IconList'),
 
  // 115web-Game
  int115Game: ()=>import(/* webpackChunkName: "pc.int115game" */ 'templates/int115/Web/game'),
 
  // 115web-header
  int115Header: ()=>import(/* webpackChunkName: "pc.int115header" */ 'templates/int115/Web/header'),
 
  // 语言设置
  LangSetting: ()=>import(/* webpackChunkName: "h5.langsetting" */ 'templates/jbb/Mobile/LangSetting'),
 
  // jbbWeb-Live
  Live: ()=>import(/* webpackChunkName: "pc.live" */ 'templates/jbb/Web/Live'),
 
  // jbbWeb-Live
  Live108: ()=>import(/* webpackChunkName: "pc.live108" */ 'templates/int108/Web/Live'),
 
  // int123-真人视讯
  LiveInt123: ()=>import(/* webpackChunkName: "pc.liveint123" */ 'templates/int123/Web/Game/components/Live'),
 
  // int115Web_login
  LoginInt115: ()=>import(/* webpackChunkName: "pc.loginint115" */ 'templates/int115/Web/Entry/Login'),
 
  // jbbWeb-登录
  LoginJbb: ()=>import(/* webpackChunkName: "pc.loginjbb" */ 'templates/jbb/Web/Login'),
 
  // int123-彩票二级页
  LotteryInt123: ()=>import(/* webpackChunkName: "pc.lotteryint123" */ 'templates/int123/Web/Game/components/Lottery'),
 
  // MobileMangoFooter
  Mango10Footer: ()=>import(/* webpackChunkName: "h5.mango10footer" */ 'templates/mango10/Web/Footer'),
 
  // MobileMangoFooter
  Mango10FooterMobile: ()=>import(/* webpackChunkName: "h5.mango10footermobile" */ 'templates/mango10/Mobile/Footer'),
 
  // mangoWeb头部
  Mango10Header: ()=>import(/* webpackChunkName: "pc.mango10header" */ 'templates/mango10/Web/Header'),
 
  // mango10H5头部
  Mango10HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango10headermobile" */ 'templates/mango10/Mobile/Header'),
 
  // mangoWeb首页
  Mango10Home: ()=>import(/* webpackChunkName: "pc.mango10home" */ 'templates/mango10/Web/Home'),
 
  // mangoH5底部导航
  Mango10TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango10tabbarmobile" */ 'templates/mango10/Mobile/TabBar'),
 
  // mangoWeb底部
  Mango11Footer: ()=>import(/* webpackChunkName: "pc.mango11footer" */ 'templates/mango11/Web/Fotter'),
 
  // MobileMangoFooter
  Mango11FooterMobile: ()=>import(/* webpackChunkName: "h5.mango11footermobile" */ 'templates/mango11/Mobile/Footer'),
 
  // mangoWeb头部
  Mango11Header: ()=>import(/* webpackChunkName: "pc.mango11header" */ 'templates/mango11/Web/Header'),
 
  // mango11H5头部
  Mango11HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango11headermobile" */ 'templates/mango11/Mobile/Header'),
 
  // mango11H5底部导航
  Mango11TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango11tabbarmobile" */ 'templates/mango11/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango12FooterMobile: ()=>import(/* webpackChunkName: "h5.mango12footermobile" */ 'templates/mango12/Mobile/Footer'),
 
  // mangoH5游戏二级
  Mango12GameListMobile: ()=>import(/* webpackChunkName: "pc.mango12gamelistmobile" */ 'templates/mango12/Mobile/GameList'),
 
  // mango12H5头部
  Mango12HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango12headermobile" */ 'templates/mango12/Mobile/Header'),
 
  // MobileMango13Footer
  Mango13FooterMobile: ()=>import(/* webpackChunkName: "h5.mango13footermobile" */ 'templates/mango13/Mobile/Footer'),
 
  // mango13H5游戏二级
  Mango13GameListMobile: ()=>import(/* webpackChunkName: "pc.mango13gamelistmobile" */ 'templates/mango13/Mobile/GameList'),
 
  // mango13H5头部
  Mango13HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango13headermobile" */ 'templates/mango13/Mobile/Header'),
 
  // mango14H5Footer
  Mango14FooterMobile: ()=>import(/* webpackChunkName: "pc.mango14footermobile" */ 'templates/mango14/Mobile/Footer'),
 
  // mango14H5头部
  Mango14HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango14headermobile" */ 'templates/mango14/Mobile/Header'),
 
  // mango14H5底部导航
  Mango14TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango14tabbarmobile" */ 'templates/mango14/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango15FooterMobile: ()=>import(/* webpackChunkName: "h5.mango15footermobile" */ 'templates/mango15/Mobile/Footer'),
 
  // mango10H5头部
  Mango15HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango15headermobile" */ 'templates/mango15/Mobile/Header'),
 
  // mangoH5底部导航
  Mango15TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango15tabbarmobile" */ 'templates/mango15/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango16FooterMobile: ()=>import(/* webpackChunkName: "h5.mango16footermobile" */ 'templates/mango16/Mobile/Footer'),
 
  // mangoH5头部
  Mango16HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango16headermobile" */ 'templates/mango16/Mobile/Header'),
 
  // mangoH5底部导航
  Mango16TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango16tabbarmobile" */ 'templates/mango16/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango17FooterMobile: ()=>import(/* webpackChunkName: "h5.mango17footermobile" */ 'templates/mango17/Mobile/Footer'),
 
  // mango17H5头部
  Mango17HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango17headermobile" */ 'templates/mango17/Mobile/Header'),
 
  // mango17H5底部导航
  Mango17TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango17tabbarmobile" */ 'templates/mango17/Mobile/TabBar'),
 
  // mango1Web底部
  Mango1Footer: ()=>import(/* webpackChunkName: "pc.mango1footer" */ 'templates/mango1/Web/Fotter'),
 
  // Mobile1MangoFooter
  Mango1FooterMobile: ()=>import(/* webpackChunkName: "h5.mango1footermobile" */ 'templates/mango1/Mobile/Footer'),
 
  // mango1Web头部
  Mango1Header: ()=>import(/* webpackChunkName: "pc.mango1header" */ 'templates/mango1/Web/Header'),
 
  // mangoH5头部
  Mango1HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango1headermobile" */ 'templates/mango1/Mobile/Header'),
 
  // mango1Web首页
  Mango1Home: ()=>import(/* webpackChunkName: "pc.mango1home" */ 'templates/mango1/Web/Home'),
 
  // mango1H5底部导航
  Mango1TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango1tabbarmobile" */ 'templates/mango1/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango20FooterMobile: ()=>import(/* webpackChunkName: "h5.mango20footermobile" */ 'templates/mango20/Mobile/Footer'),
 
  // mangoH5头部
  Mango20HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango20headermobile" */ 'templates/mango20/Mobile/Header'),
 
  // mangoH5底部导航
  Mango20TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango20tabbarmobile" */ 'templates/mango20/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango21FooterMobile: ()=>import(/* webpackChunkName: "h5.mango21footermobile" */ 'templates/mango21/Mobile/Footer'),
 
  // mangoH5头部
  Mango21HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango21headermobile" */ 'templates/mango21/Mobile/Header'),
 
  // mangoH5底部导航
  Mango21TabBarMobile: ()=>import(/* webpackChunkName: "h5.mango21tabbarmobile" */ 'templates/mango21/Mobile/TabBar'),
 
  // MobileMango22Footer
  Mango22FooterMobile: ()=>import(/* webpackChunkName: "h5.mango22footermobile" */ 'templates/mango22/Mobile/Footer'),
 
  // mango22H5头部
  Mango22HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango22headermobile" */ 'templates/mango22/Mobile/Header'),
 
  // mango22H5底部导航
  Mango22TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango22tabbarmobile" */ 'templates/mango22/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango23Footer: ()=>import(/* webpackChunkName: "h5.mango23footer" */ 'templates/mango23/Web/Footer'),
 
  // MobileMangoFooter
  Mango23FooterMobile: ()=>import(/* webpackChunkName: "h5.mango23footermobile" */ 'templates/mango23/Mobile/Footer'),
 
  // mangoWeb头部
  Mango23Header: ()=>import(/* webpackChunkName: "pc.mango23header" */ 'templates/mango23/Web/Header'),
 
  // mangoH5头部
  Mango23HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango23headermobile" */ 'templates/mango23/Mobile/Header'),
 
  // mangoWeb首页
  Mango23Home: ()=>import(/* webpackChunkName: "pc.mango23home" */ 'templates/mango23/Web/Home'),
 
  // mangoH5底部导航
  Mango23TabBar2Mobile: ()=>import(/* webpackChunkName: "pc.mango23tabbar2mobile" */ 'templates/mango23/Mobile/TabBar2'),
 
  // mangoH5底部导航
  Mango23TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango23tabbarmobile" */ 'templates/mango23/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango25FooterMobile: ()=>import(/* webpackChunkName: "h5.mango25footermobile" */ 'templates/mango25/Mobile/Footer'),
 
  // mango25H5头部
  Mango25HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango25headermobile" */ 'templates/mango25/Mobile/Header'),
 
  // mango25H5底部导航
  Mango25TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango25tabbarmobile" */ 'templates/mango25/Mobile/TabBar'),
 
  // mango27H5Footer
  Mango27FooterMobile: ()=>import(/* webpackChunkName: "h5.mango27footermobile" */ 'templates/mango27/Mobile/Footer'),
 
  // mango27H5头部
  Mango27HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango27headermobile" */ 'templates/mango27/Mobile/Header'),
 
  // mango27H5底部导航
  Mango27TabBarMobile: ()=>import(/* webpackChunkName: "h5.mango27tabbarmobile" */ 'templates/mango27/Mobile/TabBar'),
 
  // mangoWeb活动
  Mango28Activity: ()=>import(/* webpackChunkName: "pc.mango28activity" */ 'templates/mango28/Web/Activity'),
 
  // mangoWeb底部
  Mango28Footer: ()=>import(/* webpackChunkName: "pc.mango28footer" */ 'templates/mango28/Web/Footer'),
 
  // mango28H5Footer
  Mango28FooterMobile: ()=>import(/* webpackChunkName: "h5.mango28footermobile" */ 'templates/mango28/Mobile/Footer'),
 
  // mangoWeb头部
  Mango28Header: ()=>import(/* webpackChunkName: "pc.mango28header" */ 'templates/mango28/Web/Header'),
 
  // mango28H5头部
  Mango28HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango28headermobile" */ 'templates/mango28/Mobile/Header'),
 
  // mangoWeb首页
  Mango28Home: ()=>import(/* webpackChunkName: "pc.mango28home" */ 'templates/mango28/Web/Home'),
 
  // mango28H5底部导航
  Mango28TabBarMobile: ()=>import(/* webpackChunkName: "h5.mango28tabbarmobile" */ 'templates/mango28/Mobile/TabBar'),
 
  // mangoWeb底部
  Mango29Footer: ()=>import(/* webpackChunkName: "pc.mango29footer" */ 'templates/mango29/Web/Fotter'),
 
  // MobileMangoFooter29
  Mango29FooterMobile: ()=>import(/* webpackChunkName: "h5.mango29footermobile" */ 'templates/mango29/Mobile/Footer'),
 
  // mangoWeb头部
  Mango29Header: ()=>import(/* webpackChunkName: "pc.mango29header" */ 'templates/mango29/Web/Header'),
 
  // mangoH5头部
  Mango29HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango29headermobile" */ 'templates/mango29/Mobile/Header'),
 
  // mangoWeb首页
  Mango29Home: ()=>import(/* webpackChunkName: "pc.mango29home" */ 'templates/mango29/Web/Home'),
 
  // mangoH5底部导航
  Mango29TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango29tabbarmobile" */ 'templates/mango29/Mobile/TabBar'),
 
  // 活动详情模版
  Mango2ActivityDetail: ()=>import(/* webpackChunkName: "pc.mango2activitydetail" */ 'templates/mango2/Web/Activity/ActivityDetail'),
 
  // Mobile2MangoFooter
  Mango2FooterMobile: ()=>import(/* webpackChunkName: "h5.mango2footermobile" */ 'templates/mango2/Mobile/Footer'),
 
  // mangoH5头部
  Mango2Header2Mobile: ()=>import(/* webpackChunkName: "h5.mango2header2mobile" */ 'templates/mango3/Mobile/Header'),
 
  // mangoH5头部
  Mango2HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango2headermobile" */ 'templates/mango2/Mobile/Header'),
 
  // mangoH5游戏二级
  Mango2MobileGameList: ()=>import(/* webpackChunkName: "pc.mango2mobilegamelist" */ 'templates/mango2/Mobile/GameList'),
 
  // mangoH5游戏二级
  Mango2MobileGameList2: ()=>import(/* webpackChunkName: "pc.mango2mobilegamelist2" */ 'templates/mango3/Mobile/GameList'),
 
  // mangoH5底部导航
  Mango2TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango2tabbarmobile" */ 'templates/mango2/Mobile/TabBar'),
 
  // 个人设置
  Mango2UserSetupMobile: ()=>import(/* webpackChunkName: "h5.mango2usersetupmobile" */ 'templates/mango2/Mobile/Account/UserSetup'),
 
  // mangoWebVip
  Mango2Vip: ()=>import(/* webpackChunkName: "pc.mango2vip" */ 'templates/mango2/Web/Vip'),
 
  // mango2Web活动
  Mango2WebActivity: ()=>import(/* webpackChunkName: "pc.mango2webactivity" */ 'templates/mango2/Web/Activity'),
 
  // Mango2WebAgent代理
  Mango2WebAgent: ()=>import(/* webpackChunkName: "h5.mango2webagent" */ 'templates/mango2/Web/Agent'),
 
  // Mango2WebAgent2代理
  Mango2WebAgent2: ()=>import(/* webpackChunkName: "pc.mango2webagent2" */ 'templates/mango3/Web/Agent'),
 
  // mangoWeb底部
  Mango2WebFooter: ()=>import(/* webpackChunkName: "pc.mango2webfooter" */ 'templates/mango2/Web/Footer'),
 
  // mangoWeb游戏二级
  Mango2WebGameList: ()=>import(/* webpackChunkName: "pc.mango2webgamelist" */ 'templates/mango2/Web/GameList'),
 
  // mangoWeb游戏二级
  Mango2WebGameList2: ()=>import(/* webpackChunkName: "pc.mango2webgamelist2" */ 'templates/mango3/Web/GameList'),
 
  // mangoWeb头部
  Mango2WebHeader: ()=>import(/* webpackChunkName: "pc.mango2webheader" */ 'templates/mango2/Web/Header'),
 
  // mangoWeb头部
  Mango2WebHeader2: ()=>import(/* webpackChunkName: "pc.mango2webheader2" */ 'templates/mango3/Web/Header'),
 
  // mangoWeb首页
  Mango2WebHome: ()=>import(/* webpackChunkName: "pc.mango2webhome" */ 'templates/mango2/Web/Home'),
 
  // mango2Web首页2
  Mango2WebHome2: ()=>import(/* webpackChunkName: "pc.mango2webhome2" */ 'templates/mango3/Web/Home'),
 
  // mangoWeb活动
  Mango30Activity: ()=>import(/* webpackChunkName: "pc.mango30activity" */ 'templates/mango30/Web/Activity'),
 
  // mangoWeb底部
  Mango30Footer: ()=>import(/* webpackChunkName: "pc.mango30footer" */ 'templates/mango30/Web/Footer'),
 
  // mango30H5Footer
  Mango30FooterMobile: ()=>import(/* webpackChunkName: "h5.mango30footermobile" */ 'templates/mango30/Mobile/Footer'),
 
  // mangoWeb头部
  Mango30Header: ()=>import(/* webpackChunkName: "pc.mango30header" */ 'templates/mango30/Web/Header'),
 
  // mango30H5头部
  Mango30HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango30headermobile" */ 'templates/mango30/Mobile/Header'),
 
  // mangoWeb首页
  Mango30Home: ()=>import(/* webpackChunkName: "pc.mango30home" */ 'templates/mango30/Web/Home'),
 
  // mango30H5底部导航
  mango30TabBarMobile: ()=>import(/* webpackChunkName: "h5.mango30tabbarmobile" */ 'templates/mango30/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango31FooterMobile: ()=>import(/* webpackChunkName: "h5.mango31footermobile" */ 'templates/mango31/Mobile/Footer'),
 
  // mangoH5头部
  Mango31HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango31headermobile" */ 'templates/mango31/Mobile/Header'),
 
  // mangoH5底部导航
  Mango31TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango31tabbarmobile" */ 'templates/mango31/Mobile/TabBar'),
 
  // mango32H5Footer
  Mango32FooterMobile: ()=>import(/* webpackChunkName: "h5.mango32footermobile" */ 'templates/mango32/Mobile/Footer'),
 
  // mango32H5头部
  Mango32HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango32headermobile" */ 'templates/mango32/Mobile/Header'),
 
  // mango32H5底部导航
  Mango32TabBarMobile: ()=>import(/* webpackChunkName: "h5.mango32tabbarmobile" */ 'templates/mango32/Mobile/TabBar'),
 
  // Mobile3MangoFooter
  Mango3FooterMobile: ()=>import(/* webpackChunkName: "h5.mango3footermobile" */ 'templates/mango3/Mobile/Footer'),
 
  // mangoH5底部导航
  Mango3TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango3tabbarmobile" */ 'templates/mango3/Mobile/TabBar'),
 
  // mangoWeb活动
  Mango4Activity: ()=>import(/* webpackChunkName: "pc.mango4activity" */ 'templates/mango4/Web/Activity/Activity'),
 
  // mangoWeb下载
  Mango4DownLoad: ()=>import(/* webpackChunkName: "pc.mango4download" */ 'templates/mango4/Web/DownLoad'),
 
  // mangoWeb底部
  Mango4Footer: ()=>import(/* webpackChunkName: "pc.mango4footer" */ 'templates/mango4/Web/Fotter'),
 
  // MobileMango4Footer
  Mango4FooterMobile: ()=>import(/* webpackChunkName: "h5.mango4footermobile" */ 'templates/mango4/Mobile/Footer'),
 
  // mangoWeb头部
  Mango4Header: ()=>import(/* webpackChunkName: "pc.mango4header" */ 'templates/mango4/Web/Header'),
 
  // mangoH5头部
  Mango4HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango4headermobile" */ 'templates/mango4/Mobile/Header'),
 
  // mangoWeb首页
  Mango4Home: ()=>import(/* webpackChunkName: "pc.mango4home" */ 'templates/mango4/Web/Home'),
 
  // mangoH5游戏二级
  Mango4MobileGameList2: ()=>import(/* webpackChunkName: "pc.mango4mobilegamelist2" */ 'templates/mango4/Mobile/GameList'),
 
  // mangoH5底部导航
  Mango4TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango4tabbarmobile" */ 'templates/mango4/Mobile/TabBar'),
 
  // mangoWeb个人中心
  Mango4User: ()=>import(/* webpackChunkName: "pc.mango4user" */ 'templates/mango4/Web/User'),
 
  // 个人设置
  Mango4UserSetupMobile: ()=>import(/* webpackChunkName: "h5.mango4usersetupmobile" */ 'templates/mango4/Mobile/Account/UserSetup'),
 
  // mango代理
  Mango4WebAgent: ()=>import(/* webpackChunkName: "h5.mango4webagent" */ 'templates/mango4/Web/AgentMango'),
 
  // mangoWeb游戏二级
  Mango4WebGameList: ()=>import(/* webpackChunkName: "pc.mango4webgamelist" */ 'templates/mango4/Web/Home/GameList'),
 
  // VIP中心
  Mango4WebVip: ()=>import(/* webpackChunkName: "pc.mango4webvip" */ 'templates/mango4/Web/VipCenter'),
 
  // mangoWeb底部
  Mango5Footer: ()=>import(/* webpackChunkName: "pc.mango5footer" */ 'templates/mango5/Web/Fotter'),
 
  // MobileMangoFooter
  Mango5FooterMobile: ()=>import(/* webpackChunkName: "h5.mango5footermobile" */ 'templates/mango5/Mobile/Footer'),
 
  // mangoWeb头部
  Mango5Header: ()=>import(/* webpackChunkName: "pc.mango5header" */ 'templates/mango5/Web/Header'),
 
  // mangoH5头部
  Mango5HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango5headermobile" */ 'templates/mango5/Mobile/Header'),
 
  // mangoWeb首页
  Mango5Home: ()=>import(/* webpackChunkName: "pc.mango5home" */ 'templates/mango5/Web/Home'),
 
  // mangoH5底部导航
  Mango5TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango5tabbarmobile" */ 'templates/mango5/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango6FooterMobile: ()=>import(/* webpackChunkName: "h5.mango6footermobile" */ 'templates/mango6/Mobile/Footer'),
 
  // mangoH5头部
  Mango6HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango6headermobile" */ 'templates/mango6/Mobile/Header'),
 
  // mangoH5游戏二级
  Mango6MobileGameList: ()=>import(/* webpackChunkName: "pc.mango6mobilegamelist" */ 'templates/mango6/Mobile/GameList'),
 
  // mangoH5未登录弹窗
  Mango6NotLoginMobile: ()=>import(/* webpackChunkName: "h5.mango6notloginmobile" */ 'templates/mango6/Mobile/NotLogin'),
 
  // mangoH5底部导航
  Mango6TabBarMobile: ()=>import(/* webpackChunkName: "h5.mango6tabbarmobile" */ 'templates/mango6/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango7FooterMobile: ()=>import(/* webpackChunkName: "h5.mango7footermobile" */ 'templates/mango7/Mobile/Footer'),
 
  // mangoH5头部
  Mango7HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango7headermobile" */ 'templates/mango7/Mobile/Header'),
 
  // mangoH5底部导航
  Mango7TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango7tabbarmobile" */ 'templates/mango7/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango8FooterMobile: ()=>import(/* webpackChunkName: "h5.mango8footermobile" */ 'templates/mango8/Mobile/Footer'),
 
  // mangoH5底部导航
  Mango8MenuBarMobile: ()=>import(/* webpackChunkName: "pc.mango8menubarmobile" */ 'templates/mango8/Mobile/MenuBar'),
 
  // mangoH5底部导航
  Mango8TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango8tabbarmobile" */ 'templates/mango8/Mobile/TabBar'),
 
  // MobileMangoFooter
  Mango9FooterMobile: ()=>import(/* webpackChunkName: "h5.mango9footermobile" */ 'templates/mango9/Mobile/Footer'),
 
  // mango9H5头部
  Mango9HeaderMobile: ()=>import(/* webpackChunkName: "h5.mango9headermobile" */ 'templates/mango9/Mobile/Header'),
 
  // mangoH5底部导航
  Mango9TabBarMobile: ()=>import(/* webpackChunkName: "pc.mango9tabbarmobile" */ 'templates/mango9/Mobile/TabBar'),
 
  // mangoWeb活动
  MangoActivity: ()=>import(/* webpackChunkName: "pc.mangoactivity" */ 'templates/mango/Web/Activity/Activity'),
 
  // 活动详情模版
  MangoActivityDetail: ()=>import(/* webpackChunkName: "pc.mangoactivitydetail" */ 'templates/mango/Web/Activity/ActivityDetail'),
 
  // DeleteAccount
  MangoDeleteAccount: ()=>import(/* webpackChunkName: "pc.mangodeleteaccount" */ 'templates/mango/Web/DeleteAccount'),
 
  // mangoWeb下载
  MangoDownLoad: ()=>import(/* webpackChunkName: "pc.mangodownload" */ 'templates/mango/Web/DownLoad'),
 
  // 意见反馈
  MangoFeedbackMobile: ()=>import(/* webpackChunkName: "h5.mangofeedbackmobile" */ 'templates/mango/Mobile/Feedback'),
 
  // mangoWeb底部
  MangoFooter: ()=>import(/* webpackChunkName: "pc.mangofooter" */ 'templates/mango/Web/Fotter'),
 
  // MobileMangoFooter
  MangoFooterMobile: ()=>import(/* webpackChunkName: "h5.mangofootermobile" */ 'templates/mango/Mobile/Footer'),
 
  // mangoWeb游戏二级
  MangoGameList: ()=>import(/* webpackChunkName: "pc.mangogamelist" */ 'templates/mango/Web/GameList'),
 
  // mangoH5游戏二级
  MangoGameListMobile: ()=>import(/* webpackChunkName: "pc.mangogamelistmobile" */ 'templates/mango/Mobile/GameList'),
 
  // mangoWeb游戏二级
  MangoGameWrap: ()=>import(/* webpackChunkName: "pc.mangogamewrap" */ 'templates/mango/Web/GameWrap'),
 
  // MangoH53Vip
  MangoH53Vip: ()=>import(/* webpackChunkName: "pc.mangoh53vip" */ 'templates/mango3/Mobile/Vip'),
 
  // MangoH53Vip
  MangoH56Vip: ()=>import(/* webpackChunkName: "pc.mangoh56vip" */ 'templates/mango6/Mobile/Vip'),
 
  // mangoWeb头部
  MangoHeader: ()=>import(/* webpackChunkName: "pc.mangoheader" */ 'templates/mango/Web/Header'),
 
  // mangoH5头部
  MangoHeaderMobile: ()=>import(/* webpackChunkName: "h5.mangoheadermobile" */ 'templates/mango/Mobile/Header'),
 
  // 帮助中心
  MangoHelpCenterMobile: ()=>import(/* webpackChunkName: "h5.mangohelpcentermobile" */ 'templates/mango/Mobile/HelpCenter'),
 
  // mangoWeb首页
  MangoHome: ()=>import(/* webpackChunkName: "pc.mangohome" */ 'templates/mango/Web/Home'),
 
  // ios下载页
  MangoIosDownloadMobile: ()=>import(/* webpackChunkName: "h5.mangoiosdownloadmobile" */ 'templates/mango/Mobile/IosDownload'),
 
  // MangoMobileGamesIframe
  MangoMobileGamesIframe: ()=>import(/* webpackChunkName: "h5.mangomobilegamesiframe" */ 'templates/mango/Mobile/Games'),
 
  // mango兑换码
  MangoRedemptionCode: ()=>import(/* webpackChunkName: "h5.mangoredemptioncode" */ 'templates/mango/Web/PromotionCode'),
 
  // mangoH5兑换码
  MangoRedemptionCodeMobile: ()=>import(/* webpackChunkName: "h5.mangoredemptioncodemobile" */ 'templates/mango/Mobile/PromotionCode'),
 
  // mangoWeb底部导航
  MangoTabBar: ()=>import(/* webpackChunkName: "pc.mangotabbar" */ 'templates/mango/Web/TabBar'),
 
  // mangoH5底部导航
  MangoTabBarMobile: ()=>import(/* webpackChunkName: "pc.mangotabbarmobile" */ 'templates/mango/Mobile/TabBar'),
 
  // mango1H5底部导航
  MangoTestTabBarMobile: ()=>import(/* webpackChunkName: "pc.mangotesttabbarmobile" */ 'templates/mangoTest/Mobile/TabBar'),
 
  // 个人设置
  MangoUserSetupMobile: ()=>import(/* webpackChunkName: "h5.mangousersetupmobile" */ 'templates/mango/Mobile/Account/UserSetup'),
 
  // VIP中心
  MangoVip: ()=>import(/* webpackChunkName: "pc.mangovip" */ 'templates/mango/Web/VipCenter'),
 
  // MangoWeb3Vip
  MangoWeb3Vip: ()=>import(/* webpackChunkName: "pc.mangoweb3vip" */ 'templates/mango3/Web/Vip'),
 
  // MangoWebGames
  MangoWebGames: ()=>import(/* webpackChunkName: "pc.mangowebgames" */ 'templates/mango/Web/Games'),
 
  // mangoWeb头部
  MangoWebHeader130: ()=>import(/* webpackChunkName: "pc.mangowebheader130" */ 'templates/mango3/Web/Header2'),
 
  // jbbWeb会员福利
  MangoWelfarecenter: ()=>import(/* webpackChunkName: "pc.mangowelfarecenter" */ 'templates/mango/Web/WelfareCenter'),
 
  // jbbWeb会员教程详情
  MemberCourseDetailJbb: ()=>import(/* webpackChunkName: "pc.membercoursedetailjbb" */ 'templates/jbb/Web/Account/MemberCourseDetail'),
 
  // jbbWeb会员教程
  MemberCourseJbb: ()=>import(/* webpackChunkName: "pc.membercoursejbb" */ 'templates/jbb/Web/Account/MemberCourse'),
 
  // jbbWeb会员信息
  MessageJbb: ()=>import(/* webpackChunkName: "pc.messagejbb" */ 'templates/jbb/Web/Account/Message'),
 
  // mango首页
  Mobile23MangoHome: ()=>import(/* webpackChunkName: "h5.mobile23mangohome" */ 'templates/mango23/Mobile/Home'),
 
  // 首页
  Mobile23MangoMenu: ()=>import(/* webpackChunkName: "h5.mobile23mangomenu" */ 'templates/mango23/Mobile/Menu'),
 
  // 关于我们
  MobileAboutUsJbb: ()=>import(/* webpackChunkName: "h5.mobileaboutusjbb" */ 'templates/jbb/Mobile/MobileAboutUs'),
 
  // 关于我们
  MobileAboutUsTpl: ()=>import(/* webpackChunkName: "h5.mobileaboutustpl" */ 'templates/agent/Mobile/MobileAboutUs'),
 
  // H5代理中心
  MobileAgent: ()=>import(/* webpackChunkName: "h5.mobileagent" */ 'templates/agent/Mobile'),
 
  // H5代理中心首页2
  MobileAgent2: ()=>import(/* webpackChunkName: "h5.mobileagent2" */ 'templates/agent/Mobile/HomeMa2'),
 
  // 代理登录
  MobileAgentLogin: ()=>import(/* webpackChunkName: "h5.mobileagentlogin" */ 'templates/agent/Mobile/LoginMA'),
 
  // 申请提现
  MobileApplyWithdrawalJbb: ()=>import(/* webpackChunkName: "h5.mobileapplywithdrawaljbb" */ 'templates/jbb/Mobile/MobileWithdraw/MobileApplyWithdrawal'),
 
  // 账户管理
  MobileBankJbb: ()=>import(/* webpackChunkName: "h5.mobilebankjbb" */ 'templates/jbb/Mobile/MobileBank/MobileBankList'),
 
  // 账户管理
  MobileBankTpl: ()=>import(/* webpackChunkName: "h5.mobilebanktpl" */ 'templates/agent/Mobile/MobileBank/MobileBankList'),
 
  // 注单记录
  MobileBetJbb: ()=>import(/* webpackChunkName: "h5.mobilebetjbb" */ 'templates/jbb/Mobile/MobileBet'),
 
  // 关于我们
  MobileBettingRulesJbb: ()=>import(/* webpackChunkName: "h5.mobilebettingrulesjbb" */ 'templates/jbb/Mobile/MobileBettingRules'),
 
  // 绑定银行卡
  MobileBindCardTpl: ()=>import(/* webpackChunkName: "h5.mobilebindcardtpl" */ 'templates/agent/Mobile/MobileBank/MobileBindCard'),
 
  // 绑定虚拟账户
  MobileBindUsdtTpl: ()=>import(/* webpackChunkName: "h5.mobilebindusdttpl" */ 'templates/agent/Mobile/MobileBank/MobileBindUsdt'),
 
  // 联系我们
  MobileContactUsJbb: ()=>import(/* webpackChunkName: "h5.mobilecontactusjbb" */ 'templates/jbb/Mobile/MobileContactUs'),
 
  // H5客服
  MobileCustomer: ()=>import(/* webpackChunkName: "h5.mobilecustomer" */ 'templates/jbb/Mobile/MobileCustomer'),
 
  // 255国际化-账号登录首页
  MobileEntry115: ()=>import(/* webpackChunkName: "h5.mobileentry115" */ 'templates/int115/Mobile/Entry'),
 
  // H5电子游戏二级页
  MobileGameList: ()=>import(/* webpackChunkName: "h5.mobilegamelist" */ 'templates/jbb/Mobile/MobileGame/GameList'),
 
  // jbbWeb-子游戏列表
  MobileGameSubJbb: ()=>import(/* webpackChunkName: "pc.mobilegamesubjbb" */ 'templates/jbb/Mobile/MobileGame/GameSub'),
 
  // H5金币商城
  MobileGoldShopping: ()=>import(/* webpackChunkName: "h5.mobilegoldshopping" */ 'templates/jbb/Mobile/MobileGoldShopping'),
 
  // 盘口教程
  MobileHandicapJbb: ()=>import(/* webpackChunkName: "h5.mobilehandicapjbb" */ 'templates/jbb/Mobile/MobileHandicap'),
 
  // 帮助中心详情
  MobileHelpCenterDetailJbb: ()=>import(/* webpackChunkName: "h5.mobilehelpcenterdetailjbb" */ 'templates/jbb/Mobile/MobileHelpCenter/HelpCenterDetail'),
 
  // 帮助中心
  MobileHelpCenterJbb: ()=>import(/* webpackChunkName: "h5.mobilehelpcenterjbb" */ 'templates/jbb/Mobile/MobileHelpCenter'),
 
  // 埋点测试
  MobileHelpCenterTest: ()=>import(/* webpackChunkName: "h5.mobilehelpcentertest" */ 'templates/jbb/Mobile/MobileHelpCenter/Test'),
 
  // H5 Ios下载页
  MobileIosDownload: ()=>import(/* webpackChunkName: "h5.mobileiosdownload" */ 'templates/jbb/Mobile/MobileIosDownload'),
 
  // 商务合作
  MobileJoinus: ()=>import(/* webpackChunkName: "h5.mobilejoinus" */ 'templates/jbb/Mobile/MobileNewJoinus'),
 
  // 加入我们
  MobileJoinusJbb: ()=>import(/* webpackChunkName: "h5.mobilejoinusjbb" */ 'templates/jbb/Mobile/MobileJoinus'),
 
  // MobileKgtest5Live
  MobileKgtest5Live: ()=>import(/* webpackChunkName: "h5.mobilekgtest5live" */ 'templates/jbb/Mobile/Live'),
 
  // mango代理
  MobileLamBaseAgent: ()=>import(/* webpackChunkName: "h5.mobilelambaseagent" */ 'templates/lamAgent/Mobile/Home'),
 
  // mango首页
  MobileMango10Home: ()=>import(/* webpackChunkName: "h5.mobilemango10home" */ 'templates/mango10/Mobile/Home'),
 
  // 首页
  MobileMango10Menu: ()=>import(/* webpackChunkName: "h5.mobilemango10menu" */ 'templates/mango10/Mobile/Menu'),
 
  // mango11首页
  MobileMango11Home: ()=>import(/* webpackChunkName: "h5.mobilemango11home" */ 'templates/mango11/Mobile/home'),
 
  // 首页
  MobileMango11Menu: ()=>import(/* webpackChunkName: "h5.mobilemango11menu" */ 'templates/mango11/Mobile/Menu'),
 
  // 优惠活动模版
  MobileMango12Activity: ()=>import(/* webpackChunkName: "h5.mobilemango12activity" */ 'templates/mango12/Mobile/Activity/ActivityTpl'),
 
  // mango首页
  MobileMango12Home: ()=>import(/* webpackChunkName: "h5.mobilemango12home" */ 'templates/mango12/Mobile/Home'),
 
  // 首页
  MobileMango12Menu: ()=>import(/* webpackChunkName: "h5.mobilemango12menu" */ 'templates/mango12/Mobile/Menu'),
 
  // 彩金
  MobileMango12Winnings: ()=>import(/* webpackChunkName: "h5.mobilemango12winnings" */ 'templates/mango12/Mobile/Winnings'),
 
  // mango13首页
  MobileMango13Home: ()=>import(/* webpackChunkName: "h5.mobilemango13home" */ 'templates/mango13/Mobile/Home'),
 
  // mango14首页
  MobileMango14Home: ()=>import(/* webpackChunkName: "h5.mobilemango14home" */ 'templates/mango14/Mobile/Home'),
 
  // mango首页
  MobileMango15Home: ()=>import(/* webpackChunkName: "h5.mobilemango15home" */ 'templates/mango15/Mobile/Home'),
 
  // 首页
  MobileMango15Menu: ()=>import(/* webpackChunkName: "h5.mobilemango15menu" */ 'templates/mango15/Mobile/Menu'),
 
  // mango首页
  MobileMango16Home: ()=>import(/* webpackChunkName: "h5.mobilemango16home" */ 'templates/mango16/Mobile/Home'),
 
  // mango首页
  MobileMango17Home: ()=>import(/* webpackChunkName: "h5.mobilemango17home" */ 'templates/mango17/Mobile/Home'),
 
  // mango1首页
  MobileMango1Home: ()=>import(/* webpackChunkName: "h5.mobilemango1home" */ 'templates/mango1/Mobile/Home'),
 
  // 首页
  MobileMango1Menu: ()=>import(/* webpackChunkName: "h5.mobilemango1menu" */ 'templates/mango1/Mobile/Menu'),
 
  // mango首页
  MobileMango20Home: ()=>import(/* webpackChunkName: "h5.mobilemango20home" */ 'templates/mango20/Mobile/Home'),
 
  // mango首页
  MobileMango21Home: ()=>import(/* webpackChunkName: "h5.mobilemango21home" */ 'templates/mango21/Mobile/Home'),
 
  // 首页
  MobileMango21Menu: ()=>import(/* webpackChunkName: "h5.mobilemango21menu" */ 'templates/mango21/Mobile/Menu'),
 
  // mango首页
  MobileMango22Home: ()=>import(/* webpackChunkName: "h5.mobilemango22home" */ 'templates/mango22/Mobile/Home'),
 
  // mango首页
  MobileMango25Home: ()=>import(/* webpackChunkName: "h5.mobilemango25home" */ 'templates/mango25/Mobile/Home'),
 
  // mango27首页
  MobileMango27Home: ()=>import(/* webpackChunkName: "h5.mobilemango27home" */ 'templates/mango27/Mobile/Home'),
 
  // mango27首页
  MobileMango28Home: ()=>import(/* webpackChunkName: "h5.mobilemango28home" */ 'templates/mango28/Mobile/Home'),
 
  // mango首页
  MobileMango29Home: ()=>import(/* webpackChunkName: "h5.mobilemango29home" */ 'templates/mango29/Mobile/Home'),
 
  // 优惠活动模版
  MobileMango2Activity: ()=>import(/* webpackChunkName: "h5.mobilemango2activity" */ 'templates/mango2/Mobile/Activity/ActivityTpl'),
 
  // mango首页
  MobileMango2Home: ()=>import(/* webpackChunkName: "h5.mobilemango2home" */ 'templates/mango2/Mobile/Home'),
 
  // 首页
  MobileMango2Menu: ()=>import(/* webpackChunkName: "h5.mobilemango2menu" */ 'templates/mango2/Mobile/Menu'),
 
  // 首页
  MobileMango2Menu2: ()=>import(/* webpackChunkName: "h5.mobilemango2menu2" */ 'templates/mango3/Mobile/Menu'),
 
  // mango代理
  MobileMango2NewAgent: ()=>import(/* webpackChunkName: "h5.mobilemango2newagent" */ 'templates/mango2/Mobile/AgentMango'),
 
  // mango代理
  MobileMango2NewAgent2: ()=>import(/* webpackChunkName: "h5.mobilemango2newagent2" */ 'templates/mango3/Mobile/AgentMango'),
 
  // 滚动到顶部
  MobileMango2ScrollTop: ()=>import(/* webpackChunkName: "h5.mobilemango2scrolltop" */ 'templates/mango2/Mobile/ScrrollTop'),
 
  // 精彩任务
  MobileMango2Task: ()=>import(/* webpackChunkName: "h5.mobilemango2task" */ 'templates/mango2/Mobile/Task'),
 
  // VIP中心
  MobileMango2Vip: ()=>import(/* webpackChunkName: "h5.mobilemango2vip" */ 'templates/mango2/Mobile/Vip/VipCenter'),
 
  // 福利中心
  MobileMango2Welfarecenter: ()=>import(/* webpackChunkName: "h5.mobilemango2welfarecenter" */ 'templates/mango2/Mobile/WelfareCenter'),
 
  // 彩金
  MobileMango2Winnings: ()=>import(/* webpackChunkName: "h5.mobilemango2winnings" */ 'templates/mango2/Mobile/Winnings'),
 
  // mango30首页
  MobileMango30Home: ()=>import(/* webpackChunkName: "h5.mobilemango30home" */ 'templates/mango30/Mobile/Home'),
 
  // mango首页
  MobileMango31Home: ()=>import(/* webpackChunkName: "h5.mobilemango31home" */ 'templates/mango31/Mobile/Home'),
 
  // mango32首页
  MobileMango32Home: ()=>import(/* webpackChunkName: "h5.mobilemango32home" */ 'templates/mango32/Mobile/Home'),
 
  // mango首页
  MobileMango3Home: ()=>import(/* webpackChunkName: "h5.mobilemango3home" */ 'templates/mango3/Mobile/Home'),
 
  // 优惠活动模版
  MobileMango4Activity: ()=>import(/* webpackChunkName: "h5.mobilemango4activity" */ 'templates/mango4/Mobile/Activity/ActivityTpl'),
 
  // 活动详情模版
  MobileMango4ActivityDetail: ()=>import(/* webpackChunkName: "h5.mobilemango4activitydetail" */ 'templates/mango4/Mobile/Activity/ActivityDetail'),
 
  // H5 Ios下载页
  MobileMango4Download: ()=>import(/* webpackChunkName: "h5.mobilemango4download" */ 'templates/mango4/Mobile/Download'),
 
  // mango首页
  MobileMango4Home: ()=>import(/* webpackChunkName: "h5.mobilemango4home" */ 'templates/mango4/Mobile/Home'),
 
  // 首页
  MobileMango4Menu: ()=>import(/* webpackChunkName: "h5.mobilemango4menu" */ 'templates/mango4/Mobile/Menu'),
 
  // mango代理
  MobileMango4NewAgent: ()=>import(/* webpackChunkName: "h5.mobilemango4newagent" */ 'templates/mango4/Mobile/AgentMango'),
 
  // 精彩任务
  MobileMango4Task: ()=>import(/* webpackChunkName: "h5.mobilemango4task" */ 'templates/mango4/Mobile/Task'),
 
  // VIP中心
  MobileMango4Vip: ()=>import(/* webpackChunkName: "h5.mobilemango4vip" */ 'templates/mango4/Mobile/Vip/VipCenter'),
 
  // 福利中心
  MobileMango4Welfarecenter: ()=>import(/* webpackChunkName: "h5.mobilemango4welfarecenter" */ 'templates/mango4/Mobile/WelfareCenter'),
 
  // 彩金
  MobileMango4Winnings: ()=>import(/* webpackChunkName: "h5.mobilemango4winnings" */ 'templates/mango4/Mobile/Winnings'),
 
  // mango首页
  MobileMango5Home: ()=>import(/* webpackChunkName: "h5.mobilemango5home" */ 'templates/mango5/Mobile/Home'),
 
  // 首页
  MobileMango5Menu: ()=>import(/* webpackChunkName: "h5.mobilemango5menu" */ 'templates/mango5/Mobile/Menu'),
 
  // mango首页
  MobileMango6Home: ()=>import(/* webpackChunkName: "h5.mobilemango6home" */ 'templates/mango6/Mobile/Home'),
 
  // 首页
  MobileMango6Menu: ()=>import(/* webpackChunkName: "h5.mobilemango6menu" */ 'templates/mango6/Mobile/Menu'),
 
  // 彩金
  MobileMango6Winnings: ()=>import(/* webpackChunkName: "h5.mobilemango6winnings" */ 'templates/mango6/Mobile/Winnings'),
 
  // mango首页
  MobileMango7Home: ()=>import(/* webpackChunkName: "h5.mobilemango7home" */ 'templates/mango7/Mobile/Home'),
 
  // mango首页
  MobileMango8Home: ()=>import(/* webpackChunkName: "h5.mobilemango8home" */ 'templates/mango8/Mobile/Home'),
 
  // 首页
  MobileMango8Menu: ()=>import(/* webpackChunkName: "h5.mobilemango8menu" */ 'templates/mango8/Mobile/Menu'),
 
  // mango首页
  MobileMango9Home: ()=>import(/* webpackChunkName: "h5.mobilemango9home" */ 'templates/mango9/Mobile/Home'),
 
  // 首页
  MobileMango9Menu: ()=>import(/* webpackChunkName: "h5.mobilemango9menu" */ 'templates/mango9/Mobile/Menu'),
 
  // 优惠活动模版
  MobileMangoActivity: ()=>import(/* webpackChunkName: "h5.mobilemangoactivity" */ 'templates/mango/Mobile/Activity/ActivityTpl'),
 
  // 活动详情模版
  MobileMangoActivityDetail: ()=>import(/* webpackChunkName: "h5.mobilemangoactivitydetail" */ 'templates/mango/Mobile/Activity/ActivityDetail'),
 
  // 优惠活动详情模版
  MobileMangoActivityDetailTpl: ()=>import(/* webpackChunkName: "h5.mobilemangoactivitydetailtpl" */ 'templates/mango/Mobile/Activity/Detail'),
 
  // mango代理
  MobileMangoAgent: ()=>import(/* webpackChunkName: "h5.mobilemangoagent" */ 'templates/mango/Mobile/Agent'),
 
  // 注单记录
  MobileMangoBet: ()=>import(/* webpackChunkName: "h5.mobilemangobet" */ 'templates/mango/Mobile/Bet'),
 
  // MobileDeleteAccount
  MobileMangoDeleteAccount: ()=>import(/* webpackChunkName: "h5.mobilemangodeleteaccount" */ 'templates/mango/Mobile/DeleteAccount'),
 
  // H5 Ios下载页
  MobileMangoDownload: ()=>import(/* webpackChunkName: "h5.mobilemangodownload" */ 'templates/mango/Mobile/Download'),
 
  // 账号登录
  MobileMangoEntry: ()=>import(/* webpackChunkName: "h5.mobilemangoentry" */ 'templates/mango/Mobile/Entry'),
 
  // 资金明细
  MobileMangoFundingdetails: ()=>import(/* webpackChunkName: "h5.mobilemangofundingdetails" */ 'templates/mango/Mobile/Account/Fundingdetails'),
 
  // 资金明细
  MobileMangoFundingdetailsDetail: ()=>import(/* webpackChunkName: "h5.mobilemangofundingdetailsdetail" */ 'templates/mango/Mobile/Account/fundingdetailsDetail'),
 
  // mangoWeb游戏二级
  MobileMangoGameWrap: ()=>import(/* webpackChunkName: "pc.mobilemangogamewrap" */ 'templates/mango/Mobile/GameWrap'),
 
  // mango首页
  MobileMangoHome: ()=>import(/* webpackChunkName: "h5.mobilemangohome" */ 'templates/mango/Mobile/Home'),
 
  // mango代理
  MobileMangoNewAgent: ()=>import(/* webpackChunkName: "h5.mobilemangonewagent" */ 'templates/mango/Mobile/AgentMango'),
 
  // 精彩任务
  MobileMangoTask: ()=>import(/* webpackChunkName: "h5.mobilemangotask" */ 'templates/mango/Mobile/Task'),
 
  // 时区设置
  MobileMangoTimezoneSetting: ()=>import(/* webpackChunkName: "h5.mobilemangotimezonesetting" */ 'templates/mango/Mobile/Account/TimezoneSetting'),
 
  // VIP中心
  MobileMangoVip: ()=>import(/* webpackChunkName: "h5.mobilemangovip" */ 'templates/mango/Mobile/Vip/VipCenter'),
 
  // 钱包
  MobileMangoWallet: ()=>import(/* webpackChunkName: "h5.mobilemangowallet" */ 'templates/mango/Mobile/Wallet'),
 
  // 福利中心
  MobileMangoWelfarecenter: ()=>import(/* webpackChunkName: "h5.mobilemangowelfarecenter" */ 'templates/mango/Mobile/WelfareCenter'),
 
  // 彩金
  MobileMangoWinnings: ()=>import(/* webpackChunkName: "h5.mobilemangowinnings" */ 'templates/mango/Mobile/Winnings'),
 
  // 申请提现成功
  MobileMangoWithdrawalApplySuccess: ()=>import(/* webpackChunkName: "h5.mobilemangowithdrawalapplysuccess" */ 'templates/mango/Mobile/Withdraw/MobileWithdrawalApplySuccess'),
 
  // h5-提现前打码量
  MobileMangoWithdrawalCode: ()=>import(/* webpackChunkName: "h5.mobilemangowithdrawalcode" */ 'templates/mango/Mobile/Withdraw/MobileWithdrawalCode'),
 
  // 开启提现短信验证
  MobileMangoWithdrawalPhone: ()=>import(/* webpackChunkName: "h5.mobilemangowithdrawalphone" */ 'templates/mango/Mobile/Account/WithdrawalPhone'),
 
  // H5提现教程
  MobileMangoWithdrTutorial: ()=>import(/* webpackChunkName: "h5.mobilemangowithdrtutorial" */ 'templates/mango/Mobile/Withdraw/MobileWithdrTutorial'),
 
  // 帮助中心
  MobileMembershipTutorial: ()=>import(/* webpackChunkName: "h5.mobilemembershiptutorial" */ 'templates/jbb/Mobile/Account/MobileMembershipTutorial'),
 
  // H5充值详情
  MobileRechargeInfo: ()=>import(/* webpackChunkName: "h5.mobilerechargeinfo" */ 'templates/jbb/Mobile/MobileRecharge/MobileRechargeInfo'),
 
  // H5充值详情
  MobileRechargeInfoMongo: ()=>import(/* webpackChunkName: "h5.mobilerechargeinfomongo" */ 'templates/mango/Mobile/MobileRecharge/MobileRechargeInfo'),
 
  // H5充值
  MobileRechargeLayout109Mango: ()=>import(/* webpackChunkName: "h5.mobilerechargelayout109mango" */ 'templates/mango/Mobile/MobileRecharge/MobileRechargeLam109'),
 
  // H5充值
  MobileRechargeLayoutJbb: ()=>import(/* webpackChunkName: "h5.mobilerechargelayoutjbb" */ 'templates/jbb/Mobile/MobileRecharge/MobileRecharge'),
 
  // H5充值
  MobileRechargeLayoutMango: ()=>import(/* webpackChunkName: "h5.mobilerechargelayoutmango" */ 'templates/mango/Mobile/MobileRecharge/MobileRecharge'),
 
  // H5在线充值
  MobileRechargeOnline: ()=>import(/* webpackChunkName: "h5.mobilerechargeonline" */ 'templates/jbb/Mobile/MobileRecharge/MobileRechargeOnline'),
 
  // H5在线充值
  MobileRechargeOnlineMango: ()=>import(/* webpackChunkName: "h5.mobilerechargeonlinemango" */ 'templates/mango/Mobile/MobileRecharge/MobileRechargeOnline'),
 
  // H5充值教程
  MobileRechargeTutorialJbb: ()=>import(/* webpackChunkName: "h5.mobilerechargetutorialjbb" */ 'templates/jbb/Mobile/MobileRecharge/MobileRechargeTutorial'),
 
  // H5充值教程
  MobileRechargeTutorialMango: ()=>import(/* webpackChunkName: "h5.mobilerechargetutorialmango" */ 'templates/mango/Mobile/MobileRecharge/MobileRechargeTutorial'),
 
  // 红包雨活动
  MobileRedEnvelope: ()=>import(/* webpackChunkName: "h5.mobileredenvelope" */ 'templates/jbb/Mobile/Activity/RedEnvelopeRain'),
 
  // 修改密码和手机号
  MobileSetTpl: ()=>import(/* webpackChunkName: "h5.mobilesettpl" */ 'templates/jbb/Mobile/MobileSet'),
 
  // 模拟教程
  MobileSimulateJbb: ()=>import(/* webpackChunkName: "h5.mobilesimulatejbb" */ 'templates/jbb/Mobile/MobileSimulate'),
 
  // MobileTurntableEntryJbb
  MobileTurntableEntryJbb: ()=>import(/* webpackChunkName: "pc.mobileturntableentryjbb" */ 'templates/jbb/Mobile/TurntableEntry'),
 
  // h5转盘
  MobileTurntableJbb: ()=>import(/* webpackChunkName: "h5.mobileturntablejbb" */ 'templates/jbb/Mobile/Turntable'),
 
  // h5转盘
  MobileTurntableMango: ()=>import(/* webpackChunkName: "h5.mobileturntablemango" */ 'templates/mango/Mobile/TurntablePath'),
 
  // 最新版本
  MobileVersionJbb: ()=>import(/* webpackChunkName: "h5.mobileversionjbb" */ 'templates/jbb/Mobile/MobileVersion'),
 
  // VIP详情
  MobileVipDetailsJbb: ()=>import(/* webpackChunkName: "h5.mobilevipdetailsjbb" */ 'templates/jbb/Mobile/Vip/VipDetails'),
 
  // VIP中心
  MobileVipJbb: ()=>import(/* webpackChunkName: "h5.mobilevipjbb" */ 'templates/jbb/Mobile/Vip/VipCenter'),
 
  // 申请提现成功
  MobileWithdrawalApplySuccessJbb: ()=>import(/* webpackChunkName: "h5.mobilewithdrawalapplysuccessjbb" */ 'templates/jbb/Mobile/MobileWithdraw/MobileWithdrawalApplySuccess'),
 
  // h5-提现前打码量
  MobileWithdrawalCodeJbb: ()=>import(/* webpackChunkName: "h5.mobilewithdrawalcodejbb" */ 'templates/jbb/Mobile/MobileWithdraw/MobileWithdrawalCode'),
 
  // 提现验证
  MobileWithdrawalVerificationJbb: ()=>import(/* webpackChunkName: "h5.mobilewithdrawalverificationjbb" */ 'templates/jbb/Mobile/MobileWithdraw/MobileWithdrawalVerification'),
 
  // H5提现教程
  MobileWithdrTutorialJbb: ()=>import(/* webpackChunkName: "h5.mobilewithdrtutorialjbb" */ 'templates/jbb/Mobile/MobileWithdraw/MobileWithdrTutorial'),
 
  // 修改登录
  ModifyLoginJbb: ()=>import(/* webpackChunkName: "h5.modifyloginjbb" */ 'templates/jbb/Mobile/Account/ModifyLogin'),
 
  // jbbWeb投注明细
  NoteDetailsJbb: ()=>import(/* webpackChunkName: "pc.notedetailsjbb" */ 'templates/jbb/Web/Account/NoteDetails'),
 
  // int123-Notice
  NoticeInt123: ()=>import(/* webpackChunkName: "pc.noticeint123" */ 'templates/int123/Web/Home/components/Notice'),
 
  // 个人信息
  PersonalInfoJbb: ()=>import(/* webpackChunkName: "h5.personalinfojbb" */ 'templates/jbb/Mobile/Account/PersonalInfo'),
 
  // 红包雨活动
  RedEnvelopeRain: ()=>import(/* webpackChunkName: "pc.redenveloperain" */ 'templates/jbb/Web/Activity/RedEnvelopeRain'),
 
  // int115Web_register
  RegisterInt115: ()=>import(/* webpackChunkName: "pc.registerint115" */ 'templates/int115/Web/Entry/Register'),
 
  // 密保手机号
  SecretPhoneJbb: ()=>import(/* webpackChunkName: "h5.secretphonejbb" */ 'templates/jbb/Mobile/Account/SecretPhone'),
 
  // 安全提醒
  SecurityReminderJbb: ()=>import(/* webpackChunkName: "h5.securityreminderjbb" */ 'templates/jbb/Mobile/Account/SecurityReminder'),
 
  // Seo文本
  SeoText: ()=>import(/* webpackChunkName: "pc.seotext" */ 'templates/jbb/Web/SeoText'),
 
  // jbbWeb-优质服务
  ServeList108: ()=>import(/* webpackChunkName: "pc.servelist108" */ 'templates/int108/Web/ServeList'),
 
  // jbbWeb-优质服务
  ServeListJbb: ()=>import(/* webpackChunkName: "pc.servelistjbb" */ 'templates/jbb/Web/ServeList'),
 
  // 设置头像
  SetAvatarJbb: ()=>import(/* webpackChunkName: "h5.setavatarjbb" */ 'templates/jbb/Mobile/Account/SetAvatar'),
 
  // 设置姓名
  SetNameJbb: ()=>import(/* webpackChunkName: "h5.setnamejbb" */ 'templates/jbb/Mobile/Account/SetName'),
 
  // 设置昵称
  SetNickJbb: ()=>import(/* webpackChunkName: "h5.setnickjbb" */ 'templates/jbb/Mobile/Account/SetNick'),
 
  // 通用赞助模板-115
  sponerDeatil115: ()=>import(/* webpackChunkName: "h5.sponerdeatil115" */ 'templates/int115/Mobile/MobileSponerDetail'),
 
  // 通用赞助模板-jbb
  sponerDeatilJbb: ()=>import(/* webpackChunkName: "pc.sponerdeatiljbb" */ 'templates/jbb/Web/SponerDetail'),
 
  // 通用赞助模板-web115
  sponerDeatilWeb115: ()=>import(/* webpackChunkName: "pc.sponerdeatilweb115" */ 'templates/int115/Web/sponer'),
 
  // jbbWeb-赞助
  Sponsor108: ()=>import(/* webpackChunkName: "pc.sponsor108" */ 'templates/int108/Web/Sponsor'),
 
  // jbbWeb-赞助
  SponsorJbb: ()=>import(/* webpackChunkName: "pc.sponsorjbb" */ 'templates/jbb/Web/Sponsor'),
 
  // int123-体育
  SportInt123: ()=>import(/* webpackChunkName: "pc.sportint123" */ 'templates/int123/Web/Game/components/Sport'),
 
  // 体育
  SportJbb: ()=>import(/* webpackChunkName: "h5.sportjbb" */ 'templates/jbb/Mobile/Sport'),
 
  // jbbWeb-精彩任务
  TaskJbb: ()=>import(/* webpackChunkName: "pc.taskjbb" */ 'templates/jbb/Web/Account/Task'),
 
  // 时区设置
  TimezoneSetting: ()=>import(/* webpackChunkName: "h5.timezonesetting" */ 'templates/jbb/Mobile/TimezoneSetting'),
 
  // Web108-TopGame
  TopGame108: ()=>import(/* webpackChunkName: "pc.topgame108" */ 'templates/int108/Web/TopGame'),
 
  // 个人设置
  UserSetupJbb: ()=>import(/* webpackChunkName: "h5.usersetupjbb" */ 'templates/jbb/Mobile/Account/UserSetup'),
 
  // jbbWeb-VipCenter
  VipCenterJbb: ()=>import(/* webpackChunkName: "pc.vipcenterjbb" */ 'templates/jbb/Web/VipCenter'),
 
  // 255场景-我的成长值
  VipGrowthJbb: ()=>import(/* webpackChunkName: "h5.vipgrowthjbb" */ 'templates/jbb/Mobile/Vip/VipGrowth'),
 
  // jbbWeb钱包管理
  WalletManagementWebJbb: ()=>import(/* webpackChunkName: "pc.walletmanagementwebjbb" */ 'templates/jbb/Web/Account/WalletManagement'),
 
  // lamMango代理
  WebLamBaseAgent: ()=>import(/* webpackChunkName: "pc.weblambaseagent" */ 'templates/lamAgent/Web/Home'),
 
  // mango代理
  WebMangoAgent: ()=>import(/* webpackChunkName: "h5.webmangoagent" */ 'templates/mango/Web/Agent'),
 
  // mango代理
  WebMangoAgentNew: ()=>import(/* webpackChunkName: "h5.webmangoagentnew" */ 'templates/mango/Web/AgentMango'),
 
  // jbb注册
  WebRegister108: ()=>import(/* webpackChunkName: "pc.webregister108" */ 'templates/int108/Web/Entry/Register'),
 
  // jbb注册
  WebRegisterJbb: ()=>import(/* webpackChunkName: "pc.webregisterjbb" */ 'templates/jbb/Web/Entry/Register'),
 
  // WebTurntableEntryJbb
  WebTurntableEntryJbb: ()=>import(/* webpackChunkName: "pc.webturntableentryjbb" */ 'templates/jbb/Web/TurntableEntry'),
 
  // WebTurntableJbb
  WebTurntableJbb: ()=>import(/* webpackChunkName: "pc.webturntablejbb" */ 'templates/jbb/Web/Turntable'),
 
  // WebTurntableMango
  WebTurntableMango: ()=>import(/* webpackChunkName: "pc.webturntablemango" */ 'templates/mango/Web/TurntablePath'),
 
  // jbbWeb会员福利
  WelfareCenterJbb: ()=>import(/* webpackChunkName: "pc.welfarecenterjbb" */ 'templates/jbb/Web/Account/WelfareCenter'),
 
  // 取款密码设置
  WithdrawalPassSettingsJbb: ()=>import(/* webpackChunkName: "h5.withdrawalpasssettingsjbb" */ 'templates/jbb/Mobile/Account/WithdrawalPassSettings'),
 
  // 开启提现短信验证
  WithdrawalPhoneJbb: ()=>import(/* webpackChunkName: "h5.withdrawalphonejbb" */ 'templates/jbb/Mobile/Account/WithdrawalPhone'),
 
  // 电子邮件地址
  ZaloAddressJbb: ()=>import(/* webpackChunkName: "h5.zaloaddressjbb" */ 'templates/jbb/Mobile/Account/ZaloAddress'),
 
};
export default components;
