import { createVNode, render } from "vue";
import MangoPopup from "./main";

interface Options {
  type: string;
  title?: string;
  show?: boolean;
  header?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
  hasBackground?: boolean;
  isContent?: boolean;
  maskClosable?: boolean;
  blurredBg?: boolean;
  parentContext?: any;
  appContext?: any;
  props?: Object;
  closeCallBack?: Function;
  isOuterRoll?: boolean;
}

let _div: any = null;
const _vm: any = null;
// 动态设置每层节点样式
const setVmStyle = () => {
  const nodes = document.getElementsByClassName("cms-mango-popup") as HTMLCollectionOf<HTMLElement>;
  const leng = nodes.length;
  if (leng > 0) {
    for (let i = 0; i <= leng; i++) {
      if (i != leng - 1) {
        nodes[i] && (nodes[i].style.background = "none");
      } else {
        nodes[i] && (nodes[i].style.background = "rgba(0,0,0,0.5)");
      }
    }
  }
};
// 创建初始化外层节点
const createEl = (type?: string) => {
  const div = document.createElement("div");
  div.classList.add("cms-mango-popup");
  type && div.setAttribute("data-type", type);
  const nodes = document.getElementsByClassName("cms-mango-popup") as HTMLCollectionOf<HTMLElement>;
  const leng = nodes.length;
  div.setAttribute("data-index", leng + "");
  div.style.zIndex = 1600 + leng + "";
  document.body.appendChild(div);
  // document.getElementById("app")?.classList.add("mango-popup-show");
  document.body.style.overflow = "hidden";
  document.body.setAttribute("data-lock", "true");
  // setVmStyle();
  return div;
};

// 删除节点
const removeEl = () => {
  const leng = document.getElementsByClassName("cms-mango-popup")?.length;
  const div = document.getElementsByClassName("cms-mango-popup")[leng - 1];
  if (!div) return;
  render(null, div);
  if (leng === 1) {
    document.getElementById("app")?.classList.remove("mango-popup-show");
    document.body.style.overflow = "auto";
  }
  document.body.removeChild(div);
};

/**
 * 模板渲染函数
 * @param props 属性参数
 * @param el 界面节点
 */
const renderModal = (props: any, el: HTMLElement, option?: Options) => {
  const vm = createVNode(MangoPopup, { ...props });

  vm.appContext =
    window.__VUE_APP__?._context ?? (option?.parentContext || option?.appContext || vm.appContext);
  render(vm, el);
  return vm;
};

const mangoPopup = (option?: Options) => {
  const instance = {
    open: () => {
      const dom = document.getElementById("js_login");
      if (dom) {
        return false;
      }
      _div = createEl();
      const vm = renderModal(option, _div);
      vm.component?.props && (vm.component.props.show = true);
    },
    close: (callBack?: Function) => {
      removeEl();
      setVmStyle();
      callBack && callBack();
    },
    // 销毁所有节点
    destroyAll: (callBack?: Function) => {
      const nodes = document.getElementsByClassName(
        "cms-mango-popup"
      ) as HTMLCollectionOf<HTMLElement>;
      const leng = nodes.length;
      if (leng > 0) {
        for (let i = leng - 1; i >= 0; i--) {
          document.body.removeChild(nodes[i]);
        }
      }
      document.getElementById("app")?.classList.remove("mango-popup-show");
      document.body.style.overflow = "auto";
      document.body.setAttribute("data-lock", "false");
      callBack && callBack();
    },
  };
  return instance;
};
export { mangoPopup };
