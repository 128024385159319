import { computed, reactive } from "vue";
import { Api, useConfig } from "core";

interface Store {
  captchaImg: string;
  captchaKey: string;
}

const store = reactive<Store>({
  captchaImg: "",
  captchaKey: "",
});
export function useCommon() {
  const { isPc } = useConfig();
  const captchaImg = computed(() => store.captchaImg);
  // 获取图形验证码
  const captchaCode = async () => {
    try {
      const { data, result } = await Api.CommonService.captchaCode({
        name: "image",
        clientType: isPc.value ? "web" : "h5",
      });
      if (!result) return;
      store.captchaImg = data.img;
      store.captchaKey = data.captchaKey;
      return data;
    } catch (e) {
      //console.error("useWithdraw:captchaCode", e);
      return { img: "", captchaKey: "" };
    }
  };
  return {
    store,
    captchaImg,
    captchaCode,
  };
}
