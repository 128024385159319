import { defineComponent } from "vue";
import style from "./style.module.less";
// import { Loading } from "vant";
import { Loading } from "commom";
export default defineComponent({
  name: "ButtonCom",
  props: {
    type: {
      type: String,
      default: "default",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  setup(props, { slots, emit }) {
    return () => (
      <div
        class={[style.button, style[props.type], props.disabled && style.disabled]}
        onClick={e => {
          if (props.disabled) return;
          emit("click", e);
        }}
      >
        {props.loading && (
          // <Loading
          //   size={20}
          //   color={props.type === "default" ? "var(--cms-mango-text-color5)" : "#fff"}
          //   class={style.buttonLoading}
          // />
          <Loading />
        )}
        {slots.default?.()}
      </div>
    );
  },
});
