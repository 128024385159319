import { defineComponent, onMounted } from "vue";
import card from "./card.module.less";
import { useI18n, useWelfare, useWallet, timestampToDateTime, useUser } from "core";
import { Empty } from "components";
import { Loading } from "vant";
import { IWealList } from "@kgsport-cms/service";
export default defineComponent({
  props: {
    type: {
      default: 0,
      type: Number,
    },
  },
  emits: ["setTabs"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { receiveAwardMango, wealGroup, getWealGroup, store } = useWelfare(false);
    const { amountFormat } = useWallet();
    const { user } = useUser();
    const btnStatus = {
      0: $t("mine_welfare_pending_review" /**待审核 */),
      1: $t("mine_welfare_canReceive" /**可领取 */),
      2: $t("mine_welfare_finished" /**已完成 */),
      3: $t("mine_welfare_lostEfficacy" /**已失效 */),
    };
    const getBonous = (item: IWealList) => {
      receiveAwardMango(item.id, item.type);
    };
    onMounted(async () => {
      await getWealGroup();
      emit("setTabs", wealGroup.value);
    });
    return () => (
      <div class={card.welfareCard}>
        {store.loading ? (
          <div class={card.welfareCardLoading}>
            <Loading color="@cms-mango-text-color4" />
          </div>
        ) : wealGroup.value[props.type] && wealGroup.value[props.type]?.length ? (
          wealGroup.value[props.type as string | number]?.map((item: IWealList) => {
            return (
              <div class={card.welfareCardItem}>
                <div class={card.welfareCardItemTop}>
                  <div class={card.welfareCardItemTopHead}>
                    <p>{$t("funds_card_title" /* 名称 */)}</p>
                    <p>{props.type == 7 ? item.activityTitle : item.name}</p>
                  </div>
                </div>
                <div class={card.welfareCardItemContent}>
                  <div class={card.welfareCardItemContentTop}>
                    <p>{$t("agent_HomeMA_dtime" /* 时间 */)}</p>
                    <p>{timestampToDateTime(item.createTime || 0)}</p>
                  </div>
                  <div class={card.welfareCardItemContentTop}>
                    <p>{$t("common_money" /* 金额 */)}</p>
                    <p>
                      {amountFormat(Number(item.rewordAmount || 0))}
                      <span> {user?.value?.currency || "CNY"}</span>
                    </p>
                  </div>
                </div>
                <div class={card.welfareCardItemBottom}>
                  {item.status == 1 ? (
                    <div
                      class={[card.welfareCardItemBottomBtn, card.welfareCardItemBottomBtncanget]}
                      onClick={() => getBonous(item)}
                    >
                      {btnStatus[item.status]}
                    </div>
                  ) : (
                    <div class={card.welfareCardItemBottomBtn}>{btnStatus[item.status]}</div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <Empty type={"mangoNoData"} top={20} />
        )}
      </div>
    );
  },
});
