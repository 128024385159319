/**
 * 1、全局推送信息
 */
import type { CompatClient } from "@stomp/stompjs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useAuth } from "./useAuth.hook";
import { useConfig } from "../config";
import { isHybridApp } from "../common";
import { useToast } from "./useToast.hook";
import { useWallet } from "./useWallet.hook";
import { useUser } from "./useUser.hook";
import { useI18n, usePointStatistics, useTreasureBox } from "core";
import { reactive } from "vue";

let stomp: CompatClient | null = null;
const store = reactive({
  // 是否链接标记
  stompConnect: false,
  // 链接过程中标记
  isConnectIng: false,
});

export function useWebsocket() {
  const { getToken, isLogin } = useAuth();
  const { getApi } = useConfig();
  const { $t } = useI18n();
  const { user } = useUser();
  const { toast } = useToast();
  const { platformBalance, setStoreBalance } = useWallet();

  // 这里调用宝箱查询接口
  const { queryTreasureBoxCommission } = useTreasureBox();
  const { firstRechargeSuccessStatistice, repeatedlyRechargeSuccessStatistice } =
    usePointStatistics();
  const initWebsocket = async () => {
    if (stomp) return stomp;
    const SockJS = (await import(/* webpackChunkName: "sockjs" */ "sockjs-client")).default;
    const { Stomp } = await import(/* webpackChunkName: "stomp" */ "@stomp/stompjs");
    const sock = new SockJS(`${getApi()}/websocket`);
    stomp = Stomp.over(() => sock);
    return stomp;
  };
  const send = ({ body, destination = "/message" }: any) => {
    if (!stomp) return;
    stomp.publish({
      destination,
      // headers: { token: getToken() },
      body: JSON.stringify(body),
    });
  };
  const webSocketActivate = async () => {
    if (isHybridApp()) return;
    if (!isLogin.value) return;
    if (store.isConnectIng || store.stompConnect) return;
    const connectHeaders = {
      Authorization: `${await getToken()}`,
    };
    const client = await initWebsocket();
    store.isConnectIng = true;
    client.connect(
      connectHeaders,
      () => {
        store.stompConnect = true;
        store.isConnectIng = false;
        client.subscribe("/topic", data => {
          console.log("topic", data);
          data.body && toastTip(JSON.parse(data.body));
        });
        client.subscribe(`/user/${user.value?.account}`, data => {
          data.body && toastTip(JSON.parse(data.body));
        });
      },
      (err: any) => {
        store.stompConnect = false;
        store.isConnectIng = false;
        console.log(err);
      }
    );
    client.onWebSocketClose = () => {
      console.log("Stomp连接已关闭，尝试重新连接...");
      store.stompConnect = false;
      store.isConnectIng = false;
      disconnect();
      webSocketActivate(); // 断线重连
    };
  };
  const toastTip = (param: any) => {
    const channel = param.channel;
    console.log(param, "param");
    switch (channel) {
      case "RECHARGE_SUCCESS": // 充值成功
        platformBalance();
        const data = JSON.parse(param.title || "{}");
        // 渠道1
        toast.success(data.rechargeTip);
        // Number(data.totalRechTimes) === 1
        //   ? firstRechargeSuccessStatistice()
        //   : repeatedlyRechargeSuccessStatistice();
        break;
      case "RECHARGE_FAIL": // 充值失败
        !!param.title.rechargeTip && toast.error(param.title.rechargeTip);
        break;
      case "RECHARGE_CANCEL": // 充值取消
        !!param.title.rechargeTip && toast.success(param.title.rechargeTip);
        break;
      case "WITHDRAW_CANCEL":
      case "WITHDRAW_SUCCESS":
        !!param.title.userApplyLoanAfterHintsMessage &&
          toast.success(param.title.userApplyLoanAfterHintsMessage);
        break;
      case "BALANCE_SUCCESS":
        const blanceData = JSON.parse(param.title);
        if (blanceData && blanceData.data) {
          setStoreBalance(blanceData.data);
        }
        break;
      // 宝箱
      case "TREASURE_BOX_SHOW":
        queryTreasureBoxCommission();
        break;
    }
    // if (channel.indexOf("SUCCESS")) {
    //   toast.success(param.title);
    //   channel === "RECHARGE_SUCCESS" && platformBalance();
    //   return;
    // }
    // if (channel.indexOf("FAIL")) {
    //   toast.error(param.title);
    //   return;
    // }
    // if (channel.indexOf("WARN")) {
    //   toast.warn(param.title);
    //   return;
    // }
  };
  const disconnect = async () => {
    if (!stomp) return;
    store.stompConnect = false;
    store.isConnectIng = false;
    await stomp.disconnect();
    stomp = null;
  };

  return {
    initWebsocket,
    send,
    webSocketActivate,
    disconnect,
  };
}
