import { defineComponent, onMounted, reactive } from "vue";
import tab from "./style.module.less";
import { useI18n } from "core";
import { SvgIcon } from "commom";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "login",
    },
  },
  emits: ["close", "change"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const data = reactive({
      isMounted: false,
    });
    const tabClick = async (type: "login" | "register") => {
      await emit("change", type);
    };
    onMounted(() => {
      setTimeout(() => (data.isMounted = true), 10);
    });
    return () => (
      <div class={tab.tab}>
        <div class={tab.tabLeft}>
          <section
            class={[
              tab.tabLeftItem,
              (props.type === "login" || props.type === "entry") && tab.tabLeftActive,
            ]}
            onClick={() => tabClick("login")}
          >
            {$t("user_login_login") /*登录*/}
          </section>
          <section
            class={[tab.tabLeftItem, props.type === "register" && tab.tabLeftActive]}
            onClick={() => tabClick("register")}
          >
            {$t("user_login_register") /*注册*/}
          </section>
        </div>
        <div class={tab.tabRight}>
          {/* <SvgIcon
            onClick={() => emit("close")}
            class={tab.tabRightIcon}
            width="0.5rem"
            height="0.5rem"
            name="close"
          /> */}
        </div>
      </div>
    );
  },
});
