import { defineComponent, PropType, reactive, ref, watch, computed, onMounted } from "vue";
import { ConfigStore, useI18n, useWithdraw } from "core";
import { MangoTipsModal, mangoPopup, Empty } from "components";
import { Image } from "commom";
import { DeleteOutlined } from "@ant-design/icons-vue";
import list from "./account.module.less";
import btnPng from "../assets/btn.png";
import bankPng from "../assets/bank.png";

export default defineComponent({
  props: {
    accountList: {
      type: Array,
      default: () => [1],
    },
    accountType: {
      type: String,
      default: "B",
    },
    config: {
      type: Object as PropType<ConfigStore>,
      default: () => ({}),
    },
  },
  emits: ["unbankSubmit"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { withdrawLimitData, requestWithdrawLimit } = useWithdraw();
    const listItem = ref<any>(null);
    const confirm = async () => {
      emit("unbankSubmit", listItem.value?.id);
      tipModalData.show = false;
    };

    const tipModalData = reactive({
      show: false,
      title: $t("message_withdraw_passwordTitle" /*温馨提示*/),
      subTitle:
        $t("funds_withdraw_confirmRemove", [
          props.accountType === "B"
            ? $t("funds_withdraw_bankCard")
            : $t("funds_bank_virtualCurrencyAccount"),
        ]) + "?",
      confirmText: $t("common_confirm" /*确定*/),
      cancelText: $t("common_cancel" /*取消*/),
      confirmCallback() {
        confirm();
      },
      cancelCallback() {
        tipModalData.show = false;
      },
    });

    const popupStatus = reactive({ show: false, type: "" });

    watch(
      () => props.accountType,
      val => {
        tipModalData.subTitle =
          $t("funds_withdraw_confirmRemove", [
            val === "B" ? $t("funds_withdraw_bankCard") : $t("funds_bank_virtualCurrencyAccount"),
          ]) + "?";
      }
    );
    const isShowDelBtn = computed(() => {
      if (props.accountType === "B") {
        return withdrawLimitData.value.bankSwitch === 1;
      }

      return withdrawLimitData.value.usdtSwitch === 1;
    });
    onMounted(() => {
      requestWithdrawLimit();
    });
    return () => (
      <div class={list.list}>
        <Empty v-show={!props.accountList?.length} type="mangoNoData" top={50} />
        <div class={list.bankList}>
          <ul>
            {props.accountList.map((item: any) => (
              <li class={props.accountType === "V" && list.vItem}>
                <div class={list.bankListIcon}>
                  <Image src={item.icon || (props.accountType === "V" ? btnPng : bankPng)} />
                </div>
                <div class={list.bankListText}>
                  {props.accountType === "B" ? (
                    <>
                      <p class={list.bankListTextName}>{item.bankName}</p>
                      <p class={list.bankListTextType}>
                        {$t("funds_recharge_cashCard" /*储蓄卡*/)}
                      </p>
                      <p class={list.bankListTextNum}>{item.cardNo}</p>
                    </>
                  ) : (
                    <>
                      <p class={list.bankListTextName}>{item.alias}</p>
                      <p class={list.bankListTextType}>
                        {item.network}
                        {$t("funds_withdraw_USDTNetWork") /* 协议 */}
                      </p>
                      <p class={list.bankListTextNum}>{item.cardNo}</p>
                    </>
                  )}
                </div>
                {/*<Icon name="delete"  class={list.deleteCard} />*/}
                {isShowDelBtn.value && (
                  <DeleteOutlined
                    onClick={() => {
                      tipModalData.show = true;
                      listItem.value = item;
                    }}
                    class={list.deleteCard}
                  />
                )}
              </li>
            ))}
          </ul>
          <div
            v-show={
              (props.accountList?.length < props.config.isUserBankBindCount ||
                !props.accountList?.length) &&
              props.accountType === "B"
            }
            class={list.btn}
            onClick={() => {
              mangoPopup({
                type: "MangoBindCard",
                title: $t("funds_add_bank_tips"),
              }).open();
            }}
          >
            {/*添加银行卡*/}
            {$t("funds_add_bank_tips")}
          </div>
          <div
            v-show={
              (props.accountList?.length < props.config.isUserVirtualBindCount ||
                !props.accountList?.length) &&
              props.accountType === "V"
            }
            class={list.btn}
            onClick={() => {
              mangoPopup({
                type: "MangoBindUsdt",
                title: $t("funds_virtual_title_tips"),
              }).open();
            }}
          >
            {/*添加虚拟币地址*/}
            {$t("funds_virtual_title_tips")}
          </div>
        </div>
        <MangoTipsModal type="info" {...tipModalData} />
      </div>
    );
  },
});
