import { useRouter } from "vue-router";
import * as CheckList from "./useCheckList.hook";
/*
 * 全局路由检查权限
 */
const store = new Map<string, Array<() => Promise<boolean>>>();
export function useCheckList() {
  // 注册权限检查条件
  const setAuthCheck = (path: string, cbs: string[] = []) => {
    if (!path || !cbs) return;
    const check = store.get(path) || [];
    const cb: any[] = [CheckList.envCheck];
    cbs.forEach(item => {
      // @ts-ignore
      if (CheckList[item]) {
        // @ts-ignore
        if (check.includes(CheckList[item])) return;
        // @ts-ignore
        cb.push(CheckList[item]);
      } else {
        console.warn("cms no found cheeck:", item);
      }
    });
    if (!check.length) {
      store.set(path, cb);
      return;
    }
    if (check.length === cb.length) return;
    store.set(path, check.concat(cb));
  };
  // 获取注册的权限
  const getAuthCheck = (path: string) => {
    const list = store.get(path);
    if (list) return list;
    return [];
  };
  return {
    setAuthCheck,
    getAuthCheck,
  };
}
/*
 * 异步函数检测多个条件是否满足
 */
const AuthCheck = async (checks: Array<() => Promise<boolean>>) => {
  try {
    if (checks.length == 0) return true;
    const end = await Promise.all(checks.map(item => item()));
    return end.every(item => item === true);
  } catch (e) {
    console.error("cms auth check", e, checks);
    return false;
  }
};

/*
 * 异步函数检测多个条件是否满足
 */
export function createAuth(check: string[] | string = [], jumppath?: string) {
  const path = Array.isArray(check) ? jumppath : check;
  if (!path) {
    throw new Error("跳转路径不存在");
  }
  const router = useRouter();
  const { getAuthCheck, setAuthCheck } = useCheckList();
  setAuthCheck(path, Array.isArray(check) ? check : []);

  return async ({ params = {}, query = {} } = {} as any) => {
    try {
      const checkList = getAuthCheck(path);
      const end = await AuthCheck(checkList);
      if (!end) return;
      if (path.includes("/:")) {
        await router.push({ name: path, params, query });
      } else {
        await router.push({ path, params, query });
      }
    } catch (e) {
      console.error("cms-rouer is error:", e);
    }
  };
}
