import { defineComponent, onBeforeMount, onMounted, ref, watch } from "vue";
import entry from "../style.module.less";
import ThirdLogin from "../components/ThirdLogin";
import { Button, Popup } from "vant";
import { FormItemOption, useI18n, useRegister, useUser, useConfig, useCommon } from "core";
import PhoneLogin from "../components/PhoneLogin";
import { Form } from "ant-design-vue";
import { SvgIcon } from "commom";
import RadioCircle from "../components/RadioCircle";
import { IDialCode } from "@kgsport-cms/service";
import { DownOutlined } from "@ant-design/icons-vue";
// 税号弹窗
import MangoDutyCodeSelect from "templates/mango/Mobile/components/DutyCodeSelect";

export default defineComponent({
  emits: ["changeType"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const scroll = ref();
    const {
      formConfig,
      emailOption,
      phoneOption,
      modelRef,
      validateInfos,
      loading,
      openGeetestVerifier,
      phoneModelRef,
      dataStore,
      currentCurrency,
      currentCurrencyText,
      currentLangue,
      eye,
      reEye,
      cancelSelect,
      clickCurrencyLists,
      clickLangueLists,
      selectPop,
      confirmSelect,
      count: emailCount,
      getEmailCode: registerEmailCode,
      agreement,
      dutyFields,
    } = useRegister();
    const { openCaptcha, count } = useUser();
    const { captchaImg, captchaCode } = useCommon();
    const { getCurrencyList, getLangueList, config, areaCodeList, getDialCode } = useConfig();
    // 兼容华为手机输入框导致界面挤压问题
    const setBodyHeight = () => {
      document.getElementsByTagName("body")[0].style.height = window.innerHeight + "px";
    };
    const resetBodyHeight = () => {
      document.getElementsByTagName("body")[0].style.height = "100%";
    };
    // 币种 1
    const currencyPop = () => (
      <div class={entry.currencyContainer}>
        {getCurrencyList.value.map(item => (
          <div class={entry.currencyList} key={item.code} onClick={() => clickCurrencyLists(item)}>
            <img class={entry.currencyItemImg} src={config.flagIcons.currency[item.code]} alt="" />
            <span class={entry.bankIcon}>
              {item.name}({item.code})
            </span>
            <RadioCircle checked={currentCurrency.code === item.code} />
          </div>
        ))}
      </div>
    );
    // 语种
    const languagePop = () => (
      <div class={entry.currencyContainer}>
        {getLangueList.value.map(item => (
          <div class={entry.currencyList} key={item.seriNo} onClick={() => clickLangueLists(item)}>
            <img class={entry.currencyItemImg} src={config.flagIcons.lang[item.seriNo]} alt="" />
            <section class={entry.bankLanguage}>
              <span class={entry.bankLocal}>{item.localLanguage}</span>
              <span class={entry.bankIcon}>{item.info}</span>
            </section>
            <RadioCircle checked={currentLangue.code === item.seriNo} />
          </div>
        ))}
      </div>
    );

    const duty = ref(false);

    const formInput = (item: FormItemOption) => {
      if (["smsCode", "phone"].includes(item.key)) {
        return [
          <PhoneLogin
            onPhoneChange={v => (modelRef.phone = v)}
            onSmsCodeChange={v => (modelRef.smsCode = v)}
            onDialCodeChange={v => (modelRef.dialCode = v)}
            hidden={!(phoneOption.value && config.smsSwitch)}
            onSendCodeChange={async () => {
              await openCaptcha({
                type: 1,
                phone: modelRef.phone as string,
                dialCode: modelRef.dialCode as string,
              });
            }}
            validateInfos={validateInfos}
            count={count}
          />,
        ];
      } else if (item.key === "password") {
        return (
          <div class={entry.formBoxFormInput}>
            <SvgIcon
              class={entry.formBoxFormIcon}
              width="0.3rem"
              height="0.3rem"
              name="lock"
              style="margin-right: 0.1rem"
            />
            <Form.Item validateFirst name={item.key} {...validateInfos[item.key]}>
              <input
                v-model={modelRef[item.key]}
                onClick={() => setBodyHeight()}
                type={dataStore.togglePassword ? "text" : "password"}
                placeholder={$t("user_findPassword_pleaseInputPassword" /*请输入密码*/)}
              />
            </Form.Item>
            <div
              class={entry.formBoxFormInputPassword}
              onClick={() => (dataStore.togglePassword = !dataStore.togglePassword)}
            >
              <SvgIcon
                class={entry.formBoxFormIcon}
                width="0.48rem"
                height="0.48rem"
                name={eye.value}
              />
            </div>
          </div>
        );
      } else if (item.key === "confirmPassword") {
        return (
          <div class={entry.formBoxFormInput}>
            <Form.Item validateFirst name={item.key} {...validateInfos[item.key]}>
              <input
                v-model={modelRef[item.key]}
                onClick={() => setBodyHeight()}
                onBlur={() => resetBodyHeight()}
                type={dataStore.toggleRePassword ? "text" : "password"}
                placeholder={$t("user_register_input_confirmPassword" /* 请确认密码 */)}
              />
            </Form.Item>
            <div onClick={() => (dataStore.toggleRePassword = !dataStore.toggleRePassword)}>
              <SvgIcon
                class={entry.formBoxFormIcon}
                width="0.48rem"
                height="0.48rem"
                name={reEye.value}
              />
            </div>
          </div>
        );
      } else if (item.key === "language") {
        return (
          <div
            class={entry.formBoxFormSelect}
            onClick={() => {
              if (["int115-2"].includes(window.CONFIG?.name)) return;
              selectPop(2);
            }}
          >
            <div class={entry.formBoxFormSelectContent}>
              <div class={entry.formBoxFormSelectSelected}>{dataStore.form.info}</div>
            </div>
            <div class={entry.formBoxFormSelectArrow}>
              <SvgIcon width="0.48rem" height="0.48rem" name="arrow" />
            </div>
          </div>
        );
      } else if (item.key === "currency") {
        return (
          <div class={entry.formBoxFormSelect} onClick={() => selectPop(1)}>
            <div class={entry.formBoxFormSelectContent}>
              <div class={entry.formBoxFormSelectSelected}>{currentCurrencyText.value}</div>
            </div>
            <div class={entry.formBoxFormSelectArrow}>
              <SvgIcon width="0.48rem" height="0.48rem" name="arrow" />
            </div>
          </div>
        );
      } else if (item.key === "duty") {
        return (
          <>
            <div class={[entry.formBoxFormInput, entry.formBoxFormDutyWrap]}>
              <div class={entry.formBoxFormDuty} onClick={() => (duty.value = true)}>
                <span>{modelRef[dutyFields.dutyCode] || $t("common_select" /*选择*/)}</span>
                <DownOutlined />
              </div>
              <Form.Item name={dutyFields.dutyNumber} {...validateInfos[dutyFields.dutyNumber]}>
                <input
                  size="large"
                  style="padding-left:10px"
                  v-model={modelRef[dutyFields.dutyNumber]}
                  placeholder={$t("user_register_inputDutyCode" /**请输入税号 */)}
                />
              </Form.Item>
            </div>
          </>
        );
      } else if (item.key === "email") {
        return (
          <>
            <div class={entry.formBoxFormInput}>
              <Form.Item has-feedback validateFirst name={item.key} {...validateInfos[item.key]}>
                <input
                  v-model={modelRef[item.key]}
                  placeholder={item.name}
                  onBlur={() => resetBodyHeight()}
                  onClick={() => setBodyHeight()}
                />
              </Form.Item>
            </div>
            {!!(emailOption.value?.value && Number(emailOption.value?.value) === 1) && (
              <div class={entry.formBoxFormInput}>
                <Form.Item has-feedback validateFirst name="emailCode" {...validateInfos.emailCode}>
                  <input
                    v-model={modelRef.emailCode}
                    onClick={() => setBodyHeight()}
                    onBlur={() => resetBodyHeight()}
                    placeholder={$t("user_login_verificationCode" /**验证码 */)}
                  />
                </Form.Item>
                {!emailCount.value ? (
                  <div
                    class={entry.loginPhoneInputNumberCode}
                    onClick={async () => {
                      await registerEmailCode({ email: modelRef.email });
                    }}
                  >
                    {$t("user_login_sendVerificationCode" /**发送验证码 */)}
                  </div>
                ) : (
                  <div
                    class={[entry.loginPhoneInputNumberCode, entry.loginPhoneInputNumberCodeSend]}
                  >
                    {emailCount.value}s
                  </div>
                )}
              </div>
            )}
          </>
        );
      } else if (item.key === "code") {
        return window.CONFIG.isShowImgCode ? (
          <div class={entry.formBoxFormSelect}>
            <SvgIcon
              class={entry.formBoxFormIcon}
              width="0.4rem"
              height="0.4rem"
              name="vertifyCode"
              style="margin-right: 0.1rem"
            />
            <div class={entry.formBoxFormSelectContent}>
              <Form.Item name="code" {...validateInfos[item.key]}>
                <input
                  v-model={modelRef.code}
                  placeholder={$t("funds_recharge_inputVerificationCode" /**请输入图形验证码 */)}
                  type="text"
                  maxlength={6}
                />
              </Form.Item>
            </div>
            <div
              class={entry.formBoxFormInputCaptcha}
              onClick={async () => {
                const data = await captchaCode();
                modelRef.captchaKey = data.captchaKey;
              }}
            >
              <img src={captchaImg.value} alt="" />
            </div>
          </div>
        ) : null;
      } else if (item.key === "username") {
        return (
          <div class={entry.formBoxFormInput}>
            <SvgIcon class={entry.formBoxFormIcon} width="0.48rem" height="0.48rem" name="user" />
            <Form.Item
              has-feedback
              validateFirst={true}
              name="username"
              {...validateInfos.username}
            >
              <input
                v-model={modelRef.username}
                placeholder={item.name}
                onBlur={() => resetBodyHeight()}
                onClick={() => setBodyHeight()}
              />
            </Form.Item>
            {!!modelRef[item.key] && (
              <div class={entry.formBoxFormInputPassword} onClick={() => (modelRef[item.key] = "")}>
                <SvgIcon
                  class={entry.formBoxFormIcon}
                  width="0.4rem"
                  height="0.4rem"
                  name="close"
                />
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div class={entry.formBoxFormInput}>
            <Form.Item
              has-feedback
              validateFirst={true}
              name={item.key}
              {...validateInfos[item.key]}
            >
              <input
                v-model={modelRef[item.key]}
                placeholder={item.name}
                onBlur={() => resetBodyHeight()}
                onClick={() => setBodyHeight()}
              />
            </Form.Item>
            {!!modelRef[item.key] && (
              <div class={entry.formBoxFormInputPassword} onClick={() => (modelRef[item.key] = "")}>
                <SvgIcon
                  class={entry.formBoxFormIcon}
                  width="0.4rem"
                  height="0.4rem"
                  name="close"
                />
              </div>
            )}
          </div>
        );
      }
    };

    onBeforeMount(async () => {
      if (!areaCodeList.value?.length) await getDialCode();
    });
    onMounted(async () => {
      setTimeout(() => (dataStore.isMounted = true), 10);
      if (window.CONFIG.isShowImgCode) {
        const data = await captchaCode();
        modelRef.captchaKey = data.captchaKey;
      }
    });

    const accountRegister = () => (
      <>
        <div class={[entry.formBoxForm, entry.loginRegister, entry.accountRegister]} ref={scroll}>
          <Form>{formConfig.items.map(item => formInput(item))}</Form>
        </div>
        <div class={entry.loginAccountText}>
          {agreement.tip && (
            <p class={entry.loginAccountAgreement}>
              {$t("user_login_agreement_text" /*请接受服务条款*/)}
            </p>
          )}
          <p>
            <section
              class={[entry.loginAccountRadio, agreement.value && entry.loginAccountRadioChecked]}
              onClick={() => (agreement.value = !agreement.value)}
            >
              <article />
              <span>{$t("user_register_serveTips" /*我确认我已满18岁并且已阅读*/)}</span>
            </section>
            <span class={entry.loginAccountLink}>{$t("user_login_serveBtn" /*服务条款*/)}</span>
          </p>
        </div>
        <Button onClick={openGeetestVerifier} loading={loading.value}>
          {$t("user_register_createAccount" /**创建账户 */)}
        </Button>
      </>
    );
    const setAreaCodeItem = (item: IDialCode) => {
      dataStore.toggleAreaCode = false;
      phoneModelRef.dialCode = item.code;
    };
    // 搜索
    const areaCodeData = ref<IDialCode[]>();
    watch(
      () => areaCodeList.value,
      n => {
        areaCodeData.value = n;
      },
      { immediate: true, deep: true }
    );
    const searchVal = ref("");
    const setAreaCodeData = (text: string) => {
      areaCodeData.value = text
        ? areaCodeList?.value.filter(d => d.code.includes(text)) ?? []
        : areaCodeList?.value;
    };
    const timer = ref();
    watch(
      () => searchVal.value,
      (n, o) => {
        if (n === o) return;
        if (timer.value) clearTimeout(timer.value);
        timer.value = setTimeout(() => setAreaCodeData(n), 300);
      }
    );
    const clearSearch = () => {
      searchVal.value = "";
    };

    const changeDutyCode = (value: string) => {
      modelRef[dutyFields.dutyCode] = value;
    };

    return () => (
      <div
        class={[
          entry.formBox,
          dataStore.isMounted && entry.formBoxShow,
          loading.value && entry.formBoxDisbaled,
        ]}
      >
        {accountRegister()}

        <div class={entry.loginAccountText}>
          <div class={entry.loginAccountTextLight}>
            {$t("user_login_hasNoAccountNumber" /*你有没有账号？*/)}
            <span class={entry.loginAccountLink} onClick={() => emit("changeType", "login")}>
              {$t("user_login_login") /*登录*/}
            </span>
          </div>
        </div>
        <ThirdLogin />
        <Popup
          v-model:show={dataStore.toggleLanguage}
          position="bottom"
          style={{ height: "82%", borderRadius: "8px 8px 0 0" }}
          onClose={cancelSelect}
          teleport="body"
          class={entry.currencyPopup}
        >
          <div class={entry.currencyTitle}>
            <span class={entry.cancel} onClick={cancelSelect}>
              {$t("common_cancel" /**取消 */)}
            </span>
            <h4>
              {dataStore.popType === 1
                ? $t("user_register_ToastTitle_currency" /* 选择币种 */)
                : $t("mine_center_chooseLang" /* 选择语言 */)}
            </h4>
            <span class={[entry.cancel, entry.confirm]} onClick={() => confirmSelect()}>
              {$t("common_confirm" /*确定*/)}
            </span>
          </div>
          {dataStore.popType === 1 ? currencyPop() : languagePop()}
        </Popup>
        <Popup
          position="bottom"
          v-model:show={dataStore.toggleAreaCode}
          round
          class={entry.areaCode}
          teleport="body"
        >
          <div class={entry.areaCodeHeader}>
            <section class={entry.areaCodeHeaderCode}>
              {$t("user_register_ToastTitle_areaCode" /* 选择区号 */)}
            </section>
            <section class={entry.areaCodeHeaderSearch}>
              <input
                v-model={searchVal.value}
                type="text"
                placeholder={$t("user_register_searchAreaCode" /**搜索区号 */)}
              />
              <i class={entry.areaCodeHeaderSearchClose} onClick={clearSearch}>
                <SvgIcon
                  class={entry.areaCodeHeaderSearchCloseIcon}
                  name="closeTick"
                  width="0.24rem"
                  height="0.24rem"
                />
              </i>
            </section>
          </div>
          <div class={entry.areaCodeMain}>
            {areaCodeData.value?.map(option => (
              <div
                class={entry.areaCodeOption}
                key={option.code}
                onClick={() => setAreaCodeItem(option)}
              >
                <section class={entry.areaCodeOptionText}>
                  <span>{option.name}</span>
                  <span>({option.code})</span>
                </section>
                <RadioCircle checked={phoneModelRef.dialCode === option.code} />
              </div>
            ))}
          </div>
        </Popup>
        {/* 税号弹窗 */}
        <MangoDutyCodeSelect
          height={330}
          v-model:show={duty.value}
          currency={modelRef[dutyFields.dutyCode]}
          onChange={changeDutyCode}
        />
      </div>
    );
  },
});
