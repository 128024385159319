import { defineComponent, onMounted, reactive, Ref, ref, watch } from "vue";
import {
  generateRandomNum,
  useConfig,
  useI18n,
  useUser,
  getUserAgent,
  useConfigContext,
} from "core";
import styles from "./secretPhone.module.less";
import { Form, Input } from "ant-design-vue";
import { Button } from "vant";
import { SvgIcon } from "commom";
import { mangoPopup } from "components";
import { IDialCode } from "@kgsport-cms/service";
import MangoAreCodeSelect from "templates/mango/Web/components/AreCodeSelect";

export default defineComponent({
  props: {
    callback: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const { config, getDialCode, defaultCode } = useConfig();
    const {
      count,
      phoneForm,
      form,
      phoneAccount,
      smsCodeAccount,
      sendCodeSubmit,
      reqeustLoading,
      valdateType,
      editPhoneSubmit,
    } = useUser();
    const { config: global } = useConfigContext();

    const data = reactive({
      popupShow: false,
    });

    const changeAreCode = (option: IDialCode) => {
      phoneForm.dialCode = option.code;
    };

    const loginCustomCode: Ref<boolean> = ref(config.mobileRegistryInfo?.whetherOpen ?? false);
    const changeCustomCode = () => {
      if (!global.smsSwitch || !!count.value) return;
      phoneForm.smsCode = generateRandomNum();
      setTimeout(smsCodeAccount.clearValidate);
      loginCustomCode.value = true;
      valdateType.value = false;
    };
    const changeCustomCodeHotLine = () => {
      if (!global.smsSwitch || !!count.value) return;
      phoneForm.smsCode = "";
      setTimeout(smsCodeAccount.clearValidate);
      loginCustomCode.value = false;
      valdateType.value = true;
    };

    const sendCode = async () => {
      if (count.value) return;
      await phoneAccount.validate();
      await sendCodeSubmit(true);
    };

    const submit = async () => {
      // await phoneAccount.validate();
      // await loginCustomCode.value ? smsCodeRonderAccount.validate() : smsCodeAccount.validate();
      // 设置成功后返回设置页面
      editPhoneSubmit().then(res => {
        if (res) {
          mangoPopup().close();
          if (props.callback) {
            props.callback();
          }
        }
      });
    };
    watch(
      () => phoneForm.phone,
      () => {
        phoneAccount.clearValidate();
      }
    );
    watch(
      () => phoneForm.smsCode,
      () => {
        smsCodeAccount.clearValidate();
      }
    );

    onMounted(async () => {
      await getDialCode();
      phoneForm.dialCode = defaultCode.value;
      loginCustomCode.value && (phoneForm.smsCode = generateRandomNum());
    });

    return () => (
      <div>
        <div class={styles.secretPhone}>
          <Form>
            <Form.Item {...phoneAccount.validateInfos.phone}>
              <Input.Group compact>
                <div class={styles.areaCodeWrap} onClick={() => (data.popupShow = true)}>
                  <div class={styles.areaCodeWrapText}>{phoneForm.dialCode}</div>
                  <div class={styles.areaCodeWrapBtn}>
                    <SvgIcon width="24px" height="24px" name="arrow" />
                  </div>
                </div>
                <Input
                  placeholder={$t("mine_usersetup_enterMobileNo" /*请输入您的手机号 */)}
                  bordered={false}
                  // type="number"
                  v-model:value={phoneForm.phone}
                />
              </Input.Group>
            </Form.Item>
            {config.smsSwitch ? (
              <>
                <Form.Item {...smsCodeAccount.validateInfos.smsCode} class={styles.secretPhoneItem}>
                  <Input
                    placeholder={$t("user_register_input_verifyCode" /*请输入验证码*/)}
                    bordered={false}
                    type="number"
                    v-model:value={phoneForm.smsCode}
                  />
                  <a class={styles.secretPhoneCode} onClick={sendCode}>
                    {count.value
                      ? `${count.value}s`
                      : $t("user_login_sendVerificationCode" /*发送验证码*/)}
                  </a>
                </Form.Item>
                <div class={styles.secretPhoneCodeTips}>
                  {$t("user_verify_input_sms_code" /*请输入6位数验证码*/)}
                </div>
              </>
            ) : null}

            <div class={styles.secretPhoneBtns}>
              <Button onClick={submit} loading={reqeustLoading.value}>
                {$t("mine_userinfo_verify" /*验证*/)}
              </Button>
            </div>
          </Form>

          {defaultCode.value && (
            <MangoAreCodeSelect
              v-model:show={data.popupShow}
              currency={form.zaloAreaCode ? form.zaloAreaCode : defaultCode.value}
              onChange={changeAreCode}
            />
          )}
        </div>
      </div>
    );
  },
});
