var toString = Object.prototype.toString;
/**
 * @param {Object} val
 * @returns {boolean}
 */
export function isArray(val) {
  return Array.isArray(val);
}
/**
 * @param {Object} val
 * @returns {boolean}
 */
export function isUndefined(val) {
  return typeof val === "undefined";
}
/**
 * @param {Object} val
 * @returns {boolean}
 */
export function isString(val) {
  return typeof val === "string";
}
/**
 * @param {Object} val
 * @returns {boolean}
 */
export function isNumber(val) {
  return typeof val === "number";
}
/**
 * @param {Object} val
 * @returns {boolean}
 */
export function isObject(val) {
  return val !== null && typeof val === "object";
}
/**
 * @param {Object} val
 * @returns {boolean}
 */
export function isFile(val) {
  return toString.call(val) === "[object File]";
}
/**
 * @param {Object} val
 * @returns {boolean}
 */
export function isFunction(val) {
  return toString.call(val) === "[object Function]";
}