import { ref } from "vue";
import { useIntervalFn } from "@vueuse/core";
import { useI18n } from "core";

export function useCountDown() {
  const { $t } = useI18n();
  const text = ref($t("common_send" /*发送*/));
  const count = ref(0);
  const { pause, resume, isActive } = useIntervalFn(() => {
    if (count.value > 0) {
      count.value -= 1;
    } else {
      pause();
    }
  }, 1000);
  // resume: 开始倒计时
  const start = (time: number) => {
    count.value = time;
    resume();
  };
  return {
    text,
    count,
    pause,
    resume,
    isActive,
    start,
  };
}
