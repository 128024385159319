/**
 * 登录
 */
import { computed, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { Form } from "ant-design-vue";
import { useUrlSearchParams } from "@vueuse/core";
import {
  Api,
  useUser,
  useModal,
  useToast,
  usePath,
  useAuth,
  jsencrypt,
  usernameRule,
  passwordRule,
  phoneNumberRule,
  verificationCodeRule,
  verificationCodeRule3,
  STORE_USER_REMEMBER,
  STORE_USER_PHONE_REMEMBER,
  useEvent,
  useI18n,
  useLocalStorage,
  getSystemLang,
  useConfig,
  setUUID,
  matchPath,
  CMS_INTL_FACEBOOK_LOGIN_URI,
  useCommon,
  captchaRule,
  useGoogleAnaltics,
  isToday,
  usePointStatistics,
} from "core";
import { VerifyAccount } from "components";

import { mangoPopup } from "components";

const useForm = Form.useForm;

import { STORE_USER_INFO } from "../common";

import {
  UserLoginForm,
  UserRememberForm,
  UserPhoneRememberForm,
  ThirdLoginDataList,
} from "@kgsport-cms/service";
import { useDebounceFn, useStorage } from "@vueuse/core";
import {
  AuthCodeFlowErrorResponse,
  AuthCodeFlowSuccessResponse,
  useTokenClient,
} from "vue3-google-signin";
import { UserSetupItem } from "@kgsport-cms/service/src";

const storeThirdLogin = reactive({
  showUnBindThirdLogin: false, //       解绑三方登录,
  type: 1, // 三方登录类型,
});

export function useLogin() {
  const route = useRoute();
  const { $t } = useI18n();
  const { userClickLogin, userLoginSuccess } = useGoogleAnaltics();
  const { LoginSuccessStatistice } = usePointStatistics();
  const { localStore } = useLocalStorage();
  const { regDefaultCurrency, isPc, setSystemConfigs } = useConfig();
  const { goHome } = usePath();
  const { user, getUserInfo } = useUser();
  const { loginEvent } = useEvent();
  // const { config } = useConfigContext();
  const lang = getSystemLang();
  const urlSearchParams = useUrlSearchParams("history");
  const { captchaCode } = useCommon();
  const isShowImgCode = computed(() => window?.CONFIG?.isShowImgCode ?? false);
  const check = reactive({
    provision: false,
    isverifyOPen: 0,
  });
  const verifyData = reactive({
    num: 0,
    reason: "",
    verify: "",
  });
  const baseForm = reactive<UserLoginForm>({
    username: "",
    password: "",
    geetest_seccode: "",
    geetest_validate: "",
    geetest_challenge: "",
    captchaKey: "",
    code: "",
    phone: "",
    realName: "",
    qq: "",
    email: "",
    cardNo: "",
    wechat: "",
  });
  // 手机号表单
  const phoneForm = reactive({
    phone: "",
    smsCode: "",
    dialCode: "",
    captchaKey: "",
    code: "",
    geetest_seccode: "",
    geetest_validate: "",
    geetest_challenge: "",
  });
  // 账密验证
  const baseRule = reactive<any>({
    username: usernameRule(true),
    password: passwordRule(),
    code: isShowImgCode.value ? captchaRule() : [],
  });

  const phoneRule = reactive<any>({
    phone: phoneNumberRule(),
    smsCode: verificationCodeRule(),
    dialCode: [],
    code: isShowImgCode.value ? captchaRule() : [],
  });
  const changeVerificationCodeRule = (type: "number" | "strnum") => {
    if (type === "number") phoneRule.smsCode = verificationCodeRule();
    else phoneRule.smsCode = verificationCodeRule3();
  };
  const loading = ref<boolean>(false);
  // 动态创建验证,自由组合
  const account = useForm(baseForm, baseRule);
  const phone = useForm(phoneForm, phoneRule);
  const { toast } = useToast();
  const { setToken } = useAuth();
  // 记住密码
  const userRemember = useStorage<UserRememberForm>(STORE_USER_REMEMBER, {
    username: null,
    password: null,
  });
  // 记住手机号
  const userPhoneRemember = useStorage<UserPhoneRememberForm>(STORE_USER_PHONE_REMEMBER, {
    phone: null,
  });
  // 会员资料认证
  const verifyAccount = useModal({
    title: $t("user_member_auth" /*会员资料认证*/),
    content: () => <VerifyAccount verifyData={verifyData} />,
    destroyOnClose: true,
    header: true,
  });

  //第三方登录
  const loginOfThird = async (token: string, source: number) => {
    const param = {
      thridToken: token, //第三方token
      source, //1 facebook，2 谷歌
      currency: regDefaultCurrency.value || "CNY", //玩家币种
      language: lang, //玩家语言
    };
    const { result, data } = await Api.AuthService.thirdLogin(param);
    if (!result) {
      return;
    }

    if (window.jsBridge && window.CONFIG?.name === "int106") {
      window.jsBridge?.postMessage("login", '{"success":1}');
    }
    setToken(data.accessToken);
    loginEvent.emit(true);
    await getUserInfo(false);
    // 谷歌统计
    userLoginSuccess(
      user.value?.vipLevel,
      isToday(user.value?.registerTime as number),
      user.value?.invitationCode
    );
    LoginSuccessStatistice();

    if (window.CONFIG?.currentModel === "mango") {
      mangoPopup().close();
      return;
    }
    setTimeout(() => {
      // if (window.CONFIG.assets === "jbb" && !isPc.value) goGame();
      // else goHome();
      goHome();
    }, 300);
  };

  // 打开验证器
  const openCaptcha = async (isPhone = false) => {
    // 谷歌统计
    userClickLogin();
    if (isPhone) {
      await phone.validate();
      await loginPhoneSubmit();
      return;
    }
    await account.validate();
    await loginAccountSubmit();
  };
  // 普通默认函数
  const loginAccountSubmit = async () => {
    try {
      const { password, username } = baseForm;
      const info = Object.assign({}, baseForm);
      loading.value = true;
      info.password = await jsencrypt(info.password);
      const { data, result, code } = await Api.AuthService.accountlogin(info);
      data?.useInfo && localStore.set(STORE_USER_INFO, JSON.stringify(data.useInfo));
      if (code === 10005) {
        userRemember.value = {
          password,
          username,
        };
        verifyData.num = data.num;
        verifyData.reason = data.reason;
        verifyData.verify = data.verify;
        verifyAccount.open();
        return;
      }
      if (!result) {
        loading.value = false;
        return;
      }
      // 是否记住密码
      if (check.provision) {
        userRemember.value = {
          password,
          username,
        };
      } else {
        userRemember.value = {
          username: null,
          password: null,
        };
      }
      setToken(data.accessToken);
      loginEvent.emit(true);
      verifyAccount.close();
      await getUserInfo(false);
      userLoginSuccess(
        user.value?.vipLevel,
        isToday(user.value?.registerTime as number),
        user.value?.invitationCode
      );
      LoginSuccessStatistice();
      // getVipInfo();
      // toast.success($t("user_toast_loginSuccess" /*登录成功*/));
      if (window.CONFIG?.currentModel === "mango") {
        mangoPopup().destroyAll();
        return;
      }
      setTimeout(async () => {
        // if (window.CONFIG.assets === "jbb" && !isPc.value) goGame();
        // else goHome();
        goHome();
        loading.value = false;
      }, 300);
    } catch (e) {
      console.error("loginAccountSubmit", e);
      loading.value = false;
    }
  };
  // 设置手机号登录参数
  const setPhoneForm = async (dataObj: any) => {
    phoneForm.phone = dataObj.phone;
    phoneForm.smsCode = dataObj.smsCode;
    phoneForm.dialCode = dataObj.dialCode;
    phoneForm.captchaKey = dataObj.captchaKey;
    phoneForm.code = dataObj.code;
  };
  // 手机登录
  const loginPhoneSubmit = async () => {
    try {
      const { data, result, code } = await Api.AuthService.phoneLogin(phoneForm);
      data?.useInfo && localStore.set(STORE_USER_INFO, JSON.stringify(data.useInfo));
      loading.value = true;
      if (code === 10005) {
        userPhoneRemember.value = {
          phone: phoneForm.phone,
        };
        verifyData.num = data.num;
        verifyData.reason = data.reason;
        verifyData.verify = data.verify;
        verifyAccount.open();
        return;
      }
      if (!result) return;
      // 是否记住手机号码
      if (check.provision) {
        userPhoneRemember.value = {
          phone: phoneForm.phone,
        };
      } else {
        userPhoneRemember.value = {
          phone: null,
        };
      }
      setToken(data.accessToken);
      verifyAccount.close();
      await getUserInfo(false);
      if (window.CONFIG?.currentModel === "mango") {
        mangoPopup().close();
        return;
      }
      // getVipInfo();
      // toast.success($t("user_toast_loginSuccess" /*登录成功*/));
      setTimeout(() => {
        // if (window.CONFIG.assets === "jbb" && !isPc.value) goGame();
        // else goHome();
        goHome();
      }, 300);
      loginEvent.emit(true);
    } catch (e) {
      console.error("loginPhoneSubmit", e);
    } finally {
      loading.value = false;
    }
  };
  const initFacebookSDK = () => {
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = CMS_INTL_FACEBOOK_LOGIN_URI;
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };
  const facebookLogin = async () => {
    // 谷歌统计
    userClickLogin();
    if (window.FB) {
      const FB = window.FB;
      FB.getLoginStatus((response: any) => {
        // 已登录
        if (response.status === "connected") {
          loginOfThird(response.authResponse.accessToken, 1);
          return;
        } else {
          FB.login((res: any) => {
            if (res.status === "connected") {
              loginOfThird(res.authResponse.accessToken, 1);
            }
          });
        }
      });
    }
  };

  // 绑定第三方
  const bindThirdApp = async (params: { source: number; thirdToken: string }) => {
    await Api.UserService.bindThirdApp(params);
  };
  // 解绑第三方
  const unbindThirdApp = async (params: { source: number }) => {
    await Api.UserService.unbindThirdApp(params);
  };

  const facebookBind = async () => {
    if (window.FB) {
      const FB = window.FB;
      FB.getLoginStatus((response: any) => {
        // 已登录
        if (response.status === "connected") {
          bindThirdApp({
            source: 1,
            thirdToken: response.authResponse.accessToken,
          });
          return;
        } else {
          FB.login((res: any) => {
            if (res.status === "connected") {
              bindThirdApp({
                source: 1,
                thirdToken: res.authResponse.accessToken,
              });
            }
          });
        }
      });
    }
  };

  // 绑定谷歌
  const { login: googleBind } = useTokenClient({
    async onSuccess(response: AuthCodeFlowSuccessResponse) {
      await bindThirdApp({ source: 2, thirdToken: response.access_token });
      await getUserInfo(false);
    },
    onError(errorResponse: AuthCodeFlowErrorResponse) {
      console.error("Error: ", errorResponse);
    },
    client_id:
      window.CONFIG?.googleId ||
      "626937364336-1aeh8k3hi7oqopc16j1uosn3d1tv3kvv.apps.googleusercontent.com",
  });

  const unBindThird = async (type: number, cb?: () => void) => {
    const { thirdFacebook, thirdGoogle } = user?.value ?? {};
    if (thirdFacebook) {
      await unbindThirdApp({ source: type });
      await getUserInfo(false);
      // setSystemConfigs();
    }
    if (thirdGoogle) {
      await unbindThirdApp({ source: type });
      await getUserInfo(false);
      // setSystemConfigs();
    }
    cb?.();
  };
  const unBind = (item: UserSetupItem | ThirdLoginDataList, cb?: () => void) => {
    const { thirdFacebook, thirdGoogle } = user?.value ?? {};
    if (thirdFacebook && item.name === "Facebook") {
      storeThirdLogin.type = 1;
      isPc.value ? cb?.() : (storeThirdLogin.showUnBindThirdLogin = true);
      return true;
    }
    if (thirdGoogle && item.name === "Google") {
      storeThirdLogin.type = 2;
      isPc.value ? cb?.() : (storeThirdLogin.showUnBindThirdLogin = true);
      return true;
    }
    return false;
  };

  const thirdQuickItem = async (item: UserSetupItem | ThirdLoginDataList, cb?: () => void) => {
    const res = unBind(item, cb);
    if (res) return;
    if (item.name === "Facebook") {
      await facebookBind();
      await setSystemConfigs();
    } else if (item.name === "Google") {
      await googleBind();
      await setSystemConfigs();
    } else {
      // item.name === "Helu"
    }
  };

  // 获取地址栏token参数快速登录
  const tokenQuickLogin = async () => {
    const token = urlSearchParams.token as string;
    const deviceId = urlSearchParams.deviceId as string;

    if (token && deviceId) {
      setToken(token);
      setUUID(deviceId);
      if (await getUserInfo(false)) {
        if (matchPath(route.path, ["/entry/:path*", "/register"])) {
          goHome();
        }
      }
    }
  };

  // const googleLogin = () => {};
  const { login: googleLogin } = useTokenClient({
    onSuccess: (response: AuthCodeFlowSuccessResponse) => {
      loginOfThird(response.access_token, 2);
    },
    onError: (errorResponse: AuthCodeFlowErrorResponse) => {
      console.error("Error: ", errorResponse);
    },
    client_id:
      window.CONFIG?.googleId ||
      "626937364336-1aeh8k3hi7oqopc16j1uosn3d1tv3kvv.apps.googleusercontent.com",
  });
  const getCaptchaCode = async () => {
    const data = await captchaCode();
    baseForm.captchaKey = data.captchaKey;
  };

  onMounted(() => {
    // 是否记住密码
    if (userRemember.value.username) {
      check.provision = true;
      baseForm.username = userRemember.value.username;
      baseForm.password = userRemember.value.password as string;
    }
    if (userPhoneRemember.value.phone) {
      check.provision = true;
      phoneForm.phone = userPhoneRemember.value.phone;
    }
  });

  return {
    check,
    baseForm,
    verifyData,
    phoneForm,
    baseRule,
    phoneRule,
    loading,
    account,
    phone,
    openCaptcha: useDebounceFn(openCaptcha, 1000, { maxWait: 1000 }),
    loginAccountSubmit,
    setPhoneForm,
    storeThirdLogin,
    loginOfThird,
    facebookLogin,
    initFacebookSDK,
    facebookBind,
    googleBind,
    unBindThird,
    thirdQuickItem,
    tokenQuickLogin,
    changeVerificationCodeRule,
    googleLogin,
    getCaptchaCode,
    isShowImgCode,
  };
}
