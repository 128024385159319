import {
  createVNode,
  defineAsyncComponent,
  defineComponent,
  KeepAlive,
  onMounted,
  PropType,
  Transition,
  VNode,
  watch,
  ref,
} from "vue";
import { RouterView, RouteLocationNormalizedLoaded, useRouter, useRoute } from "vue-router";
import {
  useWebsocket,
  useEvent,
  useProduction,
  useConfig,
  useModal,
  useI18n,
  useI18nConfig,
  useUiLibI18n,
  matchPath,
  destroyModalAll,
  useLocalStorage,
  CMS_JUMP_DATA,
  useAuth,
  isMobile,
  userRecord,
} from "core";
import {
  IndexPopup,
  mangoPopup,
  TipsModal,
  MangoIndexPopup,
  MangoIndexPopup2,
  MangoIndexPopup3,
} from "components";
import { ConfigProvider } from "ant-design-vue";
import { AppConfigProvider } from "commom";
import "@kgsport-cms/style";
import baseView from "./baseView.module.less";
interface Icomponents {
  [key: string]: JSX.Element;
}
const ThemePalette = defineAsyncComponent(() => import("../Theme"));
export default defineComponent({
  name: "RootRouterView",
  props: {
    paddingTop: {
      type: Number as PropType<number>,
    },
  },

  setup(_props, { slots }) {
    const route = useRoute();
    const router = useRouter();
    const { getOrderStatus } = userRecord();
    const { updateHeaderTop, setPlatformMaintain } = useConfig();
    const { webSocketActivate, disconnect } = useWebsocket();
    const { runtime } = useProduction();
    const { onToken, onLogin, onMaintain } = useEvent();
    const { isLogin } = useAuth();
    const { $t } = useI18n();
    useI18nConfig();
    const { currentLocal, loadAntVLocal } = useUiLibI18n();
    const { localStore } = useLocalStorage();
    const isMango = window.CONFIG.currentModel === "mango";
    let hasLogin = ref(false);
    updateHeaderTop(_props.paddingTop);
    const tipCon = () =>
      `<div>
        <span class=${baseView.tipIcon}>
          <i class=${baseView.tipIconLeft}></i>
          <i class=${baseView.tipIconRight}></i>
        </span>
        <br/>
        ${$t("common_login_expired" /*当前登录已失效请重新登录*/)}
      </div>`;
    const noticeModal = useModal({
      title: $t("common_tip" /*提示 */),
      content: () => (
        <TipsModal
          noCancel={false}
          confirmBtnText={$t("common_confirm" /*确定*/)}
          contentText={tipCon()}
          confimCallBack={() => {
            destroyModalAll();
            router.push("/entry/login");
            disconnect();
          }}
        />
      ),
      header: true,
    });
    onToken(type => {
      localStore.remove(CMS_JUMP_DATA);
      if (!runtime.value || !type) {
        return;
      }
      if (matchPath(route.path, ["/entry/:path*", "/register"])) {
        return;
      }
      window.CONFIG?.currentModel === "mango"
        ? isMobile
          ? mangoPopup({
              type: "MangoEntryMobile",
              props: { status: "login" },
              header: false,
              blurredBg: false,
              borderRadius: "12",
            }).open()
          : mangoPopup().open()
        : router.push("/entry/login");
      disconnect();
    });
    onMaintain((type, data) => {
      if (type) {
        setPlatformMaintain(data);
        router.push("/maintain");
      } else {
        router.replace("/");
      }
    });
    onLogin(async () => {
      if (window.CONFIG?.currentModel == "mango") {
        if (route.path !== "/") router.push("/");
      }
      await webSocketActivate();
    });
    onMounted(async () => {
      await loadAntVLocal();
      await webSocketActivate();
      getOrderStatus();
    });
    watch(
      () => isLogin.value,
      async val => {
        if (!val) return;
        hasLogin.value = true;
      }
    );
    const components: Icomponents = {
      1: <MangoIndexPopup />,
      2: <MangoIndexPopup2 />,
      3: <MangoIndexPopup3 />,
    };
    watch(
      () => route.path,
      val => {
        getOrderStatus();
      }
    );
    return () => (
      <AppConfigProvider>
        <ConfigProvider locale={currentLocal.value}>
          {window.DEBUG ? <ThemePalette /> : null}
          {isMango ? components[window.CONFIG?.mangoPopup || 1] : <IndexPopup />}
          {slots?.header?.()}
          <RouterView
            v-slots={{
              default: ({
                Component,
              }: {
                Component: VNode;
                route: RouteLocationNormalizedLoaded;
              }) => (
                <Transition name="fade" appear>
                  {Component && createVNode(Component)}
                </Transition>
              ),
            }}
          />
          {slots?.default?.()}
        </ConfigProvider>
      </AppConfigProvider>
    );
  },
});
