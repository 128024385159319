import { defineComponent, onMounted, reactive } from "vue";
import { Tab, Tabs } from "vant";
import MessageList from "./MessageList";
import { useI18n, useMessage, useConfigContext } from "core";
import message from "./style.module.less";

export default defineComponent({
  emits: ["change", "gotoDetail"],
  props: {
    messageType: {
      type: String,
      default: "message",
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { store, messageType, tabMongoList } = useMessage();
    const { messageUnReadCount } = useConfigContext();
    const data = reactive({
      editMode: false,
      messageType: messageType.value || props.messageType,
    });
    // 点击切换tab 控制组件显示
    const onClickTab = (e: any) => {
      // router.push({ query: { messageType: e } });
      store.type = e;
      data.messageType = e;
      emit("change", data);
    };
    const gotoDetail = (val: any) => {
      emit("gotoDetail", val);
    };
    onMounted(async () => {
      // 设置路由参数，记录当前状态
      // if (!route.query.messageType) {
      //   router.push({ query: { messageType: data.messageType } });
      // } else {
      //   data.messageType = String(route.query.messageType);
      //   emit("change", data);
      // }
      // 设置本地存储参数，记录当前状态
      // if (!props.messageType) {
      //   localStorage.setItem("messageType", data.messageType);
      // } else {
      // data.messageType = props.messageType || "";
      // }
      emit("change", data);
    });
    return () => (
      <div>
        {/* <MobileHeader
          back={false}
          backPath="/account"
          //我的消息
          title={$t("mine_center_message" )}
        >
        </MobileHeader> */}
        {data.messageType === "message" ? (
          <p
            class={message.navRight}
            onClick={() => (data.editMode = !data.editMode)}
            style="position:absolute;top:0.28rem;left:0.24rem"
          >
            {/* //完成 编辑 */}
            {data.editMode ? $t("common_complete") : $t("common_edit")}
          </p>
        ) : null}
        <div class={message.tab}>
          <Tabs
            v-model:active={data.messageType}
            onClick-tab={(e: any) => {
              onClickTab(e.name);
            }}
          >
            {tabMongoList.map(item => (
              <Tab
                title={$t(item.name)}
                name={item.type}
                titleClass={item.type === "message" && message.unRead}
                v-slots={
                  item.type === "message" && {
                    title: () => [
                      <span>{$t(item.name)}</span>,
                      messageUnReadCount.value > 0 && (
                        <p class={message.unReadCount}>
                          {messageUnReadCount.value > 99 ? "99+" : messageUnReadCount.value}
                        </p>
                      ),
                    ],
                  }
                }
              >
                <div class={[message.tabInner, data.editMode && message.tabInnerEdit]}>
                  <MessageList
                    type={item.type}
                    editMode={data.editMode}
                    onGotoDetail={gotoDetail}
                  />
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    );
  },
});
