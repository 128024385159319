import { computed, defineComponent, onMounted, reactive, ref } from "vue";
// import { useRouter } from "vue-router";
import { mangoPopup, MangoTipsModal } from "components";
import { useUser, useAuth, useI18n, useConfig, useLogin } from "core";
import { SvgIcon } from "commom";
import usersetup from "./usersetup.module.less";
import CSwitch from "./components/Switch";
import { UserSetupItem } from "@kgsport-cms/service";

interface NewUserSetupItem extends UserSetupItem {
  subName: string;
  disabled?: boolean;
}

export default defineComponent({
  setup() {
    // const router = useRouter();
    // const { isLogin } = useAuth();
    const { config, customer } = useConfig();
    const { $t } = useI18n();
    const {
      user,
      profile,
      registerTime,
      isWithdrawPwd,
      isOpenWithdrawValidate,
      isPhone,
      isEmail,
      isZalo,
      getProfile,
      editProtectWithdrawSubmit,
    } = useUser();
    const { storeThirdLogin, initFacebookSDK, unBindThird, thirdQuickItem } = useLogin();
    const store = reactive({
      showWithdrawPasswordTipsModal: false,
      showBindPhoneTipsModal: false,
    });

    const list = [
      {
        name: "mine_userinfo_PersonalInformation" /*个人信息*/,
        subName: "mine_userinfo_PersonalInformation" /*个人信息*/,
        path: "",
        icon: "mangoUserInfo",
        type: "MangoPersonalInfo",
      },
      !["lam120", "lam117"].includes(window.CONFIG?.name) && {
        name: "mine_usersetup_loginPassword" /*登录密码*/,
        subName: "mine_userinfo_ModifyLoginPassword" /*修改登录密码*/,
        path: "",
        icon: "mangoLoginPwd",
        type: "MangoModifyLogin",
      },
      {
        name: "mine_usersetup_withdrawalPassSettings" /*提现密码设置*/,
        subName: "mine_usersetup_withdrawalPassSettings" /*提现密码设置*/,
        path: "",
        icon: "mangoWithdrawalPassword",
        type: "MangoWithdrawalPassSettings",
      },
    ].filter(Boolean) as NewUserSetupItem[];

    const safety = computed(
      () =>
        [
          !!(profile.value && profile.value?.phoneOption < 2) && {
            name: "mine_usersetup_secretPhone" /**密保手机号*/,
            subName: "mine_usersetup_secretPhone" /*密保手机号*/,
            path: "",
            icon: "mangoSecurityPhoneNumber",
            status: isPhone.value
              ? $t("mine_usersetup_alreadyBind" /* 已绑定 */)
              : $t("common_change" /*修改*/),
            type: "MangoSecretPhone",
            disabled: false,
          },
          !!(profile.value && profile.value?.emailOption < 2) && {
            name: "mine_usersetup_emailAddress" /*邮箱地址*/,
            subName: "mine_usersetup_emailAddress" /*邮箱地址*/,
            path: "",
            icon: "mangoEmailAddress",
            status: isEmail.value
              ? $t("mine_usersetup_alreadyBind" /* 已绑定 */)
              : $t("common_change" /*修改*/),
            type: "MangoEmailAddress",
            disabled: isEmail.value,
          },
          !!(profile.value && profile.value?.zaloOption < 2) && {
            name: "mine_usersetup_bindZaloAddress" /*Zalo地址*/,
            subName: "mine_usersetup_bindZaloAddress" /*Zalo地址*/,
            path: "",
            icon: "mangoZalo",
            status: isZalo.value
              ? $t("mine_usersetup_alreadyBind" /* 已绑定 */)
              : $t("common_change" /*修改*/),
            type: "MangoZaloAddress",
            disabled: isZalo.value,
          },
          config.smsSwitch && {
            name: "funds_withdraw_msgTip" /*提现短信验证*/,
            subName: "",
            path: "",
            icon: "mangoWithdrawalSms",
            status: "",
            type: "",
            disabled: false,
          },
        ].filter(Boolean) as NewUserSetupItem[]
    );

    const safetyTipsModal = ref(false);
    const safetyTipsModalText = ref("");
    const goDetail = (item: NewUserSetupItem) => {
      const typeFns = {
        // 个人信息
        MangoPersonalInfo: () => {
          mangoPopup({ type: item.type as string, title: $t(item.subName) }).open();
        },
        // 登录密码
        MangoModifyLogin: () => {
          mangoPopup({
            type: item.type as string,
            title: $t(item.subName),
          }).open();
        },
        // 提现密码设置
        MangoWithdrawalPassSettings: () => {
          // 提现密码如果已经设置过，不能进行修改
          if (isWithdrawPwd.value) {
            store.showWithdrawPasswordTipsModal = true;
            return;
          }
          mangoPopup({ type: item.type as string, title: $t(item.subName) }).open();
        },
        // 密保手机号
        MangoSecretPhone: () => {
          if (isPhone.value) {
            safetyTipsModalText.value = $t(
              "mine_center_alreadyHaveBindPhone",
              [
                `${user.value?.dialCode}${user.value?.phone}`,
              ] /*您已经绑定手机号:[0]，如需修改请联系客服！*/
            );
            safetyTipsModal.value = true;
            return false;
          }
          mangoPopup({ type: item.type as string, title: $t(item.subName) }).open();
        },
        // 邮箱地址
        MangoEmailAddress: () => {
          if (isEmail.value) return;
          mangoPopup({ type: item.type as string, title: $t(item.subName) }).open();
        },
        // zalo地址
        MangoZaloAddress: () => {
          if (isZalo.value) return;
          mangoPopup({ type: item.type as string, title: $t(item.subName) }).open();
        },
      };

      typeFns[item.type as string] && typeFns[item.type as string]();
    };
    const onUpdateModelValue = () => {
      if (!isPhone.value) {
        store.showBindPhoneTipsModal = true;
        return;
      }
      // router.push("/account/withdrawalPhone");
      editProtectWithdrawSubmit();
    };

    // 快捷登录 ("user_thiredLogin_unbundle" /* 解绑 */ , "mine_usersetup_bind" /* 绑定 */)
    const quickList = computed(() => {
      const { google = true, facebook = true } = config?.thirdLoginInfo ?? {};
      const { thirdFacebook, thirdGoogle } = user?.value ?? {};
      const data = [];
      facebook &&
        data.push({
          name: "Facebook",
          icon: "mangoFacebook",
          i18nKey: thirdFacebook ? "user_thiredLogin_unbundle" : "mine_usersetup_bind",
        });
      google &&
        data.push({
          name: "Google",
          icon: "mangoGoogle",
          i18nKey: thirdGoogle ? "user_thiredLogin_unbundle" : "mine_usersetup_bind",
        });
      return data;
    });

    const toCustomer = () => {
      store.showWithdrawPasswordTipsModal = false;
      if (!customer.value) return;
      if (customer.value.startsWith("http")) window.location.href = customer.value;
      else window.location.href = window.location.protocol + "//" + customer.value;
    };

    onMounted(async () => {
      await getProfile();
      initFacebookSDK();
    });

    const unBindThirdLogin = () => {
      return (
        <MangoTipsModal
          v-model:show={storeThirdLogin.showUnBindThirdLogin}
          title={$t("message_withdraw_passwordTitle" /*温馨提示*/)}
          subTitle={$t("user_thiredLogin_unbundle" /*确认*/)}
          type="info"
          confirmText={$t("common_confirm" /*确定*/)}
          cancelText={$t("common_cancel" /*取消*/)}
          confirmCallback={() => unBindThird(storeThirdLogin.type)}
        />
      );
    };

    return () => (
      <div class={usersetup.setUp}>
        <ul class={usersetup.listView}>
          <li class={usersetup.listViewHeader}>
            <div class={usersetup.bar} />
            <span class={usersetup.listViewHeaderTitle}>
              {$t("mine_usersetup_accountInfo" /*账号信息*/)}
            </span>
          </li>
          {list.map(item => (
            <li class={`${usersetup.listViewItem}`} onClick={() => goDetail(item)}>
              <div class={usersetup.listViewItemLeft}>
                <div class={usersetup.listViewItemIcon}>
                  <SvgIcon name={item.icon} width="24px" height="24px" />
                </div>
                <span class={usersetup.listViewItemTitle}>{$t(item.name)}</span>
              </div>

              <div class={usersetup.listViewItemLeft}>
                <div class={usersetup.revise}>
                  {item.type === "MangoWithdrawalPassSettings" ? (
                    isWithdrawPwd.value ? (
                      $t("mine_userinfo_set" /**已设置 */)
                    ) : (
                      <span style="color: #ff0000">{$t("mine_usersetup_notSet" /* 未设置 */)}</span>
                    )
                  ) : (
                    $t("common_change" /*修改*/)
                  )}
                </div>
                <div
                  class={usersetup.arrow}
                  v-show={isWithdrawPwd.value && item.type !== "MangoWithdrawalPassSettings"}
                >
                  <SvgIcon
                    class={usersetup.arrowIcon}
                    name="mangoArrowRight"
                    width="24px"
                    height="24px"
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
        {!!safety.value.length && (
          <ul class={usersetup.listView}>
            <li class={usersetup.listViewHeader}>
              <div class={usersetup.bar} />
              <span class={usersetup.listViewHeaderTitle}>
                {$t("mine_usersetup_safeSetting" /*安全验证*/)}
              </span>
            </li>
            {safety.value.map((item, index) => (
              <li
                class={[
                  usersetup.listViewItem,
                  index === safety.value.length - 1 && usersetup.listViewLastItem,
                  item.disabled && usersetup.listViewItemDisabled,
                ]}
                onClick={() => goDetail(item)}
              >
                <div class={usersetup.listViewItemLeft}>
                  <div class={usersetup.listViewItemIcon}>
                    <SvgIcon name={item.icon} width="24px" height="24px" />
                  </div>
                  <span class={usersetup.listViewItemTitle}>{$t(item.name)}</span>
                </div>

                {item.type ? (
                  <div class={usersetup.listViewItemLeft}>
                    <div class={usersetup.revise}>{item.status}</div>
                    <div class={usersetup.arrow} v-show={!item.disabled}>
                      <SvgIcon
                        class={usersetup.arrowIcon}
                        name="mangoArrowRight"
                        width="24px"
                        height="24px"
                      />
                    </div>
                  </div>
                ) : (
                  <CSwitch
                    v-model:open={isOpenWithdrawValidate.value}
                    onChange={onUpdateModelValue}
                  />
                )}
              </li>
            ))}
          </ul>
        )}
        {!!(config?.thirdLoginInfo?.whetherOpen && quickList.value.length) && (
          <ul class={usersetup.listView}>
            <li class={usersetup.listViewHeader}>
              <div class={usersetup.bar} />
              <span class={usersetup.listViewHeaderTitle}>
                {$t("mine_quick_login" /*快捷登录*/)}
              </span>
            </li>
            {quickList.value.map((item, index) => (
              <li
                class={[
                  usersetup.listViewItem,
                  index === safety.value.length - 1 && usersetup.listViewLastItem,
                ]}
                onClick={() => thirdQuickItem(item)}
              >
                <div class={usersetup.listViewItemLeft}>
                  <div class={usersetup.listViewItemIcon}>
                    <SvgIcon name={item.icon} width="24px" height="24px" />
                  </div>
                  <span class={usersetup.listViewItemTitle}>{$t(item.name)}</span>
                </div>

                <div class={usersetup.listViewItemLeft}>
                  <div class={usersetup.revise}>{$t(item.i18nKey)}</div>
                  <div class={usersetup.arrow}>
                    <SvgIcon
                      class={usersetup.arrowIcon}
                      name="mangoArrowRight"
                      width="24px"
                      height="24px"
                    />
                  </div>
                </div>
              </li>
            ))}
            <div class={usersetup.regRow}>
              <div class={usersetup.regText}>
                {$t("mine_usersetup_accountRegTime" /*账号注册时间*/)}
              </div>
              {registerTime.value}
            </div>
          </ul>
        )}

        <MangoTipsModal
          v-model:show={store.showWithdrawPasswordTipsModal}
          title={$t("message_withdraw_passwordTitle" /*温馨提示*/)}
          subTitle={$t("message_withdraw_passwordSubTitle" /*如需修改提现密码，请联系客服*/)}
          type="info"
          confirmText={$t("user_contactUs_actionTitle" /*联系客服*/)}
          cancelText={$t("common_cancel" /*取消*/)}
          confirmCallback={toCustomer}
        />

        <MangoTipsModal
          v-model:show={store.showBindPhoneTipsModal}
          title={$t("mine_center_noPhone" /*未绑定手机号*/)}
          subTitle={$t("funds_withdraw_safetyPhoneTips" /*为了更加安全，需要绑定您的手机号*/)}
          type="info"
          confirmText={$t("funds_withdraw_gotoBind" /*去绑定*/)}
          cancelText={$t("user_register_alert_cancel" /**取消 */)}
          confirmCallback={() =>
            mangoPopup({
              type: "MangoSecretPhone",
              title: $t("mine_usersetup_secretPhone" /*密保手机号*/),
            }).open()
          }
        />
        <MangoTipsModal
          v-model:show={safetyTipsModal.value}
          title={$t("message_withdraw_passwordTitle" /*温馨提示*/)}
          subTitle={safetyTipsModalText.value}
          type="info"
          confirmText={$t("user_contactUs_actionTitle" /**联系客服 */)}
          cancelText={$t("user_register_alert_cancel" /**取消 */)}
          confirmCallback={toCustomer}
        />
        {unBindThirdLogin()}
      </div>
    );
  },
});
