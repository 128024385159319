import { computed, reactive, ref, watch } from "vue";
import { Api } from "../services";
import { useToast } from "./useToast.hook";
import {
  TypesListItem,
  TaskListParams,
  TaskListItem,
  TaskData,
  TaskApplyParams,
} from "@kgsport-cms/service";
import { useUser } from "./useUser.hook";
const { toast } = useToast();

export interface TaskStore {
  typesList: TypesListItem[];
  data: TaskData;
  finished: boolean;
  loading: boolean;
  // 是否是web端常规分页（即有分页按钮时）
  isPagination: boolean;
}

const store = reactive<TaskStore>({
  typesList: [] as TypesListItem[],
  data: {} as TaskData,
  finished: false,
  loading: false,
  isPagination: false,
});

const params = reactive<TaskListParams>({
  type: 0,
  current: 1,
  size: 6,
  currency: "",
});
const activeTab = ref(1);

type Fn = (n: string | number, dec?: number) => number;
const round: Fn = (n, dec = 2) => window.Number(`${Math.round(Number(`${n}e${dec}`))}e-${dec}`);
export function useTask(prevType?: string) {
  const loading = ref(false);
  const { user } = useUser();
  const finished = computed(() => store.finished);
  const typesList = computed(() => store.typesList);
  const taskList = computed(() => store.data?.records || []);
  const currentRecords = computed(() => store.data?.currentRecords || []);
  const resetParams = () => {
    activeTab.value = 1;
    params.type = 1;
    params.current = 1;
  };
  // 改变isPagination参数
  const isPaginationHandler = (value: boolean) => {
    store.isPagination = value;
  };
  // 任务板块 tab
  const getMissionTypes = async () => {
    loading.value = true;
    try {
      const { data = [], message, result } = await Api.TaskService.types();
      loading.value = false;
      if (!result) {
        toast.error(message);
        return;
      }
      const arr = data?.filter((d: TypesListItem) => d.state === 1 && d.count > 0) || [];
      const type = arr.length ? (prevType ? Number(prevType) : arr?.[0]?.missionType ?? 0) : 0;
      params.type = type;
      activeTab.value = type;
      store.typesList = arr;
    } catch (e) {
      loading.value = false;
      console.log("获取任务板块失败", e);
    } finally {
      loading.value = false;
    }
  };
  /**
   * 获取任务列表
   * @param id 任务类型
   * @param current 当前请求列表页码
   */
  const getMissionList = async (id?: string | number, current?: number) => {
    // if (!typesList.value?.find(d => d.missionType === params.type)) return;
    if (!store.typesList.length) return;
    loading.value = true;
    const records = store.data?.records || [];
    try {
      const {
        data = {},
        message,
        result,
      } = await Api.TaskService.list(current ? { ...params, current } : params);
      if (!result) {
        !current && params.current === 1 && (store.data = {} as TaskData);
        toast.error(message);
        return;
      }
      if (params.current >= data.pages) store.finished = true;
      const arr =
        (data.records?.map((item: TaskListItem) => {
          item.current = params.current;
          const speed =
            ((item.details?.[item.currentNum - 1]?.currentCondition ?? 0) /
              (item.details?.[item.currentNum - 1]?.conditionReword ?? 0)) *
            100;
          item.speed = speed > 100 ? 100 : round(speed, 2);
          return item;
        }) as TaskListItem[]) || [];
      if (id) {
        const ind = store.data.records.findIndex(d => d.id === id);
        store.data.records[ind] = arr.find(d => d.id === id) || ({} as TaskListItem);
        return;
      }
      store.data = {
        ...data,
        records: params.current === 1 ? arr : [...records, ...arr],
        currentRecords: arr,
      };
      if (!store.isPagination) {
        params.current += 1;
      }
    } catch (e) {
      params.current === 1 && (store.data = {} as TaskData);
      console.log("获取任务列表失败", e);
    } finally {
      loading.value = false;
    }
  };
  const onRefresh = async () => {
    // 清空列表数据
    store.data = {} as TaskData;
    params.current = 1;
    store.finished = false;

    // 重新加载数据
    await getMissionList();
  };
  // 参加任务
  const getMissionApply = async (param: TaskApplyParams, current: number) => {
    try {
      const { message, result } = await Api.TaskService.apply(param);
      if (!result) {
        toast.error(message);
        return;
      }
      await getMissionList(param.hallId, current);
    } catch (e) {
      console.log("参加任务失败", e);
    } finally {
      //
    }
  };
  const getMissionApplyExt = async (param: TaskApplyParams, current: number) => {
    try {
      const { message, result } = await Api.TaskService.applyExt(param);
      if (!result) {
        toast.error(message);
        return;
      }
      await getMissionList(param.hallId, current);
    } catch (e) {
      console.log("参加任务失败", e);
    } finally {
      //
    }
  };

  watch(
    () => user.value?.currency as string,
    (n, o) => {
      if (n === o) return;
      params.currency = n;
    },
    { immediate: true }
  );

  return {
    store,
    round,
    activeTab,
    resetParams,
    loading,
    finished,
    params,
    typesList,
    taskList,
    currentRecords,
    getMissionTypes,
    getMissionList,
    onRefresh,
    getMissionApply,
    getMissionApplyExt,
    isPaginationHandler,
  };
}
