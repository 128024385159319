import { defineComponent, ref, PropType } from "vue";
import style from "./style.module.less";
import { useI18n, useRecharge, useWithdraw } from "core";
import { Divider } from "vant";
export default defineComponent({
  props: {
    tabVal: {
      type: String as PropType<string>,
      default: "deposit",
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { verificationWithdrawalPassword, verificationBindBankOrVirtual } = useWithdraw();
    const { store } = useRecharge();
    const tabValue = ref<string>(props.tabVal);
    const tabClick = async (item: string) => {
      if (item == "withdraw") {
        tabValue.value = item;
        emit("change", item);
        if (!(await verificationWithdrawalPassword())) return;
        if (!(await verificationBindBankOrVirtual())) return;
      } else {
        tabValue.value = item;
        emit("change", item);
      }
    };
    return () => (
      <>
        {["lam109"].includes(window.CONFIG?.name) ? (
          <div class={style.tab2} v-show={!store.visible}>
            <div
              class={[style.tab2Item, tabValue.value === "deposit" && style.tab2Active]}
              onClick={() => tabClick("deposit")}
            >
              <img src={require("./assets/rechange.png")} />
              <span>{$t("funds_wallet_recharge") /*充值*/}</span>
            </div>
            <div
              class={[style.tab2Item, tabValue.value === "withdraw" && style.tab2Active]}
              onClick={() => tabClick("withdraw")}
            >
              <img src={require("./assets/widthaw.png")} />
              <span>{$t("funds_wallet_withdraw") /*提现*/}</span>
            </div>
          </div>
        ) : (
          <div class={style.tabLeft}>
            <section
              class={[style.tabLeftItem, tabValue.value === "deposit" && style.tabLeftActive]}
              onClick={() => tabClick("deposit")}
            >
              {$t("funds_wallet_recharge") /*充值*/}
            </section>
            <section
              class={[style.tabLeftItem, tabValue.value === "withdraw" && style.tabLeftActive]}
              onClick={() => tabClick("withdraw")}
            >
              {$t("funds_wallet_withdraw") /*提现*/}
            </section>
          </div>
        )}
      </>
    );
  },
});
