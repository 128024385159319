import {
  defineComponent,
  PropType,
  provide,
  InjectionKey,
  useAttrs,
  Transition,
  computed,
  onMounted,
  reactive,
  watchEffect,
} from "vue";
import axios from "axios";
import {
  useConfigContext,
  isMobile,
  useI18n,
  useScrollLock,
  useLocalStorage,
  STORE_LADING,
} from "core";
import loading from "./loading.module.less";
import { noon } from "core";
import { Vue3Lottie } from "vue3-lottie";
export { loading };

export interface LoadingInjectionContext {
  visible: boolean;
  update: () => void;
  emit: (...arg: any[]) => void;
}
export const LoadingContext: LoadingInjectionContext = {
  visible: false,
  update: noon,
  emit: noon,
};
export const LOADING_INJECTION_KEY: InjectionKey<LoadingInjectionContext> = Symbol("loading");
export const Loading = defineComponent({
  name: "loadingView",
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loadingImg: {
      type: String,
      default: "",
    },
    loadingText: {
      type: String,
      default: "",
    },
    textStyle: {
      type: [Object, undefined, null] as PropType<object | undefined | null>,
      default: () => ({
        color: "#abafb1",
        fontWeight: "bold",
      }),
    },
  },
  inheritAttrs: false,
  emits: ["update:visible"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { localStore } = useLocalStorage();
    const store = reactive<any>({
      loadingJson: null,
    });
    const isLocked = useScrollLock(document.body);
    const { tsx } = useConfigContext();
    const loadingText = computed<string[]>(() => {
      const text: string = props.loadingText
        ? props.loadingText
        : $t("common_loading_text" /*正在加载中···*/);
      return text.split("");
    });
    const getLodingData = async () => {
      if (!tsx?.loading) return;
      const loadingJson = localStore.get(STORE_LADING);
      if (loadingJson) {
        return (store.loadingJson = loadingJson);
      }
      try {
        const { data } = await axios.get(
          tsx.loading[
            `${
              window.CONFIG?.currentModel === "mango" ? "mango" : window.cms_common?.themeDefault
            }_loadingData`
          ]
        );
        store.loadingJson = data;
        localStore.set(STORE_LADING, data, Date.now() + 10800);
      } catch (e) {
        console.log(e);
      }
    };
    const loadingJson = computed(() => {
      return JSON.parse(JSON.stringify(store.loadingJson));
    });
    const attr: any = useAttrs();
    provide(LOADING_INJECTION_KEY, {
      update: attr.update,
      visible: props.visible,
      emit,
    });
    const css_var: any = [];
    loadingText.value.forEach((item: string, index: number) => {
      css_var.push({ "--i": index });
    });
    watchEffect(() => {
      isLocked.value = props.visible;
    });
    onMounted(async () => {
      await getLodingData();
    });
    return () => (
      <Transition name="fade" appear>
        <div
          class={[loading.loading, isMobile ? loading.h5Loading : loading.webLoading]}
          v-show={props.visible}
        >
          <div
            class={[
              loading.loadingModel,
              isMobile ? loading.loadingH5Model : loading.loadingWebModel,
            ]}
          >
            {props.loadingImg ? (
              <div class={loading.loadingModelImg}>
                <img src={props.loadingImg} />
              </div>
            ) : (
              <div class={loading.loadingModelImg}>
                {loadingJson.value ? (
                  <Vue3Lottie
                    animationData={loadingJson.value}
                    loop={true}
                    autoPlay={true}
                    v-show={loadingJson.value}
                  />
                ) : null}
              </div>
            )}
            <div class={loading.loadingModelText} style={{ ...props.textStyle }}>
              {loadingText.value.map((text: string, i: number) => (
                <i style={css_var[i]}>{text}</i>
              ))}
            </div>
          </div>
        </div>
      </Transition>
    );
  },
});
