/**
 * 1、获取系统消息列表
 * 2、获取未读消息数量
 * 3、删除指定消息
 * 4、已读消息
 * 5、
 */
import { reactive, onMounted, computed, ComputedRef, watch } from "vue";
import { Api, useToast, useAuth, useConfig, awaitWrap, useConfigContext, useI18n } from "core";
import { useRoute, useRouter } from "vue-router";
import { NoticesItem, MgessageItem } from "@kgsport-cms/service";

export interface MessageStore {
  messageList: MgessageItem[];
  noticeList: any[];
  isEdit: boolean;
  ids: number[];
  message: null | any;
  activeTab: number;
  type: string;
  current: number;
  size: number;
  total: number;
  pages: number;
  unRead: number;
  loading: boolean;
}

// 消息类型（1.系统消息、2.平台公告、3.个人弹窗消息）
export const tabList: any[] = [
  { name: "mine_message_discountsNotic" /*优惠通知*/, type: "discount" },
  { name: "mine_message_platformNotic" /*平台公告*/, type: "notice" },
  { name: "mine_message_inbox" /*收件箱*/, type: "message" },
];
export const tabMongoList: any[] = [
  // { name: "mine_message_discountsNotic" /*优惠通知*/, type: "discount" },
  { name: "mine_message_platformNotic" /*平台公告*/, type: "notice" },
  { name: "common_notice" /*通知*/, type: "message" },
];

const store = reactive<MessageStore>({
  messageList: [],
  noticeList: [],
  ids: [],
  isEdit: false,
  message: null,
  activeTab: 0,
  type: "message",
  // 分页信息
  current: 1,
  size: 15,
  total: 0,
  pages: 0,
  unRead: 0,
  loading: false,
});
export function useMessage() {
  const { $t } = useI18n();
  const { toast } = useToast();
  const { isPc } = useConfig();
  const router = useRouter();
  const { isLogin } = useAuth();
  const { updateMessageCount } = useConfigContext();
  const messageType = computed(() => store.type);
  const unRead = computed(() => store.unRead);
  const isLoading = computed(() => store.loading);
  const isSelectAll = computed(
    () => store.ids.length === store.messageList.length && store.messageList.length !== 0
  );
  const changeEdit = () => {
    store.isEdit = !store.isEdit;
    !store.isEdit && (store.ids = []);
  };
  const changeTab = (index: number) => {
    store.activeTab = index;
  };
  const itemAciveSwitch = (id: number) => {
    if (store.ids.includes(id)) {
      const index = store.ids.findIndex(item => item === id);
      if (index == undefined) return;
      store.ids.splice(index, 1);
    } else {
      store.ids.push(id);
    }
  };
  // 全 部已读
  const handleAllRead = async () => {
    const loadToast = toast.loading($t("message_loading_text" /*更新中*/));
    const { result } = await Api.MessageService.allRead();
    loadToast();
    if (result) {
      store.ids = [];
      store.isEdit = false;
      // 刷新数据
      store.current = 1;
      setTimeout(() => {
        getMessageList();
        getMessageCount();
      }, 500);
    }
  };

  // 某条消息已读
  const handleRead = async (params?: { idList: string } | null, callBack?: Function) => {
    let param = params;
    if (!param) {
      if (store.ids.length <= 0) return;
      param = { idList: store.ids.join(",") };
    }
    const loadToast = toast.loading($t("message_loading_text" /*更新中*/));
    const { result } = await Api.MessageService.read(param as never);
    loadToast();
    if (result) {
      store.ids = [];
      store.isEdit = false;
      // 刷新数据
      store.current = 1;
      setTimeout(() => {
        if (callBack) {
          callBack();
        } else {
          getMessageList();
        }
        getMessageCount();
      }, 500);
    }
  };

  // 删除指定消息
  const removeMsg = async (params?: { idList: string } | null, callBack?: Function) => {
    if (!params) {
      if (store.ids.length <= 0) return;
      params = { idList: store.ids.join(",") };
    }

    const loadToast = toast.loading($t("message_del_text" /*删除中*/));
    const { result } = await Api.MessageService.remove(params);
    loadToast();
    if (result) {
      store.ids = [];
      store.isEdit = false;
      // 刷新数据
      store.current = 1;
      if (callBack) {
        callBack();
      } else {
        getMessageList();
      }
    }
  };

  const selectAll = () => {
    store.ids = isSelectAll.value ? [] : store.messageList.map(item => item.messageId);
  };
  const itemDesc = (item: any) => {
    store.message = item;
  };
  // 获取系统消息列表
  const getMessageList = async () => {
    try {
      store.loading = true;
      const params = {
        current: store.current,
        size: store.size || 15,
      };
      const { data, code, result } = await Api.MessageService.list(params);
      if (!result) return;
      if (isPc.value) {
        // 芒果模版数据兼容
        store.messageList =
          window.CONFIG?.currentModel === "mango"
            ? data.current == 1
              ? data.records
              : [...store.messageList, ...data.records]
            : (store.messageList = data.records);
      } else {
        store.messageList =
          data.current == 1 ? data.records : [...store.messageList, ...data.records];
      }
      // store.messageList.sort((a, b) => a.readStatus - b.readStatus);
      store.size = data.size;
      store.current = data.current;
      store.total = data.total;
      store.pages = data.pages;
    } catch (e) {
      console.log(e);
    } finally {
      store.loading = false;
    }
  };
  // 获取未读消息数量
  const getMessageCount = async () => {
    if (!isLogin.value) return false;
    const [_, response] = await awaitWrap(Api.MessageService.count());
    if (response) {
      const { data, result } = response;
      if (result) {
        store.unRead = data;
        updateMessageCount(data);
      }
    }
  };

  const changeMeassagePage = (current: number, size?: number) => {
    store.current = current;
    store.size = size || 15;
    getMessageList();
  };

  const pathToDetail = ({
    id,
    type,
    readStatus,
  }: {
    id: string;
    type: string;
    readStatus?: string;
  }) => {
    store.type = type;
    if (window.CONFIG?.currentModel === "mango") return;
    router.push({
      path: "/message/detail",
      query: { id, type, readStatus },
    });
  };

  const pathToDetailForPc = ({
    id,
    type,
    readStatus,
  }: {
    id: string;
    type: string;
    readStatus?: string;
  }) => {
    router.push({
      path: "/account/messageDetails",
      query: { id, type, readStatus },
    });
  };
  watch(
    () => store.type,
    (a, b) => {
      console.warn(a, b, "a,b");
      // getMessageList();
    }
  );
  onMounted(() => {
    getMessageCount();
  });

  return {
    store,
    tabList,
    tabMongoList,
    pathToDetail,
    handleRead,
    handleAllRead,
    itemDesc,
    pathToDetailForPc,
    changeEdit,
    changeTab,
    getMessageList,
    itemAciveSwitch,
    selectAll,
    removeMsg,
    isSelectAll,
    getMessageCount,
    unRead,
    messageType,
    isLoading,
    changeMeassagePage,
  };
}

// 消息详情
export function useMessageDetail() {
  const route = useRoute();
  // const { toast } = useToast();
  const { query } = route || {};
  const state = reactive<{ info: NoticesItem }>({
    info: {
      beginTime: 0,
      content: "",
      enable: true,
      endDate: 0,
      id: 0,
      noticeType: "",
      summary: "",
      title: "",
      type: 0,
      messageId: 0,
    },
  });
  const detailsQuery = computed(() => {
    return (
      (route && route.query) || {
        id: "",
        type: "",
        readStatus: "",
      }
    );
  }) as ComputedRef<{ id: string; type: string; readStatus?: string }>;
  const meassageType = computed<string>(() => query?.type as string);
  const getMessageInfo = async (pId?: number, pType?: string) => {
    const { id, type } = detailsQuery.value;
    const paramsId = pId && pId > 0 ? pId : id;
    const parmasType = pType ? pType : type;
    if (parmasType === "message") {
      // 获取系统消息详细
      const { data, result } = await Api.MessageService.detail({ id: paramsId });
      if (result) {
        state.info = data;
        state.info.beginTime = (data as any).createTime || 0;
      }
    } else {
      // 获取公告详细
      const { data, result } = await Api.MessageService.sysDetail({ id: paramsId });
      if (result) {
        state.info = data;
      }
    }
  };

  return { state, meassageType, detailsQuery, getMessageInfo };
}
