const jbbGreen = {
  jbbGreen_home: () => import(/* webpackChunkName: "green-activity" */ "./jbbGreen/home.json"),
  jbbGreen_home1: () => import(/* webpackChunkName: "green-activity" */ "./jbbGreen/home1.json"),
  jbbGreen_activity: () =>
    import(/* webpackChunkName: "green-activity" */ "./jbbGreen/activity.json"),
  jbbGreen_asport: () => import(/* webpackChunkName: "green-asport" */ "./jbbGreen/asport.json"),
  jbbGreen_betRecord: () => import(/* webpackChunkName: "green-bet" */ "./jbbGreen/bet.json"),
  jbbGreen_chess: () => import(/* webpackChunkName: "green-chess" */ "./jbbGreen/chess.json"),
  jbbGreen_customer: () =>
    import(/* webpackChunkName: "green-customer" */ "./jbbGreen/customer.json"),
  jbbGreen_egame: () => import(/* webpackChunkName: "green-egame" */ "./jbbGreen/egame.json"),
  jbbGreen_esport: () => import(/* webpackChunkName: "green-esport" */ "./jbbGreen/esport.json"),
  jbbGreen_game: () => import(/* webpackChunkName: "green-game" */ "./jbbGreen/game.json"),
  jbbGreen_lottery: () => import(/* webpackChunkName: "green-lottery" */ "./jbbGreen/lottery.json"),
  jbbGreen_account: () => import(/* webpackChunkName: "green-mine" */ "./jbbGreen/mine.json"),
  jbbGreen_real: () => import(/* webpackChunkName: "green-real" */ "./jbbGreen/real.json"),
  jbbGreen_sport: () => import(/* webpackChunkName: "green-sport" */ "./jbbGreen/sport.json"),
  jbbGreen_sport3: () => import(/* webpackChunkName: "green-sport" */ "./jbbGreen/sport3.json"),
  jbbGreen_recharge: () =>
    import(/* webpackChunkName: "green-recharge" */ "./jbbGreen/recharge.json"),
  jbbGreen_tsport: () => import(/* webpackChunkName: "green-tsport" */ "./jbbGreen/tsport.json"),
  jbbGreen_square: () => import(/* webpackChunkName: "green-square" */ "./jbbGreen/square.json"),
  jbbGreen_hunter: () => import(/* webpackChunkName: "green-hunter" */ "./jbbGreen/hunter.json"),
  // jbbGreen_sponsor: () => import(/* webpackChunkName: "green-sponsor" */ "./jbbGreen/sponsor.json"),
  jbbGreen_chat: () => import(/* webpackChunkName: "green_chat" */ "./jbbGreen/chat.json"),
};
const defaults = {
  default_home: () => import(/* webpackChunkName: "green-activity" */ "./default/home.json"),
  default_home1: () => import(/* webpackChunkName: "green-activity" */ "./default/home1.json"),
  default_activity: () =>
    import(/* webpackChunkName: "green-activity" */ "./default/activity.json"),
  default_asport: () => import(/* webpackChunkName: "green-asport" */ "./default/asport.json"),
  default_betRecord: () => import(/* webpackChunkName: "green-bet" */ "./default/bet.json"),
  default_chess: () => import(/* webpackChunkName: "green-chess" */ "./default/chess.json"),
  default_customer: () =>
    import(/* webpackChunkName: "green-customer" */ "./default/customer.json"),
  default_egame: () => import(/* webpackChunkName: "green-egame" */ "./default/egame.json"),
  default_esport: () => import(/* webpackChunkName: "green-esport" */ "./default/esport.json"),
  default_game: () => import(/* webpackChunkName: "green-game" */ "./default/game.json"),
  default_lottery: () => import(/* webpackChunkName: "green-lottery" */ "./default/lottery.json"),
  default_account: () => import(/* webpackChunkName: "green-mine" */ "./default/mine.json"),
  default_real: () => import(/* webpackChunkName: "green-real" */ "./default/real.json"),
  default_sport: () => import(/* webpackChunkName: "green-sport" */ "./default/sport.json"),
  default_sport3: () => import(/* webpackChunkName: "green-sport" */ "./default/sport3.json"),
  default_recharge: () =>
    import(/* webpackChunkName: "green-recharge" */ "./default/recharge.json"),
  default_tsport: () => import(/* webpackChunkName: "green-tsport" */ "./default/tsport.json"),
  default_square: () => import(/* webpackChunkName: "green-square" */ "./default/square.json"),
  default_hunter: () => import(/* webpackChunkName: "green-hunter" */ "./default/hunter.json"),
  // jbbGreen_sponsor: () => import(/* webpackChunkName: "green-sponsor" */ "./jbbGreen/sponsor.json"),
  default_chat: () => import(/* webpackChunkName: "green_chat" */ "./default/chat.json"),
  default_csport: () => import(/* webpackChunkName: "green_chat" */ "./default/csport.json"),
  default_sport1: () => import(/* webpackChunkName: "green_chat" */ "./default/sport1.json"),
  default_home2: () => import(/* webpackChunkName: "green_chat" */ "./default/home2.json"),
  default_game1: () => import(/* webpackChunkName: "green_chat" */ "./default/game1.json"),
  default_sport2: () => import(/* webpackChunkName: "green_chat" */ "./default/sport2.json"),
  default_bet1: () => import(/* webpackChunkName: "green_chat" */ "./default/bet1.json"),
  default_mine1: () => import(/* webpackChunkName: "green_chat" */ "./default/mine1.json"),
};
const orange = {
  orange_home: () => import(/* webpackChunkName: "green-activity" */ "./orange/home.json"),
  orange_home1: () => import(/* webpackChunkName: "green-activity" */ "./orange/home1.json"),
  orange_activity: () => import(/* webpackChunkName: "green-activity" */ "./orange/activity.json"),
  orange_asport: () => import(/* webpackChunkName: "green-asport" */ "./orange/asport.json"),
  orange_betRecord: () => import(/* webpackChunkName: "green-bet" */ "./orange/bet.json"),
  orange_chess: () => import(/* webpackChunkName: "green-chess" */ "./orange/chess.json"),
  orange_customer: () => import(/* webpackChunkName: "green-customer" */ "./orange/customer.json"),
  orange_egame: () => import(/* webpackChunkName: "green-egame" */ "./orange/egame.json"),
  orange_esport: () => import(/* webpackChunkName: "green-esport" */ "./orange/esport.json"),
  orange_game: () => import(/* webpackChunkName: "green-game" */ "./orange/game.json"),
  orange_lottery: () => import(/* webpackChunkName: "green-lottery" */ "./orange/lottery.json"),
  orange_account: () => import(/* webpackChunkName: "green-mine" */ "./orange/mine.json"),
  orange_real: () => import(/* webpackChunkName: "green-real" */ "./orange/real.json"),
  orange_sport: () => import(/* webpackChunkName: "green-sport" */ "./orange/sport.json"),
  orange_sport3: () => import(/* webpackChunkName: "green-sport" */ "./orange/sport3.json"),
  orange_recharge: () => import(/* webpackChunkName: "green-recharge" */ "./orange/recharge.json"),
  orange_tsport: () => import(/* webpackChunkName: "green-tsport" */ "./orange/tsport.json"),
  orange_square: () => import(/* webpackChunkName: "green-square" */ "./orange/square.json"),
  orange_hunter: () => import(/* webpackChunkName: "green-hunter" */ "./orange/hunter.json"),
  // jbbGreen_sponsor: () => import(/* webpackChunkName: "green-sponsor" */ "./jbbGreen/sponsor.json"),
  orange_chat: () => import(/* webpackChunkName: "green_chat" */ "./orange/chat.json"),
  orange_sport1: () => import(/* webpackChunkName: "green_chat" */ "./orange/sport1.json"),
  orange_home2: () => import(/* webpackChunkName: "green_chat" */ "./orange/home2.json"),
  orange_game1: () => import(/* webpackChunkName: "green_chat" */ "./orange/game1.json"),
  orange_sport2: () => import(/* webpackChunkName: "green_chat" */ "./orange/sport2.json"),
  orange_bet1: () => import(/* webpackChunkName: "green_chat" */ "./orange/bet1.json"),
  orange_mine1: () => import(/* webpackChunkName: "green_chat" */ "./orange/mine1.json"),
};
const purple = {
  purple_home: () => import(/* webpackChunkName: "green-activity" */ "./purple/home.json"),
  purple_home1: () => import(/* webpackChunkName: "green-activity" */ "./purple/home1.json"),
  purple_activity: () => import(/* webpackChunkName: "green-activity" */ "./purple/activity.json"),
  purple_asport: () => import(/* webpackChunkName: "green-asport" */ "./purple/asport.json"),
  purple_betRecord: () => import(/* webpackChunkName: "green-bet" */ "./purple/bet.json"),
  purple_chess: () => import(/* webpackChunkName: "green-chess" */ "./purple/chess.json"),
  purple_customer: () => import(/* webpackChunkName: "green-customer" */ "./purple/customer.json"),
  purple_egame: () => import(/* webpackChunkName: "green-egame" */ "./purple/egame.json"),
  purple_esport: () => import(/* webpackChunkName: "green-esport" */ "./purple/esport.json"),
  purple_game: () => import(/* webpackChunkName: "green-game" */ "./purple/game.json"),
  purple_lottery: () => import(/* webpackChunkName: "green-lottery" */ "./purple/lottery.json"),
  purple_account: () => import(/* webpackChunkName: "green-mine" */ "./purple/mine.json"),
  purple_real: () => import(/* webpackChunkName: "green-real" */ "./purple/real.json"),
  purple_sport: () => import(/* webpackChunkName: "green-sport" */ "./purple/sport.json"),
  purple_recharge: () => import(/* webpackChunkName: "green-recharge" */ "./purple/recharge.json"),
  purple_tsport: () => import(/* webpackChunkName: "green-tsport" */ "./purple/tsport.json"),
  purple_square: () => import(/* webpackChunkName: "green-square" */ "./purple/square.json"),
  purple_hunter: () => import(/* webpackChunkName: "green-hunter" */ "./purple/hunter.json"),
  // jbbGreen_sponsor: () => import(/* webpackChunkName: "green-sponsor" */ "./jbbGreen/sponsor.json"),
  purple_chat: () => import(/* webpackChunkName: "green_chat" */ "./purple/chat.json"),
  purple_sport1: () => import(/* webpackChunkName: "green_chat" */ "./purple/sport1.json"),
  purple_sport3: () => import(/* webpackChunkName: "green_chat" */ "./purple/sport3.json"),
};
const darkBlue = {
  darkBlue_home: () => import(/* webpackChunkName: "green-activity" */ "./darkBlue/home.json"),
  darkBlue_home1: () => import(/* webpackChunkName: "green-activity" */ "./darkBlue/home1.json"),
  darkBlue_activity: () =>
    import(/* webpackChunkName: "green-activity" */ "./darkBlue/activity.json"),
  darkBlue_asport: () => import(/* webpackChunkName: "green-asport" */ "./darkBlue/asport.json"),
  darkBlue_betRecord: () => import(/* webpackChunkName: "green-bet" */ "./darkBlue/bet.json"),
  darkBlue_chess: () => import(/* webpackChunkName: "green-chess" */ "./darkBlue/chess.json"),
  darkBlue_customer: () =>
    import(/* webpackChunkName: "green-customer" */ "./darkBlue/customer.json"),
  darkBlue_egame: () => import(/* webpackChunkName: "green-egame" */ "./darkBlue/egame.json"),
  darkBlue_esport: () => import(/* webpackChunkName: "green-esport" */ "./darkBlue/esport.json"),
  darkBlue_game: () => import(/* webpackChunkName: "green-game" */ "./darkBlue/game.json"),
  darkBlue_lottery: () => import(/* webpackChunkName: "green-lottery" */ "./darkBlue/lottery.json"),
  darkBlue_account: () => import(/* webpackChunkName: "green-mine" */ "./darkBlue/mine.json"),
  darkBlue_real: () => import(/* webpackChunkName: "green-real" */ "./darkBlue/real.json"),
  darkBlue_sport: () => import(/* webpackChunkName: "green-sport" */ "./darkBlue/sport.json"),
  darkBlue_recharge: () =>
    import(/* webpackChunkName: "green-recharge" */ "./darkBlue/recharge.json"),
  darkBlue_tsport: () => import(/* webpackChunkName: "green-tsport" */ "./darkBlue/tsport.json"),
  darkBlue_square: () => import(/* webpackChunkName: "green-square" */ "./darkBlue/square.json"),
  darkBlue_hunter: () => import(/* webpackChunkName: "green-hunter" */ "./darkBlue/hunter.json"),
  // jbbGreen_sponsor: () => import(/* webpackChunkName: "green-sponsor" */ "./jbbGreen/sponsor.json"),
  darkBlue_chat: () => import(/* webpackChunkName: "green_chat" */ "./darkBlue/chat.json"),
  darkBlue_sport1: () => import(/* webpackChunkName: "green_chat" */ "./darkBlue/sport1.json"),
  darkBlue_sport3: () => import(/* webpackChunkName: "green_chat" */ "./darkBlue/sport3.json"),
  darkBlue_home2: () => import(/* webpackChunkName: "green_chat" */ "./darkBlue/home2.json"),
  darkBlue_game1: () => import(/* webpackChunkName: "green_chat" */ "./darkBlue/game1.json"),
  darkBlue_sport2: () => import(/* webpackChunkName: "green_chat" */ "./darkBlue/sport2.json"),
  darkBlue_bet1: () => import(/* webpackChunkName: "green_chat" */ "./darkBlue/bet1.json"),
  darkBlue_mine1: () => import(/* webpackChunkName: "green_chat" */ "./darkBlue/mine1.json"),
  darkBlue_mine2: () => import(/* webpackChunkName: "green_chat" */ "./darkBlue/mine2.json"),
  darkBlue_mibo_home: () =>
    import(/* webpackChunkName: "green_chat" */ "./darkBlue/mibo_home.json"),
  darkBlue_mibo_bet: () => import(/* webpackChunkName: "green_chat" */ "./darkBlue/mibo_bet.json"),
  darkBlue_mibo_activity: () =>
    import(/* webpackChunkName: "green_chat" */ "./darkBlue/mibo_activity.json"),
  darkBlue_mibo_recharge: () =>
    import(/* webpackChunkName: "green_chat" */ "./darkBlue/mibo_recharge.json"),
  darkBlue_mibo_mine: () =>
    import(/* webpackChunkName: "green_chat" */ "./darkBlue/mibo_mine.json"),
};
const pink = {
  pink_home: () => import(/* webpackChunkName: "green-activity" */ "./pink/home.json"),
  pink_home1: () => import(/* webpackChunkName: "green-activity" */ "./pink/home1.json"),
  pink_activity: () => import(/* webpackChunkName: "green-activity" */ "./pink/activity.json"),
  pink_asport: () => import(/* webpackChunkName: "green-asport" */ "./pink/asport.json"),
  pink_betRecord: () => import(/* webpackChunkName: "green-bet" */ "./pink/bet.json"),
  pink_chess: () => import(/* webpackChunkName: "green-chess" */ "./pink/chess.json"),
  pink_customer: () => import(/* webpackChunkName: "green-customer" */ "./pink/customer.json"),
  pink_egame: () => import(/* webpackChunkName: "green-egame" */ "./pink/egame.json"),
  pink_esport: () => import(/* webpackChunkName: "green-esport" */ "./pink/esport.json"),
  pink_game: () => import(/* webpackChunkName: "green-game" */ "./pink/game.json"),
  pink_lottery: () => import(/* webpackChunkName: "green-lottery" */ "./pink/lottery.json"),
  pink_account: () => import(/* webpackChunkName: "green-mine" */ "./pink/mine.json"),
  pink_real: () => import(/* webpackChunkName: "green-real" */ "./pink/real.json"),
  pink_sport: () => import(/* webpackChunkName: "green-sport" */ "./pink/sport.json"),
  pink_recharge: () => import(/* webpackChunkName: "green-recharge" */ "./pink/recharge.json"),
  pink_tsport: () => import(/* webpackChunkName: "green-tsport" */ "./pink/tsport.json"),
  pink_square: () => import(/* webpackChunkName: "green-square" */ "./pink/square.json"),
  pink_hunter: () => import(/* webpackChunkName: "green-hunter" */ "./pink/hunter.json"),
  // jbbGreen_sponsor: () => import(/* webpackChunkName: "green-sponsor" */ "./jbbGreen/sponsor.json"),
  pink_chat: () => import(/* webpackChunkName: "green_chat" */ "./pink/chat.json"),
  pink_sport1: () => import(/* webpackChunkName: "green_chat" */ "./pink/sport1.json"),
  pink_home2: () => import(/* webpackChunkName: "green_chat" */ "./pink/home2.json"),
  pink_game1: () => import(/* webpackChunkName: "green_chat" */ "./pink/game1.json"),
  pink_sport2: () => import(/* webpackChunkName: "green_chat" */ "./pink/sport2.json"),
  pink_sport3: () => import(/* webpackChunkName: "green_chat" */ "./pink/sport3.json"),
  pink_bet1: () => import(/* webpackChunkName: "green_chat" */ "./pink/bet1.json"),
  pink_mine1: () => import(/* webpackChunkName: "green_chat" */ "./pink/mine1.json"),
};
const brown = {
  brown_home: () => import(/* webpackChunkName: "green-activity" */ "./brown/home.json"),
  brown_home1: () => import(/* webpackChunkName: "green-activity" */ "./brown/home1.json"),
  brown_activity: () => import(/* webpackChunkName: "green-activity" */ "./brown/activity.json"),
  brown_asport: () => import(/* webpackChunkName: "green-asport" */ "./brown/asport.json"),
  brown_betRecord: () => import(/* webpackChunkName: "green-bet" */ "./brown/bet.json"),
  brown_chess: () => import(/* webpackChunkName: "green-chess" */ "./brown/chess.json"),
  brown_customer: () => import(/* webpackChunkName: "green-customer" */ "./brown/customer.json"),
  brown_egame: () => import(/* webpackChunkName: "green-egame" */ "./brown/egame.json"),
  brown_esport: () => import(/* webpackChunkName: "green-esport" */ "./brown/esport.json"),
  brown_game: () => import(/* webpackChunkName: "green-game" */ "./brown/game.json"),
  brown_lottery: () => import(/* webpackChunkName: "green-lottery" */ "./brown/lottery.json"),
  brown_account: () => import(/* webpackChunkName: "green-mine" */ "./brown/mine.json"),
  brown_real: () => import(/* webpackChunkName: "green-real" */ "./brown/real.json"),
  brown_sport: () => import(/* webpackChunkName: "green-sport" */ "./brown/sport.json"),
  brown_sport3: () => import(/* webpackChunkName: "green-sport" */ "./brown/sport3.json"),
  brown_recharge: () => import(/* webpackChunkName: "green-recharge" */ "./brown/recharge.json"),
  brown_tsport: () => import(/* webpackChunkName: "green-tsport" */ "./brown/tsport.json"),
  brown_square: () => import(/* webpackChunkName: "green-square" */ "./brown/square.json"),
  brown_hunter: () => import(/* webpackChunkName: "green-hunter" */ "./brown/hunter.json"),
  // jbbGreen_sponsor: () => import(/* webpackChunkName: "green-sponsor" */ "./jbbGreen/sponsor.json"),
  brown_chat: () => import(/* webpackChunkName: "green_chat" */ "./brown/chat.json"),
  brown_sport1: () => import(/* webpackChunkName: "green_chat" */ "./brown/sport1.json"),
  brown_home2: () => import(/* webpackChunkName: "green_chat" */ "./brown/home2.json"),
  brown_game1: () => import(/* webpackChunkName: "green_chat" */ "./brown/game1.json"),
  brown_sport2: () => import(/* webpackChunkName: "green_chat" */ "./brown/sport2.json"),
  brown_bet1: () => import(/* webpackChunkName: "green_chat" */ "./brown/bet1.json"),
  brown_mine1: () => import(/* webpackChunkName: "green_chat" */ "./brown/mine1.json"),
};
export default {
  ...jbbGreen,
  ...defaults,
  ...orange,
  ...purple,
  ...darkBlue,
  ...pink,
  ...brown,
};
