import { defineComponent, reactive, PropType, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { Swipe, SwipeItem, Icon } from "vant";
import { Carousel } from "ant-design-vue";
import { Image } from "commom";
import { ActivityListItem } from "@kgsport-cms/service";
import {
  isMobile,
  DataType,
  useI18n,
  useLocalStorage,
  STORE_PERSONAL_POPUP,
  STORE_ACTIVITY_POPUP,
  todayTime,
} from "core";
import "swiper/css";
import "swiper/css/navigation";
import style from "../pcPopup.module.less";
import h5Style from "../MobileStyle/mobilePopup.module.less";

interface StoreType {
  currentIndex: number;
  tipsStatus: boolean;
}
type noon = () => void;
export default defineComponent({
  props: {
    data: {
      type: Array as PropType<DataType[] | ActivityListItem[]>,
      default: () => [],
    },
    handClose: {
      type: Function as PropType<noon>,
      default: () => {},
    },
    type: {
      type: String as PropType<"my" | "activity">,
      default: "my",
    },
  },

  setup(props) {
    const { $t } = useI18n();
    const router = useRouter();
    const { localStore } = useLocalStorage();
    const swiperRef = ref<any>();
    const store = reactive<StoreType>({
      currentIndex: 0,
      // 弹窗今日提醒状态
      tipsStatus: false,
    });
    const isMyPopup = props.type === "my";
    // const isMyPopup = false
    const selectNav = (item: DataType, index: number) => {
      store.currentIndex = index;
    };
    const goUrl = (id: string) => {
      props.handClose();
      router.push(`/activity/detail?id=${id}`);
    };
    const checkTodayNoTips = () => {
      store.tipsStatus = !store.tipsStatus;
      const key = isMyPopup ? STORE_PERSONAL_POPUP : STORE_ACTIVITY_POPUP;
      if (store.tipsStatus) {
        localStore.set(key, Date.now() + "", todayTime());
      } else {
        localStore.remove(key);
      }
    };
    const loadIframe = (id: string) => {
      const iframeBox: any = document.getElementById(id);
      const doc = iframeBox.contentWindow?.document;
      let pEl: [any] = doc.getElementsByTagName("p");
      for (var i = 0; i < pEl.length; i++) {
        pEl[i].style.color = "#fff";
      }
    };
    const H5Render = (props: DataType[], close: noon) => {
      const RenderIframe = (item: DataType) => (
        <div class={h5Style.h5PopupIframebox} v-html={item.content} />
      );

      const RenderImage = (item: DataType) => (
        <div
          class={h5Style.h5PopupImgbox}
          onClick={() => {
            if (isMyPopup && item.whetherJump && item.jumpUrl) {
              window.location.href = item.jumpUrl;
            }
          }}
        >
          <img src={item.popupPic || item.appImage || ""} />
        </div>
      );

      const RenderContent = (item: DataType) => {
        if (item.showType === 3) return RenderImage(item);
        if (item.unId) return RenderImage(item);
        return RenderIframe(item);
      };

      const indicatorNum = computed(() => {
        const len = props?.length ?? 0;
        return Array.from(new Array(len > 5 ? 5 : len)).map((_, i) => i);
      });
      return (
        <div class={isMyPopup ? h5Style.h5Popup : [h5Style.h5Popup, h5Style.h5PopupNobg]}>
          <div class={style.h5PopupBody}>
            <Swipe
              loop
              touchable
              ref={swiperRef}
              v-slots={{
                indicator({ active }: { active: number }) {
                  if (isMyPopup && indicatorNum.value.length > 1) {
                    return (
                      <div class={h5Style.h5PopupIndicator}>
                        {indicatorNum.value.map(i => (
                          <section
                            class={[
                              h5Style.h5PopupIndicatorItem,
                              (active < 5 ? active === i : active - 5 == i) &&
                                h5Style.h5PopupIndicatorActive,
                            ]}
                          >
                            {active}
                          </section>
                        ))}
                      </div>
                    );
                  } else {
                    return null;
                  }
                },
              }}
            >
              {props.map(item => (
                <SwipeItem key={item.id}>
                  {isMyPopup && <div class={h5Style.h5PopupHeader}>{item.title}</div>}
                  <div>
                    <div onClick={() => item.unId && goUrl(item.unId)}>{RenderContent(item)}</div>
                  </div>
                </SwipeItem>
              ))}
            </Swipe>
          </div>
          <div class={h5Style.bottomClose}>
            <Icon
              class={h5Style.h5PopupHeaderClose}
              name="cross"
              color="#5c6f84"
              size="20px"
              onClick={() => close()}
            />
          </div>
        </div>
      );
    };

    const PcRender = (props: DataType[], close: noon) => {
      const RenderIframe = (item: DataType) => (
        <div class={style.h5PopupIframebox2} v-html={item.content} />
      );
      const RenderImage = (item: DataType) => (
        <div
          class={style.h5PopupImgbox}
          onClick={() => {
            if (isMyPopup && item.whetherJump && item.jumpUrl) {
              window.location.href = item.jumpUrl;
            }
          }}
        >
          <img src={item.popupPic || item.appImage || ""} />
        </div>
      );

      const RenderContent = (item: DataType) => {
        if (item.showType === 3) return RenderImage(item);
        if (item.unId) return RenderImage(item);
        return RenderIframe(item);
      };

      const indicatorNum = computed(() => {
        const len = props?.length ?? 0;
        return Array.from(new Array(len > 5 ? 5 : len)).map((_, i) => i);
      });

      const prevSwipe = () => {
        swiperRef.value.prev();
      };

      const nextSwipe = () => {
        swiperRef.value.next();
      };
      return (
        <div class={[style.h5Popup, style.h5PopupNobg, style.h5PopupNew]}>
          <Carousel
            ref={swiperRef}
            dots={isMyPopup && indicatorNum.value.length > 1}
            dotsClass={style.pcdot}
            draggable
          >
            {props.map(item => (
              <div>
                <div class={style.h5PopupNewHeader}>
                  <h1>{item.title}</h1>
                </div>
                <div class={style.h5PopupNewBody}>
                  <div key={item.id}>
                    <div onClick={() => item.unId && goUrl(item.unId)}>{RenderContent(item)}</div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
          {/* {isMyPopup && props.length > 1 ? (
            <div class={style.actionbox}>
              <div class={style.prevbtn} onClick={prevSwipe}></div>
              <div class={style.nextbtn} onClick={nextSwipe}></div>
            </div>
          ) : null} */}
          <div class={style.rgClose}>
            <Icon
              class={style.h5PopupHeaderClose}
              name="cross"
              color="#5c6f84"
              size="20px"
              onClick={() => close()}
            />
          </div>
        </div>
      );
    };
    return () =>
      isMobile ? H5Render(props.data, props.handClose) : PcRender(props.data, props.handClose);
  },
});
