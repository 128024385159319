// 对象数组  根据key去重
export function unique(arr: any, key: any) {
  if (!arr) return arr;
  if (key === undefined) return [...new Set(arr)];
  const map: any = {
    string: (e: any) => e[key],
    function: (e: any) => key(e),
  };
  const fn = map[typeof key];
  const obj = arr.reduce((o: any, e: any) => ((o[fn(e)] = e), o), {});
  return Object.values(obj);
}
