import { ref } from "vue";
import { Api, awaitWrap, useLoaclforage, CMS_I18N_LANG_NAME } from "core";

const moduleNames = ["agent", "user", "common", "message", "mine", "funds", "game"];
interface langugItem {
  info: string;
  language: string;
  seriNo: string;
  localLanguage: string;
}

let i18nResponeData = {} as langugItem;

export function i18nRquestLoad() {
  const i18nLoading = ref(false);

  const { localForage } = useLoaclforage();

  /**
   * 拉取api语言包
   * @param lang 语言标识
   * @returns 语言包
   */
  const requestI18nByLang = async (lang: string): Promise<langugItem> => {
    if (Object.keys(i18nResponeData).length) return Promise.resolve(i18nResponeData);

    const [_, response] = await awaitWrap(
      Api.I18nService.getI18n({ language: lang, type: moduleNames.join(",") })
    );

    if (response) {
      const { data, result } = response;
      if (result && Array.isArray(data)) {
        i18nResponeData = { ...array2Object(data) };
        return Promise.resolve(i18nResponeData);
      }
    }

    return Promise.resolve({} as langugItem);
  };

  /**
   * 转换国际化数据（key:value）
   * @param data api数据
   * @returns 转换key-value
   */
  const array2Object = (data: langugItem[]): langugItem => {
    const i18nObj: langugItem = {} as langugItem;

    data.reduce((obj, cur) => {
      obj[cur.seriNo] = cur.info;
      return obj;
    }, i18nObj);

    return i18nObj;
  };

  /**
   * 对比语言包内容是否一致
   */
  const diff = (newValue: langugItem, oldValue: langugItem) => {
    const sortedJSONStringify = (obj: langugItem) => {
      var keys = Object.keys(obj).sort();
      var newObj = {};
      keys.forEach(function (key) {
        newObj[key] = obj[key];
      });
      return JSON.stringify(newObj);
    };

    return sortedJSONStringify(newValue) === sortedJSONStringify(oldValue);
  };

  /**
   * 更新国际化语言缓存
   * @param lang 语言标识
   * @param data 语言包数据
   */
  const updateI18nCache = (lang: string, data: langugItem) => {
    return new Promise(resolve => {
      localForage.setItem(`${CMS_I18N_LANG_NAME}_${lang}`, data, resolve);
    });
  };

  /**
   * 加载语言包缓存数据
   * @param lang 语言标识
   * @returns 语言包
   */
  const loadI18nCache = (lang: string): Promise<langugItem | boolean> => {
    return new Promise(async reolve => {
      try {
        i18nLoading.value = true;

        localForage.getItem(`${CMS_I18N_LANG_NAME}_${lang}`, async function (err, value: any) {
          // 语言缓存加载失败，读取接口数据
          if (err || !value || (value && !Object.keys(value).length)) {
            const i18nResult = await requestI18nByLang(lang);

            // 请求失败，使用静态语言包
            if (!Object.keys(i18nResult).length) {
              return reolve(false);
            }

            await updateI18nCache(lang, i18nResult);
            return reolve(i18nResult);
          }

          // 读取缓存成功，需要和接口数据对比是否更新
          const i18nResult = await requestI18nByLang(lang);
          // 接口数据有更新，更新缓存
          if (!diff(i18nResult, value as langugItem)) {
            await updateI18nCache(lang, i18nResult);
            return reolve(i18nResult);
          }

          // 使用缓存
          reolve(value as langugItem);
        });
      } catch (e) {
        reolve(false);
      } finally {
        i18nLoading.value = false;
      }
    });
  };

  return {
    loadI18nCache,
    updateI18nCache,
  };
}
