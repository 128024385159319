import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuth, matchPath } from "core";
import { webBaseUrl } from "@kgsport-cms/config";
import { mangoPopup } from "components";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "scaffolds/cms-mango-web/views/home"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/activity",
    name: "activity",
    component: () =>
      import(/* webpackChunkName: "activity" */ "scaffolds/cms-mango-web/views/activity"),
  },
  {
    path: "/game/gameList",
    name: "/gameList",
    component: () => import("scaffolds/cms-mango-web/views/game"),
  },
  {
    path: "/game/wrap",
    name: "/gameWrap",
    component: () => import("scaffolds/cms-mango-web/views/game/wrap"),
  },
  {
    path: "/activity/detail",
    name: "activityDetail",
    component: () =>
      import(
        /* webpackChunkName: "activityDateils" */ "scaffolds/cms-mango-web/views/activity/activityDetail"
      ),
    meta: {
      title: "优惠详情",
      isTabbar: false,
    },
  },
  {
    path: "/activity/detailTpl/:tpl?",
    name: "activityDetailTpl",
    component: () =>
      import(
        /* webpackChunkName: "activityDetailTpl" */ "scaffolds/cms-mango-web/views/activity/activityDetailTpl"
      ),
    meta: {
      title: "优惠详情",
      isTabbar: false,
    },
  },
  {
    path: "/activity/redEnvelope",
    name: "activityRedEnvelope",
    component: () =>
      import(
        /* webpackChunkName: "activityRedEnvelope" */ "scaffolds/cms-mangoWeb-base/views/activity/redEnvelope"
      ),
  },
  {
    path: "/agent",
    name: "agent",
    component: () => import("scaffolds/cms-mango-web/views/mangoAgent"),
  },
  {
    path: "/vip",
    name: "vip",
    component: () => import(/* webpackChunkName: "vip" */ "scaffolds/cms-mango-web/views/vip"),
    meta: {
      title: "VIP特权",
      isTabbar: false,
    },
  },
  {
    path: "/download",
    name: "download",
    component: () =>
      import(/* webpackChunkName: "activity" */ "scaffolds/cms-mango-web/views/downLoad"),
  },
  {
    path: "/self-developed-game",
    name: "/self-developed-game",
    component: () => import("scaffolds/cms-mango-web/views/games/iframe"),
  },
  {
    path: "/turntable",
    name: "turntableWeb",
    component: () => import("scaffolds/cms-mango-web/views/turntable"),
  },
  {
    path: "/delAccount",
    name: "delAccount",
    component: () => import("scaffolds/cms-mango-web/views/deleteAccount"),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV === "production" ? webBaseUrl : "/mangoWeb"),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});
const matchs = [
  "/",
  "/entry/:path*",
  "/activity/:path*",
  "/game/:path*",
  "/test",
  "/sponsor",
  "/download",
  "/access",
  "/forgetPass",
  "/vip",
  "/agent",
  // "/:path*",
];
router.beforeEach((to, form, next) => {
  const { isLogin } = useAuth();
  if (to.path.includes("entry/login")) {
    next("/");
    setTimeout(
      mangoPopup({ type: "MangoEntry", props: { dialogType: 1, header: false } }).open,
      2100
    );
    return;
  }
  if (to.path.includes("entry/register")) {
    next("/");
    setTimeout(
      mangoPopup({ type: "MangoEntry", props: { dialogType: 2, header: false } }).open,
      2100
    );
    return;
  }
  if (matchPath(to.path, matchs)) {
    next();
  } else if (isLogin.value) {
    next();
  } else {
    next();
  }
});
export default router;
