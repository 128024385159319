import qs from "qs";
import { match } from "path-to-regexp";
// 解析链接追加参数
export function appendUrlParms(url = "", data: Record<any, any>) {
  if (!url) return "";
  const l = new URL(url);
  if (l.hash) {
    l.hash += `&${qs.stringify(data)}`;
  } else {
    Object.keys(data || {}).forEach(item => {
      l.searchParams.append(item, data[item]);
    });
  }
  return l.toString();
}

// 地址匹配
export const matchPath = (path: string, paths: string[] = []) => {
  return (
    paths.filter(item => {
      const end = match(item, { decode: decodeURIComponent });
      return !(end(path) === false);
    }).length > 0
  );
};

// 解析地址参数
export function getUrlParam(urlKey: string, urlStr?: string) {
  if (!urlStr) urlStr = window.location.href;
  const url = new URL(urlStr);
  const reg = new RegExp("[?&]" + urlKey + "=([^&]*)(&?)", "i");
  const r = url.search.match(reg);
  return r ? r[1] : "";
}
