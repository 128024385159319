import { defineComponent } from "vue";
import { Form, Input } from "ant-design-vue";
import { Button } from "vant";
import style from "./modifylogin.module.less";
import { useI18n, useUser } from "core";

export default defineComponent({
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { passAccount, passForm, reqeustLoading, editPassSubmit } = useUser();
    return () => (
      <div class={style.modifyPassword}>
        <Form>
          <Form.Item
            {...passAccount.validateInfos.rawPassword}
            validateFirst
            class={style.listFormItem}
            name="rawPassword"
          >
            <Input.Password
              placeholder={$t("mine_userinfo_pleaseEnterOldPassword" /*请输入旧密码*/)}
              type="password"
              v-model:value={passForm.rawPassword}
            />
          </Form.Item>
          <Form.Item
            {...passAccount.validateInfos.newPassword}
            validateFirst
            class={style.listFormItem}
            name="newPassword"
          >
            <Input.Password
              placeholder={$t("mine_userinfo_pleaseEnterNewPassword" /*请输入新密码*/)}
              type="password"
              v-model:value={passForm.newPassword}
            />
          </Form.Item>
          <Form.Item
            {...passAccount.validateInfos.confirmPassword}
            validateFirst
            class={style.listFormItem}
            name="confirmPassword"
          >
            <Input.Password
              placeholder={$t("mine_userinfo_pleaseConfirmNewPass" /*请确认新密码*/)}
              type="password"
              v-model:value={passForm.confirmPassword}
            />
          </Form.Item>
          <div class={style.modifyPasswordBtns}>
            <Button
              onClick={async () => {
                await editPassSubmit();
              }}
              loading={reqeustLoading.value}
            >
              {$t("mine_userinfo_confirmSubmission" /*确认提交*/)}
            </Button>
          </div>
        </Form>
      </div>
    );
  },
});
