import { defineComponent, ref, computed, reactive } from "vue";
import {
  useI18n,
  useRecharge,
  BankFlagEnum,
  useConfig,
  useUser,
  useNotice,
  useWallet,
  FlagNames,
  useConfigContext,
  useCurrencySymbol,
} from "core";
import { Input } from "ant-design-vue";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import { Picker, Popup, Cell, Icon, List } from "vant";

import { Loading } from "commom";
import { useRoute, useRouter } from "vue-router";
import { Image, SvgIcon } from "commom";
import { Empty } from "components";
import { HotFlag, StableFlag, NewFlag } from "./assets";
import recharge from "./recharge.module.less";
import pay from "./assets/pay.png";
import popup from "templates/mango/Mobile/popup.module.less";
// 税号弹窗
import MangoDutyCodeSelect from "templates/mango/Mobile/components/DutyCodeSelect";

import PaymentInfo from "../MobileRechargeInfo";
// 角标0-无，1-火热，2-稳定，3-最新
const FLAG_IMGS: any = {
  1: HotFlag,
  2: StableFlag,
  3: NewFlag,
};

export default defineComponent({
  name: "MobileRechargeMongo",
  emits: ["close"],
  setup(prop, { emit }) {
    const {
      store,
      typeList,
      usdtRate,
      amountList,
      actualAmount,
      expectedAmount,
      changeType,
      amountRange,
      supportBanks,
      changeChannel,
      bankFlagNumber,
      isEmptyChannel,
      isBtnActivation,
      getPayDebounced,
      changeAmountType,
      amountInputVsisble,
      amountSelectVsisble,
      captchaCode,
      // updateRechargePopUpStatus,
      loadingPay,
      isThreePartiesPay,
      thirdBankCodes,
      onThirdCountyCodeChange,
      activeChannelItem,
      activeItem,
    } = useRecharge();
    const duty = reactive({
      visible: false,
    });

    console.log(typeList, "typeList");
    // const rechargeModalShow = ref(false);
    const router = useRouter();
    const route = useRoute();
    const { customer } = useConfig();
    const { user, currencySymbol } = useUser();
    const { noticeStore } = useNotice();
    const { scaleAmountValue, amountFormat } = useWallet();
    const { config } = useConfigContext();
    // const checkedChange = (e: any) => {
    //   updateRechargePopUpStatus(!e.target.checked);
    // };
    // 选择国家编码
    const countrySelectShow = ref(false);
    const { I18n, $t } = useI18n();
    const baseAmount = computed(() => {
      let arr = [];
      // amountRange.value[1] || Number(item) >= amountRange.value[0]
      // [1,2,3,4,5,6]
      const baseArr = [1, 2, 3, 4];
      baseArr.map(item => {
        arr.push(
          amountRange.value[0] +
            Math.floor(((amountRange.value[1] - amountRange.value[0]) / 5) * item)
        );
      });
      arr.push(amountRange.value[1]);
      arr.unshift(amountRange.value[0]);
      return arr;
    });
    const changeAmount = async (index: number) => {
      store.activeAmount = index;
      store.amount = Number(baseAmount.value[index]);
    };
    // const RechargeTipsModal = () => (
    //   <div class={recharge.rechargePopup}>
    //     <Overlay show={rechargeModalShow.value} zIndex={99}>
    //       <div class={recharge.rechargePopupWrap}>
    //         <div class={recharge.rechargePopupHeader}>
    //           {$t("funds_recharge_modalTitle" /*充值活动*/)}
    //         </div>
    //         <div class={recharge.rechargePopupContent}>
    //           <div class={recharge.rechargePopupList}>
    //             <div class={recharge.rechargePopupItem}>
    //               <img src={activityIcon} alt="" />
    //               <div class={recharge.rechargePopupItemText}>
    //                 <p class={recharge.rechargePopupItemTitle}>充值送礼金</p>
    //                 <p class={recharge.rechargePopupItemDes}>充值送礼金活动开始了</p>
    //               </div>
    //             </div>
    //           </div>
    //           <div class={recharge.rechargePopupBottom}>
    //             <div class={recharge.rechargePopupBottomLinear}></div>
    //             <div class={recharge.rechargePopupBottomBg}></div>
    //             <div class={recharge.rechargePopupCheck}>
    //               <Checkbox onChange={checkedChange}>
    //                 {$t("funds_recharge_notRemindToday" /* 今日不再提醒 */)}
    //               </Checkbox>
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           class={recharge.rechargePopupClose}
    //           onClick={() => (rechargeModalShow.value = false)}
    //         >
    //           <Icon name="close" size="30" color="#fff" />
    //         </div>
    //       </div>
    //     </Overlay>
    //   </div>
    // );
    // 充值方法
    const countryTrigger = (e: Event) => {
      e.preventDefault();
      countrySelectShow.value = true;
    };
    const countryCancel = () => {
      countrySelectShow.value = false;
    };
    const setCountryItem = ({ code }: any) => {
      onThirdCountyCodeChange(code);
      countryCancel();
    };
    const RechargeMethds = () => (
      <div class={recharge.rechargeCard} v-show={false}>
        <div class={recharge.rechargeSetp}>{$t("common_payment_type") /**支付类型 */}</div>
        <ul class={recharge.rechargeMethods}>
          {typeList.value.map((item, index) => (
            <li
              onClick={() => changeType(item, index)}
              key={index}
              class={[
                recharge.rechargeMethodsItem,
                recharge.rechargeMethodsItem1,
                index === store.activeType ? recharge.active : null,
              ]}
            >
              <div class={recharge.rechargeMethodsIcon}>
                <Image src={item.iconUrl || ""} />
              </div>
              <div class={[recharge.rechargeMethodsText, recharge.rechargeMethodsText1]}>
                {item.name}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );

    // 限额placeholder文本
    const quotaText = computed(() => {
      const { maxAmountPerOrder, minAmountPerOrder } =
        store.payChannel?.[store.activePayChannel] ?? {};
      if (minAmountPerOrder >= 0 && maxAmountPerOrder >= 0)
        return `${$t("funds_recharge_limitAmout") /* 限额 */}${amountFormat(
          minAmountPerOrder
        )} ~ ${amountFormat(maxAmountPerOrder)} ${user.value?.currency ?? ""}`;
      return null;
    });
    // 充值通道
    const RechargeChannel = () =>
      isEmptyChannel.value ? null : (
        <div class={recharge.rechargeCard}>
          <div class={recharge.rechargeSetp}>{$t("funds_recharge_method" /* 存款方式 */)}</div>
          <ul class={recharge.rechargeMethods}>
            {store.payChannel.map((item, index) =>
              item.bankFlag == 1 || item.bankFlag == 2 ? (
                <li
                  onClick={() => changeChannel(index)}
                  key={item.id}
                  class={[
                    recharge.rechargeMethodsItem,
                    recharge.rechargeMethodsItem2,
                    index === store.activePayChannel ? recharge.active : null,
                  ]}
                >
                  <img class={recharge.rechargeMethodsIcon} src={item.url || pay} />
                  <span class={recharge.rechargeMethodsItemName}>{item.name}</span>
                  <span class={recharge.rechargeMethodsItemYh}>
                    {item.eachRechargeDiscountValue}
                  </span>
                </li>
              ) : (
                <li
                  onClick={() => changeChannel(index)}
                  key={item.id}
                  class={[
                    recharge.rechargeMethodsItem,
                    recharge.rechargeMethodsHalf,
                    index === store.activePayChannel ? recharge.active : null,
                  ]}
                >
                  <div class={recharge.rechargeMethodsHalfLeft}>
                    <section class={recharge.rechargeMethodsIcon}>
                      <img src={item.url || typeList.value?.[store.activeType]?.iconUrl} alt="" />
                    </section>
                  </div>
                  <div class={recharge.rechargeMethodsHalfRight}>
                    {item.typeSubscript !== 0 ? (
                      <>
                        <span class={recharge.rechargeMethodsItemText}>
                          {FlagNames[item.typeSubscript]}
                        </span>
                        <img
                          class={recharge.rechargeMethodsItemFlag}
                          src={FLAG_IMGS[item.typeSubscript]}
                          alt="Hot"
                        />
                      </>
                    ) : null}
                    <div class={recharge.rechargeMethodsText}>{`${item.payTypeName}`}</div>
                    <div class={recharge.rechargeMethodsText}>{`${item.name}`}</div>
                    {Number(String(item.eachRechargeDiscountValue).replace("%", "")) > 0 && (
                      <div
                        class={[recharge.rechargeMethodsText, recharge.rechargeMethodsDiscount]}
                      >{`【${$t("funds_recharge_discount" /* 优惠 */)} ${
                        item.eachRechargeDiscountValue
                      }】`}</div>
                    )}
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
      );

    // 银行卡姓名输入
    const DepositNameInput = () =>
      bankFlagNumber.value === BankFlagEnum.isBank ? (
        <div class={recharge.rechargeCard}>
          {/* <div class={recharge.depositNameInputTips}>{$t("funds_recharge_bindNameTip")}</div> */}

          <div class={[recharge.rechargeForm, recharge.rechargeFormName]}>
            <Input
              v-model:value={store.name}
              class={recharge.rechargeFormFiled}
              type="text"
              maxlength={50}
              placeholder={$t("funds_recharge_inputName" /* 请输入存款人姓名 */)}
            />
          </div>
          {/* <div class={recharge.depositNameInputTips2}>{$t("funds_recharge_inputNameTip")}</div> */}
        </div>
      ) : null;

    // 虚拟币充值: 汇率信息
    const usdtDepositAmountInfo = () =>
      bankFlagNumber.value === BankFlagEnum.isVirtualCurrency ? (
        <div class={recharge.depositAmountInfoBox}>
          <p class={recharge.depositAmountVirtualExChange}>
            <span>
              <Icon name="warning-o" />
              {/* {$t("funds_recharge_exchangeRate")}1USDT ≈ {usdtRate.value} */}
              {$t(
                "funds_recharge_depositAmountVirtualTutorial" /*实际到账金额将以支付时的汇率计算 */
              )}
            </span>
            {/* <span>
              {$t("funds_recharge_exchangeRate")} 1USDT ≈ {usdtRate.value}CNY
            </span>
            <span>
              {$t("funds_recharge_estimatedPayment")}&nbsp;
              <span class={recharge.depositAmountMoneyCount}>{usdtTotal.value}</span>
              USDT
            </span> */}
          </p>
          {/* <p class={recharge.depositAmountVirtualTutorial}>
            <span>
              {$t("funds_recharge_depositAmountVirtualTutorial")}
              <i class={recharge.warningIcon} />
            </span>
            <span class={recharge.depositAmountVirtualToKnow}>
              {$t("funds_recharge_depositAmountVirtualToKnow")}
              <i></i>
            </span>
          </p> */}
        </div>
      ) : null;

    const result = ref($t("funds_recharge_selectBank" /* 选择银行 */));

    const onConfirm = (info: { text: string; value: string }) => {
      result.value = info.text;
      store.bank = info.value;
      store.showBankPicker = false;
    };

    // 选择支持的银行
    const SupportBanks = () => {
      return supportBanks.value.length > 0 ? (
        <div class={recharge.rechargeCard}>
          <Cell
            class={recharge.rechargeCardCell}
            onClick={() => (store.showBankPicker = true)}
            is-link
            title={$t("funds_recharge_supportBank" /* 支持的银行 */)}
            border={false}
            value={result.value}
          />
          <Popup v-model:show={store.showBankPicker} round position="bottom" teleport="#app">
            <Picker
              columns={supportBanks.value}
              onCancel={() => (store.showBankPicker = false)}
              onConfirm={v => onConfirm(v)}
            />
          </Popup>
        </div>
      ) : null;
    };

    const RechargeAmountInput = () => (
      <div class={recharge.rechargeCard}>
        <div class={recharge.rechargeSetp}>{$t("funds_recharge_depositAmount" /* 存款金额 */)}</div>
        {/*TODO 暂时改为金额都可以输入*/}
        <div class={recharge.country}>
          {isThreePartiesPay.value && !!thirdBankCodes.value?.length && (
            <div class={recharge.countryPicker} onClick={countryTrigger}>
              <section class={recharge.countryName}>
                <span>{store.thirdBankCode}</span>
                <i
                  class={[
                    recharge.countryNameTriangle,
                    countrySelectShow.value && recharge.countryNameOpen,
                  ]}
                >
                  <CaretDownOutlined />
                </i>
              </section>
            </div>
          )}
          <Input
            v-model:value={store.amount}
            class={[
              recharge.amountInput,
              recharge.rechargeFormFiled,
              isThreePartiesPay.value && thirdBankCodes.value?.length && recharge.countryCashBank,
            ]}
            type="tel"
            maxlength={8}
            placeholder={
              quotaText.value
                ? quotaText.value
                : $t("funds_recharge_inputDepositAmount" /* 请输入存款金额 */)
            }
            v-slots={{
              suffix() {
                return (
                  <span class={recharge.rechargeFormSuffix}>({user.value?.currency ?? ""})</span>
                );
              },
            }}
          />
        </div>
        {/* {AmountScale2ActualTips()} */}
        <div class={recharge.rechargeForm}>
          {
            <div class={recharge.rechargeAmount}>
              {amountList.value.map((item, index) =>
                Number(item) <= amountRange.value[1] || Number(item) >= amountRange.value[0] ? (
                  <div
                    onClick={() => changeAmountType(index)}
                    key={item}
                    class={[
                      recharge.rechargeAmountItem,
                      store.activeAmount === index ? recharge.active : null,
                    ]}
                  >
                    <div class={recharge.rechargeAmountItemCurry}>{currencySymbol.value}</div>
                    {item}
                  </div>
                ) : null
              )}
            </div>
          }

          {!!store.validateCodeEnabled && (
            <div class={recharge.rechargeCode}>
              <Input
                v-model:value={store.capCode}
                class={recharge.rechargeFormFiled}
                type="tel"
                maxlength={8}
                placeholder={$t("funds_recharge_imgVerificationCode" /* 图形验证码 */)}
              />
              <img onClick={() => captchaCode()} src={store.captchaImg} alt="" srcset="" />
            </div>
          )}
          {usdtDepositAmountInfo()}
        </div>
      </div>
    );

    // 帮助提示
    const HelpTips = () => {
      return (
        <I18n
          tag="div"
          keyPath="funds_recharge_problems" /*存款遇到问题？联系[0]解决*/
          class={recharge.helpTips}
          v-slots={{
            $0: () => {
              return (
                <a href={customer.value} target="_blank" class={recharge.helpTipsService}>
                  {$t("mine_customerservice_onlineCS" /*在线客服*/)}
                </a>
              );
            },
          }}
        />
      );
    };

    // 教程
    const DepositTutorial = () => (
      <span
        onClick={() => {
          router.push("/customer");
        }}
        class={recharge.rechargeTeach}
      >
        <SvgIcon name="custom" width="24px" height="24px" />
      </span>
    );

    const NoChannels = () => {
      return (
        <Empty
          type="mangoNoData"
          option={
            { mangoNoData: { text: $t("funds_no_recharge_channels") } } /**暂无可用充值渠道 */
          }
        />
      );
    };

    // 金额比例和实际金额
    const AmountScale2ActualTips = () => {
      return (
        <>
          <div class={recharge.rechargeSacle}>
            <p>
              {$t("funds_currency_ratio" /*货币比例*/)} 1:{scaleAmountValue.value}
            </p>
            <p>
              {$t("funds_actual_pay" /*实际支付*/)}:
              <span>{amountFormat(actualAmount.value || 0)}</span>
            </p>
          </div>
          {bankFlagNumber.value === BankFlagEnum.isVirtualCurrency && usdtRate.value > 0 && (
            <div class={recharge.rechargeSacle}>
              <p>
                {$t("funds_recharge_exchangeRate" /*参考汇率*/)} 1:{usdtRate.value}
              </p>
              <p>
                {$t("funds_recharge_estimatedPayment" /*预计支付*/)}:
                <span>{amountFormat(Number(expectedAmount.value))}</span>
              </p>
            </div>
          )}
        </>
      );
    };
    return () => [
      <div class={recharge.recharge}>
        {!store.visible ? (
          <>
            <div v-show={!store.visible} class={recharge.rechargeBox}>
              {/* // 支付通道 */}
              {isEmptyChannel.value ? NoChannels() : RechargeChannel()}
              {/* 输入或者金额 */}
              {[
                RechargeAmountInput(),
                // <>
                //   {store.payChannel?.[store.activePayChannel]?.remarks && (
                //     <div class={recharge.rechargeRemarks}>
                //       {store.payChannel[store.activePayChannel].remarks}
                //     </div>
                //   )}
                // </>,
              ]}
              {/*{DepositNameInput()}*/}
              {/*税号*/}
              {activeChannelItem.value?.cpfFlag === "1" &&
              window.CONFIG.currentModel === "mango" ? (
                <>
                  <div class={[recharge.duty]}>
                    <div
                      class={recharge.dutyCode}
                      onClick={() => {
                        duty.visible = true;
                      }}
                    >
                      {store.taxType}
                    </div>
                    <div class={[recharge.rechargeForm, recharge.rechargeFormName]}>
                      <Input
                        class={[recharge.dutyNumber, recharge.rechargeFormFiled]}
                        v-model:value={store.taxNumber}
                        placeholder={$t("common_validator_duty_rule" /**请输入10-30位字符 */)}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              {/* lam109新魔版隐藏 */}
              {RechargeMethds()}
            </div>
            {/* {SupportBanks()} */}
            <div class={recharge.stepbox}>
              {!isEmptyChannel.value && [
                <div
                  class={[
                    recharge.rechargeDeposit,
                    isBtnActivation.value && recharge.rechargeDepositDisabled,
                  ]}
                  onClick={() => {
                    if (isBtnActivation.value) return;
                    emit("close");
                    getPayDebounced();
                  }}
                >
                  {loadingPay.value && (
                    // <Loading size={20} color="#fff" />
                    <Loading />
                  )}
                  {$t("common_next" /* 下一步 */)}
                </div>,
                HelpTips(),
              ]}
            </div>
            {/* 税号类型弹窗 */}
            <MangoDutyCodeSelect
              height={330}
              v-model:show={duty.visible}
              currency={store.taxType}
              onChange={value => (store.taxType = value)}
            />
          </>
        ) : (
          <div class={recharge.paybox}>
            <PaymentInfo model={activeChannelItem.value.bankFlag} />
          </div>
        )}
      </div>,
      <Popup v-model:show={countrySelectShow.value} round position="bottom" class={popup.popup}>
        <div class={[popup.popupHeader, popup.popupHeaderBg]}>
          <section class={popup.popupHeaderLeft} onClick={countryCancel}>
            {$t("user_register_alert_cancel" /* 取消 */)}
          </section>
          <section class={popup.popupHeaderMiddle}>
            {$t("mine_select_encoding" /* 选择编码 */)}
          </section>
          <section class={popup.popupHeaderRight} />
        </div>
        <div class={recharge.countryMain}>
          {thirdBankCodes.value?.map(option => (
            <div
              class={recharge.countryOption}
              key={option.code}
              onClick={() => setCountryItem(option)}
            >
              <section class={recharge.countryOptionText}>
                <span>{option.name}</span>
                <span>({option.code})</span>
              </section>
              <span
                class={[
                  recharge.countryRadioCircle,
                  store.thirdBankCode === option.code && recharge.countryRadioCircleChecked,
                ]}
              />
            </div>
          ))}
        </div>
      </Popup>,
      // <>{RechargeTipsModal()}</>,
    ];
  },
});
