import { GeetestType } from "cms/cms-service/es";
import { CaptchaType } from "commom";

/**
 * 验证器
 * @param {function} success 成功
 * @param {function} fail 失败回调
 * @param {function} close 关闭回调
 */

interface GeetDataType extends GeetestType {
  status: number;
}

interface NEdataType {
  validate: string;
}

interface CaptchaConfig {
  gt?: string;
  challenge?: string;
  captchaId?: string;
}

type CaptchaLang =
  | "zh-cn"
  | "zh-hk"
  | "zh-tw"
  | "en"
  | "ja"
  | "ko"
  | "id"
  | "ru"
  | "ar"
  | "es"
  | "pt-pt"
  | "fr"
  | "de";

interface CaptchaOption {
  /**
   * 验证器类型
   */
  type: CaptchaType;
  /**
   * 数据配置
   */
  config: CaptchaConfig;
  /**
   * 语言
   */
  lang?: CaptchaLang;
  /**
   * 挂载节点
   */
  element?: string;
  /**
   * 失败回调
   */
  onFail?: () => void;
  /**
   * 关闭回调
   */
  onClose?: () => void;
  /**
   * 成功回调
   */
  onSuccess: (result: GeetDataType | NEdataType) => void;
  // 开启单例模式
  isSingleton?: boolean;
}

let isOpenCaptcha = false;

export class Captcha {
  option: CaptchaOption;

  constructor(option: CaptchaOption) {
    if (!option) {
      new Error("no find config option");
    }
    this.option = option;
  }

  private captchaIns: any = null;

  async open() {
    const {
      config,
      onSuccess,
      onClose,
      onFail,
      type,
      lang,
      element,
      isSingleton = true,
    } = this.option;
    if (isSingleton) {
      if (isOpenCaptcha) return;
    }
    isOpenCaptcha = true;
    // 初始化极验
    if (type === CaptchaType.GEETEST) {
      const geet = (window as any).initGeetest;
      if (!geet) {
        await require("./src/geetest.captcha.ts");
      }
      await (window as any).initGeetest(
        {
          gt: config.gt,
          challenge: config.challenge,
          product: "bind",
          offline: false,
          https: true,
          width: "300px",
          hideClose: true,
          hideSuccess: true,
          lang: lang || "zh-cn",
          new_captcha: false,
        },
        (captchaObj: any) => {
          captchaObj
            .onReady(() => {
              captchaObj.verify();
            })
            .onSuccess(() => {
              const captchaResult = captchaObj.getValidate();
              const param: GeetDataType = {
                geetest_challenge: captchaResult.geetest_challenge,
                geetest_validate: captchaResult.geetest_validate,
                geetest_seccode: captchaResult.geetest_seccode,
                status: 1,
              };
              // 极验校验的参数，将其传给服务端，进行校验。
              onSuccess(param);
              isOpenCaptcha = false;
            })
            .onError(() => {
              // 图形验证失败
              !!onFail && onFail();
              captchaObj.destroy();
              isOpenCaptcha = false;
            })
            .onClose(() => {
              // 图形验证关闭
              !!onClose && onClose();
              captchaObj.destroy();
              isOpenCaptcha = false;
            });
        }
      );
    }
    // 初始化网易云盾
    if (type === CaptchaType.NE) {
      const initNECaptcha = require("./src/ne.captcha");
      const neCaptcha = document.createElement("div");
      const domId = "#ne-captcha";
      neCaptcha.id = domId;
      neCaptcha.setAttribute(
        "style",
        "position: fixed;left: 0;top: 0;bottom: 0;right: 0;background: rgba(0, 0, 0, 0.6);z-index: 99999;"
      );
      !!element && document.body.append(neCaptcha);
      await initNECaptcha(
        {
          captchaId: config.captchaId,
          element: element || domId,
          mode: "popup",
          width: "320px",
          onVerify: function (err: any, data: NEdataType) {
            if (err) {
              console.error(err);
            } else {
              onSuccess(data);
              this.captchaIns.destroy();
              !element && neCaptcha.remove();
              isOpenCaptcha = false;
            }
          },
          onClose: () => {
            onClose && onClose();
            !element && neCaptcha.remove();
            isOpenCaptcha = false;
          },
        },
        (onload = (instance: any) => {
          this.captchaIns = instance;
          this.captchaIns && this.captchaIns.popUp();
        }),
        (onerror = err => {
          // 初始化失败后触发该函数，err对象描述当前错误信息
          console.error(err);
          onFail && onFail();
          isOpenCaptcha = false;
        })
      );
    }
  }
}

// 转换验证器类型, 默认极验第一
export function formatCaptchaType({
  enableGeetest,
  enableTencent,
  enableImage,
}: {
  enableGeetest: boolean;
  enableTencent: boolean;
  enableImage: boolean;
}): CaptchaType | null {
  if (enableGeetest) {
    return CaptchaType.GEETEST;
  } else if (enableTencent) {
    return CaptchaType.TENCENT;
  } else if (enableImage) {
    return CaptchaType.IMG;
  }
  return null;
}
