import { computed, defineComponent, onMounted, reactive, ComputedRef } from "vue";
import styles from "./personalinfo.module.less";
import popup from "templates/mango/Mobile/popup.module.less";
import { UserAvatar, SvgIcon } from "commom";
import { mangoPopup } from "components";
import { Picker, Popup, DatetimePicker } from "vant";
import { useI18n, useAuth, useUser, toTimeNormal, useDayjs, useConfig } from "core";
import { useRouter } from "vue-router";
import SetAvatar from "./components/SetAvatar";
import SetNick from "./components/SetNick";
import SetName from "./components/SetName";

import SetDutyNum from "./components/SetDutyNum";
// 税号弹窗
import MangoDutyCodeSelect from "templates/mango/Mobile/components/DutyCodeSelect";

export default defineComponent({
  props: {
    callback: {
      type: Function,
      default: null,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { isLogin } = useAuth();
    const { $t } = useI18n();
    const router = useRouter();
    const {
      form,
      user,
      isSex,
      gender,
      sexText,
      selectBirthday,
      checkSex,
      getUserInfo,
      editDebounceSubmit,
      isClick,
    } = useUser();
    const { config } = useConfig();
    const { dayjs } = useDayjs();
    interface list {
      name: string;
      value: ComputedRef<string | undefined>;
      isRed: boolean;
      itemName: string;
      userKey: string;
    }
    interface data {
      list: list[];
    }
    const data = reactive<data>({
      list: [
        {
          name: "mine_userinfo_gender" /*性别*/,
          value: computed(() =>
            isSex.value ? sexText.value : $t("mine_usersetup_notSet" /* 未设置 */)
          ),
          isRed: true,
          itemName: "gender",
          userKey: "sex",
        },
        {
          name: "mine_userinfo_name" /*姓名*/,
          value: computed(() =>
            user.value?.realName ? user.value?.realName : $t("mine_usersetup_notSet" /* 未设置 */)
          ),
          isRed: true,
          itemName: "name",
          userKey: "realName",
        },
        {
          name: "mine_userinfo_nick_name" /*昵称*/,
          value: computed(() =>
            user.value?.nickname ? user.value?.nickname : $t("mine_usersetup_notSet" /* 未设置 */)
          ),
          isRed: true,
          itemName: "nick",
          userKey: "nickname",
        },
        {
          name: "mine_userinfo_birthday" /*生日*/,
          value: computed(() =>
            user.value?.birthday ? user.value?.birthday : $t("mine_usersetup_notSet" /* 未设置 */)
          ),
          isRed: true,
          itemName: "birthday",
          userKey: "birthday",
        },
      ],
    });
    // 税号popup
    const popupDutyNum = reactive({ toggle: false });
    // 税号类型popup
    const popupDutyType = reactive({ toggle: false });
    // 头像
    const avatar = reactive({ toggle: false });
    // 性别popup
    const popupSex = reactive({ toggle: false });
    // 时间popup
    const popupDate = reactive({ toggle: false });
    // nick
    const nick = reactive({ toggle: false });
    const name = reactive({ toggle: false });
    // 默认选中时间
    const currentDate = computed(() => dayjs(user.value?.birthday).toDate());
    const minDate = dayjs("1960-1-1").toDate();
    // 确认性别
    const onConfirmAge = async (i: string) => {
      checkSex(i);
      await editDebounceSubmit([""]);
      popupSex.toggle = false;
    };
    // 确认税号类型
    const onConfirmDutyType = async (value: string) => {
      form.taxType = value;
      await editDebounceSubmit(["taxType"]);
      popupDutyType.toggle = false;
    };
    // 确认生日
    const onConfirmDate = async (selDate: string) => {
      await selectBirthday(toTimeNormal(selDate, "YYYY-MM-DD") as string);
      await editDebounceSubmit(["birthday"]);
      popupDate.toggle = false;
    };
    // 点击修改
    const chkEventName = (eventName: string) => {
      if (eventName == "gender") popupSex.toggle = true;
      else if (eventName == "birthday") popupDate.toggle = true;
      else if (eventName === "name") name.toggle = true;
      else if (eventName === "nick") nick.toggle = true;
      else avatar.toggle = true;
    };
    onMounted(async () => {
      await getUserInfo();
    });
    return () => (
      <div class={styles.personalInfo}>
        <div class={[popup.popupHeader, popup.popupHeaderBorder]}>
          <section class={popup.popupHeaderLeft}></section>
          <section class={popup.popupHeaderMiddle}>
            {$t("mine_userinfo_PersonalInformation" /*个人信息*/)}
          </section>
          <section
            class={popup.popupHeaderRight}
            onClick={() => {
              mangoPopup().destroyAll();
              props.callback && props.callback();
            }}
          >
            <SvgIcon class={popup.popupHeaderIcon} name="close" width="0.48rem" height="0.48rem" />
          </section>
        </div>
        <ul class={styles.personalInfoList}>
          <li class={styles.personalInfoListItem} onClick={() => chkEventName("")}>
            <span class={styles.personalInfoListTitle}>{$t("mine_userinfo_avatar" /*头像*/)}</span>
            <div class={styles.personalInfoListAvatar}>
              <UserAvatar />
            </div>
          </li>
          {data.list.map(item => (
            <li
              class={styles.personalInfoListItem}
              onClick={() => isClick(item.userKey) && chkEventName(item.itemName)}
            >
              <span class={styles.personalInfoListTitle}>{$t(item.name)}</span>
              <div
                class={[
                  item.isRed && user.value?.[item.userKey] === null
                    ? styles.personalInfoListRedColor
                    : styles.personalInfoListRevise,
                ]}
              >
                {item.value}
              </div>
            </li>
          ))}
          {window.CONFIG.currentModel === "mango" && config.isTaxFlag ? (
            <li class={styles.personalInfoListItem}>
              <span
                class={[styles.personalInfoListTitle, styles.personalInfoListTitle1]}
                onClick={() => (popupDutyType.toggle = true)}
              >
                {user.value?.taxType}
              </span>
              <div
                onClick={() => (popupDutyNum.toggle = true)}
                class={[
                  !user.value?.taxNum
                    ? styles.personalInfoListRedColor
                    : styles.personalInfoListRevise,
                ]}
              >
                {user.value?.taxNum || $t("mine_usersetup_notSet" /* 未设置 */)}
              </div>
            </li>
          ) : null}
        </ul>
        {/* 税号类型弹窗 */}
        <MangoDutyCodeSelect
          height={330}
          v-model:show={popupDutyType.toggle}
          currency={user.value?.taxType}
          onChange={value => onConfirmDutyType(value)}
        />
        {/* 税号 */}
        <Popup
          position="bottom"
          v-model:show={popupDutyNum.toggle}
          round
          teleport="body"
          class={popup.popupWidth100}
        >
          <SetDutyNum onClose={() => (popupDutyNum.toggle = false)} />
        </Popup>
        {/*头像*/}
        <Popup
          position="bottom"
          v-model:show={avatar.toggle}
          round
          teleport="body"
          class={popup.popupWidth100}
        >
          <SetAvatar onClose={() => (avatar.toggle = false)} />
        </Popup>
        <Popup
          position="bottom"
          v-model:show={nick.toggle}
          round
          teleport="body"
          class={popup.popupWidth100}
        >
          <SetNick onClose={() => (nick.toggle = false)} />
        </Popup>
        <Popup
          position="bottom"
          v-model:show={name.toggle}
          round
          teleport="body"
          class={popup.popupWidth100}
        >
          <SetName onClose={() => (name.toggle = false)} />
        </Popup>
        <Popup
          position="bottom"
          v-model:show={popupSex.toggle}
          round
          teleport="body"
          class={popup.popupWidth100}
        >
          <Picker
            class={popup.picker}
            title={$t("mine_userinfo_gender") /*性别*/}
            columns={gender}
            default-index={user.value?.sex ?? 0}
            onCancel={() => (popupSex.toggle = false)}
            onConfirm={(v, i) => onConfirmAge(i + "")}
            confirm-button-text={$t("common_confirm" /*确定*/)}
            cancel-button-text={$t("common_cancel" /*取消*/)}
          />
        </Popup>

        <Popup
          position="bottom"
          v-model:show={popupDate.toggle}
          round
          teleport="body"
          class={popup.popupWidth100}
        >
          <DatetimePicker
            class={popup.picker}
            v-model={currentDate.value}
            title={$t("mine_userinfo_birthday") /*生日*/}
            type="date"
            minDate={minDate}
            confirm-button-text={$t("common_confirm" /*确定*/)}
            cancel-button-text={$t("common_cancel" /*取消*/)}
            onConfirm={onConfirmDate}
            onCancel={() => (popupDate.toggle = false)}
          />
        </Popup>
      </div>
    );
  },
});
