import { defineComponent, reactive, PropType, computed } from "vue";
import { useRouter } from "vue-router";
import { Swipe, SwipeItem, Icon } from "vant";
import { Image } from "commom";
import { ActivityListItem } from "@kgsport-cms/service";
import {
  isMobile,
  DataType,
  useI18n,
  useLocalStorage,
  STORE_PERSONAL_POPUP,
  STORE_ACTIVITY_POPUP,
  todayTime,
} from "core";
import "swiper/css";
import "swiper/css/navigation";
import style from "../IndexPopup.module.less";
import h5Style from "../MobileStyle/mobilePopup.module.less";
import RadioCircle from "./RadioCircle";

interface StoreType {
  currentIndex: number;
  tipsStatus: boolean;
}
type noon = () => void;
export default defineComponent({
  props: {
    data: {
      type: Array as PropType<DataType[] | ActivityListItem[]>,
      default: () => [],
    },
    handClose: {
      type: Function as PropType<noon>,
      default: () => {},
    },
    type: {
      type: String as PropType<"my" | "activity">,
      default: "my",
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const router = useRouter();
    const { localStore } = useLocalStorage();
    const store = reactive<StoreType>({
      currentIndex: 0,
      // 弹窗今日提醒状态
      tipsStatus: false,
    });
    const isMyPopup = props.type === "my";
    const selectNav = (item: DataType, index: number) => {
      store.currentIndex = index;
    };
    const goUrl = (id: string) => {
      props.handClose();
      router.push(`/activity/detail?id=${id}`);
    };
    const checkTodayNoTips = () => {
      store.tipsStatus = !store.tipsStatus;
      const key = isMyPopup ? STORE_PERSONAL_POPUP : STORE_ACTIVITY_POPUP;
      if (store.tipsStatus) {
        localStore.set(key, Date.now() + "", todayTime());
      } else {
        localStore.remove(key);
      }
    };

    const H5Render = (props: DataType[], close: noon) => {
      const RenderIframe = (item: DataType) => (
        <div>
          <iframe class={h5Style.h5PopupIframebox} frameborder="0" srcdoc={item.content}></iframe>
        </div>
      );

      const RenderImage = (item: DataType) => (
        <div
          class={h5Style.h5PopupImgbox}
          onClick={() => {
            if (isMyPopup && item.whetherJump && item.jumpUrl) {
              window.location.href = item.jumpUrl;
            }
          }}
        >
          <Image src={item.popupPic || item.appImage || ""} />
        </div>
      );

      const RenderContent = (item: DataType) => {
        if (item.showType === 3) return RenderImage(item);
        if (item.unId) return RenderImage(item);
        return RenderIframe(item);
      };

      const indicatorNum = computed(() => {
        const len = props?.length ?? 0;
        return Array.from(new Array(len > 5 ? 5 : len)).map((_, i) => i);
      });

      return (
        <div class={h5Style.h5Popup}>
          <Swipe
            autoplay={3000}
            loop
            v-slots={{
              indicator({ active }: { active: number }) {
                return (
                  <div class={h5Style.h5PopupIndicator}>
                    {indicatorNum.value.map(i => (
                      <section
                        class={[
                          h5Style.h5PopupIndicatorItem,
                          (active < 5 ? active === i : active - 5 == i) &&
                            h5Style.h5PopupIndicatorActive,
                        ]}
                      >
                        {active}
                      </section>
                    ))}
                  </div>
                );
              },
            }}
          >
            {props.map(item => (
              <SwipeItem key={item.id}>
                <div>
                  <div class={h5Style.h5PopupHeader}>
                    <h1>{item.title}</h1>
                    <Icon class={h5Style.h5PopupHeaderClose} name="cross" onClick={() => close()} />
                  </div>
                  <div onClick={() => item.unId && goUrl(item.unId)}>{RenderContent(item)}</div>
                </div>
              </SwipeItem>
            ))}
          </Swipe>
          <div class={h5Style.h5PopupClose}>
            {/* <Image src={closeImg} /> */}
            <div class={[h5Style.todayWrap]} onClick={checkTodayNoTips}>
              <RadioCircle checked={store.tipsStatus} />
              <span class={h5Style.todayText}>
                {$t("funds_recharge_notRemindToday" /*今日不再提醒*/)}
              </span>
            </div>
          </div>
        </div>
      );
    };
    const PcRender = (props: DataType[]) => {
      const currentItem = props[store.currentIndex];
      const RenderImage = (
        <div
          class={style.myPopupRight}
          onClick={() => currentItem?.unId && goUrl(currentItem?.unId)}
        >
          <Image
            class={style.myPopupRightImg}
            src={currentItem?.popupPic || currentItem?.pcImage || ""}
          />
        </div>
      );
      const RenderIfram = (
        <div class={style.myPopupContent}>
          <iframe class={style.myPopupRight} srcdoc={currentItem?.content} />
        </div>
      );
      const RenderContent = () => {
        if (currentItem?.showType === 3) return RenderImage;
        if (!isMyPopup) return RenderImage;
        return RenderIfram;
      };

      return (
        <div>
          <div class={style.myPopup}>
            <ul class={style.myPopupLeft}>
              {props?.map((item, index) => (
                <li
                  key={item.id}
                  class={[
                    style.myPopupLeftTitle,
                    store.currentIndex === index ? style.myPopupLeftActive : "",
                  ]}
                  onClick={() => selectNav(item, index)}
                >
                  {item.title}
                </li>
              ))}
            </ul>
            {RenderContent()}
          </div>
          <div class={[style.todayWrap]} onClick={checkTodayNoTips}>
            <RadioCircle checked={store.tipsStatus} />
            <span class={style.todayText}>
              {$t("funds_recharge_notRemindToday" /*今日不再提醒*/)}
            </span>
          </div>
        </div>
      );
    };
    return () => (isMobile ? H5Render(props.data, props.handClose) : PcRender(props.data));
  },
});
