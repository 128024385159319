import { defineComponent, ref, onMounted } from "vue";
import style from "./betDetail.module.less";
import { RecordItem } from "@kgsport-cms/service";
import {
  useBetRecord,
  useI18n,
  timestampToDateTime,
  useClipboards,
  useToast,
  useWallet,
} from "core";
import { MobilePageList } from "commom";
type PageListRefType = {
  refreshList: () => void;
};

export default defineComponent({
  props: {
    gameKind: {
      type: String,
      default: "",
    },
  },
  name: "GameRecordDetail",
  setup(props) {
    const { form, betDetailData, getRecordData, betStore, settleEnum, winEnum } = useBetRecord();
    const { amountFormat } = useWallet();
    // 国际化
    const { $t } = useI18n();
    const gameBetDetailListRefs = ref<PageListRefType | null>(null);
    const { copyFn } = useClipboards();
    const { toast } = useToast();
    // 点击复制按钮
    const copyText = async (value: any) => {
      copyFn(value || "");
      toast.success($t("common_copySuccessfully") /*复制成功*/);
    };
    onMounted(() => {
      form.gameKind = props.gameKind;
      if (gameBetDetailListRefs.value) {
        gameBetDetailListRefs.value?.refreshList();
      }
    });
    const RecordItem = (item: RecordItem) => (
      <div class={style.recordItem}>
        <div class={style.recordItemContent}>
          <div class={style.recordItemContentName}>
            <section class={style.recordItemContentTitle}>{item.gameName}</section>
            <section class={style.recordItemContentUsd}>
              <article class={style.recordItemContentMoney}>
                <span class={Number(item.winAmount) < 0 && style.recordItemContentMoneyRed}>
                  {amountFormat(Number(item.winAmount))}
                </span>
                ({item.currency})
              </article>
              <article class={style.recordItemContentSettlement}>
                {settleEnum[item.settle]}/{Number(item.winAmount) > 0 ? winEnum[1] : winEnum[0]}
              </article>
            </section>
          </div>
          <div class={style.recordItemContentBet}>
            <section class={style.recordItemContentBetItem}>
              <article class={style.recordItemContentBetText}>
                {$t("funds_bet_amount" /*投注额*/)}
              </article>
              <article class={style.recordItemContentBetText}>
                {amountFormat(Number(item.betAmount))}
                <span>({item.currency})</span>
              </article>
            </section>
            <section class={style.recordItemContentBetItem}>
              <article class={style.recordItemContentBetText}>
                {$t("funds_withdraw_validBetAmount" /*有效投注*/)}
              </article>
              <article class={style.recordItemContentBetText}>
                {amountFormat(Number(item.validAmount))}
                <span>({item.currency})</span>
              </article>
            </section>
          </div>
        </div>
        <div class={style.recordItemDesc}>
          <section class={style.recordItemText}>
            {timestampToDateTime(Number(item.betTime || 0))}
          </section>
          <section class={style.recordItemText} onClick={() => copyText(item.billNo)}>
            {$t("funds_withdraw_orderNo" /*订单号*/)}：{item.billNo}{" "}
            <span>{$t("common_copy" /*复制*/)}</span>
          </section>
        </div>
      </div>
    );

    return () => (
      <div class={style.gameRecordDetail}>
        <MobilePageList
          ref={gameBetDetailListRefs}
          requestApi={getRecordData}
          list={betDetailData.value?.page?.records}
          total={betStore.pageData.total}
          pages={betStore.pageData.pages}
          v-model:current={betStore.pageData.current}
          empty={"mangoNoData"}
        >
          {betDetailData.value?.page?.records.map((item: RecordItem) => RecordItem(item))}
        </MobilePageList>
      </div>
    );
  },
});
