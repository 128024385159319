import { defineComponent, onMounted, PropType } from "vue";
import { Form, Input, Button } from "ant-design-vue";
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import css from "../../Entry.module.less";
import { useLogin, useI18n, useCommon } from "core";
import { SvgIcon } from "commom";
// 其他登录方式
import LoginOther from "../LoginOther";

import { forgetPasswordType } from "../../Entry";

export default defineComponent({
  props: {
    switchLoginOrRegister: Function as PropType<(value: number) => void>,
    switchForgetPasswordMode: Function as PropType<(value: forgetPasswordType) => void>,
  },
  setup(props, { attrs, slots, emit, expose }) {
    const { $t } = useI18n();
    const { baseForm, account, loading, check, openCaptcha, getCaptchaCode } = useLogin();
    const { captchaImg } = useCommon();
    const loginEvent = async () => {
      await openCaptcha();
    };
    onMounted(async () => {
      window.CONFIG.isShowImgCode && (await getCaptchaCode());
    });
    return () => (
      <div class={css.loginAccount}>
        <Form style={{ marginTop: "20px" }}>
          <Form.Item required validateFirst={true} {...account.validateInfos.username}>
            <Input
              v-model:value={baseForm.username}
              allowClear
              size="large"
              placeholder={$t("user_login_pleaseInputUserName") /*请输入用户名*/}
            >
              {{
                prefix: () => <UserOutlined style="color: rgba(255,255,255,.2)" />,
              }}
            </Input>
          </Form.Item>
          <Form.Item {...account.validateInfos.password}>
            <Input.Password
              v-model:value={baseForm.password}
              allowClear
              size="large"
              placeholder={$t("user_findPassword_pleaseInputPassword" /**请输入密码 */)}
            >
              {{
                prefix: () => <LockOutlined style="color: rgba(255,255,255,.2)" />,
              }}
            </Input.Password>
          </Form.Item>
          {window.CONFIG.isShowImgCode && (
            <Form.Item required {...account.validateInfos.code}>
              <div style="display:flex">
                <Input
                  v-model:value={baseForm.code}
                  allowClear
                  size="large"
                  placeholder={$t("funds_recharge_inputVerificationCode") /*请输入图形验证码*/}
                  class={css.loginAccountCaptcha}
                >
                  {{
                    prefix: () => (
                      <SvgIcon
                        class={css.loginAccountCaptchaIcon}
                        name="vertifyCode"
                        width="16px"
                        height="18px"
                      />
                    ),
                    suffix: () => (
                      <img
                        src={captchaImg.value}
                        onClick={getCaptchaCode}
                        class={css.loginAccountCaptchaImg}
                        alt=""
                      />
                    ),
                  }}
                </Input>
              </div>
            </Form.Item>
          )}
          <p
            class={[css.loginAccountForgetText, css.noMargiTop]}
            onClick={() => props.switchForgetPasswordMode?.(1)}
          >
            {$t("user_login_forgetPassword" /** 忘记密码*/)}？
          </p>
          <Form.Item noStyle>
            <Button
              size="large"
              class={css.specialDialogButton}
              loading={loading.value}
              onClick={() => loginEvent()}
            >
              {$t("user_login_login" /**登录 */)}
            </Button>
          </Form.Item>
        </Form>
        <div class={css.loginAccountText}>
          <p>
            {$t("user_login_serveTips" /*要访问此网站，请确保您已年满 18 岁并同意*/)}
            <span class={css.loginAccountLink}>{$t("user_login_serveBtn" /*服务条款*/)}</span>
          </p>
          <p class={css.loginAccountTextLight}>
            {$t("user_login_noAccountNumber" /*你没有账户吗？*/)}
            <span class={css.loginAccountLink} onClick={() => props.switchLoginOrRegister?.(2)}>
              {$t("user_login_register" /*注册*/)}
            </span>
          </p>
        </div>
        <LoginOther />
      </div>
    );
  },
});
