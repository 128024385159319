import { computed, reactive } from "vue";
import { tryOnScopeDispose } from "@vueuse/core";
import { ApiAgent } from "../services";
import { isMobile } from "core";
export interface AgAuthStore {
  token: string;
  userId: number;
  user: any | null;
}
// 不要直接操作该store 以免鉴权信息丢失
const store = reactive<AgAuthStore>({
  token: localStorage.access_token,
  userId: -1,
  user: null,
});

// 检查token
export function checkALogin() {
  if (localStorage.access_token) return true;
  return false;
}
export function useAAuth() {
  const isLogin = computed<boolean>(() => !!store.token);
  tryOnScopeDispose(() => {
    store.token = localStorage.access_token;
  });
  // 此处暴露操作方法 进行数据操作
  const getToken = (): string => {
    return (
      localStorage.access_token ||
      store.token ||
      localStorage[`ACCESS_TOKEN_${isMobile ? "H5" : "WEB"}`]
    );
  };
  const setToken = (token: string): void => {
    store.token = token;
    localStorage.access_token = token;
  };
  // 回调函数
  const logout = async (cb?: () => void) => {
    try {
      const { result } = await ApiAgent.AgentAuthService.userLogout();
      if (!result) {
        return;
      }
      store.token = localStorage.access_token = "";
      cb?.();
    } catch (e) {
      store.token = localStorage.access_token = "";
      // toast.success("异常退登" + e);
    }
  };
  return {
    isLogin,
    setToken,
    getToken,
    logout,
  };
}
