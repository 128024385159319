/**
 * 本地存储器（indexDB, webSql, localStorage）
 */
import localForage from "localforage";

export function useLoaclforage() {
  const bootstrap = () => {
    localForage.config({
      name: "CMS_INTL",
      version: 1.0,
      storeName: "CMS_INTL_DB",
      description: "International resource library",
    });
  };

  bootstrap();

  return {
    localForage,
  };
}
