import { defineComponent, Fragment, onMounted } from "vue";
import {
  useI18n,
  toTimeNormal,
  userRecord,
  useClipboards,
  useConfig,
  useWallet,
  FundsTypeEnum,
  RechargeStatus,
} from "core";
import { SvgIcon } from "commom";
import { mangoMobileToast } from "components";

import styleModule from "./style.module.less";

import moneyIn from "./assets/money_in.png";
import moneyOut from "./assets/money_out.png";
import withdrawImg from "./assets/withdraw.png";
import popup from "templates/mango/Mobile/popup.module.less";

export default defineComponent({
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { amountFormat } = useWallet();
    const { $t } = useI18n();
    const { copyFn } = useClipboards();
    const { goService } = useConfig();
    const { getTransType, getTransTypeText, getWithdrawType, getTransferType } = userRecord();
    const fundsType = Number(props.params.fundsType);

    (async () => {
      await getTransType();
    })();

    const copyText = (value: any) => {
      copyFn(value || "");
      mangoMobileToast({
        type: "success",
        title: $t("common_copySuccessfully" /*复制成功*/),
      });
    };

    const getInfoByFundsType = () => {
      switch (fundsType) {
        case FundsTypeEnum.ACCOUNT_CHANGE_RECORD:
          return {
            title: "mine_record_billDetail" /*帐单详情*/,
            topInfo: () => $t(getTransTypeText(Number(props.params?.tranType))),
            contentCallabck: billFlowContent,
            balanceBefore: amountFormat(Number(props.params?.balance)),
            balanceAfter: amountFormat(
              Number(props.params?.amount) + Number(props.params?.balance)
            ),
          };
        case FundsTypeEnum.RECHARGE_ORDER:
          return {
            title: "mine_changeRecord_rechargeOrder" /*充值订单*/,
            topInfo: () => $t(RechargeStatus[props.params.status as string]),
            contentCallabck:
              Number(props.params.isRechargeIng) === 1 ? rechargingContent : rechargerContent,
            balanceBefore: 0,
            balanceAfter: 0,
          };
        case FundsTypeEnum.WITHDRAW_ORDER:
          return {
            title: "mine_changeRecord_withdrawOrder" /*提现订单*/,
            topInfo: () => `${$t("funds_withdraw_success" /* ""提现成功！"" */)}!`,
            contentCallabck: withdrawContent,
            balanceBefore: 0,
            balanceAfter: 0,
          };
        case FundsTypeEnum.QUOTA_CONVERSION:
          return {
            title: "mine_record_billDetail" /*帐单详情*/,
            topInfo: () =>
              props.params?.platformName + $t(getTransferType(Number(props.params?.transferType))),
            contentCallabck: billFlowContent,
            balanceBefore:
              Number(props.params?.transferType) === 2
                ? amountFormat(Number(props.params?.amount))
                : 0,
            balanceAfter:
              Number(props.params?.transferType) === 2
                ? amountFormat(Number(props.params?.balance))
                : amountFormat(Number(props.params?.amount)),
          };
        default:
          return {
            title: "mine_record_billDetail" /*帐单详情*/,
            topInfo: () => "",
            contentCallabck: () => {},
            balanceBefore: 0,
            balanceAfter: 0,
          };
      }
    };

    // 账变 &&  额度
    const billFlowContent = () => (
      <Fragment>
        <div class={styleModule.detailPageContentMiddleLine}>
          <div class={styleModule.detailPageContentMiddleLineLeft}>
            {$t("mine_record_beforeChange" /* "账户变前余额" */)}
          </div>
          <div class={styleModule.detailPageContentMiddleLineRight}>
            {amountFormat(getInfoByFundsType().balanceBefore)}
            <span>({props.params?.currency})</span>
          </div>
        </div>
        <div class={styleModule.detailPageContentMiddleLine}>
          <div class={styleModule.detailPageContentMiddleLineLeft}>
            {$t("mine_record_afterChange" /* "账户变后余额" */)}
          </div>
          <div class={styleModule.detailPageContentMiddleLineRight}>
            {amountFormat(getInfoByFundsType().balanceAfter)}
            <span>({props.params?.currency})</span>
          </div>
        </div>
      </Fragment>
    );
    // 充值
    const rechargerContent = () => (
      <Fragment>
        <div class={styleModule.detailPageContentMiddleLine}>
          <div class={styleModule.detailPageContentMiddleLineLeft}>
            {$t("funds_recharge_discount" /* "优惠" */)}
          </div>
          <div class={styleModule.detailPageContentMiddleLineRight}>
            {amountFormat(Number(props.params?.discountAmount || 0))}
            <span>({props.params?.currency})</span>
          </div>
        </div>
        <div class={styleModule.detailPageContentMiddleLine}>
          <div class={styleModule.detailPageContentMiddleLineLeft}>
            {$t("funds_withdraw_actual_amount" /* "实际到账金额" */)}
          </div>
          <div class={styleModule.detailPageContentMiddleLineRight}>
            {amountFormat(Number(props.params?.totalAmount || 0))}
            <span>({props.params?.currency})</span>
          </div>
        </div>
      </Fragment>
    );
    // 未完成充值
    const rechargingContent = () => (
      <Fragment>
        <div class={styleModule.detailPageContentMiddleLine}>
          <div class={styleModule.detailPageContentMiddleLineLeft}>
            {$t("funds_recharge_discount" /* "充值优惠" */)}
          </div>
          <div class={styleModule.detailPageContentMiddleLineRight}>
            {amountFormat(Number(props.params?.discountAmount || 0))}
            <span>({props.params?.currency})</span>
          </div>
        </div>
        <div class={styleModule.detailPageContentMiddleLine}>
          <div class={styleModule.detailPageContentMiddleLineLeft}>
            {$t("funds_withdraw_actual_amount" /* "实际到账金额" */)}
          </div>
          <div class={styleModule.detailPageContentMiddleLineRight}>
            {amountFormat(Number(props.params?.totalAmount || 0))}
            <span>({props.params?.currency})</span>
          </div>
        </div>
      </Fragment>
    );
    // 提现
    const withdrawContent = () => (
      <Fragment>
        <div class={styleModule.detailPageContentMiddleLine}>
          <div class={styleModule.detailPageContentMiddleLineLeft}>
            {$t("mine_changeRecord_withdraw_service_fee" /* "手续费" */)}
            {amountFormat(props.params?.counterFee || 0)}%
          </div>
          <div class={styleModule.detailPageContentMiddleLineRight}>
            {amountFormat(Number(props.params?.counterFee))}
            <span>({props.params?.currency})</span>
          </div>
        </div>
        <div class={styleModule.detailPageContentMiddleLine}>
          <div class={styleModule.detailPageContentMiddleLineLeft}>
            {$t("funds_withdraw_account_balance" /* "账户余额" */)}
          </div>
          <div class={styleModule.detailPageContentMiddleLineRight}>
            {amountFormat(Number(props.params?.accountMoney || 0))}
            <span>({props.params?.currency})</span>
          </div>
        </div>
      </Fragment>
    );

    return () => (
      <div class={styleModule.detailPage}>
        <div class={popup.popupHeader}>
          <section class={popup.popupHeaderLeft} onClick={() => emit("close")} />
          <section class={popup.popupHeaderMiddle}>{$t(getInfoByFundsType().title)}</section>
          <section class={popup.popupHeaderRight} onClick={() => emit("close")}>
            <SvgIcon class={popup.popupHeaderIcon} name="close" width="0.48rem" height="0.48rem" />
          </section>
        </div>
        <div class={styleModule.detailPageContent}>
          <div class={styleModule.detailPageContentTop}>
            {fundsType === FundsTypeEnum.RECHARGE_ORDER ? (
              <SvgIcon
                name="recharger"
                width="2.96rem"
                height="2.96rem"
                style={{ marginBottom: "0.16rem", color: "@cms-mango-primary-color" }}
              />
            ) : (
              <img
                class={styleModule.detailPageContentTopImg}
                src={
                  fundsType === FundsTypeEnum.WITHDRAW_ORDER
                    ? withdrawImg
                    : Number(props.params?.amount) > 0
                    ? moneyIn
                    : moneyOut
                }
                alt=""
              />
            )}
            <div class={styleModule.detailPageContentTopInfo}>{getInfoByFundsType().topInfo()}</div>
            <div
              class={[
                styleModule.detailPageContentTopMoney,
                Number(props.params?.amount) < 0
                  ? styleModule.detailPageContentTopMoneyRed
                  : styleModule.detailPageContentTopMoneyGreen,
              ]}
            >
              {fundsType === FundsTypeEnum.WITHDRAW_ORDER ? (
                <div class={styleModule.detailPageContentTopMoneyWithdraw}>
                  -{amountFormat(Number(props.params?.approveMoney) || 0)}
                </div>
              ) : fundsType === FundsTypeEnum.QUOTA_CONVERSION ? (
                <div
                  class={[
                    Number(props.params?.transferType) === 2 &&
                      styleModule.detailPageContentTopMoneyRed,
                  ]}
                >
                  {Number(props.params?.transferType) === 1 ? "+" : "-"}
                  {amountFormat(Number(props.params?.amount) || 0)}
                </div>
              ) : (
                <div>
                  {Number(props.params?.amount) > 0 && "+"}
                  {amountFormat(Number(props.params?.amount) || 0)}
                </div>
              )}
              <span>({props.params?.currency || "--"})</span>
            </div>
          </div>
          <div class={styleModule.detailPageContentMiddle}>
            {getInfoByFundsType().contentCallabck()}
          </div>
          <div class={styleModule.detailPageContentBottom}>
            {![FundsTypeEnum.QUOTA_CONVERSION].includes(fundsType) && (
              <div class={styleModule.detailPageContentBottomLine}>
                <div class={styleModule.detailPageContentBottomLineLeft}>
                  {$t("funds_withdraw_orderNo" /* "订单号" */)}
                </div>
                <div class={styleModule.detailPageContentBottomLineRight}>
                  {props.params?.orderNo || props.params?.cashOrderNo}
                  <span
                    class={styleModule.detailPageContentBottomLineRightCopy}
                    onClick={() => copyText(props.params?.orderNo || props.params?.cashOrderNo)}
                  >
                    {$t("common_copy" /* 复制 */)}
                  </span>
                </div>
              </div>
            )}
            <div class={styleModule.detailPageContentBottomLine}>
              <div class={styleModule.detailPageContentBottomLineLeft}>
                {$t("funds_recharge_tradingTime" /* "交易时间" */)}
              </div>
              <div class={styleModule.detailPageContentBottomLineRight}>
                {toTimeNormal(Number(props.params?.createTime), "YYYY-MM-DD HH:mm:ss") || "--"}
              </div>
            </div>
            {[FundsTypeEnum.RECHARGE_ORDER].includes(fundsType) && (
              <div class={styleModule.detailPageContentBottomLine}>
                <div class={styleModule.detailPageContentBottomLineLeft}>
                  {$t("funds_recharge_payWay" /* "充值方式" */)}
                </div>
                <div class={styleModule.detailPageContentBottomLineRight}>
                  {props.params?.payTypeName || ""}
                </div>
              </div>
            )}
            {fundsType === FundsTypeEnum.WITHDRAW_ORDER && (
              <div class={styleModule.detailPageContentBottomLine}>
                <div class={styleModule.detailPageContentBottomLineLeft}>
                  {$t("funds_withdraw_bizType" /* "提现方式" */)}
                </div>
                <div class={styleModule.detailPageContentBottomLineRight}>
                  {$t(getWithdrawType(props.params?.withdrawType as string))}
                </div>
              </div>
            )}
            {fundsType === FundsTypeEnum.WITHDRAW_ORDER && props.params.cashStatus == 4 && (
              <div class={styleModule.detailPageContentBottomLine}>
                <div class={styleModule.detailPageContentBottomLineLeft}>
                  {$t("funds_recharge_remark" /* "备注" */)}
                </div>
                <div
                  class={[
                    styleModule.detailPageContentBottomLineRight,
                    styleModule.detailPageContentBottomLineRemark,
                  ]}
                >
                  {props.params?.approveReason || $t("common_no_remark")}
                </div>
              </div>
            )}
            {[FundsTypeEnum.ACCOUNT_CHANGE_RECORD, FundsTypeEnum.RECHARGE_ORDER].includes(
              fundsType
            ) && (
              <div class={styleModule.detailPageContentBottomLine}>
                <div class={styleModule.detailPageContentBottomLineLeft}>
                  {$t("funds_recharge_remark" /* "备注" */)}
                </div>
                <div
                  class={[
                    styleModule.detailPageContentBottomLineRight,
                    styleModule.detailPageContentBottomLineRemark,
                  ]}
                >
                  {props.params?.content || props.params?.remark || $t("common_no_remark")}
                </div>
              </div>
            )}
          </div>
        </div>
        {fundsType === FundsTypeEnum.RECHARGE_ORDER && (
          <div class={styleModule.detailPageBottom}>
            <div class={styleModule.detailPageBottomBox}>
              <p>{$t("funds_recharge_question" /* "对订单有疑问？" */)}</p>
              <div class={styleModule.detailPageBottomBoxBtn} onClick={() => goService()}>
                {$t("user_contactUs_actionTitle" /* "联系客服" */)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
});
