import { defineComponent, ref, PropType } from "vue";
import style from "./style.module.less";
import { useRecharge } from "core";
import Switch from "./components/Switch";
import { mangoPopup, NoticeContent } from "components";
import popup from "templates/mango/Mobile/popup.module.less";
import { SvgIcon } from "commom";
import RechargeView from "../MobileRecharge/MobileRecharge";
import RechargeView109 from "../MobileRecharge/MobileRechargeLam109";
import WithdrawView from "../Withdraw/MobileApplyWithdrawal";
export default defineComponent({
  props: {
    switchVal: {
      type: String as PropType<string>,
      default: "deposit",
    },
    isPay: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { store } = useRecharge();
    const isPay = ref(false);
    const tabVal = ref<string>(props.switchVal);
    const changeValue = (val: string) => {
      tabVal.value = val;
    };
    return () => (
      <div class={style.rechargmodal}>
        <div class={style.close}>
          <section
            class={popup.popupHeaderRight}
            onClick={() =>
              !isPay.value ? mangoPopup().close() : ((store.visible = false), (isPay.value = false))
            }
          >
            <SvgIcon class={popup.popupHeaderIcon} name="close" width="0.48rem" height="0.48rem" />
          </section>
        </div>
        <div class={style.rechargmodalMain}>
          <Switch onChange={changeValue} tabVal={tabVal.value} />
          {/* 公告模块 */}
          <div class={style.rechargmodalNotice}>
            <SvgIcon name="notice" width="24px" height="24px" color="@cms-mango-text-color" />
            <NoticeContent
              styleStr={{ width: "100%", color: "@cms-mango-text-color" }}
              onClose={() => emit("close")}
            />
          </div>
        </div>
        {tabVal.value == "deposit" ? (
          ["lam109"].includes(window.CONFIG?.name) ? (
            <RechargeView109 onClose={() => (isPay.value = true)} />
          ) : (
            <RechargeView onClose={() => (isPay.value = true)} />
          )
        ) : (
          <WithdrawView isPay={isPay.value} onClose={() => (isPay.value = true)} />
        )}
      </div>
    );
  },
});
