import { computed, reactive } from "vue";
import { Api } from "../services";
import { HelpData } from "@kgsport-cms/service";
// import { useToast } from "core";

/**
 * 帮助中心hooks
 * */
const store = reactive<{
  dataList: HelpData[];
  loading: boolean;
}>({
  dataList: [],
  loading: false,
});
export function useHelp() {
  // const { toast } = useToast();
  const navList = computed(() => store.dataList);
  const openKeys = computed(() => [store.dataList && store.dataList[0].id]);
  const selectedKeys = computed(() => [store.dataList && store.dataList[0]?.children[0]?.id]);
  // 获取帮助中心列表
  const getHelpList = async () => {
    try {
      const { data, result } = await Api.HelpService.helpList();
      if (result) {
        store.dataList = data || [];
      }
    } catch (e) {
      console.log("帮助中心接口异常", e);
    }
  };

  return {
    store,
    navList,
    openKeys,
    selectedKeys,
    getHelpList,
  };
}
