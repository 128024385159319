import { defineComponent, ref } from "vue";
import style from "./style.module.less";
import { useI18n, useMessage } from "core";
import { mangoPopup } from "components";
import popup from "templates/mango/Mobile/popup.module.less";
import { SvgIcon } from "commom";
import MyMessage from "./MyMessage";
import MessageDetail from "./MyMessage/MessageDetail";
export default defineComponent({
  props: {
    type: {
      type: String,
      default: "message",
    },
  },
  setup(props) {
    const { $t } = useI18n();
    const { store, messageType } = useMessage();
    store.type = props.type;
    const toDetail = ref(false);
    const data = ref({
      editMode: false,
      messageType: messageType,
    });
    let message = ref({
      id: -99,
    });
    const changeVal = (val: any) => {
      data.value.editMode = false;
      data.value = val;
    };
    const showDetail = (messageInfo: any) => {
      message.value.id = messageInfo.id;
      toDetail.value = true;
    };
    const backMessage = () => {
      toDetail.value = false;
    };
    return () => (
      <div class={[style.messagegmodal, "mangoPage"]}>
        <div class={style.close}>
          {toDetail.value ? (
            <div class={style.arrow} onClick={backMessage}>
              <SvgIcon
                class={style.arrowIcon}
                name="mangoArrowRight"
                width="0.48rem"
                height="0.48rem"
              />
            </div>
          ) : (
            <p class={style.navRight}></p>
          )}
          <p class={style.titlename}>
            {toDetail.value
              ? $t("mine_message_details" /*我的消息*/)
              : $t("mine_center_message" /*我的消息*/)}
          </p>
          <section
            class={popup.popupHeaderRight}
            onClick={() => {
              data.value.editMode = false;
              mangoPopup().destroyAll();
            }}
          >
            <SvgIcon class={popup.popupHeaderIcon} name="close" width="0.48rem" height="0.48rem" />
          </section>
        </div>
        {toDetail.value ? (
          <MessageDetail id={message.value.id} />
        ) : (
          <MyMessage onChange={changeVal} onGotoDetail={showDetail} messageType={props.type} />
        )}
      </div>
    );
  },
});
