/**
 * 1、全局弹窗
 */
import { createVNode, getCurrentInstance, render as vueRender, inject } from "vue";
import {
  MODAL_INJECTION_KEY,
  ModalInjectionContext,
  modal,
  ModalWrap,
  ModalContext,
} from "commom/modal";
interface ModalOption {
  title?: any;
  footer?: any;
  content?: any;
  contentText?: any;
  onClose?: () => void;
  parentContext?: any;
  appContext?: any;
  bodyClass?: string | string[] | null;
  transitionName?: string;
  position?: string;
  destroyOnClose?: boolean;
  wrapClassName?: string;
  [key: string]: any;
}

let index = 1;
const destroyFns: any[] = [];

export function useModalContext() {
  return inject<ModalInjectionContext>(MODAL_INJECTION_KEY, ModalContext) || null;
}
/**
 * 清空所有弹窗
 */
export const destroyModalAll = () => {
  const copyDestroyFns = [...destroyFns];
  while (copyDestroyFns.length) {
    const close = copyDestroyFns.pop();
    if (close) {
      close();
    }
  }
};

export function useModal(option: ModalOption) {
  let _div: any = null;
  let _vm: any = null;
  let _index: any = null;

  const close = () => {
    if (_vm) {
      // destroy
      update({ visible: false });
      vueRender(null, _div as any);
      _vm.component.update();
      _vm = null;
    }

    for (let i = 0; i < destroyFns.length; i++) {
      const fn = destroyFns[i];
      if (fn === close) {
        destroyFns.splice(i, 1);
        index--;
        _div && document.body.removeChild(_div);
        break;
      }
    }
  };

  const update = (newConfig: any) => {
    if (!_vm) return;
    baseConfig = {
      ...baseConfig,
      ...newConfig,
    };
    Object.assign(_vm.component.props, baseConfig);
    _vm.component.update();
  };

  const { title, footer = null, bodyClass = null, onClose = close, ...other } = option || {};
  const appContext = getCurrentInstance() && (getCurrentInstance() as any).appContext;
  let baseConfig = {
    visible: true,
    footer,
    title,
    ...other,
    onClose,
    bodyClass,
  };

  const renderModal = (props: any, el: HTMLElement) => {
    const vm = createVNode(ModalWrap, { ...props, close, update, baseConfig });
    vm.appContext = option.parentContext || option.appContext || appContext || vm.appContext;
    vueRender(vm, el);
    return vm;
  };

  const createEl = () => {
    _index = index;
    const div = document.createElement("div");
    div.classList.add("cms-modal-container", `cms-modal-container-${index}`);
    option.wrapClassName && div.classList.add(option.wrapClassName);
    document.body.appendChild(div);
    index++;
    return div;
  };

  const open = (modalProps = {}, option: Partial<ModalOption> = {}) => {
    if (_vm && destroyFns.length) {
      update({
        visible: true,
        modalProps,
        ...option,
      });
      return;
    }
    _div = createEl();
    _vm = renderModal(Object.assign(baseConfig, { modalProps, visible: true }, option), _div);
    destroyFns.push(close);
  };
  return {
    open,
    close,
    update,
    destroyAll: destroyModalAll,
    modal,
  };
}
