import { defineComponent, toRaw, ref, watch } from "vue";
import { Popup } from "vant";
import style from "./arecode.module.less";
import { Empty } from "components";
import RadioCircle from "./components/RadioCircle";
import { useI18n, useConfig, useUser } from "core";
import { IDialCode } from "@kgsport-cms/service";
import { SvgIcon } from "commom";
export default defineComponent({
  props: {
    currency: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 600,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change", "update:show"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { areaCodeList } = useConfig();
    const { user } = useUser();
    // 搜索
    const areaCodeData = ref<IDialCode[]>([]);
    // 选中code
    const currentCode = ref<string>(
      user.value?.zaloAreaCode ? user.value?.zaloAreaCode : props.currency
    );
    const setAreaCodeItem = (option: IDialCode) => {
      currentCode.value = option.code;
      emit("update:show", false);
      emit("change", toRaw(option));
    };
    watch(
      () => areaCodeList.value,
      n => {
        areaCodeData.value = n;
      },
      { immediate: true, deep: true }
    );
    const searchVal = ref("");
    const setAreaCodeData = (text: string) => {
      areaCodeData.value = text
        ? areaCodeList?.value.filter(d => d.code.includes(text)) ?? []
        : areaCodeList?.value;
    };
    const timer = ref();
    watch(
      () => searchVal.value,
      (n, o) => {
        if (n === o) return;
        if (timer.value) clearTimeout(timer.value);
        timer.value = setTimeout(() => setAreaCodeData(n), 300);
      }
    );
    const clearSearch = () => {
      searchVal.value = "";
    };
    return () => (
      <Popup
        position="bottom"
        v-model:show={props.show}
        close-on-click-overlay
        onClick-overlay={() => emit("update:show", false)}
        round
        class={style.areaCode}
      >
        <div class={style.areaCodeHeader}>
          <section class={style.areaCodeHeaderCode}>
            {$t("user_register_ToastTitle_areaCode" /* 选择区号 */)}
          </section>
          <section class={style.areaCodeHeaderSearch}>
            <input
              v-model={searchVal.value}
              type="text"
              placeholder={$t("user_register_searchAreaCode" /**搜索区号 */)}
            />
            <i class={style.areaCodeHeaderSearchClose} onClick={clearSearch}>
              <SvgIcon
                class={style.areaCodeHeaderSearchCloseIcon}
                name="closeTick"
                width="14px"
                height="14px"
              />
            </i>
          </section>
        </div>
        <div class={style.areaCodeMain} style={{ height: props.height + "px" }}>
          {areaCodeData.value && areaCodeData.value.length ? (
            areaCodeData.value?.map(option => (
              <div
                class={style.areaCodeOption}
                key={option.code}
                onClick={() => setAreaCodeItem(option)}
              >
                <section class={style.areaCodeOptionText}>
                  <span>{option.name}</span>
                  <span>({option.code})</span>
                </section>
                <RadioCircle checked={currentCode.value === option.code} />
              </div>
            ))
          ) : (
            <Empty type="mangoNoData" top={50} />
          )}
        </div>
      </Popup>
    );
  },
});
