// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Lazyload from "vant/es/lazyload/vue-lazyload/lazy";
const LazyClass = Lazyload();
const lazy = new LazyClass({
  preLoad: 1.3,
  attempt: 3,
  observer: true,
  listenEvents: ["scroll", "transitionend"],
  filter: {
    progressive(listener: any, options: any) {
      // 实现渐近式加载图片（先加载模糊的图）
      const { src } = listener;
      if (src && src.includes("@thumb")) {
        listener.el.setAttribute("lazy-progressive", "true");
        // 先加载模糊的图
        const loadingUrl = listener.src;
        if (options.supportWebp) {
          // loadingUrl += ".webp";
        }
        listener.loading = loadingUrl;
        // 最后加载清晰一点的图
        // listener.src += "@thumb";
      }
    },
    webp(listener: any, options: any) {
      // 加载 webp 图
      if (!options.supportWebp || process.env.NODE_ENV == "development") return;
      const { src } = listener;
      if (!src) return;
      if (src.includes("/assets") || src.includes("/img")) {
        // listener.src += ".webp";
      }
    },
  },
  adapter: {
    error(listender: any, Init: any) {
      const { el } = listender;
      if (el.parentNode.hasAttribute("data-load")) {
        el.parentNode.setAttribute("data-load", "error");
      }
    },
    loaded(listender: any) {
      const { el } = listender;
      if (el.parentNode.hasAttribute("data-load")) {
        el.parentNode.setAttribute("data-load", "loaded");
      }
    },
    loading(listender: any) {
      const { el } = listender;
      if (el.parentNode.hasAttribute("data-load")) {
        el.parentNode.setAttribute("data-load", "loading");
      }
    },
  },
});
export default {
  beforeMount: lazy.add.bind(lazy),
  updated: lazy.update.bind(lazy),
  unmounted: lazy.remove.bind(lazy),
};
