import { createAuth } from "./useCheck.hook";
export function usePath() {
  // 登录跳转
  const goLogin = createAuth("/entry/login");
  // 注册跳转
  // const goRegister = createAuth("/entry/register");
  // 首页
  const goHome = createAuth("/");
  // 进入游戏
  const goGame = createAuth("/game");
  // 站内信
  const goMessage = createAuth("/account/message");
  //  充值
  const goRecharge = createAuth("/recharge");
  // 活动详情
  const goActivityDetail = createAuth("/activity/detail");
  // 指南
  const goRegisterGuide = createAuth("/registerGuide");
  // 游戏中心
  const goGameCenter = createAuth("/gameCenter");

  return {
    goLogin,
    // goRegister,
    goHome,
    goGame,
    goMessage,
    goRecharge,
    goActivityDetail,
    goRegisterGuide,
    goGameCenter,
  };
}
