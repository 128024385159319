/**
 * 1、APP初始化环境，设置token
 * 2、检查token是否存在，
 * 3、获取登录token、设置token
 * 4、登出 && 清除token
 */
import { computed, reactive } from "vue";
import { tryOnScopeDispose } from "@vueuse/core";
import { useRouter } from "vue-router";
import {
  STORE_TOKEN,
  isHybridApp,
  getBridgeToken,
  STORE_GLOBEL_CONFIGS,
  STORE_USER_INFO,
  CMS_JUMP_DATA,
} from "../common";
import { Api } from "../services";
import { useToast } from "./useToast.hook";
import { useSetWallet } from "./useWallet.hook";
import { useSetUser } from "./useUser.hook";
import { useI18nFn } from "../i18n";
import { useLocalStorage } from "core";
import { showMangoModalTips } from "components";

export interface AuthStore {
  token: string | null;
  userId: number;
  user: any | null;
}

const facebookLogout = () => {
  const FB = window.FB as any;
  FB &&
    FB.getLoginStatus(function (response: any) {
      if (response.status === "connected") {
        FB?.logout();
      }
    });
};

// 不要直接操作该store 以免鉴权信息丢失
const store = reactive<AuthStore>({
  token: localStorage[STORE_TOKEN],
  userId: -1,
  user: null,
});

(async function () {
  if (isHybridApp()) {
    const loca = localStorage[STORE_TOKEN];
    store.token = loca || "";
    const token = await getBridgeToken();
    store.token = token;
    localStorage[STORE_TOKEN] = token;
    console.log(token, "异步获取token");
  }
})();

// 检查token
export async function checkLogin() {
  if (localStorage[STORE_TOKEN]) return true;
  return false;
}

const { localStore } = useLocalStorage();

export function useAuth() {
  const isLogin = computed<boolean>(() => !!store.token);
  const router = useRouter();
  tryOnScopeDispose(async () => {
    if (isHybridApp()) {
      const data = await getBridgeToken();
      localStorage[STORE_TOKEN] = data;
      store.token = data;
      return;
    }
    store.token = localStorage[STORE_TOKEN];
  });
  // 此处暴露操作方法 进行数据操作
  // 回调函数
  const getToken = async () => {
    if (isHybridApp()) return (await getBridgeToken()) || localStorage[STORE_TOKEN];
    return localStorage[STORE_TOKEN] || store.token;
  };
  const setToken = (token: string): void => {
    store.token = token || null;
    localStorage[STORE_TOKEN] = token;
  };
  const setMaintain = () => {
    const router = useRouter();
    router.push("/maintain");
  };
  const logout = async (cb?: () => void) => {
    // const { toast } = useToast();
    const wallet = useSetWallet().store;
    const user = useSetUser().store;
    try {
      const { result } = await Api.AuthService.logout();
      if (!result) {
        return;
      }
      // toast.success($t("user_toast_logouSuccess" /*退出成功*/));
      localStorage[STORE_GLOBEL_CONFIGS] = {};
      facebookLogout();
      store.token = localStorage[STORE_TOKEN] = "";
      wallet.lotteryData.balance = 0;
      wallet.lotteryData.freeze = 0;
      wallet.lotteryData.gameTotal = 0;
      wallet.lotteryData.gameBalance = [];
      user.user = null;
      localStore.remove(STORE_USER_INFO);
      localStore.remove(CMS_JUMP_DATA);
      if (window.jsBridge && window.CONFIG?.name === "int106") {
        window.jsBridge?.postMessage("logout", '{"success":1}');
      }
      cb?.();
      if (window.CONFIG?.currentModel === "mango") {
        router.push("/");
      }
    } catch (e) {
      console.error(e);
    }
  };

  // 会员注销
  const memberCancellation = async () => {
    const { $t } = useI18nFn();
    const { toast } = useToast();
    showMangoModalTips({
      title: $t("common_tip" /* 提示 */),
      subTitle: $t("common_confirm_del_account" /*确定要删除账号吗？*/),
      confirmText: $t("common_confirm" /*确定*/),
      cancelText: $t("common_cancel" /*"取消"*/),
      async confirmCallback() {
        const user = useSetUser().store;
        try {
          const { result, message } = await Api.AuthService.memberCancellation();
          if (!result) {
            message && toast.error(message);
            return;
          }
          // toast.success(message || $t("common_operate_success" /**操作成功 */));
          facebookLogout();
          localStorage[STORE_GLOBEL_CONFIGS] = {};
          store.token = localStorage[STORE_TOKEN] = "";
          user.user = null;
          localStore.remove(STORE_USER_INFO);
          localStore.remove(CMS_JUMP_DATA);
          await router.push("/");
          if (window.CONFIG?.currentModel === "mango") {
            window.location.reload();
          }
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  // 通过用户名密码注销接口   /api/auth/cancelledByName
  const cancelledByName = async (data: { username: string; password: string }) => {
    const user = useSetUser().store;
    try {
      const { result } = await Api.AuthService.cancelledByName(data);
      if (!result) return false;
      facebookLogout();
      localStorage[STORE_GLOBEL_CONFIGS] = {};
      store.token = localStorage[STORE_TOKEN] = "";
      user.user = null;
      localStore.remove(STORE_USER_INFO);
      localStore.remove(CMS_JUMP_DATA);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  return {
    isLogin,
    setToken,
    getToken,
    setMaintain,
    logout,
    memberCancellation,
    cancelledByName,
  };
}
