import { defineComponent } from "vue";
import { Form, Input } from "ant-design-vue";
import { Button } from "vant";
import style from "./modifylogin.module.less";
import { useI18n, useUser } from "core";
import popup from "templates/mango/Mobile/popup.module.less";
import { SvgIcon } from "commom";
import { mangoPopup } from "components";

export default defineComponent({
  emits: ["close"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { passAccount, passForm, reqeustLoading, editPassSubmit } = useUser();
    return () => (
      <div class={style.modifyPassword}>
        <div class={[popup.popupHeader, popup.popupHeaderBorder]}>
          <section class={popup.popupHeaderLeft}></section>
          <section class={popup.popupHeaderMiddle}>
            {$t("mine_userinfo_ModifyLoginPassword" /*修改登录密码*/)}
          </section>
          <section class={popup.popupHeaderRight} onClick={() => mangoPopup().destroyAll()}>
            <SvgIcon class={popup.popupHeaderIcon} name="close" width="0.48rem" height="0.48rem" />
          </section>
        </div>
        <Form class={style.modifyPassword}>
          <Form.Item
            {...passAccount.validateInfos.rawPassword}
            validateFirst
            class={style.listFormItem}
            name="rawPassword"
          >
            <Input.Password
              placeholder={$t("mine_userinfo_pleaseEnterOldPassword" /*请输入旧密码*/)}
              type="password"
              v-model:value={passForm.rawPassword}
            />
          </Form.Item>
          <Form.Item
            {...passAccount.validateInfos.newPassword}
            validateFirst
            class={style.listFormItem}
            name="newPassword"
          >
            <Input.Password
              placeholder={$t("mine_userinfo_pleaseEnterNewPassword" /*请输入新密码*/)}
              type="password"
              v-model:value={passForm.newPassword}
            />
          </Form.Item>
          <Form.Item
            {...passAccount.validateInfos.confirmPassword}
            validateFirst
            class={style.listFormItem}
            name="confirmPassword"
          >
            <Input.Password
              placeholder={$t("mine_userinfo_pleaseConfirmNewPass" /*请确认新密码*/)}
              type="password"
              v-model:value={passForm.confirmPassword}
            />
          </Form.Item>
          <div class={style.modifyPasswordBtns}>
            <Button
              onClick={async () => {
                await editPassSubmit();
                emit("close");
              }}
              loading={reqeustLoading.value}
            >
              {$t("mine_userinfo_confirmSubmission" /*确认提交*/)}
            </Button>
          </div>
        </Form>
      </div>
    );
  },
});
