import { useUser } from "core";
export function usePointStatistics() {
  const { user } = useUser();
  // 用户登录成功各类埋点
  const LoginSuccessStatistice = () => {
    // fb埋点
    window.fbq && window.fbq("track", "Contact");
    // af埋点1
    window.redGo && window.redGo.sendTY("login", JSON.stringify({}));
    // 快手埋点
    window.kwaiq &&
      window.kwaiq.instance(localStorage.getItem("kwaiPixelBaseCode")).track("subscribe");

    // af埋点标准
    window.jsBridge && window.jsBridge?.postMessage("login", "{}");
    // lam103游戏sdk埋点
    if (window.JYSDK) {
      const roleInfo = {
        isCreateRole: true,
        roleCreateTime: user.value?.registerTime,
        serverId: 1,
        serverName: "内测1区",
        userRoleName: user.value?.account,
        userRoleId: "roleId1",
        userRoleBalance: 1000,
        vipLevel: user.value?.vipLevel,
        userRoleLevel: 1,
        partyId: 1,
        partyName: "行会名称",
        gameRoleGender: "男",
        gameRolePower: 100,
        partyRoleId: 1,
        partyRoleName: "会长",
        professionId: "1",
        profession: "武士",
        friendlist: "",
      };
      window.JYSDK.uploadGameRoleInfo(JSON.stringify(roleInfo));
    }
    // lam106埋点
    if (window.jsBridge && window.CONFIG?.name === "int106") {
      window.jsBridge?.postMessage("login", '{"success":1}');
    }
  };
  // 用户注册成功各类埋点
  const registerSuccessStatistice = () => {
    // int106马甲包埋点渠道1
    if (window.CONFIG?.name === "int106") {
      window.androidJS && window.androidJS.tracker("register", JSON.stringify({}));
      window.H5Inject &&
        window.H5Inject.onEvent(JSON.stringify({ evnetKey: "register", eventValue: {} }));
      window.jsBridge && window.jsBridge?.postMessage("register", '{"success":1}');
    }
    // fb埋点
    window.fbq && window.fbq("track", "CompleteRegistration");

    // af标准事件埋点
    window.jsBridge && window.jsBridge?.postMessage("register", "{}");
    // lam103特殊埋点
    window.redGo && window.redGo.sendTY("register", JSON.stringify({}));
    // 快手标准事件埋点
    window.kwaiq &&
      window.kwaiq
        .instance(localStorage.getItem("kwaiPixelBaseCode"))
        .track("completeRegistration");
    // 129埋点
    window.H5Inject &&
      window.H5Inject.onEvent(JSON.stringify({ evnetKey: "register", eventValue: {} }));
  };
  // 用户点击充值功能埋点
  const clickRechargeSuccessStatistice = () => {
    // lam103埋点事件统计
    window.redGo && window.redGo.sendTY("payStart", JSON.stringify({}));
    // fb标准事件埋点
    window.fbq && window.fbq("track", "InitiateCheckout");
    // 快手标准事件埋点
    window.fbq &&
      window.kwaiq.instance(localStorage.getItem("kwaiPixelBaseCode")).track("addToCart");

    // af标准事件埋点
    window.jsBridge && window.jsBridge?.postMessage("rechargeClick", "{}");
  };
  // 用户首充功能埋点
  const firstRechargeSuccessStatistice = () => {
    // int129埋点统计
    window.H5Inject &&
      window.H5Inject.onEvent(JSON.stringify({ evnetKey: "frist_recharge", eventValue: {} }));
    // fb标注事件埋点
    window.fbq &&
      window.fbq("track", "Purchase", `{ currency: ${user.value?.currency || "BRL"}, value: 0.0 }`);
    window.fbq &&
      window.fbq(
        "track",
        "firstDeposit",
        `{ currency: ${user.value?.currency || "BRL"}, value: 0.0 }`
      );
    // af标准事件
    window.jsBridge && window.jsBridge?.postMessage("frist_recharge", "{}");
    // 快手标准事件
    window.kwaiq &&
      window.kwaiq.instance(localStorage.getItem("kwaiPixelBaseCode")).track("firstDeposit");
    window.kwaiq &&
      window.kwaiq.instance(localStorage.getItem("kwaiPixelBaseCode")).track("purchase");
    // lam106特殊事件
    window.androidJS && window.androidJS.tracker("frist_recharge", JSON.stringify({}));
    window.H5Inject &&
      window.H5Inject.onEvent(JSON.stringify({ evnetKey: "frist_recharge", eventValue: {} }));
    // lam103特殊事件
    window.redGo && window.redGo.sendTY("frist_recharge", JSON.stringify({}));
  };
  // 用户复冲功能埋点
  const repeatedlyRechargeSuccessStatistice = () => {
    // int106埋点统计
    window.androidJS && window.androidJS.tracker("repeatedly_recharge", JSON.stringify({}));
    window.H5Inject &&
      window.H5Inject.onEvent(JSON.stringify({ evnetKey: "repeatedly_recharge", eventValue: {} }));
    // af标准事件
    window.jsBridge && window.jsBridge?.postMessage("repeatedly_recharge", "{}");
    // fb标准事件
    // window.fbq && window.fbq("track", "Purchase");
    window.fbq &&
      window.fbq("track", "Purchase", `{ currency: ${user.value?.currency || "BRL"}, value: 0.0 }`);
    // 快手标准事件
    window.kwaiq &&
      window.kwaiq.instance(localStorage.getItem("kwaiPixelBaseCode")).track("purchase");

    // lam103标准事件
    window.redGo && window.redGo.sendTY("repeatedly_recharge", JSON.stringify({}));
  };

  return {
    LoginSuccessStatistice,
    registerSuccessStatistice,
    clickRechargeSuccessStatistice,
    firstRechargeSuccessStatistice,
    repeatedlyRechargeSuccessStatistice,
  };
}
