import { computed, defineComponent, PropType } from "vue";
import { Overlay, Button } from "vant";
import { useVModel } from "@vueuse/core";
import { SvgIcon } from "commom";
import style from "./mangoTipsModal.module.less";

const Icon = {
  info: "mangoInfo",
  success: "success",
  recharge: "tipsRecharge",
  withdrawal: "tipsWithdrawal",
};

export default defineComponent({
  props: {
    show: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    title: {
      type: String as PropType<string>,
      default: "",
    },
    subTitle: {
      type: String as PropType<string>,
      default: "",
    },
    type: {
      type: String as PropType<"info" | "success" | "recharge" | "withdrawal">,
      default: "info",
    },
    confirmText: {
      type: String as PropType<string>,
      default: "",
    },
    cancelText: {
      type: String as PropType<string>,
      default: "",
    },
    confirmCallback: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    cancelCallback: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    // 点击遮罩是否关闭弹窗
    maskClosable: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const data = useVModel(props, "show", emit);
    const icon = computed(() => Icon[props.type]);

    return () => (
      <div class={style.overlay}>
        <Overlay show={data.value} zIndex={99} onClick={() => props.maskClosable && props.close()}>
          <div class={style.overlayWrap}>
            <div
              class={style.overlayWrapClose}
              onClick={() => {
                data.value = false;
              }}
            >
              <SvgIcon
                onClick={() => {
                  data.value = false;
                  props.cancelCallback();
                }}
                class={style.overlayContentBottomIcon}
                width="16px"
                height="16px"
                color={"#849FCE"}
                name="close"
              />
            </div>
            <div class={style.overlayContent}>
              <h1>{props.title}</h1>
              <p>{props.subTitle}</p>
              <div class={style.overlayContentBottom}>
                <SvgIcon
                  class={style.overlayContentBottomIcon}
                  width="148px"
                  height="148px"
                  name={icon.value}
                />
                {props.confirmText && (
                  <Button
                    type="primary"
                    onClick={() => {
                      data.value = false;
                      props.confirmCallback();
                    }}
                  >
                    {props.confirmText}
                  </Button>
                )}
                {props.cancelText && (
                  <Button
                    onClick={() => {
                      data.value = false;
                      props.cancelCallback();
                    }}
                  >
                    {props.cancelText}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Overlay>
      </div>
    );
  },
});
