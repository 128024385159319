import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { useAAuth, useLocalStorage, useToast } from "core";
import { makeRandomId, getUUID, isMobile, PROJECT_VERSION, STORE_USER_INFO } from "../common";
import { useEvent } from "../config";
import { useI18n, getSystemLang } from "../i18n";

interface Sign {
  [key: string]: string | number;
}
const { localStore } = useLocalStorage();
const { $t } = useI18n();
const { getToken, setToken } = useAAuth();
const { tokenEvent } = useEvent();
const { toast } = useToast();
const excludePath = ["/api/agent/auth/logout"];
const requestCacheMap = new Map();
const ERROR_CODE = [
  "ERR_BAD_OPTION_VALUE",
  "ERR_BAD_OPTION",
  "ECONNABORTED",
  "ETIMEDOUT",
  "ERR_NETWORK",
  "ERR_FR_TOO_MANY_REDIRECTS",
  "ERR_DEPRECATED",
  "ERR_BAD_RESPONSE",
  "ERR_BAD_REQUEST",
  // "ERR_CANCELED",
  "ERR_NOT_SUPPORT",
  "ERR_INVALID_URL",
];

/**
 * 代理请求工具函数
 */
export function requesterAgent() {
  const request = axios.create({
    baseURL: window.CONFIG?.api || process.env.CMS_AGENT_API,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    timeout: 10 * 1000,
  });
  request.interceptors.request.use(
    async (config: any) => {
      // 上传文件接口单独处理
      if (config.url === "/api/file/upload") {
        config.headers["content-type"] = "multipart/form-data";
        config.headers = Object.assign(config.headers, await creatHeaders());
        return config;
      }
      // const requestParams = `${config.url}${JSON.stringify(config.params)}`;
      // if (requestMap.includes(requestParams)) return;
      // requestMap.push(requestParams);
      const controller = new AbortController();
      config.signal = controller.signal;
      const requestParams = `${config.url}${config.params ? JSON.stringify(config.params) : ""}`;
      if (requestCacheMap.has(requestParams)) {
        console.log("cancel reqeust", requestParams);
        controller.abort();
      } else {
        requestCacheMap.set(requestParams, controller);
      }

      if (config?.method && ["post", "put"].includes(config?.method)) {
        config.data = qs.stringify(config.data, {
          arrayFormat: "indices",
          allowDots: true,
        });
      }
      config.headers = Object.assign(config.headers, await creatHeaders());
      return config;
    },
    () => {
      return Promise.reject({ code: 1, status: 200, data: {}, result: false });
    }
  );
  request.interceptors.response.use(
    response => {
      clearRequestCache(response);
      // const requestParams = `${response.config.url}${JSON.stringify(response.config.params)}`;
      // requestMap.splice(requestMap.indexOf(requestParams), 1);
      const { data, status } = response;
      const { code } = data || {};
      if ([401, 403, 405].includes(code)) {
        localStore.remove(STORE_USER_INFO);
        setToken("");
        tokenEvent.emit(true);
        //toast.error(data.message);
        return Promise.resolve(Object.assign(data, { result: false }));
      }
      if (typeof data === "string" && data.length > 10 && status === 200) {
        return Promise.resolve(Object.assign({ data }, { result: true, status }));
      } else if (code !== 0) {
        toast.error(data.message);
      }
      return Promise.resolve(Object.assign(data, { result: code === 0, status }));
    },
    error => {
      console.log("APi request fail", error);
      clearRequestCache(error);
      const { response, message } = error;
      const { status, data = {} } = response || {};
      if (message.includes("timeout")) {
        toast.error($t("common_request_timed_out" /* 请求超时，请稍后再试! */));
        return Promise.resolve(
          Object.assign(data ? data : {}, {
            result: false,
            message: $t("common_request_timed_out" /* 请求超时，请稍后再试! */),
          })
        );
      }

      if ([401, 403, 405].includes(status) && !excludePath.includes(response.config.url || "")) {
        localStore.remove(STORE_USER_INFO);
        setToken("");
        console.log("request.interceptors.tokenEvent");
        tokenEvent.emit(true);
        return Promise.resolve(Object.assign(data, { result: false }));
      }
      if (status >= 500 || status === 400) {
        toast.error(data.message);
        return Promise.resolve({ ...data, result: false });
      }
    }
  );
  return request;
}

async function creatHeaders() {
  const timestamp = Date.now();
  const sign = makeRandomId(-16);
  const client_type = isMobile ? "h5" : "web";
  const version = PROJECT_VERSION;
  const nonce = makeRandomId(-8);
  const device_id = getUUID();
  const token = await getToken();
  const langue = getSystemLang();
  const signParams: Sign = {
    timestamp,
    sign,
    version,
    client_type,
    nonce,
    device_id,
    langue,
  };

  if (token) {
    signParams.Authorization = `Bearer ${token}`;
  }
  return signParams;
}

function clearRequestCache(response: AxiosResponse | any) {
  let requestParams = "";

  if (response && response.config) {
    requestParams = `${response.config.url}${
      response.config.params ? JSON.stringify(response.config.params) : ""
    }`;
    requestCacheMap.delete(requestParams);
    return;
  }

  // TODO 处理config为空的清空，默认直接清除全部, 待观察
  // 响应code:ERR_CANCELED 类型是abort的情况，不用处理
  if (ERROR_CODE.includes(response.code)) {
    requestCacheMap.clear();
  }
}
