import { defineComponent, onMounted, reactive, computed, watch } from "vue";
import wallet from "./wallet.module.less";
import { useI18n, useWallet, useConfigContext, useWithdraw, useRecharge, useCocos } from "core";
import { SvgIcon } from "commom";
import { mangoPopup, MangoTipsModal } from "components";
import { useDebounceFn } from "@vueuse/core";
import CSwitch from "./components/Switch/index";
import addImg from "./asstes/add.png";
export default defineComponent({
  name: "MangoTest",
  setup() {
    const { $t } = useI18n();
    const {
      AllBalance,
      getUserWalletList,
      userWalletList,
      reclaimloading,
      amountFormat,
      addPlus,
      acWallet,
      balanceSwitch,
      platformBalance,
      currencyList,
    } = useWallet();
    const { getGameParams } = useCocos();
    const { getCurrencyList } = useConfigContext();
    const data = reactive({
      currency: "",
      tipsModalShow: false,
      showMoney: true,
      showCurrency: false,
    });
    const { store: rechargeStore } = useRecharge();
    const { tipModalData, verificationBeforeWithdraw } = useWithdraw();
    const eye = computed(() => (data.showMoney ? "eyeShow" : "eyeHide"));
    const updateBalance = useDebounceFn(platformBalance, 1000, { maxWait: 1000 });
    const toWithdrawal = async () => {
      await verificationBeforeWithdraw();
    };
    onMounted(async () => {
      await getUserWalletList();
      await updateBalance();
    });
    return () => (
      <div class={wallet.wallet}>
        <div class={wallet.walletTab}>
          <div class={wallet.walletTabTop}></div>
          <div class={wallet.walletTabBottom}>
            <div class={wallet.walletTabBottomWrap}>
              <div
                class={wallet.walletTabBottomWrapItem}
                onClick={() =>
                  mangoPopup({
                    type: "MangoRechargeWithdrawModal",
                    closeCallBack: () => (rechargeStore.visible = false),
                    props: { tab: "charge" },
                  }).open()
                }
              >
                <SvgIcon name="recharge" color={"var(--cms-mango-primary-color)"} />
                <p>{$t("funds_wallet_recharge" /* 充值 */)}</p>
              </div>
              <div
                class={wallet.walletTabBottomWrapItem}
                onClick={() =>
                  mangoPopup({
                    type: "MangoRechargeWithdrawModal",
                    closeCallBack: () => (rechargeStore.visible = false),
                    props: { tab: "drawl" },
                  }).open()
                }
              >
                <SvgIcon name="withdraw" color={"var(--cms-mango-primary-color)"} />
                <p>{$t("funds_wallet_withdraw" /* 提现 */)}</p>
              </div>
              <div
                class={wallet.walletTabBottomWrapItem}
                onClick={() => {
                  mangoPopup({
                    type: "MangoBankCardManage",
                    title: $t("funds_wallet_cardManager" /* 卡片管理 */),
                    borderRadius: "12",
                    props: { type: "B" },
                  }).open();
                }}
              >
                <SvgIcon name="cardManagement" color={"var(--cms-mango-primary-color)"} />
                <p>{$t("funds_wallet_cardManager" /* 卡片管理 */)}</p>
              </div>
            </div>
          </div>
          <div class={wallet.walletTabCard}>
            <div class={wallet.walletTabCardInfo}>
              <div class={wallet.walletTabCardInfoFlag}>
                <img src={acWallet.value?.ico} />
                {acWallet.value?.name}
              </div>
              <div class={wallet.walletTabCardInfoNumber}>
                <span>{acWallet.value?.currency}</span>
                {data.showMoney ? AllBalance?.value : "******"}
              </div>
            </div>
            <div class={wallet.walletTabCardBtn}>
              <div class={wallet.walletTabCardBtnLeft}>
                {$t("funds_wallet_currentWallet" /* 当前主钱包 */)}
                <span>·{$t("common_active" /* 活跃 */)}</span>
              </div>
              <div class={wallet.walletTabCardBtnRight}>
                <span onClick={() => updateBalance()}>
                  <SvgIcon
                    class={reclaimloading.value && wallet.walletTabCardBtnRightLoading}
                    width="24px"
                    height="24px"
                    name="refresh"
                    color={"var(--cms-mango-primary-color)"}
                  />
                </span>
                <span onClick={() => (data.showMoney = !data.showMoney)}>
                  <SvgIcon
                    width="24px"
                    height="24px"
                    name={eye.value}
                    color={"var(--cms-mango-primary-color)"}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class={wallet.walletTitle}>
          <span>{$t("funds_wallet_myCurrency" /* 我的货币 */)}</span>
        </div>
        <div class={wallet.walletCon}>
          {userWalletList.value?.map((item: any, i: number) => (
            <div key={i} class={wallet.walletList}>
              <div class={wallet.walletListItem}>
                <div class={wallet.walletListItemInfo}>
                  <img src={item.ico} alt="" />
                  <div class={wallet.walletListItemInfoText}>
                    <div>{item.currency}</div>
                    <div>
                      {amountFormat(
                        addPlus(
                          item?.balance?.balance ?? 0,
                          item?.balance?.freeze ?? 0,
                          item?.balance?.gameTotal ?? 0
                        )
                      )}
                    </div>
                    {item.status}
                  </div>
                </div>
                <CSwitch
                  v-model:open={item.status}
                  isChange={false}
                  onChange={() => {
                    data.currency = item?.currency;
                    tipModalData.show = true;
                    tipModalData.title = $t("user_contactUs_content" /*温馨提示*/);
                    tipModalData.subTitle = $t(
                      "mine_wallet_changeWallet",
                      [data.currency as string] /*设主钱包为[0]*/
                    );
                    tipModalData.confirmText = $t("common_confirm" /*确定*/);
                    tipModalData.cancelText = $t("common_cancel" /*取消*/);
                    tipModalData.confirmCallback = () => {
                      balanceSwitch(data.currency, true, false, () => {
                        getGameParams();
                      });
                    };
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        {/** 如果币种选择列表数据大于0并且租户开放的币种大于1 显示新增币种按钮 */}
        {currencyList.value?.length > 0 && getCurrencyList.value?.length > 1 && (
          <div class={wallet.walletAdd} onClick={() => (data.showCurrency = true)}>
            {$t("funds_wallet_addCurrency" /* 新增币种 */)}
          </div>
        )}
        <MangoTipsModal type="info" {...tipModalData} v-model:show={tipModalData.show} />
        <div class={[wallet.walletSelect, data.showCurrency && wallet.walletSelectShow]}>
          <div class={wallet.walletSelectHeader}>
            <div
              class={wallet.walletSelectHeaderCancel}
              onClick={() => (data.showCurrency = false)}
            >
              {$t("common_cancel" /*取消*/)}
            </div>
            <div class={wallet.walletSelectHeaderTitle}>
              {$t("funds_wallet_addCurrency" /*新增币种*/)}
            </div>
          </div>
          <div class={wallet.walletSelectCon}>
            {currencyList.value?.map(item => (
              <div
                class={wallet.walletSelectConItem}
                onClick={async () => {
                  data.showCurrency = false;
                  await balanceSwitch(item.code, false, false);
                }}
              >
                <img src={item.ico} class={wallet.walletSelectConItemImg1} />
                <div class={wallet.walletSelectConItemInfo}>
                  <div>{item.code}</div>
                  <div>{item.name}</div>
                </div>
                <img class={wallet.walletSelectConItemImg2} src={addImg} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
});
