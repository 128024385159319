import { computed, reactive, ref, ComputedRef } from "vue";
import { Api, toTime, useConfig, useI18n, useUser, useAuth, usePointStatistics } from "core";
import { useToast } from "./useToast.hook";
import {
  TranType,
  ReportItem,
  TranRecords,
  TranItem,
  RechargerItem,
  WithdrawItem,
  WithdrawData,
  RechargerData,
  RecordItem,
  QuotaItem,
} from "@kgsport-cms/service";
import { useRoute } from "vue-router";
const { toast } = useToast();
import dayjs from "dayjs";

export enum FundsDataLoadEnum {
  // 替换增加
  REPLACE_LOAD = "replace_load",
  // 分页加载
  PAGE_LOAD = "page_load",
}
interface options {
  value: string;
  label: string;
}

interface BetDetail {
  betAmount: number;
  betCount: number;
  validAmount: number;
  winAmount: number;
}
interface TransStore {
  data: TranRecords | null;
  billList: TranItem[]; //账变记录
  betList: ReportItem[]; //投注记录
  typeList: TranType[]; //账变类型
  date: options[]; // 日期选项
  recordType: string; // 交易类型
  // gameList: KindItem[]; //游戏列表
  transList: QuotaItem[]; // 转账记录
  rechargerItemList: RechargerItem[]; // 充值列表
  withdrawItemList: WithdrawItem[]; // 提现列表
  withdrawList: WithdrawData | null;
  rechargerList: RechargerData | null;
  betDetail: BetDetail | null;
  day: string;
  // 开始时间
  beginTime: number;
  // 结束时间
  endTime: number;
  loading: boolean;
  // 分页信息
  pagination: {
    total: number;
    current: number;
    pageSize: number;
    pages: number;
  };
  totalIncome: number | null; // 总收入
  totalOutlay: number | null; // 总支出
  currency: string; // 货币
  status: number; // 状态
  fundsType: number; // 资金类型 账变记录(0),充值订单(1),提现订单(2),额度转换(3)
}
// const { $t } = useI18n();
const store = reactive<TransStore>({
  data: null,
  billList: [],
  betList: [],
  typeList: [],
  transList: [],
  rechargerItemList: [],
  withdrawItemList: [],
  betDetail: null,
  withdrawList: null,
  rechargerList: null,
  date: [
    { value: "0", label: "mine_changeRecord_today" }, // 今天
    { value: "1", label: "mine_changeRecord_yesterday" }, // 昨天
    { value: "7", label: "common_last7days" }, // 七日内
    { value: "30", label: "common_last30days" }, // 三十日内
  ],
  recordType: "0",
  day: "0",
  beginTime: dayjs().startOf("D").valueOf(), // 今天00:00:00
  endTime: dayjs().endOf("D").valueOf(), // 今天23:59:59
  loading: false,
  pagination: {
    total: 0,
    current: 1,
    pageSize: 20,
    pages: 0,
  },
  totalIncome: 0,
  totalOutlay: 0,
  currency: "",
  status: 0,
  fundsType: 0,
});

export const dateOptions = [
  {
    value: "0",
    label: "mine_changeRecord_today",
    startTime: dayjs().startOf("D").valueOf(),
    endTime: dayjs().endOf("D").valueOf(),
  },
  {
    value: "1",
    label: "mine_changeRecord_yesterday",
    startTime: dayjs().subtract(1, "day").startOf("D").valueOf(),
    endTime: dayjs().subtract(1, "day").endOf("D").valueOf(),
  },
  {
    value: "6",
    label: "mine_changeRecord_Nearly7days",
    startTime: dayjs().subtract(7, "day").endOf("D").valueOf(),
    endTime: dayjs().valueOf(),
  },
];

export enum FundsTypeEnum {
  // 帐变记录
  ACCOUNT_CHANGE_RECORD,
  // 充值订单
  RECHARGE_ORDER,
  // 提现订单
  WITHDRAW_ORDER,
  // 额度转换
  QUOTA_CONVERSION,
}

// 额度转换状态选项  * 传给后台的值对应索引  ["全部", "转入失败", "转出失败", "受理成功", "已取消"];
const transStatus: string[] = [
  "funds_wallet_currency_all",
  "mine_record_inFailure",
  "mine_record_outFailure",
  "mine_record_acceptSucceed",
  "mine_bet_cancel",
];

// 提现记录状态选项  * 传给后台的值对应索引  ["全部", "未受理", "受理中", "已出款", "拒绝出款", "撤销出款订单"];
const withdrawStatus: string[] = [
  "agent_AccountMA_all",
  "agent_common_notAccepted",
  "agent_common_accepting",
  "agent_withdrOrder_paidOut",
  "agent_withdrOrder_refusalWithdraw",
  "funds_withdraw_cancelWithdrawOrder",
];

// 充值记录状态选项  * 传给后台的值对应索引  ["全部", "未受理", "受理中", "已入款", "已取消"];
const rechargeStatus: string[] = [
  "funds_wallet_currency_all",
  "funds_not_accepted",
  "funds_withdraw_shoulizhong",
  "funds_paid_in",
  "mine_bet_cancel",
];
// ["已取消","未结算","已结算"]
const betStatus: { [key: string]: string } = {
  "-1": "mine_bet_cancel",
  "0": "mine_bet_noSettlemen",
  "1": "mine_bet_haveAlreadySettled",
};
// ["转账汇款","在线支付","后台入款"]
const rechargemode: { [key: string]: string } = {
  1: "funds_recharge_transferMoney",
  2: "funds_recharge_online_pay",
  3: "funds_recharge_backgroundInto",
};

// 资金类型 [账变记录(0),充值订单(1),提现订单(2),额度转换(3)]
const fundsTypeList = [
  {
    label: "mine_changeRecord_ZhangChangeRecord", // 账变记录
    value: 0,
  },
  {
    label: "mine_changeRecord_rechargeOrder", // 充值订单
    value: 1,
  },
  {
    label: "mine_changeRecord_withdrawOrder", // 提现订单
    value: 2,
  },
  {
    label: "funds_withdraw_eduzhuanhuan", // 额度转换
    value: 3,
  },
];

const selectOptionsAll: TranType[] = [
  { dictValue: "0", dictName: "funds_wallet_currency_all", dictLabel: "全部" },
];

export function userRecord() {
  const { isPc } = useConfig();
  const { $t } = useI18n();
  const { user } = useUser();
  const { isLogin } = useAuth();
  const { firstRechargeSuccessStatistice, repeatedlyRechargeSuccessStatistice } =
    usePointStatistics();
  const fundLoadMode = ref(
    isPc.value ? FundsDataLoadEnum.REPLACE_LOAD : FundsDataLoadEnum.PAGE_LOAD
  );
  const betList = computed<ReportItem[]>(() => store.betList);
  const betDetail = computed<BetDetail | null>(() => store.betDetail);
  const billList = computed<TranItem[]>(() => store.billList);
  const transList = computed<QuotaItem[]>(() => store.transList);
  const rechargerItemList = computed<RechargerItem[]>(() => store.rechargerItemList);
  const withdrawItemList = computed<WithdrawItem[]>(() => store.withdrawItemList);
  const typeList = computed<TranType[]>(() => store.typeList);
  const date = computed<options[]>(() => store.date);
  const withdrawList = computed<WithdrawData | null>(() => store.withdrawList);
  const rechargerList = computed<RechargerData | null>(() => store.rechargerList);
  // 最新充值数据
  const newRechargerInfo = computed<RechargerItem>(() => store.rechargerItemList?.[0] ?? {});

  // 资金类型过滤项
  const fundsFilterValue = computed(() =>
    store.fundsType === FundsTypeEnum.ACCOUNT_CHANGE_RECORD ? store.recordType : store.status
  );
  // 资金类型 [账变记录(0),充值订单(1),提现订单(2),额度转换(3)]
  const fundsType = computed<number>(() => store.fundsType);
  // 资金类型激活文字
  const fundsActiveText = computed<string>(
    () => fundsTypeList.find(item => item.value === store.fundsType)?.label || ""
  );
  // 过滤项激活文字
  const fundsActiveFilterText = computed<string>(() =>
    recordStatusTypeText(fundsFilterValue.value)
  );
  // 当前列表总条数
  const fundsListSize = computed(() => getFundsResponseList().length);

  // 重置状态
  const initStatus = () => {
    store.recordType = "0";
    store.status = 0;
  };

  // 更新过滤值
  const updateStatus = (value: string | number) => {
    store.fundsType === FundsTypeEnum.ACCOUNT_CHANGE_RECORD
      ? (store.recordType = String(value))
      : (store.status = Number(value));
  };

  // 切换日期参数
  const dayChange = (day: string) => {
    store.day = day;
    store.pagination.pageSize = 20;
    store.pagination.current = 1;
  };

  store.currency = user.value?.currency || "";

  // 获取账变记录
  const getBillFlow = async () => {
    const params = {
      dateType: store.day,
      tranType: store.recordType,
      size: store.pagination.pageSize,
      current: store.pagination.current,
      currency: store.currency || user.value?.currency,
    };
    store.loading = true;
    const { data, result } = await Api.TransService.billFlow(params);
    store.loading = false;
    if (!result) {
      return;
    }
    store.data = data;
    if (fundLoadMode.value === FundsDataLoadEnum.REPLACE_LOAD) {
      store.billList = data.records;
    } else {
      store.billList =
        store.pagination.current === 1 ? data.records : store.billList.concat(data.records || []);
    }
    // console.warn(store.billList, "获取账变记录");
    store.pagination.total = data.total;
    store.pagination.pages = data.pages;
    store.totalIncome = data.sum.totalIncome || 0;
    store.totalOutlay = data.sum.totalOutlay || 0;
  };
  // 获取账变类型，交易记录=>下拉菜单筛选
  const getTransType = async () => {
    const { data, result } = await Api.TransService.tranTypes();
    if (!result) {
      store.typeList = selectOptionsAll;
      return;
    }
    store.typeList = selectOptionsAll.concat(data);
  };

  // 根据tranType值返回对应tranType文案
  const getTransTypeText = (tranType: number) => {
    if (store.typeList.length === 0) return "--";
    return recordStatusTypeText(tranType);
  };
  // 根据资金类型返回对应状态文案
  const getStatus = (status: string | number) => recordStatusTypeText(status);

  // 获取一级游戏投注记录
  const betReport = async (beginTime: number, endTime: number) => {
    const time = {
      beginTime,
      endTime,
    };
    store.loading = true;
    const { data, result } = await Api.RecordService.betReport(time);
    store.loading = false;
    if (!result) {
      return;
    }
    const { betAmount, betCount, validAmount, winAmount, records } = data;
    store.betList = records;
    store.betDetail = {
      betAmount,
      betCount,
      validAmount,
      winAmount,
    };
  };

  // 获取取款记录
  const getwithdrawList = async () => {
    const params = {
      dateType: store.day,
      currency: store.currency || user.value?.currency,
      size: store.pagination.pageSize,
      current: store.pagination.current,
      status: store.status,
    };
    store.loading = true;
    const { data, result, message } = await Api.TransService.withdrawList(params);
    store.loading = false;
    if (result) {
      store.withdrawList = data;
      if (fundLoadMode.value === FundsDataLoadEnum.REPLACE_LOAD) {
        store.withdrawItemList = data.records;
      } else {
        store.withdrawItemList =
          store.pagination.current === 1
            ? data.records
            : store.withdrawItemList.concat(data.records || []);
      }
      store.pagination.total = data.total;
      store.pagination.pages = data.pages;
    }
  };
  // 获取取款记录
  const getWithdrawType = (type: string) => {
    // 提现方式（B 银行卡 、 D(免提直冲） 、 其他（虚拟币））
    switch (type) {
      case "B":
        return "funds_withdraw_bankCard";
      case "D":
        return "funds_recharge_directCharging";
      default:
        return "common_virtualCurrency";
    }
  };

  // 获取存款记录
  const getrechargerList = async () => {
    const params = {
      dateType: store.day,
      currency: store.currency || user.value?.currency,
      size: store.pagination.pageSize,
      current: store.pagination.current,
      status: store.status,
    };
    store.loading = true;
    const { data, result } = (await Api.TransService.rechargerList(params)) || {};
    store.loading = false;
    if (result) {
      store.rechargerList = data;
      if (fundLoadMode.value === FundsDataLoadEnum.REPLACE_LOAD) {
        store.rechargerItemList = data.records;
      } else {
        store.rechargerItemList =
          store.pagination.current === 1
            ? data.records
            : store.rechargerItemList.concat(data.records || []);
      }
      store.pagination.total = data.total;
      store.pagination.pages = data.pages;
    }
  };
  // 获取最近充值订单状态
  const getOrderStatus = async () => {
    const params = {
      dateType: "7",
      currency: user.value?.currency,
      size: 20,
      current: 1,
      status: 0,
      orderNo: "",
    };
    let orderNoStr = localStorage.getItem("ORDER_LIST") || "";
    if (!orderNoStr || !isLogin.value || orderNoStr === "null") {
      return false;
    }
    let orderNoArr = JSON.parse(orderNoStr);
    params.orderNo = orderNoArr.toString();
    const { data, result } = (await Api.TransService.rechargerList(params)) || {};
    if (!result) return;
    // "未受理" 1, "受理中" 2, "已入款"3, "已取消"4
    data.records.forEach(item => {
      if (Number(item.status) === 3) {
        if (item.firstFlag) {
          console.log(item.orderNo + "埋点统计成功++++++++++++++++++++++++");
          firstRechargeSuccessStatistice();
        } else {
          console.log(item.orderNo + "复冲埋点统计成功++++++++++++++++++++++++");
          repeatedlyRechargeSuccessStatistice();
        }
        orderNoArr.splice(orderNoArr.indexOf(item.orderNo), 1);
      }
      if (Number(item.status) === 4) {
        orderNoArr.splice(orderNoArr.indexOf(item.orderNo), 1);
      }
    });
    if (orderNoArr.length > 0) {
      localStorage.setItem("ORDER_LIST", JSON.stringify(orderNoArr));
    } else {
      localStorage.setItem("ORDER_LIST", "null");
    }
  };
  // 获取存款记录类型
  const getRechargeType = (mode: number) => {
    // 充值方式: [1 - 转账汇款, 2 - 在线支付, 3 - 后台入款]
    switch (mode) {
      case 1:
        return "funds_recharge_transferMoney";
      case 2:
        return "funds_recharge_online_pay";
      case 3:
        return "funds_recharge_backgroundInto";
      default:
        return "--";
    }
  };

  // 获取额度转化记录
  const getquotaList = async () => {
    const params = {
      dateType: store.day,
      currency: store.currency || user.value?.currency,
      size: store.pagination.pageSize,
      current: store.pagination.current,
      status: store.status,
    };
    store.loading = true;
    const { data, result } = await Api.TransService.quotaList(params);
    store.loading = false;
    if (result) {
      // store.transList = data.records;
      // console.warn(store.transList, "获取额度转化记录");
      if (fundLoadMode.value === FundsDataLoadEnum.REPLACE_LOAD) {
        store.transList = data.records;
      } else {
        store.transList =
          store.pagination.current === 1
            ? data.records
            : store.transList.concat(data.records || []);
      }
      store.pagination.total = data.total;
      store.pagination.pages = data.pages;
    }
  };
  // 获取额度转化记录类型
  const getTransferType = (type: number) => {
    // 转账类型：1转入，2转出
    switch (type) {
      case 1:
        return "common_transferIn";
      case 2:
        return "common_transferOut";
      default:
        return "--";
    }
  };

  // 获取资金类型对应筛选项
  // [账变记录(0),充值订单(1),提现订单(2),额度转换(3)]
  const getFundsFilterList = () => {
    const filterTypeList = [typeList.value, rechargeStatus, withdrawStatus, transStatus];
    return filterTypeList[fundsType.value] || [];
  };

  // 获取资金类型对应请求api
  const getFundsRequestApi = (dataLoadMode?: FundsDataLoadEnum) => {
    const apiList = [getBillFlow, getrechargerList, getwithdrawList, getquotaList];
    if (dataLoadMode) {
      fundLoadMode.value = dataLoadMode;
    }
    return apiList[fundsType.value];
  };

  // 获取资金类型对应列表数据
  const getFundsResponseList = () => {
    const responseList = [
      billList.value,
      rechargerItemList.value,
      withdrawItemList.value,
      transList.value,
    ];
    return responseList[fundsType.value];
  };

  // 获取类型文字
  const recordStatusTypeText = (status: number | string) => {
    const currentFilterList = getFundsFilterList();
    if (store.fundsType === FundsTypeEnum.ACCOUNT_CHANGE_RECORD) {
      return (
        (currentFilterList as TranType[]).find(
          (item: TranType) => item.dictValue === String(status)
        )?.dictName || ""
      );
    }
    return currentFilterList[status] || "";
  };

  // 获取全部交易类型对应文字，列表展示用
  const getListTransactionTypeText = (value: any) => {
    const textListCallback = [
      () => $t(getTransTypeText(value?.tranType)),
      () => $t(getRechargeType(value?.mode)),
      () => $t(getWithdrawType(value?.withdrawType)),
      () => `${value?.platformName}${$t(getTransferType(value?.transferType))}`,
    ];
    return textListCallback[fundsType.value] && textListCallback[fundsType.value]();
  };

  return {
    store,
    withdrawStatus,
    betList,
    date,
    billList,
    transList,
    typeList,
    withdrawList,
    withdrawItemList,
    rechargerList,
    rechargerItemList,
    rechargeStatus,
    transStatus,
    betStatus,
    rechargemode,
    betDetail,
    fundsType,
    fundsTypeList,
    fundsActiveText,
    fundsActiveFilterText,
    fundsListSize,
    fundsFilterValue,
    newRechargerInfo,
    initStatus,
    updateStatus,
    getBillFlow,
    betReport,
    getwithdrawList,
    getrechargerList,
    getTransType,
    getquotaList,
    dayChange,
    getOrderStatus,
    getTransTypeText,
    getStatus,
    getRechargeType,
    getWithdrawType,
    getTransferType,
    getFundsFilterList,
    getFundsRequestApi,
    getFundsResponseList,
    getListTransactionTypeText,
  };
}

/**
 * Bet detail for H5 or Pc
 */

// PC表配置
export const PC_TABLE_COLUMNS: any = [
  {
    title: "注单号",
    dataIndex: "billNo",
    key: "billNo",
    align: "center",
    width: 150,
  },
  {
    title: "游戏名称",
    dataIndex: "gameName",
    key: "gameName",
    align: "center",
    width: 120,
  },
  {
    title: "投注额",
    dataIndex: "betAmount",
    key: "betAmount",
    align: "center",
    width: 120,
  },
  {
    title: "有效投注额",
    dataIndex: "validAmount",
    key: "validAmount",
    align: "center",
  },
  {
    title: "输赢",
    dataIndex: "winAmount",
    key: "winAmount",
    align: "center",
    width: 120,
  },
  {
    title: "注单状态",
    dataIndex: "settle",
    customRender: ({ record }: any) => betStatus[record.settle],
    align: "center",
    width: 90,
  },
  {
    title: "投注时间",
    dataIndex: "",
    customRender: ({ record }: any) => toTime(record.betTime),
    align: "center",
    width: 200,
  },
];

export function useBetDetail() {
  const route = useRoute();
  const { isPc } = useConfig();
  // 数据
  const state = reactive({
    data: [] as RecordItem[],
    pagination: {
      total: 0,
      current: 1,
      pageSize: 20,
      pages: 0,
    },
    loading: false,
  });
  // 链接参数
  const gameKindRef: ComputedRef<any> = computed(() => {
    return (
      route.query || {
        gameKind: "",
        gameName: "",
      }
    );
  });

  // 获取投注详情
  const betRecordDetail = async () => {
    const day = route.query.day as string;
    const param: any = dateOptions.find(item => item.value === day);
    const params = {
      current: state.pagination.current,
      size: state.pagination.pageSize,
      gameKind: gameKindRef.value?.gameKind || "",
      status: "",
      beginTime: param.startTime,
      endTime: param.endTime,
    };
    state.loading = true;
    const { data, result } = await Api.RecordService.betRecord(params);
    state.loading = false;
    if (!result) {
      // toast.error(message);
    } else {
      const records = data.page.records;
      console.log(isPc.value);
      if (isPc.value) {
        state.data = records;
      } else {
        state.data = state.pagination.current === 1 ? records : state.data.concat(records || []);
      }
      state.pagination.total = data.page.total;
      state.pagination.pages = data.page.pages;
    }
  };
  return { state, gameKindRef, betRecordDetail };
}

// 获取当天前十条所有类别的投注详情
export function useBetTopTen() {
  const state = reactive({
    list: [] as RecordItem[],
  });
  const getBetDetail = async () => {
    const params = {
      current: 1,
      size: 10,
      beginTime: new Date(new Date().toLocaleDateString()).getTime(), // 今天00:00:00
      endTime: new Date(new Date().toLocaleDateString()).getTime() + (24 * 60 * 60 * 1000 - 1), // 今天23:59:59
    };
    const { data, result } = await Api.RecordService.betRecord(params);
    if (result) {
      state.list = data.page.records;
    }
  };
  return { state, getBetDetail };
}
