import { computed, reactive, ref, watchEffect, nextTick } from "vue";
import { Form } from "ant-design-vue";
import { useRouter, useRoute } from "vue-router";
import { UserInfoSchema, ProfileOptions } from "@kgsport-cms/service";
import { useDebounceFn } from "@vueuse/core";
import {
  Api,
  useToast,
  isAdult,
  useAuth,
  jsencrypt,
  passwordRule,
  useCountDown,
  timesDiffToDays,
  useConfigContext,
  timestampToDateTime,
  phoneNumberRule,
  confirmPasswordRule,
  verificationCodeRule,
  verificationCodeRule3,
  confirmNewAndOldPasswordRule,
  requireArrRule,
  nicknameRule,
  zaloRule,
  dutyRule,
  emailRule,
  birthdayRule,
  destroyModalAll,
  useDayjs,
  useLocalStorage,
  useI18n,
  useConfig,
  useCurrencySymbol,
  withdrawPassRule2,
  withdrawPassRuleCustom2,
} from "core";
import { mangoPopup } from "components";

import { STORE_USER_INFO } from "../common";
export interface UserStore {
  user: null | UserInfoSchema;
  profile: null | ProfileOptions;
  mangoHeaderMenuShow: boolean;
  mangoLeftMenuShow: boolean;
}

export interface UserForm {
  account: string;
  birthday: string;
  email: string;
  nickname: string;
  sex: string;
  realName: string;
  avatar: string;
  emailCode: string;
  zalo: string;
  zaloAreaCode: string;
  zaloPhone: string;
  taxNum: string;
  taxType: string;
}

export interface UserPhoneForm {
  phone: string;
  code: string;
}

export interface UserPassForm {
  newPassword: string;
  rawPassword2: string;
  rawPassword: string;
}

export interface smsCodeParams {
  type: number;
  phone: string;
  dialCode: string;
}

export interface UserWithdrawForm {
  withdrawPassword: string;
}

export interface headerItem {
  value: number;
  src: string;
}

const store = reactive<UserStore>({
  user: null,
  profile: null,
  mangoHeaderMenuShow: false,
  mangoLeftMenuShow: false,
});
const useForm = Form.useForm;
// 个人中心修改表单
const form = reactive<UserForm>({
  account: "",
  birthday: "",
  email: "",
  nickname: "",
  sex: "0",
  realName: "",
  avatar: "",
  emailCode: "",
  zalo: "",
  zaloAreaCode: "",
  zaloPhone: "",
  taxNum: "",
  taxType: "",
});
export function useUser() {
  // const noticeModalProvide = inject("noticeModalProvide");
  const { $t } = useI18n();
  const router = useRouter();
  const route = useRoute();
  const { dayjs } = useDayjs();
  const { count, start } = useCountDown();
  const { isLogin, getToken } = useAuth();
  const { config } = useConfigContext();
  const { isPc, tsx } = useConfig();
  const { localStore } = useLocalStorage();
  const { currencySymbols } = useCurrencySymbol();
  const valdateType = ref(true);

  const formRule = reactive({
    birthday: birthdayRule(form),
    nickname: nicknameRule(),
    sex: [],
    avatar: [],
    realName: requireArrRule(),
    email: emailRule(),
    emailCode: requireArrRule(),
    zalo: zaloRule(),
    taxNum: dutyRule(),
  });
  // 修改手机号
  const phoneForm = reactive({
    dialCode: "", //国际区号
    phone: "", // 手机号码
    smsCode: "", // 短信验证码
    captchaKey: "",
    code: "",
  });
  // 手机号/验证码 验证规则
  const phoneFormRules = reactive({
    phone: phoneNumberRule(), // 手机号码
  });
  // 验证码 验证规则
  const smsCodeRules = reactive({
    smsCode: verificationCodeRule(), // 短信验证码
  });
  // 验证码 验证规则
  const smsCodeRounderRules = reactive({
    smsCode: verificationCodeRule3(), // 短信随机验证码
  });
  // 修改密码
  const passForm = reactive({
    rawPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  // 修改密码验证规则
  const passFormRules = reactive({
    rawPassword: passwordRule(), // 旧密码
    newPassword: [...passwordRule(1), ...confirmNewAndOldPasswordRule(passForm, "rawPassword")], // 新密码
    confirmPassword: [...passwordRule(2), ...confirmPasswordRule(passForm, "newPassword")], // 确认新密码
  });

  // 修改提现
  const withdrawForm = reactive({
    withdrawPassword: "",
    confirmWithdrawPassword: "",
  });
  // 修改提现密码验证规则
  const withdrawFormRules = reactive({
    withdrawPassword: withdrawPassRule2(),
    confirmWithdrawPassword: withdrawPassRuleCustom2(withdrawForm, "withdrawPassword"),
  });
  // 二级密码
  const protectWithdrawForm = reactive({
    dialCode: "",
    phone: "",
    smsCode: "",
  });
  // 二级密码验证规则
  const protectWithdrawFormRules = reactive({
    smsCode: verificationCodeRule(),
  });
  // 性比list
  const gender = [
    $t("mine_userinfo_male") /*男*/,
    $t("mine_userinfo_female") /*女*/,
    $t("mine_userinfo_keep_secret") /*保密*/,
  ];

  // 基础资料验证规则暴露
  const userAccount = useForm(form, formRule);
  // 修改手机验证规则暴露
  const phoneAccount = useForm(phoneForm, phoneFormRules);
  // 验证码验证
  const smsCodeAccount = useForm(phoneForm, smsCodeRules);
  // 验证随机验证码
  const smsCodeRonderAccount = useForm(phoneForm, smsCodeRounderRules);
  // 修改账号密码验证规则暴露
  // const passAccount = useForm(passForm, passFormRules);
  const passAccount = useForm(passForm, passFormRules);
  // 提现密码验证规格暴露
  const withdrawAccount = useForm(withdrawForm, withdrawFormRules);
  // 二级密码验证规格暴露
  const protectAccount = useForm(protectWithdrawForm, protectWithdrawFormRules);

  const user = computed<UserInfoSchema | null>(
    () => store.user || JSON.parse(localStore.get(STORE_USER_INFO)) || {}
  );
  const currencySymbol = computed<string>(
    () =>
      currencySymbols?.[
        isLogin.value && user.value?.currency ? user.value.currency : config.defaultCurrency
      ] ?? ""
  );

  const profile = computed<ProfileOptions | null>(() => store.profile);
  //注册时间
  const registerTime = computed(() => timestampToDateTime(user.value?.registerTime || 0));
  // 注册天数
  const registerDate = computed(() =>
    user.value?.registerTime ? timesDiffToDays(user.value?.registerTime) + 1 : 0
  );
  // 是否绑定手机号
  const isPhone = computed<boolean>(() => !!user.value?.phone);
  // 是否绑定邮箱
  const isEmail = computed<boolean>(() => !!user.value?.email);
  //是否绑定Zalo
  const isZalo = computed<boolean>(() => !!user.value?.zalo);
  // 是否开启手机号提现验证
  const isWithdrawPhone = computed<boolean>(() => !!user.value?.email);
  // 是否设置了提现密码
  const isWithdrawPwd = computed<boolean>(() => !!user.value?.hasWithdrawPwd);
  // 是否开启提现短信验证
  const isOpenWithdrawValidate = computed<boolean>(() => user.value?.cashValidate === 1);
  // 用户语言环境
  const userLang = computed(() => user.value?.language);
  // 是否设置性别
  const isSex = computed<boolean>(() => !!user.value?.sex);
  // 当前性别
  const sexText = computed(() => user.value?.sex && $t(gender[+user.value?.sex]));
  const { toast } = useToast();
  const domain = computed(
    () => config.domainList?.jump_h5_domain && config?.domainList.jump_h5_domain.normal[0]
  );
  // 用户头像
  const avatarIconList = computed(() => {
    const icons: string[] = [];
    const imgNameReg = /^headerImg_avatar_[\d]+$/;
    const imgPathReg = /[\d]+(?=.png)/g;
    if (tsx?.common) {
      Object.keys(tsx.common).forEach(
        item => imgNameReg.test(item) && icons.push(tsx?.common[item])
      );
    }
    return icons.sort(
      (a, b) => Number(a.match(imgPathReg)?.[0]) - Number(b.match(imgPathReg)?.[0])
    );
  });
  // mango模板头部菜单显示状态
  const mangoHeaderMenuShow = computed(() => store.mangoHeaderMenuShow);
  const changeMangoHeaderMenuShow = (val = false) => {
    store.mangoHeaderMenuShow = val;
  };
  // mango模板侧边弹窗
  const mangoLeftMenuShow = computed(() => store.mangoLeftMenuShow);
  const changeMangoLeftMenuShow = (val = false) => {
    store.mangoLeftMenuShow = val;
  };
  const reqeustLoading = ref(false);
  // 初始化数据
  const stopWatchEffect = watchEffect(() => {
    if (user.value?.account) {
      nextTick(() => {
        updateData();
        stopWatchEffect();
      });
    }
  });

  const updateData = () => {
    if (user.value) {
      Object.keys(form).forEach((item: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form[item] = (user.value as any)[item];
      });
    }
  };
  // 暴露获取用户详情方法
  const getUserInfo = async (isCache = true) => {
    if ((isCache && store.user) || !isLogin.value) return false;
    const userInfo = JSON.parse(localStore.get(STORE_USER_INFO)) || null;
    if (userInfo) {
      userInfo.taxType = userInfo.taxType || "CPF";
    }
    if (userInfo && isCache) {
      //拉取缓存
      store.user = userInfo;
      return true;
    }
    try {
      const { data, result } = await Api.UserService.userInfo();
      if (!result) {
        // 接口挂的时候 拉取缓存
        store.user = userInfo || { taxType: "CPF" };
        return false;
      }
      if (data) {
        data.taxType = data.taxType || "CPF";
        // 更新缓存
        store.user = data;
        localStore.set(STORE_USER_INFO, JSON.stringify(data));
        return true;
      }
    } catch (e) {
      //网络问题 拉取缓存
      store.user = userInfo || { taxType: "CPF" };
      console.error(e, "user cache storage or null");
      return false;
    }
  };
  // 个人信息 names: 需要验证的项，isBack：成功后是否返回上一页
  const editSubmit = async (names?: string[], isBack = false, callback?: Function) => {
    try {
      reqeustLoading.value = true;
      // if (isBirthday && isAdult(dayjs(form.birthday).valueOf())) {
      //   toast.error($t("mine_userinfo_birthday18" /*生日不能设置小于18周岁！*/));
      //   return;
      // }
      await userAccount.validate(names);
      const { result } = await Api.UserService.editUser(form);
      if (!result) return;
      toast.success($t("message_change_success" /*修改成功*/));
      form.sex = form.sex;
      await getUserInfo(false);
      destroyModalAll();
      if (isBack) router.go(-1);
      callback && callback();
    } catch (e) {
      console.log(e, "e");
    } finally {
      reqeustLoading.value = false;
    }
  };
  // 发送短信验证码
  const getPhoneInfoCode = async (params: smsCodeParams) => {
    try {
      reqeustLoading.value = true;
      const { result } = await Api.UserService.getPhoneInfoCode(params);
      if (!result) {
        return;
      }
      toast.success($t("user_login_sendVerificationCode" /*发送验证码*/));
      start(240);
    } catch (e) {
      console.error(e);
    } finally {
      reqeustLoading.value = false;
    }
  };
  //发送邮箱验证码
  const getEmailCode = async (params: any) => {
    try {
      await userAccount.validate(["email"]);
      reqeustLoading.value = true;
      const { result = false } = (await Api.UserService.getEmailVerifyCode(params)) ?? {};
      reqeustLoading.value = false;
      if (!result) {
        return false;
      }
      toast.success($t("user_login_sendVerificationCode" /*发送验证码*/));
      start(60);
      return true;
    } catch (e) {
      console.error(e);
    } finally {
      reqeustLoading.value = false;
    }
  };

  // 发送短信验证码(已绑定手机号，发送到绑定手机号，无需传递手机号码)
  const getSmsCode = async (params: { type: number }) => {
    try {
      reqeustLoading.value = true;
      const { result } = await Api.UserService.getSmsCode(params);
      if (!result) {
        return;
      }
      start(60);
    } catch (e) {
      console.error(e);
    } finally {
      reqeustLoading.value = false;
    }
  };
  // 个人手机号
  const editPhoneSubmit = async () => {
    try {
      reqeustLoading.value = true;
      await phoneAccount.validate();
      (await valdateType.value) ? smsCodeAccount.validate() : smsCodeRonderAccount.validate();
      const { result } = await Api.UserService.bindPhone(phoneForm);
      if (!result) {
        return false;
      }
      toast.success($t("message_change_success" /*修改成功*/));
      await getUserInfo(false);
      destroyModalAll();
      return true;
    } catch (e) {
      console.log("editPhoneSubmit", e);
    } finally {
      reqeustLoading.value = false;
    }
  };
  // 点击发送验证码先验证手机号不为空 再弹出极验
  const sendCodeSubmit = async (isPwdSwitch = false, type = 5) => {
    phoneAccount.validate();
    if (!phoneForm.phone) {
      toast.error($t("mine_userinfo_phoneEmptyTips" /*手机号码不能为空*/));
      return;
    }
    await openCaptcha(
      {
        type: type,
        phone: phoneForm.phone as string,
        dialCode: phoneForm.dialCode as string,
      },
      isPwdSwitch
    );
  };
  // 个人密码
  const editPassSubmit = async (destroyModalAll?: any) => {
    try {
      reqeustLoading.value = true;
      // await passAccount.validate();
      await passAccount.validate("rawPassword");
      await passAccount.validate("newPassword");
      await passAccount.validate("confirmPassword");
      const info = {
        rawPassword: await jsencrypt(passForm.rawPassword),
        newPassword: await jsencrypt(passForm.newPassword),
        confirmPassword: await jsencrypt(passForm.confirmPassword),
      };
      const { result } = await Api.UserService.updatePassword(info);
      if (!result) return;
      passAccount.resetFields();
      destroyModalAll?.close?.();
      // isPc.value && (noticeModalProvide as any).open?.();
      toast.success($t("message_change_success" /*修改成功*/));
      window.CONFIG?.currentModel === "mango" && mangoPopup().close();
      await getUserInfo(false);
    } catch (e) {
      console.log("editPhoneSubmit", e);
    } finally {
      reqeustLoading.value = false;
    }
  };
  // 个人提现密码
  const editWithdrawSubmit = async () => {
    try {
      reqeustLoading.value = true;
      await withdrawAccount.validate();
      const info = {
        withdrawPwd: withdrawForm.withdrawPassword,
      };
      const { result } = await Api.UserService.updateWithdrawPwd(info);
      if (!result) {
        return;
      }
      toast.success($t("message_change_success" /*修改成功*/));
      window.CONFIG?.currentModel === "mango" ? mangoPopup().close() : router.back();
      await getUserInfo(false);
      destroyModalAll();
    } catch (e) {
      console.log("editPhoneSubmit", e);
    } finally {
      reqeustLoading.value = false;
    }
  };
  // 二级密码
  const editProtectWithdrawSubmit = async () => {
    try {
      reqeustLoading.value = true;
      if (config.withdrowSms) {
        await protectAccount.validate();
      }
      const { result } = await Api.UserService.protectWithdraw(protectWithdrawForm);
      if (!result) {
        return false;
      }
      protectWithdrawForm.smsCode = "";
      await getUserInfo(false);
      destroyModalAll();
      return true;
    } catch (e) {
      console.log("editProtectWithdrawSubmit", e);
    } finally {
      reqeustLoading.value = false;
    }
  };

  const openCaptcha = async (smsParams: smsCodeParams, isPwdSwitch = false) => {
    if (count.value !== 0) return;
    await getPhoneInfoCode(smsParams);
    // // 提现短信验证
    // if (isPwdSwitch && !config.smsSwitch) {
    //
    // }
    // // TODO 验证器开关  目前只支持极验
    // if (!isPwdSwitch && !config.captchaType) {
    //   return await getPhoneInfoCode(smsParams);
    // }
    // 配置了验证器
    // const prams = { name: config.captchaType ?? "geetest", clientType: "web" };
    // const { data, result } = await Api.CommonService.captchaCode(prams);
    // if (result) {
    //   const captcha = new Captcha({
    //     type: config.captchaType ?? ("geetest" as any),
    //     config: {
    //       gt: data.gt,
    //       challenge: data.challenge,
    //     },
    //     onSuccess: async (capPrams: any) => {
    //       const cPams: any = {};
    //       cPams.geetest_seccode = capPrams.geetest_seccode;
    //       cPams.geetest_validate = capPrams.geetest_validate;
    //       cPams.geetest_challenge = capPrams.geetest_challenge;
    //       await getPhoneInfoCode({ ...smsParams, ...cPams });
    //     },
    //   });
    //   await captcha.open();
    // }
  };

  // 提交头像选择，此方法仅参入头像参数
  const avatarAction = async (isBack: boolean) => {
    try {
      reqeustLoading.value = true;
      const { result } = await Api.UserService.editUser({ avatar: form.avatar });
      if (!result) {
        return;
      }
      toast.success($t("message_change_success" /*修改成功*/));
      await getUserInfo(false);
      if (isBack) router.go(-1);
    } finally {
      reqeustLoading.value = false;
    }
  };

  // 修改用户语言
  const updateUserLang = async (language: string) => {
    toast.loading($t("common_lang_updateTips" /*当前用户语言更新中...*/));
    const { result } = await Api.UserService.editUser({ language });
    if (!result) {
      return;
    }
    await getUserInfo(false);
    toast.success($t("message_change_success" /*修改成功*/));
  };

  const editDebounceSubmit = useDebounceFn(editSubmit, 500);
  const avatarSubmit = useDebounceFn(avatarAction, 500);

  // 基础资料性别选择
  const checkSex = (index: string) => {
    form.sex = index;
  };
  const goAgentCenter = () => {
    getToken().then(token => {
      localStorage.access_token = token;
      // 接口域名配置不同地址会出现token失效问题，使用当前主域名
      window.open(location.origin + "/static/agent/?token=" + token);
      // window.open(
      //   domain.value
      //     ? domain.value + "/static/agent/?token=" + token
      //     : location.origin + "/static/agent/"
      // );
    });
  };
  const goH5AgentCenter = () => {
    getToken().then(token => {
      localStorage.access_token = token;
      localStorage.setItem("h5agentbackurl", location.href);
      location.href =
        location.origin + "/static/h5Agent/?token=" + token + "&backurl=" + location.href;
      // location.href = domain.value
      //   ? domain.value + "/static/h5Agent/?token=" + token + "&backurl=" + location.href
      //   : location.origin + "/static/h5Agent/?backurl=" + location.href;
    });
  };
  // 用户头像选择
  const selectHeader = async (item: headerItem) => {
    form.avatar = String(item.value);
  };
  // 生日选择
  const selectBirthday = (birthday: string) => {
    if (isAdult(dayjs(birthday).valueOf())) {
      toast.error($t("mine_userinfo_birthday18" /*生日不能设置小于18周岁！*/));
      return;
    }
    debugger;
    form.birthday = birthday;
  };

  // 确认头像选择
  const submitAvatar = async (isBack = false) => {
    await userAccount.clearValidate();
    await avatarSubmit(isBack);
  };
  // 是否可修改信息
  const isClick = (key: string) => {
    let bl = true; // 性别，昵称，邮箱地址，Zalo，登录密码，提现短信验证设置后可修改
    switch (key) {
      case "realName": // 姓名设置后不可修改
        bl = !user.value?.realName;
        break;
      case "birthday": // 生日设置后不可修改
        bl = !user.value?.birthday;
        break;
      case "withdrawalPassword": // 提现密码设置后不可修改
        bl = !isWithdrawPwd.value;
        break;
      case "secretCellPhone": // 密保手机设置后不可修改
        bl = !isPhone.value;
        break;
      case "email": // 邮箱设置后不可修改
        bl = !isEmail.value;
        break;
    }
    return bl;
  };
  const getProfile = async () => {
    try {
      const { data } = await Api.ConfigService.getProfile();
      store.profile = data || {};
      data.emailOption === 0 && (formRule.emailCode = []);
    } catch (e) {
      console.log("getProfile", e);
    }
  };

  // 设置从代理url过来的逻辑(要进入代理必须登录)
  //web：
  // 登录：http://int130.wk311.com?model=MangoEntry&type=login
  // 注册：http://int130.wk311.com?model=MangoEntry&type=register
  //h5：
  // 登录：http://int130.wk311.com/m?model=MangoEntryMobile&type=login
  // 注册：http://int130.wk311.com/m?model=MangoEntryMobile&type=register
  const setEntryModel = () => {
    const { model, type = "login" } = route.query;
    if (!model) return;
    if (model.includes("Mobile")) {
      mangoPopup({
        type: model as string,
        props: { status: type },
        header: false,
        borderRadius: "12",
      }).open();
    } else {
      mangoPopup({
        type: model as string,
        props: { dialogType: type === "login" ? 1 : 2, header: false },
      }).open();
    }
  };

  return {
    form,
    phoneForm,
    passForm,
    withdrawForm,
    user,
    currencySymbol,
    profile,
    registerTime,
    registerDate,
    userAccount,
    phoneAccount,
    protectAccount,
    smsCodeAccount,
    protectWithdrawForm,
    protectWithdrawFormRules,
    passAccount,
    withdrawAccount,
    count,
    isPhone,
    isEmail,
    isZalo,
    isWithdrawPhone,
    isWithdrawPwd,
    userLang,
    isSex,
    gender,
    sexText,
    reqeustLoading,
    avatarIconList,
    isOpenWithdrawValidate,
    editDebounceSubmit,
    smsCodeRonderAccount,
    valdateType,
    getUserInfo,
    checkSex,
    openCaptcha,
    getPhoneInfoCode,
    getEmailCode,
    getSmsCode,
    editPhoneSubmit,
    editPassSubmit,
    selectHeader,
    selectBirthday,
    editWithdrawSubmit,
    editProtectWithdrawSubmit,
    sendCodeSubmit,
    submitAvatar,
    goAgentCenter,
    goH5AgentCenter,
    updateUserLang,
    isClick,
    getProfile,
    setEntryModel,
    mangoHeaderMenuShow,
    changeMangoHeaderMenuShow,
    mangoLeftMenuShow,
    changeMangoLeftMenuShow,
  };
}
export function useSetUser() {
  return {
    store,
  };
}
