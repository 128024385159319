import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import entry from "../style.module.less";
import { Popup } from "vant";
import { useConfig, useI18n, useUser } from "core";
import { IDialCode } from "@kgsport-cms/service";
import { Form } from "ant-design-vue";
import { SvgIcon } from "commom";
import RadioCircle from "./RadioCircle";

export default defineComponent({
  props: ["validateInfos", "count", "hidden"],
  emits: ["phoneChange", "smsCodeChange", "dialCodeChange", "sendCodeChange"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { areaCodeList, defaultCode, getDialCode } = useConfig();
    // const { phoneForm } = useUser();
    const { phoneForm } = useUser();
    const data = reactive({
      toggleAreaCode: false,
      phone: "",
      smsCode: "",
      dialCode: "",
    });

    // 设置输入框数据
    const setPhoneForm = () => {
      emit("phoneChange", phoneForm.phone);
      emit("dialCodeChange", phoneForm.dialCode);
      phoneForm.smsCode && emit("smsCodeChange", phoneForm.smsCode);
    };

    watch(
      () => defaultCode.value,
      (n, o) => {
        if (!n || n === o) return;
        data.dialCode = n;
        phoneForm.dialCode = n;
        setPhoneForm();
      },
      { immediate: true }
    );
    watch(
      () => [phoneForm.phone, phoneForm.smsCode],
      () => {
        setPhoneForm();
      }
    );

    onMounted(async () => {
      await getDialCode();
      phoneForm.dialCode = defaultCode.value;
    });

    const setAreaCodeItem = (item: IDialCode) => {
      data.toggleAreaCode = false;
      phoneForm.dialCode = item.code;
      data.dialCode = item.code;
      emit("dialCodeChange", phoneForm.dialCode);
    };

    // 搜索
    const areaCodeData = ref<IDialCode[]>();
    watch(
      () => areaCodeList.value,
      n => {
        areaCodeData.value = n;
      },
      { immediate: true, deep: true }
    );
    const searchVal = ref("");
    const setAreaCodeData = (text: string) => {
      areaCodeData.value = text
        ? areaCodeList?.value.filter(d => d.code.includes(text)) ?? []
        : areaCodeList?.value;
    };
    const timer = ref();
    watch(
      () => searchVal.value,
      (n, o) => {
        if (n === o) return;
        if (timer.value) clearTimeout(timer.value);
        timer.value = setTimeout(() => setAreaCodeData(n), 300);
      }
    );
    const clearSearch = () => {
      searchVal.value = "";
    };

    return () => (
      <>
        <Form>
          <div class={entry.loginPhoneInput}>
            <div class={entry.loginPhoneInputSelect} onClick={() => (data.toggleAreaCode = true)}>
              <div class={entry.loginPhoneInputSelectContent}>{data.dialCode}</div>
              <div class={entry.formBoxFormSelectArrow}>
                <SvgIcon width="0.48rem" height="0.48rem" name="arrow" />
              </div>
            </div>
            <div class={[entry.formBoxFormInput, entry.loginPhoneInputBox]}>
              <Form.Item name="phone" {...props.validateInfos.phone}>
                <input
                  placeholder={$t("user_login_pleaseInputPhoneNum" /**请输入手机号 */)}
                  v-model={phoneForm.phone}
                />
              </Form.Item>
            </div>
            {!!phoneForm.phone && (
              <div class={entry.formBoxFormInputPassword} onClick={() => (phoneForm.phone = "")}>
                <SvgIcon
                  class={entry.formBoxFormIcon}
                  width="0.4rem"
                  height="0.4rem"
                  name="close"
                />
              </div>
            )}
          </div>
          {!props.hidden ? (
            <div class={[entry.formBoxFormInput, entry.loginPhoneInputResetPlaceholder]}>
              <Form.Item name="smsCode" {...props.validateInfos.smsCode}>
                <input
                  placeholder={$t("user_login_verificationCode" /**验证码 */)}
                  v-model={phoneForm.smsCode}
                />
              </Form.Item>
              {props.count.value === 0 ? (
                <div
                  class={entry.loginPhoneInputNumberCode}
                  onClick={() => {
                    setPhoneForm();
                    emit("sendCodeChange");
                  }}
                >
                  {$t("user_login_sendVerificationCode" /**发送验证码 */)}
                </div>
              ) : (
                <div class={[entry.loginPhoneInputNumberCode, entry.loginPhoneInputNumberCodeSend]}>
                  {props.count.value}s
                </div>
              )}
            </div>
          ) : null}
        </Form>
        <Popup
          position="bottom"
          teleport="body"
          v-model:show={data.toggleAreaCode}
          round
          class={entry.areaCode}
        >
          <div class={entry.areaCodeHeader}>
            <section class={entry.areaCodeHeaderCode}>
              {$t("user_register_ToastTitle_areaCode" /* 选择区号 */)}
            </section>
            <section class={entry.areaCodeHeaderSearch}>
              <input
                v-model={searchVal.value}
                type="text"
                placeholder={$t("user_register_searchAreaCode" /**搜索区号 */)}
              />
              <i class={entry.areaCodeHeaderSearchClose} onTouchend={clearSearch}>
                <SvgIcon
                  class={entry.areaCodeHeaderSearchCloseIcon}
                  name="closeTick"
                  width="0.3rem"
                  height="0.3rem"
                />
              </i>
            </section>
          </div>
          <div class={entry.areaCodeMain}>
            {areaCodeData.value?.map(option => (
              <div
                class={entry.areaCodeOption}
                key={option.code}
                onClick={() => setAreaCodeItem(option)}
              >
                <section class={entry.areaCodeOptionText}>
                  <span>{option.name}</span>
                  <span>({option.code})</span>
                </section>
                <RadioCircle checked={data.dialCode === option.code} />
              </div>
            ))}
          </div>
        </Popup>
      </>
    );
  },
});
