// 货币符号
export function useCurrencySymbol() {
  const currencySymbols = {
    USD: "$", // 美元
    EUR: "€", // 欧元
    AUD: "$", // 澳大利亚元
    BGN: "лв", // 保加利亚列弗
    BRL: "R$", // 巴西雷亚尔
    CAD: "$", // 加拿大元
    CHF: "CHF", // 瑞士法郎
    CNY: "¥", // 人民币
    CZK: "Kč", // 捷克克朗
    DKK: "kr", // 丹麦克朗
    GBP: "£", // 英镑
    HKD: "$", // 港币
    HRK: "kn", // 克罗地亚库纳
    HUF: "Ft", // 匈牙利福林
    IDR: "Rp", // 印度尼西亚卢比
    ILS: "₪", // 以色列谢克尔
    INR: "₹", // 印度卢比
    JPY: "¥", // 日元
    KRW: "₩", // 韩元
    MXN: "$", // 墨西哥比索
    MYR: "RM", // 马来西亚林吉特
    NOK: "kr", // 挪威克朗
    NZD: "$", // 新西兰元
    PHP: "₱", // 菲律宾比索
    PLN: "zł", // 波兰兹罗提
    RON: "lei", // 罗马尼亚新列伊
    RUB: "₽", // 俄罗斯卢布
    SEK: "kr", // 瑞典克朗
    SGD: "$", // 新加坡元
    THB: "฿", // 泰铢
    TRY: "₺", // 土耳其里拉
    ZAR: "R", // 南非兰特
    AED: "د.إ", // 阿联酋迪拉姆
    AFN: "؋", // 阿富汗尼
    ALL: "Lek", // 阿尔巴尼列克
    AMD: "֏", // 亚美尼亚德拉姆
    ANG: "ƒ", // 荷兰盾
    AOA: "Kz", // 安哥拉宽扎
    ARS: "$", // 阿根廷比索
    AWG: "ƒ", // 阿鲁巴或荷兰盾
    AZN: "₼", // 阿塞拜疆马纳特
    BAM: "KM", // 波斯尼亚可兑换马尔卡
    BBD: "$", // 巴巴多斯元
    BDT: "৳", // 孟加拉国塔卡
    BHD: ".د.ب", // 巴林第纳尔
    BIF: "Fr", // 布隆迪法郎
    BND: "$", // 文莱元
    BOB: "$b", // 玻利维亚诺
    BSD: "$", // 巴哈马元
    BTN: "Nu.", // 不丹努尔特鲁姆
    BWP: "P", // 博茨瓦纳普拉
    BYN: "Br", // 白俄罗斯卢布
    BZD: "BZ$", // 伯利兹元
    CDF: "Fr", // 刚果法郎
    CLP: "$", // 智利比索
    COP: "$", // 哥伦比亚比索
    CRC: "₡", // 哥斯达黎加科朗
    CUP: "₱", // 古巴比索
    CVE: "$", // 佛得角埃斯库多
    DJF: "Fr", // 吉布提法郎
    DOP: "RD$", // 多米尼加比索
    DZD: "د.ج", // 阿尔及利亚第纳尔
    EGP: "£", // 埃及镑
    ERN: "Nfk", // 厄立特里亚纳克法
    ETB: "Br", // 埃塞俄比亚比尔
    FJD: "$", // 斐济元
    FKP: "£", // 福克兰群岛镑
    GEL: "₾", // 格鲁吉亚拉里
    GHS: "₵", // 加纳塞地
    GIP: "£", // 直布罗陀镑
    GMD: "D", // 冈比亚达拉西
    GNF: "Fr", // 几内亚法郎
    GTQ: "Q", // 危地马拉格查尔
    GYD: "$", // 圭亚那元
    HNL: "L", // 洪都拉斯伦皮拉
    HTG: "G", // 海地古德
    IQD: "ع.د", // 伊拉克第纳尔
    IRR: "﷼", // 伊朗里亚尔
    ISK: "kr", // 冰岛克朗
    JMD: "J$", // 牙买加元
    JOD: "د.ا", // 约旦第纳尔
    KES: "KSH", // 肯尼亚先令
    KGS: "лв", // 吉尔吉斯斯坦索姆
    KHR: "៛", // 柬埔寨瑞尔
    KMF: "Fr", // 科摩罗法郎
    KPW: "₩", // 朝鲜元
    KWD: "د.ك", // 科威特第纳尔
    KYD: "$", // 开曼元
    KZT: "лв", // 哈萨克斯坦坚戈
    LAK: "₭", // 老挝基普
    LBP: "£", // 黎巴嫩镑
    LKR: "₨", // 斯里兰卡卢比
    LRD: "$", // 利比里亚元
    LSL: "L", // 巴索托洛蒂
    LYD: "ل.د", // 利比亚第纳尔
    MAD: "د.م.", // 摩洛哥迪拉姆
    MDL: "L", // 摩尔多瓦列伊
    MGA: "Ar", // 马尔加什阿里亚
    MKD: "ден", // 马其顿第纳尔
    MMK: "Ks", // 缅元
    MNT: "₮", // 蒙古图格里克
    MOP: "P", // 澳门元
    MRU: "UM", // 毛里塔尼亚乌吉亚
    MUR: "₨", // 毛里求斯卢比
    MVR: ".ރ", // 马尔代夫拉菲亚
    MWK: "MK", // 马拉维克瓦查
    MZN: "MT", // 莫桑比克梅蒂卡尔
    NAD: "$", // 纳米比亚元
    NGN: "₦", // 尼日利亚奈拉
    NIO: "C$", // 尼加拉瓜科多巴
    NPR: "₨", // 尼泊尔卢比
    OMR: "﷼", // 阿曼里亚尔
    PAB: "B/.", // 巴拿马巴波亚
    PEN: "S/.", // 秘鲁索尔
    PGK: "K", // 巴布亚新几内亚基那
    PKR: "₨", // 巴基斯坦卢比
    PYG: "Gs", // 巴拉圭瓜拉尼
    QAR: "﷼", // 卡塔尔里亚尔
    RSD: "Дин.", // 塞尔维亚第纳尔
    RWF: "Fr", // 卢旺达法郎
    SAR: "﷼", // 沙特里亚尔
    SBD: "$", // 所罗门群岛元
    SCR: "₨", // 塞舌尔卢比
    SDG: "ج.س.", // 苏丹镑
    SHP: "£", // 圣赫勒拿镑
    SLL: "Le", // 塞拉利昂利昂
    SOS: "S", // 索马里先令
    SRD: "$", // 苏里南元
    SSP: "£", // 南蘇丹鎊
    STN: "Db", // 圣多美多布拉
    SVC: "$", // 萨尔瓦多科朗
    SYP: "£", // 叙利亚镑
    SZL: "L", // 斯威士兰里兰吉尼
    TJS: "ЅМ", // 塔吉克斯坦索莫尼
    TMT: "m", // 土库曼斯坦马纳特
    TND: "د.ت", // 突尼斯第纳尔
    TOP: "T$", // 汤加潘加
    TTD: "TT$", // 特立尼达元
    TWD: "NT$", // 新台币
    TZS: "Sh", // 坦桑尼亚先令
    UAH: "₴", // 乌克兰格里夫纳
    UGX: "Sh", // 乌干达先令
    UYU: "$U", // 乌拉圭比索
    UZS: "лв", // 乌兹别克斯坦索姆
    VEF: "Bs", // 委内瑞拉玻利瓦尔
    VND: "₫", // 越南盾
    VUV: "Vt", // 瓦努阿图瓦图
    WST: "T", // 萨摩亚塔拉
    XAF: "Fr", // 中非金融合作法郎
    XCD: "$", // 东加勒比元
    XOF: "Fr", // CFA 法郎
    XPF: "Fr", // CFP 法郎
    YER: "﷼", // 也门里亚尔
    ZMW: "Z$", // 赞比亚克瓦查
    BMD: "$", // 百慕大元
  };
  return { currencySymbols };
}
