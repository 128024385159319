import { computed, defineComponent, onMounted } from "vue";
import entry from "../../style.module.less";
import { useI18n, useLogin, useConfig } from "core";
import { SvgIcon } from "commom";
export default defineComponent({
  setup() {
    const { thirdLoginInfo } = useConfig();
    const { $t } = useI18n();
    const { googleLogin, facebookLogin, initFacebookSDK } = useLogin();
    const whetherOpenShow = computed(() => !!thirdLoginInfo.value?.whetherOpen);
    const googleShow = computed(() => !!thirdLoginInfo.value?.google);
    const facebookShow = computed(() => !!thirdLoginInfo.value?.facebook);

    onMounted(() => {
      initFacebookSDK();
    });
    return () => {
      return (
        whetherOpenShow.value && (
          <div class={entry.other}>
            <div class={entry.otherTitle}>{$t("user_login_otherLoginType" /**其他登录方式 */)}</div>
            <div class={entry.otherLogin}>
              {googleShow.value && (
                <div
                  class={entry.otherLoginIcon}
                  style="background:#fff;width:30px;height:30px;border-radius:15px"
                >
                  <SvgIcon
                    name="newGoogle"
                    width="24px"
                    height="24px"
                    onClick={() => googleLogin()}
                  />
                </div>
              )}
              {facebookShow.value && (
                <div
                  class={entry.otherLoginIcon}
                  style="background:rgba(30, 110, 230, 0.984);width:30px;height:30px;border-radius:15px"
                >
                  <SvgIcon
                    name="newFb"
                    width="32px"
                    height="32px"
                    onClick={() => facebookLogin()}
                  />
                </div>
              )}
            </div>
          </div>
        )
      );
    };
  },
});
