import { defineComponent, onMounted } from "vue";
import { Form, Input } from "ant-design-vue";
import style from "./nick.module.less";
import { useI18n, useUser } from "core";
import popup from "templates/mango/Mobile/popup.module.less";

export default defineComponent({
  emits: ["close"],
  setup(props, { emit }) {
    const { form, reqeustLoading, userAccount, getUserInfo, editDebounceSubmit } = useUser();
    const { $t } = useI18n();
    onMounted(async () => {
      await getUserInfo();
    });
    return () => (
      <div class={style.formWrap}>
        <div class={[popup.popupHeader, popup.popupHeaderBg2]}>
          <section class={popup.popupHeaderLeft} onClick={() => emit("close")}>
            {$t("common_cancel" /*"取消"*/)}
          </section>
          <section class={popup.popupHeaderMiddle}>
            {$t("mine_userinfo_setNickName" /*设置昵称*/)}
          </section>
          <section
            class={popup.popupHeaderRight}
            onClick={async () => {
              if (reqeustLoading.value) return;
              await editDebounceSubmit(["nickname"], false);
              emit("close");
            }}
          >
            {$t("common_confirm" /*"确定"*/)}
          </section>
        </div>
        <Form class={style.formWrapForm}>
          <Form.Item name={"nickname"} validateFirst {...userAccount.validateInfos.nickname}>
            <Input
              placeholder={$t("user_toast_nickName" /*请输入昵称 */)}
              bordered={false}
              v-model:value={form.nickname}
            />
          </Form.Item>
        </Form>
      </div>
    );
  },
});
