import { defineComponent, reactive, computed, watch, toRaw, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { Popup, List, Icon } from "vant";
import style from "./style.module.less";
import styleMain from "../Main/style.module.less";
import { useConfigContext, useI18n, useUserBank, useWithdraw, useAuth } from "core";
import btnPng from "./assets/btn.png";
import bankPng from "./assets/bank.png";
import RadioCircle from "../RadioCircle";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import { mangoPopup } from "components";

interface BankItem {
  id: string;
  bankName: string;
  cardNo: string;
  network: string;
  currency: string;
  alias: string;
  exchangeRate: string;
  isDefault: string;
  icon: string;
  type: string;
  tarCurrency?: null | string;
  show?: boolean;
  address?: null | string;
  bankCode?: null | number;
  remark?: null | string;
  [key: string]: any;
}

export default defineComponent({
  name: "PickerCom",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const router = useRouter();
    const { $t } = useI18n();
    const { isLogin } = useAuth();
    const { cardList, getUserBank } = useUserBank();
    const { config } = useConfigContext();
    const { verificationBindBank, verificationBindVirtual } = useWithdraw();
    // 添加按钮是否显示
    const addBtnShow = computed(() => {
      const { B = [], V = [] } = cardList.value;
      const { isUserBankBindCount = 0, isUserVirtualBindCount = 0 } = config;
      if (props.type === "B") return B.length < isUserBankBindCount;
      else if (props.type === "V") return V.length < isUserVirtualBindCount;
      else return false;
    });

    const pickerTitle = computed(() => {
      return props.type === "B"
        ? $t("funds_choose_bank_card" /* 选择提现银行卡 */)
        : $t("funds_choose_btc_address" /* 选择虚拟币地址 */);
    });
    const bank = reactive<BankItem>({
      show: false,
      id: "",
      bankName: "",
      cardNo: "",
      network: "",
      currency: "",
      exchangeRate: "",
      isDefault: "",
      icon: "",
      alias: "",
      tarCurrency: "",
      type: "",
    });

    const endOfNumber = computed(() => {
      return props.type === "B" ? bank.cardNo : bank.alias;
    });
    const name = computed(() => {
      return props.type === "B"
        ? bank.bankName
        : `${bank.currency}${bank.network ? "/" + bank.network : ""}`;
    });

    const triggerClick = async (e: Event) => {
      e.preventDefault();
      if (props.type === "B" && !(await verificationBindBank())) return;
      if (props.type === "V" && !(await verificationBindVirtual())) return;
      bank.show = true;
    };
    const setBank = (item: BankItem) => {
      const { id = "", bankName = "", cardNo = "", network = "", currency = "", alias = "" } = item;
      bank.id = id;
      bank.bankName = bankName;
      bank.cardNo = cardNo;
      bank.network = network;
      bank.currency = currency;
      bank.alias = alias;
      emit("change", toRaw(item));
    };
    watch(
      () => props.type,
      n => {
        bank.show = false;
        const data = cardList.value[n] || [];
        setBank((data[0] || {}) as BankItem);
      },
      { immediate: true }
    );
    watch(
      () => cardList.value,
      n => {
        const data = n[props.type] || [];
        setBank((data[0] || {}) as BankItem);
      },
      { deep: true }
    );

    const onConfirm = (item: BankItem) => {
      bank.show = false;
      setBank(item);
    };
    const addBank = async () => {
      // await router.push(props.type === "B" ? "bindCard" : "bindUsdt");
      bank.show = false;
      mangoPopup().destroyAll();
      if (props.type === "B") {
        mangoPopup({
          type: "BankCardManageMobile",
          header: false,
          props: { type: "B" },
        }).open();
      } else if (props.type === "V") {
        mangoPopup({
          type: "BankCardManageMobile",
          header: false,
          props: { type: "V" },
        }).open();
      }
    };

    // 获取当前币种银行卡（虚拟币）列表，需要用列表长度判断是否绑定了银行卡（虚拟币）；user中的bankcardCount, virtualCurrencyCount两个参数是所有币种的数量
    onBeforeMount(async () => {
      const { B = [], V = [] } = cardList.value || {};
      if (B?.length || V?.length || !isLogin.value) return;
      await getUserBank();
    });

    return () => (
      <>
        <div class={style.picker} onClick={triggerClick}>
          <section class={style.backNo}>{endOfNumber.value}</section>
          <section class={style.backName}>
            <span>{name.value}</span>
            <i class={[style.backNameTriangle, bank.show && style.backNameOpen]}>
              <CaretDownOutlined />
            </i>
          </section>
        </div>
        <Popup
          v-model:show={bank.show}
          round
          position="bottom"
          teleport="body"
          style={{ maxHeight: "60%" }}
        >
          <div class={styleMain.countryHeader}>
            <section></section>
            <section class={styleMain.countryHeaderCode}>{pickerTitle.value}</section>
            <section class={style.cancel} onClick={() => (bank.show = false)}>
              <Icon name="cross" color="#fff" size="15" />
            </section>
          </div>
          <div class={styleMain.countryMain}>
            <List>
              {cardList.value[props.type].map(option => (
                <div
                  class={styleMain.countryOption}
                  key={option.id}
                  onClick={() => onConfirm(option as BankItem)}
                >
                  {props.type === "B" ? (
                    <section class={styleMain.countryOptionText}>
                      <img src={option.icon || bankPng} alt="" />
                      <span class={styleMain.countryOptionTextName}>
                        {option.bankName}({option.cardNo})
                      </span>
                      <span></span>
                    </section>
                  ) : (
                    <section class={styleMain.countryOptionText}>
                      <img src={option.icon || btnPng} alt="" />
                      <span class={styleMain.countryOptionTextName}>
                        {option.alias}({option.network})
                      </span>
                      <span></span>
                    </section>
                  )}
                  <RadioCircle checked={bank.id === option.id} />
                </div>
              ))}
            </List>

            {addBtnShow.value && (
              <div class={style.addBank}>
                <section class={style.addBankBtn} onClick={addBank}>
                  +{" "}
                  {
                    $t(
                      props.type === "V" ? "funds_virtual_title_tips" : "funds_add_bank_tips"
                    ) /* 添加虚拟币地址 添加银行卡 */
                  }
                </section>
              </div>
            )}
          </div>
        </Popup>
      </>
    );
  },
});
