import { defineComponent, onBeforeMount } from "vue";
import Account from "./setavatar.module.less";
import popup from "templates/mango/Web/popup.module.less";
import { useI18n, useUser } from "core";

export default defineComponent({
  emits: ["close"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { form, avatarIconList, getUserInfo, submitAvatar } = useUser();
    onBeforeMount(async () => {
      await getUserInfo();
    });
    return () => (
      <div class={Account.setUp}>
        <div class={popup.popupHeader}>
          <section class={popup.popupHeaderLeft} onClick={() => emit("close")}>
            {$t("common_cancel" /*取消*/)}
          </section>
          <section class={popup.popupHeaderMiddle}>{$t("mine_userinfo_avatar" /*头像*/)}</section>
          <section
            class={popup.popupHeaderRight}
            onClick={async () => {
              await submitAvatar();
              emit("close");
            }}
          >
            {$t("common_confirm" /*确定*/)}
          </section>
        </div>
        <div class={Account.listView}>
          <div class={Account.listViewItem}>
            {avatarIconList.value.map((img, index) => (
              <div class={Account.listviewItemTitleDiv}>
                <img
                  class={[
                    Number(form.avatar) === index + 1 ? Account.avtActive : null,
                    Number(form.avatar) != index + 1 ? Account.prevActive : null,
                    Account.avtImg,
                  ]}
                  onClick={() => {
                    form.avatar = index + 1 + "";
                  }}
                  src={img}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
});
