import { defineComponent, ref, reactive, onMounted } from "vue";
import { Form, Input } from "ant-design-vue";
import { Button } from "vant";
import style from "./zaloaddress.module.less";
import { useConfig, useI18n, useUser } from "core";
import { SvgIcon } from "commom";
import { mangoPopup } from "components";
import { IDialCode } from "@kgsport-cms/service";
import MangoAreCodeSelect from "templates/mango/Web/components/AreCodeSelect";

export default defineComponent({
  emits: ["close"],
  setup(props, { emit }) {
    const { $t } = useI18n();
    const { user, form, userAccount, editDebounceSubmit, reqeustLoading } = useUser();
    const { getDialCode, defaultCode } = useConfig();
    const data = reactive({
      popupShow: false,
    });
    const changeAreCode = (option: IDialCode) => {
      form.zaloAreaCode = option.code;
    };
    const isMounted = ref(false);

    onMounted(async () => {
      await getDialCode();
      form.zaloAreaCode = form.zaloAreaCode ? form.zaloAreaCode : defaultCode.value;
      setTimeout(() => {
        userAccount.clearValidate();
        isMounted.value = true;
      });
    });

    return () => (
      <div class={style.formWrap}>
        {isMounted.value && (
          <Form>
            <Form.Item name="zalo">
              <Input
                placeholder={$t("mine_usersetup_enterzaloAddress" /*输入您的zalo地址 */)}
                v-model:value={form.zalo}
                disabled={!!user.value?.zalo}
                type="text"
              />
            </Form.Item>
            <Form.Item name="zaloCode">
              <div
                class="phoneCode"
                onClick={() => {
                  if (user.value?.zaloPhone) return;
                  data.popupShow = true;
                }}
              >
                {form.zaloAreaCode}
                <SvgIcon width="18px" height="18px" name="arrow" />
              </div>
              <Input
                placeholder={$t("mine_usersetup_enterMobileNo" /*输入您的电话号码 */)}
                disabled={!!user.value?.zaloPhone}
                v-model:value={form.zaloPhone}
                type="number"
              />
            </Form.Item>
            <div class={style.formWrapBtns}>
              <Button
                onClick={() =>
                  editDebounceSubmit(["zalo"], false, () => {
                    mangoPopup().close();
                  })
                }
                loading={reqeustLoading.value}
                disabled={!!(user.value?.zalo && user.value?.zaloPhone)}
              >
                {$t("mine_userinfo_confirmSubmission" /**确认提交 */)}
              </Button>
            </div>
          </Form>
        )}
        {defaultCode.value && (
          <MangoAreCodeSelect
            v-model:show={data.popupShow}
            currency={form.zaloAreaCode ? form.zaloAreaCode : defaultCode.value}
            onChange={changeAreCode}
          />
        )}
      </div>
    );
  },
});
