import { defineComponent, onDeactivated, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import infoStyle from "./information.module.less";
import { MobileTipsMango } from "components";
import { SvgIcon } from "commom";
import {
  useWallet,
  useRechargeInfo,
  useToast,
  RechargeStatus,
  useClipboards,
  timestampToDateTime,
  useUser,
  useI18n,
  FundsTypeEnum,
  userRecord,
  useRecharge,
} from "core";
import huobi from "./assets/huobi.png";
import binance from "./assets/binance.png";
import okx from "./assets/okx.png";
import kucoin from "./assets/kucoin.png";
import popup from "templates/mango/Mobile/popup.module.less";
import FundingdetailsDetail from "templates/mango/Mobile/Account/fundingdetailsDetail";
import { Popup } from "vant";
const ExchangeImgs = {
  huobi,
  binance,
  okx,
  kucoin,
};

const addZero = (n: string | number, len = 2) => `${n}`.padStart(len, "0");

export default defineComponent({
  name: "MobileRechargeInformationMongo",
  props: {
    model: Number,
  },
  setup(props) {
    const router = useRouter();
    const { routeQueryUsdt, info, cancelOrder, isFinish } = useRechargeInfo();
    const { store } = useRecharge();
    const { getrechargerList, newRechargerInfo } = userRecord();
    const { amountFormat } = useWallet();
    const { copyFn } = useClipboards();
    const { toast } = useToast();
    const { user } = useUser();
    const { $t, I18n } = useI18n();

    const ExchangeList = [
      { name: "funds_recharge_huobi", iconClass: "huobi" },
      { name: "funds_recharge_binance", iconClass: "binance" },
      { name: "funds_recharge_okx", iconClass: "okx" },
      { name: "funds_recharge_kucoin", iconClass: "kucoin" },
    ];

    const copyText = async (value: any) => {
      copyFn(value || "");
      toast.success($t("common_copySuccessfully" /* 复制成功 */));
    };
    const timer = ref();
    const hhMm = ref("");
    const time = ref(0);
    const startTime = () => {
      timer.value = setTimeout(() => {
        time.value--;
        if (time.value) startTime();
        const hh = Math.floor(time.value / 60);
        const mm = time.value % 60;
        hhMm.value = `${addZero(hh)}:${addZero(mm)}`;
      }, 1000);
    };
    const send = () => {
      time.value--;
      startTime();
    };

    watch(
      () => info.value.orderExpireTime,
      n => {
        if (!n) return;
        time.value = (n || 15) * 60;
        send();
      },
      { immediate: true }
    );
    onDeactivated(() => {
      clearTimeout(timer.value);
    });

    onMounted(async () => {
      await getrechargerList();
    });
    const tipModalData = reactive({
      show: false,
      title: $t("funds_recharge_cancelRecharge" /*撤销充值申请*/),
      subTitle: $t(
        "funds_recharge_cancelRechargeTips" /* 如果您已完成存款，请勿取消，我们将尽快为您处理！ */
      ),
      confirmText: $t("funds_recharge_OkToRevoke" /*确定撤销*/),
      cancelText: $t("common_cancel" /*取消*/),
      async confirmCallback() {
        await cancelOrder(tipModalData.cancelCallback);
      },
      cancelCallback(back = false) {
        tipModalData.show = false;
        store.visible = false;
        // back && router.go(-1);
      },
    });
    const openModal = () => {
      tipModalData.show = true;
    };

    const InfoHeader = () => {
      return isFinish.value ? (
        <div class={infoStyle.informationMoneyCard}>
          <div class={infoStyle.informationTopLine}>
            {amountFormat(info.value.amount || 0)}
            <span class={infoStyle.informationTopLineCurrency}>{user.value?.currency}</span>
          </div>
          <div class={infoStyle.informationTopLine}>{$t(RechargeStatus[info.value.status])}</div>
        </div>
      ) : (
        <div class={infoStyle.informationMoneyCard}>
          <div class={infoStyle.informationTopLine}>
            {amountFormat(info.value.amount || 0)}
            <span class={infoStyle.informationTopLineCurrency}>{user.value?.currency}</span>
          </div>
          <p
            class={infoStyle.informationTime}
            v-html={$t("funds_recharge_countDown" /* 请在[0]内完成支付 */, [
              `<span class="${infoStyle.informationTime}">${hhMm.value}</span>`,
            ])}
          />
          <I18n
            tag="p"
            class={infoStyle.informationCustom}
            keyPath="funds_recharge_rechargeTipNotifi" // 成功付款后，将自动到账，并发送到账通知 长时间无反应，请[0]确认
            v-slots={{
              $0: () => {
                return (
                  <span onClick={() => router.push("/customer")}>
                    {$t("user_contactUs_actionTitle" /* 联系客服 */)}
                  </span>
                );
              },
            }}
          />
        </div>
      );
    };

    const UsdtInfoHeader = () => {
      return (
        <div class={infoStyle.informationUsdtHeader}>
          <div class={infoStyle.informationUsdtHeaderLine}>
            <div
              class={[
                infoStyle.informationUsdtHeaderLineTitle,
                infoStyle.informationUsdtHeaderLineTitleIcon,
              ]}
            >
              {$t("funds_recharge_recommended_exchange" /* 推荐交易所 */)}
            </div>
            <div class={infoStyle.informationUsdtHeaderLineContent}>
              {ExchangeList.map(item => (
                <div class={infoStyle.informationUsdtHeaderLineContentItem}>
                  <img
                    class={infoStyle.informationUsdtHeaderLineContentItemIcon}
                    src={ExchangeImgs[item.iconClass]}
                    alt=""
                  />
                  {/* <div class={infoStyle.informationUsdtHeaderLineContentItemName}>
                    {$t(item.name)}
                  </div> */}
                </div>
              ))}
            </div>
            <div
              class={[
                infoStyle.informationUsdtHeaderLineTips,
                infoStyle.informationUsdtHeaderLineAddafter,
              ]}
            >
              {$t("funds_recharge_open_trading" /* 打开并登录交易网站，复制充值信息，完成交易 */)}
            </div>
          </div>
          <div class={infoStyle.informationUsdtHeaderLine}>
            <div class={infoStyle.informationUsdtHeaderTopbox}>
              <div
                class={[
                  infoStyle.informationUsdtHeaderLineTitle,
                  infoStyle.informationUsdtHeaderLineAddmargin,
                ]}
              >
                {$t("funds_recharge_amout" /* 充值数额 */)}:
              </div>
              <div class={infoStyle.informationUsdtHeaderLineMoney}>
                <div class={infoStyle.informationUsdtHeaderLineMoneyLeft}>
                  <div class={infoStyle.informationUsdtHeaderLineMoneyLeftR}>
                    <p>{amountFormat(info.value.amount || 0)}</p>
                    <span>({user.value?.currency ?? ""})</span>
                  </div>
                  <div class={infoStyle.informationUsdtHeaderLineMoneyLeftM}>
                    <p>≈</p>
                  </div>
                  <div class={infoStyle.informationUsdtHeaderLineMoneyLeftL}>
                    <p class={infoStyle.informationUsdtHeaderLineMoneyLeftLAddcolor}>
                      {amountFormat(routeQueryUsdt.value || 0)}
                    </p>
                    <span>(USDT)</span>
                  </div>
                </div>
                <div
                  class={infoStyle.informationUsdtHeaderLineMoneyRight}
                  onClick={() => copyText(routeQueryUsdt.value)}
                >
                  {$t("common_copy" /* 复制 */)}
                </div>
              </div>
            </div>
            <div
              class={[
                infoStyle.informationUsdtHeaderLineTips,
                infoStyle.informationUsdtHeaderLineTipsRed,
              ]}
            >
              {$t(
                "funds_recharge_USDTRechargeTips2" /* 交易所可能收取手续费，若实际到账金额与订单不符，则无法到账（交易所转账金额=订单金额+交易所手续费） */
              )}
            </div>
          </div>
        </div>
      );
    };

    const InfoContent = () => {
      return (
        <div class={infoStyle.informationContent}>
          <ul class={infoStyle.informationBankInfo}>
            {info.value.payAccountBankName ? (
              <li class={infoStyle.boldFont}>
                <span>{info.value.payAccountBankName || "--"}</span>
              </li>
            ) : null}
            {info.value?.qrCode && (
              <li class={infoStyle.qrCodeBox}>
                <span class={infoStyle.qrCodeBoxText}>
                  {$t("funds_recharge_paymentScan" /* 扫一扫付款 */)}
                </span>
                <div class={infoStyle.qrCode}>
                  <img src={info.value.qrCode || ""} alt="" />
                </div>
                <span class={infoStyle.saveTips}>
                  <a href={info.value.qrCode} download="beautifulGirl" target="_blank">
                    {$t("common_save" /* 保存 */)}
                  </a>
                </span>
              </li>
            )}

            {info.value.payAccountAccount && (
              <li class={infoStyle.boldFont}>
                <span>{$t("funds_recharge_paymentAccount" /* "收款账号" */)}</span>
                <span>{info.value.payAccountAccount}</span>
                <i class={infoStyle.copy} onClick={() => copyText(info.value.payAccountAccount)}>
                  {$t("common_copy")}
                </i>
              </li>
            )}

            {info.value.payAccountOwner && (
              <li class={infoStyle.boldFont}>
                <span>{$t("funds_payee_name" /* "收款人姓名" */)}</span>
                <span>{info.value.payAccountOwner}</span>
                <i class={infoStyle.copy} onClick={() => copyText(info.value.payAccountOwner)}>
                  {$t("common_copy")}
                </i>
              </li>
            )}

            {info.value.payAccountBankName && (
              <li class={infoStyle.boldFont}>
                <span>{$t("mine_device_UserAccount" /* "会员账号" */)}</span>
                <span>{user.value?.account}</span>
                <i class={infoStyle.copy} onClick={() => copyText(user.value?.account)}>
                  {$t("common_copy")}
                </i>
              </li>
            )}

            {/*{info.value?.bankAddress ? (
              <li class={infoStyle.boldFont}>
                <span>{$t("funds_recharge_address") /* 地址 *!/</span>
                <span>{info.value.bankAddress}</span>
                <i class={infoStyle.copy} onClick={() => copyText(info.value.bankAddress)}>
                  {$t("common_copy") /* 复制 *!/
                </i>
              </li>
            ) : null}*/}

            {info.value.webRemarks && !info.value?.qrCode
              ? [
                  <li class={infoStyle.boldFont}>
                    <span>{$t("funds_recharge_remark" /* "附言" */)}</span>
                    <span>{info.value.webRemarks || ""}</span>
                    <i class={infoStyle.copy} onClick={() => copyText(info.value.webRemarks)}>
                      {$t("common_copy")}
                    </i>
                  </li>,
                  // <p>(请务必填写正确的附言/备注)</p>,
                ]
              : null}

            <li class={infoStyle.addBorder}>
              <span>{$t("funds_withdraw_orderNo" /**订单号 */)}</span>
              <span>{info.value.orderNo || "--"}</span>
              <i class={infoStyle.copy} onClick={() => copyText(info.value.orderNo)}>
                {$t("common_copy" /* 复制 */)}
              </i>
            </li>
            <li>
              <span>{$t("funds_recharge_tradingTime" /* 交易时间 */)}</span>
              <span>{timestampToDateTime(Number(info.value?.createTime || 0))}</span>
            </li>
            <li>
              <span>{$t("funds_withdraw_paymentAmount" /* "支付金额" */)}</span>
              <span>{amountFormat(info.value?.amount || 0)}</span>
              <i
                class={infoStyle.copy}
                onClick={() => copyText(amountFormat(info.value?.amount || 0))}
              >
                {$t("common_copy" /* 复制 */)}
              </i>
            </li>
            <li>
              <span>{$t("funds_recharge_payWay" /* 充值方式 */)}</span>
              <span>{info.value?.payTypeName || "--"}</span>
            </li>
            {info.value?.payAccountBankName ? (
              <p>{$t("funds_recharge_sameAmoutTips" /* "转账金额务必与订单金额一致" */)}</p>
            ) : null}
          </ul>
        </div>
      );
    };

    const InfoWarnTips = () => {
      return (
        <div class={infoStyle.informationWarns}>
          <h1>{$t("funds_recharge_importantNotice" /* "重要提醒" */)}</h1>
          <p>
            {$t(
              "funds_recharge_tips1" /* "1. 请填写正确的转账金额和附言（备注），否则存款无法及时到账" */
            )}
          </p>
          <p>
            {$t("funds_recharge_tips2" /* "2. 下次存款时，请获取新的账号，存入旧账号将无法到账" */)}
          </p>
          {/* <p>{$t("funds_recharge_tips3")}</p> /* "3. 请勿使用支付宝、微信转账至公司账户" */}
        </div>
      );
    };

    const UsdtInfoContent = () => {
      return [
        <div class={infoStyle.informationUsdtQc}>
          {/* <QRcode ref={usdtQrCode} text={info.value.payAccountAccount || ""} /> */}
          {/* <div class={infoStyle.informationUsdtQcCode}>
            <img src={info.value.qrCode || ""} alt="" />
          </div> */}
          {/*<div class={infoStyle.informationUsdtQcLeft}></div>*/}
          <div class={infoStyle.informationUsdtQcMiddle}>
            <div class={infoStyle.informationUsdtQcMiddleTitle}>
              {$t("funds_recharge_wallet_address" /* 钱包地址 */)}
            </div>
            <div class={infoStyle.informationUsdtQcMiddleAddress}>
              {info.value.payAccountAccount || "--"}
            </div>
            {/* <div class={infoStyle.informationUsdtQcMiddleBtn}>
              <div>
                <a href={usdtQrCode.value?.qrcode} download="beautifulGirl" target="_blank">
                //保存二维码
                  {$t("funds_recharge_save_qr_code" )}
                </a>
              </div>
            </div> */}
          </div>
          <div
            class={infoStyle.informationUsdtQcRight}
            onClick={() => copyText(info.value.payAccountAccount)}
          >
            {$t("common_copy" /* 复制 */)}
          </div>
        </div>,
        <div class={infoStyle.informationContent}>
          <ul class={infoStyle.informationBankInfo}>
            <li>
              <span>{$t("funds_withdraw_orderNo")}</span>
              <span>{info.value.orderNo || "--"}</span>
              <i class={infoStyle.copy} onClick={() => copyText(info.value.orderNo)}>
                {$t("common_copy" /* 复制 */)}
              </i>
            </li>
            <li>
              <span>{$t("funds_recharge_tradingTime" /* 交易时间 */)}</span>
              <span>{timestampToDateTime(Number(info.value?.createTime || 0)) || "--"}</span>
            </li>
            <li>
              <span>{$t("funds_withdraw_paymentAmount" /* "支付金额" */)}</span>
              <span>{amountFormat(info.value?.amount || 0)}</span>
            </li>
            <li>
              <span>{$t("funds_recharge_method")}</span>
              <span>{info.value.payTypeName || "--"}</span>
            </li>
          </ul>
        </div>,
      ];
    };

    // 其他充值方式
    const OtherType = () => {
      return (
        <div class={infoStyle.informationContent}>
          <ul class={infoStyle.informationBankInfo}>
            <li>
              <span>{$t("funds_recharge_method" /* 存款方式 */)}</span>
              <span>{info.value.payTypeName || "--"}</span>
            </li>
            <li>
              <span>{$t("funds_withdraw_orderNo" /* 订单号 */)}</span>
              <span>{info.value.orderNo || "--"}</span>
              <i class={infoStyle.copy} onClick={() => copyText(info.value.orderNo)}>
                {$t("common_copy" /* 复制 */)}
              </i>
            </li>
          </ul>
        </div>
      );
    };

    const detailPopup = ref(false);
    const detailParams = ref({});
    const clickRecordItem = () => {
      const { orderNo, createTime, payTypeName, webRemarks, status } = info.value;
      const { currency } = user?.value ?? {};
      detailParams.value = {
        fundsType: FundsTypeEnum.RECHARGE_ORDER,
        isRechargeIng: 1, // 是否充值中
        orderNo,
        createTime,
        amount: newRechargerInfo.value.amount,
        discountAmount: newRechargerInfo.value.discountAmount,
        payTypeName,
        remark: webRemarks,
        tranType: status,
        mode: 1,
        currency,
        balance: newRechargerInfo.value.balance,
        totalAmount: newRechargerInfo.value.totalAmount,
        status: 1,
      };
      detailPopup.value = true;
    };
    const InfoCancelBtn = () => {
      return isFinish.value ? null : (
        <>
          <div class={infoStyle.informationCancel}>
            <span class={infoStyle.informationCancelBtn} onClick={clickRecordItem}>
              <SvgIcon class={infoStyle.informationCancelBtnIcon} name="orderLIst" />
              {$t("funds_recharge_goDetail" /* "查看订单详情" */)}
            </span>
            <span class={infoStyle.informationCancelBtn} onClick={openModal}>
              <SvgIcon class={infoStyle.informationCancelBtnIcon} name="orderCancel" />
              {$t("funds_recharge_cancelRecharge" /* "撤销充值申请" */)}
            </span>
          </div>
          <Popup
            v-model:show={detailPopup.value}
            teleport="body"
            position="bottom"
            round
            class={[popup.popupWidth100, popup.popupHeight630]}
          >
            <FundingdetailsDetail
              params={detailParams.value}
              onClose={() => (detailPopup.value = false)}
            />
          </Popup>
          <MobileTipsMango type="info" {...tipModalData} />
        </>
      );
    };

    return () => (
      <div class={infoStyle.information}>
        {props.model === 2 ? UsdtInfoHeader() : InfoHeader()}
        {props.model === 1 || info.value?.qrCode ? InfoContent() : null}
        {props.model === 0 ? OtherType() : null}
        {props.model === 1 && info.value.payAccountBankName ? InfoWarnTips() : null}
        {props.model === 2 ? UsdtInfoContent() : null}
        {InfoCancelBtn()}
      </div>
    );
  },
});
