export const mobileUserCard = [];
export const mobileTabBar = [
  // 游戏类跳转配置
  // {
  //   name: "xx游戏",
  //   path: "",
  //   type: "game",
  //   gameItem: {
  //     platformCode: "KGNL",
  //     gameType: "LOTTERY",
  //     gameKindCode: "KGNL_LOTTERY",
  //     subLevel: 0,
  //   }
  // }
  // 外链跳转配置 path 配置主要是为了能够获取json动画名称
  {
    name: "common_pagination_home", // 首页
    path: "/home",
    animation: "home",
  },
  {
    name: "common_tabbar_game", // 娱乐城
    path: "/game",
  },
  {
    name: "2022FIFA", // 体育
    path: "/",
    animation: "sport",
  },
  {
    name: "common_tabbar_bet", // 注单
    path: "/betRecord",
  },
  {
    name: "common_tabbar_mine", // 我的
    path: "/account",
  },
];
export const mobileGameScence = "Scence1";
export const vipIcon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAgCAYAAACFM/9sAAADLklEQVRoBe2ajW7aMBDH8wh7hD5K35C+wfY2k4BidaJLxkbJuvE12oJAtN3WytM/4sj5mg87KWRSbSly4trnu1/ufC5xEIjSUeFp+/zzWbcXjTq9SPsrYaC66st7pcITgSt9VEq966hBywMrcRoVtcAqJRcEQQKvFykPrwReGpHKgOg9zxpcuqSpqJV44UcVnnjPqwAQ3tgJT4N2L/zgAVYDiGQbdPzal4ZlusZZtWGnAoBWnX2/bE7WANebreZlPFmUgr/oD/Xj7z98mB7FE30VT4222fzWkNW7GLwYZwxgD9DrKp4Y44/5sq0BXv+YM7W1fnp6LlV69uvOGLO4WSVjAJ8XyOZGL25X/M9W9xjDZRzr3hogvALQeIkG17lKw/t4gSeiDYYtV2v+J/11+HMvZzw14RodSx5souK1wVoDxMSzuelRCJ88hbJCl/pu7x8MFJfhKJHTD2Oj3fUBc9Icx6qdAMLjZIFnSmWlF1HoUj/pydQOWZ/6Q6vrMoz1eHIj1XmhC8k+VO0EEEpI75FhI0P379PzPnQxHpB42d4/1jKay8L9oUDlyXUGKBOATCYSsEwQ0eC7YfN6nb8M5ClN7f1wZMiq+zJIrkvtDFB6ECygZCK3J1lwZB/awsBzsR2xvbBMwLt5kS/LBUTVvs4AMZHcEy5XmyRMZeKgrMuVkx5MRiMT1yk8y/P5Dn1fCaBMJgjju9XGsB8LfJbycgtD3ivBGsJKHuCJyOBZ8x26rRJAKCUzKbexaDsh10jawkiwXF7RPZaJLE8/NDiSXxlgkccUGSTBkyIS7Hb7oAEn61ouNxprJ3kvyWiirgwwK5nAU/JCF8bJMTxr5oFtAorLnJUBYhKZTIpCF/3ztjBFYF2MaaJvLYAymRSFLozL28LkgW0CiOuctQBiMvx/jCw4iqelWRB9+Jr2bfcjArYwvJ22Nq7GNNG/NsAmlP6f5gz8B/TsX5otX5IK2ufRmWXn0hB9a3JwWiHAp7m3Zvhr2bs/6tH1XugcXV180qTij3Y4r4Pm0Q6ABETvieUg4XnGuRjyQqpx1GN3WsEfNtp9M8dOBacQkqMcBGpX/wMo+pAHnd8/9AAAAABJRU5ErkJggg==";
export const RechargeInfoTips = `
          <p>1. 请填写正确的转账金额和附言（备注），否则存款无法及时到账</p>
          <p>2. 下次存款时，请获取新的账号，存入旧账号将无法到账</p>
          <p>3. 转账时，优先复制收款卡号粘贴</p>
          <p>
            4. 不知道如何存款，请按照存款教程页面的"<a  target="_blank" href="/help?key=withdraw">存款教程</a>
            "步骤来操作
          </p>
          <p>5. 请及时前往存款，附言（备注）有效时间只有<%- orderExpireTime %>分钟</p>
          <p>6. 若存款存在疑问，请联系客服及时处理</p>
          <p>7. 请勿使用支付宝、微信转账至公司账户</p>
          <p>8. 晚上18:00过后，建议使用光大、建设、民生、农业、工商、中国银行进行转账</p>
          <p>9. 17:00~18:00额度为：3W~5W，其余时间额度为：5W~20W</p>
`;
