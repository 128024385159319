import {
  CommonService,
  ConfigService,
  AuthService,
  GameService,
  HelpService,
  RechargeService,
  UserInfoService,
  BankService,
  merge,
  MessageService,
  RecordService,
  ActivityService,
  TransService,
  WithdrawService,
  DownloadService,
  BannerService,
  FeedbackService,
  LiveService,
  LoanService,
  YuebaoService,
  SportBetRecordsService,
  WalletService,
  TimezoneService,
  TaskService,
  TurntableService,
  PromotionCodeService,
  I18nService,
  TreasureBoxService,
} from "@kgsport-cms/service";
import { requesterFn } from "./requester.service";

type ServiceType = {
  ConfigService: ConfigService;
  CommonService: CommonService;
  AuthService: AuthService;
  GameService: GameService;
  HelpService: HelpService;
  RechargeService: RechargeService;
  UserService: UserInfoService;
  BankService: BankService;
  MessageService: MessageService;
  RecordService: RecordService;
  ActivityService: ActivityService;
  TransService: TransService;
  WithdrawService: WithdrawService;
  DownloadService: DownloadService;
  BannerService: BannerService;
  FeedbackService: FeedbackService;
  LiveService: LiveService;
  LoanService: LoanService;
  YuebaoService: YuebaoService;
  SportBetRecordsService: SportBetRecordsService;
  WalletService: WalletService;
  TimezoneService: TimezoneService;
  TaskService: TaskService;
  TurntableService: TurntableService;
  PromotionCodeService: PromotionCodeService;
  I18nService: I18nService;
  TreasureBoxService: TreasureBoxService;
};
const Services = {
  ConfigService,
  CommonService,
  AuthService,
  GameService,
  HelpService,
  RechargeService,
  BankService,
  UserService: UserInfoService,
  MessageService,
  RecordService,
  ActivityService,
  TransService,
  WithdrawService,
  DownloadService,
  BannerService,
  FeedbackService,
  LiveService,
  LoanService,
  YuebaoService,
  SportBetRecordsService,
  WalletService,
  TimezoneService,
  TaskService,
  TurntableService,
  PromotionCodeService,
  I18nService,
  TreasureBoxService,
};

export const Api = merge<ServiceType>(Services, {
  requesterFn,
  timeout: 10 * 1000,
});
